import styled from 'styled-components'

export default styled.div`
  width: 100%;
  margin-bottom: 32px;
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  border-radius: 10px;
  box-sizing: border-box;
  user-select: none;

  .header {
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      margin-right: 8px;
    }
  }

  .body {
    padding: 16px;
  }
`
