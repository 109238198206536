import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import countries from "i18n-iso-countries"
import i18next from "i18next";

countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

export default class extends Component {
    state = {
        id: null,
        name: null,
        label: null,
        legalName: null,
        notes: null,
        contactEmail: null,
        contactTelephone: null,
        contactTelefax: null,
        addressStreetName: null,
        addressStreetNumber: null,
        addressAdditional: null,
        addressPostalCode: null,
        addressCity: null,
        addressCountry: null,
        loading: false
    }

    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Modal minWidth="650px" instance={this.props.instance} initialize={serviceProvider => this.setState({
            id: serviceProvider.id,
            name: serviceProvider.name,
            responsibility: serviceProvider.responsibility,
            contactEmail: serviceProvider.contactEmail,
            contactTelephone: serviceProvider.contactTelephone,
            contactTelefax: serviceProvider.contactTelefax,
            addressStreetName: serviceProvider.address.streetName,
            addressStreetNumber: serviceProvider.address.streetNumber,
            addressAdditional: serviceProvider.address.additional,
            addressPostalCode: serviceProvider.address.postalCode,
            addressCity: serviceProvider.address.city,
            addressCountry: serviceProvider.address.country,
            loading: false
        })}>
            <Modal.Head title={i18next.t("page.private.serviceProvider.updateServiceProviderModal.titleForm")} icon="pencil" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Modal.Divider title={i18next.t("page.private.serviceProvider.updateServiceProviderModal.generalInformation")} />
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="font" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.name")} adjustWidth />
                    <Input required value={this.state.responsibility} onChange={responsibility => this.setState({ responsibility })} icon="hard-hat" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.Responsibility")} adjustWidth />
                    <Modal.Divider title={i18next.t("page.private.serviceProvider.updateServiceProviderModal.contactDetails")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                        <Input value={this.state.contactEmail} onChange={contactEmail => this.setState({ contactEmail })} icon="at" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.email")} adjustWidth />
                        <Input value={this.state.contactTelephone} onChange={contactTelephone => this.setState({ contactTelephone })} icon="phone" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.telephoneNumber")} adjustWidth />
                        <Input value={this.state.contactTelefax} onChange={contactTelefax => this.setState({ contactTelefax })} icon="fax" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.fax")} adjustWidth />
                    </div>
                    <Modal.Divider title={i18next.t("page.private.serviceProvider.updateServiceProviderModal.address")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "2fr 0.5fr" }}>
                        <Input value={this.state.addressStreetName} onChange={addressStreetName => this.setState({ addressStreetName })} icon="road" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.street")} adjustWidth />
                        <Input value={this.state.addressStreetNumber} onChange={addressStreetNumber => this.setState({ addressStreetNumber })} icon="bell" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.houseNumber")} adjustWidth />
                    </div>
                    <Input value={this.state.addressAdditional} onChange={addressAdditional => this.setState({ addressAdditional })} icon="font" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.additionalAddress")} adjustWidth />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 2fr" }}>
                        <Input value={this.state.addressPostalCode} onChange={addressPostalCode => this.setState({ addressPostalCode })} icon="mailbox" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.postalCode")} adjustWidth />
                        <Input value={this.state.addressCity} onChange={addressCity => this.setState({ addressCity })} icon="city" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.city")} adjustWidth />
                    </div>
                    <Select upwards adjustWidth value={this.state.addressCountry} onChange={addressCountry => this.setState({ addressCountry })} icon="globe-europe" label={i18next.t("page.private.serviceProvider.updateServiceProviderModal.country")} items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.responsibility} title={i18next.t("page.private.serviceProvider.updateServiceProviderModal.exitButton")} icon="pencil" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $name: String!, $responsibility: String!, $contactEmail: String, $contactTelephone: String, $contactTelefax: String, $addressStreetName: String, $addressStreetNumber: String, $addressAdditional: String, $addressPostalCode: String, $addressCity: String, $addressCountry: String) {
                            updateServiceProvider(id: $id, name: $name, responsibility: $responsibility, contactEmail: $contactEmail, contactTelephone: $contactTelephone, contactTelefax: $contactTelefax, addressStreetName: $addressStreetName, addressStreetNumber: $addressStreetNumber, addressAdditional: $addressAdditional, addressPostalCode: $addressPostalCode, addressCity: $addressCity, addressCountry: $addressCountry)
                        }
                    `,
                    variables: {
                        id: this.state.id,
                        name: this.state.name,
                        responsibility: this.state.responsibility,
                        contactEmail: this.state.contactEmail,
                        contactTelephone: this.state.contactTelephone,
                        contactTelefax: this.state.contactTelefax,
                        addressStreetName: this.state.addressStreetName,
                        addressStreetNumber: this.state.addressStreetNumber,
                        addressAdditional: this.state.addressAdditional,
                        addressPostalCode: this.state.addressPostalCode,
                        addressCity: this.state.addressCity,
                        addressCountry: this.state.addressCountry
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.updateServiceProvider) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
