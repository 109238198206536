import React, { Component } from "react"
import { Page, Breadcrumbs, Section, GridElement, Grid, Input } from "scanmetrix-components"
import styled from "styled-components"
import i18next from "i18next"

import Chart from "react-apexcharts"
import ChangeBudgetModal from "./ChangeBudgetModal";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
})

let categories = []

for(let i = 0; i < 12; i++) {
    categories.push(moment().month(i).format("MMM YY"))
}

export default class extends Component {
    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($year: Int!, $status: String!, $declined: Boolean!) {
                    AccountingInvoices(filter: { status_eq: $status, declined_eq: $declined }) {
                        nodes {
                            id
                            date
                            net
                            number
                            accountingInvoiceDocument {
                                id
                            }
                            serviceProvider {
                                id
                                name
                                responsibility
                            }
                            subsidiary {
                                id
                                name
                                label
                            }
                        }
                    }
                    BudgetCategories {
                        nodes {
                            id
                            name
                            color
                        }
                    }
                    BudgetCategoryFigures(filter: { year_eq: $year }) {
                        nodes {
                            id
                            month
                            value
                            budgetCategory {
                                id
                            }
                        }
                    }
                    BudgetYears(filter: { year_eq: $year }) {
                        nodes {
                            id
                            value
                        }
                    }
                }
            `,
            variables: {
                year: moment().year(),
                status: "archived",
                declined: false
            }
        }).then(data => {
            this.setState({
                invoices: data.data.AccountingInvoices.nodes,
                categories: data.data.BudgetCategories.nodes,
                figures: data.data.BudgetCategoryFigures.nodes,
                budget: data.data.BudgetYears.nodes.length ? data.data.BudgetYears.nodes[0].value : 0
            })
        })

        fetch(scanmetrix.backendURL + "/contract-provision-intervals", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ contractProvisionIntervals: data.contractProvisionIntervals.filter(c => c.cost !== null) })
        })
    }

    state = {
        budget: 0,
        contractProvisionIntervals: [],
        invoices: [],
        categories: [],
        figures: [],
        options: {
            chart: {
                height: 550,
                width: "100%",
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: true
                }
            },
            colors: [ "#3b97d3" ],
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: formatter.format,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            xaxis: {
                categories,
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#3b97d3',
                            colorTo: '#3b97d3',
                            stops: [0, 100],
                            opacityFrom: 0.75,
                            opacityTo: 0,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: true,
                    formatter: formatter.format
                }
            },
            tooltip: {
                enabled: true,
                shared: true,
                intersect: false
            }
        },
        optionsBudget: {
            chart: {
                height: 350,
                width: "100%",
                type: 'line',
                toolbar: {
                    show: false
                }
            },
            annotations: {
                xaxis: [
                    {
                        x: categories[moment().month()],
                        seriesIndex: 0,
                        label: {
                            borderColor: '#2ecc71',
                            offsetY: 150,
                            style: {
                                color: '#fff',
                                background: '#1abc9c',
                            },
                            text: i18next.t("page.private.budget.budgetMonitoring.budgetDevelopment.endStartText"),
                        }
                    }
                ]
            },
            colors: [ "#3b97d3" ],
            plotOptions: {
                line: {
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: formatter.format,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            xaxis: {
                categories,
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#3b97d3',
                            colorTo: '#3b97d3',
                            stops: [0, 100],
                            opacityFrom: 0.75,
                            opacityTo: 0,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    gradientToColors: [ "#1abc9c" ],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                },
            },
            forecastDataPoints: {
                count: 11 - moment().month(),
                strokeWidth: 8
            },
            yaxis: {
                floating: false,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: true,
                    formatter: formatter.format
                }
            },
            tooltip: {
                enabled: true,
                shared: true,
                intersect: false
            }
        }
    }

    render() {
        let dataContractsUpcoming = []
        let dataContractsDue = []

        let dataInvoices = []

        for(let i = 0; i < 12; i++) {
            let totalInvoices = 0

            this.state.invoices.forEach(invoice => {
                if(moment(invoice.date).year() === moment().year() && moment(invoice.date).month() === i) totalInvoices += invoice.net
            })

            dataInvoices.push(totalInvoices)

            let totalContractsUpcoming = 0
            let totalContractsDue = 0

            this.state.contractProvisionIntervals.forEach(prov => {
                if(moment(prov.date, "DD.MM.YYYY").year() === moment().year() && moment(prov.date, "DD.MM.YYYY").month() === i) {
                    if(i >= moment().month()) {
                        totalContractsUpcoming += prov.cost
                    } else {
                        totalContractsDue += prov.cost
                    }
                }
            })

            dataContractsUpcoming.push(totalContractsUpcoming)
            dataContractsDue.push(totalContractsDue)
        }

        const budget = this.state.budget

        const series = [
            {
                name: i18next.t("page.private.budget.budgetMonitoring.costDevelopmentGraph.targetCosts"),
                data: dataContractsUpcoming,
                color: "#3b97d3"
            },
            {
                name: i18next.t("page.private.budget.budgetMonitoring.costDevelopmentGraph.actualCosts"),
                data: dataInvoices,
                color: "#1abc9c"
            },
            {
                name: i18next.t("page.private.budget.budgetMonitoring.costDevelopmentGraph.provisionsRequired"),
                data: dataContractsDue,
                color: "#e74c3c"
            },
            ...this.state.categories.map(category => {
                let data = []

                for(let i = 0; i < 12; i++) {
                    let figures = this.state.figures.filter(figure => figure.month === i && figure.budgetCategory.id === category.id).map(figure => figure.value)

                    if(figures.length > 0) data.push(figures.reduce((a, b) => a + b, 0))
                    else data.push(0)
                }

                return ({
                    name: category.name,
                    data,
                    color: category.color
                })
            })
        ]

        let budgetData = []

        let remainingBudget = budget

        for(let i = 0; i < 12; i++) {
            series.forEach(serie => {
                remainingBudget -= serie.data[i]
            })

            budgetData.push(remainingBudget)
        }

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "piggy-bank",
                    title: i18next.t("page.private.budget.breadCrumbsTitle")
                }
            ]} />
            <ChangeBudgetModal instance={ref => this.changeBudgetModal = ref} onChange={budget => this.setState({ budget })} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.budget.budgetMonitoring.sectionTitle")} icon="chart-mixed">
                    <Grid gap="32px" columns={[ "1fr", "1fr", "1fr", "1fr" ]}>
                        <GridElement styled columnStart={1} columnEnd={2} title={i18next.t("page.private.budget.budgetMonitoring.provisionsRequired.gridElementTitle")} icon="message-exclamation">
                            <Number color="#e74c3c">
                                <p className="value">{formatter.format(dataContractsDue.reduce((a, b) => a + b, 0))}</p>
                                <p className="description">{i18next.t("page.private.budget.budgetMonitoring.provisionsRequired.description")}</p>
                            </Number>
                        </GridElement>
                        <GridElement styled columnStart={2} columnEnd={3} title={i18next.t("page.private.budget.budgetMonitoring.upcomingTargetCosts.gridElementTitle")} icon="thought-bubble">
                            <Number color="#3b97d3">
                                <p className="value">{formatter.format(dataContractsUpcoming.reduce((a, b) => a + b, 0))}</p>
                                <p className="description">{i18next.t("page.private.budget.budgetMonitoring.upcomingTargetCosts.description")}</p>
                            </Number>
                        </GridElement>
                        <GridElement styled columnStart={3} columnEnd={4} title={i18next.t("page.private.budget.budgetMonitoring.actualCosts.gridElementTitle")} icon="coins">
                            <Number color="#1abc9c">
                                <p className="value">{formatter.format(dataInvoices.reduce((a, b) => a + b, 0))}</p>
                                <p className="description">{i18next.t("page.private.budget.budgetMonitoring.actualCosts.description")}</p>
                            </Number>
                        </GridElement>
                        <GridElement styled columnStart={4} columnEnd={5} title={i18next.t("page.private.budget.budgetMonitoring.remainingBudget.gridElementTitle")} icon="piggy-bank">
                            <Number color="#e67e22">
                                <p className="value">{formatter.format(remainingBudget)}</p>
                                <p className="description">{i18next.t("page.private.budget.budgetMonitoring.remainingBudget.description")} (<b>Planbudget {formatter.format(budget)}</b> <i className="fas fa-cog" style={{ color: "#3b97d3", cursor: "pointer" }} onClick={() => {
                                    this.changeBudgetModal.open(budget)
                                }} />)</p>
                            </Number>
                        </GridElement>
                        <GridElement styled columnStart={1} columnEnd={5} title={i18next.t("page.private.budget.budgetMonitoring.budgetDevelopment.gridElementTitle")} icon="chart-mixed">
                            <Chart options={this.state.optionsBudget} series={[
                                {
                                    name: i18next.t("page.private.budget.budgetMonitoring.budgetDevelopment.remainingBudgetGraphLine"),
                                    data: budgetData,
                                    color: "#e74c3c"
                                },
                            ]} type="line" height={350} />
                        </GridElement>
                        <GridElement styled columnStart={1} columnEnd={5} title={i18next.t("page.private.budget.budgetMonitoring.budgetDevelopment.costDevelopment")} icon="chart-mixed">
                            <Chart options={this.state.options} series={series} type="bar" height={550} />
                        </GridElement>
                    </Grid>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.budget.targetFigures.sectionTitle")} icon="table-list">
                    <GridElement styled title="Planzahlen" icon="table-list" rightContent={<p><i style={{ color: "#3b97d3", marginRight: 4 }} className="far fa-info-circle" /> {i18next.t("page.private.budget.targetFigures.description")}</p>}>
                        <BudgetFigures />
                    </GridElement>
                </Section.Item>
            </Section>
        </Page>
    }
}

const StyledBudgetFigures = styled.div`
    padding: 24px 24px 8px 24px;
  
    .header, .category {
        display: grid;
        grid-template-columns: 2fr repeat(12, 1fr);
        grid-gap: 8px;
    }
  
    .header {
        height: 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-bottom: 24px;
        user-select: none;
        font-size: 18px;
    }
  
    .category {
        height: 80px;
      
        .title {
            display: flex;
            align-items: center;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            user-select: none;
            
            .circle {
                width: 24px;
                height: 24px;
                border-radius: 100%;
                margin-right: 12px;
                flex-shrink: 0;
            }
        }
    }
`

const Number = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 600;
    flex-direction: column;
  
    .value {
        color: ${props => props.color};
    }
  
    .description {
        text-align: center;
        width: 50%;
        font-weight: 400;
        margin-top: 12px;
        font-size: 14px;
    }
`

class BudgetFigures extends Component {
    state = { months: [], categories: [], figures: [] }

    constructor(props) {
        super(props)

        let months = []

        for(let i = 0; i < 12; i++) months.push(moment().month(i).format("MMM YY"))

        this.state.months = months

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($year: Int!) {
                    BudgetCategories {
                        nodes {
                            id
                            name
                            color
                        }
                    }
                    BudgetCategoryFigures(filter: { year_eq: $year }) {
                        nodes {
                            id
                            month
                            value
                            budgetCategory {
                                id
                            }
                        }
                    }
                }
            `,
            variables: {
                year: moment().year()
            }
        }).then(data => {
            this.setState({
                categories: data.data.BudgetCategories.nodes,
                figures: data.data.BudgetCategoryFigures.nodes
            })
        })
    }

    updateValue(budgetCategoryId, index, val) {
        if(!this.updateMap) this.updateMap = {}

        if(this.timeout) clearTimeout(this.timeout)

        if(!this.updateMap[budgetCategoryId]) this.updateMap[budgetCategoryId] = {}

        this.updateMap[budgetCategoryId][index] = val

        let self = this

        this.timeout = setTimeout(() => {
            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($data: JSON!, $year: Int!) {
                        updateBudgetCategoryFigures(data: $data, year: $year)
                    }
                `,
                variables: {
                    data: self.updateMap,
                    year: moment().year()
                }
            })

            self.timeout = null
            self.updateMap = null
        }, 400)
    }

    render() {
        return <StyledBudgetFigures>
            <div className="header">
                <div className="title">{i18next.t("page.private.budget.targetFigures.budgetCategory")}</div>
                {this.state.months.map((month, index) => <div className="month" key={index}>{month}</div>)}
            </div>
            {this.state.categories.map((category, index) => <div className="category" key={index}>
                <div className="title">
                    <div className="circle" style={{ background: category.color }} />
                    <p>{category.name}</p>
                </div>
                {this.state.months.map((_, index) => <div key={index}>
                    <Input readOnly={scanmetrix.checkPermission("Budget") < 3} value={this.state.figures.find(fig => fig.month === index && fig.budgetCategory.id === category.id)?.value} onChange={val => this.updateValue(category.id, index, val)} label={i18next.t("page.private.budget.targetFigures.targetNumber")} float optional adjustWidth format={val => val !== null ? formatter.format(val) : val} noOverlay />
                </div>)}
            </div>)}
        </StyledBudgetFigures>
    }
}
