import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    margin-bottom: 32px;
    opacity: 0.75;
  }

  img {
    width: 150px;
    height: auto;
    filter: grayscale(1);
    opacity: 0.75;
    pointer-events: none;
    user-drag: none;
  }
`
