import React, { Component } from "react"
import styled from "styled-components"
import DayPicker from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
//import 'moment/locale/de'

import enhanceWithClickOutside from "react-click-outside"
import {ClipLoader} from "react-spinners";

const MutationContext = React.createContext()

const InputContainer = styled.div`
  height: ${props => props.textArea ? (props.height || "128px") : "64px"};
  position: relative;
  display: ${props => props.adjustWidth ? "block" : "inline-block"};
  width: ${props => props.adjustWidth ? "100%" : "auto"};
  font-size: 1.1em;
  margin: ${props => props.margin || 0};
  transition: opacity 0.3s;
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? "none" : "all"};

  &:after {
    opacity: ${props => props.textArea ? 0 : (props.gradientVisible ? 1 : 0)};
    display: block;
    position: absolute;
    content: " ";
    height: 47px;
    width: 64px;
    right: 0;
    bottom: 1px;
    background: linear-gradient(90deg,rgba(255, 255, 255, 0),white,#ffffff);
    z-index: 4;
    transition: opacity 0.3s;
  }
`

const InputLabel = styled.label`
  position: absolute;
  z-index: 1;
  line-height: ${props => props.value ? "16px" : (props.textArea ? "144px" : "80px")};
  opacity: ${props => props.value ? 1 : 0.5};
  font-size: ${props => props.value ? "0.8em" : "0.9em"};
  transition: line-height 0.3s, opacity 0.3s, font-size 0.3s;
  user-select: none;
  pointer-events: none;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`

const InputIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: ${props => props.textArea ? "112px" : "48px"};
  z-index: 5;
  color: #20242b;
  transition: opacity 0.3s;
  opacity: ${props => props.value ? 1 : 0.5};
`

const Input = styled.input`
  outline: 0;
  border: none;
  padding-right: ${props => props.noOverlay ? 0 : "24px"};
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 64px;
  box-sizing: border-box;
  padding-top: 16px;
  transition: border-color 0.3s;
  z-index: 3;
  width: 100%;
  font-size: 0.95em;
  position: relative;

  &:focus {
    border-color: #20242b;
  }
`

const TextArea = styled.textarea`
  outline: 0;
  border: none;
  padding-right: 24px;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: ${props => props.height || "128px"};
  box-sizing: border-box;
  padding-top: 28px;
  transition: border-color 0.3s;
  z-index: 3;
  width: 100%;
  font-size: 0.95em;
  position: relative;
  font-family: inherit;
  resize: none;

  &:focus {
    border-color: #20242b;
  }
`

const InputRightText = styled.p`
  position: absolute;
  right: 32px;
  bottom: 0;
  font-size: 0.9em;
  line-height: ${props => props.textArea ? "112px" : "48px"};
  z-index: 5;
  color: #20242b;
`

const DatePickerDropdown = styled.div`
  z-index: 100;
  position: absolute;
  top: 64px;
  left: 0;
  background: white;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.4);

  select {
    height: 32px;
    padding: 0 8px;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    transition: opacity 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    &:first-child {
      margin-right: 8px;
    }
  }
`

const DeleteValue = styled.div`
  position: absolute;
  bottom: 12px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
  cursor: pointer;
  user-select: none;
  width: 24px;
  height: 24px;
  font-size: 16px;
  border-radius: 3px;
  transition: background 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const fromMonth = new Date(new Date().getFullYear() - 50, 0)
const toMonth = new Date(new Date().getFullYear() + 10, 11)

function YearMonthForm({ date, localeUtils, onChange }) {
    const months = localeUtils.getMonths("de");

    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
        years.push(i);
    }

    const handleChange = function handleChange(e) {
        const { year, month } = e.target.form;
        onChange(new Date(year.value, month.value));
    };

    return (
        <form className="DayPicker-Caption">
            <select name="month" onChange={handleChange} value={date.getMonth()}>
                {months.map((month, i) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            <select name="year" onChange={handleChange} value={date.getFullYear()}>
                {years.map(year => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </form>
    );
}

const Dropdown = enhanceWithClickOutside(class extends Component {
    constructor(props) {
        super(props);
        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
        this.state = {
            month: new Date(),
        };
    }

    handleClickOutside() {
        this.props.blur()
    }

    handleYearMonthChange(month) {
        this.setState({ month });
    }

    render() {
        return <DatePickerDropdown>
            <DayPicker showWeekNumbers month={this.state.month}
                       fromMonth={fromMonth}
                       toMonth={toMonth} onDayClick={day => {
                this.props.onChange(moment(day).format("DD.MM.YYYY"))
            }} localeUtils={MomentLocaleUtils} locale="de" captionElement={({ date, localeUtils }) => (
                <YearMonthForm
                    date={date}
                    localeUtils={localeUtils}
                    onChange={this.handleYearMonthChange}
                />
            )} />
        </DatePickerDropdown>
    }
})

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

const Required = styled.div`
  color: #3b97d3;
  margin-left: 8px;
  font-size: 0.6em;
`

class InputClass extends Component {
    static contextType = MutationContext

    state = { value: null, isFocused: false, saving: false, saved: false, error: false }

    constructor(props) {
        super(props)

        this.mutate = this.mutate.bind(this)

        let initialValue = props.value

        const mutationContext = props.mutationContext

        if(mutationContext) {
            if(!mutationContext.listeners) mutationContext.listeners = []

            if(props.contextualValue || props.contextualDisabled || props.disabledContextualValue) {
                mutationContext.listeners.push(() => {
                    this.forceUpdate()
                })
            }

            if(mutationContext.data && props.field) {
                const data = mutationContext.data
                const fieldValue = data[props.field]

                if(props.date) {
                    if(fieldValue) initialValue = moment(fieldValue).format("DD.MM.YYYY")
                    else initialValue = null
                } else {
                    if(props.field.includes(".")) {
                        const structure = props.field.split(".")
                        let tempValue = data

                        structure.forEach(key => {
                            if(tempValue === undefined) tempValue = null
                            else if(tempValue !== null) tempValue = tempValue[key]
                        })

                        initialValue = tempValue
                    } else initialValue = fieldValue === undefined ? null : fieldValue
                }
            }
        }

        if(initialValue !== undefined && initialValue !== null) this.state.value = props.date ? (moment(initialValue, "DD.MM.YYYY").format("DD.MM.YYYY")) : initialValue

        this.savedValue = this.state.value
    }

    componentWillReceiveProps(props) {
        if(props.value !== undefined && props.value !== null) this.setState({ value: props.date ? (moment(props.value, "DD.MM.YYYY").format("DD.MM.YYYY")) : props.value })
        else if(props.value === null) this.setState({ value: null })
    }

    mutate(mutationContext, value) {
        if(mutationContext.customFunction) {
            mutationContext.customFunction(value)
        } else {
            const parsedValue = this.props.float ? (value ? parseFloat(value) : null) : (this.props.int ? (value ? parseInt(value, 10) : null) : value)

            this.setState({ saving: true })

            const fieldString = this.props.field
            const parts = fieldString.split(".").reverse()

            let lastObj = {}

            parts.forEach((part, index) => {
                lastObj = { [part]: index === 0 ? ("$" + this.props.field.replace(/\./g, "_")) : lastObj }
            })

            const str = JSON.stringify(lastObj)
            const fieldStringParsed = str.substring(1, str.length - 1).replace(/"/g, "")

            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`mutation($id: ID!, $${this.props.field.replace(/\./g, "_")}: ${this.props.int ? "Int" : (this.props.float ? "Float" : (this.props.date ? "DateTime" : "String"))}) {
                ${mutationContext.name}(id: $id, ${fieldStringParsed})
                }`,
                variables: {
                    id: mutationContext.id,
                    [this.props.field.replace(/\./g, "_")]: parsedValue
                }
            }).then(data => {
                if(data.data[mutationContext.name]) {
                    if(mutationContext.onSave) mutationContext.onSave()

                    this.setState({ saving: false, saved: true, error: false })

                    this.savedValue = value

                    const updateAt = (object, path, value) => {
                        let o = object

                        for(let i = 0; i < path.length; i++) {
                            if(i < path.length - 1) {
                                if(!o[path[i]]) o[path[i]] = {}
                                o = o[path[i]]
                            }
                            else o[path[i]] = value
                        }
                    }

                    if(parsedValue === null && this.props.field.endsWith(".id")) mutationContext.data[this.props.field.split(".")[0]] = null
                    else updateAt(mutationContext.data, this.props.field.split("."), parsedValue)

                    const list = mutationContext.listeners || []

                    list.forEach(listener => listener())

                    setTimeout(() => this.setState({ saved: false }), 1000)
                } else {
                    this.setState({ saving: false, saved: false, error: true })
                }
            })
        }
    }

    render() {
        const InputElement = this.props.textArea ? TextArea : Input
        const mutationContext = this.props.mutationContext

        let value = null

        let disabled = this.props.disabled

        if(this.props.contextualDisabled) {
            disabled = this.props.contextualDisabled(this.props.mutationContext.data)
        }

        if(this.props.disabledContextualValue && disabled) {
            value = this.props.disabledContextualValue(mutationContext.data)

            if(this.props.format) value = this.props.format(value)
        } else if(this.props.contextualValue) {
            value = this.props.contextualValue(mutationContext.data)
        } else {
            value = ((this.state.isFocused && !this.props.readOnly && !this.props.date) ? this.state.value : (this.props.format ? this.props.format(this.state.value) : (this.props.date ? (this.state.value ? moment(this.state.value, "DD.MM.YYYY").format("DD.MM.YYYY") : "") : this.state.value)))
            if(value === undefined || value === null) value = ""
        }

        return <InputContainer disabled={disabled} height={this.props.height} textArea={this.props.textArea} gradientVisible={!this.state.isFocused && !this.props.noOverlay} adjustWidth={this.props.adjustWidth} margin={this.props.margin}>
            {this.props.label && <InputLabel textArea={this.props.textArea} value={(this.props.time && this.state.isFocused) ? "00:00" : (value !== undefined && value !== null && value !== "")}>{this.props.label}{this.props.required && <Required><i className="far fa-asterisk" /></Required>}</InputLabel>}
            <InputElement
                autoComplete="new-password"
                autoCorrect="false"
                noOverlay={this.props.noOverlay}
                autoCapitalize="false"
                spellCheck="false"
                height={this.props.height}
                readOnly={this.props.readOnly || this.props.date || this.state.saving || this.state.saved || this.props.contextualValue}
                onFocus={() => {
                    if(!disabled && !this.props.date) this.setState({ isFocused: true })
                }}
                onClick={() => {
                    if(!disabled && this.props.date) this.setState({ isFocused: true })
                }}
                onBlur={() => {
                    if(disabled || this.state.saving || this.state.saved || this.props.readOnly) return false

                    let value = this.state.value

                    if(this.props.time) {
                        if(value) {
                            if(value.indexOf(":") < 0) {
                                if(value.length === 1) value = `0${value}:00`
                                else if(value.length === 2) value = `${value}:00`
                                else if(value.length === 3) value = `${value.substring(0, 2)}:${value.substring(2, 3)}0`
                            } else if(value.indexOf(":") === 1) {
                                if(value.length === 2) value = `0${value}00`
                                else if(value.length === 3) value = `0${value}0`
                                else if(value.length === 4) value = `0${value}`
                            } else if(value.indexOf(":") === 2) {
                                if(value.length === 3) value = `${value}00`
                                else if(value.length === 4) value = `${value}0`
                            }

                            if(value.indexOf(":") !== 2) value = null
                            if(value.length !== 5) value = null
                            if((value.match(/:/g) || []).length > 1) value = null

                            let hour = parseInt(value.substring(0, 2), 10) || 0
                            let minute = parseInt(value.substring(3, 5), 10) || 0

                            if(hour > 23) {
                                hour = 23
                                minute = 59
                            }
                            if(minute > 59) minute = 59

                            value = `${pad(hour, 2)}:${pad(minute, 2)}`
                        } else value = null
                    } else if(this.props.float) {
                        if(value !== undefined && value !== null) {
                            if(isNaN(Number(value))) value = null
                        } else value = null
                    } else if(this.props.int) {
                        if(value !== undefined && value !== null) {
                            if(isNaN(Number(value))) value = null
                        } else value = null
                    }

                    if(this.props.onChange && this.props.time) this.props.onChange(value)
                    else if(this.props.onChange && this.props.float) this.props.onChange(value ? parseFloat(value) : null)

                    if(this.props.field && !this.props.date && value !== this.savedValue) {
                        this.mutate(mutationContext, value)
                    }

                    if(this.state.isFocused && !this.props.date) this.setState({ isFocused: false, value })
                }}
                value={value}
                onKeyDown={event => {
                    if(event.keyCode === 13 && this.props.onEnter) this.props.onEnter()
                }}
                onChange={value => {
                    let newValue = value.target.value || null

                    if(this.props.time) {
                        if(newValue) {
                            while(newValue.startsWith(":")) newValue = newValue.substring(1, newValue.length)
                            if(newValue.length > 5) newValue = newValue.substring(0, 5)
                            if(newValue.length === 4 && newValue.indexOf(":") < 0) newValue = `${newValue.substring(0, 2)}:${newValue.substring(2, 4)}`
                            newValue = newValue.replace(/[^0-9:]/g, "")
                            if((newValue.match(/:/g) || []).length > 1 || newValue.indexOf(":") > 2) newValue = this.state.value
                        }
                    } else if(this.props.float) {
                        if(newValue) {
                            newValue = this.props.allowNegative ? newValue.replace(/,/g, ".").replace(/[^0-9.-]/g, "").replace(/(?!^)-/g, '') : newValue.replace(/,/g, ".").replace(/[^0-9.]/g, "")

                            while(newValue.startsWith("0") && (newValue.length >= 2 && newValue.charAt(1) !== ".")) newValue = newValue.substring(1, newValue.length)

                            while(newValue.startsWith(".")) newValue = newValue.substring(1, newValue.length)
                            if((newValue.match(/\./g) || []).length > 1) newValue = this.state.value

                            if(isNaN(parseFloat(newValue)) && newValue !== "-") newValue = "0"
                        }
                    } else if(this.props.int) {
                        if(newValue) {
                            newValue = newValue.replace(/[^0-9]/g, "")

                            while(newValue.startsWith("0")) newValue = newValue.substring(1, newValue.length)

                            if(isNaN(parseInt(newValue, 10))) newValue = "0"

                            newValue = parseInt(newValue, 10)
                        }
                    }

                    if(this.props.maxLength) {
                        if(newValue) {
                            if(String(newValue).length > this.props.maxLength) {
                                newValue = String(this.state.value)
                                if(newValue.length > this.props.maxLength) newValue = newValue.substring(0, this.props.maxLength)
                            }

                            if(this.props.int) newValue = parseInt(newValue, 10)
                        }
                    }

                    this.setState({ value: newValue })
                    if(this.props.onChange && !this.props.time && !this.props.float) this.props.onChange(newValue)
                }}
                type={this.props.type} />
            {this.props.rightText && <InputRightText textArea={this.props.textArea}>{this.props.rightText}</InputRightText>}
            {this.props.icon && !this.state.error && !this.state.saved && !this.state.saving && <InputIcon textArea={this.props.textArea} value={this.state.value}><i className={`far fa-${this.props.icon}`} /></InputIcon>}
            {this.state.saved && <InputIcon value={true} textArea={this.props.textArea}><i style={{ color: "#3b97d3" }} className="far fa-check-circle" /></InputIcon>}
            {this.state.error && <InputIcon value={true} textArea={this.props.textArea}><i style={{ color: "#e74c3c" }} className="fas fa-exclamation-circle" /></InputIcon>}
            {this.state.saving && !this.state.error && !this.state.saved && <div style={{ position: "absolute", zIndex: 15, right: 0, bottom: 14 }}>
                <ClipLoader color="#3b97d3" size={12} />
            </div>}
            {this.props.date && this.state.value && !disabled && !this.props.readOnly && !this.props.required && !this.props.disableEmpty && <DeleteValue onClick={() => {
                this.setState({ value: null })
                if(this.props.onChange) this.props.onChange(null)

                if(this.props.field) this.mutate(mutationContext, null)
            }}><i className="far fa-times" /></DeleteValue>}
            {this.props.date && !this.props.readOnly && this.state.isFocused && <Dropdown blur={() => this.setState({ isFocused: false })} onChange={day => {
                this.setState({ value: day, isFocused: false })

                if(this.props.field) {
                    this.mutate(mutationContext, moment(day, "DD.MM.YYYY").toDate())
                }

                if(this.props.onChange) this.props.onChange(day)
            }} />}
        </InputContainer>
    }
}

class InputClassWrap extends Component {
    render() {
        return <MutationContext.Consumer>
            {mutationContext => <InputClass {...this.props} mutationContext={mutationContext}/> }
        </MutationContext.Consumer>
    }
}

InputClassWrap.MutationProvider = MutationContext.Provider
InputClassWrap.MutationConsumer = MutationContext.Consumer

export default InputClassWrap
