import React, { Component } from "react"
import {Modal, Input, Button } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { name: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ name: null, loading: false })

            this.file = null
        }}>
            <Modal.Head title={i18next.t("page.private.workFlows.work-Flows.createWorkflowModal.titleForm")} icon="project-diagram" />
            <Modal.Body padding={24}>
                <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.workFlows.work-Flows.createWorkflowModal.name")} adjustWidth />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name} title={i18next.t("page.private.workFlows.work-Flows.createWorkflowModal.exitButton")} icon="project-diagram" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!) {
                            createWorkflow(name: $name)
                        }
                    `,
                    variables: {
                        name: this.state.name
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createWorkflow) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
