import React, { Component } from 'react'
import { Modal } from 'scanmetrix-components'
import styled from "styled-components"

const FullSizeImage = styled.img`
  max-width: 768px;
  max-height: 768px;
  object-fit: cover;
  display: block;
`

export default class extends Component {
    state = { url: null }

    render() {
        return <Modal instance={this.props.instance} initialize={url => this.setState({ url })}>
            <Modal.Head title="Bild ansehen" icon="images" />
            <Modal.Body>
                <FullSizeImage src={this.state.url} />
            </Modal.Body>
        </Modal>
    }
}