import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { userrId: null, role: null, teamId: null, users: [], bannedIds: [], loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                {
                    Users {
                        nodes {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                }
            `
        }).then(data => this.setState({ users: data.data.Users.nodes }))
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={team => this.setState({ userId: null, role: null, teamId: team.id, bannedIds: team.users.map(us => us.user.id), loading: false })}>
            <Modal.Head title={i18next.t("page.private.users.sectionItem.createTeamUserModal.title")} icon="user-plus" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Select
                        label={i18next.t("page.private.users.sectionItem.createTeamUserModal.user")}
                        icon="user"
                        adjustWidth
                        required
                        value={this.state.userId}
                        noUnselect
                        items={this.state.users.filter(user => !this.state.bannedIds.includes(user.id)).map(user => ({ key: user.id, title: `${user.firstName} ${user.lastName} / ${user.email}` }))}
                        onChange={userId => this.setState({ userId })}
                    />
                    <Input required value={this.state.role} onChange={role => this.setState({ role })} icon="tag" label={i18next.t("page.private.users.sectionItem.createTeamUserModal.role")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.role || !this.state.userId} title={i18next.t("page.private.users.sectionItem.createTeamUserModal.exitButton")} icon="user-plus" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($role: String!, $userId: ID!, $teamId: ID!) {
                            createTeamUser(role: $role, userId: $userId, teamId: $teamId)
                        }
                    `,
                    variables: {
                        role: this.state.role,
                        userId: this.state.userId,
                        teamId: this.state.teamId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createTeamUser) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
