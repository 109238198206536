import styled from "styled-components"

export default styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: 1fr;
  
  
  




  #sidebarOpener{
    position: absolute;
   
    border: 1px solid;
 
    padding: 4px;
    height: 30px;
    border: rgba(0, 0, 0, 0.05);
    background-color: #f8f8f8;
    color: rgb(59, 151, 211);
    &:hover{
      background-color: white;
      cursor: pointer;
    }
    

   #sidebarOpenerIcon{
     padding: 2px;
   }
  }



  .disabled-style {
    color: #808080; 
    background-color: #e0e0e0; 
    border-color: #d0d0d0;
    cursor: not-allowed; 
    opacity: 0.6; 
    pointer-events: none;
    border-radius: 16px;
    
   }



   .disabled-style:hover{
      .disabled-style{
        border: 1px solid red;
        padding: 1px
      }
   }
`
