import React, { useCallback, useMemo } from 'react'
import isHotkey from 'is-hotkey'
import { slateToHtml, htmlToSlate, slateToHtmlConfig, htmlToSlateConfig } from '@slate-serializers/html'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import styled from "styled-components"
import {
    Editor,
    Transforms,
    createEditor,
    Descendant,
    Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'

import { Button, Icon, Toolbar } from './components'

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const EditableContainer = styled.div`
    > div > p {
        outline: none;
    }
    
    > div {
         outline: none;
        padding: 16px;
        box-sizing: border-box;
        height: ${props => (props.height || 300) + "px"};
        width: ${props => props.width ? (props.width + "px") : "auto"};
        overflow-y: auto;
    }
`

const RichTextExample = (props) => {
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])

    const initialValue = htmlToSlate(props.value || "<p></p>", {
        ...htmlToSlateConfig,
        filterWhitespaceNodes: true,
        convertBrToLineBreak: true,
        textTags: {
            ...htmlToSlateConfig.textTags,
            b: () => ({ bold: true }),
            q: () => ({ q: true }),
            italic: () => ({ italic: true }),
            underline: () => ({ underline: true }),
            h1: () => ({ h1: true }),
            h2: () => ({ h2: true }),
        }
    })

    return (
        <Slate editor={editor} onValueChange={value => {
            const html = slateToHtml(value, { ...slateToHtmlConfig, markMap: { ...slateToHtmlConfig.markMap, h1: ["h1"], h2: ["h2"], q: ["q"] } }).replace(/(?:\r\n|\r|\n)/g, '<br>')

            console.log(html)

            if(props.onChange) props.onChange(html);
        }} initialValue={initialValue}>
            {!props.disabled && !props.readOnly && <Toolbar>
                <MarkButton format="bold" icon="bold" />
                <MarkButton format="italic" icon="italic" />
                <MarkButton format="underline" icon="underline" />
                <MarkButton format="code" icon="code" />
                <MarkButton format="h1" icon="h1" />
                <MarkButton format="h2" icon="h2" />
                <MarkButton format="q" icon="quotes" />
                {/*<MarkButton format="numbered-list" icon="list-ol" />
                <MarkButton format="bulleted-list" icon="list" />
                <BlockButton format="left" icon="format_align_left" />
                <BlockButton format="center" icon="format_align_center" />
                <BlockButton format="right" icon="format_align_right" />
                <BlockButton format="justify" icon="format_align_justify" />*/}
            </Toolbar>}
            <EditableContainer height={props.height}>
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={props.placeholder || "Geben Sie hier Ihren Text ein..."}
                    readOnly={props.readOnly || props.disabled}
                    onKeyDown={event => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                    }}
                />
            </EditableContainer>
        </Slate>
    )
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align }
    switch (element.type) {
        case 'q':
            return (
                <q style={style} {...attributes}>
                    {children}
                </q>
            )
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'h1':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            )
        case 'h2':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    if (leaf["h1"]) {
        children = <h1>{children}</h1>
    }

    if (leaf["h2"]) {
        children = <h2>{children}</h2>
    }

    if (leaf["q"]) {
        children = <q>{children}</q>
    }

    if (leaf["numbered-list"]) {
        children = <ol>{children}</ol>
    }

    if (leaf["list-item"]) {
        children = <li>{children}</li>
    }

    return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            )}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}

const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}

export default RichTextExample
