import React, { Component } from "react"
import { Page, Section, Breadcrumbs, Button, GridElement, Table } from "scanmetrix-components"
import styled from "styled-components"
import Avatar from "react-avatar";
import i18next from "i18next";
import CreateTeamModal from "./modals/CreateTeamModal"
import CreateRoleModal from "./modals/CreateRoleModal"
import CreateTeamUserModal from "./modals/CreateTeamUserModal"
import UpdateServiceDeskUserModal from "./modals/UpdateServiceDeskUserModal"
import UpdateUserModal from "./modals/UpdateUserModal";
import DeleteTeamModal from "./modals/DeleteTeamModal";
import DeleteTeamUserModal from "./modals/DeleteTeamUserModal";

const UserView = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 25px;
  column-gap: 25px;
  
  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const UserBox = styled.div`
  width: 100%;
  height: 500px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  box-sizing: border-box;
  transition: all 100ms ease;
  user-select: none;
  
  &:hover {
    transform: scale(1.025);
  }
  
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    
    .banner {
      width: 100%;
      height: 125px;
      border-radius: 5px 5px 0 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  
    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 125px;
      border: 5px solid white;
      box-shadow: 0 0 15px rgba(0, 0, 0, .15);
      box-sizing: border-box;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      .avatarInner {
          border-radius: 100%;
          overflow: hidden;
      }
    }
  }
  
  .data-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .name-view {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 25px 0;
      
      .name-up {
        
        .surname {
          color: #3B97D3;
        }
      }
      
      .name {
        font-weight: bold;
      }
      
      .status {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        
        i {
          margin-right: 8px;
          font-size: 10px;
          
          &.green {
              color: #2ECC71;
          }
          
          &.red {
              color: #e74c3c;
          }
        }
        
        .status-label {
          color: #e74c3c;
          
          &.green {
            color: #2ECC71;
          }
        }
      }
    }
    
    .contacts {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      
      .row-view {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 40px;
        
        .contact-info {
            line-height: 24px;
        }
        
        i {
          width: 20px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }
        
        .separator {
          margin: 0 10px;
          height: 100%;
          width: 2px;
          background: rgba(0, 0, 0, .15);
          flex-shrink: 0;
        }
      }
    }
    
    .button-field {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      
      .button {
        margin-top: 25px;
        padding: 5px 10px;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 125px;
        background: linear-gradient(140deg, #3B97D3, #81C6F3);
        box-shadow: 0 2px 8px -3px #3B97D3;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 250ms ease;
        
        &:hover {
          opacity: 0.75;
        }
        
        i {
          margin-right: 8px;
          color: white;
        }
        
        p {
          color: white;
          user-select: none;
        }
      }
    }
  }
`

const AlignRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  
  .search-bar {
    width: 100%;
    margin-right: 25px;
    
    input {
      width: 100%;
      height: 48px;
      padding: 0 32px;
      font-size: 1.1em;
      border-radius: 125px;
      box-sizing: border-box;
      outline: none;
      border: none;
      box-shadow: 0 0 5px rgba(0, 0 , 0, .15);
      
      &::placeholder {
        color: rgba(0, 0 , 0 , .5);
      }
    }
  }
`

const Sort = styled.div`
  width: 25%;
  display: inline-flex;
  margin-left: auto;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  user-select: none;
  
  b {
      margin: 0 8px;
      color: #3B97D3;
  }
`

class Users extends Component {
    render() {
        return<>
            {this.props.userMap.map(( item, index ) => {
                return<UserBox key={index}>
                    <div className='header'>
                        <div className='banner' style={{ backgroundImage: 'linear-gradient(45deg,rgba(12,16,22,0.76),rgba(31,36,44,0.78)),url(/background2.jpg)' }} />
                        <div className='avatar'><Avatar size="90" email={scanmetrix.disableGravatar ? null : item.email} name={`${item.firstName} ${item.lastName}`} className="avatarInner" /></div>
                    </div>
                    <div className='data-view'>
                        <div className='name-view'>
                            <p className='name-up'>{i18next.t(`defaults.salutation.${item.salutation}`)} <b className='surname'>{item.lastName}</b></p>
                            <p className='name'>{item.firstName}</p>
                            <div className='status'>
                                <i className={`fas fa-circle ${scanmetrix.onlineUsers.includes(parseInt(item.id)) ? "green" : "red"}`} />
                                <p className={'status-label ' + (scanmetrix.onlineUsers.includes(parseInt(item.id)) ? "green" : "")}>{scanmetrix.onlineUsers.includes(parseInt(item.id)) ? "Online" : (item.lastActivity ? `Zuletzt ${moment(item.lastActivity).fromNow()} gesehen` : "Offline")}</p>
                            </div>
                        </div>
                        <div className='contacts'>
                            <div className='row-view'>
                                <i className='far fa-envelope' />
                                <div className='separator' />
                                <p className='contact-info'>{item.email}</p>
                            </div>
                            <div className='row-view'>
                                <i className='far fa-phone' />
                                <div className='separator' />
                                <p className='contact-info'>{item.profileSettings?.landline || "Keine Angabe"}</p>
                            </div>
                            <div className='row-view'>
                                <i className='far fa-mobile' />
                                <div className='separator' />
                                <p className='contact-info'>{item.profileSettings?.mobile || "Keine Angabe"}</p>
                            </div>
                            <div className='row-view'>
                                <i className='far fa-fax' />
                                <div className='separator' />
                                <p className='contact-info'>{item.profileSettings?.fax || "Keine Angabe"}</p>
                            </div>
                            <div className='row-view'>
                                <i className='far fa-user-tie' />
                                <div className='separator' />
                                <p className='contact-info'>{item.position || "Keine Angabe"}</p>
                            </div>
                        </div>
                        {/*<div className='button-field'>
                            <div className='button'>
                                <i className='far fa-user' />
                                <p>Profil besuchen</p>
                            </div>
                        </div>*/}
                    </div>
                </UserBox>
            })}
        </>
    }
}

export default class extends Component {
    state = { users: [], teams: [], roles: null, serviceDeskUsers: null, subsidiaries: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetchServiceDeskUsers = this.fetchServiceDeskUsers.bind(this)

        this.fetch()
        this.fetchServiceDeskUsers()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Teams {
                        nodes {
                            id
                            name
                            number
                            users {
                                id
                                role
                                isAdmin
                                user {
                                    id
                                    email
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                    Roles {
                        nodes {
                            id
                            name
                            icon
                            users {
                                id
                            }
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                streetName
                                streetNumber
                                additional
                                postalCode
                                city
                                country
                                inline
                            }
                        }
                    }
                    Users {
                        nodes {
                            id
                            salutation
                            firstName
                            lastName
                            email
                            position
                            lastActivity
                            profileSettings
                            language
                            active
                            emailVerified
                            approved
                            subsidiaryWildcard
                            modules {
                                id
                                module
                            }
                            subsidiaries {
                                id
                                subsidiary {
                                    id
                                }
                            }
                            role {
                                id
                                name
                                icon
                            }
                        }
                    }
                }
            `
        }).then(data => {
            const users = data.data.Users.nodes
            const teams = data.data.Teams.nodes
            const roles = data.data.Roles.nodes
            const subsidiaries = data.data.Subsidiaries.nodes

            this.setState({ users, teams, roles, subsidiaries })
        })
    }

    fetchServiceDeskUsers() {
        return fetch(scanmetrix.backendURL + "/service-desk-users", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ serviceDeskUsers: data })
        })
    }

    render() {
        if(!this.state.users) return null

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "user",
                    title: i18next.t("page.private.users.breadCrumbsTitle")
                }
            ]} />
            <CreateRoleModal instance={ref => this.createRoleModal = ref} refresh={this.fetch} />
            <CreateTeamModal instance={ref => this.createTeamModal = ref} refresh={this.fetch} />
            <CreateTeamUserModal instance={ref => this.createTeamUserModal = ref} refresh={this.fetch} />
            <DeleteTeamModal instance={ref => this.deleteTeamModal = ref} refresh={this.fetch} />
            <DeleteTeamUserModal instance={ref => this.deleteTeamUserModal = ref} refresh={this.fetch} />
            <UpdateServiceDeskUserModal instance={ref => this.updateServiceDeskUserModal = ref} refresh={this.fetchServiceDeskUsers} subsidiaries={this.state.subsidiaries} />
            <UpdateUserModal instance={ref => this.updateUserModal = ref} refresh={this.fetch} subsidiaries={this.state.subsidiaries} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.users.sectionItem.user.title")} icon="user" amount={this.state.users.length}>
                    <GridElement styled title="Benutzer" icon="user" rightContent={<div><i className="fa-duotone fa-info-circle" style={{ marginRight: 8, color: "#3b97d3" }} />Benutzer können sich selbst registrieren.</div>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.users.sectionItem.user.table.access"), width: "7.5%", column: "access" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.salutation"), width: "5%", column: "salutation" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.display"), width: "12.5%", column: "display" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.email"), width: "12.5%", column: "email" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.position"), width: "10%", column: "position" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.landline"), width: "10%", column: "landline" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.mobile"), width: "10%", column: "mobile" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.fax"), width: "10%", column: "fax" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.role"), width: "10%", column: "role" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.language"), width: "5%", column: "language" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.lastActivity"), width: "7.5%", column: "lastActivity" }
                            ]}
                            onItemClick={user => {
                                if(scanmetrix.session.administrative && user.raw.id !== scanmetrix.session.id) this.updateUserModal.open(user.raw)
                            }}
                            items={this.state.users ? this.state.users.sort((a, b) => (b.lastActivity ? moment(b.lastActivity).valueOf() : 0) - (a.lastActivity ? moment(a.lastActivity).valueOf() : 0)).map(user => ({
                                ...user,
                                raw: user,
                                access: (!user.approved || !user.emailVerified || !user.active) ? <><i className="fa-duotone red fa-lock" />Gesperrt</> : <><i className="fa-duotone blue fa-lock-open" />Möglich</>,
                                salutation: i18next.t(`defaults.salutation.${user.salutation}`),
                                landline: user.profileSettings?.landline,
                                mobile: user.profileSettings?.mobile,
                                fax: user.profileSettings?.fax,
                                lastActivity: user.lastActivity ? moment(user.lastActivity).fromNow() : i18next.t("page.private.users.sectionItem.user.lastActivityNeverBefore"),
                                role: user.role ? <div><i className={`fa-duotone fa-${user.role.icon}`} /> {user.role.name}</div> : i18next.t("page.private.users.sectionItem.user.roleNone"),
                                display: <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : user.email} name={`${user.firstName} ${user.lastName}`} className="avatarInner" />
                                    </div>
                                    {user.firstName} {user.lastName}
                                </div>
                            })) : null}
                        />
                    </GridElement>
                    {/*<AlignRight>
                        <div className='search-bar'>
                            <input placeholder='Nach Benutzer suchen...' />
                        </div>
                        <Sort>
                            Sortieren nach <b>Alle Benutzer</b><i className="far fa-arrow-down" />
                        </Sort>
                    </AlignRight>*/}
                    <UserView>
                        {/*<Users
                            userMap={this.state.users.map(user => ({
                                ...user,
                                banner: 'https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80'
                            }))}
                        />*/}

                    </UserView>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.users.sectionItem.serviceDesk.title")} icon="bell-concierge" amount={this.state.serviceDeskUsers?.length || 0}>
                    <GridElement styled title={i18next.t("page.private.users.sectionItem.serviceDesk.gridElementTitle")} icon="bell-concierge" rightContent={<div><i className="fa-duotone fa-info-circle" style={{ marginRight: 8, color: "#3b97d3" }} />Benutzer können sich selbst registrieren.</div>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.active"), width: "5%", column: "activeStyled" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.avatar"), width: "5%", column: "avatar" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.firstname"), width: "12.5%", column: "firstName" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.lastname"), width: "12.5%", column: "lastName" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.email"), width: "17.5%", column: "email" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.propertiesRealEstate"), width: "7.5%", column: "subsidiariesStyled" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.right"), width: "30%", column: "permissions" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.userSince"), width: "7.5%", column: "createdAt" }
                            ]}
                            onItemClick={user => {
                                if(scanmetrix.checkPermission("ServiceDeskUser") >= 2) this.updateServiceDeskUserModal.open(user)
                            }}
                            items={this.state.serviceDeskUsers ? this.state.serviceDeskUsers.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(user => {
                                const hasAllSubsidiaries = user.enableSubsidiaryWhitelist ? (user.subsidiaries.length === this.state.subsidiaries.length) : true

                                return ({
                                    ...user,
                                    activeStyled: <div style={{ color: "white", background: user.active ? "#16a085" : "#e74c3c", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{user.active ? i18next.t("page.private.users.sectionItem.serviceDesk.userActiveYes") : i18next.t("page.private.users.sectionItem.serviceDesk.userActiveNo")}</div>,
                                    subsidiariesStyled: <div style={{ color: "white", background: hasAllSubsidiaries ? "#16a085" : "#3b97d3", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{hasAllSubsidiaries ? i18next.t("page.private.users.sectionItem.serviceDesk.allSubsidiaries") : `${user.subsidiaries.length} von ${this.state.subsidiaries.length}`}</div>,
                                    permissions: <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div style={{ whiteSpace: "nowrap", color: "white", background: user.featureBlacklist.includes("meters") ? "#e74c3c" : "#16a085", padding: "4px 8px", display: "inline-flex", alignItems: "center", fontSize: 14 }}><i className="fa-duotone fa-monitor-waveform" />{i18next.t("page.private.users.sectionItem.serviceDesk.permission.counter")}</div>
                                        <div style={{ whiteSpace: "nowrap", color: "white", background: user.featureBlacklist.includes("buildingInspections") ? "#e74c3c" : "#16a085", padding: "4px 8px", display: "inline-flex", alignItems: "center", fontSize: 14 }}><i className="fa-duotone fa-person-walking" />{i18next.t("page.private.users.sectionItem.serviceDesk.permission.inspections")}</div>
                                        <div style={{ whiteSpace: "nowrap", color: "white", background: user.featureBlacklist.includes("data") ? "#e74c3c" : "#16a085", padding: "4px 8px", display: "inline-flex", alignItems: "center", fontSize: 14 }}><i className="fa-duotone fa-qrcode" />{i18next.t("page.private.users.sectionItem.serviceDesk.permission.dataInsight")}</div>
                                        <div style={{ whiteSpace: "nowrap", color: "white", background: user.featureBlacklist.includes("ticketWithoutInspection") ? "#e74c3c" : "#16a085", padding: "4px 8px", display: "inline-flex", alignItems: "center", fontSize: 14 }}><i className="fa-duotone fa-comments-alt" />{i18next.t("page.private.users.sectionItem.serviceDesk.permission.ticketWithoutInspection")}</div>
                                    </div>,
                                    avatar: <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                            <Avatar size="32" email={scanmetrix.disableGravatar ? null : user.email} name={`${user.firstName} ${user.lastName}`} className="avatarInner" />
                                        </div>
                                    </div>,
                                    createdAt: moment(user.createdAt).format("DD.MM.YYYY")
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>

                <Section.Item title={i18next.t("page.private.users.sectionItem.workGroup.title")} icon="users-crown" amount={this.state.teams.length}>
                    <Button disabled={scanmetrix.checkPermission("Team") < 2} title={i18next.t("page.private.users.sectionItem.workGroup.button")} icon="users-crown" thick onClick={() => this.createTeamModal.open()} />
                    <div style={{ marginTop: 16 }}>
                        {this.state.teams.sort((a, b) => b.id - a.id).map((team, index) => <Team key={index} deleteUser={userId => this.deleteTeamUserModal.open(userId)} delete={() => this.deleteTeamModal.open(team.id)} data={team} createTeamUser={() => this.createTeamUserModal.open(team)} />)}
                    </div>
                </Section.Item>
                <Section.Item disabled={!scanmetrix.session.administrative} title={i18next.t("page.private.users.sectionItem.role.title")} icon="shield-halved" amount={this.state.roles ? this.state.roles.length : 0}>
                    <GridElement styled title={i18next.t("page.private.users.sectionItem.role.gridElementTitle")} icon="shield-halved" rightContent={<Button thick icon="shield-halved" title={i18next.t("page.private.users.sectionItem.role.button")} onClick={() => this.createRoleModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.users.sectionItem.role.table.name"), width: "80%", column: "name" },
                                { title: i18next.t("page.private.users.sectionItem.role.table.user"), width: "20%", column: "users" }
                            ]}
                            onItemClick={role => this.props.history.push(`/role/${role.id}`)}
                            items={this.state.roles ? this.state.roles.map(role => ({
                                ...role,
                                name: <><i className={`fa-duotone fa-${role.icon}`} /> {role.name}</>,
                                users: <div style={{ color: "white", background: role.users.length > 0 ? "#3b97d3" : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{role.users.length} {i18next.t("page.private.users.sectionItem.role.table.userCount")}</div>,
                            })) : null}
                            contextItems={[
                                { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: role => window.open(`/role/${role.id}`, '_blank')}
                            ]}
                        />
                    </GridElement>
                </Section.Item>
            </Section>
        </Page>
    }
}

const StyledTeam = styled.div`
    background: white;
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 4px 8px -2px rgb(0 0 0 / 15%);
    box-sizing: border-box;
    margin-bottom: 16px;
  
    &:last-child {
        margin-bottom: 0;
    }
  
    .title {
        font-size: 18px;
        margin-bottom: 24px;
      
        b {
            padding: 4px 8px;
            color: white;
            background: #3b97d3;
            border-radius: 3px;
            margin-right: 8px;
            overflow: hidden;
        }
    }
  
    .users {
        display: flex;
        overflow-x: scroll;
        padding-bottom: 16px;
      
        &::-webkit-scrollbar {
            height: 5px;
        }
      
        &::-webkit-scrollbar-track {
            background: #e5e5e5;
        }
      
        &:hover {
            .user {
                opacity: 0.4;
            }
        }
        
        .user {
            height: 96px;
            position: relative;
            border-radius: 96px 0 0 96px;
            margin-left: 16px;
            cursor: pointer;
            display: flex;
            transition: opacity 0.4s, width 0.4s;
          
            &:hover {
                opacity: 1;
            }
          
            .admin {
                position: absolute;
                left: 69px;
                bottom: 0;
                width: 24px;
                height: 24px;
                border: 3px solid white;
                background: #3b97d3;
                color: white;
                z-index: 3;
                border-radius: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
            }
          
            .info {
                height: 96px;
                box-sizing: border-box;
                padding: 16px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                white-space: nowrap;
                transition: max-width 0.4s, margin-left 0.4s, opacity 0.4s;
                opacity: 0;
                margin-left: 0;
                max-width: 0;
              
                .email {
                    font-size: 12px;
                    opacity: 0.75;
                }
              
                .role {
                    
                }
            }

            &:hover {
                .info {
                    max-width: 250px;
                    margin-left: 16px;
                    opacity: 1;
                }
            }

            .avatarInner {
                border-radius: 96px;
                height: 96px;
                width: 96px;
                overflow: hidden;
                z-index: 2;
                position: relative;
                flex-shrink: 0;
            }
        }
      
        .addUser {
            height: 96px;
            width: 96px;
            border-radius: 96px;
            border: 2px solid rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            flex-direction: column;
            user-select: none;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            transition: border-color 0.3s;
            box-sizing: border-box;
            padding: 16px;
          
            i {
                margin-top: 8px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.25);
                transition: color 0.3s;
            }
          
            &:hover {
                border-color: rgba(0, 0, 0, 0.5);
              
                i {
                    color: rgba(0, 0, 0, 0.75);
                }
            }
        }
    }
`

class Team extends Component {
    render() {
        const team = this.props.data

        return <StyledTeam>
            <p className="title">{scanmetrix.checkPermission("Team") > 3 && <i onClick={this.props.delete} className="fa-duotone fa-trash" style={{ width: 32, cursor: "pointer" }} />}<b>{team.number}</b> {team.name}</p>
            <div className="users">
                {scanmetrix.checkPermission("TeamUser") >= 2 && <div className="addUser" onClick={() => this.props.createTeamUser()}>
                   {i18next.t("page.private.users.sectionItem.workGroup.member")}
                    <i className="far fa-user-plus" />
                </div>}
                {team.users.map((user, index) => <div className="user" key={index}>
                    <Avatar size="96" email={scanmetrix.disableGravatar ? null : user.user.email} name={`${user.user.firstName} ${user.user.lastName}`} className="avatarInner" />
                    {user.isAdmin && <div className="admin"><i className="far fa-crown" /></div>}
                    <div className="info">
                        <p className="name">{scanmetrix.checkPermission("TeamUser") > 3 && <i onClick={() => this.props.deleteUser(user.id)} className="fa-duotone fa-trash" style={{ width: 24, cursor: "pointer" }} />}{user.user.firstName} {user.user.lastName}</p>
                        <p className="email">{user.user.email}</p>
                        <p className="role">{user.role}</p>
                    </div>
                </div>)}
            </div>
        </StyledTeam>
    }
}
