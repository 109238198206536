import styled from "styled-components"

export default styled.div`
  user-select: none;
  
    h2 {
      font-weight: 300;
      margin-bottom: 32px;
      text-align: center;
      font-size: 1.3em;
    }
    
    img {
      height: 128px;
      margin: 0 auto;
      display: block;
      filter: grayscale(100%);
      opacity: 0.4;
      -webkit-user-drag: none;
    }
`
