import React, { Component } from "react"

import SideNavigation from "./side/"
import { Button, Modal } from "scanmetrix-components"
import Script from "react-load-script";

import changelog from "./changelog.json"
const SDK_URL = "https://cdn.nolt.io/widgets.js"
const BOARD_URL = "https://scanmetrix-cockpit.nolt.io"
import styled from "styled-components"

import i18next from "i18next"

const Changelog = styled.div`
    width: 100%;
    max-width: 600px;
    max-height: 768px;
    overflow-y: scroll;
    padding-right: 24px;
  
    .group {
        margin-bottom: 64px;
      
        &:last-child {
            margin-bottom: 0;
        }
      
        .version {
            font-size: 1.3em;
        }
      
        .date {
            font-size: 1.1em;
            opacity: 0.75;
            font-style: italic;
        }
      
        .description {
            margin: 24px 0;
            line-height: 1.7em;
        }
      
        .categories {
            .category {
                margin-top: 24px;
              
                .categoryTitle {
                    font-size: 1.2em;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    color: #3B97D3;
                    font-weight: bold;
                    margin-bottom: 8px;
                  
                    &::after {
                        display: block;
                        height: 1px;
                        content: " ";
                        width: 100%;
                        margin-left: 16px;
                        background: rgba(0, 0, 0, 0.25);
                    }
                    
                    i {
                        width: 32px;
                        flex-shrink: 0;
                    }
                }
              
                .items {
                    list-style-type: square;
                    box-sizing: border-box;
                    width: 100%;
                    padding-left: 32px;
                  
                    li {
                      margin-bottom: 6px;
                      line-height: 1.7em;
                    }
                }
            }
        }
    }
`

const RotateIcon = styled.i`
  transform: rotate(-44deg);
`;

export default class extends Component {
    handleScriptCreate() {
        console.log("script created");
    }

    handleScriptError() {
        console.log("script error");
    }

    handleScriptLoad() {
        console.log("script loaded");
        const { nolt } = window;

        // Option 1:
        // Render an <a data-nolt="modal" /> element

        // Option 2:
        nolt("init", { selector: ".noltFeedback", url: BOARD_URL });

        // Single Sign-On
        nolt("identify");
    }

    render() {
        return <React.Fragment>
            <Modal minWidth="600px" instance={ref => this.changelogModal = ref}>
                <Modal.Head title="Changelog" icon="list" />
                <Modal.Body padding={24}>
                    <Changelog>
                        {changelog.map((item, index) => <div className="group" key={index}>
                            <p className="version">{i18next.t("navigation.indexNavigation.version")} {item.version}</p>
                            <p className="date">{item.date}</p>
                            <p className="description">{item.description}</p>
                            <div className="categories">
                                {item.categories.map((category, index) => <div className="category" key={index}>
                                    <p className="categoryTitle"><i className={`far fa-${category.icon}`} />{category.title}</p>
                                    <ul className="items">
                                        {category.items.map((item, index) => <li key={index}>{item}</li>)}
                                    </ul>
                                </div>)}
                            </div>
                        </div>)}
                    </Changelog>
                </Modal.Body>
                <Modal.Footer buttons={instance => <Button secondary thick title="Schließen" icon="times" onClick={() => instance.close()} />} />
            </Modal>
            <Modal minWidth="600px" instance={ref => this.feedbackModal = ref}>
                <Modal.Head title="Feedback" icon="megaphone" />
                <Modal.Body padding={24}>
                    <Script
                        url={SDK_URL}
                        onCreate={this.handleScriptCreate.bind(this)}
                        onError={this.handleScriptError.bind(this)}
                        onLoad={this.handleScriptLoad.bind(this)}
                    />
                    <Feedback>
                        <div className="option noltFeedback" onClick={() => this.feedbackModal.close()}>
                            <i className="fa-duotone fa-face-party" />
                            <div className="right">
                                <p className="title">{i18next.t("navigation.indexNavigation.feedback.publicRoadMap.title")}</p>
                                <p className="description">{i18next.t("navigation.indexNavigation.feedback.publicRoadMap.description")}</p>
                            </div>
                        </div>
                        <div className="option" onClick={() => {
                            window.open("https://calendly.com/scanmetrixfm/sprechstunde-kunden", "_blank")
                        }}>
                            <i className="fa-duotone fa-screen-users" />
                            <div className="right">
                                <p className="title">{i18next.t("navigation.indexNavigation.feedback.openConsultationHours.title")}</p>
                                <p className="description">{i18next.t("navigation.indexNavigation.feedback.openConsultationHours.description")}</p>
                            </div>
                        </div>
                        <div className="option" onClick={() => {
                            this.feedbackModal.close();
                            if (window.Intercom) {

                                Intercom('showNewMessage');
                            } else {
                                console.error('Intercom widget is not initialized');
                            }
                        }}>
                            <i className="fa-duotone fa-comments" />
                            <div className="right">
                                <p className="title">{i18next.t("navigation.indexNavigation.feedback.directMessage.title")}</p>
                                <p className="description">{i18next.t("navigation.indexNavigation.feedback.directMessage.description")}</p>
                            </div>
                        </div>
                        <div className="option" onClick={() => {
                            this.feedbackModal.close()
                            Intercom('showSpace', 'help')
                        }}>
                            <i className="fa-duotone fa-question-circle" />
                            <div className="right">
                                <p className="title">{i18next.t("navigation.indexNavigation.feedback.helpArea.title")}</p>
                                <p className="description">{i18next.t("navigation.indexNavigation.feedback.helpArea.description")}</p>
                            </div>
                        </div>
                        <div className="option" onClick={() => {
                            this.props.history.push("/tours")
                        }}>
                            <RotateIcon className="fa-duotone fa-presentation-screen" />
                            <div className="right">
                                <p className="title">{i18next.t("navigation.indexNavigation.feedback.tours.title")}</p>
                                <p className="description">{i18next.t("navigation.indexNavigation.feedback.tours.description")}</p>
                            </div>
                        </div>
                    </Feedback>
                </Modal.Body>
            </Modal>
            <Modal minWidth="600px" instance={ref => this.languageModal = ref}>
                <Modal.Head title="Sprache ändern" icon="globe" />
                <Modal.Body padding={24}>
                    <Script
                        url={SDK_URL}
                        onCreate={this.handleScriptCreate.bind(this)}
                        onError={this.handleScriptError.bind(this)}
                        onLoad={this.handleScriptLoad.bind(this)}
                    />
                    <Feedback>
                        <div className="option" onClick={() => {
                            scanmetrix.client.mutate({
                                mutation: scanmetrix.gql`
                                mutation($language: String!) {
                                    updateLanguage(language: $language)
                                }
                            `,
                                variables: {
                                    language: "de_DE"
                                }
                            }).then(result => {
                                if(result.data.updateLanguage) {
                                    scanmetrix.session.language = "de_DE"
                                    location.reload()
                                }
                            })
                        }}>
                            <i className="fa-duotone fa-globe" />
                            <div className="right">
                                <p className="title">Deutsch</p>
                                <p className="description">Systemsprache zu Deutsch ändern</p>
                            </div>
                        </div>
                        <div className="option" onClick={() => {
                            scanmetrix.client.mutate({
                                mutation: scanmetrix.gql`
                                mutation($language: String!) {
                                    updateLanguage(language: $language)
                                }
                            `,
                                variables: {
                                    language: "en_US"
                                }
                            }).then(result => {
                                if(result.data.updateLanguage) {
                                    scanmetrix.session.language = "en_US"
                                    location.reload()
                                }
                            })
                        }}>
                            <i className="fa-duotone fa-globe" />
                            <div className="right">
                                <p className="title">English</p>
                                <p className="description">Change system language to english</p>
                            </div>
                        </div>
                    </Feedback>
                </Modal.Body>
            </Modal>
            <Modal minWidth="600px" instance={ref => this.perspectiveModal = ref}>
                <Modal.Head title="Perspektive ändern" icon="eye" />
                <Modal.Body padding={24}>
                    <Script
                        url={SDK_URL}
                        onCreate={this.handleScriptCreate.bind(this)}
                        onError={this.handleScriptError.bind(this)}
                        onLoad={this.handleScriptLoad.bind(this)}
                    />
                    <Feedback>
                        <div className="option" onClick={() => {
                            scanmetrix.client.mutate({
                                mutation: scanmetrix.gql`
                                mutation($perspective: String!) {
                                    updatePerspective(perspective: $perspective)
                                }
                            `,
                                variables: {
                                    perspective: "facilityManagement"
                                }
                            }).then(result => {
                                if(result.data.updatePerspective) {
                                    scanmetrix.session.perspective = "facilityManagement"
                                    this.perspectiveModal.close()
                                    if(this.props.history.location.pathname === "/dashboard") this.props.history.replace("/reload")
                                } this.setState({ showPerspectives: false })
                            })
                        }}>
                            <i className="fa-duotone fa-building" />
                            <div className="right">
                                <p className="title">{i18next.t("navigation.sideNavigation.perspective.facilityManagement.title")}</p>
                                <p className="description">{i18next.t("navigation.sideNavigation.perspective.facilityManagement.description")}</p>
                            </div>
                        </div>
                        <div className="option" onClick={() => {
                            scanmetrix.client.mutate({
                                mutation: scanmetrix.gql`
                                mutation($perspective: String!) {
                                    updatePerspective(perspective: $perspective)
                                }
                            `,
                                variables: {
                                    perspective: "propertyManagement"
                                }
                            }).then(result => {
                                if(result.data.updatePerspective) {
                                    scanmetrix.session.perspective = "propertyManagement"
                                    this.perspectiveModal.close()

                                    if(this.props.history.location.pathname === "/dashboard") this.props.history.replace("/reload")
                                }
                            })
                        }}>
                            <i className="fa-duotone fa-house-return" />
                            <div className="right">
                                <p className="title">{i18next.t("navigation.sideNavigation.perspective.propertyManagement.title")}</p>
                                <p className="description">{i18next.t("navigation.sideNavigation.perspective.propertyManagement.description")}</p>
                            </div>
                        </div>
                    </Feedback>
                </Modal.Body>
            </Modal>
            <SideNavigation changelogModal={() => {
                if(this.changelogModal && this.changelogModal.open) this.changelogModal.open()
            }} feedbackModal={() => this.feedbackModal.open()} languageModal={() => this.languageModal.open()} perspectiveModal={() => this.perspectiveModal.open()} globeClick={this.props.globeClick} routes={this.props.routes} />
        </React.Fragment>
    }
}

const Feedback = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 24px;
    width: 600px;
  
    .option {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 24px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        user-select: none;
        cursor: pointer;
        transition: all 0.3s;
        
        &:hover {
            border-color: #3b97d3;  
            background: rgb(59 151 211 / 9%);
        }
      
        .right {
            display: flex;
            flex-direction: column;
            font-size: 18px;
          
            .title {
                font-weight: bold;
                margin-bottom: 4px;
            }
          
            .description {
                line-height: 28px;
            }
        }
      
        i {
            font-size: 40px;
            color: #3b97d3;
            flex-shrink: 0;
            margin-right: 32px;
            width: 48px;
        }
    }
`
