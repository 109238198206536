import React, { Component } from "react"
import { Modal, Button, Input, TextEditor, Select, Checkbox } from "scanmetrix-components"
import countries from "i18n-iso-countries";
import i18next from "i18next";
import isEmail from 'validator/lib/isEmail';

export default class extends Component {
    state = { serviceProviders: [], serviceProviderId: null, email: null, title: null, text: null, loading: false, selectedStandardFiles: [], selectedFiles: [], files: [], standardFiles: [], additionalEmails: null }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    StandardFiles {
                        nodes {
                            id
                            name
                        }
                    }
                    Files(filter: { measureId_eq: $id }) {
                        nodes {
                            id
                            name
                            path
                        }
                    }
                    ServiceProviders {
                        nodes {
                            id
                            name
                            contactEmail
                        }
                    }
                }
            `,
            variables: {
                id: this.props.measure.id
            }
        }).then(data => {
            this.setState({ serviceProviders: data.data.ServiceProviders.nodes, files: data.data.Files.nodes, standardFiles: data.data.StandardFiles.nodes })
        })
    }

    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ selectedStandardFiles: [], selectedFiles: [], email: null, additionalEmails: null, serviceProviderId: null, loading: false, title: `${i18next.t("page.private.measure.createMeasureOfferRequestModal.text1")} ${this.props.measure.number}`, text: `<p>${i18next.t("page.private.measure.createMeasureOfferRequestModal.text2")}<br /><br />${i18next.t("page.private.measure.createMeasureOfferRequestModal.text3")} <br /><br /><b>${this.props.measure.title}</b><br /><br />${i18next.t("page.private.measure.createMeasureOfferRequestModal.text4")}<br /><br /><u>${i18next.t("page.private.measure.createMeasureOfferRequestModal.text5")}</u><br />${this.props.measure.subsidiary.legalName}<br />${this.props.measure.subsidiary.label ? (this.props.measure.subsidiary.label + "<br />") : ""}${this.props.measure.subsidiary.address.additional ? (this.props.measure.subsidiary.address.additional + "<br />") : ""}${this.props.measure.subsidiary.address.streetName} ${this.props.measure.subsidiary.address.streetNumber}<br />${this.props.measure.subsidiary.address.postalCode} ${this.props.measure.subsidiary.address.city}<br />${formattedCountries[this.props.measure.subsidiary.address.country] || this.props.measure.subsidiary.address.country}<br /><br /><br />${i18next.t("page.private.measure.createMeasureOfferRequestModal.text6")}<br /><br />${scanmetrix.session.firstName} ${scanmetrix.session.lastName}<br />${scanmetrix.session.email}<br />${this.props.measure.subsidiary.legalName}</p>` })
        }}>
            <Modal.Head title={i18next.t("page.private.measure.createMeasureOfferRequestModal.title")} icon="envelope" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 24 }}>
                    <Select readOnly={this.state.loading} label={i18next.t("page.private.measure.createMeasureOfferRequestModal.serviceProvider")} adjustWidth required icon="user-tie" items={this.state.serviceProviders.map(serviceProvider => ({
                        key: serviceProvider.id,
                        title: serviceProvider.name,
                        contactEmail: serviceProvider.contactEmail
                    }))} value={this.state.serviceProviderId} onChange={(serviceProviderId, serviceProvider) => {
                        this.setState({ serviceProviderId, email: serviceProvider.contactEmail })
                    }} />
                    <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.createMeasureOfferRequestModal.email")} icon="envelope" placeholder={i18next.t("page.private.measure.createMeasureOfferRequestModal.searchPlaceholderMustermann")} value={this.state.email} onChange={email => this.setState({ email })} required />
                    <Input adjustWidth label={i18next.t("page.private.measure.createMeasureOfferRequestModal.additionalEmails")} textArea placeholder="max@mustermann.de,peter@mustermann.de" icon="envelope" value={this.state.additionalEmails} onChange={additionalEmails => this.setState({ additionalEmails: additionalEmails.replace(/ /g, "") })} />
                    <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.createMeasureOfferRequestModal.subject")} icon="heading" placeholder={i18next.t("page.private.measure.createMeasureOfferRequestModal.searchPlaceholderPo2023")} value={this.state.title} onChange={title => this.setState({ title })} required />
                    <div style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
                        <TextEditor
                            readOnly={this.state.loading}
                            label={i18next.t("page.private.measure.createMeasureOfferRequestModal.Receiver")}
                            icon="font"
                            placeholder={i18next.t("page.private.measure.createMeasureOfferRequestModal.searchPlaceholderGreetings")}
                            value={this.state.text}
                            height={500}
                            onChange={text => this.setState({ text })}
                        />
                    </div>
                    <Modal.Divider title={i18next.t("page.private.measure.createMeasureOfferRequestModal.standardDocumentAttachment")} />
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                        {this.state.standardFiles.map((standardFile, index) => <Checkbox key={index} label={standardFile.name} value={this.state.selectedStandardFiles.includes(standardFile.id)} onChange={value => {
                            if(value) {
                                let selected = this.state.selectedStandardFiles
                                selected.push(standardFile.id)
                                selected = [...new Set(selected)]
                                this.setState({ selectedStandardFiles: selected })
                            } else {
                                let selected = this.state.selectedStandardFiles.filter(id => id !== standardFile.id)
                                this.setState({ selectedStandardFiles: selected })
                            }
                        }} />)}
                    </div>
                    <Modal.Divider title={i18next.t("page.private.measure.createMeasureOfferRequestModal.standardAttachment")} />
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                        {this.state.files.map((file, index) => <Checkbox key={index} label={file.path + file.name} value={this.state.selectedFiles.includes(file.id)} onChange={value => {
                            if(value) {
                                let selected = this.state.selectedFiles
                                selected.push(file.id)
                                selected = [...new Set(selected)]
                                this.setState({ selectedFiles: selected })
                            } else {
                                let selected = this.state.selectedStandardFiles.filter(id => id !== file.id)
                                this.setState({ selectedFiles: selected })
                            }
                        }} />)}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={!this.state.email || !this.state.serviceProviderId || !this.state.text || !this.state.title || !isEmail(this.state.email)} loading={this.state.loading} primary thick title={i18next.t("page.private.measure.createMeasureOfferRequestModal.exitButton")} icon="envelope" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($measureId: ID!, $serviceProviderId: ID!, $email: String!, $title: String!, $text: String!, $files: [ID!], $standardFiles: [ID!], $additionalEmails: [String!]) {
                            createMeasureOfferRequest(measureId: $measureId, serviceProviderId: $serviceProviderId, email: $email, title: $title, text: $text, files: $files, standardFiles: $standardFiles, additionalEmails: $additionalEmails)
                        }
                    `,
                    variables: {
                        measureId: this.props.measure.id,
                        serviceProviderId: this.state.serviceProviderId,
                        email: this.state.email,
                        title: this.state.title,
                        text: this.state.text,
                        files: this.state.selectedFiles,
                        standardFiles: this.state.selectedStandardFiles,
                        additionalEmails: this.state.additionalEmails ? this.state.additionalEmails.split(",").filter(email => isEmail(email)) : []
                    }
                }).then(result => {
                    if(result.data.createMeasureOfferRequest) {
                        this.props.refresh().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                })}
            } />} />
        </Modal>
    }
}
