import React, { Component } from "react"
import { Page, Section, Breadcrumbs, Table, GridElement, Grid, Input } from "scanmetrix-components"

import { getIntervalType } from "../object/"
import styled from "styled-components"
import i18next from "i18next"

import {FileManager} from "../documents";

const Status = styled.div`
    position: absolute;
    left: -16px;
    width: 32px;
    height: 32px;
    background: ${props => props.yellow ? "#e67e22" : (!!props.status ? "#27ae60" : (props.status === false ? "#c0392b" : "#2c3e50"))};
    border-radius: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 16px);
    font-size: 0.9em;
    box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);

    i {
        margin: 0 !important;
    }
`

const Responsibilities = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    box-sizing: border-box;
    padding: 16px;
`

const StyledResponsibility = styled.div`
    border: 1px solid ${props => props.selected ? "#3b97d3 !important" : "rgba(0, 0, 0, 0.1)"};
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 64px 1fr 64px;
    align-items: center;
    user-select: none;
    box-sizing: border-box;
    transition: border-color 0.3s;
  
    &:hover {
        border-color: rgba(0, 0, 0, 0.25);
    }
  
    .fa-check-circle {
        color: #3b97d3;
    }
    
    i {
        width: 64px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8em;
    }
  
    .right {
        .title {
            font-size: 1.2em;
            margin-bottom: 4px;
        }
      
        .description {
            color: rgba(0, 0, 0, 0.75);
            font-size: 0.8em;
        }
    }
`

class Responsibility extends Component {
    render() {
        return <StyledResponsibility selected={this.props.selected} onClick={this.props.onClick}>
            <i className={`far fa-${this.props.icon}`} />
            <div className="right">
                <p className="title">{this.props.title}</p>
                <p className="description">{this.props.description}</p>
            </div>
            {this.props.selected && <i className="fal fa-check-circle" />}
        </StyledResponsibility>
    }
}

export default class extends Component {
    state = { updating: false, customerSubsidiaryFacility: null, responsibility: null, workSection: null }

    updateResponsibility(responsibility) {
        if(this.state.updating) return false
        this.setState({ updating: true })

        let val = responsibility === this.state.responsibility ? null : responsibility

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($facilityId: String!, $serviceProviderId: ID!, $responsibility: String) {
                    updateServiceProviderFacilityResponsibility(facilityId: $facilityId, serviceProviderId: $serviceProviderId, responsibility: $responsibility)
                }
            `,
            variables: {
                responsibility: val,
                facilityId: this.props.match.params.facilityid,
                serviceProviderId: this.props.match.params.serviceproviderid
            }
        }).then(() => {
            this.setState({ responsibility: val, updating: false })
        }).catch(e => {
            this.setState({ updating: false })
            console.log(e)
        })
    }

    constructor(props) {
        super(props)

        this.updateResponsibility = this.updateResponsibility.bind(this)

        fetch(scanmetrix.backendURL + "/facility/" + this.props.match.params.serviceproviderid + "/" + this.props.match.params.facilityid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ customerSubsidiaryFacility: data })

            if(data.targetWorkSectionId) {
                scanmetrix.client.query({
                    query: scanmetrix.gql`
                        query($id: ID!) {
                            WorkSection(id: $id) {
                                id
                                name
                                icon
                            }
                        }
                    `,
                    variables: {
                        id: data.targetWorkSectionId
                    }
                }).then(data => {
                    if(data.data) this.setState({ workSection: data.data.WorkSection })
                })
            }
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($facilityId: String!, $serviceProviderId: ID!) {
                    ServiceProviderFacilityResponsibilities(filter: { facilityId_eq: $facilityId, serviceProviderId_eq: $serviceProviderId }) {
                        nodes {
                            id
                            responsibility
                        }
                    }
                }
            `,
            variables: {
                facilityId: this.props.match.params.facilityid,
                serviceProviderId: this.props.match.params.serviceproviderid
            }
        }).then(data => {
            if(data.data.ServiceProviderFacilityResponsibilities.nodes.length) {
                this.setState({ responsibility: data.data.ServiceProviderFacilityResponsibilities.nodes[0].responsibility })
            }
        })
    }

    render() {
        if(!this.state.customerSubsidiaryFacility) return null
        const customerSubsidiaryFacility = this.state.customerSubsidiaryFacility

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "building",
                    title: i18next.t("page.private.facility.breadCrumbsTitle"),
                    link: "/facilitymanagement"
                },
                {
                    icon: "map-marker",
                    title: `${customerSubsidiaryFacility.customerSubsidiary.name} (${customerSubsidiaryFacility.customerSubsidiary.label})`,
                    link: `/subsidiary/${customerSubsidiaryFacility.customerSubsidiary.id}`
                },
                {
                    icon: "folder-tree",
                    title: `${customerSubsidiaryFacility.name} (Gebäude „${customerSubsidiaryFacility.building}“)`
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.facility.attachement.sectionElementTitle")} icon="folder-tree">
                    <Grid columns={[ "3fr", "1fr" ]} gap="32px">
                        <GridElement styled title={i18next.t("page.private.facility.attachement.attachementTable.gridElementTitle")} icon="folder-tree">
                            <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input adjustWidth label={i18next.t("page.private.facility.attachement.attachementTable.table.name")} icon="font" value={customerSubsidiaryFacility.name} readOnly />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input adjustWidth label={i18next.t("page.private.facility.attachement.attachementTable.table.building")} icon="gavel" value={customerSubsidiaryFacility.building} readOnly />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input adjustWidth label={i18next.t("page.private.facility.attachement.attachementTable.table.workSection")} icon={customerSubsidiaryFacility.workSection.icon} value={customerSubsidiaryFacility.workSection.name} readOnly />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input adjustWidth label={i18next.t("page.private.facility.attachement.attachementTable.table.resposibleServiceProvider")} icon="user-tie" value={customerSubsidiaryFacility.serviceProvider.name} readOnly />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input adjustWidth label={i18next.t("page.private.facility.attachement.attachementTable.table.interval")} icon="hourglass" value={customerSubsidiaryFacility.intervalAmount ? `${customerSubsidiaryFacility.intervalAmount} ${getIntervalType(customerSubsidiaryFacility.intervalType)}` : null} readOnly />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input adjustWidth label={i18next.t("page.private.facility.attachement.attachementTable.table.nextMaintenance")} icon="calendar" value={customerSubsidiaryFacility.nextInspectionDate ? moment(customerSubsidiaryFacility.nextInspectionDate, "DD.MM.YYYY").format("MM / YYYY") : null} readOnly />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Input adjustWidth label={i18next.t("page.private.facility.attachement.attachementTable.table.targetWorksection")} icon={this.state.workSection ? this.state.workSection.icon : "flag-checkered"} value={this.state.workSection ? `${customerSubsidiaryFacility.targetWorkSectionId}: ${this.state.workSection.name}` : customerSubsidiaryFacility.targetWorkSectionId} readOnly />
                                </GridElement>
                            </Grid>
                        </GridElement>
                        <GridElement styled title={i18next.t("page.private.facility.attachement.responsibility.gridElementTitle")} icon="user-tie" height="100%">
                            <Responsibilities>
                                <Responsibility onClick={() => this.updateResponsibility("landlord")} selected={this.state.responsibility === "landlord"} title={i18next.t("page.private.facility.attachement.responsibility.landlord")} type="notes" icon="house-user" description={i18next.t("page.private.facility.attachement.responsibility.landlordDescription")} />
                                <Responsibility onClick={() => this.updateResponsibility("administrative")} selected={this.state.responsibility === "administrative"} title={i18next.t("page.private.facility.attachement.responsibility.administrator")} type="notes" icon="house-damage" description={i18next.t("page.private.facility.attachement.responsibility.administratorDescription")} />
                                <Responsibility onClick={() => this.updateResponsibility("self")} selected={this.state.responsibility === "self"} type="self" title={i18next.t("page.private.facility.attachement.responsibility.operator")} icon="user-tie" description={i18next.t("page.private.facility.attachement.responsibility.operatorDescription")} />
                            </Responsibilities>
                        </GridElement>
                    </Grid>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.facility.object.sectionElementTitle")} icon="qrcode" amount={customerSubsidiaryFacility.customerObjects.length}>
                    <GridElement styled title={i18next.t("page.private.facility.object.gridElementTitle")} icon="qrcode">
                        <Table
                            head={[
                                { title: i18next.t("page.private.facility.object.table.smid"), width: "15%", column: "smid" },
                                { title: i18next.t("page.private.facility.object.table.position"), width: "5%", column: "position" },
                                { title: i18next.t("page.private.facility.object.table.positionDescription"), width: "30%", column: "positionDescription" },
                                { title: i18next.t("page.private.facility.object.table.manufacturer"), width: "15%", column: "manufacturer" },
                                { title: i18next.t("page.private.facility.object.table.model"), width: "15%", column: "model" },
                                { title: i18next.t("page.private.facility.object.table.interval"), width: "10%", column: "interval" },
                                { title: i18next.t("page.private.facility.object.table.nextInspection"), width: "10%", column: "nextInspection" }
                            ]}
                            onItemClick={customerObject => this.props.history.push(`/object/${customerSubsidiaryFacility.serviceProvider.id}/${customerObject.id}`)}
                            items={customerSubsidiaryFacility.customerObjects.map(customerObject => {
                                return ({
                                    id: customerObject.id,
                                    smid: <div style={{ paddingLeft: "16px" }}><Status yellow={customerObject.operative && customerObject.hasFlaw} status={(customerObject.discarded || customerObject.noFurtherMaintenance || customerObject.lifetimeExceeded) ? null : customerObject.operative}><i className={`far fa-${(customerObject.discarded || customerObject.noFurtherMaintenance || customerObject.lifetimeExceeded) ? "trash" : (customerObject.operative ? (customerObject.hasFlaw ? "bolt" : "check") : (customerObject.operative === false ? "times" : "question"))}`} /></Status><i className="far fa-qrcode" />{customerObject.smid.id}</div>,
                                    position: customerObject.position,
                                    interval: customerObject.inheritInterval ? (customerObject.intervalAmount ? `${customerObject.intervalAmount} ${getIntervalType(customerObject.intervalType)}` : null) : (customerSubsidiaryFacility.intervalAmount ? `${customerSubsidiaryFacility.intervalAmount} ${getIntervalType(customerSubsidiaryFacility.intervalType)}` : null),
                                    positionDescription: customerObject.positionDescription,
                                    nextInspection: customerObject.inheritInterval ? (customerSubsidiaryFacility.nextInspectionDate ? moment(customerSubsidiaryFacility.nextInspectionDate, "DD.MM.YYYY").format("MM / YYYY") : null) : (customerObject.nextInspectionDate ? moment(customerObject.nextInspectionDate, "DD.MM.YYYY").format("MM / YYYY") : null),
                                    manufacturer: customerObject.manufacturer_name,
                                    model: customerObject.accounting_article_name
                                })
                            }).sort((a, b) => b.position - a.position)}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.facility.documents.sectionElementTitle")} icon="folder-tree">
                    <FileManager facilityId={this.props.match.params.serviceproviderid + ":" + customerSubsidiaryFacility.id} title={i18next.t("page.private.facility.documents.gridElementTitle")} icon="folder-tree" scope="facility" onPathChange={() => {}} />
                </Section.Item>
            </Section>
        </Page>
    }
}
