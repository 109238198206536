import styled from "styled-components"

export default styled.div`
  table {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;background: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 24px -8px rgb(0 0 0 / 15%);
    overflow: hidden;
    
    thead {
      
      tr {
        th {
          user-select: none;
          padding-bottom: 8px;
          text-align: left;
          font-size: 0.9em;
          opacity: 0.75;
          font-weight: normal;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin-bottom: 8px;
          padding-top: 8px;
          
          &:nth-child(1) {
            width: 17.5%;
            padding-left: 16px;
          }

          &:nth-child(2) {
            width: 17.5%;
          }

          &:nth-child(3) {
            width: 25%;
          }

          &:nth-child(4) {
            width: 15%;
          }

          &:nth-child(5) {
            width: 20%;
          }

          &:nth-child(6) {
            width: 5%;
            padding-right: 16px;
            text-align: right;
          }
        }
      }
    }
    
    tbody {
      tr {
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.75);
        user-select: none;
        height: 40px;
        
        &:nth-child(even) {
          background: rgba(0, 0, 0, 0.025);
          
          td:first-child {
              border-radius: 16px 0 0 16px;
          }

          td:last-child {
            border-radius: 0 16px 16px 0;
          }
        }
        
        td {
            line-height: 100%;
        }
        
        &:hover {
          background: #2c3e50;
          border-radius: 16px;
          box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.2);
          
          td {
            background: #2c3e50;
            color: white;
          }
          
          td:first-child {
            border-radius: 16px 0 0 16px;
          }
          
          td:last-child {
            border-radius: 0 16px 16px 0;
          }
        }
        
        td {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 16px;
          font-size: 0.9em;
          
          &:last-child {
              text-overflow: fade;
          }
          
          .dot {
            height: 100%;
            display: inline-flex;
            align-items: center;
            margin-right: 8px;
            border: 3px solid white;
            border-radius: 100%;
            
            &.red:after {
              background: #c0392b;
            }
            
            &.yellow:after {
              background: #e67e22;
            }
            
            &.green:after {
              background: #27ae60;
            }
            
            &:after {
              content: " ";
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 100%;
            }
          }
          
          &:nth-child(1) {
            width: 17.5%;
            padding-left: 16px;
            opacity: 0.5;
            font-weight: bold;
          }
          
          &:nth-child(2) {
            width: 17.5%;
          }
          
          &:nth-child(3) {
            width: 25%;
          }
          
          &:nth-child(4) {
            width: 15%;
          }
          
          &:nth-child(5) {
            width: 20%;
          }
          
          &:nth-child(6) {
            width: 5%;
            text-align: right;
            
            i {
              font-size: 1.4em;
              cursor: pointer;
              transition: opacity 0.3s;

              &:hover {
              opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
`
