import React, { Component } from "react"
import { Modal, Select, Input } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { id: null, measureOffer: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={measureOffer => this.setState({ id: measureOffer.id, measureOffer, loading: false })}>
            <Modal.Head title={i18next.t("page.private.measure.updateMeasureOfferStatusModal.title")} icon="cog" />
            <Modal.Body padding={24} adjustHeight={328}>
                <Input.MutationProvider value={{
                    name: "updateMeasureOffer",
                    id: this.state.id,
                    data: this.state.measureOffer,
                    onSave: () => this.props.refresh()
                }}>
                    <Select field="status" required icon="cog" label={i18next.t("page.private.measure.updateMeasureOfferStatusModal.status")} noUnselect adjustWidth items={[
                        { key: "unedited", title: i18next.t("page.private.measure.updateMeasureOfferStatusModal.offerStatus.unedited") },
                        { key: "approval", title: i18next.t("page.private.measure.updateMeasureOfferStatusModal.offerStatus.approval") },
                        { key: "accepted", title: i18next.t("page.private.measure.updateMeasureOfferStatusModal.offerStatus.accepted") },
                        { key: "declined", title: i18next.t("page.private.measure.updateMeasureOfferStatusModal.offerStatus.declined") },
                        { key: "purchased", title: i18next.t("page.private.measure.updateMeasureOfferStatusModal.offerStatus.purchased") }
                    ]} />
                    <Modal.Info><b>{i18next.t("page.private.measure.updateMeasureOfferStatusModal.warning")}</b> {i18next.t("page.private.measure.updateMeasureOfferStatusModal.recommendedStatus")}</Modal.Info>
                </Input.MutationProvider>
            </Modal.Body>
        </Modal>
    }
}
