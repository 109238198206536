"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
// @flow
var React = require("react");
var prop_types_1 = require("prop-types");
var classnames_1 = require("classnames");
var layoutClassName = "react-grid-layout";
/*
 * A simple HOC that provides facility for listening to container resizes.
 *
 * The Flow type is pretty janky here. I can't just spread `WPProps` into this returned object - I wish I could - but it triggers
 * a flow bug of some sort that causes it to stop typechecking.
 */
function WidthProvideRGL(ComposedComponent) {
    var _a;
    return _a = /** @class */ (function (_super) {
        __extends(WidthProvider, _super);
        function WidthProvider() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.state = {
                width: 1280
            };
            _this.elementRef = React.createRef();
            _this.mounted = false;
            _this.onWindowResize = function () {
                if (!_this.mounted)
                    return;
                var node = _this.elementRef.current; // Flow casts this to Text | Element
                // fix: grid position error when node or parentNode display is none by window resize
                // #924 #1084
                if (node instanceof HTMLElement && node.offsetWidth) {
                    _this.setState({ width: node.offsetWidth });
                }
            };
            return _this;
        }
        WidthProvider.prototype.componentDidMount = function () {
            this.mounted = true;
            window.addEventListener("resize", this.onWindowResize);
            // Call to properly set the breakpoint and resize the elements.
            // Note that if you're doing a full-width element, this can get a little wonky if a scrollbar
            // appears because of the grid. In that case, fire your own resize event, or set `overflow: scroll` on your body.
            this.onWindowResize();
        };
        WidthProvider.prototype.componentWillUnmount = function () {
            this.mounted = false;
            window.removeEventListener("resize", this.onWindowResize);
        };
        WidthProvider.prototype.render = function () {
            var _a = this.props, measureBeforeMount = _a.measureBeforeMount, rest = __rest(_a, ["measureBeforeMount"]);
            if (measureBeforeMount && !this.mounted) {
                return (<div className={classnames_1["default"](this.props.className, layoutClassName)} style={this.props.style}
                    // $FlowIgnore ref types
                             ref={this.elementRef}/>);
            }
            return (<ComposedComponent innerRef={this.elementRef} {...rest} width={this.state.width + 32}/>);
        };
        return WidthProvider;
    }(React.Component)),
        _a.defaultProps = {
            measureBeforeMount: false
        },
        _a;
}
exports["default"] = WidthProvideRGL;
