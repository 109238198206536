import React, { Component } from "react"
import { Modal, Table } from "scanmetrix-components"

import styled from "styled-components"
import Avatar from "react-avatar"
import DeleteMeterHistoryModal from "./DeleteMeterHistoryModal";
import UpdateMeterHistoryModal from "./UpdateMeterHistoryModal";
import i18next from "i18next";

function padDigits(number, digits) {
    return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
}

const Reading = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 1.5fr 1fr 24px;
    padding: 8px 0;
    user-select: none;

    .picture {
      img {
        height: 40px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            opacity: 0.75;
        }
      }
    }

    .delete {
      width: 20px;
    height: 20px;
    margin-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(30deg, #0F7ABF, #58B9F9);
    border-radius: 5px;
    padding: 12px;
    box-sizing: border-box;
    transition: all 250ms ease;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    i {
      color: white;
      font-size: 0.85em;
    }
  }

    .user {
        display: flex;
        align-items: center;

        .avatar {
            border-radius: 32px;
            margin-right: 8px;
            width: 32px;
            height: 32px;
        }
    }

    .value {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: bold;
        letter-spacing: 5px;
        padding-right: 16px;
    }

    .date {
        display: flex;
        align-items: center;
    }
`

const NoReadings = styled.div`
    padding: 48px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    h2 {
        max-width: 50%;
        font-weight: normal;
    }
`

const types = [
    { key: "electricity", title: "kWh"},
    { key: "water", title: "m³" },
    { key: "gas", title: "m³" },
    { key: "teleheating", title: "MWh" },
    { key: "heatQuantity", title: "kWh" },
    { key: "cold", title: "MWh" }
]

export default class extends Component {
    state = { readings: [] }

    render() {
        return <>
            <Modal minWidth="650px" instance={this.props.instance} initialize={readings => {
                this.setState({ readings })
            }}>
                <Modal.Head title={i18next.t("page.private.subsidiary.styled.meterReadingHistoryModal.title")} icon="history" />
                <Modal.Body padding={24}>
                    {this.state.readings.length === 0 && <NoReadings>
                        <h2>{i18next.t("page.private.subsidiary.styled.meterReadingHistoryModal.description")}</h2>
                    </NoReadings>}
                    {this.state.readings.length > 0 && <Table
                        head={[
                            { title: i18next.t("page.private.subsidiary.styled.meterReadingHistoryModal.table.date"), width: "10%", column: "date" },
                            { title: i18next.t("page.private.subsidiary.styled.meterReadingHistoryModal.table.user"), width: "20%", column: "user" },
                            { title: i18next.t("page.private.subsidiary.styled.meterReadingHistoryModal.table.difference"), width: "25%", column: "difference" },
                            { title: i18next.t("page.private.subsidiary.styled.meterReadingHistoryModal.table.value"), width: "25%", column: "value" },
                            { title: i18next.t("page.private.subsidiary.styled.meterReadingHistoryModal.table.image"), width: "20%", column: "image" }
                        ]}
                        contextItems={[
                            { title: i18next.t("page.private.subsidiary.styled.meterReadingHistoryModal.editButton"), icon: "pencil", onClick: item => this.updateMeterReadingModal.open(({ ...item, value: item.rawValue }))},
                            { title: i18next.t("page.private.subsidiary.styled.meterReadingHistoryModal.deleteButton"), icon: "trash", onClick: item => this.deleteMeterHistoryModal.open(item.id)}
                        ]}
                        items={this.state.readings.sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()).map((reading, index) => {
                            const differenceValue = index > 0 ? (reading.value - this.state.readings.sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())[index - 1].value) : reading.value

                            return ({
                                ...reading,
                                difference: <p style={differenceValue === 0 ? ({ fontWeight: "bold" }) : (differenceValue > 0 ? ({ fontWeight: "bold", color: "#1abc9c" }) : ({ fontWeight: "bold", color: "#e74c3c" }))}>{differenceValue.toFixed(2)} {types.find(t => t.key === reading.type).title}</p>,
                                user: <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : reading.user.email} name={`${reading.user.firstName} ${reading.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {reading.user.firstName} {reading.user.lastName}
                                </div>,
                                date: moment(reading.createdAt).format("DD.MM.YYYY"),
                                value: <>{reading.value.toFixed(2)} {types.find(t => t.key === reading.type).title}</>,
                                rawValue: reading.value,
                                image: reading.image ? <img style={{ height: 48, width: 48, objectFit: "cover", borderRadius: "100%" }} onClick={() => window.open(`${scanmetrix.backendURL}/meter-reading/image/${reading.id}`, "_blank")} src={`${scanmetrix.backendURL}/meter-reading/image/${reading.id}`} /> : null
                            })
                        }).reverse()}
                    />}
                    {/*this.state.readings.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map((reading, index) => <Reading key={index}>
                    <div className="picture">
                        {reading.image && <img onClick={() => window.open(`${scanmetrix.backendURL}/meter-reading/image/${reading.id}`, "_blank")} src={`${scanmetrix.backendURL}/meter-reading/image/${reading.id}`} />}
                    </div>
                    <div className="date">{moment(reading.createdAt).fromNow()} ({moment(reading.createdAt).format("DD.MM.YYYY")})</div>
                    {reading.user && <div className="user">
                        <Avatar size="32" email={scanmetrix.disableGravatar ? null: reading.user.email} name={`${reading.user.firstName} ${reading.user.lastName}`} className="avatar" />
                        {`${reading.user.firstName} ${reading.user.lastName}`}
                    </div>}
                    <div className="value">{reading.value.toFixed(2)} {types.find(t => t.key === reading.type).title}</div>
                    {scanmetrix.checkPermission("MeterReading") >= 4 && <div className="delete" onClick={() => {
                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                               mutation($id: ID!) {
                                    deleteMeterReading(id: $id)
                               }
                            `,
                            variables: {
                                id: reading.id
                            }
                        }).then(data => {
                            if(data.data.deleteMeterReading) {
                                this.props.refresh()
                                this.setState({ readings: this.state.readings.filter(t => t.id !== reading.id) })
                            }
                        })
                    }}>
                        <i className="far fa-trash" />
                    </div>}
                </Reading>)*/}
                </Modal.Body>
            </Modal>
            <DeleteMeterHistoryModal instance={ref => this.deleteMeterHistoryModal = ref} refresh={readingId => {
                this.props.refresh()
                this.setState({ readings: this.state.readings.filter(t => t.id !== readingId) })
            }} />
            <UpdateMeterHistoryModal instance={ref => this.updateMeterReadingModal = ref} refresh={(readingId, value) => {
                this.props.refresh()
                this.setState({
                    readings: this.state.readings.map(reading => {
                        if(reading.id !== readingId) return reading

                        return {
                            ...reading,
                            value,
                            user: scanmetrix.session
                        }
                    })
                })
            }} />
        </>
    }
}
