import React, { Component } from "react"
import Dropzone from "react-dropzone"
import { v4 as uuid } from "uuid"
import { Grid, GridElement, Button, Input, Select, PlateInput, Tags } from "scanmetrix-components"
import CarPreview from "../../../carpool/styled/CarPreview"
import Thumbnail from "./styled/Thumbnail"
import ImageBox from "./styled/ImageBox"
import TagBody from "./styled/TagBody"
import UploadBody from "./styled/UploadBody"


import DeleteCarModal from "./modals/DeleteCarModal"
import ViewPictureModal from "./modals/ViewPictureModal";

export default class extends Component {
    state = { files: [], photos: [] }

    constructor(props) {
        super(props)

        this.fetchPhotos = this.fetchPhotos.bind(this)

        this.fetchPhotos()
    }

    saveNumberPlate(key, value) {
        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $value: String!) {
                    updateCarInfos(id: $id, ${key}: $value)
                }
            `,
            variables: {
                id: this.props.car.id,
                value
            }
        })
    }

    fetchPhotos() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($carId: ID!) {
                    CarPhotos(filter: { carId_eq: $carId }) {
                        nodes {
                            id
                            createdAt
                        }
                    }
                }
            `,
            variables: {
                carId: this.props.car.id
            }
        }).then(data => {
            if(data.data.CarPhotos) {
                this.setState({ photos: data.data.CarPhotos.nodes })
            }
        })
    }

    render() {
        const car = this.props.car
        const subsidiaries = this.props.subsidiaries

        return <>
            <DeleteCarModal instance={ref => this.deleteCarModal = ref} carId={car.id} history={this.props.history} />
            <ViewPictureModal instance={ref => this.viewPictureModal = ref} />
            <Grid gap="32px" columns={[ "minmax(0, 1fr)", "300px" ]}>
                <Grid gap="32px">
                    <GridElement styled title="Galerie" icon="images">
                        <Grid gap='16px' padding='16px' columns={[ "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                            <Dropzone accept={[ ".jpg", ".jpeg", ".png" ]} onDrop={acceptedFiles => {
                                if(acceptedFiles.length && !this.state.loading) {
                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($file: Upload!, $carId: ID!) {
                                                createCarPhoto(file: $file, carId: $carId)
                                            }
                                        `,
                                        context: {
                                            hasUpload: true,
                                            fetchOptions: {
                                                onUploadProgress: ev => {
                                                    const progress = ev.loaded / ev.total

                                                    console.log(progress)

                                                    //this.setState({ uploadProgress: progress })
                                                }
                                            }
                                        },
                                        variables: {
                                            file: acceptedFiles[0],
                                            carId: car.id
                                        }
                                    }).then(result => {
                                         if (result.data.createCarPhoto) {
                                             this.fetchPhotos()
                                         }
                                    })
                                }
                            }}>
                                {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody style={{ height: 200 }} tabIndex={0} {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef", className: 'dropzone' })}>
                                    <input {...getInputProps()} tabIndex={-1} accept='image/jpeg, image/jpg, image/png' multiple={true} type='file' autoComplete='off' />
                                    <i className="far fa-upload" />
                                    <p className="title">Legen Sie hier Bilder des Fahrzeugs ab oder klicken Sie hier um den Dateibrowser zu öffnen.</p>
                                </UploadBody>}
                            </Dropzone>
                            {this.state.photos.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map((photo, index) => <ImageBox key={index} onClick={() => {
                                this.viewPictureModal.open(`${scanmetrix.backendURL}/carphoto/${photo.id}`)
                            }}>
                                <img src={`${scanmetrix.backendURL}/carphoto/${photo.id}`} />
                                {/*<div className='delete-hover' onClick={() => {}}>
                                    <i className='far fa-trash' />
                                </div>*/}
                            </ImageBox>)}
                        </Grid>
                    </GridElement>
                    <Input.MutationProvider value={{
                        name: "updateCarInfos",
                        id: car.id,
                        data: car
                    }}>
                        <GridElement styled title="Eckdaten" icon="car" rightContent={<Button title="Fahrzeug löschen" icon="trash" secondary onClick={() => this.deleteCarModal.open()} thick />}>
                            <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "0.5fr", "1fr" ]}>
                                <GridElement columnStart={1} columnEnd={4}>
                                    <Input field="name" adjustWidth label="Name" icon="font" />
                                </GridElement>
                                <GridElement columnStart={4} columnEnd={6}>
                                    <Select field="subsidiary.id" adjustWidth label="Befindet sich bei Niederlassung" icon="map-marker" items={subsidiaries.map(subsidiary => ({
                                        key: subsidiary.id,
                                        title: `${subsidiary.name}${subsidiary.label ? (" / " + subsidiary.label) : ""} / ${subsidiary.address.inline}`
                                    }))} />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={6}>
                                    <Input field="description" adjustWidth label="Beschreibung" icon="pencil" />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input field="manufacturer" adjustWidth label="Hersteller" icon="industry" />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input field="model" adjustWidth label="Modell" icon="cubes" />
                                </GridElement>
                                <GridElement columnStart={3} columnEnd={4}>
                                    <Input field="buildYear" int adjustWidth label="Baujahr" icon="calendar" />
                                </GridElement>
                                <GridElement columnStart={4} columnEnd={6}>
                                    <Input field="chassisNumber" adjustWidth label="Fahrgestellnummer" icon="list-ol" />
                                </GridElement>
                            </Grid>
                        </GridElement>
                        <GridElement styled title="Technische Fahrzeugdaten" icon="cog">
                            <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Input field="firstRegistration" date adjustWidth label="Erstzulassung" icon="calendar-day" />
                                </GridElement>
                                <GridElement columnStart={3} columnEnd={5}>
                                    <Select field="fuelType" adjustWidth label="Sprittyp" icon="gas-pump" items={[
                                        { key: "electric", title: "Elektrisch (kW)" },
                                        { key: "gas", title: "Benzin (L)" },
                                        { key: "diesel", title: "Diesel (L)" },
                                        { key: "hydrogen", title: "Wasserstoff (L)" }
                                    ]} />
                                </GridElement>
                                <GridElement columnStart={5} columnEnd={7}>
                                    <Select field="type" adjustWidth label="Typ" icon="info" items={[
                                        { key: "smallCar", title: "Kleinwagen", icon: "car" },
                                        { key: "limousine", title: "Limousine", icon: "car" },
                                        { key: "smallTransporter", title: "Kleintransporter", icon: "shuttle-van" },
                                        { key: "bigTransporter", title: "Großtransporter", icon: "shuttle-van" },
                                        { key: "boxTransporter", title: "Boxtransporter", icon: "truck-moving" },
                                        { key: "truck", title: "LKW", icon: "truck-container" },
                                        { key: "forklift", title: "Gabelstapler", icon: "forklift" },
                                        { key: "excavator", title: "Bagger", icon: "truck-plow" },
                                        { key: "tractor", title: "Traktor", icon: "tractor" },
                                        { key: "trailer", title: "Anhänger", icon: "trailer" },
                                        { key: "transportTruck", title: "Pritschenwagen", icon: "truck-pickup" },
                                        { key: "transportLift", title: "Steigerwagen", icon: "truck" },
                                        { key: "flatbedTruck", title: "Tieflader", icon: "trailer" }
                                    ]} />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Input field="transmissionType" adjustWidth label="Getriebeart" icon="steering-wheel" />
                                </GridElement>
                                <GridElement columnStart={3} columnEnd={5}>
                                    <Input field="nextInspection" date adjustWidth label="Nächste Inspektion" icon="car-mechanic" />
                                </GridElement>
                                <GridElement columnStart={5} columnEnd={7}>
                                    <Input field="combinedUsage" float adjustWidth label="Verbrauch / 100km" icon="road" format={val => val !== null ? `${val} ${car.fuelType === "electric" ? "kW" : "L"}` : null} />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Input field="capacity" float adjustWidth label={car.fuelType === "electric" ? "Batteriekapazität" : "Tankkapazität"} icon="weight-hanging" />
                                </GridElement>
                                <GridElement columnStart={3} columnEnd={5}>
                                    <Input field="emptyWeight" float adjustWidth label="Leergewicht" icon="car-side" />
                                </GridElement>
                                <GridElement columnStart={5} columnEnd={7}>
                                    <Input field="permissibleMaximumWeight" float adjustWidth label="Zulässiges Gesamtgewicht" icon="truck-couch" />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Input field="usableWeight" float adjustWidth label="Nutzbare Traglast" icon="truck-container" />
                                </GridElement>
                                <GridElement columnStart={3} columnEnd={5}>
                                    <Input field="tensibleLoadWeight" float adjustWidth label="Spannbare Ladelast" icon="car-garage" />
                                </GridElement>
                                <GridElement columnStart={5} columnEnd={7}>
                                    <Input field="tensibleTowingWeight" float adjustWidth label="Spannbare Abschlepplast" icon="car-tilt" />
                                </GridElement>
                            </Grid>
                        </GridElement>
                    </Input.MutationProvider>
                </Grid>
                <Grid columns={[ "1fr" ]} rows={[ "auto", "auto", "auto", "100%" ]} gap="32px">
                    <GridElement styled title="3D Vorschau" icon="cubes">
                        <CarPreview enableZoom={false} minDistance={4} maxDistance={4} selectedCar={car.type} />
                    </GridElement>
                    <PlateInput
                        onChangePlate1={value => this.saveNumberPlate("licensePlate1", value)}
                        onChangePlate2={value => this.saveNumberPlate("licensePlate2", value)}
                        onChangePlate3={value => this.saveNumberPlate("licensePlate3", value)}
                        value1={car.licensePlate1}
                        value2={car.licensePlate2}
                        value3={car.licensePlate3}
                    />
                    <GridElement styled title="Thumbnail" icon="images">
                        <Thumbnail>
                            {car.thumbnailBase64 && <img src={car.thumbnailBase64} />}
                            {!car.thumbnailBase64 && <Dropzone accept={[ ".jpg", ".jpeg", ".png" ]} onDrop={acceptedFiles => {
                                if(acceptedFiles.length && !this.state.loading) {
                                    this.setState({
                                        files: this.state.files.concat(acceptedFiles.map(file => ({
                                            id: uuid(),
                                            fileName: file.name,
                                            fileSize: file.size,
                                            imageFile: file
                                        })))
                                    })
                                }
                            }}>
                                {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody tabIndex={0} {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef", className: 'dropzone' })}>
                                    <input {...getInputProps()} tabIndex={-1} accept='image/jpeg, image/jpg, image/png' multiple={true} type='file' autoComplete='off' />
                                    <i className="far fa-upload" />
                                    <p className="title">Legen Sie hier Bilder des Fahrzeugs ab oder klicken Sie hier um den Dateibrowser zu öffnen.</p>
                                </UploadBody>}
                            </Dropzone>}
                        </Thumbnail>
                    </GridElement>
                    {/*<GridElement styled title="Beschreibung" icon="tags">
                        <TagBody>
                            <Tags tagMap={[
                                { tag: 'Automatik' },
                                { tag: 'Elektrofahrzeug' },
                                { tag: 'SUV' },
                                { tag: 'Transporter' },
                                { tag: 'Transporter' },
                                { tag: 'Transporter' },
                                { tag: 'Transporter' },
                            ]} />
                        </TagBody>
                    </GridElement>*/}
                </Grid>
            </Grid>
        </>
    }
}
