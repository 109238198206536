import React, { Component } from "react"
import styled from "styled-components"
import Input from "../Input/"
import {ClipLoader} from "react-spinners";

const CheckboxContainer = styled.div`
    display: flex;
    opacity: ${props => props.disabled ? 0.5 : 1};
    
    &:hover {
      .checkbox {
        opacity: 0.5;
      }
    }
`

const Checkbox = styled.div`
    width: 24px;
    height: 24px;
    border: 1px solid rgb(32, 36, 43);
    min-width: 24px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    
    i {
      font-size: 1em;
      transition: all 0.3s;
      visibility: ${props => props.checkVisible ? "visible" : "hidden"};
      opacity: ${props => props.checkVisible ? 1 : 0};
    }
`

const CheckboxLabel = styled.p`
    font-size: 0.9em;
    line-height: 24px;
    user-select: none;
`

class CheckboxClass extends Component {
    state = { value: false, saving: false }

    constructor(props) {
        super(props)

        if(props.value) this.state.value = !!props.value

        const mutationContext = props.mutationContext

        if(mutationContext) {
            if(!mutationContext.listeners) mutationContext.listeners = []

            if(props.contextualDisabled) {
                mutationContext.listeners.push(() => {
                    this.forceUpdate()
                })
            }

            if(mutationContext.data && props.field) {
                if(mutationContext.data[props.field] !== undefined) this.state.value = mutationContext.data[props.field]
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.value !== undefined && nextProps.value !== null) {
            if(nextProps.value !== prevState.value) {
                return { value: !!nextProps.value }
            }
        }

        return null
    }

    mutate(mutationContext, value) {
        this.setState({ saving: true })

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`mutation($id: ID!, $${this.props.field}: Boolean) {
                ${mutationContext.name}(id: $id, ${this.props.field}: $${this.props.field})
            }`,
            variables: {
                id: mutationContext.id,
                [this.props.field]: value
            }
        }).then(() => {
            if(mutationContext.onSave) mutationContext.onSave()

            this.setState({ saving: false })

            mutationContext.data[this.props.field] = value

            const list = mutationContext.listeners || []

            list.forEach(listener => listener())
        })
    }

    render() {
        let disabled = this.props.disabled

        if(this.props.contextualDisabled) {
            disabled = this.props.contextualDisabled(this.props.mutationContext.data)
        }

        return <CheckboxContainer disabled={disabled} onClick={() => {
            if(disabled) return false

            if(this.props.field) this.mutate(this.props.mutationContext, !this.state.value)

            if(!this.props.readOnly) this.setState({ value: !this.state.value })
            if(this.props.onChange) this.props.onChange(!this.state.value)
        }}>
            <Checkbox className="checkbox" checkVisible={!!this.state.value}>
                {!this.state.saving && <i className="far fa-check" />}
                {this.state.saving && <ClipLoader color="#3b97d3" size={12} />}
            </Checkbox>
            <CheckboxLabel>{this.props.label}</CheckboxLabel>
        </CheckboxContainer>
    }
}

class CheckboxClassWrap extends Component {
    render() {
        return <Input.MutationConsumer>
            {mutationContext => <CheckboxClass {...this.props} mutationContext={mutationContext}/> }
        </Input.MutationConsumer>
    }
}

export default CheckboxClassWrap
