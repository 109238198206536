import styled from "styled-components"

export default styled.div`
  background: white;
  border-radius: 32px;
  padding: 8px 16px;
  font-size: 0.6em;
  user-select: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.2);
  transition: color 0.3s, background 0.3s;
  white-space: nowrap;
  
  &:hover {
      background: #2c3e50;
      color: white;
  }
`
