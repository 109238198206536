import styled from "styled-components"

export default styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    margin-top: -8px;
`
