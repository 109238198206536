import React, { Component } from "react"

import { GridElement, Table, Section } from "scanmetrix-components"
import PaymentModal from "./modals/PaymentModal";
import i18next from "i18next"

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = { accountingInvoices: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($status: String!) {
                    AccountingInvoices(filter: { status_eq: $status }) {
                        nodes {
                            id
                            number
                            date
                            net
                            gross
                            notes
                            status
                            accountingInvoiceDocument {
                                id
                                name
                            }
                            serviceProvider {
                                id
                                name
                            }
                            purchaseOrder {
                                id
                                number
                                date
                                positions
                            }
                            subsidiary {
                                id
                                name
                                label
                            }
                        }
                    }
                }
            `,
            variables: {
                status: "waiting_for_payment"
            }
        }).then(data => {
            this.setState({ accountingInvoices: data.data.AccountingInvoices.nodes }, () => {
                this.props.onAmount(this.state.accountingInvoices.length)
            })
        })
    }

    render() {
        return <Section.Item title={i18next.t("page.private.accounting.waitingForPayment.sectionTitle")} icon="coins" {...this.props} amount={this.props.amount}>
            <PaymentModal instance={ref => this.paymentModal = ref} fetch={() => {
                this.props.addArchive()

                return this.fetch()
            }} />
            <GridElement styled icon="coins" title={i18next.t("page.private.accounting.waitingForPayment.gridElementTitle")}>
                <Table
                    head={[
                        { title: i18next.t("page.private.accounting.waitingForPayment.table.date"), width: "10%", column: "date" },
                        { title: i18next.t("page.private.accounting.waitingForPayment.table.number"), width: "12.5%", column: "number" },
                        { title: i18next.t("page.private.accounting.waitingForPayment.table.serviceProvider"), width: "15%", column: "serviceProvider" },
                        { title: i18next.t("page.private.accounting.waitingForPayment.table.net"), width: "10%", column: "net" },
                        { title: i18next.t("page.private.accounting.waitingForPayment.table.gross"), width: "10%", column: "gross" },
                        { title: i18next.t("page.private.accounting.waitingForPayment.table.branch"), width: "17.5%", column: "subsidiary" },
                        { title: i18next.t("page.private.accounting.waitingForPayment.table.purchaseOrder"), width: "12.5%", column: "purchaseOrder" },
                        { title: i18next.t("page.private.accounting.waitingForPayment.table.contract"), width: "12.5%", column: "contract" }
                    ]}
                    onItemClick={item => scanmetrix.checkPermission("AccountingInvoice") >= 3 ? this.paymentModal.open(item.data) : {}}
                    items={this.state.accountingInvoices ? this.state.accountingInvoices.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(accountingInvoice => ({
                        ...accountingInvoice,
                        data: accountingInvoice,
                        net: formatter.format(accountingInvoice.net),
                        gross: formatter.format(accountingInvoice.gross),
                        date: moment(accountingInvoice.date).format("DD.MM.YYYY"),
                        serviceProvider: accountingInvoice.serviceProvider.name,
                        subsidiary: accountingInvoice.subsidiary ? (accountingInvoice.subsidiary.name + (accountingInvoice.subsidiary.label ? (" (" + accountingInvoice.subsidiary.label + ")") : "")) : null,
                        purchaseOrder: accountingInvoice.purchaseOrder ? `${accountingInvoice.purchaseOrder.number} / ${moment(accountingInvoice.purchaseOrder.date).format("DD.MM.YYYY")}` : null,
                        contract: accountingInvoice.contract ? `${accountingInvoice.contract.title} ${accountingInvoice.contract.number ? (" (" + accountingInvoice.contract.number + ")") : ""}` : null
                    })) : null}
                />
            </GridElement>
        </Section.Item>
    }
}
