import React, { Component } from "react"
import { GridElement, Button, Table } from "scanmetrix-components"

export default class extends Component {
    render() {
        const car = this.props.car
        const maintenances = car.maintenances

        return <GridElement styled title="Wartungen" icon="tools" rightContent={<div style={{ display: 'grid', gridGap: 16, gridTemplateColumns: 'auto auto' }}>
            <Button thick green icon="file-pdf" title="Als PDF exportieren" onClick={() => alert('Noch nicht verfügbar!')} />
            <Button thick icon="plus" title="Wartung eintragen" onClick={() => this.newMaintenanceModal.open()} />
        </div>}>
            <Table
                head={[
                    { title: "Beschreibung", width: "25%", column: "description" },
                    { title: "Mangel", width: "35%", column: "flaw" },
                    { title: "Versicherung", width: "15%", column: "insurance" },
                    { title: "Wartungsdatum", width: "12.5%", column: "date" },
                    { title: "Status", width: "12.5%", column: "status" },
                ]}
                items={maintenances.map(maintenance => ({
                    description: maintenance.description,
                    flaw: maintenance.flaw,
                    insurance: maintenance.insurance,
                    date: maintenance.date,
                    status: maintenance.status
                }))}
            />
        </GridElement>
    }
}