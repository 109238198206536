import React, { Component } from "react"
import PlateBody from "./styled/PlateBody"

export default class extends Component {
    state = { plate1: '', plate2: '', plate3: '' }

    constructor(props) {
        super(props)

        if(props.value1) this.state.plate1 = props.value1
        if(props.value2) this.state.plate2 = props.value2
        if(props.value3) this.state.plate3 = props.value3

        this.onPlate1Change = this.onPlate1Change.bind(this)
        this.onPlate2Change = this.onPlate2Change.bind(this)
        this.onPlate3Change = this.onPlate3Change.bind(this)
    }

    onPlate1Change(e) {
        const re = /^[A-Za-z]+$/

        if (e.target.value === '' || re.test(e.target.value)) {
            const val = e.target.value.toUpperCase()
            this.setState({ plate1: val }, () => this.props.onChangePlate1(val))
        }
    }

    onPlate2Change(e) {
        const re = /^[A-Za-z]+$/

        if (e.target.value === '' || re.test(e.target.value)) {
            const val = e.target.value.toUpperCase()
            this.setState({ plate2: val }, () => this.props.onChangePlate2(val))
        }
    }

    onPlate3Change(e) {
        const re = /^[EH0-9]+$/

        const val = e.target.value.toUpperCase()

        let valid = true

        if(val.length > 4) valid = false
        if(!val.match(/^\d/)) valid = false
        if(val.replace(/[0-9]/g, "").length > 1) valid = false
        if(/[a-zA-Z]/g.test(val) && /[0-9]/g.test(val.slice(-1))) valid = false

        if (e.target.value === '' || (valid && re.test(val))) {
            this.setState({ plate3: val }, () => this.props.onChangePlate3(val))
        }
    }

    render() {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PlateBody style={(this.state.plate1.length && this.state.plate2.length && this.state.plate3.length) ? { borderColor: '#20242B' } : { borderColor: '#E74C3C' }}>
                <img className='country-sticker' src='plate.svg' />
                <b>D</b>

                <input spellCheck={false} readOnly={ this.props.readOnly } style={{ maxWidth: 'calc(3ch + 10px)' }} className='letter-input' type='text' placeholder='ABC' placeholderTextColor='rgba(0, 0, 0, .4)' maxLength={3} onChange={this.onPlate1Change} value={this.state.plate1} />
                <input spellCheck={false} readOnly={ this.props.readOnly } style={{ maxWidth: 'calc(2ch + 10px)' }} className='letter-input' type='text' placeholder='DE' placeholderTextColor='rgba(0, 0, 0, .4)' maxLength={2} onChange={this.onPlate2Change} value={this.state.plate2} />
                <input spellCheck={false} readOnly={ this.props.readOnly } className='number-input' type='text' placeholder='1234' placeholderTextColor='rgba(0, 0, 0, .4)' maxLength={4} onChange={this.onPlate3Change} value={this.state.plate3} />
            </PlateBody>
        </div>
    }
}