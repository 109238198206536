import styled from "styled-components"

export default styled.div`
    width: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
        border-bottom: none;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .name {
            font-size: 1.1em;
            padding-left: 16px;
            box-sizing: border-box;
            
            &.title {
              font-size: 1.2em;
              padding-left: 0;
              font-weight: bold;
            }
        }

        .description {
            font-size: 0.9em;
            padding-left: 16px;
            box-sizing: border-box;
        }

        .right {
            display: flex;
            align-items: center;

            .required {
                margin-right: 8px;
                color: #3B97D3;
            }
        }
    }
`
