import styled from 'styled-components'

export default styled.div`
    .formContainer {
        box-sizing: border-box;
        margin: -8px 0;
        margin-left: -5px;

        .react-grid-layout {
            margin: 0 !important;
        }
    }
`  