import React, { Component } from "react"
import { Modal, Input, GridElement, Grid, Checkbox, Section } from "scanmetrix-components"
import i18next from "i18next";

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/theme-xcode"
import "ace-builds/src-noconflict/ext-language_tools"

export default class extends Component {
    state = { id: null, module: null, letterHead: null, firstPageHeader: null, firstPageFooter: null, secondPageHeader: null, purchaseOrderHeader: null, purchaseOrderFooter: null, secondPageFooter: null, bothPagesSame: false, loading: false }

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
    }

    onChange(key) {
        if(!this.saveCache) this.saveCache = {}

        if(this.saveCache[key]) clearTimeout(this.saveCache[key])

        this.saveCache[key] = setTimeout(() => {
            const value = this.state[key]

            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($id: ID!, $${key}: String!) {
                        updateLetterHead(id: $id, ${key}: $${key})
                    }
                `,
                variables: {
                    id: this.state.id,
                    [key]: value
                }
            }).then(data => {
                if(data.data.updateLetterHead) this.props.refresh()
            })

            this.saveCache[key] = null
        }, 300)
    }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={letterHead => this.setState({ ...letterHead, letterHead, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.updateLetterHeadModal.title")} icon="scroll" />
            <Modal.Body padding={24}>
                <Input.MutationProvider value={{
                    name: "updateLetterHead",
                    id: this.state.id,
                    data: this.state.letterHead,
                    onSave: () => this.props.refresh()
                }}>
                    <Grid gap="32px" columns={[ "1fr", "600px" ]}>
                        <GridElement>
                            <Grid gap="32px" columns={[ "1fr", "1fr" ]}>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Input required field="name" icon="tag" label={i18next.t("page.private.settings.modals.updateLetterHeadModal.name")} adjustWidth />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input field="marginLeft" icon="arrow-left-to-line" label={i18next.t("page.private.settings.modals.updateLetterHeadModal.arrowLeft")} int format={val => val !== null ? `${val} mm` : ""} adjustWidth />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input field="marginRight" icon="arrow-right-to-line" label={i18next.t("page.private.settings.modals.updateLetterHeadModal.arrowRight")} int format={val => `${val} mm`} adjustWidth />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input field="marginTop" icon="arrow-up-to-line" label={i18next.t("page.private.settings.modals.updateLetterHeadModal.arrowTop")} int format={val => `${val} mm`} adjustWidth />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input field="marginBottom" icon="arrow-down-to-line" label={i18next.t("page.private.settings.modals.updateLetterHeadModal.arrowDown")} int format={val => `${val} mm`} adjustWidth />
                                </GridElement>

                                <GridElement columnStart={1} columnEnd={3}>
                                    <AceEditor
                                        placeholder={i18next.t("page.private.settings.modals.updateLetterHeadModal.htmlDescription")}
                                        mode="html"
                                        theme="xcode"
                                        value={this.state.module || ""}
                                        onChange={module => this.setState({ module }, () => this.onChange("module"))}
                                        name="module"
                                        editorProps={{ $blockScrolling: true }}
                                        style={{ width: "100%", height: "320px", border: "1px solid rgba(0, 0, 0, 0.1)" }}
                                    />
                                </GridElement>
                            </Grid>
                        </GridElement>
                        <GridElement>
                            <Grid gap="32px">
                                <GridElement>
                                    <Checkbox label={i18next.t("page.private.settings.modals.updateLetterHeadModal.bothSideEqual")} field="bothPagesSame" onChange={bothPagesSame => this.setState({ bothPagesSame })} />
                                </GridElement>
                                <GridElement>
                                    <Section>
                                        <Section.Item title={i18next.t("page.private.settings.modals.updateLetterHeadModal.sideOneHead")} icon="border-top">
                                            <AceEditor
                                                placeholder={i18next.t("page.private.settings.modals.updateLetterHeadModal.sideOneHeadHtml")}
                                                mode="html"
                                                theme="xcode"
                                                value={this.state.firstPageHeader || ""}
                                                onChange={firstPageHeader => this.setState({ firstPageHeader }, () => this.onChange("firstPageHeader"))}
                                                name="firstPageHeader"
                                                editorProps={{ $blockScrolling: true }}
                                                style={{ width: "100%" }}
                                            />
                                        </Section.Item>
                                        <Section.Item title={i18next.t("page.private.settings.modals.updateLetterHeadModal.sideOneFoot")} icon="border-bottom">
                                            <AceEditor
                                                placeholder={i18next.t("page.private.settings.modals.updateLetterHeadModal.sideOneFootHtml")}
                                                mode="html"
                                                theme="xcode"
                                                value={this.state.firstPageFooter || ""}
                                                onChange={firstPageFooter => this.setState({ firstPageFooter }, () => this.onChange("firstPageFooter"))}
                                                name="firstPageFooter"
                                                editorProps={{ $blockScrolling: true }}
                                                style={{ width: "100%" }}
                                            />
                                        </Section.Item>
                                        <Section.Item title={i18next.t("page.private.settings.modals.updateLetterHeadModal.sideTwoHead")} icon="border-top" disabled={this.state.bothPagesSame}>
                                            <AceEditor
                                                placeholder={i18next.t("page.private.settings.modals.updateLetterHeadModal.sideTwoHeadHtml")}
                                                mode="html"
                                                theme="xcode"
                                                value={this.state.secondPageHeader || ""}
                                                onChange={secondPageHeader => this.setState({ secondPageHeader }, () => this.onChange("secondPageHeader"))}
                                                name="secondPageHeader"
                                                editorProps={{ $blockScrolling: true }}
                                                style={{ width: "100%" }}
                                            />
                                        </Section.Item>
                                        <Section.Item title={i18next.t("page.private.settings.modals.updateLetterHeadModal.sideTwoFoot")} icon="border-bottom" disabled={this.state.bothPagesSame}>
                                            <AceEditor
                                                placeholder={i18next.t("page.private.settings.modals.updateLetterHeadModal.sideTwoFootHtml")}
                                                mode="html"
                                                theme="xcode"
                                                value={this.state.secondPageFooter || ""}
                                                onChange={secondPageFooter => this.setState({ secondPageFooter }, () => this.onChange("secondPageFooter"))}
                                                name="secondPageFooter"
                                                editorProps={{ $blockScrolling: true }}
                                                style={{ width: "100%" }}
                                            />
                                        </Section.Item>
                                        <Section.Item title={i18next.t("page.private.settings.modals.updateLetterHeadModal.poHeadText")} icon="wrench">
                                            <AceEditor
                                                placeholder={i18next.t("page.private.settings.modals.updateLetterHeadModal.headTextHtml")}
                                                mode="html"
                                                theme="xcode"
                                                value={this.state.purchaseOrderHeader || ""}
                                                onChange={purchaseOrderHeader => this.setState({ purchaseOrderHeader }, () => this.onChange("purchaseOrderHeader"))}
                                                name="purchaseOrderHeader"
                                                editorProps={{ $blockScrolling: true }}
                                                style={{ width: "100%" }}
                                            />
                                        </Section.Item>
                                        <Section.Item title={i18next.t("page.private.settings.modals.updateLetterHeadModal.poFootText")} icon="wrench">
                                            <AceEditor
                                                placeholder={i18next.t("page.private.settings.modals.updateLetterHeadModal.footTextHtml")}
                                                mode="html"
                                                theme="xcode"
                                                value={this.state.purchaseOrderFooter || ""}
                                                onChange={purchaseOrderFooter => this.setState({ purchaseOrderFooter }, () => this.onChange("purchaseOrderFooter"))}
                                                name="purchaseOrderFooter"
                                                editorProps={{ $blockScrolling: true }}
                                                style={{ width: "100%" }}
                                            />
                                        </Section.Item>
                                    </Section>
                                </GridElement>
                            </Grid>
                        </GridElement>
                    </Grid>
                </Input.MutationProvider>
            </Modal.Body>
        </Modal>
    }
}
