import styled from "styled-components"

export default styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 250ms ease;
  
  &:hover {
    opacity: 0.75;
    
    .delete-hover {
      opacity: 1;
    }
  }
  
  img {
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
    box-sizing: border-box;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
    user-drag: none;
  }
  
  .delete-hover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease;
    opacity: 0;
    z-index: 15;
    
    i {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 48px;
      background: #E74C3C;
      color: white;
      font-size: 1.6em;
      box-shadow: 0 3px 6px -2px #E74C3C;
    }
  }
`