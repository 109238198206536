import React, { Component } from "react"
import { Modal } from "scanmetrix-components"
import { ContractDatesComponent } from "./index"
import i18next from "i18next";

export default class extends Component {
    state = { canceledAt: null, loading: false }

    render() {
        return <Modal instance={this.props.instance} initialize={() => this.setState({  })}>
            <Modal.Head title={i18next.t("page.private.dashboard.propertyManagement.contractDatesModal.title")} icon="file-contract" />
            <Modal.Body>
                <ContractDatesComponent data={this.props.data} embed />
            </Modal.Body>
        </Modal>
    }
}
