import React, { Component } from "react"
import { Modal, Button, Select, Input } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = {
        id: null,
        index: null,
        userId: null,
        status: null,
        loading: false
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={({ id, index, status }) => {
            this.setState({
                id,
                index,
                status,
                userId: null,
                loading: false
            })
        }}>
            <Modal.Head title={i18next.t("page.private.ticket-system.assignTicketModal.title")} icon="user" />
            <Modal.Body padding={24} adjustHeight="328px">
                <Select
                    adjustWidth
                    required
                    noUnselect
                    value={this.state.userId}
                    onChange={userId => this.setState({ userId })}
                    icon="user"
                    label={i18next.t("page.private.ticket-system.assignTicketModal.description")}
                    items={this.props.users.map(user => ({
                        key: user.id,
                        title: `${user.firstName} ${user.lastName} / ${user.email}`
                    }))}
                />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.userId} thick title={i18next.t("page.private.ticket-system.assignTicketModal.exitButton")} icon="user" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $userId: ID!, $index: Int!, $status: String!) {
                            assignTicket(id: $id, userId: $userId, index: $index, status: $status)
                        }
                    `,
                    variables: {
                        id: this.state.id,
                        userId: this.state.userId,
                        status: this.state.status,
                        index: this.state.index
                    }
                }).then(result => {
                    if(result.data.assignTicket) {
                        this.props.refresh().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                })}
            } />} />
        </Modal>
    }
}
