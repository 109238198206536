import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import i18next from "i18next";


export default class extends Component {
    state = { name: null, position: null, number: null, factor: null, type: null, maloId: null, meloId: null, deliveryBegin: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ name: null, position: null, number: null, factor: null, type: null, maloId: null, meloId: null, deliveryBegin: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.createMeterModal.title")} icon="monitor-heart-rate" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.subsidiary.styled.createMeterModal.name")} adjustWidth />
                    <Input date value={this.state.deliveryBegin} adjustWidth onChange={deliveryBegin => this.setState({ deliveryBegin })} icon="hourglass-start" label={i18next.t("page.private.subsidiary.styled.createMeterModal.deliveryStart")} />
                    <Select required adjustWidth value={this.state.type} onChange={type => this.setState({ type })} icon="tachometer" label={i18next.t("page.private.subsidiary.styled.createMeterModal.counterUnit")} items={[
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.electricity"), key: "electricity", icon: "plug" },
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.water"), key: "water", icon: "water" },
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.gas"), key: "gas", icon: "burn" },
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.teleheating"), key: "teleheating", icon: "heat" },
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.heatQuantity"), key: "heatQuantity", icon: "heat" },
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.cold"), key: "cold", icon: "snowflake" }
                    ]} />
                    <Input required value={this.state.position} onChange={position => this.setState({ position })} icon="map-marker" label={i18next.t("page.private.subsidiary.styled.createMeterModal.position")} adjustWidth />
                    <Input value={this.state.number} onChange={number => this.setState({ number })} icon="sort-numeric-up-alt" label={i18next.t("page.private.subsidiary.styled.createMeterModal.counterNumber")} adjustWidth />
                    <Input value={this.state.maloId} onChange={maloId => this.setState({ maloId })} icon="sort-numeric-up-alt" label={i18next.t("page.private.subsidiary.styled.createMeterModal.marketLocationNumber")} adjustWidth />
                    <Input value={this.state.meloId} onChange={meloId => this.setState({ meloId })} icon="sort-numeric-up-alt" label={i18next.t("page.private.subsidiary.styled.createMeterModal.measurementLocationNumber")} adjustWidth />
                    <Input float value={this.state.factor} onChange={factor => this.setState({ factor })} icon="wave-square" label={i18next.t("page.private.subsidiary.styled.createMeterModal.factor")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.position || !this.state.type} title={i18next.t("page.private.subsidiary.styled.createMeterModal.exitButton")} icon="monitor-heart-rate" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $position: String!, $number: String, $factor: Float, $type: String!, $subsidiaryId: ID!, $deliveryBegin: DateTime, $meloId: String, $maloId: String) {
                            createMeter(name: $name, position: $position, number: $number, factor: $factor, type: $type, subsidiaryId: $subsidiaryId, deliveryBegin: $deliveryBegin, meloId: $meloId, maloId: $maloId)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        position: this.state.position,
                        number: this.state.number,
                        factor: this.state.factor,
                        type: this.state.type,
                        maloId: this.state.maloId,
                        meloId: this.state.meloId,
                        subsidiaryId: this.props.subsidiaryId,
                        deliveryBegin: this.state.deliveryBegin ? moment(this.state.deliveryBegin, "DD.MM.YYYY").toDate() : null
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createMeter) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
