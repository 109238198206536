import React, { Component } from 'react'
import { Button, Modal, Input, Select } from 'scanmetrix-components'
import styled from 'styled-components'
import i18next from "i18next";

const ModalInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

export default class extends Component {
    state = {
        tradeGroup: null,
        components: null,
        renewalDurationType: null
    }

    render() {
        return <Modal minWidth="768px" instance={this.props.instance}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.newFacilityModal.title")} icon="folder-tree" />
            <Modal.Body adjustHeight="50vh" padding={24}>
                <ModalInner>
                    <Input label={i18next.t("page.private.subsidiary.styled.newFacilityModal.name")} icon='font' required />
                    <Input label={i18next.t("page.private.subsidiary.styled.newFacilityModal.building")} icon='building' required />
                    <Select value={this.state.tradeGroup} onChange={tradeGroup => this.setState({ tradeGroup })} icon="industry" label={i18next.t("page.private.subsidiary.styled.newFacilityModal.trade")} adjustWidth items={[
                        { key: "security", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.security") },
                        { key: "fireBarriers", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.fireBarriers") },
                        { key: "fireExtinguisher", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.fireExtinguisher") },
                        { key: "buildings", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.buildings") },
                        { key: "securityLights", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.securityLights") },
                        { key: "alarms", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.alarms") },
                        { key: "airConditioning", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.airConditioning") },
                        { key: "coldConditioning", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.coldConditioning") },
                        { key: "construction", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.construction") },
                        { key: "fireSecurity", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.fireSecurity") },
                        { key: "heater", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.heater") },
                        { key: "workOrder", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.workOrder") },
                        { key: "electricity", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.electricity") },
                        { key: "swimmingPool", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.tradeTypes.swimmingPool") },
                    ]} />
                    <Select value={this.state.components} onChange={components => this.setState({ components })} icon="puzzle-piece" label={i18next.t("page.private.subsidiary.styled.newFacilityModal.mainMask")} adjustWidth />
                    <Input label={i18next.t("page.private.subsidiary.styled.newFacilityModal.intervalQuantity")} icon='hourglass-half' int />
                    <Select value={this.state.renewalDurationType} onChange={renewalDurationType => this.setState({ renewalDurationType })} icon="history" label={i18next.t("page.private.subsidiary.styled.newFacilityModal.intervalUnit")} adjustWidth items={[
                        { key: "days", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.intervalUnitTypes.days") },
                        { key: "weeks", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.intervalUnitTypes.weeks") },
                        { key: "months", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.intervalUnitTypes.months") },
                        { key: "years", title: i18next.t("page.private.subsidiary.styled.newFacilityModal.intervalUnitTypes.years") },
                    ]} />
                    <Input label={i18next.t("page.private.subsidiary.styled.newFacilityModal.nextMaintenance")} icon='calendar-exclamation' date />
                    <Input label={i18next.t("page.private.subsidiary.styled.newFacilityModal.responsibility")} icon='hard-hat' />
                    <Input label={i18next.t("page.private.subsidiary.styled.newFacilityModal.targetStock")} icon='tally' int />
                </ModalInner>
            </Modal.Body>
            <Modal.Footer buttons={instance => <div style={{ display: 'grid', gridTemplateColumns: 'max-content max-content', gridGap: 16 }}>
                <Button thick title={i18next.t("page.private.subsidiary.styled.newFacilityModal.createSystemButton")} icon="folder-tree" onClick={() => { instance.close() }} />
                <Button thick title={i18next.t("page.private.subsidiary.styled.newFacilityModal.cancelButton")} icon="times" secondary onClick={() => { instance.close() }} />
            </div>} />
        </Modal>
    }
}