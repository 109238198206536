import React, { Component } from "react"
import {Modal, Input, Button, Select} from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { roomId: null, userId: null, firstName: null, lastName: null, positionDescription: null, notes: null, users: [], error: null, loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Users {
                        nodes {
                            id
                            firstName
                            lastName
                            position
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ users: data.data.Users.nodes })
        })
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={roomId => {
            this.setState({ roomId, userId: null, firstName: null, lastName: null, positionDescription: null, notes: null, error: null, loading: false })

            this.file = null
        }}>
            <Modal.Head title={i18next.t("page.private.space.createRoomUserModal.title")} icon="user-plus" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <Select required adjustWidth value={this.state.userId} onChange={userId => this.setState({ userId })} icon="user" label={i18next.t("page.private.space.createRoomUserModal.userImport")} items={this.state.users.map(user => ({
                            key: user.id,
                            title: `${user.firstName} ${user.lastName}`
                        }))} />
                    </div>
                    {!this.state.userId && <Input required value={this.state.firstName} onChange={firstName => this.setState({ firstName })} icon="user-tag" label={i18next.t("page.private.space.createRoomUserModal.firstName")} adjustWidth />}
                    {!this.state.userId && <Input required value={this.state.lastName} onChange={lastName => this.setState({ lastName })} icon="user-tag" label={i18next.t("page.private.space.createRoomUserModal.lastName")} adjustWidth />}
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        {!this.state.userId && <Input value={this.state.positionDescription} onChange={positionDescription => this.setState({ positionDescription })} icon="user-graduate" label={i18next.t("page.private.space.createRoomUserModal.position")} adjustWidth />}
                    </div>
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <Input value={this.state.notes} onChange={notes => this.setState({ notes })} icon="font" label={i18next.t("page.private.space.createRoomUserModal.notes")} adjustWidth />
                    </div>
                </div>
                {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={(!this.state.userId && (!this.state.firstName || !this.state.lastName))} title={i18next.t("page.private.space.createRoomUserModal.exitButton")} icon="user-plus" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($roomId: ID!, $userId: ID, $firstName: String, $lastName: String, $positionDescription: String, $notes: String) {
                            createRoomUser(roomId: $roomId, userId: $userId, firstName: $firstName, lastName: $lastName, positionDescription: $positionDescription, notes: $notes)
                        }
                    `,
                    variables: {
                        roomId: this.state.roomId,
                        userId: this.state.userId,
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        positionDescription: this.state.positionDescription,
                        notes: this.state.notes
                    }
                }).then(result => {
                    if(result.data.createRoomUser) {
                        this.props.refresh()
                        instance.close()
                    } else {
                        this.setState({ loading: false })
                        this.setState({ error: "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut." })
                    }
                })}
            } />} />
        </Modal>
    }
}
