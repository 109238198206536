import React, { Component } from "react"
import { Modal, Button, Input } from "scanmetrix-components"
import i18next from "i18next"

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = { budget: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={budget => this.setState({ budget: budget, loading: false })}>
            <Modal.Head title={i18next.t("page.private.budget.changeBudgetModal.title")} icon="piggy-bank" />
            <Modal.Body padding={24}>
                <Input readOnly={this.state.loading} adjustWidth float label={i18next.t("page.private.budget.changeBudgetModal.yearlyBudget")} icon="calendar-day" value={this.state.budget} format={val => val !== null ? formatter.format(val) : val} onChange={budget => this.setState({ budget })} required />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.budget} thick title={i18next.t("page.private.budget.changeBudgetModal.exitButton")} icon="piggy-bank" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($year: Int!, $value: Float!) {
                            setBudgetYear(year: $year, value: $value)
                        }
                    `,
                    variables: {
                        year: moment().year(),
                        value: this.state.budget
                    }
                }).then(data => {
                    if(data.data.setBudgetYear) {
                        instance.close()
                        this.props.onChange(this.state.budget)
                    } else {
                        this.setState({ loading: false })
                    }
                })
            }} />} />
        </Modal>
    }
}
