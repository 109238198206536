import React, { Component } from "react"
import { Page, Breadcrumbs, Section, GridElement, Button, Table } from "scanmetrix-components"
import CreateBuildingInspectionTemplateModal from "./modals/CreateBuildingInspectionTemplateModal"
import DeleteBuildingInspectionTemplateModal from "./modals/DeleteBuildingInspectionTemplateModal"
import DeleteBuildingInspectionModal from "./modals/DeleteBuildingInspectionModal"

import {getIntervalType} from "../object";
import CreateBuildingInspectionReportTemplateModal from "./modals/CreateBuildingInspectionReportTemplateModal";
import DeleteBuildingInspectionReportTemplateModal from "./modals/DeleteBuildingInspectionReportTemplateModal";
import UpdateBuildingInspectionReportTemplateModal from "./modals/UpdateBuildingInspectionReportTemplateModal";
import {DueDates} from "../dueDates";
import Avatar from "react-avatar";
import PDFModal from "./modals/PDFModal"
import DuplicateBuildingInspectionTemplateModal from "./modals/DuplicateBuildingInspectionTemplateModal";
import i18next from "i18next"

moment.locale("de")

const getStatus = status => {
    switch(status) {
        case "draft": return ({ title: "Entwurf", icon: "pen-ruler" })
        case "published": return ({ title: "Veröffentlicht", icon: "badge-check" })
        case "disabled": return ({ title: "Deaktiviert", icon: "pause" })
    }
}

const getType = type => {
    switch(type) {
        case "safetyInspection": return ({ title: "Sicherheitsbegehung", icon: "hard-hat" })
        case "defectView": return ({ title: "Mängelansicht", icon: "bolt" })
        case "test": return ({ title: "Probelauf", icon: "message-smile" })
        case "visualControl": return ({ title: "Sichtkontrolle", icon: "eye" })
        case "maintenance": return ({ title: "Wartung", icon: "clipboard-list" })
        case "riskAssessment": return ({ title: "Gefährdungsbeurteilung", icon: "brake-warning" })
        case "asa": return ({ title: "ASA-Begehung", icon: "user-doctor" })
        case "sibe": return ({ title: "SiBe-Begehung", icon: "shield-check" })
    }
}

export default class extends Component {
    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()

        fetch(scanmetrix.backendURL + "/buildinginspection-intervals", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ buildingInspectionsDueDates: data })
        })
    }

    fetch() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    BuildingInspectionReportTemplates {
                        nodes {
                            id
                            name
                            content
                            createdAt
                        }
                    }
                    BuildingInspectionTemplates {
                        nodes {
                            id
                            name
                            type
                            status
                            intervalAmount
                            intervalType
                            reportTemplate {
                                id
                                name
                            }
                            steps {
                                id
                            }
                            createdAt
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({
                buildingInspectionTemplates: data.data.BuildingInspectionTemplates.nodes,
                buildingInspectionReportTemplates: data.data.BuildingInspectionReportTemplates.nodes
            })
        })
    }

    state = {
        buildingInspectionTemplates: null,
        buildingInspectionsDueDates: null,
        buildingInspections: null,
        buildingInspectionReportTemplates: null
    }

    render() {
        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "person-walking",
                    title: i18next.t("page.private.buildingInspection.title")
                }
            ]} />
            <PDFModal instance={ref => this.pdfModal = ref} />
            <DuplicateBuildingInspectionTemplateModal refresh={() => this.fetch()} instance={ref => this.duplicateBuildingInspectionTemplateModal = ref} />
            <CreateBuildingInspectionTemplateModal refresh={() => this.fetch()} instance={ref => this.createBuildingInspectionTemplateModal = ref} />
            <DeleteBuildingInspectionModal refresh={() => this.fetch()} instance={ref => this.deleteBuildingInspectionModal = ref} />
            <DeleteBuildingInspectionTemplateModal refresh={() => this.fetch()} instance={ref => this.deleteBuildingInspectionTemplateModal = ref} />
            <CreateBuildingInspectionReportTemplateModal refresh={() => this.fetch()} instance={ref => this.createBuildingInspectionReportTemplateModal = ref} />
            <DeleteBuildingInspectionReportTemplateModal refresh={() => this.fetch()} instance={ref => this.deleteBuildingInspectionReportTemplateModal = ref} />
            <UpdateBuildingInspectionReportTemplateModal refresh={() => this.fetch()} instance={ref => this.updateBuildingInspectionReportTemplateModal = ref} />
            <Section bodyPadding="32px 0 0 0" initialSelected={this.props.location.state ? (this.props.location.state.tab || 0) : 0}>
                <Section.Item title={i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.sectionTitle")} icon="person-walking" amount={this.state.buildingInspectionAmount || 0}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.gridElementTitle")} icon="person-walking" rightContent={<p>{i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.text-1")} <b style={{ color: "#3b97d3" }}><i style={{ marginRight: 0 }} className="far fa-person-walking" /> {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.text-2")}</b> {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.text-3")}</p>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.date"), width: "10%", column: "date", orderField: "created_at" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.status"), width: "10%", column: "status", orderField: "status" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.buildingInspectionTemplate"), width: "20%", column: "buildingInspectionTemplate", orderField: "buildingInspectionTemplate.name" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.branch"), width: "30%", column: "subsidiary", orderField: "subsidiary.name" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.user"), width: "15%", column: "user", orderField: "user.id" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.result"), width: "15%", column: "result" }
                            ]}
                            contextItems={[
                                { title: i18next.t("page.private.buildingInspection.entryDelete"), icon: "trash", onClick: item => this.deleteBuildingInspectionModal.open(item.id)}
                            ]}
                            metaCallback={meta => this.setState({ buildingInspectionAmount: meta.total })}
                            query={`${scanmetrix.nestURL}/v2/building-inspection`}
                            defaultSort="created_at_DESC"
                            onItemClick={buildingInspection => this.pdfModal.open(buildingInspection.id)}
                            map={buildingInspections => buildingInspections.map(buildingInspection => ({
                                ...buildingInspection,
                                user: buildingInspection.user ? <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : buildingInspection.user.email} name={`${buildingInspection.user.first_name} ${buildingInspection.user.last_name}`} className="avatarInner" />
                                    </div>
                                    {buildingInspection.user.first_name} {buildingInspection.user.last_name}
                                </div> : (buildingInspection.serviceDeskUser ? <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : buildingInspection.serviceDeskUser.email} name={`${buildingInspection.serviceDeskUser.first_name} ${buildingInspection.serviceDeskUser.last_name}`} className="avatarInner" />
                                    </div>
                                    {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.sd")} {buildingInspection.serviceDeskUser.first_name} {buildingInspection.serviceDeskUser.last_name}
                                </div> : <><i className="fa-duotone fa-bell-concierge" /> {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.guestUser")}</>),
                                date: moment(buildingInspection.created_at).format("DD.MM.YYYY"),
                                subsidiary: `${buildingInspection.subsidiary.name} ${buildingInspection.subsidiary.label ? ("(" + buildingInspection.subsidiary.label + ") ") : ""}/ ${buildingInspection.subsidiary.address_postal_code} ${buildingInspection.subsidiary.address_city}`,
                                buildingInspectionTemplate: buildingInspection.buildingInspectionTemplate.name,
                                result: <div style={{ color: "white", background: buildingInspection.tickets.length > 0 ? "#3b97d3" : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{buildingInspection.tickets.length} {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.pieces")}</div>,
                                status: <div style={{ color: "white", background: buildingInspection.status === "done" ? "#16a085" : "#f39c12", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{buildingInspection.status === "done" ? "Fertig" : "Aktiv"}</div>
                            }))}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.buildingInspection.upcomingInspections.sectionTitle")} icon="history" amount={(this.state.buildingInspectionsDueDates || []).filter(d => moment().isSameOrAfter(moment(d.due))).length} amountColor={(this.state.buildingInspectionsDueDates || []).filter(d => moment().isSameOrAfter(moment(d.due))).length > 0 ? "#e67e22" : "#16a085"}>
                    <GridElement styled title={i18next.t("page.private.buildingInspection.upcomingInspections.gridElementTitle")} icon="history">
                        <DueDates>
                            <Table
                                head={[
                                    { title: i18next.t("page.private.buildingInspection.upcomingInspections.table.due"), width: "20%", column: "due" },
                                    { title: i18next.t("page.private.buildingInspection.upcomingInspections.table.buildingInspectionTemplate"), width: "20%", column: "buildingInspectionTemplate" },
                                    { title: i18next.t("page.private.buildingInspection.upcomingInspections.table.branch"), width: "30%", column: "subsidiary" },
                                    { title: i18next.t("page.private.buildingInspection.upcomingInspections.table.interval"), width: "15%", column: "interval" },
                                    { title: i18next.t("page.private.buildingInspection.upcomingInspections.table.reclamations"), width: "15%", column: "reclamations" }
                                ]}
                                items={this.state.buildingInspectionsDueDates ? this.state.buildingInspectionsDueDates.sort((a, b) => moment(a.due).valueOf() - moment(b.due).valueOf()).map(buildingInspectionsDueDate => {
                                    const due = moment(buildingInspectionsDueDate.due)

                                    return ({
                                        ...buildingInspectionsDueDate,
                                        buildingInspectionTemplate: <><i className="far fa-memo-circle-check" />{buildingInspectionsDueDate.buildingInspectionTemplate.name}</>,
                                        subsidiary: `${buildingInspectionsDueDate.subsidiary.name} ${buildingInspectionsDueDate.subsidiary.label ? ("(" + buildingInspectionsDueDate.subsidiary.label + ") ") : ""}/ ${buildingInspectionsDueDate.subsidiary.address.postalCode} ${buildingInspectionsDueDate.subsidiary.address.city}`,
                                        interval: `${buildingInspectionsDueDate.intervalAmount} ${getIntervalType(buildingInspectionsDueDate.intervalType)}`,
                                        due: <><div className={`dot ${due.isSameOrBefore(moment()) ? "red" : (moment().add(buildingInspectionsDueDate.intervalAmount / 10, buildingInspectionsDueDate.intervalType).isSameOrBefore(due) ? "green" : "yellow")}`} />{due.fromNow()}</>,
                                        reclamations: <div style={{ color: "white", background: buildingInspectionsDueDate.tickets.length > 0 ? "#3b97d3" : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{buildingInspectionsDueDate.tickets.length} {i18next.t("page.private.buildingInspection.upcomingInspections.pieces")}</div>,
                                    })
                                }) : null}
                            />
                        </DueDates>
                    </GridElement>
                </Section.Item>
                <Section.Item disabled={scanmetrix.checkPermission("BuildingInspectionTemplate") < 3} title={i18next.t("page.private.buildingInspection.inspectionTemplates.sectionTitle")} icon="memo-circle-check" amount={this.state.buildingInspectionTemplates?.length || 0}>
                    <GridElement styled title={i18next.t("page.private.buildingInspection.inspectionTemplates.gridElementTitle")} icon="memo-circle-check" rightContent={<Button title={i18next.t("page.private.buildingInspection.inspectionTemplates.button")} icon="memo-circle-check" thick primary onClick={() => this.createBuildingInspectionTemplateModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.buildingInspection.inspectionTemplates.table.name"), width: "22.5%", column: "name" },
                                { title: i18next.t("page.private.buildingInspection.inspectionTemplates.table.type"), width: "15%", column: "type" },
                                { title: i18next.t("page.private.buildingInspection.inspectionTemplates.table.status"), width: "12.5%", column: "status" },
                                { title: i18next.t("page.private.buildingInspection.inspectionTemplates.table.reportTemplate"), width: "20%", column: "reportTemplate" },
                                { title: i18next.t("page.private.buildingInspection.inspectionTemplates.table.interval"), width: "10%", column: "interval" },
                                { title: i18next.t("page.private.buildingInspection.inspectionTemplates.table.steps"), width: "10%", column: "steps" },
                                { title: i18next.t("page.private.buildingInspection.inspectionTemplates.table.createdAt"), width: "10%", column: "createdAt" }
                            ]}
                            onItemClick={buildingInspectionTemplate => this.props.history.push(`/buildinginspectiontemplate/${buildingInspectionTemplate.id}`)}
                            items={this.state.buildingInspectionTemplates ? this.state.buildingInspectionTemplates.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(buildingInspectionTemplate => ({
                                ...buildingInspectionTemplate,
                                raw: buildingInspectionTemplate,
                                createdAt: moment(buildingInspectionTemplate.createdAt).fromNow(),
                                interval: `${buildingInspectionTemplate.intervalAmount} ${getIntervalType(buildingInspectionTemplate.intervalType)}`,
                                steps: <div style={{ color: "white", background: buildingInspectionTemplate.steps.length > 0 ? "#3b97d3" : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{buildingInspectionTemplate.steps.length} {i18next.t("page.private.buildingInspection.inspectionTemplates.pieces")}</div>,
                                type: <><i className={`far fa-${getType(buildingInspectionTemplate.type).icon}`} /> {getType(buildingInspectionTemplate.type).title}</>,
                                status: <><i className={`far fa-${getStatus(buildingInspectionTemplate.status).icon}`} /> {getStatus(buildingInspectionTemplate.status).title}</>,
                                reportTemplate: buildingInspectionTemplate.reportTemplate ? <><i className="far fa-file-pdf" /> {buildingInspectionTemplate.reportTemplate.name}</> : <><i className="far fa-times" /> {i18next.t("page.private.buildingInspection.inspectionTemplates.no")}</>
                            })) : null}
                            contextItems={[
                                { title: "Duplizieren", icon: "copy", onClick: item => this.duplicateBuildingInspectionTemplateModal.open({ ...item, type: item.raw.type, reportTemplateId: item.raw.reportTemplate ? item.raw.reportTemplate.id : null })},
                                { title: "Eintrag löschen", icon: "trash", onClick: item => this.deleteBuildingInspectionTemplateModal.open(item.id)},
                                { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: buildingInspectionTemplate => window.open(`/buildinginspectiontemplate/${buildingInspectionTemplate.id}`, '_blank')}
                            ]}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item disabled={scanmetrix.checkPermission("BuildingInspectionReportTemplate") < 3} title={i18next.t("page.private.buildingInspection.reportTemplates.sectionTitle")} icon="file-pdf" amount={this.state.buildingInspectionReportTemplates?.length || 0}>
                    <GridElement styled title={i18next.t("page.private.buildingInspection.reportTemplates.gridElementTitle")} icon="file-pdf" rightContent={<div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
                        {/*<Button thick title="Standardvorlage herunterladen" icon="download" secondary />*/}
                        <Button title={i18next.t("page.private.buildingInspection.reportTemplates.button")} icon="file-pdf" thick primary onClick={() => this.createBuildingInspectionReportTemplateModal.open()} />
                    </div>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.buildingInspection.reportTemplates.table.name"), width: "85%", column: "name" },
                                { title: i18next.t("page.private.buildingInspection.reportTemplates.table.createdAt"), width: "15%", column: "createdAt" }
                            ]}
                            items={this.state.buildingInspectionReportTemplates ? this.state.buildingInspectionReportTemplates.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(buildingInspectionReportTemplate => ({
                                ...buildingInspectionReportTemplate,
                                createdAt: moment(buildingInspectionReportTemplate.createdAt).fromNow()
                            })) : null}
                            onItemClick={buildingInspectionReportTemplate => this.updateBuildingInspectionReportTemplateModal.open(buildingInspectionReportTemplate)}
                            contextItems={[
                                { title: i18next.t("page.private.buildingInspection.entryDelete"), icon: "trash", onClick: item => this.deleteBuildingInspectionReportTemplateModal.open(item.id)}
                            ]}
                        />
                    </GridElement>
                </Section.Item>
            </Section>
        </Page>
    }
}
