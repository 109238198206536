import React, { Component } from "react"
import { Modal, Button, Input, GridElement } from "scanmetrix-components";
import i18next from "i18next"

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})


const getNotices = accountingInvoice => {
    let warnings = []
    let infos = []

    if(accountingInvoice) {
        if(moment().isBefore(moment(accountingInvoice.date))) warnings.push("Rechnungsdatum liegt in der Zukunft.")
        if(accountingInvoice.gross < accountingInvoice.net) warnings.push("Bruttobetrag liegt unter Nettobetrag.")
        if(accountingInvoice.gross === accountingInvoice.net) infos.push("Rechnung enthält 0% Steuern.")

        let tax = Math.round(((accountingInvoice.gross - accountingInvoice.net) / accountingInvoice.net) * 100)

        if(tax !== 0 && tax !== 16 && tax !== 7 && tax !== 19) warnings.push("Ungewöhnlicher Steuersatz erkannt: " + tax + "%")

        if(accountingInvoice.purchaseOrder) {
            const purchaseOrder = accountingInvoice.purchaseOrder
            const positions = ((typeof purchaseOrder.positions === "string") ? JSON.parse(purchaseOrder.positions) : purchaseOrder.positions) || []
            const netPrice = positions.map(item => (parseFloat(item.price, 10) || 0) * (parseFloat(item.amount, 10) || 0)).reduce((a, b) => a + b, 0)
            const grossPrice = positions.map(item => (parseFloat(item.price, 10) || 0) * (parseFloat(item.amount, 10) || 0) * (((parseFloat(item.taxRate, 10) || 0) + 100) / 100)).reduce((a, b) => a + b, 0)

            if(netPrice !== accountingInvoice.net) warnings.push("Nettobetrag weicht zwischen PO und Rechnung ab.")
            if(grossPrice !== accountingInvoice.gross) warnings.push("Bruttobetrag weicht zwischen PO und Rechnung ab.")
            if(moment(accountingInvoice.date).isBefore(moment(purchaseOrder.date))) warnings.push("Rechnungsdatum liegt vor Beauftragungsdatum.")
        } else {
            infos.push("Es wurde kein Bestellbezug in Form einer PO angegeben.")
        }
    }

    return { warnings, infos }
}

export { getNotices }

export default class extends Component {
    state = { accountingInvoice: null, notes: null, acceptLoading: false, declineLoading: false }

    render() {
        const accountingInvoice = this.state.accountingInvoice

        const { warnings, infos } = getNotices(accountingInvoice)

        return <Modal minWidth="650px" instance={this.props.instance} initialize={accountingInvoice => this.setState({ accountingInvoice, notes: accountingInvoice.notes, acceptLoading: false, declineLoading: false })}>
            <Modal.Head title={i18next.t("page.private.accounting.factualClearanceModal.title")} icon="user-check" />
            <Modal.Body padding={24}>
                {accountingInvoice && <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 32, marginBottom: 32 }}>
                    {accountingInvoice.declined && <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <Modal.Divider title={i18next.t("page.private.accounting.factualClearanceModal.notesAccountant")} />
                        <Input label={i18next.t("page.private.accounting.factualClearanceModal.rejectionReason")} icon="sticky-note" value={accountingInvoice.notesAccounting || "Keine Angabe"} readOnly adjustWidth textArea />
                    </div>}
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <Modal.Divider title={i18next.t("page.private.accounting.factualClearanceModal.document")} />
                        <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "500px" }}><i style={{ width: 24 }} className="far fa-file-invoice" />{i18next.t("page.private.accounting.factualClearanceModal.invoiceDocument")} <a style={{ textDecoration: "none", fontWeight: "bold", color: "#3b97d3" }} href={scanmetrix.backendURL + "/accounting-invoice-document/" + accountingInvoice.accountingInvoiceDocument.id} target="_blank">{accountingInvoice.accountingInvoiceDocument.name}</a></div>
                        {accountingInvoice.purchaseOrder && <div style={{ marginTop: 12, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "500px" }}><i style={{ width: 24 }} className="far fa-wrench" />{i18next.t("page.private.accounting.factualClearanceModal.orderDocument")} <a style={{ textDecoration: "none", fontWeight: "bold", color: "#3b97d3" }} href={scanmetrix.backendURL + "/purchaseorder/pdf/" + accountingInvoice.purchaseOrder.id} target="_blank">{accountingInvoice.purchaseOrder.number}{i18next.t("page.private.accounting.factualClearanceModal.pdf")}</a></div>}
                    </div>
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <Modal.Divider title={i18next.t("page.private.accounting.factualClearanceModal.warning")} />
                        {warnings.length === 0 && infos.length === 0 && <div style={{ marginTop: 16, marginBottom: 16, gridColumnStart: 1, gridColumnEnd: 3, color: "#1abc9c", fontWeight: "bold", userSelect: "none" }}><i className="far fa-check-circle" style={{ marginRight: 8 }} />{i18next.t("page.private.accounting.factualClearanceModal.noWarning")}</div>}
                        {(warnings.length > 0 || infos.length > 0) && <div style={{ marginTop: 16, marginBottom: 16, gridColumnStart: 1, gridColumnEnd: 3, userSelect: "none" }}>
                            <ul>
                                {warnings.map((warning, index) => <li style={{ listStyleType: "none", marginBottom: 8, fontWeight: "bold", color: "#e67e22" }} key={index}><i className="far fa-exclamation-circle" style={{ marginRight: 8 }} />{warning}</li>)}
                                {infos.map((info, index) => <li style={{ listStyleType: "none", marginBottom: 8, fontWeight: "bold", color: "#3b97d3" }} key={index}><i className="far fa-info-circle" style={{ marginRight: 8 }} />{info}</li>)}
                            </ul>
                        </div>}
                        <div style={{ marginBottom: 16 }}>
                            <Modal.Divider title={i18next.t("page.private.accounting.factualClearanceModal.readInvoiceInformation")} />
                        </div>
                    </div>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input readOnly label={i18next.t("page.private.accounting.factualClearanceModal.billDate")} icon="calendar-day" date adjustWidth value={moment(accountingInvoice.date).format("DD.MM.YYYY")} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input readOnly label={i18next.t("page.private.accounting.factualClearanceModal.billNumber")} icon="file-invoice" adjustWidth value={accountingInvoice.number} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input readOnly label={i18next.t("page.private.accounting.factualClearanceModal.netto")} icon="piggy-bank" format={val => val !== null ? formatter.format(val) : val} float adjustWidth value={accountingInvoice.net} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input readOnly label={i18next.t("page.private.accounting.factualClearanceModal.brutto")} icon="piggy-bank" format={val => val !== null ? formatter.format(val) : val} float adjustWidth value={accountingInvoice.gross} />
                    </GridElement>
                </div>}
                <div style={{ marginBottom: 32 }}>
                    <Modal.Divider title={i18next.t("page.private.accounting.factualClearanceModal.audit")} />
                </div>
                <Input label={i18next.t("page.private.accounting.factualClearanceModal.accountantNote")} placeholder={i18next.t("page.private.accounting.factualClearanceModal.newAccount")} icon="sticky-note" textArea adjustWidth value={this.state.notes} onChange={notes => this.setState({ notes })} />
            </Modal.Body>
            <Modal.Footer buttons={instance => <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
                <Button loading={this.state.acceptLoading} disabled={this.state.declineLoading} thick title={i18next.t("page.private.accounting.factualClearanceModal.confirmButton")} icon="check" onClick={() => {
                    this.setState({ acceptLoading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($id: ID!, $notes: String) {
                                acceptAccountingInvoice(id: $id, notes: $notes)
                            }
                        `,
                        variables: {
                            id: accountingInvoice.id,
                            notes: this.state.notes
                        }
                    }).then(data => {
                        if(data.data.acceptAccountingInvoice) {
                            return this.props.fetch(true).then(() => instance.close())
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                }} />
                <Button loading={this.state.declineLoading} disabled={this.state.acceptLoading} secondary thick title="Rechnung ablehnen" icon="times" onClick={() => {
                    this.setState({ declineLoading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($id: ID!, $notes: String) {
                                declineAccountingInvoice(id: $id, notes: $notes)
                            }
                        `,
                        variables: {
                            id: accountingInvoice.id,
                            notes: this.state.notes
                        }
                    }).then(data => {
                        if(data.data.declineAccountingInvoice) {
                            return this.props.fetch(false).then(() => instance.close())
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                }} />
            </div>} />
        </Modal>
    }
}
