{
  "en": {
    "translation": {
      "component": {
        "table": {
          "searchPlaceholder": "Search for entry...",
          "page": "Page",
          "entries": "Entries",
          "content": "This table has no entries.",
          "button": {
            "begin": "Beginning",
            "back": "Back",
            "further": "Further",
            "end": "End"
          }
        },
        "iconSelector_factsheets": {
          "label": "Select symbolism",
          "searchPlaceholder": "Search for an Icon..."
        },
        "select_gewerke_dropdown": {
          "removeSelection": "Remove selection",
          "noResult": "No Results Found.",
          "searchPlaceholder": "Search for entry..."
        },
        "dataForm": {
          "inputScreen": {
            "title": "This input mask has no content.",
            "description": "First fill the input mask in the editor with sections to continue"
          },
          "section": {
            "title": "This section has no content.",
            "description": "First fill the section in the input form editor with input fields to continue."
          }
        }
      },
      "defaults": {
        "durationType": {
          "days": "days",
          "weeks": "weeks",
          "months": "Months",
          "years": "Years"
        },
        "salutation": {
          "male": "Mister",
          "female": "Woman",
          "maleDr": "Dr.",
          "femaleDr": "Miss Dr.",
          "maleProfDr": "Prof. Dr.",
          "femaleProfDr": "Ms. Prof. Dr.",
          "maleProfDrMed": "Prof. Dr. ",
          "femaleProfDrMed": "Ms. Prof. Dr. ",
          "maleDrMed": "Dr. ",
          "femaleDrMed": "Miss Dr. ",
          "maleDiplIng": "Mr. Dipl.-Ing.",
          "femaleDiplIng": "Ms. Dipl.-Ing.",
          "maleDiplKfm": "Mr. Dipl.-Kfm.",
          "femaleDiplKfr": "Ms. Dipl.-Kfr."
        },
        "button": {
          "login": "Register",
          "cancel": "Cancel",
          "confirm": "Confirm",
          "send": "Submit",
          "reset": "Reset to default",
          "close": "Close"
        },
        "input": {
          "email": "E-mail address",
          "password": "Password",
          "passwordRepeat": "Repeat password",
          "firstName": "First name",
          "lastName": "Last name",
          "salutation": "Salutation"
        },
        "slogan": "Digital. "
      },
      "notification": {
        "CONVERSATION_CREATED": {
          "title": "New conversation: {{topic}}",
          "description": "{{name}}: {{content}}"
        },
        "CONVERSATION_REPLY_CREATED": {
          "title": "New answer in conversation: {{topic}}",
          "description": "{{name}}: {{content}}"
        }
      },
      "navigation": {
        "placeholders": {
          "mission": "scanmetrix Mission",
          "serviceDesk": "Servicedesk",
          "feedback": "Feedback & Help",
          "language": "Change language",
          "perspective": "Change perspective",
          "notifications": "Notifications",
          "integrations": "Integrations",
          "marketplace": "Marketplace",
          "settings": "Settings",
          "signOut": "Sign out"
        },
        "sideNavigation": {
          "language": {
            "topTitle": "Select language"
          },
          "perspective": {
            "topTitle": "Select perspective",
            "choiceButton": "Selected",
            "germanTitle": "German",
            "germanDescription": "Switch to the German interface.",
            "englishTitle": "English (Beta)",
            "englishDescription": "Switch to the English interface.",
            "notificationSettings": "Notification Settings",
            "facilityManagement": {
              "title": "Facility Management",
              "description": "Manage your technical building equipment, plans, contact persons, due dates and defect escalation."
            },
            "propertyManagement": {
              "title": "Property Management",
              "description": "Manage utility costs, sewer fees, landlord fees"
            }
          },
          "notification": {
            "date": "Today",
            "message": "Show older notifications..."
          },
          "organisation": {
            "title": "Organisation",
            "dashboard": "Dashboard",
            "calendar": "Calendar",
            "map": "Map"
          },
          "tasks": {
            "title": "Tasks",
            "agenda": "Agenda",
            "ticket": "Ticket system",
            "purchaseOrder": "Orders",
            "measure": "Measures",
            "workflow": "Workflows"
          },
          "administration": {
            "title": "Administration",
            "serviceProviders": "Service provider",
            "users": "Users",
            "contracts": "Contract management",
            "documents": "Documents",
            "cars": "Fleet"
          },
          "liability": {
            "title": "Operator liability",
            "branches": "Subsidiary",
            "tgaDueDates": "TGA due dates",
            "escalation": "Escalation",
            "inspections": "Inspections"
          },
          "fiCo": {
            "title": "Finance & Controlling",
            "accounting": "Receipt documents",
            "budget": "Budgeting"
          },
          "buildingTechnology": {
            "title": "Building technology",
            "msr": "Smart Building",
            "spacemanagement": "Space management"
          },
          "freeVersion": "Free trial version",
          "text1": "There are still",
          "text2": "days in your trial version."
        },
        "indexNavigation": {
          "version": "version",
          "feedback": {
            "publicRoadMap": {
              "title": "Public Roadmap",
              "description": "Report a general bug, request a feature or suggest an improvement - and always stay up to date."
            },
            "openConsultationHours": {
              "title": "Open consultation hours",
              "description": "Twice a week we organize an open, personal consultation hour led by employees of our company in order to respond specifically to your questions and suggestions."
            },
            "directMessage": {
              "title": "Direct message",
              "description": "Discuss a very specific topic, report a confidential problem, or ask a question."
            },
            "helpArea": {
              "title": "Help area",
              "description": "Articles that will help you use scanmetrix - if you get stuck."
            },
            "tours": {
              "title": "Interactive tours",
              "description": "Interactive tours which guide you through the usage of scanmetrix Cockpit."
            }
          }
        }
      },
      "page": {
        "private": {
          "dashboard": {
            "welcome": {
              "title": "Welcome back, {{name}}.",
              "content": "You have completed <b>{{agendaPercentage}}%</b> from your agenda."
            },
            "facilityManagement": {
              "percent": "percent",
              "chat": "To chat",
              "profileVisit": "Visit profile",
              "online": "Online",
              "offline": "Offline",
              "notBookedModule": "This function is not included in your booked modules.",
              "learnMore": "Learn more",
              "activeUsers": "User Activities",
              "cost": "Actual/target costs",
              "fm": "FM",
              "energyConsumption": "Energy consumption",
              "visualizeFuntion": "Sample data to visualize function!",
              "costs": {
                "targetCosts": "Cost target",
                "actualCosts": "Cost is",
                "budgetDefinition": "Budget definition"
              },
              "facilityManagement": {
                "newInventories": "New inventories",
                "maintenanceCarriedOut": "Maintenance carried out",
                "dueDatesExceeded": "Exceeded due dates"
              },
              "energy": {
                "target": "Should.",
                "actual": "Is"
              },
              "OpenConsultationModal": {
                "titleForm": "New now: open consultation hours",
                "description1": "New now:",
                "description2": "Open consultation hour",
                "description3": "Starting in October 2023, we will be holding an open consultation hour online up to twice a week, which all customers and end users can attend by prior arrangement.",
                "description4": "The consultation hour is the right place for suggestions, process coordination, feedback and application questions.",
                "description5": "By introducing these meetings, we hope to strengthen personal contact with our customers and provide more insight into the further development of our platform.",
                "description6": "You can register around the clock by clicking on the",
                "description7": "Megaphone symbol",
                "description8": "in the menu bar on the left",
                "exitButton": "Understood, do not show again."
              },
              "testPhase": {
                "titleForm": "Welcome to the test phase",
                "description1": "We warmly welcome you to the test phase of the scanmetrix cockpit.",
                "description2": "As part of your test program, you have the opportunity to test pre-selected modules for their functionality and clarity.",
                "description3": "Certain modules were maintained in advance with example data, which would otherwise require an existing data master in order to better visualize the function.",
                "description4": "Some elements were deliberately limited in their functionality in order to give you the best possible perspective on the overall scope.",
                "description5": "The aim of your test phase is to give you not only the current range of functions but also the...",
                "description6": "scanmetrix® CFDS protocol",
                "description7": "to present a solution that ensures the self-sufficient, cross-disciplinary provision of data from your technical systems - we have already provided you with example data from an existing customer.",
                "description8": "If you have any questions about handling, modules or functionality, please do not hesitate to contact us.",
                "description9": "We wish you a lot of fun and a pleasant Christmas season.",
                "description10": "- Your scanmetrix.fm GmbH team",
                "exitButton11": "Got it, don't show it again in the future"
              },
              "activeUsersShow": "View all activities",
              "button": {
                "back": "Back",
                "close": "Close",
                "last": "Close",
                "next": "Next",
                "open": "Open dialog",
                "skip": "Skip"
              },
              "overallRating": {
                "Rating1": "This tile shows you overdue contractual services (so-called service cycles).",
                "Rating2": "This tile shows you open tracking of performance records for your contracts.",
                "Rating3": "This tile shows you undeclared assignments of your subsidiaries to the Work sections declared in the settings.",
                "Rating4": "This tile describes the number of TGA systems that are still pending maintenance or inspection by a service provider and are overdue.",
                "Rating5": "This tile describes the number of defects that have not yet been remedied that have been recorded by a service provider in your TGA systems.",
                "Rating6": "This statistic describes the percentage of functional TGA objects in your subsidiaries.",
                "ratingImprovement": "How do I improve my rating?",
                "overallRatingTitle": "Overall rating",
                "factors": "Factors",
                "functionalReadiness": "Functional readiness",
                "correcteddeficiencies": "Fixed defects",
                "complianceInspectionDeadlines": "Compliance with inspection deadlines"
              },
              "overview": "Overview",
              "overdueInspections": "Overdue inspections",
              "inventoriedObjects": "Inventorized objects",
              "activeUser": "Active users",
              "branches": "Subsidiaries",
              "serviceProvider": "Service provider",
              "defects": "Open defects",
              "overdueServices": "Overdue services",
              "proofPerformance": "Follow-up proof of performance",
              "undeclaredTradeAssignments": "Undeclared work section assignments",
              "overdueTGA": "Overdue TGA inspections",
              "pendingTGA": "Upcoming TGA exams",
              "upcomingDueDates": "Upcoming due dates",
              "performanceCycles": "Performance cycles",
              "TGASystems": "TGA systems",
              "viewAllContract": "View all",
              "tableLZ": {
                "identifier": "Code",
                "place": "Location",
                "name": "Name",
                "serviceProvider": "service provider",
                "due": "Due"
              },
              "performanceCyclesDescription": {
                "text1": "There are no due dates.",
                "text2": "As soon as the due date of a performance cycle approaches, it is automatically displayed here."
              },
              "tableTGA": {
                "identifier": "Code",
                "place": "Location",
                "name": "Name",
                "serviceProvider": "Service providers",
                "due": "Due"
              },
              "TGASystemsDescription": {
                "text1": "There are no due dates.",
                "text2": "As soon as the maturity of an investment approaches, it is automatically displayed here."
              },
              "dashboardCalendar": "Calendar",
              "viewAllCalendar": "View all",
              "recentActiveUsers": "Recently active users",
              "FMnews": "Facility Management News",
              "fmWebsite": "Powered by facility-management.de",
              "agendaDashboard": {
                "title": "Agenda",
                "and": "...and",
                "furtherEntries": "more entries",
                "description": "You have nothing on your agenda."
              },
              "latest": "News",
              "facilityManagementStatistics": "Facility Management",
              "functionalObjects": "Functional objects",
              "correctedDefects": "Fixed defects",
              "complianceInspectionDeadlines": "Compliance with inspection deadlines"
            },
            "propertyManagement": {
              "selectYear": "Select year",
              "savingShares": "Savings shares",
              "processingStatuses": "Processing states",
              "pieces": " pcs.",
              "pieChartValues": {
                "operatingCosts": "Operating costs",
                "sewageCharges": "Exemption from sewage charges",
                "notApplicable": "Not applicable",
                "pending": "Pending",
                "inProcess": "In Progress",
                "done": "Completed",
                "rebuffed": "Rejected",
                "requested": "Requested",
                "notRequested": "Not requested"
              },
              "operatingCost": {
                "operatingCostTitle": "Operating cost",
                "operatingCostSavings": "Saved through operating cost bills",
                "branchSavings": "Average savings per subsidiary"
              },
              "wastewater": {
                "wastewaterTitle": "Wastewater fee exemption",
                "wastewaterSavings": "Saved through wastewater fee exemption",
                "branchSavings": "Average savings per Subsidiary"
              },
              "monthlySavings": "Savings by month",
              "totalSavings": "Total savings",
              "branch": "Subsidiary",
              "content": "Missing registration of the easement",
              "serviceCharges": "Extra costs",
              "contractManagement": "Contract management",
              "viewAll": "View all",
              "meterReadings": "Meter readings",
              "missingMeter0101": "Missing meter readings until January 1st.",
              "missingMeter1507": "Missing meter readings until July 15th.",
              "recentActiveUsers": "Recently active users",
              "contractManagementTable": {
                "deadlineType": "Deadline type",
                "deadlineDate": "Deadline date",
                "contractNumber": "Contract number",
                "contractPartner": "Contractual partner",
                "automaticRenewal": "Automatic renewal",
                "contractType": "Contract type"
              },
              "yes": "Ja",
              "no": "Nein",
              "contractDatesModal": {
                "title": "All contract management appointments"
              },
              "deadlineType": {
                "extensionOption": "Extension options",
                "contractStart": "Start of contract",
                "contractEnd": "End of contract",
                "terminationPeriod": "notice period",
                "automaticRenewal": "Automatic renewal"
              },
              "contractType": {
                "onDemand": "Service on call",
                "surveyor": "Expert",
                "maintenance": "Maintenance contract / service contract",
                "general": "Framework agreement",
                "rent": "Rental agreement & any addenda",
                "rentAd": "Advertising rental agreement & any addenda",
                "rentSub": "Sublease agreement & any addenda",
                "commodate": "Loan agreement",
                "nonCashLending": "Loan in kind agreement",
                "endowment": "Gift agreement",
                "barter": "Exchange contract",
                "purchase": "Purchase agreement",
                "loan": "Loan agreement",
                "service": "Service contract",
                "work": "Contract for work",
                "leasing": "leasing contract",
                "lease": "Lease agreement",
                "deployment": "employment contract",
                "guarantee": "Guarantee contract",
                "saas": "SaaS contract",
                "estateDuties": "Land charges",
                "energyPower": "Energy contract (electricity)",
                "energyWater": "Energy contract (water)",
                "energyGas": "Energy contract (gas)",
                "energyHeat": "Energy contract (heat)",
                "insurance": "Insurance contract",
                "eegReward": "EEG remuneration",
                "other": "Other contracts"
              }
            }
          },
          "calendar": {
            "upcomingAppointments": {
              "title": "Upcoming appointments",
              "noAvailableAppointments": "No appointments available.",
              "fireExtinguisherMaintenance": "Maintenance fire extinguisher",
              "time": "10:00 a.m. - 4:00 p.m",
              "description": "Fire extinguisher SM-1-34001 foam extinguisher expired."
            },
            "legendForm": {
              "title": "Create new appointment",
              "startDate": "Start date",
              "startTime": "Start time",
              "endDate": "End date",
              "endTime": "End time",
              "allDay": "All day",
              "name": "Name",
              "place": "location",
              "description": "Description",
              "proofOfPerformance": "certificate of achievement",
              "SubsidiaryFilter": "Filter by subsidiary",
              "contract": "Contract",
              "performanceCycleContract": "Performance cycle (select contract first)",
              "intervalTypes": {
                "days": "days",
                "weeks": "weeks",
                "months": "Months",
                "years": "Years"
              },
              "exitButton": "Create an appointment"
            },
            "bottomCalendarForm": {
              "titleShow": "Show appointment",
              "titleUpdate": "Update appointment",
              "startDate": "Start date",
              "startTime": "Start time",
              "endDate": "End date",
              "endTime": "End time",
              "allDay": "All day",
              "name": "Name",
              "place": "location",
              "description": "Description",
              "updateButton": "Update appointment",
              "deleteButton": "Delete appointment"
            },
            "legend": {
              "title": "Legend",
              "vacationDays": "Holidays",
              "expertAppointments": "Expert appointments",
              "maintenance": "Maintenance",
              "deadlines": "Deadlines",
              "generalAppointments": "General dates",
              "synchronizedAppointments": "Synchronized appointments",
              "inspectionAppointments": "Inspection dates",
              "performanceCycles": "Performance cycles"
            },
            "legendButtons": {
              "newvacationDays": "Enter vacation time",
              "newexpertAppointments": "New expert appointment",
              "newmaintenance": "New maintenance or service date",
              "newInspectionAppointments": "New inspection date",
              "newgeneralAppointments": "New general appointment",
              "newGoogleAppointments": "New Google appointment"
            },
            "synchronizedAppointmentsText": "Sync appointments with your integrations...",
            "bottomCalendar": {
              "further": "Further",
              "back": "Back",
              "today": "Today",
              "month": "Month",
              "allDay": "All day"
            },
            "featureTooltip": {
              "performanceCycle": "Performance cycle:",
              "branch": "Subsidiary:",
              "interval": "Interval:",
              "intervalTypes": {
                "days": "days",
                "weeks": "weeks",
                "months": "Months",
                "years": "Years"
              },
              "contractReference": "Contract reference:",
              "contractNumber": "Contract number:",
              "contractPartner": "Contractual partner:",
              "exitButton": "About the contract"
            }
          },
          "map": {
            "searchPlaceholder": "Search by location using zip code, name, city or tag...",
            "mapData": "Loading card data...",
            "workflowChoose": "Select workflow",
            "Perspective": {
              "title": "Change of perspective",
              "PerspectiveChanger": "Facility management",
              "workFlows": "Workflows",
              "establishmentTypes": "Types of establishment",
              "subtenant": "Subtenants",
              "status": "Status"
            },
            "pieChartValues": {
              "urgentActionNeeded": "Urgent need for action",
              "mediumActionNeeded": "Medium need for action",
              "noActionNeeded": "No need for action",
              "noDataAvailabe": "No data available",
              "noPhase": "No phase",
              "noEstablishment": "No type of establishment",
              "noStatus": "No status"
            },
            "toggleView": {
              "hide": "Hide all controls",
              "show": "Show all controls"
            },
            "displayedBranches": "Displayed subsidiaries",
            "popUp": {
              "title": "Name:",
              "legalName": "Legal ",
              "address": "Address:",
              "button": "To the subsidiary"
            }
          },
          "agenda": {
            "concernsBranchOffice": "Concerns subsidiary",
            "completedOn": "Done on",
            "from": "from",
            "searchPlaceholderComment": "Add comment...",
            "noEntries": "There are no entries in this agenda.",
            "newAgendaEntryButton": "New agenda entry",
            "searchPlaceholder": "Search for tasks...",
            "agendaForm": {
              "title": "Create a new agenda entry",
              "priority": {
                "priorityTitle": "Priority",
                "priorityTypes": {
                  "low": "Low",
                  "medium": "Medium",
                  "high": "High"
                },
                "deadline": "Deadline",
                "settlement": "Subsidiary",
                "title": "Title",
                "description": "Description",
                "button": "Create agenda entry"
              }
            },
            "comment": {
              "title": "Delete comment",
              "description": "Are you sure you want to delete this comment? ",
              "button": "Delete comment"
            },
            "sorting": {
              "sortingTitle": "Sort by:",
              "sortingTypes": {
                "newestFirst": "Newest first",
                "oldestFirst": "Oldest first",
                "priorityHigh": "Priority high",
                "priorityLow": "Priority low"
              }
            },
            "sectionItem": {
              "all": "All",
              "serviceProvider": "Service Provider",
              "own": "Own",
              "googleTasks": "Google Tasks",
              "archive": "Archive"
            }
          },
          "ticket-system": {
            "breadCrumbsTitle": "Ticket system",
            "tickets": {
              "title": "Tickets (Kanban view)",
              "gridElementTitle": "Filter",
              "button": "Open new ticket",
              "searchPlaceholder": {
                "branch": "Filter by subsidiary",
                "ticketEscalationStep": "Filter by escalation level",
                "priority": "Filter by priority",
                "priorityTypes": {
                  "low": "Low",
                  "medium": "Medium",
                  "high": "High"
                },
                "user": "Filter by user",
                "ticket": "Filter by ticket type",
                "source": "Filter by source",
                "sourceTypes": {
                  "manual": "Custom",
                  "servicedesk": "Service Desk",
                  "buildingInspection": "Inspection"
                },
                "inspection": "Inspection",
                "from": "from"
              },
              "status": {
                "unassigned": "Unassigned",
                "assigned": "Assigned",
                "inProcess": "In Progress",
                "completed": "Completed"
              },
              "switchCases": {
                "lowPriority": "Low Priority",
                "mediumPriority": "Medium Priority",
                "highPriority": "High Priority",
                "technicalDefect": "Technical Defect",
                "malfunction": "Malfunction",
                "replacementProcurement": "Replacement Procurement",
                "warrantly": "Warrantly",
                "cleaning": "Cleaning",
                "inquiry": "Inquiry",
                "external": "External",
                "other": "Other",
                "unknown": "Unknown",
                "userDefined": "User-defined",
                "serviceDesk": "Servicedesk",
                "inspection": "Inspection"
              }
            },
            "archive": {
              "title": "Archive",
              "gridElementTitle": "Archive",
              "table": {
                "number": "Number",
                "title": "Title",
                "branch": "Subsidiary",
                "source": "Source",
                "type": "Type",
                "priority": "Priority",
                "lastEdited": "Last edited"
              },
              "deleteButton": "Ticket löschen"
            },
            "assignTicketModal": {
              "title": "Assign ticket",
              "description": "Assign to user",
              "exitButton": "Assign ticket"
            },
            "createTicketModal": {
              "titleForm": "Open new ticket",
              "branch": "Subsidiary",
              "assignUser": "Assign to user",
              "smid": "SMID",
              "smidSearchPlaceholder": "SMID",
              "ticketType": "Ticket type",
              "priority": "Priority",
              "priorityTypes": {
                "low": "Low",
                "medium": "Medium",
                "high": "High"
              },
              "title": "Title",
              "titleSearchPlaceholder": "Short description of the issue",
              "description": "Description",
              "descriptionSearchPlaceholder": "Detailed description of the issue",
              "exitButton": "Open new ticket"
            },
            "deleteTicketCommentModal": {
              "title": "Delete comment",
              "description": "Are you sure you want to delete this comment? ",
              "exitButton": "Delete comment"
            },
            "deleteTicketModal": {
              "title": "Delete ticket",
              "description": "Are you sure you want to delete this ticket? ",
              "exitButton": "Delete ticket"
            },
            "ticketEmailModal": {
              "title": "Forward ticket as email",
              "email": "Recipient email address",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "text": "Post text",
              "searchPlaceholderGreetings": "Ladies and Gentlemen...",
              "exitButton": "Send E-mail"
            },
            "updateTicketModal": {
              "switchCases": {
                "creation": {
                  "text1": "Ticket was created manually by",
                  "text2": "",
                  "text3": "Ticket was created in the service desk by",
                  "text4": "",
                  "text5": "Ticket was created in an inspection by",
                  "text6": "",
                  "text7": "Ticket was created"
                },
                "archived": {
                  "text1": "Ticket was archived by",
                  "text2": "",
                  "text3": "Ticket was automatically archived"
                },
                "unarchived": {
                  "text1": "Ticket was unarchived by",
                  "text2": "",
                  "text3": "Ticket was automatically unarchived"
                },
                "clearance": {
                  "text1": "Ticket was sent by",
                  "text2": "with the following reason:",
                  "text3": "Ticket was sent by",
                  "text4": "",
                  "text5": "Ticket was automatically released with the following reason:",
                  "text6": "Ticket was automatically released"
                },
                "deleteFile": {
                  "text1": "File",
                  "text2": "was deleted by",
                  "text3": "",
                  "text4": "File",
                  "text5": "was deleted"
                },
                "uploadFile": {
                  "text1": "File",
                  "text2": "was uploaded by",
                  "text3": "",
                  "text4": "File",
                  "text5": "was uploaded"
                },
                "status": {
                  "text1": "Ticket was changed by",
                  "text2": "to status",
                  "text3": "",
                  "text4": "previously",
                  "text5": "Ticket was changed by",
                  "text6": "to Status",
                  "text7": "previously",
                  "text8": ""
                },
                "email": {
                  "text1": "Ticket was assigned by",
                  "text2": "to email address",
                  "text3": "",
                  "text4": "Post text:"
                },
                "assignment": {
                  "text1": "Ticket was assigned by",
                  "text2": "to",
                  "text3": "",
                  "text4": "previously",
                  "text5": "",
                  "text6": "Ticket was not assigned by",
                  "text7": "to",
                  "text8": "anyone",
                  "text9": "",
                  "text10": "previously",
                  "text11": "",
                  "text12": "Ticket was assigned by",
                  "text13": "to",
                  "text14": "",
                  "text15": "Ticket was not assigned by",
                  "text16": "to",
                  "text17": "anyone",
                  "text18": "",
                  "text19": "Ticket was assigned to",
                  "text20": "",
                  "text21": "previously",
                  "text22": "",
                  "text23": "Ticket was not assigned to",
                  "text24": "anyone",
                  "text25": "",
                  "text26": "previously",
                  "text27": "",
                  "text28": "Ticket was assigned to",
                  "text29": "",
                  "text30": "Ticket was not assigned to",
                  "text31": "anyone",
                  "text32": ""
                }
              },
              "title": "Update ticket",
              "noDescriptionGiven": "No description given.",
              "branch": "Subsidiary",
              "measure": "Measure",
              "inspection": "Inspection",
              "from": "from the",
              "pdf": "PDF",
              "attachements": "Attachments",
              "attachementsDescription": "Place a file here to upload it as an attachment.",
              "uploadFile": "Upload file",
              "noDescriptionUploaded": "No attachments uploaded.",
              "chronology": "Chronology",
              "dearchiveButton": "Unarchive",
              "archiveButton": "Archive",
              "email": "Forward as email",
              "createdAt": "Created on",
              "lastEdited": "Last edited",
              "assigned": "Assigned",
              "nobody": "No one",
              "priority": "priority",
              "ticketType": "Ticket type",
              "source": "source",
              "smid": "SMID",
              "no": "No",
              "comments": "Comments",
              "searchPlaceholderComment": "Write a comment here...",
              "noComments": "No comments yet.",
              "noUserCommentDescription": "No user has yet written a comment for this ticket."
            }
          },
          "purchaseOrder": {
            "type": {
              "service": "Service/Maintenance",
              "construction": "Construction project",
              "goods": "Purchase of goods",
              "other": "Miscellaneous"
            },
            "breadCrumbsTitle": "Orders",
            "orders": {
              "sectionElementTitle": "Orders",
              "draft": {
                "currentStatus": "Current state",
                "titleDraft": "1. Draft",
                "finish": "Complete",
                "resetDraft": "Reset to draft"
              },
              "internalRelease": {
                "currentStatus": "Current state",
                "titleInternalRelease": "2. Internal release",
                "release": "Release",
                "noReleaseAuthorization": "No release permission"
              },
              "commissioning": {
                "currentStatus": "Current state",
                "titleCommissioning": "3. Commissioning",
                "dispatch": "To ship",
                "commissionedOn": "Commissioned on"
              },
              "orderConfirmation": {
                "currentStatus": "Current state",
                "titleOrderConfirmation": "4. Order confirmation",
                "confirm": "Confirm",
                "confirmedOn": "Confirmed on",
                "downloadOrderConfirmation": "Download order confirmation"
              },
              "implementation": {
                "currentStatus": "Current state",
                "completed": "Completed",
                "titleImplementation": "5. Execution",
                "finalize": "Complete",
                "completedOn": "Carried out on"
              },
              "pdfView": "PDF view",
              "headerText": "Header",
              "footerText": "Footer",
              "frameworkData": {
                "gridElementTitle": "Framework data",
                "deleteButton": "Delete PO",
                "editButton": "Edit PO",
                "number": "PO no.",
                "letterHead": "Letterhead",
                "type": "Type",
                "date": "Date",
                "serviceProvider": "Service provider/supplier",
                "branch": "Subsidiary",
                "title": "title",
                "referenz": "Reference/offer"
              },
              "vatNetto": "Order total excluding VAT (net):",
              "vatBrutto": "Order total including VAT (gross):",
              "newPositionButton": "New position",
              "searchPlaceholder": {
                "articleNumber": "Article no.",
                "positionDescription": "Position description",
                "amount": "Crowd",
                "unit": "Unit",
                "price": "Single price",
                "taxRate": "Tax rate"
              }
            },
            "invoice": {
              "sectionElemnentTitle": "Invoices/receipts",
              "gridElementTitle": "Invoices/receipts",
              "table": {
                "status": "Status",
                "number": "Invoice number",
                "date": "Date",
                "serviceProvider": "Service provider",
                "net": "EUR(Net)",
                "gross": "EUR(Gross)",
                "branch": "Subsidiary",
                "purchaseOrder": "Order (PO)"
              }
            },
            "documents": {
              "sectionTitle": "Documents",
              "title": "Documents"
            },
            "approvePurchaseOrderModal": {
              "title": "Release PO",
              "releasedBy": "Released by",
              "releaseDate": "Release date",
              "notes": "Notes",
              "button": "Release PO"
            },
            "cfdsModal": {
              "title": "Send order via CFDS",
              "text1": "After sending the order via CFDS, your service provider will receive an email notification that they have received a new order.",
              "text2": "Important NOTE:",
              "text3": "Using the CFDS protocol is best suited for services in the MEP sector. ",
              "understoodCheckbox": "I have understood",
              "button": "Send bindingly"
            },
            "commissionPurchaseOrderModal": {
              "title": "Order PO",
              "emailTitle": "Send by email",
              "emailDescription": "Send PDF of the order automatically via email via the portal.",
              "cfdsTitle": "Send via CFDS",
              "cfdsDescription": "Provide the service provider with the order digitally (recommended for TGA services).",
              "commissionTitle": "Just mark as commissioned",
              "commissionDescription": "Send the order yourself and mark it as 'ordered'."
            },
            "confirmPurchaseOrderModal": {
              "title": "Confirm order",
              "pdfDescription": "Place the order confirmation here to save it (PDF format required!)",
              "button": "Confirm order"
            },
            "deletePurchaseOrderModal": {
              "title": "Delete PO",
              "description": "Are you sure you want to delete the PO? ",
              "exitButton": "Delete PO"
            },
            "pdfModal": {
              "title": "PDF view"
            },
            "purchaseOrderEmailModal": {
              "title": "Send order as email",
              "email": "Recipient email address",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Subject",
              "searchPlaceholderPo2023": "PO2023 ABC",
              "Receiver": "Text to recipient",
              "searchPlaceholderGreetings": "Ladies and Gentlemen, ...",
              "standardDocumentAttachment": "Attachments (standard documents)",
              "standardAttachment": "Attachments (documents)",
              "exitButton": "Send E-mail"
            },
            "updatePurchaseOrderModal": {
              "titleForm": "Update PO",
              "serviceProvider": "Service provider",
              "letterHead": "Letterhead",
              "type": "Type",
              "typeTypes": {
                "service": "Service/Maintenance",
                "construction": "Construction project",
                "goods": "Purchase of goods",
                "other": "Miscellaneous"
              },
              "subsidiary": "Subsidiary",
              "number": "PO no.",
              "date": "Date",
              "title": "Title",
              "offer": "Reference/offer",
              "button": "Update PO"
            }
          },
          "workOrders": {
            "breadCrumbsTitle": "Orders",
            "gridElementTitle": "Orders",
            "button": "Create order",
            "switchCases": {
              "constructionProject": "Construction project",
              "purchaseGoods": "Purchase of goods",
              "service": "Service/Maintenance",
              "other": "Other services",
              "draft": "Draft",
              "internalRelease": "Internal release",
              "commissioningPending": "Commissioning pending",
              "commissionedWaitingAB": "Commissioned, waiting for AB",
              "commissionedWaitingCompletion": "Commissioned, waiting for completion",
              "completed": "Completed"
            },
            "table": {
              "date": "Date",
              "status": "Status",
              "number": "PO no.",
              "letterHead": "Letterhead",
              "type": "Type",
              "branch": "Subsidiary",
              "serviceProvider": "Service provider",
              "title": "Title",
              "sum": "Total (net)"
            },
            "createPurchaseOrderModal": {
              "titleForm": "Create order",
              "letterHead": "Letterhead",
              "type": "Type",
              "typeTypes": {
                "service": "Service/Maintenance",
                "construction": "Construction project",
                "goods": "Purchase of goods",
                "other": "Miscellaneous"
              },
              "title": "Title",
              "branch": "Subsidiary",
              "serviceProvider": "Service provider",
              "checkbox": "Create new service provider",
              "serviceProviderName": "Service provider name",
              "Responsibility": "Area of ​​responsibility/field of activity",
              "email": "E-mail address",
              "phoneNumber": "Telephone number",
              "fax": "Fax",
              "street": "Street",
              "houseNumber": "House number",
              "additionalAddress": "Address supplement",
              "postalCode": "Postal code",
              "city": "City",
              "country": "Country",
              "exitButton": "Create order"
            }
          },
          "measures": {
            "breadCrumbsTitle": "Measures",
            "gridElementTitle": "Measures",
            "button": "Create measure",
            "switchCases": {
              "service": "Service/Maintenance",
              "construction": "Construction project",
              "goods": "Purchase of goods",
              "other": "Miscellaneous",
              "draft": "Draft",
              "ongoing": "Ongoing",
              "canceled": "Canceled",
              "completed": "Completed"
            },
            "table": {
              "user": "User",
              "status": "Status",
              "type": "Type",
              "branch": "Subsidiary",
              "title": "Title",
              "begin": "Begin",
              "end": "End",
              "number": "Number",
              "letterHead": "Letterhead",
              "subsidiary": "Subsidiary"
            },
            "newTabLinkButton": "Open link in new tab",
            "deleteButton": "Delete action",
            "noInformationStart": "Unavailable",
            "noInformationEnd": "Unavailable",
            "createMeasureModal": {
              "titleForm": "Create new measure",
              "letterHead": "Letterhead",
              "type": "Type",
              "typeTypes": {
                "service": "Service/Maintenance",
                "construction": "Construction project",
                "goods": "Purchase of goods",
                "other": "Miscellaneous"
              },
              "title": "Title",
              "branch": "Subsidiary",
              "exitButton": "Create measure"
            },
            "deleteMeasureModal": {
              "title": "Delete action",
              "description": "Are you sure you want to delete this measure? ",
              "exitButton": "Delete action"
            }
          },
          "measure": {
            "breadCrumbsTitle": "Measures",
            "offerStatus": {
              "unedited": "unedited",
              "approval": "approval",
              "accepted": "accepted",
              "declined": "declined",
              "purchased": "purchased"
            },
            "summary": {
              "gridElementTitle": "Summary",
              "title": "Title",
              "start": "Start",
              "end": "End",
              "clerk": "Clerk",
              "number": "Nummer",
              "status": "Status",
              "statusTypes": {
                "draft": "Draft",
                "ongoing": "Ongoing",
                "canceled": "Canceled",
                "completed": "Completed"
              },
              "letterHead": "Briefkopf",
              "type": "Typ",
              "typeTypes": {
                "service": "Service/Maintenance",
                "construction": "Construction project",
                "goods": "Purchase of goods",
                "other": "Miscellaneous"
              }
            },
            "documents": {
              "sectionTitle": "Documents",
              "tableTitle": "Documents"
            },
            "tickets": {
              "sectionTitle": "Tickets",
              "gridElementTitle": "Tickets",
              "text1": "Link a",
              "text2": "Ticket",
              "text3": "to this action to display it here.",
              "table": {
                "number": "Number",
                "title": "Title",
                "branch": "Subsidiary",
                "source": "Source",
                "type": "Type",
                "priority": "Priority",
                "lastEdited": "Last edited"
              }
            },
            "quoteCreation": {
              "sectionTitle": "Offer requests",
              "gridElementTitle": "Offer requests",
              "button": "Request an offer",
              "table": {
                "user": "User",
                "date": "Date",
                "serviceProvider": "Service provider",
                "email": "E-mail",
                "remindedAt": "Reminded At",
                "offer": "Offer",
                "receivedAt": "Received At",
                "title": "Title"
              },
              "offerUpload": "Upload received offer",
              "remindServiceProvider": "Remind service provider",
              "cancelRequest": "Cancel request",
              "never": "Never",
              "canceledOn": "Canceled on",
              "reminded": "Reminded",
              "waitForOffer": "Wait for offer"
            },
            "offer": {
              "sectionTitle": "Offers",
              "gridElementTitle": "Offers",
              "text1": "Create or edit an ",
              "text2": "offer request",
              "text3": " to upload an offer.",
              "table": {
                "user": "User",
                "date": "Date",
                "status": "Status",
                "serviceProvider": "Service provider",
                "number": "Number",
                "net": "Net",
                "gross": "Gross",
                "createdAt": "Uploaded",
                "file": "File",
                "size": "Size"
              },
              "changeStatus": "Change status",
              "createOffer": "Create order",
              "deleteOffer": "Delete offer"
            },
            "quoteReleased": {
              "sectionTitle": "Approval requests",
              "gridElementTitle": "Approval requests",
              "button": "Create approval request",
              "table": {
                "user": "User",
                "createdAt": "Date",
                "email": "Target email",
                "status": "Status",
                "approvedAt": "Approved at",
                "proof": "Proof of approval",
                "net": "Net sum",
                "fee": "Fee",
                "title": "Title"
              },
              "confirmRelease": "Confirm release",
              "releaseProof": "View proof of release",
              "releaseForm": "View release form",
              "cancelRequest": "Cancel release request",
              "deleleRequest": "Delete release request",
              "canceledOn": "Canceled on",
              "createdAtNotYet": "Not yet",
              "withoutProof": "Without proof",
              "noneYet": "None yet"
            },
            "orders": {
              "sectionTitle": "Orders",
              "gridElementTitle": "Orders",
              "text1": "Hire one of the",
              "text2": "Offers",
              "text3": "of this measure to view the order here.",
              "table": {
                "date": "Date",
                "status": "Status",
                "number": "PO no.",
                "letterHead": "Letterhead",
                "type": "Type",
                "branch": "Subsidiary",
                "serviceProvider": "Service provider",
                "title": "Title",
                "sum": "Total (net)"
              }
            },
            "invoice": {
              "sectionElemnentTitle": "Invoices",
              "gridElementTitle": "Invoices",
              "text1": "Post an",
              "text2": "Incoming Document",
              "text3": "with reference to a ",
              "text4": "Order",
              "text5": "of this action to view the invoice here.",
              "table": {
                "number": "Invoice number",
                "date": "Date",
                "serviceProvider": "Service provider",
                "net": "EUR(Net)",
                "gross": "EUR(Gross)",
                "branch": "Subsidiary",
                "purchaseOrder": "Order (PO)",
                "contract": "Vertrag"
              }
            },
            "approveMeasureApprovalModal": {
              "title": "Confirm release",
              "releaseDate": "Release date",
              "description": "Save the certificate of approval (e.g. order) in PDF format here, or click here to open the file browser.",
              "coonfirmReleaseButton": "Confirm release",
              "withoutProofButton": "Confirm approval without proof"
            },
            "approvePurchaseOrderModal": {
              "title": "Release PO",
              "releasedBy": "Released by",
              "releaseDate": "Release date",
              "notes": "Notes",
              "button": "Release PO"
            },
            "cancelMeasureApprovalModal": {
              "text1": "Cancellation: Release request for measure",
              "text2": "Dear Sir/Madam,",
              "text3": "We hereby cancel our release request from",
              "text4": "A confirmation is not required.",
              "text5": "Original place of performance:",
              "text6": "Yours sincerely",
              "title": "Cancel release request by E-mail",
              "email": "Recipient email address",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Subject",
              "searchPlaceholderPo2023": "MN2023 ABC",
              "Receiver": "Text to recipient",
              "searchPlaceholderGreetings": "Ladies and Gentlemen, ...",
              "exitButton": "Send cancellation by E-mail"
            },
            "cancelMeasureOfferRequestModal": {
              "text1": "Cancellation: Request for offer for measure",
              "text2": "Dear Sir/Madam,",
              "text3": "We hereby cancel our request for offer from",
              "text4": "A confirmation is not required.",
              "text5": "Original place of performance:",
              "text6": "Yours sincerely",
              "title": "Cancel release request by E-mail",
              "email": "Recipient email address",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Subject",
              "searchPlaceholderPo2023": "MN2023 ABC",
              "Receiver": "Text to recipient",
              "searchPlaceholderGreetings": "Ladies and Gentlemen, ...",
              "exitButton": "Send cancellation by E-mail"
            },
            "cfdsModal": {
              "title": "Send order via CFDS",
              "text1": "After sending the order via CFDS, your service provider will receive an email notification that they have received a new order.",
              "text2": "Important NOTE:",
              "text3": "Using the CFDS protocol is best suited for services in the MEP sector. ",
              "understoodCheckbox": "I have understood",
              "button": "Send bindingly"
            },
            "commissionPurchaseOrderModal": {
              "title": "Order PO",
              "emailTitle": "Send by email",
              "emailDescription": "Send PDF of the order automatically via email via the portal.",
              "cfdsTitle": "Send via CFDS",
              "cfdsDescription": "Provide the service provider with the order digitally (recommended for TGA services).",
              "commissionTitle": "Just mark as commissioned",
              "commissionDescription": "Send the order yourself and mark it as 'ordered'."
            },
            "confirmPurchaseOrderModal": {
              "title": "Confirm order",
              "pdfDescription": "Place the order confirmation here to save it (PDF format required!)",
              "button": "Confirm order"
            },
            "createMeasureApprovalModal": {
              "text1": "Release request for measure",
              "text2": "Dear Sir/Madam,",
              "text3": "We hereby request your approval of the services listed in the attachment.",
              "text4": "Please use the above-mentioned measure number as a reference or sign directly on the release form.",
              "text5": "Place of performance:",
              "text6": "Yours sincerely",
              "title": "Create release request",
              "email": "Recipient E-mail address",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Subject",
              "searchPlaceholderPo2023": "MN2023-ABC",
              "serviceSurcharge": "Service surcharge",
              "Receiver": "Text to recipient",
              "searchPlaceholderGreetings": "Ladies and Gentlemen, ...",
              "exitButton": "Send release request by E-mail",
              "offersAwaitingRelease": "Offers awaiting release",
              "offersAwaitingApproval": "Offers awaiting approval",
              "offersAlreadyApproved": "Offers already approved",
              "offersAlreadyRejected": "Offers already rejected",
              "offersAlreadyCommissioned": "Offers already commissioned",
              "net": "Net",
              "from": "vom"
            },
            "createMeasureOfferRequestModal": {
              "text1": "Request for quotation for measure",
              "text2": "Dear Sir/Madam,",
              "text3": "We hereby request a quotation from you for the following measures/services:",
              "text4": "Please use the above-mentioned measure number as a reference when submitting your offer.",
              "text5": "Place of performance:",
              "text6": "Yours sincerely",
              "title": "Request a quote by E-mail",
              "serviceProvider": "Service provider",
              "email": "Recipient email address",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Subject",
              "searchPlaceholderPo2023": "MN2023 ABC",
              "Receiver": "Text to recipient",
              "searchPlaceholderGreetings": "Ladies and Gentlemen, ...",
              "standardDocumentAttachment": "Attachments (standard documents)",
              "standardAttachment": "Attachments (documents)",
              "exitButton": "Send E-mail"
            },
            "createPurchaseOrderModal": {
              "titleForm": "Create order from quotation",
              "type": "Type",
              "typeTypes": {
                "service": "Service/Maintenance",
                "construction": "Construction project",
                "goods": "Purchase of goods",
                "other": "Miscellaneous"
              },
              "letterHead": "Letterhead",
              "title": "Title",
              "ocrCheckbox": "Read out offer items via OCR",
              "exitButton": "Create order"
            },
            "deleteMeasureApprovalModal": {
              "title": "Delete release request",
              "description1": "Are you sure you want to delete this release request?",
              "description2": "All offers already released as part of this request will be reset.",
              "description3": "This action cannot be revoked.",
              "exitButton": "Delete release request"
            },
            "deleteMeasureOfferModal": {
              "title": "Delete offer",
              "description": "Are you sure you want to delete this offer? This offer cannot be revoked.",
              "exitButton": "Delete offer"
            },
            "deletePurchaseOrderModal": {
              "title": "Delete PO",
              "description": "Are you sure you want to delete the TOE? This action cannot be revoked.",
              "exitButton": "Delete PO"
            },
            "pdfModal": {
              "title": "PDF view"
            },
            "remindMeasureOfferRequestModal": {
              "text1": "Reminder: Request for quotation for measure",
              "text2": "Dear Sir/Madam,",
              "text3": "We hereby remind you of our request for quotation dated",
              "text4": "Please send us your offer as soon as possible.",
              "text5": "Place of performance:",
              "text6": "Yours sincerely",
              "title": "Remind service provider of request for quotation",
              "email": "Recipient email address",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Subject",
              "searchPlaceholderPo2023": "MN2023 ABC",
              "Receiver": "Text to recipient",
              "searchPlaceholderGreetings": "Ladies and Gentlemen, ...",
              "exitButton": "Send reminder by E-mail"
            },
            "showMeasureApprovalModal": {
              "title": "View release request",
              "Receiver": "Text to recipient",
              "searchPlaceholderGreetings": "Ladies and Gentlemen, ..."
            },
            "updateMeasureOfferStatusModal": {
              "title": "Update offer status",
              "status": "Status",
              "offerStatus": {
                "unedited": "Unedited",
                "approval": "Approval",
                "accepted": "Accepted",
                "declined": "Declined",
                "purchased": "Purchased"
              },
              "warning": "Warning:",
              "recommendedStatus": "It is recommended not to change the offer status manually."
            },
            "updatePurchaseOrderModal": {
              "titleForm": "Update PO",
              "serviceProvider": "Service provider",
              "letterHead": "Letterhead",
              "type": "Type",
              "typeTypes": {
                "service": "Service/Maintenance",
                "construction": "Construction project",
                "goods": "Purchase of goods",
                "other": "Miscellaneous"
              },
              "subsidiary": "subsidiary",
              "number": "PO no.",
              "date": "Date",
              "title": "Title",
              "offer": "Reference/offer",
              "button": "Update PO"
            },
            "updateOfferModal": {
              "title": "Enter offer",
              "description": "Save your offer in PDF format here, or click here to open the file browser.",
              "backButton": "Back",
              "page": "Page",
              "from": "from",
              "pdfDownload": "Download PDF file...",
              "nextButton": "Next",
              "offerData": "Offer data",
              "offerDate": "Offer date",
              "offerNo": "Offer no.",
              "net": "Amount in EUR (net)",
              "gross": "Amount in EUR (gross)",
              "exitButton": "Enter offer"
            }
          },
          "workFlows": {
            "title": "Workflows",
            "newTabLinkButton": "Open link in new tab",
            "entryDelete": "Delete Entry",
            "none": "None",
            "executions": {
              "sectionTitle": "Executions",
              "gridElementTitle": "Workflow executions",
              "button": "Start new workflow",
              "table": {
                "workflow": "NameWorkflow",
                "context": "Context",
                "branch": "Subsidiary",
                "team": "Working group",
                "user": "User",
                "createdAt": "Triggered",
                "phase": "Phase",
                "openSteps": "Open task"
              },
              "user": "User",
              "branch": "Subsidiary",
              "team": "Working group",
              "defect": "Defect",
              "other": "Other",
              "tasks": "Tasks",
              "createWorkflowExecutionModal": {
                "titleForm": "Start new workflow",
                "workflow": "Workflow",
                "context": "Context",
                "contextTypes": {
                  "branch": "Subsidiary",
                  "user": "User",
                  "team": "Working group"
                },
                "exitButton": "Execute workflow"
              },
              "comment": {
                "title": "Delete workflow execution",
                "description": "Are you sure you want to delete this workflow execution?",
                "button": "Delete workflow execution"
              }
            },
            "work-Flows": {
              "sectionTitle": "Workflows",
              "gridElementTitle": "Workflows",
              "button": "Create workflow",
              "table": {
                "name": "Name",
                "executions": "Executions"
              },
              "deleteButton": "Delete Entry",
              "status": "active",
              "createWorkflowModal": {
                "titleForm": "Create a new workflow",
                "name": "Name",
                "exitButton": "Create workflow"
              },
              "comment": {
                "title": "Delete workflow",
                "description": "Are you sure you want to delete this workflow?",
                "button": "Delete workflow"
              }
            }
          },
          "workFlow": {
            "soonAvailable": "Available Soon",
            "searchPlaceholder": "Subsidiary name...",
            "nodes": {
              "branching": "Subsidiary:",
              "workingSteps": "Work step:",
              "phase": "Set phase:",
              "workflowTrigger": "Trigger workflow:",
              "workflowEnd": "Workflow end"
            },
            "breadCrumbsWorkflows": "Workflows",
            "breadCrumbsEditor": "Editor",
            "nodePalette": {
              "gridElementTitle": "Node Palette",
              "branchingTitle": "Subsidiary",
              "branchingDescription": "A logical subsidiary with multiple exit possibilities.",
              "workingStepsTitle": "Work step",
              "workingStepsDescription": "A simple step with a possible outcome and documentation option.",
              "phaseTitle": "Phase setter",
              "phaseDescription": "Sets the current phase of the workflow.",
              "conclusionTitle": "Diploma",
              "conclusionDescription": "Completion of the workflow with status display.",
              "workflowTriggerTitle": "Workflow triggers",
              "workflowTriggerDescription": "Trigger another workflow.",
              "notificationTitle": "Notification",
              "notificationDescription": "Sends a notification to a specified group of recipients.",
              "agendaTitle": "Agenda",
              "agendaDescription": "Creates a new entry in the agenda.",
              "calendarTitle": "Calendar appointment",
              "calendarDescription": "Creates a new calendar event."
            },
            "workflowEditor": {
              "gridElementTitle": "Workflow Editor",
              "warning": "Warning:",
              "noConclusion": "No degree created.",
              "settingsButton": "Settings",
              "savedChanges": "Save changes...",
              "saved": "saved",
              "leftClickButtonDescription": "Left click to move and connect, right click to edit",
              "deleteNodeButton": "Delete node",
              "disconnect": "Disconnect"
            },
            "createBranchNodeModal": {
              "title": "Create new subsidiary",
              "name": "Name",
              "exitButton": "Create node"
            },
            "createSimpleNodeModal": {
              "title": "Create a new work step",
              "name": "Name",
              "exitButton": "Create node"
            },
            "createPhaseNodeModal": {
              "title": "Create a new phase setter",
              "exitButton": "Create node"
            },
            "createEndNodeModal": {
              "title": "Create a new degree",
              "exitButton": "Create node"
            },
            "createWorkflowNodeModal": {
              "title": "Create a new workflow trigger",
              "exitButton": "Create node"
            }
          },
          "workFlowExecution": {
            "breadCrumbsWorkflows": "Workflows",
            "breadCrumbsExecutions": "Explanations",
            "WorkflowView": {
              "sectionElementTitle": "Workflow view",
              "gridElementTitle": "Workflow view"
            },
            "taskBoard": {
              "sectionElementTitle": "Task board",
              "note1": "There are no tasks.",
              "note2": "Add your own tasks or complete this workflow.",
              "workflowCloseButton": "Complete workflow",
              "taskCreationButton": "Add your own task",
              "note3": "This workflow has been completed successfully.",
              "note4": "Start a new workflow to make changes."
            },
            "chronology": {
              "sectionElementTitle": "Chronology",
              "nodes": {
                "branching": "Subsidiary:",
                "workingSteps": "Work step:",
                "phase": "Set phase:",
                "workflowTrigger": "Trigger workflow:",
                "workflowEnd": "Workflow end"
              },
              "subtask": "Subtask...",
              "subtaskCreation": "Create subtask",
              "searchPlaceholderNotes": "Your notes here...",
              "text1": "Completing this task will move it into the chronology. ",
              "taskCloseButton": "Complete task",
              "text2": "Completing this task will move it into the chronology. ",
              "workingSteps": "work step",
              "branching": "Subsidiary"
            }
          },
          "serviceProviders": {
            "breadCrumbsTitle": "Service provider",
            "gridElementTitle": "Service provider",
            "button": "Create service provider",
            "table": {
              "name": "Name",
              "responsibility": "Responsibility",
              "city": "Location",
              "contactEmail": "E-mail",
              "contactTelephone": "Phone number."
            },
            "createserviceProvidersModal": {
              "titleForm": "Create new service provider",
              "generalInformation": "General information",
              "name": "Name",
              "Responsibility": "Area of ​​responsibility/field of activity",
              "email": "Contact E-mail address",
              "address": "Address",
              "street": "Street",
              "houseNumber": "House number",
              "additionalAddress": "Additional address",
              "postalCode": "Postal code",
              "city": "City",
              "country": "Country",
              "exitButton": "Create service provider"
            }
          },
          "serviceProvider": {
            "breadCrumbsTitle": "Service provider",
            "generalInformation": {
              "sectionTitle": "General information",
              "gridElementTitle": "General information",
              "updateButton": "Update service provider",
              "deleteButton": "Delete service provider",
              "table": {
                "name": "Name",
                "responsibility": "Responsibility/area of ​​activity",
                "address": "Address",
                "email": "E-mail address",
                "telephoneNumber": "Telephone number",
                "fax": "Fax"
              }
            },
            "contactPerson": {
              "sectionTitle": "Contact person",
              "gridElementTitle": "Contact person",
              "button": "Create contact person",
              "table": {
                "salutation": "Salutation",
                "name": "Name",
                "email": "E-mail",
                "telephone": "Phone",
                "responsibility": "Responsibility"
              }
            },
            "Synchronisation": {
              "sectionTitle": "Synchronization (CFDS)",
              "gridElementTitle": "Synchronizations (CFDS)",
              "button": "Enter pairing code",
              "text1": "Service provider uses this",
              "text2": "scanmetrix® CFDS protocol",
              "text3": ".",
              "table": {
                "condition": "Condition",
                "branch": "Subsidiary",
                "place": "Location",
                "date": "Date"
              }
            },
            "contracts":{
              "sectionTitle": "Contracts",
              "gridElementTitle": "Contracts",
              "button": "Create contract",
              "table": {
                "title": "Contract reference",
                "type": "Contract type",
                "workSection": "Work section",
                "number": "Contract number",
                "partnerName": "Contractual partner",
                "duration": "Duration",
                "beginsAt": "Beginning of contract",
                "endsAt": "End of contract",
                "automaticRenewal": "Automatic renewal",
                "branch": "Subsidiary"
              },
              "durationUnlimited": "Unlimited",
              "beginsAtUnavailabe": "Unavailable",
              "endsAtUnlimited": "Unlimited",
              "endsAtUnavailable": "Unavailable",
              "yes": "Yes",
              "no": "No"
            },
            "inviteServiceProviderModal": {
              "title": "Invite service providers",
              "text1": "Notice:",
              "text2": "If you invite your service provider to use the CFDS protocol, this enables you to automatically compare the asset data of your subsidiaries. ",
              "text3": "You cannot edit the data provided by the service provider.",
              "text4": "After inviting your service provider, they will receive an automated email asking them to either:",
              "list1": "to accept the invitation because he is already working with scanmetrix® service provider",
              "list2": "to accept the invitation by contacting us to discuss the implementation of digital data capture",
              "list3": "To guarantee the correct invitation, first ask your service provider which contact email they have stored for their portal if they already use scanmetrix® service providers.",
              "serviceProvider": "service provider",
              "serviceProvideremail": "Email address of your service provider",
              "noteCheckbox": "I have taken note of the notice.",
              "button": "Invite service provider"
            },
            "updateServiceProviderModal": {
              "titleForm": "Update service provider",
              "generalInformation": "General information",
              "name": "Name",
              "Responsibility": "Area of ​​responsibility/field of activity",
              "contactDetails": "Contact details",
              "email": "E-mail address",
              "telephoneNumber": "Telephone number",
              "fax": "Fax",
              "address": "Address",
              "street": "Street",
              "houseNumber": "House number",
              "additionalAddress": "Address supplement",
              "postalCode": "Postal code",
              "city": "City",
              "country": "Country",
              "exitButton": "Update service provider"
            },
            "deleteServiceProviderModal": {
              "title": "Delete service provider",
              "description": "Are you sure you want to delete this service provider? ",
              "exitButton": "Delete service provider"
            },
            "createContactModal": {
              "title": "Create contact person",
              "firstName": "First name",
              "lastName": "Last name",
              "email": "E-mail address",
              "telephoneNumber": "Telephone number",
              "responsibility": "Responsibility",
              "exitButton": "Create contact person"
            },
            "updateContactModal": {
              "title": "Update contact person",
              "firstName": "First name",
              "lastName": "Last name",
              "email": "E-mail address",
              "telephoneNumber": "Telephone number",
              "responsibility": "Responsibility",
              "exitButton": "Update contact person"
            },
            "connectServiceProviderModal": {
              "title": "Enter pairing code",
              "couplingCodeInput": "Pairing code",
              "text1": "Notice:",
              "text2": "You can obtain the pairing code from your service provider upon request. ",
              "moreInformationLink": "More information",
              "exitButton": "Connect service provider"
            }
          },
          "users": {
            "breadCrumbsTitle": "Users",
            "sectionItem": {
              "user": {
                "title": "Users",
                "table": {
                  "salutation": "Salutation",
                  "display": "User",
                  "email": "E-mail",
                  "position": "Actvity",
                  "landline": "Landline No.",
                  "mobile": "Mobile No.",
                  "fax": "Fax No.",
                  "role": "Role",
                  "language": "Language",
                  "lastActivity": "Last Activity"
                },
                "lastActivityNeverBefore": "Never before",
                "roleNone": "None"
              },
              "workGroup": {
                "title": "Working groups",
                "button": "Create workgroup",
                "member": "Add member"
              },
              "serviceDesk": {
                "title": "Service desk",
                "gridElementTitle": "Service desk",
                "table": {
                  "active": "Active",
                  "avatar": "Avatar",
                  "firstname": "First name",
                  "lastname": "Last name",
                  "email": "E-mail address",
                  "propertiesRealEstate": "Properties",
                  "right": "Due",
                  "userSince": "Member since"
                },
                "userActiveYes": "Yes",
                "userActiveNo": "No",
                "allSubsidiaries": "All",
                "permission": {
                  "counter": "Counter",
                  "inspections": "Inspections",
                  "dataInsight": "Data insight",
                  "ticketWithoutInspection": "Ticket without inspection"
                }
              },
              "role": {
                "title": "Roles",
                "gridElementTitle": "Roles",
                "button": "Create role",
                "table": {
                  "name": "Name",
                  "user": "User",
                  "userCount": "User"
                }
              },
              "createRoleModal": {
                "title": "Create new role",
                "name": "Name",
                "exitButton": "Create role"
              },
              "createTeamModal": {
                "title": "Create new workgroup",
                "description": "Name",
                "exitButton": "Create workgroup"
              },
              "createTeamUserModal": {
                "title": "Add member",
                "user": "User",
                "role": "Role",
                "exitButton": "Add member"
              },
              "updateServiceDeskUserModal": {
                "title": "Customize Service Desk users",
                "profileData": "Profile data",
                "firstname": "First name",
                "lastname": "Last name",
                "email": "E-mail",
                "functionDeactivate": "Disable functions",
                "counterDeactivate": "Disable counter",
                "inspectionsDeactivate": "Deactivate visits",
                "ticketWithoutInspection": "Deactivate tickets without an inspection reference",
                "dataViewDeactivate": "Disable data viewing",
                "accessRestrictions": "Access restrictions",
                "usersActive": "User active",
                "restrictAccess": "Restrict access to certain properties",
                "selectAllVisible": "Select all visible (",
                "deselectAllVisible": "Deselect all visible ones (",
                "selected": "selected",
                "branchLabel": "Search for subsidiary...",
                "saveUserButton": "Save user"
              }
            }
          },
          "role": {
            "users": "Users",
            "roles": "roll",
            "gridElementTitle": "role",
            "roleUpadteButton": "Update role",
            "roleDeleteButton": "Delete role",
            "enableButtons": {
              "read": "To read",
              "create": "Create",
              "change": "Change",
              "delete": "Delete"
            },
            "updateRoleModal": {
              "title": "Update role",
              "name": "Name",
              "exitButton": "Update role"
            },
            "deleteRoleModal": {
              "title": "Delete role",
              "description": "Are you sure you want to delete this role? ",
              "exitButton": "Delete role"
            }
          },
          "contracts": {
            "breadCrumbsTitle": "Contracts",
            "contractsTable": {
              "sectionTitle": "Contracts",
              "gridElementTitle": "Contracts",
              "button": "Create contract",
              "table": {
                "title": "Contract reference",
                "type": "Contract type",
                "workSection": "Work section",
                "number": "Contract number",
                "partnerName": "Contractual partner",
                "duration": "Duration",
                "beginsAt": "Beginning of contract",
                "endsAt": "End of contract",
                "automaticRenewal": "Automatic renewal",
                "branch": "Subsidiary"
              },
              "durationUnlimited": "Unlimited",
              "beginsAtUnavailabe": "Unavailable",
              "endsAtUnlimited": "Unlimited",
              "endsAtUnavailable": "Unavailable",
              "yes": "Yes",
              "no": "No",
              "newTabLinkButton": "Open link in new tab"
            },
            "performanceRecords": {
              "sectionTitle": "Tracking performance records",
              "filterSortation": {
                "gridElementTitle": "Filter & sort",
                "searchPlaceholderBranches": "Filter by subsidiary",
                "searchPlaceholderContractType": "Filter by contract type",
                "searchPlaceholderServiceProvider": "Filter by service provider",
                "sortation": {
                  "title": "Sort by",
                  "serviceDateAscending": "Date of service in ascending order",
                  "serviceDateDescending": "Date of service in descending order"
                }
              },
              "prioritySection": {
                "medium": "Medium",
                "high": "High",
                "low": "Low",
                "priority": "Priority",
                "trackingLN": "Follow-up for LN:",
                "trackingButton": "Stop tracking",
                "basicData": {
                  "basicDataTitle": "Basic data",
                  "branchInformation": "Subsidiary information",
                  "branchInformationName": "Name",
                  "abbreviation": "Abbreviation",
                  "address": "Address",
                  "contractInformation": "Contract information",
                  "contractNumner": "Contract number",
                  "contractName": "Contract name",
                  "contractType": "Contract type",
                  "contractPartner": "Contractual partner",
                  "serviceCycle": "Performance cycle",
                  "serviceCycleName": "Name",
                  "interval": "Interval",
                  "intervalType": {
                    "days": "days",
                    "weeks": "weeks",
                    "months": "Months",
                    "years": "Years"
                  },
                  "start": "Beginning",
                  "proofOfPerformance": "Certificate of achievement",
                  "proofOfPerformanceName": "Name",
                  "date": "Date",
                  "continueInterval": "Continue interval",
                  "serviceDateInterval": "Continue interval from service date"
                },
                "comments": {
                  "sectionElementTitle": "Comments",
                  "searchPlaceholder": "Your comment",
                  "commentButton": "Leave a Comment"
                },
                "fileBox": {
                  "sectionElementTitle": "File box",
                  "performanceDocuments": "Proof of documents"
                }
              }
            },
            "contractProvisionIntervals": {
              "sectionTitle": "Upcoming performance cycles",
              "gridElementTitle": "Upcoming performance cycles",
              "intervalTypes": {
                "days": "days",
                "weeks": "weeks",
                "months": "Months",
                "years": "Years"
              },
              "table": {
                "branch": "Subsidiary",
                "due": "Due",
                "name": "Name",
                "interval": "Interval",
                "contract": "Contract",
                "partner": "Contractual partner",
                "lastContractProof": "Last contract proof",
                "latestAppointment": "Latest appointment"
              },
               "newTabLinkButton": "Open link in new tab"
            },
            "missingFiles": {
              "sectionTitle": "Missing documents",
              "gridElementContract": "Contracts with missing documents",
              "contractTable": {
                "title": "Contract reference",
                "type": "Contract type",
                "number": "Contract number",
                "partner_name": "Contractual partner",
                "beginsAt": "Beginning of contract",
                "endsAt": "End of contract",
                "automaticRenewal": "Automatic renewal",
                "beginsAtUnavailabe": "k. A.",
                "endsAtUnlimited": "Unbefristet",
                "endsAtUnavailable": "k. A.",
                "yes": "Ja",
                "no": "Nein"
              },
              "gridElementPerformanceProof": "Performaceproof with missing documents",
              "performanceTable": {
                "date": "Date",
                "branch": "Subsidiary",
                "name": "Name of the service",
                "title": "Contract reference",
                "type": "Contract type",
                "number": "Contract number",
                "partner_name": "Contractual partner",
                "beginsAt": "Beginning of contract",
                "endsAt": "End of contract",
                "automaticRenewal": "Automatic renewal",
                "files": "File box",
                "beginsAtUnavailabe": "k. A.",
                "endsAtUnlimited": "Unbefristet",
                "endsAtUnavailable": "k. A.",
                "yes": "Ja",
                "no": "Nein"
              },
              "newTabLinkButton": "Open link in new tab"
            },
            "createContractModal": {
              "depositContract": {
                "title": "Create contract",
                "extendedView": "Extended view",
                "contractData": "Contract data",
                "companyBranch": "Subsidiary of the company",
                "trade": "Work section",
                "workGroup": "Working group",
                "contractName": "Contract name",
                "contractType": "Contract type",
                "contractNumber": "Contract number",
                "contractDate": "Contract date",
                "contractDescription": "Contract description/content/comments"
              },
              "contractTerm": {
                "title": "Contract term",
                "contractStart": "Start of contract",
                "contractEnd": "End of contract",
                "termNumber": "Running time number",
                "termUnit": "Runtime unit",
                "termUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                },
                "unlimitedContract": "Permanent contract",
                "automaticRenewal": "Automatic renewal"
              },
              "noticePeriod": {
                "title": "Notice period",
                "noticePeriodNumber": "Notice period number",
                "noticePeriodUnit": "Notice period unit",
                "noticePeriodUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                },
                "noticePeriodType": "Notice period type",
                "noticePeriodTypeTypes": {
                  "ongoing": "Constantly",
                  "monthEnd": "End of month",
                  "yearEnd": "End of year",
                  "quarterEnd": "End of quarter",
                  "contractEnd": "End of contract"
                }
              },
              "renewalPeriod": {
                "title": "Renewal period for contract extension",
                "renewalPeriodNumber": "Renewal period number",
                "renewalPeriodUnit": "Renewal period unit",
                "renewalPeriodUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                }
              },
              "otherData": {
                "title": "Other data",
                "annualCostsNet": "Annual costs in € (net)",
                "annualPricePercent": "Annual price increase",
                "settingPrice": "Price set on"
              },
              "contractPartner": {
                "titleServiceProvider": "Service provider",
                "titleContractPartner": "Contractual partner",
                "serviceProvider": "Service provider",
                "contractPartnerName": "Name of the contractual partner",
                "createServiveProvider": "Create service providers",
                "responsibility": "Area of ​​responsibility/field of activity",
                "email": "E-mail address",
                "telephoneNumber": "Telephone number",
                "fax": "Fax",
                "street": "Street",
                "houseNumber": "House number",
                "additionalAddress": "Address supplement",
                "zipCode": "Postal code",
                "city": "City",
                "country": "Country",
                "depositContractButton": "Create contract",
                "exitButton": "Store and access the contract"
              }
            },
            "deleteContractProvisionProofCommentModal": {
              "title": "Delete comment",
              "description": "Are you sure you want to delete this comment? ",
              "exitButton": "Delete comment"
            },
            "EndTracingModal": {
              "title": "Stop tracking",
              "description": "Are you sure you want to stop tracking this proof of achievement?",
              "exitButton": "Stop tracking"
            }
          },
          "contract": {
            "breadCrumbsTitle": "Contracts",
            "termination": {
              "notSpecified": "Not specified",
              "expired": "Expired",
              "text1": "You have on",
              "text2": "terminated",
              "text3": "You are late on",
              "text4": "terminated"
            },
            "generalInformation": {
              "sectionTitle": "General information",
              "noticePeriod": {
                "text1": "Notice period",
                "text2": "Not specified",
                "text3": "No cancellation necessary",
                "text4": "Termination due",
                "text5": "at the",
                "text6": "Termination effective",
                "text7": "at the",
                "text8": "You have on",
                "text9": "terminated",
                "text10": "Contract renewal",
                "text11": "End of contract"
              },
              "contractData": {
                "gridElementTitle": "Contract data",
                "serviceProvider": "Service provider",
                "updateButton": "Update contract",
                "deleteButton": "Delete contract",
                "contractName": "Contract name",
                "contractType": "Contract type",
                "contractSign": "Contract is signed",
                "contractNumbe": "Contract number",
                "contractDescription": "Contract description/content"
              },
              "classification": {
                "gridElementTitle": "Classification",
                "trade": "Work section",
                "workGroup": "Working group",
                "notes": "Remarks/Notes"
              },
              "contractCost": {
                "gridElementTitle": "Contract data",
                "annualCostsNet": "Annual costs in € (net)",
                "annualPricePercent": "Annual price increase in percent",
                "settingPrice": "Price set on"
              },
              "contractTerm": {
                "gridElementTitle": "Contract term",
                "contractStart": "Start of contract",
                "contractEnd": "End of contract",
                "unlimitedContract": "Permanent",
                "contractDate": "Contract date",
                "termNumber": "Running time number",
                "termUnit": "Runtime unit",
                "termUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                }
              },
              "termination": {
                "gridElementTitle": "Termination",
                "revokeTerminationButton": "Cancel termination",
                "cancelContract": "Terminate the contract",
                "noticePeriodNumber": "Notice period number",
                "noticePeriodUnit": "Notice period unit",
                "noticePeriodUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                },
                "noticePeriodType": "Notice period type",
                "noticePeriodTypeTypes": {
                  "ongoing": "Constantly",
                  "monthEnd": "End of year",
                  "yearEnd": "End of month",
                  "quarterEnd": "End of quarter",
                  "contractEnd": "End of contract"
                },
                "contractEndCheckbox": "Termination possible before the end of the contract"
              },
              "contractExtension": {
                "gridElementTitle": "Contract extension",
                "renewalPeriodNumber": "Renewal period number",
                "renewalPeriodUnit": "Renewal period unit",
                "renewalPeriodUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                },
                "automaticRenewal": "Automatic renewal"
              },
              "contractPartner": {
                "gridElementTitle": "Contractual partner",
                "serviceProvider": "Service provider",
                "contractPartner": "Contractual partner",
                "addressContractPartner": "Address of the contractual partner"
              }
            },
            "documents": {
              "sectionTitle": "Documents",
              "contractDocument": "Contract documents"
            },
            "extensionOptions": {
              "sectionTitle": "Extension options",
              "gridElementTitle": "Extension options",
              "button": "Create extension option",
              "table": {
                "date": "Extension from",
                "deadline": "Claim until",
                "duration": "Extension period",
                "notes": "Remarks"
              },
              "deleteButton": "Delete Entry"
            },
            "areaApplication": {
              "sectionTitle": "Subsidiaries within the scope",
              "gridElementTitle": "Subsidiaries within the scope",
              "button": "Link subsidiary",
              "table": {
                "name": "Name",
                "label": "Code",
                "legalName": "Legal name",
                "street": "Street",
                "postalCode": "Postal code",
                "city": "City",
                "costQuota": "Cost share"
              },
              "branchCallButton": "Call subsidiary",
              "deleteButton": "Delete Entry",
              "noShare": "No share"
            },
            "performanceCycle": {
              "sectionTitle": "Performance cycles",
              "gridElementTitle": "Performance cycles",
              "button": "Create performance cycle",
              "table": {
                "disabled": "Disabled",
                "name": "Name/service description",
                "startingAt": "Starts on",
                "cost": "Net costs",
                "duration": "Interval"
              },
              "deleteButton": "Delete Entry",
              "Yes": "Yes",
              "No": "No"
            },
            "upcomingPerformanceCycle": {
              "gridElementTitle": "Upcoming performance cycles",
              "tableOne": {
                "days": "days",
                "weeks": "weeks",
                "months": "Months",
                "years": "Years"
              },
              "tableTwo": {
                "branch": "Subsidiary",
                "due": "Due",
                "name": "Name",
                "interval": "Interval",
                "lastContractProof": "Last contract proof",
                "latestAppointment": "Latest appointment"
              }
            },
            "proofOfPerformance": {
              "sectionTitle": "Evidence of performance",
              "gridElementTitle": "Evidence of performance",
              "button": "Create proof of performance",
              "table": {
                "date": "Date",
                "branch": "Subsidiary",
                "interval": "cycle",
                "name": "Name of the service",
                "continueInterval": "Interval continue",
                "continueFromDate": "From date onwards.",
                "tracing": "Follow up",
                "files": "File box"
              },
              "deleteButton": "Delete Entry",
              "continueIntervalJa": "Yes",
              "continueIntervalNein": "No",
              "continueFromDateJa": "Yes",
              "continueFromDateNein": "No",
              "high": "High",
              "low": "Low",
              "tracingJa": "Yes",
              "tracingNein": "No"
            },
            "modals": {
              "cancelContractModal": {
                "revokeTerminationTitle": "Cancel termination",
                "cancelContractTitle": "Terminate the contract",
                "terminationDate": "Termination date",
                "text1": "The following template is for guidance only. ",
                "text2": "Ladies and Gentlemen",
                "text3": "We hereby request the revocation of our termination from",
                "text4": "of the contract of",
                "text5": "We ask that you send us confirmation of this as soon as possible.",
                "text6": "Best regards",
                "text7": "The following template is for guidance only. ",
                "text8": "Ladies and Gentlemen",
                "text9": "We hereby terminate the contract from",
                "text10": "We ask that you send us a confirmation of cancellation at short notice.",
                "text11": "Best regards",
                "revokeTerminationButton": "Cancel termination",
                "cancelledMarkButton": "Mark as terminated"
              },
              "createContractExtensionOptionModal": {
                "title": "Create extension option",
                "extensionFrom": "Extension from",
                "utilizationLatest": "Claim at the latest",
                "durationNumber": "Duration number",
                "durationUnit": "Duration unit",
                "durationUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                },
                "notes": "Remarks",
                "exitButton": "Create extension option"
              },
              "createContractProvisionIntervalModal": {
                "title": "Create performance cycle",
                "startsOn": "Starts on (leave blank to transfer from contract date)",
                "name": "Name/service description",
                "netCosts": "Net costs",
                "userDefinedDescription": "Custom description",
                "intervalQuantity": "Interval number",
                "intervalUnit": "Interval unit",
                "intervalUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                },
                "exitButton": "Create performance cycle"
              },
              "createContractProvisionProoflModal": {
                "title": "Create proof of performance",
                "branch": "Subsidiary",
                "performanceCycle": "Performance cycle",
                "performanceDate": "Date of service",
                "performanceName": "Name of the service",
                "priority": "Priority (only for tracking)",
                "priorityTypes": {
                  "low": "Low",
                  "medium": "Medium",
                  "high": "High"
                },
                "intervalContinue": "Continue interval",
                "serviceDateInterval": "Continue interval from service date",
                "defectEvent": "Follow-up (e.g. in the event of a shortage)",
                "exitButton": "Create proof of performance",
                "exitButton1": "Create proof of performance and open file box"
              },
              "createSubsidiaryContractModal": {
                "title": "Link subsidiary",
                "linkIndividually": "Link individually",
                "branch": "Subsidiary",
                "linkMore": "Link several",
                "selectAllVisible": "Select all visible (",
                "deselectAllVisible": "Deselect all visible ones (",
                "selected": "Selected",
                "searchPlaceholder": "Search for subsidiary...",
                "exitButton": "Link subsidiary"
              },
              "deleteContractExtensionOptionModal": {
                "title": "Delete renewal option",
                "description": "Are you sure you want to delete this renewal option?",
                "exitButton": "Delete renewal option"
              },
              "deleteContractModal": {
                "title": "Delete contract",
                "description": "Are you sure you want to delete this contract?",
                "exitButton": "Delete contract"
              },
              "deleteContractProvisionIntervalModal": {
                "title": "Clear power cycle",
                "description": "Are you sure you want to delete this performance cycle?",
                "exitButton": "Clear power cycle"
              },
              "deleteContractProvisionProofModal": {
                "title": "Delete proof of performance",
                "description": "Are you sure you want to delete this proof of achievement?",
                "exitButton": "Delete proof of performance"
              },
              "deleteSubsidiaryContractModal": {
                "title": "Delete subsidiary link",
                "description": "Are you sure you want to delete this shortcut?",
                "exitButton": "Delete subsidiary link"
              },
              "openContractProvisionProofDocumentsModal": {
                "title": "File box",
                "performanceDocuments": "Proof of performance documents"
              },
              "updateContractExtensionOptionModal": {
                "title": "Create extension option",
                "extensionFrom": "Extension from",
                "utilizationLatest": "Claim at the latest",
                "durationNumber": "Duration number",
                "durationUnit": "Duration unit",
                "durationUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                },
                "notes": "Remarks"
              },
              "updateContractModal": {
                "updateContract": {
                  "title": "Update contract",
                  "contractData": "Contract data",
                  "companyBranch": "Subsidiary of the company",
                  "trade": "Work section",
                  "workGroup": "Working group",
                  "contractName": "Contract name",
                  "contractType": "Contract type",
                  "contractNumber": "Contract number",
                  "contractDate": "Contract date",
                  "contractDescription": "Contract description/content",
                  "signed": "Signed"
                },
                "contractTerm": {
                  "title": "Contract term",
                  "contractStart": "Start of contract",
                  "contractEnd": "End of contract",
                  "termNumber": "Running time number",
                  "termUnit": "Runtime unit",
                  "termUnitTypes": {
                    "days": "days",
                    "weeks": "weeks",
                    "months": "Months",
                    "years": "Years"
                  },
                  "unlimitedContract": "Permanent contract",
                  "automaticRenewal": "Automatic renewal"
                },
                "noticePeriod": {
                  "title": "Notice period",
                  "noticePeriodNumber": "Notice period number",
                  "noticePeriodUnit": "Notice period unit",
                  "noticePeriodUnitTypes": {
                    "days": "days",
                    "weeks": "weeks",
                    "months": "Months",
                    "years": "Years"
                  },
                  "noticePeriodType": "Notice period type",
                  "noticePeriodTypeTypes": {
                    "ongoing": "Constantly",
                    "monthEnd": "End of year",
                    "yearEnd": "end of year",
                    "quarterEnd": "End of quarter",
                    "contractEnd": "End of contract"
                  },
                  "contractCancellationPossible": "Termination possible before the end of the contract"
                },
                "renewalPeriod": {
                  "title": "Renewal period for contract extension",
                  "renewalPeriodNumber": "Renewal period number",
                  "renewalPeriodUnit": "Renewal period unit",
                  "renewalPeriodUnitTypes": {
                    "days": "days",
                    "weeks": "weeks",
                    "months": "Months",
                    "years": "Years"
                  }
                },
                "contractPartner": {
                  "titleContractPartner": "Contractual partner",
                  "serviceProvider": "Service provider",
                  "contractPartnerName": "Name of the contractual partner",
                  "street": "Street",
                  "houseNumber": "House number",
                  "additionalAddress": "Address supplement",
                  "zipCode": "Postal code",
                  "city": "City",
                  "country": "Country"
                },
                "otherData": {
                  "title": "Other data",
                  "notes": "Remarks/Notes",
                  "annualCostsNet": "Annual costs in € (net)",
                  "annualPricePercent": "Annual price increase in percent",
                  "settingPrice": "Price set on",
                  "exitButton": "Update contract"
                }
              },
              "updateContractProvisionIntervalModal": {
                "title": "Update performance cycle",
                "performanceCycle": {
                  "sectionElementTitle": "Performance cycle",
                  "startsOn": "Starts on (leave blank to transfer from contract date)",
                  "name": "Name/service description",
                  "netCosts": "Net costs",
                  "intervalQuantity": "Interval number",
                  "intervalUnit": "Interval unit",
                  "intervalUnitTypes": {
                    "days": "days",
                    "weeks": "weeks",
                    "months": "Months",
                    "years": "Years"
                  },
                  "deactivateCheckbox": "Disable (overrides deviations)"
                },
                "deviations": {
                  "sectionElementTitle": "Deviations",
                  "branch": "Subsidiary",
                  "deviationsButton": "Create deviation",
                  "table": {
                    "branch": "Subsidiary",
                    "interval": "interval",
                    "startingAt": "Starts on",
                    "cost": "Cost",
                    "inactive": "Inactive"
                  },
                  "deleteButton": "Delete deviation",
                  "intervalInherited": "Inherited",
                  "costInherited": "Inherited",
                  "inactiveYes": "Yes",
                  "inactiveNo": "No",
                  "startingAtInherited": "Inherited"
                }
              },
              "updateContractProvisionIntervalVariationModal": {
                "title": "Update deviation",
                "intervalNumber": "Interval number",
                "intervalUnit": "Interval unit",
                "intervalUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                },
                "netCosts": "Net costs",
                "startsOn": "Starts on",
                "inactiveCheckbox": "Inactive"
              },
              "updateContractProvisionProoflModal": {
                "title": "Update proof of performance",
                "branch": "Subsidiary",
                "performanceDate": "Date of service",
                "performanceName": "Name of the service",
                "priority": "Priority (only for tracking)",
                "priorityTypes": {
                  "low": "Low",
                  "medium": "Medium",
                  "high": "High"
                },
                "intervalContinue": "Continue interval",
                "serviceDateInterval": "Continue interval from service date",
                "defectEvent": "Follow-up (e.g. in the event of a shortage)"
              },
              "updateSubsidiaryContractModal": {
                "title": "Update subsidiary link",
                "costQuota": "Cost share"
              }
            }
          },
          "documents": {
            "topData": "File",
            "transmitted": "will be transmitted...",
            "bottomData": "File",
            "successfullyTransmitted": "has been sent successfully.",
            "dataUpload": "Place your file here to upload it.",
            "title": "Documents",
            "FolderAll": "All",
            "normalFileAll": "All",
            "File": "Folder",
            "generalDocuments": {
              "breadCrumbsTitle": "General documents",
              "generalDocumentsTitle": "General documents",
              "generalDocumentsTableTitle": "General documents",
              "text": "Drag files into the search window to upload them.",
              "bottomText": "You are in the main folder.",
              "back": "Back to",
              "createFile": "Create a folder",
              "createFileForm": {
                "createFileTitle": "Create folder",
                "ordnerNameLabel": "Ordername",
                "createFileButton": "Create folder"
              },
              "uploadFile": "Upload file(s).",
              "successPopup": "File {index 1} / {acceptedFiles.length} is being transmitted...",
              "rightClickDataMenu": {
                "dataDownload": "Download file",
                "dataDelete": "Delete file",
                "dataRename": "Rename file"
              }
            },
            "branchDocuments": "Establishment documents",
            "branchDocumentsTableTitle": "Establishment documents",
            "contractDocument": "Contract documents",
            "contractDocumentTableTitle": "Contract documents",
            "performanceProof": "Performance documents",
            "performanceProofTableTitle": "Performance documents",
            "measures": "Measures",
            "measuresTableTitle": "Measures documents",
            "serviceProviderDocuments": "Service Provider documents",
            "serviceProviderDocumentsTableTitle": "Service Provider documents",
            "personalDocuments": {
              "personalDocumentsTitle": "Personal documents",
              "personalDocumentsTableTitle": "Personal documents",
              "rightClickfileMenu": {
                "fileDownload": "Download folder as a ZIP file",
                "fileDelete": {
                  "fileDeleteTitle": "Delete folder",
                  "elementDeleteTitle": "Delete item",
                  "elementDeleteText": "Please confirm that you want to delete this item.",
                  "elementDeleteText1": "Warning: This promotion is permanent and cannot be revoked!",
                  "elementDeleteButton": "Delete item"
                },
                "fileRename": {
                  "fileRenameTitle": "Rename folder",
                  "elementRenameTitle": "Rename element",
                  "elementRenameButton": "Rename element"
                }
              }
            },
            "googleDrive": "Google Drive",
            "googleDriveTableTitle": "Google Drive",
            "documentsTable": {
              "name": "Name",
              "type": "Type",
              "owner": "Owner",
              "modified": "Modified",
              "created": "Created",
              "size": "Size",
              "release": "Release"
            },
            "mainFile": "You are in the main folder."
          },
          "facilityManagement": {
            "landlordBottom": "Landlord",
            "administrativeBottom": "Property manager",
            "title": "Facility management",
            "branches": {
              "breadCrumbsTitle": "Subsidiary",
              "gridElementTitle": "Subsidiary",
              "button": "Create Subsidiary",
              "table": {
                "name": "Name",
                "label": "Code",
                "legalName": "Legal name",
                "street": "Street",
                "postalCode": "Postcode",
                "city": "City",
                "type": "Type",
                "phase": "Status"
              },
              "createSubsidiaryModal": {
                "titleForm": "Create new subsidiary",
                "generalInformation": "General information",
                "name": "Name",
                "abbreviation": "Abbreviation / Subsidiary",
                "businessBranch": "Business subsidiary",
                "legalName": "Legal name",
                "address": "Address",
                "street": "Street",
                "houseNumber": "House number",
                "additionalAddress": "Address supplement",
                "postalCode": "Postal code",
                "city": "City",
                "country": "Country",
                "exitButton": "Create Subsidiary"
              }
            },
            "landlord": {
              "breadCrumbsTitle": "Landlord/Manager",
              "gridElementTitle": "Landlord/Manager",
              "button": "Create Landlord/Manager",
              "table": {
                "type": "Type",
                "name": "Name",
                "address": "Address"
              },
              "createCustodianModal": {
                "titleForm": "Create new Landlord/Manager",
                "generalInformation": "General information",
                "name": "Name",
                "type": "Type",
                "typeTypes": {
                  "landlord": "Landlord",
                  "administrative": "Property manager"
                },
                "address": "Address",
                "street": "Street",
                "houseNumber": "House number",
                "additionalAddress": "Address supplement",
                "postalCode": "Postal code",
                "city": "City",
                "country": "Country",
                "exitButton": "Create Landlord/Manager"
              }
            },
            "undeclaredTradeAssignments": {
              "title": "Undeclared work section assignments",
              "table": {
                "name": "Name",
                "label": "Code",
                "legalName": "Legal name",
                "street": "Street",
                "postalCode": "Postcode",
                "city": "City"
              }
            }
          },
          "facility": {
            "breadCrumbsTitle": "Facility management",
            "attachement": {
              "sectionElementTitle": "Attachment",
              "attachementTable": {
                "gridElementTitle": "Attachment",
                "table": {
                  "name": "Name",
                  "building": "Building",
                  "workSection": "Work section",
                  "resposibleServiceProvider": "Responsible service provider",
                  "interval": "Interval",
                  "nextMaintenance": "Next maintenance"
                }
              },
              "responsibility": {
                "gridElementTitle": "Attachment",
                "landlord": "Landlord",
                "landlordDescription": "The landlord of the property is liable and responsible for maintenance.",
                "administrator": "Administrator",
                "administratorDescription": "The property manager is liable and responsible for maintenance.",
                "operator": "Operator",
                "operatorDescription": "You are liable for the system yourself and are responsible for its maintenance."
              }
            },
            "object": {
              "sectionElementTitle": "Objects",
              "gridElementTitle": "Objects",
              "table": {
                "smid": "SMID",
                "position": "Position",
                "positionDescription": "Position description",
                "manufacturer": "Manufacturer",
                "model": "Model",
                "interval": "Interval",
                "nextInspection": "Due"
              }
            },
            "documents": {
              "sectionElementTitle": "Documents",
              "gridElementTitle": "Documents"
            },
            "newObjectModal": {
              "sectionElementTitle": "Documents",
              "gridElementTitle": "Documents"
            }
          },
          "maintenance": {
            "viewTest": "View test",
            "viewImage": "View image",
            "defectsRepairedMaintenance": {
              "title": "Defects repaired during maintenance",
              "defects": "Shortage",
              "description": "Description",
              "recorded": "Created At",
              "priority": "Priority",
              "deadline": "Deadline",
              "type": "Type",
              "condition": "Condition"
            },
            "low": "Low",
            "medium": "Medium",
            "high": "High",
            "fixed": "Fixed",
            "open": "Open",
            "facilityManagement": "Facility management",
            "maintenanceFrom": "Maintenance from",
            "result": "Result:",
            "defectsDetected": "Detected defect(s).",
            "defectsRepaired": "Repaired defect(s).",
            "maintenancePicture": "Pictures from maintenance",
            "frameData": {
              "title": "Framework data",
              "maintenanceLog": "Maintenance log",
              "maintenanceLogPdf": "Maintenance log as PDF",
              "date": "Date",
              "interval": "Interval",
              "technician": "Technician",
              "serviceProvider": "Service provider",
              "notes": "Notes"
            },
            "tests": {
              "title": "Exams",
              "smid": "SMID",
              "component": "Component",
              "test": "Test",
              "date": "Date"
            },
            "defectsMaintenance": {
              "title": "Defects",
              "defects": "Shortage",
              "description": "Description",
              "recorded": "Created At",
              "priority": "Priority",
              "deadline": "Deadline",
              "type": "Type",
              "condition": "Condition"
            },
            "checkListForm": {
              "noCheckpoints": "This checklist has no checkpoints."
            }
          },
          "custodian": {
            "breadCrumbsFacilityManagement": "Facility management",
            "breadCrumbsLandlord": "Landlord/Manager",
            "landlord": {
              "sectionTitle": "Landlord/Manager",
              "generalInformation": {
                "gridElementTitle": "General information",
                "deleteButton": "Delete Landlord/Manager",
                "name": "Name",
                "type": "Type",
                "typeTypes": {
                  "landlord": "Landlord",
                  "propertyManager": "Property manager"
                },
                "notes": "Notes"
              },
              "address": {
                "gridElementTitle": "Address",
                "street": "Street",
                "houseNumber": "House number",
                "additionalAddress": "Address supplement",
                "postalCode": "Postal code",
                "city": "City",
                "country": "Country"
              }
            },
            "contactPerson": {
              "sectionTitle": "Contact person",
              "contactPersonArea": {
                "gridElementTitle": "Contact person",
                "deleteButton": "Create contact person",
                "table": {
                  "salutation": "Salutation",
                  "name": "Name",
                  "email": "E-mail",
                  "telephone": "Phone",
                  "type": "Type",
                  "company": "Company",
                  "responsibility": "Responsibility"
                }
              }
            },
            "branches": {
              "sectionTitle": "Subsidiary",
              "branchesArea": {
                "gridElementTitle": "Subsidiary",
                "table": {
                  "name": "Name",
                  "label": "Code",
                  "legalName": "Legal name",
                  "street": "Street",
                  "postalCode": "Postcode",
                  "city": "City",
                  "responsibility": "Function"
                },
                "Landlord": "Landlord",
                "Manager": "Manager",
                "Landlord/Manager": "Landlord/Manager"
              }
            },
            "createContactModal": {
              "title": "Create contact person",
              "firstName": "First name",
              "lastName": "Last name",
              "email": "E-mail address",
              "faxNumber": "Fax number",
              "responsibility": "Responsibility",
              "type": "Type",
              "telephoneNumber": "Telephone number",
              "mobile": "Mobile phone number",
              "company": "Company or company",
              "exitButton": "Create contact person"
            },
            "updateContactModal": {
              "title": "Update contact person",
              "firstName": "First name",
              "lastName": "Last name",
              "email": "E-mail address",
              "faxNumber": "Fax number",
              "responsibility": "Responsibility",
              "type": "Type",
              "telephoneNumber": "Telephone number",
              "mobile": "Mobile phone number",
              "company": "Company or company",
              "exitButton": "Create contact person",
              "deleteButton": "Delete contact person"
            },
            "deleteCustodianModal": {
              "title": "Delete Landlord/Manager",
              "description": "Are you sure you want to delete this Landlord/Manager?",
              "exitButton": "Delete Landlord/Manager"
            }
          },
          "flaws": {
            "viewImage": "View image",
            "facilityManagement": "Facility management",
            "maintenance": "Maintenance from",
            "defect": "Defect",
            "general": {
              "title": "Generally",
              "status": "Status:",
              "fixed": "Behoben",
              "open": "Offen",
              "measureServiceProvider": "Measures service provider",
              "measureOperator": "Measures operator"
            },
            "frameData": {
              "title": "Framework data",
              "name": "Name",
              "type": "Type",
              "description": "Description",
              "entryData": "Date of entry",
              "priority": "Priority",
              "deadline": "Deadline"
            },
            "beforeImage": "Before pictures",
            "afterImage": "After pictures"
          },
          "subsidiaryTenant": {
            "facilityManagementTitle": "Facility management",
            "subtenantTitle": "Subtenants",
            "subtenant": {
              "sectionElementTitle": "Subtenants",
              "generalInformation": {
                "gridElementTitle": "General information",
                "button": "Delete subtenant",
                "name": "Name",
                "notes": "Notes",
                "email": "E-mail address",
                "Telefon": "Phone",
                "fax": "Fax",
                "deposit": "Deposit",
                "depositPaid": "Deposit paid"
              },
              "address": {
                "gridElementTitle": "Address",
                "street": "Street",
                "houseNumber": "House number",
                "additionalAddress": "Address supplement",
                "zipCode": "Postal code",
                "city": "City",
                "country": "Country"
              }
            },
            "rentalProperties": {
              "sectionElementTitle": "Rental properties",
              "general": {
                "gridElementTitle": "General",
                "subsidiaryTransferable": "Object transferable",
                "easementRentalContract": "Easement agreed according to rental agreement",
                "easementRegistered": "Easement registered",
                "features": "Special features"
              },
              "plot": {
                "gridElementTitle": "Area",
                "plotArea": "Property area in m²",
                "mainRentalArea": "Main rental area in m²",
                "ancillaryArea": "Additional area(s) in m²",
                "totalArea": "Total area in m²"
              },
              "basicData": {
                "gridElementTitle": "Basic data",
                "handoverDate": "Handover day",
                "openingDate": "Opening day"
              },
              "rent": {
                "gridElementTitle": "Rent",
                "rentStartDate": "Start of rental",
                "rentPaymentStartDate": "Rent payment start date",
                "minimumRent": "Minimum rent (net rent) in €",
                "salesRent": "Sales rent in %",
                "rentIncrease": "Rent increases",
                "parkingSpaceRental": "Parking space rental in €",
                "advertisingSpaceRental": "Rent advertising space(s) in €",
                "operatingDevicesRental": "Rent operating equipment in €",
                "rentNotes": "Comment on rent/sales rent"
              },
              "serviceCharges": {
                "gridElementTitle": "Extra costs",
                "advancePaymentServiceCharges": "Additional cost advance payment(s) in €",
                "ancillaryCostsFlatRate": "Additional cost flat rate(s) in €",
                "advancePaymentServiceChargesAll": "NK advance payment for parking space/advertising space/operating equipment in €",
                "ancillaryCostsFlatRateAll": "NK flat rate for parking space/advertising space/operating equipment in €"
              },
              "costOverview": {
                "gridElementTitle": "Cost overview",
                "totalAncillaryCosts": "Total additional costs in €",
                "rentAndAncillaryCosts": "Rent and additional costs total in €",
                "ParkingAdvertisingOperatingCosts": "Rent and additional costs for parking space/advertising space/operating equipment total in €",
                "totalPropertyCosts": "Total property costs in €"
              }
            }
          },
          "subsidiary": {
            "plan": "Plan",
            "noServiceContract": "No service contract was found!",
            "notes": {
              "sectionElementTitle": "Notes/Annotations",
              "table": {
                "title": "Contract reference",
                "type": "Contract type",
                "number": "Contract number",
                "partnerName": "Contractual partner",
                "duration": "Duration"
              },
              "unlimitedDuration": "Permanent"
            },
            "breadCrumbsTitle": "Facility management",
            "branch": {
              "sectionElementTitle": "Subsidiary",
              "branchForm": {
                "gridElementTitle": "Subsidiary",
                "updateButton": "Update subsidiary",
                "deleteButton": "Delete subsidiary",
                "name": "Name",
                "abbreviation": "Abbreviation / subsidiary",
                "legalName": "Legal name",
                "address": "Address"
              },
              "general": {
                "gridElementTitle": "Generally",
                "branchType": "Subsidiary type",
                "status": "Status",
                "districtManager": "District/Regional Manager",
                "generalInformation": "General",
                "specialfeatures": "Special features",
                "notes": "Notes"
              },
              "contactData": {
                "gridElementTitle": "Contact details",
                "email": "E-mail address",
                "phoneNumber": "Telephone number",
                "fax": "Fax"
              }
            },
            "tradeAssignments": {
              "sectionElementTitle": "Work section assignments",
              "workSectionNotAvailable": "Work section not available / not necessary",
              "workSectionSearch": "Work section search..."
            },
            "TGASystems": {
              "sectionElementTitle": "TGA systems",
              "gridElementTitle": "TGA systems",
              "cfdsText": "CFDS systems are provided digitally by your service providers.",
              "table": {
                "name": "Name",
                "building": "Building",
                "interval": "Interval",
                "nextInspectionDate": "Next maintenance",
                "serviceProvider": "Responsible",
                "inventory": "(Target) stock"
              },
              "pieces": "pcs.",
              "target": " (Target)"
            },
            "orders": {
              "sectionElementTitle": "Orders",
              "gridElementTitle": "Orders",
              "table": {
                "date": "Date",
                "status": "Status",
                "number": "PO no.",
                "letterHead": "Letterhead",
                "type": "Type",
                "serviceProvider": "Service provider",
                "title": "Title",
                "sum": "Total (net)"
              }
            },
            "factSheets": {
              "sectionElementTitle": "Fact Sheets",
              "factSheetsButton": "Create fact sheet",
              "factSheetsText": "No fact sheets have yet been created for this subsidiary.",
              "deleteButton": "Delete fact sheet"
            },
            "rent": {
              "sectionElementTitle": "Rent/Ownership",
              "general": {
                "gridElementTitle": "General",
                "responsibilityMaintenance": "Responsibility for maintenance/repair",
                "subsidiaryTransferable": "Object transferable",
                "securityDeposit": "Amount of rental security / bank guarantee in €",
                "easementRentalContract": "Easement agreed according to rental agreement",
                "easementRegistered": "Easement registered",
                "features": "Special features"
              },
              "responsibilities": {
                "gridElementTitle": "Responsibilities",
                "landlord": "Landlord",
                "propertyManager": "Property manager"
              },
              "plot": {
                "gridElementTitle": "Area",
                "plotArea": "Property area in m²",
                "mainRentalArea": "Main rental area in m²",
                "ancillaryArea": "Additional area(s) in m²",
                "totalArea": "Total area in m²"
              },
              "basicData": {
                "gridElementTitle": "Basic data",
                "handoverDate": "Handover day",
                "openingDate": "Opening day"
              },
              "rents": {
                "gridElementTitle": "Rent",
                "rentStartDate": "Start of rental",
                "rentPaymentStartDate": "Rent payment start date",
                "minimumRent": "Minimum rent (net rent) in €",
                "salesRent": "Sales rent in %",
                "rentIncrease": "Rent increases",
                "parkingSpaceRental": "Parking space rental in €",
                "advertisingSpaceRental": "Rent advertising space(s) in €",
                "operatingDevicesRental": "Rent operating equipment in €",
                "rentNotes": "Comment on rent/sales rent"
              },
              "serviceCharges": {
                "gridElementTitle": "Extra costs",
                "advancePaymentServiceCharges": "Additional cost advance payment(s) in €",
                "ancillaryCostsFlatRate": "Additional cost flat rate(s) in €",
                "advancePaymentServiceChargesAll": "NK advance payment for parking space/advertising space/operating equipment in €",
                "ancillaryCostsFlatRateAll": "NK flat rate for parking space/advertising space/operating equipment in €"
              },
              "costOverview": {
                "gridElementTitle": "Cost overview",
                "totalAncillaryCosts": "Total additional costs in €",
                "rentAndAncillaryCosts": "Rent and additional costs total in €",
                "ParkingAdvertisingOperatingCosts": "Rent and additional costs for parking space/advertising space/operating equipment total in €",
                "totalPropertyCosts": "Total property costs in €"
              }
            },
            "subtenant": {
              "sectionElementTitle": "Subtenants",
              "gridElementTitle": "Subtenants",
              "button": "Create subtenants",
              "table": {
                "name": "Name",
                "place": "Location",
                "email": "E-mail",
                "Telefon": "Phone",
                "fax": "Fax"
              },
              "deleteButton": "Delete subtenant",
              "incompleteAddress": "Incomplete address"
            },
            "workflowExecutions": {
              "sectionTitle": "Workflows",
              "gridElementTitle": "Workflow executions",
              "button": "Start new workflow",
              "table": {
                "workflow": "NameWorkflow",
                "context": "Context",
                "branch": "Subsidiary",
                "team": "Working group",
                "user": "User",
                "createdAt": "Triggered on",
                "openSteps": "Open task"
              },
              "content": {
                "user": "User",
                "branch": "Subsidiary",
                "team": "Working group",
                "defect": "Shortage",
                "other": "Other",
                "tasks": "Tasks"
              },
              "deleteButton": "Delete Entry"
            },
            "documents": {
              "sectionTitle": "Documents",
              "title": "Documents"
            },
            "plans": {
              "sectionElementTitle": "Plans",
              "gridElementTitle": "Plans",
              "cfdsText": "CFDS plans are provided digitally by your service providers.",
              "table": {
                "name": "Name",
                "type": "Type",
                "markings": "Markings"
              }
            },
            "contracts": {
              "sectionTitle": "Contracts",
              "gridElementTitle": "Contracts",
              "table": {
                "title": "Contract reference",
                "type": "Contract type",
                "number": "Contract number",
                "partnerName": "Contractual partner",
                "duration": "Duration",
                "beginsAt": "Contract terms",
                "endsAt": "End of contract",
                "automaticRenewal": "Automatic renewal"
              },
              "unlimitedDuration": "Permanent",
              "unlimitedEndsAt": "Permanent",
              "yes": "Yes",
              "no": "No"
            },
            "performanceCycle": {
              "title": "Performance cycles"
            },
            "contactPerson": {
              "sectionElementTitle": "Contact person",
              "gridElementTitle": "Contact person",
              "button": "Create contact person",
              "table": {
                "salutationFormatted": "Salutation",
                "name": "Name",
                "email": "E-mail",
                "telephone": "Phone",
                "type": "Type",
                "company": "Company",
                "responsibility": "Responsibility"
              }
            },
            "serviceCharges": {
              "sectionTitle": "Extra costs",
              "gridElementTitle": "Additional cost settings",
              "utilitiesStatementNotes": "Notes on utility billing",
              "months": " Month(s)",
              "utilitiesStatementDeadlineMonths": "Deadline for utility billing",
              "utilitiesStatementAllegation": "According to case law (36 months)",
              "utilitiesStatementBackPayBarred": "Additional payment excluded",
              "operatingCostSavings": {
                "sectionTitle": "Operating cost savings",
                "button": "New operating cost statement",
                "paragraph": "No operating expense reports have yet been created for this subsidiary."
              },
              "wastewaterChargesSavings": {
                "sectionTitle": "Sewerage Fee Waiver Savings",
                "button": "New wastewater fee exemption",
                "paragraph": "No wastewater fee exemptions have yet been created for this subsidiary."
              },
              "subtenantSettlements": {
                "sectionTitle": "Subtenant statements",
                "button": "New subtenant bill",
                "paragraph": "No statements have yet been created for the subtenants."
              }
            },
            "spacemanagement": {
              "sectionElementTitle": "Areas",
              "gridElementTitle": "Areas",
              "table": {
                "name": "Name",
                "type": "Type/Art"
              }
            },
            "inspections": {
              "title": "inspections",
              "entryDelete": "Delete Entry",
              "duplicate": "Duplicate",
              "inspectionsCarriedOut": {
                "sectionTitle": "Inspections carried out",
                "gridElementTitle": "Inspections carried out",
                "text-1": "The implementation of",
                "text-2": "inspections",
                "text-3": "happens in the service desk.",
                "table": {
                  "date": "Date",
                  "status": "status",
                  "buildingInspectionTemplate": "Inspection template",
                  "branch": "Subsidiary",
                  "user": "Executive",
                  "result": "New complaints"
                },
                "sd": "SD:",
                "guestUser": "Guest user",
                "pieces": "pcs.",
                "done": "Fertig",
               "active": "Aktiv"
              }
            },
            "tickets": {
              "sectionTitle": "Tickets",
              "gridElementTitle": "Tickets",
              "table": {
                "status": "Status",
                "number": "Number",
                "title": "Title",
                "branch": "Subsidiary",
                "source": "Source",
                "type": "Type",
                "priority": "Priority",
                "updatedAt": "Last edited"
              }
            },
            "updateSubsidiaryTenantUtilitiesStatement": {
              "name": "updateSubsidiaryTenantUtilitiesStatement",
              "date": "Date",
              "clerk": "Clerk",
              "state": "Processing status",
              "stateTypes": {
                "notApplicable": "Not applicable",
                "pending": "Pending",
                "afoot": "In Progress",
                "done": "Completed",
                "rebuffed": "Rejected",
                "requested": "Requested",
                "notRequested": "Not requested"
              },
              "refund": "Refund",
              "subtenant": "Subtenants",
              "type": "Type",
              "typeTypes": {
                "effluent": "Wastewater fee exemption",
                "overhead": "Operating cost"
              },
              "button": "Notes"
            },
            "updateUtilitiesStatement": {
              "name": "updateSubsidiaryTenantUtilitiesStatement",
              "date": "Date",
              "clerk": "Clerk",
              "state": "Processing status",
              "stateTypes": {
                "notApplicable": "Not applicable",
                "pending": "Pending",
                "afoot": "In Progress",
                "done": "Completed",
                "rebuffed": "Rejected",
                "requested": "Requested",
                "notRequested": "Not requested"
              },
              "refund": "Refund",
              "invoice": "Billing party",
              "freeWasteWater": "Release ",
              "button": "Notes"
            },
            "createSubsidiaryFactSheetModal": {
              "title": "Create fact sheet",
              "factSheetLabel": "Fact sheet",
              "exitButton": "Create fact sheet"
            },
            "createWorkflowExecutionModal": {
              "title": "Execute workflow",
              "factSheetLabel": "Workflow",
              "exitButton": "Execute workflow"
            },
            "deleteSubsidiaryFactSheetModal": {
              "title": "Delete fact sheet",
              "description": "Are you sure you want to delete this fact sheet? ",
              "button": "Delete fact sheet"
            },
            "styled": {
              "cases": {
                "title": "Delete case",
                "description": "Are you sure you want to delete this case? ",
                "deleteButton": "Delete case",
                "sectionElementTitle": "Case history",
                "gridElementTitle": "Case history",
                "createCaseButton": "Create case",
                "paragraph": "This office has no cases yet."
              },
              "counters": {
                "counterOne": "Counter",
                "counterTwo": "Counter",
                "gridElementTitle": "Meter readings",
                "text1": "If you do that",
                "text2": "Smart building module",
                "text3": "you can automate the design.",
                "createCounterGroupButton": "Create counter group",
                "createCounterButton": "Create case",
                "noMetersDescription": "This subsidiary does not yet have any meters.",
                "editButton": "Edit",
                "imageSection": {
                  "enterStand": "Record status",
                  "Standhistorie": "Stand history",
                  "editButton": "Edit",
                  "deleteButton": "Delete",
                  "type": "Type:",
                  "name": "Name:",
                  "location": "Location:",
                  "number": "Number:",
                  "recorded": "Perm. recorded on:",
                  "electricity": "Electricity meter (kWh)",
                  "water": "Water meter (m³)",
                  "gas": "Gas meter (m³)",
                  "teleheating": "District heating (MWh)",
                  "cold": "Cold (MWh)"
                }
              },
              "ratingView": {
                "percent": "Percent",
                "overallRating": "Overall rating",
                "functionalObjects": "Functional objects",
                "correctedDefects": "Fixed defects",
                "complianceInspectionDeadlines": "Compliance with inspection deadlines"
              },
              "createCaseModal": {
                "titleForm": "Create new case",
                "title": "title",
                "date": "Date",
                "caseDescription": "Case description",
                "exitButton": "Create new case"
              },
              "createContactModal": {
                "title": "Create contact person",
                "firstName": "First name",
                "lastName": "Last name",
                "email": "E-mail address",
                "faxNumber": "Fax number",
                "responsibility": "Responsibility",
                "type": "Type",
                "telephoneNumber": "Telephone number",
                "mobile": "Mobile phone number",
                "company": "Company or company",
                "exitButton": "Create contact person"
              },
              "createMeterGroupModal": {
                "title": "Create new meter group",
                "name": "Name",
                "exitButton": "Create new meter group"
              },
              "createMeterModal": {
                "title": "Create new counter",
                "name": "Name",
                "deliveryStart": "Start of delivery",
                "counterUnit": "Meter type/unit",
                "counterTypes": {
                  "electricity": "Electricity meter (kilowatt hours kWh)",
                  "water": "Water meter (cubic meters m³)",
                  "gas": "Gas meter (cubic meter m³)",
                  "teleheating": "District heating (megawatt hours MWh)",
                  "cold": "Cold (megawatt hours MWh)"
                },
                "position": "Position / location",
                "counterNumber": "Meter number",
                "marketLocationNumber": "Market location number",
                "measurementLocationNumber": "Measuring location number",
                "factor": "Factor",
                "exitButton": "Create new counter"
              },
              "createMeterReadingModal": {
                "title": "Record meter reading",
                "meterValue": "Counter value",
                "readingDate": "Reading date",
                "exitButton": "Record meter reading"
              },
              "createSubsidiaryTenantUtlitiesStatementModal": {
                "title": "Create a new subtenant statement",
                "landlord": "Subtenants",
                "Jahr": "Year",
                "type": "Type",
                "typeTypes": {
                  "effluent": "Wastewater fee exemption",
                  "overhead": "Operating cost"
                },
                "exitButton": "Create a new subtenant statement"
              },
              "createTenantModal": {
                "title": "Create subtenants",
                "name": "Name of the subtenant",
                "exitButton": "Create subtenants"
              },
              "createUtilitiesStatementEffluentModal": {
                "title": "Create a new wastewater fee exemption",
                "year": "Year",
                "exitButton": "Create a new wastewater fee exemption"
              },
              "createUtilitiesStatementOverheadModal": {
                "title": "Create a new operating cost statement",
                "year": "Year",
                "exitButton": "Create a new operating cost statement"
              },
              "deleteMeterGroupModal": {
                "title": "Delete counter group",
                "text1": "Are you sure you want to delete this counter group? ",
                "text2": "The counters contained in the group are not lost.",
                "exitButton": "Delete counter group"
              },
              "deleteMeterHistoryModal": {
                "title": "Clear counter reading",
                "description": "Are you sure you want to delete this meter reading? ",
                "exitButton": "Clear counter reading"
              },
              "deleteMeterModal": {
                "title": "Clear counter",
                "description": "Are you sure you want to delete this counter? ",
                "exitButton": "Clear counter"
              },
              "deleteSubsidiaryModal": {
                "title": "Delete subsidiary",
                "description": "Are you sure you want to delete this subsidiary? ",
                "exitButton": "Delete subsidiary"
              },
              "deleteSubsidiaryTenantUtlitiesStatement": {
                "title": "Delete subtenant statement",
                "description": "Are you sure you want to delete this subtenant statement?",
                "exitButton": "Delete subtenant statement"
              },
              "deleteTenantModal": {
                "title": "Delete subtenant",
                "description": "Are you sure you want to delete this subtenant? ",
                "exitButton": "Delete subtenant"
              },
              "deleteUtilitiesModal": {
                "title": "Delete NK billing",
                "description": "Are you sure you want to delete this NK statement?",
                "exitButton": "Delete NK billing"
              },
              "deleteMeterReadingModal": {
                "title": "Stand history",
                "description": "SThis meter does not yet have a status history.",
                "table": {
                  "date": "Date",
                  "user": "User",
                  "difference": "Difference to previous reading",
                  "value": "New value"
                },
                "editButton": "Edit entry",
                "deleteButton": "Delete entry"
              },
              "newFacilityModal": {
                "title": "Create new system",
                "name": "Name",
                "building": "Building",
                "trade": "Work section",
                "tradeTypes": {
                  "security": "Safety devices, construction site facilities",
                  "fireBarriers": "Insulation and fire protection work on technical systems",
                  "fireExtinguisher": "Fire extinguishing systems, fire extinguishing devices",
                  "buildings": "Building systems technology",
                  "securityLights": "Security lighting systems",
                  "alarms": "Hazard alarm systems",
                  "airConditioning": "Air conditioning systems",
                  "coldConditioning": "Refrigeration systems for air conditioning systems",
                  "construction": "Construction logistics",
                  "fireSecurity": "Fire protection devices",
                  "heater": "Heating/sanitary systems",
                  "workOrder": "processing machines",
                  "electricity": "Electrical systems",
                  "swimmingPool": "Swimming pool technology"
                },
                "mainMask": "Main mask",
                "intervalQuantity": "Interval number",
                "intervalUnit": "Interval unit",
                "intervalUnitTypes": {
                  "days": "days",
                  "weeks": "weeks",
                  "months": "Months",
                  "years": "Years"
                },
                "nextMaintenance": "Next maintenance",
                "responsibility": "Responsible",
                "targetStock": "(target) stock",
                "createSystemButton": "Create facility",
                "cancelButton": "Cancel"
              },
              "tenantUtilitiesStatementDocumentsModal": {
                "title": "File box",
                "documents": "Documents"
              },
              "updateContactModal": {
                "title": "Update contact person",
                "firstName": "First name",
                "lastName": "Last name",
                "email": "E-mail address",
                "faxNumber": "Fax number",
                "responsibility": "Responsibility",
                "type": "Type",
                "telephoneNumber": "Telephone number",
                "mobile": "Mobile phone number",
                "company": "Company or company",
                "exitButton": "Update contact person",
                "deleteButton": "Delete contact person"
              },
              "updateMeterGroupModal": {
                "title": "Update counter group",
                "description": "Name"
              },
              "updateMeterHistoryModal": {
                "title": "Update meter reading",
                "description": "Counter value for",
                "exitButton": "Update meter reading"
              },
              "updateMeterModal": {
                "title": "Update counters",
                "active": "Active",
                "name": "Name",
                "deliveryStart": "Start of delivery",
                "position": "Position / location",
                "counterNumber": "Meter number",
                "marketLocationNumber": "Market location number",
                "measurementLocationNumber": "Measuring location number",
                "factor": "Factor",
                "exitButton": "Update counters"
              },
              "updateSubsidiaryModal": {
                "titleForm": "Update subsidiary",
                "generalInformation": "General information",
                "name": "Name",
                "abbreviation": "Abbreviation / subsidiary",
                "legalName": "Legal name",
                "address": "Address",
                "street": "Street",
                "houseNumber": "House number",
                "additionalAddress": "Address supplement",
                "postalCode": "Postal code",
                "city": "City",
                "country": "Country",
                "exitButton": "Update subsidiary"
              },
              "utilitiesStatementDocumentsModal": {
                "title": "File box",
                "documents": "Documents"
              }
            }
          },
          "tours": {
            "breadCrumbsTitle": "Interactive tours"
          },
          "object": {
            "pastMaintenance": {
              "title": "Past maintenance",
              "table": {
                "workOrder": "Order",
                "date": "Date",
                "technician": "Technician",
                "operative": "Result"
              },
              "operationReady": "Ready for operation",
              "outOfFunction": "Out of order",
              "notSpecified": "Not specified"
            },
            "reportDefect": {
              "title": "Report a defect",
              "defectName": "Deficiency name",
              "detailedDescription": "Detailed description",
              "exitButton": "Send shortage to service provider"
            },
            "viewImage": "View image",
            "component": "component",
            "componentForm": {
              "title": "Add component",
              "trade": "Work section",
              "testTrade": "Test work section",
              "component": "component",
              "fireExtinguisher": "Fire extinguisher",
              "quantity": "Number"
            },
            "defectsForm": {
              "title": "Defects",
              "defects": "Shortage",
              "description": "Description",
              "recorded": "Created At",
              "priority": "Priority",
              "deadline": "Deadline",
              "type": "Type",
              "condition": "Condition",
              "priorityTypes": {
                "low": "Low",
                "medium": "Medium",
                "high": "High"
              },
              "fixedInMaintenance": {
                "fixed": "Fixed",
                "open": "Open"
              },
              "getIntervalType": {
                "days": "Tag(-e)",
                "weeks": "Woche(-n)",
                "months": "Monat(-e)",
                "years": "Jahr(-e)"
              }
            },
            "breadCrumbsTitle": "Facility management",
            "general": {
              "sectionTitle": "Generally",
              "saveDetailsButton": "Save information",
              "objectEditButton": "Edit object",
              "smid": {
                "title": "SMID",
                "digitalID": "Digital ID",
                "physicalID": "Physical ID"
              },
              "operationReady": "Ready to function",
              "outOfFunction": "Out of order",
              "notSpecified": "Not specified",
              "pastMaintenance": "Past maintenance",
              "correctedDefects": "Fixed defects",
              "manualObjectText": "This object was created manually, therefore no maintenance is possible for this object.",
              "frameData": {
                "title": "Framework data",
                "positionDescription": "Position description",
                "notes": "Notes",
                "manufacturer": "Manufacturer",
                "product": "Product"
              },
              "PlanPosition": "Plan position",
              "defectsForm": {
                "title": "Maintenance",
                "lastMaintenance": "Last maintenance",
                "interval": "Interval",
                "nextMaintenance": "Next maintenance"
              },
              "component": {
                "title": "Components",
                "addComponentButton": "Add component",
                "table": {
                  "smid": "SMID",
                  "component": "Component",
                  "manufacturer": "Manufacturer",
                  "model": "Product",
                  "amount": "Number"
                },
                "deleteComponent": "Delete component",
                "mainComponent": "Main component",
                "pieces": "pcs."
              },
              "gallery": "Gallery",
              "jpg/pngData": "Place any images of the object here in JPG/JPEG or PNG format."
            }
          },
          "dueDates": {
            "breadCrumbsTitle": "Due dates",
            "gridElementTitle": "Due dates",
            "table": {
              "branch": "Subsidiary",
              "code": "Labelling",
              "location": "Location",
              "component": "Component",
              "duration": "Duration",
              "building": "Building",
              "due": "Due",
              "plant": "DL planned"
            }
          },
          "escalation": {
            "highPriority": "high Priority",
            "mediumPriority": "medium Priority",
            "lowPriority": "low Priority",
            "breadCrumbsTitle": "Escalation",
            "gridElementTitle": "Escalation",
            "table": {
              "branch": "Subsidiary",
              "code": "Code",
              "location": "Location",
              "building": "Building",
              "name": "Name",
              "smid": "SMID",
              "priority": "Priority",
              "due": "Due"
            },
            "newTabLinkButton": "Open link in new tab",
            "low": "Low",
            "medium": "Medium",
            "high": "High",
            "unknown": "Unknown"
          },
          "buildingInspection": {
            "title": "inspections",
            "entryDelete": "Delete Entry",
            "duplicate": "Duplicate",
            "inspectionsCarriedOut": {
              "sectionTitle": "Inspections carried out",
              "gridElementTitle": "Inspections carried out",
              "text-1": "The implementation of",
              "text-2": "inspections",
              "text-3": "happens in the service desk.",
              "table": {
                "date": "Date",
                "status": "status",
                "buildingInspectionTemplate": "Inspection template",
                "branch": "Subsidiary",
                "user": "Executive",
                "result": "New complaints"
              },
              "sd": "SD:",
              "guestUser": "Guest user",
              "pieces": "pcs."
            },
            "upcomingInspections": {
              "sectionTitle": "Upcoming inspections",
              "gridElementTitle": "Upcoming inspections",
              "table": {
                "due": "Date",
                "buildingInspectionTemplate": "Inspection template",
                "branch": "Subsidiary",
                "interval": "interval",
                "reclamations": "Open complaints"
              },
              "pieces": "pcs."
            },
            "inspectionTemplates": {
              "sectionTitle": "Inspection templates",
              "gridElementTitle": "Inspection templates",
              "button": "Create inspection templates",
              "table": {
                "name": "Name",
                "type": "Type",
                "status": "status",
                "reportTemplate": "Report template",
                "interval": "Interval",
                "steps": "steps",
                "createdAt": "Created"
              },
              "pieces": "pcs.",
              "no": "No",
              "createBuildingInspectionTemplateModal": {
                "title": "Create an inspection template",
                "name": "Name",
                "type": "Type",
                "typeTable": {
                  "safetyInspection": "Safety inspection",
                  "defectView": "Defect view",
                  "test": "Test run",
                  "visualControl": "Visual inspection",
                  "maintenance": "maintenance",
                  "riskAssessment": "Risk assessment",
                  "asa": "ASA inspection",
                  "sibe": "SiBe inspection"
                },
                "reportTemplate": "Report template",
                "exitButton": "Create an inspection template"
              },
              "duplicateBuildingInspectionTemplateModal": {
                "title": "Duplicate inspection template",
                "name": "Name",
                "type": "Type",
                "typeTable": {
                  "safetyInspection": "Safety inspection",
                  "defectView": "Defect view",
                  "test": "Test run",
                  "visualControl": "Visual inspection",
                  "maintenance": "maintenance",
                  "riskAssessment": "Risk assessment",
                  "asa": "ASA inspection",
                  "sibe": "SiBe inspection"
                },
                "reportTemplate": "Report template",
                "exitButton": "Duplicate inspection template"
              },
              "pdfModal": {
                "title": "PDF view",
                "upperDescription": "This PDF cannot be viewed yet.",
                "lowerDescription": "Make sure the inspection is completed. "
              },
              "deleteBuildingInspectionTemplateModal": {
                "title": "Delete inspection template",
                "description": "Are you sure you want to delete this inspection template? ",
                "button": "Delete inspection template"
              }
            },
            "reportTemplates": {
              "sectionTitle": "Report templates",
              "gridElementTitle": "Report templates",
              "button": "Create report template",
              "table": {
                "name": "Name",
                "createdAt": "Created"
              },
              "createBuildingInspectionsReportTemplateModal": {
                "title": "Create report template",
                "name": "Name",
                "searchPlaceholder": "Your HTML code for the content of the report template.",
                "exitButton": "Create report template"
              },
              "updateBuildingInspectionsReportTemplateModal": {
                "title": "Update report template",
                "name": "Name",
                "searchPlaceholder": "Your HTML code for the content of the report template."
              },
              "deleteBuildingInspectionReportTemplateModal": {
                "title": "Delete report template",
                "description": "Are you sure you want to delete this report template? ",
                "button": "Delete report template"
              }
            },
            "deleteBuildingInspectionModal": {
              "title": "Delete inspection",
              "description": "Are you sure you want to delete this visit? ",
              "button": "Delete inspection"
            }
          },
          "accounting": {
            "breadCrumbsTitle": "Finance",
            "unbooked": {
              "sectionTitle": "Entrance stack",
              "gridElementTitle": "Entrance stack",
              "button": "Upload invoice receipt",
              "table": {
                "createdAt": "Uploaded",
                "size": "Size",
                "name": "Name",
                "source": "Source",
                "manuallyUploaded": "Manually uploaded by",
                "automaticallyUploaded": "Automatically uploaded by"
              },
              "deleteButton": "Delete invoice document",
              "bookInvoiceModal": {
                "titleForm": "Enter invoice receipt",
                "back": "Back",
                "page": "Page",
                "uploadPDFdata": "Load PDF file...",
                "further": "Further",
                "billDate": "Date of invoice",
                "billNumber": "Invoice number",
                "netto": "Amount in EUR (net)",
                "brutto": "Amount in EUR (gross)",
                "serviceProvider": "Service provider",
                "optionalInformation": "Optional information",
                "branch": "Subsidiary",
                "exitButton": "Enter invoice receipt"
              },
              "uploadInvoiceModal": {
                "titleForm": "Upload invoice receipt",
                "billPDF": "Save your invoice here in PDF format, or click here to open the file browser.",
                "exitButton": "Upload invoice receipt"
              },
              "DeleteAccountingInvoiceDocumentModal": {
                "title": "Delete invoice document",
                "description": "Are you sure you want to delete this invoice document? ",
                "button": "Delete invoice document"
              }
            },
            "factualClearance": {
              "sectionTitle": "Factual examination",
              "gridElementTitle": "Factual examination",
              "table": {
                "number": "Invoice number",
                "date": "Date",
                "serviceProvider": "Service provider",
                "net": "EUR(Net)",
                "gross": "EUR(Gross)",
                "branch": "Subsidiary",
                "purchaseOrder": "Order (PO)",
                "contract": "Contract"
              }
            },
            "fiClearance": {
              "sectionTitle": "FI posting",
              "gridElementTitle": "FI posting",
              "table": {
                "number": "Invoice number",
                "date": "Date",
                "serviceProvider": "Service provider",
                "net": "EUR(Net)",
                "gross": "EUR(Gross)",
                "branch": "Subsidiary",
                "purchaseOrder": "Order (PO)",
                "contract": "Contract"
              }
            },
            "waitingForPayment": {
              "sectionTitle": "Waiting for payment",
              "gridElementTitle": "Waiting for payment",
              "table": {
                "number": "Invoice number",
                "date": "Date",
                "serviceProvider": "Service provider",
                "net": "EUR(Net)",
                "gross": "EUR(Gross)",
                "branch": "Subsidiary",
                "purchaseOrder": "Order (PO)",
                "contract": "Contract"
              }
            },
            "archive": {
              "sectionTitle": "Archive",
              "gridElementTitle": "Archive",
              "button": "Upload invoice receipt",
              "table": {
                "number": "Invoice number",
                "date": "Date",
                "serviceProvider": "Service provider",
                "net": "EUR(Net)",
                "gross": "EUR(Gross)",
                "branch": "Subsidiary",
                "purchaseOrder": "Order (PO)",
                "contract": "Contract"
              }
            },
            "statistics": {
              "sectionTitle": "Evaluation",
              "monthlyCost": "Monthly costs through service providers",
              "loadingBillingData": "Load billing data...",
              "gridElementTitle": "Invoices received",
              "table": {
                "date": "Date",
                "number": "Bill number",
                "serviceProvider": "Service provider",
                "workSection": "Work section",
                "subsidiary": "Subsidiary",
                "cost": "Net amount"
              }
            },
            "factualClearanceModal": {
              "title": "Check the invoice objectively",
              "notesAccountant": "Comment from FI accountant",
              "rejectionReason": "Reason for rejection",
              "document": "Documents",
              "invoiceDocument": "Invoice receipt:",
              "orderDocument": "Order receipt:",
              "pdf": ".pdf",
              "warning": "Warnings/Notes",
              "noWarning": "No warnings/notes.",
              "readInvoiceInformation": "Read view billing information",
              "billDate": "Date of invoice",
              "billNumber": "Invoice number",
              "netto": "Amount in EUR (Net)",
              "brutto": "Amount in EUR (Gross)",
              "audit": "Audit",
              "accountantNote": "Note to FI accountant / reason for rejection",
              "newAccount": "e.g. note new account details / invoice amount does not match PO",
              "exitButton": "Reject invoice"
            },
            "fiClearanceModal": {
              "title": "Mark invoice as posted",
              "document": "Documents",
              "invoiceDocument": "Invoice receipt:",
              "orderDocument": "Order receipt:",
              "pdf": ".pdf",
              "auditor": "Note from auditor",
              "bookingNote": "Booking note / reason for rejection for auditors",
              "invoiceForm": "e.g. invoice form incomplete",
              "invoiceMarkPosted": "Mark invoice as posted",
              "exitButton": "Reject invoice"
            },
            "paymentModal": {
              "title": "Mark invoice as paid",
              "paymentDate": "date of payment",
              "exitButton": "Mark invoice as paid"
            }
          },
          "budget": {
            "breadCrumbsTitle": "budgeting",
            "budgetMonitoring": {
              "sectionTitle": "Budget monitoring",
              "provisionsRequired": {
                "gridElementTitle": "Provision requirement",
                "description": "Claims that did not come about according to the target calculation"
              },
              "upcomingTargetCosts": {
                "gridElementTitle": "Upcoming target costs",
                "description": "Target costs still to be incurred, which consist of services or planned figures"
              },
              "actualCosts": {
                "gridElementTitle": "Previous actual costs",
                "description": "Costs incurred so far, which consist of receipts"
              },
              "remainingBudget": {
                "gridElementTitle": "Remaining budget",
                "description": "Remaining budget at the end of the year"
              },
              "budgetDevelopment": {
                "gridElementTitle": "Budget development",
                "remainingBudgetGraphLine": "Remaining budget",
                "costDevelopment": "Cost development (actual/target)",
                "endStartText": "Kostenentwicklung (Ist/Soll)"
              },
              "costDevelopmentGraph": {
                "targetCosts": "Target costs from LZ",
                "actualCosts": "Actual costs from FI",
                "provisionsRequired": "Provision requirement"
              }
            },
            "targetFigures": {
              "sectionTitle": "Planned figures",
              "description": "Budget categories can be defined in the settings.",
              "budgetCategory": "Budget category",
              "targetNumber": "Target number"
            },
            "changeBudgetModal": {
              "title": "Set annual budget",
              "yearlyBudget": "Annual budget",
              "exitButton": "Set annual budget"
            }
          },
          "spaceManagement": {
            "breadCrumbsTitle": "Areas",
            "gridElementTitle": "Areas",
            "deleteButton": "Create area",
            "table": {
              "name": "Name",
              "types": "Type/Art",
              "branch": "Subsidiary",
              "rooms": "Rooms"
            },
            "pieces": "pcs.",
            "spaceManagementForm": {
              "titleForm": "Create new area",
              "name": "Name",
              "types": "Type/Art",
              "branch": "Subsidiary",
              "2dFloorplan": "Save your 2D floor plan here in DXF or DWG format.",
              "dwgConversion": "Convert DWG to DXF, this may take a while...",
              "exitButton": "Create area"
            }
          },
          "space": {
            "loader": {
              "loadGeometry": "Loading geometry, just a moment...",
              "middleRoom": "Click in the center of the room you want to create.",
              "middleRoomCancel": "Cancel",
              "respectivePositionRoom": "Click on the appropriate location where you would like to move the room",
              "respectivePositionRoomCancel": "Cancel"
            },
            "roomOnMap": {
              "area": "Area:",
              "meterSquare": "m²",
              "WorkingSpace": "Max. workplaces according to ASR A1.2:",
              "persons": "Persons)",
              "addPersonButton": "Add person",
              "repositionLink": "Reposition",
              "dropdownMeterSquare": "m²",
              "dropdownPersons": "Persons)"
            },
            "newRoomForm": {
              "title": "Create a new room",
              "rooms": "rooms",
              "noRoomsCreated": "You haven't created any rooms yet."
            },
            "createRoomModal": {
              "title": "Create a new room",
              "name": "Name",
              "area": "Surface in m²",
              "exitButton": "Create space"
            },
            "createRoomUserModal": {
              "title": "Add new person to the room",
              "userImport": "Import from User",
              "firstName": "First name",
              "lastName": "Last name",
              "position": "Position/activity",
              "notes": "Notes",
              "exitButton": "Add new person to the room"
            },
            "deleteRoomModal": {
              "title": "Clear room",
              "description": "Are you sure you want to delete this room?",
              "button": "Clear room"
            }
          },
          "integrations": {
            "deactivated": "Disabled",
            "Integration": "Integrations",
            "marketPlace": "Marketplace",
            "googleworkspace": {
              "title": "Google Workspace",
              "todoList": "Todoist",
              "OutlookCalendar": "Outlook Calendar",
              "hotelKit": "Hotel kit",
              "slackChatbot": "Slack chatbot",
              "microsoftTeams": "Microsoft Teams",
              "beuth": "Beuth",
              "getMyInvoices": "GetMyInvoices.com",
              "mailGun": "Mailgun email server",
              "twilio": "twilio communication",
              "holoLens": "HoloLens SDK",
              "Loxone": "Loxone Smart Building",
              "droneRecording": "DJI drone shot",
              "3Drecording": "Matterport 3D recording"
            },
            "description": {
              "title": "Synchronize upcoming deadlines and due dates directly with your Google Calendar, display your Google appointments in scanmetrix, access your Google Drive, use your Google contacts for the conversation center and synchronize your to-do lists.",
              "todoList": "Synchronize your agenda and deadlines with your Todoist account.",
              "OutlookCalendar": "Synchronize upcoming deadlines and due dates directly with your Outlook calendar and display your Google appointments in scanmetrix.",
              "hotelKit": "Shorten communication channels to the users created in Hotelkit and compare and archive inspection reports of your hotels.",
              "slackChatbot": "Connect a slack chatbot to start conversations, reply to them and receive notifications.",
              "microsoftTeams": "Connect a Microsoft Teams chatbot to start conversations, reply to them and receive notifications.",
              "beuth": "Required integration for the legal texts module. Maps the interface to DIN and semi-public data sheets.",
              "getMyInvoices": "Automatically import invoices into the invoicing module from all kinds of data sources."
            },
            "version": "Version 1.0.0",
            "unlink": "Disconnect link",
            "IntegrationInstallation": "Install integration",
            "googleWorkspaceModal": {
              "title": "Link Google Calendar",
              "description": "Sign in to Google to link your calendar.",
              "linkButton": "Link Google account",
              "registrationButton": "Sign in to Google"
            },
            "integrationNotPossible": "Integration not available"
          },
          "marketPlace": {
            "breadCrumbsTitle": "Marketplace",
            "activateObjectClasses": {
              "sectionTitle": "Enable object classes",
              "searchPlaceholder": "Search by work section or object class...",
              "results": "Results",
              "soonAvailable": "Available Soon",
              "priceClassA": "Price class A",
              "component": "Components",
              "tests": "exams",
              "activationListButton": {
                "inActivationList": "In the activation list",
                "outActivationList": "Remove from activation list",
                "alreadyActivated": "Already activated",
                "unknown": "Unknown"
              },
              "activationList": {
                "title": "Activation list",
                "text1": "You have no object classes in your activation list.",
                "text2": "Use the list on the left to add object classes.",
                "legalCondition": "I have read and accept the general terms and conditions. ",
                "exitButton": "Activate for a fee"
              }
            },
            "activatedObjectClasses": {
              "sectionTitle": "Enabled object classes"
            }
          },
          "notificationSettings": {
            "breadCrumbsTitle": "Settings",
            "gridElementTitle": "Notifications",
            "immediately": "Instantly",
            "hours": "Hr.",
            "days": "D",
            "facilityManagement": {
              "title": "Facility management",
              "deadlineTechnicalInstallations": "Deadline for technical systems",
              "deadlineDefect": "Deadline deadline defect"
            },
            "contractManagement": {
              "title": "Facility management",
              "contractStart": "Start of contract",
              "contractEnd": "End of contract",
              "terminationPeriod": "Notice period",
              "pendingExtensionOption": "Pending extension option",
              "pendingContractExtension": "Upcoming contract extension",
              "upcomingServiceCycle": "Upcoming performance cycle"
            },
            "calendar": {
              "title": "Calendar",
              "appointmentStart": "Start of appointment"
            },
            "inspection": {
              "title": "Inspection",
              "dueInspection": "Inspection is due"
            },
            "tickets": {
              "title": "Tickets",
              "newTicket": "New ticket",
              "assignedTicket": "Ticket assigned to you"
            }
          },
          "profile": {
            "device": {
              "removeDeviceButton": "Remove device",
              "deviceText1": "You have not yet linked a device to the cockpit.",
              "deviceText2": "Download this <b>scanmetrix Mobile Cockpit</b> to link your device."
            },
            "chatButton": "To chat",
            "searchPlaceholder": "Send daily FM reports",
            "profileButton": {
              "saveInformation": "Save information'",
              "editProfile": "Edit profile"
            },
            "profileTitle": "Profile",
            "contact": {
              "title": "Contact",
              "landlineNumber": "Landline number",
              "noInfo1": "Not specified",
              "mobileNumber": "Mobile number",
              "noInfo2": "Not specified",
              "faxNumber": "Fax number",
              "noInfo3": "Not specified",
              "email": "E-mail address",
              "noInfo4": "Not specified",
              "noInfo5": "Not specified",
              "noInfo6": "Not specified"
            },
            "description": {
              "title": "Description",
              "searchPlaceholder": "Enter your public profile description here.",
              "noProfileDescription": "This profile has no description yet.",
              "activity": "Activities"
            },
            "ORCodeModal": {
              "title": "Mobile Cockpit device registration",
              "text1": "scanmetrix Mobile Cockpit",
              "text2": "Scan your QR code in the scanmetrix Mobile Cockpit to use the scanmetrix Cockpit on your mobile device.",
              "text3": "To reduce data usage, connect your phone to a Wi-Fi network.",
              "exitButton": "Ready"
            },
            "socialMediaModal": {
              "title": "Provide link",
              "url": "URL",
              "exitButton": "To link"
            }
          },
          "legalTexts": {
            "unread": "Unread",
            "breadCrumbsTitle": "Legal texts",
            "latestChange": "last changes",
            "acquiredLegalTexts": "Acquired legal texts",
            "acquiredLegalTextsFiles": {
              "file1": {
                "date": "September 2009",
                "title": "DIN 14406-4",
                "publisher": "Beuth Verlag"
              },
              "file2": {
                "date": "January 1984",
                "title": "DIN 18089-1",
                "publisher": "Beuth Verlag"
              },
              "file3": {
                "date": "April 2017",
                "title": "DIN 14033",
                "publisher": "Beuth Verlag"
              },
              "file4": {
                "date": "September 2020",
                "title": "DIN 14462",
                "publisher": "Beuth Verlag"
              },
              "file5": {
                "date": "October 2018",
                "title": "DIN 4108-3",
                "publisher": "Beuth Verlag"
              },
              "file6": {
                "date": "November 1984",
                "title": "DIN 15018-3",
                "publisher": "Beuth Verlag"
              },
              "file7": {
                "date": "February 2001",
                "title": "DIN 685-2",
                "publisher": "Beuth Verlag"
              },
              "acquireFurtherTexts": "Purchase additional texts"
            },
            "latestChangeFiles": {
              "file1": {
                "date": "Coming out in December",
                "title": "DIN EN ISO 23387",
                "description": "Building information modeling (BIM) - Data templates for construction objects during the life cycle of a built asset - Concepts and principles (ISO 23387:2020); "
              },
              "file2": {
                "date": "New: 3 days ago",
                "title": "DIN EN 60335-2-17",
                "description": "Safety of electrical appliances for domestic and similar purposes - Part 2-17: Particular requirements for thermal blankets, heated blankets, heating pads, clothing and similar conformable warming devices (IEC 60335-2-17:2012 A1:2015, modified); "
              },
              "file3": {
                "date": "New: 7 days ago",
                "title": "DIN 16463",
                "description": "This standard specifies a method for determining the influence of chemical fluids transported in the pipe on the creep internal pressure behavior of thermoplastic pressure pipes."
              }
            },
            "legalTextsFiles": {
              "pages": "pages",
              "shoppingCartButton": "Add to Cart",
              "alreadyPurchasedButton": "Already purchased",
              "title": "Legal text marketplace",
              "table": {
                "DIN": "DIN",
                "EN": "EN",
                "ASR": "ASR",
                "SPrüfV": "SPrüfV",
                "VDMA": "VDMA",
                "ISO/IEC": "ISO/IEC",
                "VDI": "VDI",
                "VDE": "VDE",
                "BetrSichV": "BetrSichV"
              },
              "purchaseableItem1": {
                "date": "September 2009",
                "title": "Portable fire extinguishers - Part 4: Maintenance",
                "name": "DIN 14406-4",
                "publisher": "Beuth Verlag"
              },
              "purchaseableItem2": {
                "date": "January 1984",
                "title": "fire barriers; ",
                "name": "DIN 18089-1",
                "publisher": "Beuth Verlag"
              },
              "purchaseableItem3": {
                "date": "April 2017",
                "title": "Abbreviation for the fire department",
                "name": "DIN 14033",
                "publisher": "Beuth Verlag"
              },
              "purchaseableItem4": {
                "date": "September 2020",
                "title": "Fire-fighting water systems - planning, installation, operation and maintenance of wall hydrant systems as well as systems with above-ground and underground hydrants",
                "name": "DIN 14462",
                "publisher": "Beuth Verlag"
              },
              "purchaseableItem5": {
                "date": "October 2018",
                "title": "Thermal protection and energy saving in buildings - Part 3: Climate-related moisture protection - Requirements, calculation methods and information for planning and execution",
                "name": "DIN 4108-3",
                "publisher": "Beuth Verlag"
              },
              "purchaseableItem6": {
                "date": "November 1984",
                "title": "cranes; ",
                "name": "DIN 15018-3",
                "publisher": "Beuth Verlag"
              },
              "purchaseableItem7": {
                "date": "February 2001",
                "title": "Tested round steel chains - Part 2: Safety requirements",
                "name": "DIN 685-2",
                "publisher": "Beuth Verlag"
              },
              "shoppinCartText": "There are no documents in the shopping cart. Subtotal:",
              "zeroEuro": "€0.00",
              "checkoutButton": "To checkout"
            }
          },
          "settings": {
            "breadCrumbsTitle": "Settings",
            "companyBranch": {
              "sectionElementTitle": "Business sectors",
              "gridElementTitle": "Business sectors",
              "button": "Create company subsidiary",
              "table": {
                "logo": "Logo",
                "name": "Name",
                "legalName": "Legal name",
                "color": "Color"
              },
              "deleteButton": "Delete company subsidiary"
            },
            "TicketEscalationSteps": {
              "sectionElementTitle": "Ticket escalation steps",
              "gridElementTitle": "Ticket escalation steps"
            },
            "workflowPhase": {
              "sectionElementTitle": "Workflow phases",
              "gridElementTitle": "Workflow phases",
              "button": "Create workflow phase",
              "table": {
                "name": "Name",
                "color": "Color"
              },
              "deleteButton": "Delete phase"
            },
            "letterHead": {
              "sectionElementTitle": "Letterheads",
              "gridElementTitle": "Letterheads",
              "button": "Create a letterhead",
              "table": {
                "name": "Name",
                "marginLeft": "Distance",
                "marginRight": "Distance",
                "marginTop": "Distance",
                "marginBottom": "Distance",
                "bothPagesSame": "Both sides the same"
              },
              "deleteButton": "Delete letterhead",
              "yes": "Yes",
              "no": "No"
            },
            "budgetCategory": {
              "sectionElementTitle": "Budget categories",
              "gridElementTitle": "Budget categories",
              "button": "Create budget category",
              "table": {
                "name": "Name",
                "color": "Color"
              },
              "deleteButton": "Delete budget category"
            },
            "branchTypes": {
              "sectionElementTitle": "Types of establishment",
              "gridElementTitle": "Types of establishment",
              "button": "Create subsidiary type",
              "table": {
                "name": "Name",
                "color": "Color"
              },
              "deleteButton": "Delete subsidiary type"
            },
            "file": {
              "sectionElementTitle": "File structure templates",
              "gridElementTitle": "File structure templates",
              "button": "Create file structure template",
              "name": "Name",
              "fileStructureTemplateBranch": "Apply file structure template to subsidiaries",
              "fileStructureTemplateDeleteButton": "Delete file structure template"
            },
            "factSheets": {
              "sectionElementTitle": "Fact sheets"
            },
            "factSheetsFolder": {
              "DeleteFactSheetModal": {
                "title": "Delete fact sheet",
                "description": "Are you sure you want to delete this fact sheet? ",
                "deleteButton": "Delete fact sheet"
              },
              "DeleteFactSheetElementModal": {
                "title": "Delete fact sheet",
                "description": "Are you sure you want to delete this fact sheet? ",
                "deleteButton": "Delete fact sheet"
              },
              "CreateFactSheetModal": {
                "title": "Create fact sheet",
                "name": "Name",
                "exitButton": "Create fact sheet"
              },
              "UpdateFactSheetModal": {
                "title": "Update fact sheet",
                "name": "Name",
                "exitButton": "Update fact sheet"
              },
              "inputElementButton": "New input element",
              "updateButton": "Update fact sheet",
              "deleteButton": "Delete fact sheet",
              "fieldName": "Field label",
              "createFactSheetButton": "Create fact sheet"
            },
            "trade": {
              "sectionElementTitle": "Work sections",
              "gridElementTitle": "Work sections",
              "tradeImportButton": "Import work section from CFDS",
              "tradeCreateButton": "Create custom work section",
              "table": {
                "modus": "Mode",
                "stlbId": "StLB ID / No.",
                "name": "Name",
                "objectClasses": "Object classes",
                "standardEquipment": "Standard TGA"
              },
              "deleteButton": "Delete work section",
              "cfds": "CFDS",
              "userDefined": "Custom",
              "yes": "Yes",
              "no": "No",
              "objectClasses": "Object classes"
            },
            "Standarddocumente": {
              "sectionElementTitle": "Standard documents",
              "gridElementTitle": "Standard documents",
              "button": "Upload standard document",
              "table": {
                "name": "Name",
                "fileName": "Filename",
                "type": "Type",
                "size": "Size",
                "createdAt": "Created on"
              },
              "deleteButton": "Delete default document"
            },
            "Exports": {
              "sectionElementTitle": "Exports"
            },
            "ExportsFolder": {
              "notAvailable": "Not available",
              "exportList": "Export list",
              "exportFeatures": "Export properties",
              "exportDownloadButton": "Download export",
              "noExportDescription": "No export selected",
              "configurationDescription": "Select an export on the left to use and configure it.",
              "noConfigurationDescription": "No configuration available",
              "exportButtonDescription": "This export is not configurable.",
              "branch": "Subsidiary",
              "date": "Date"
            },
            "modals": {
              "applyFileStructureTemplateModal": {
                "title": "Apply file structure template",
                "description1": "Are you sure you want to apply this file structure template?",
                "description2": "Folders are created in each subsidiary if they do not exist.",
                "description3": "This promotion cannot be revoked!",
                "exitButton": "Apply file structure template"
              },
              "createBranchModal": {
                "title": "Create company subsidiary",
                "name": "Name",
                "legalName": "Legal name",
                "exitButton": "Create company subsidiary"
              },
              "createBudgetCategoryModal": {
                "title": "Create budget category",
                "name": "Name",
                "exitButton": "Create budget category"
              },
              "createFileStructureTemplateModal": {
                "title": "Create file structure template",
                "name": "Name",
                "exitButton": "Create file structure template"
              },
              "createLetterHeadModal": {
                "title": "Create a letterhead",
                "name": "Name",
                "arrowLeft": "Distance left in mm",
                "arrowRight": "Distance to the right in mm",
                "arrowTop": "Distance at top in mm",
                "arrowDown": "Distance below in mm",
                "htmlDescription": "Your HTML code for the dynamic module on the first page.",
                "bothSideEqual": "Both sides the same",
                "sideOneHead": "1st page header",
                "sideOneHeadHtml": "Your HTML code for the first page header.",
                "sideOneFoot": "1. Page foot",
                "sideOneFootHtml": "Your HTML code for the footer of the first page.",
                "sideTwoHead": "2nd page header",
                "sideTwoHeadHtml": "Your HTML code for the header of the second page.",
                "sideTwoFoot": "2nd page foot",
                "sideTwoFootHtml": "Your HTML code for the footer of the second page.",
                "exitButton": "Create a letterhead"
              },
              "createPhaseModal": {
                "title": "Create phase",
                "name": "Name",
                "exitButton": "Create phase"
              },
              "createStandardFileModal": {
                "title": "Upload standard document",
                "name": "Name",
                "uploadFile": "Place your standard document here.",
                "exitButton": "Create standard document"
              },
              "createSubsidiaryTypeModal": {
                "title": "Create subsidiary type",
                "name": "Name",
                "exitButton": "Create subsidiary type"
              },
              "createWorkSectionModal": {
                "title": "Create work section",
                "name": "Name",
                "number": "StLB / number",
                "standardBuildingEquipment": "Standard building equipment",
                "exitButton": "Create work section"
              },
              "deleteBranchModal": {
                "title": "Delete company subsidiary",
                "description": "Are you sure you want to delete this subsidiary? ",
                "deleteButton": "Delete company subsidiary"
              },
              "deleteBudgetCategoryModal": {
                "title": "Delete budget category",
                "description": "Are you sure you want to delete this budget category? ",
                "deleteButton": "Delete budget category"
              },
              "deleteFileStructureTemplateModal": {
                "title": "Delete file structure template",
                "description": "Are you sure you want to delete this file structure template? ",
                "deleteButton": "Delete file structure template"
              },
              "deleteLetterModal": {
                "title": "Delete letterhead",
                "description": "Are you sure you want to delete this letterhead? ",
                "deleteButton": "Delete letterhead"
              },
              "deletePhaseModal": {
                "title": "Delete phase",
                "description": "Are you sure you want to delete this stage? ",
                "deleteButton": "Delete phase"
              },
              "deleteStandardFileModal": {
                "title": "Delete default document",
                "description": "Are you sure you want to delete this default document? ",
                "deleteButton": "Delete default document"
              },
              "deleteSubsidiaryTypeModal": {
                "title": "Delete subsidiary type",
                "description": "Are you sure you want to delete this subsidiary type? ",
                "deleteButton": "Delete subsidiary type"
              },
              "deleteWorkSectionModal": {
                "title": "Delete work section",
                "description": "Are you sure you want to delete this work section? ",
                "deleteButton": "Delete work section"
              },
              "importWorksectionModal": {
                "title": "Import Work sections",
                "tradeChooseLabel": "Select work section",
                "standardBuildingEquipment": "Standard building equipment",
                "exitButton": "Import Work sections"
              },
              "updateBranchModal": {
                "title": "Update company subsidiary",
                "name": "Name",
                "legalName": "Legal name"
              },
              "updateBudgetCategoryModal": {
                "title": "Update budget category",
                "name": "Name"
              },
              "updateFileStructureTemplateModal": {
                "title": "Update file structure template",
                "name": "Name",
                "fileStructureTemplate": "File structure template"
              },
              "updateLetterHeadModal": {
                "title": "Update letterhead",
                "name": "Name",
                "arrowLeft": "Distance left in mm",
                "arrowRight": "Distance to the right in mm",
                "arrowTop": "Distance at top in mm",
                "arrowDown": "Distance below in mm",
                "htmlDescription": "Your HTML code for the dynamic module on the first page.",
                "bothSideEqual": "Both sides the same",
                "sideOneHead": "1st page header",
                "sideOneHeadHtml": "Your HTML code for the first page header.",
                "sideOneFoot": "1. Page foot",
                "sideOneFootHtml": "Your HTML code for the footer of the first page.",
                "sideTwoHead": "2nd page header",
                "sideTwoHeadHtml": "Your HTML code for the header of the second page.",
                "sideTwoFoot": "2nd page foot",
                "sideTwoFootHtml": "Your HTML code for the footer of the second page.",
                "poHeadText": "PO header text",
                "headTextHtml": "Your HTML code for the PO header text.",
                "poFootText": "PO footer text",
                "footTextHtml": "Your HTML code for the footer text of the PO."
              },
              "updatePhaseModal": {
                "title": "Update phase",
                "name": "Name"
              },
              "updateSubsidiaryTypeModal": {
                "title": "Update subsidiary type",
                "name": "Name"
              },
              "updateWorkSectionCFDSModal": {
                "title": "Update work section",
                "standardBuildingEquipment": "Standard building equipment",
                "updateButton": "Update work section"
              },
              "updateWorkSectionModal": {
                "title": "Update work section",
                "name": "Name",
                "number": "StLB / number",
                "standardBuildingEquipment": "Standard building equipment",
                "exitButton": "Update work section"
              }
            }
          }
        },
        "public": {
          "login": {
            "welcomeTo": "Welcome to",
            "choice": {
              "login": "Register",
              "registration": "to register"
            },
            "blur": {
              "error": {
                "title": "Failed",
                "error": {
                  "CREDENTIALS_INCORRECT": "The login details provided are incorrect.",
                  "USER_PENDING_APPROVAL": "This user requires activation by an administrator.",
                  "USER_EMAIL_NOT_VERIFIED": "Please confirm your email address first.",
                  "USER_INACTIVE": "This user is inactive.",
                  "UNKNOWN_EMAIL": "This email address is unknown.",
                  "MISSING_FIELDS": "Please check your information for empty values.",
                  "LAST_PASSWORD_RESET_RATE_LIMIT_EXCEEDED": "Please wait before performing this action again.",
                  "PASSWORDS_DO_NOT_MATCH": "The passwords do not match.",
                  "PASSWORD_STRENGTH_INSUFFICIENT": "The password strength is insufficient. ",
                  "INVALID_PARAMETERS": "The information is invalid. ",
                  "INVALID_EMAIL_ADDRESS": "The email address is invalid.",
                  "INVALID_EMAIL_POLICY": "Your email address does not comply with company policy.",
                  "INVALID_FIRST_NAME": "The first name is invalid.",
                  "INVALID_LAST_NAME": "The last name is invalid.",
                  "INVALID_SALUTATION": "The salutation is invalid.",
                  "USER_INVITED": "Since you have been invited to scanmetrix, you must first reset your password before you can log in.",
                  "EMAIL_ALREADY_EXISTS": "This E-mail address already exists."
                }
              },
              "email-verification": {
                "title": "User account verified",
                "text": "You have successfully verified your user account. "
              },
              "password-reset-verification": {
                "title": "Password changed",
                "text": "Your password was changed successfully. "
              },
              "password-reset-challenge": {
                "title": "Resetting takes place",
                "text": "Please check your email inbox now to finally reset your password."
              },
              "registration-successful": {
                "title": "registration successful",
                "text": "Please now check your email inbox to finally verify your user account."
              },
              "u2f-verification": {
                "title": "U2F authentication required",
                "text": "Use your U2F compatible device to continue signing in."
              }
            },
            "mode": {
              "passwordResetVerification": {
                "subtitle": "Choose a password to complete the process and set your credentials."
              },
              "login": {
                "forgotPassword": "Forgot Password?",
                "subtitle": "Register with your company to use scanmetrix."
              },
              "registration": {
                "checkbox": "I have read the data protection declaration and accept it.",
                "subtitle": "Register with your business email address to use scanmetrix."
              },
              "passwordResetChallenge": {
                "back": "Back to Login",
                "subtitle": "Enter your work email address to receive a link to recover your password."
              }
            },
            "footer": {
              "imprint": "imprint",
              "privacy": "data protection",
              "aboutUs": "About Us",
              "more": "Learn more",
              "termsOfUse": "Terms of Use",
              "termsOfService": "Conditions"
            }
          }
        }
      }
    }
  }
}
