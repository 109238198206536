import React, { Component } from "react"
import { Input, Grid, GridElement, Button, Select, Checkbox } from "scanmetrix-components"
import { withRouter } from "react-router-dom"

/*
    Import styled components
*/
import BackgroundContainer from "./styled/BackgroundContainer"
import BlurContainer from "./styled/BlurContainer"
import BlurContent from "./styled/BlurContent"
import ButtonRow from "./styled/ButtonRow"
import Choice from "./styled/Choice"
import LoginContainer from "./styled/LoginContainer"
import LoginLeftContainer from "./styled/LoginLeftContainer"
import LoginRightContainer from "./styled/LoginRightContainer"
import Logo from "./styled/Logo"
import Watermark from "./styled/Watermark"
import SessionContainer from "./styled/SessionContainer"
import Cookies from "universal-cookie"

import i18next from "i18next"

/*
    Imports methods
*/
import loginMethod from "./methods/login"
import registerMethod from "./methods/register"
import passwordResetListenerMethod from "./methods/passwordResetListener"
import passwordResetChallengeMethod from "./methods/passwordResetChallenge"
import passwordResetVerificationMethod from "./methods/passwordResetVerification"
import emailVerificationMethod from "./methods/emailVerification"
import Avatar from "react-avatar"

//import u2fApi from "u2f-api"

export default withRouter(class extends Component {
    /*
        Setup default state
    */
    state = {disableSSO: false, passwordVisible: false ,remember: true, salutation: null, passwordRepeat: null, firstName: null, lastName: null, email: null, password: null, mode: "login", blurMode: null, error: null, accepted: false, loading: false }
    
    constructor(props) {
       
        super(props)

        /*
            Provide methods with this context
        */
        this.login = loginMethod.bind(this)
        this.register = registerMethod.bind(this)
        this.emailVerification = emailVerificationMethod.bind(this)
        this.passwordResetChallenge = passwordResetChallengeMethod.bind(this)
        this.passwordResetVerification = passwordResetVerificationMethod.bind(this)
        this.passwordResetListener = passwordResetListenerMethod.bind(this)


        /*
            Remember username
        */

        this.state.email = new Cookies().get("rememberUsername") || null

       
        
    }
    

    /*
        Check for email verification & password reset
    */
    componentDidMount() {
        this.emailVerification()
        this.passwordResetListener()
    }

 



    render() {
        return <BackgroundContainer>
            <LoginContainer>
                <LoginLeftContainer>
                    <div>
                        <img src="/logo_white.svg" />
                        <h1>{i18next.t("defaults.slogan")}</h1>
                    </div>
                    {/*<Watermark src={`${scanmetrix.backendURL}/logo`} />*/}
                    <div className="footer">
                        <ul>
                            <li><a target="_blank" href="https://scanmetrix.fm/imprint">{i18next.t("page.public.login.footer.imprint")}</a></li>
                            <li><a target="_blank" href="https://scanmetrix.fm/privacy-policy">{i18next.t("page.public.login.footer.privacy")}</a></li>
                            <li><a target="_blank" href="https://betreiber.scanmetrix.io">{i18next.t("page.public.login.footer.more")}</a></li>
                            <li><a target="_blank" href="https://scanmetrix.fm/about">{i18next.t("page.public.login.footer.aboutUs")}</a></li>
                        </ul>
                        <div className="version">scanmetrix® Cockpit {scanmetrix.version}</div>
                    </div>
                </LoginLeftContainer>
                <LoginRightContainer style={{ pointerEvents: this.state.loading ? "none" : "all" }}>
                    <BlurContent show={this.state.blurMode === "u2f-verification"} color="#3B97D3">
                        <i className="fa-duotone fa-fingerprint" />
                        <h2>{i18next.t("page.public.login.blur.u2f-verification.title")}</h2>
                        <h3>{i18next.t("page.public.login.blur.u2f-verification.text")}</h3>
                        <p className="cancel" onClick={() => {
                            this.setState({ blurMode: null })
                        }}>{i18next.t("defaults.button.cancel")}</p>
                    </BlurContent>
                    <BlurContent show={this.state.blurMode === "error"} color="#41474e">
                        <i className="fas fa-exclamation" />
                        <h2>{i18next.t("page.public.login.blur.error.title")}</h2>
                        <h3>{i18next.t(`page.public.login.blur.error.error.${this.state.error}`)}</h3>
                        <p className="cancel" onClick={() => {
                            this.setState({ blurMode: null })
                        }}>{i18next.t("defaults.button.close")}</p>
                    </BlurContent>
                    <BlurContent show={this.state.blurMode === "registration-successful"} color="#3B97D3">
                        <i className="fa-duotone fa-envelope" />
                        <h2>{i18next.t("page.public.login.blur.registration-successful.title")}</h2>
                        <h3>{i18next.t("page.public.login.blur.registration-successful.text")}</h3>
                        <p className="cancel" onClick={() => {
                            this.setState({ blurMode: null, mode: "login" })
                        }}>{i18next.t("defaults.button.close")}</p>
                    </BlurContent>
                    <BlurContent show={this.state.blurMode === "password-reset-challenge"} color="#3B97D3">
                        <i className="fa-duotone fa-envelope" />
                        <h2>{i18next.t("page.public.login.blur.password-reset-challenge.title")}</h2>
                        <h3>{i18next.t("page.public.login.blur.password-reset-challenge.text")}</h3>
                        <p className="cancel" onClick={() => {
                            this.setState({ blurMode: null, mode: "login" })
                        }}>{i18next.t("defaults.button.close")}</p>
                    </BlurContent>
                    <BlurContent show={this.state.blurMode === "password-reset-verification"} color="#3B97D3">
                        <i className="fa-duotone fa-check" />
                        <h2>{i18next.t("page.public.login.blur.password-reset-verification.title")}</h2>
                        <h3>{i18next.t("page.public.login.blur.password-reset-verification.text")}</h3>
                        <p className="cancel" onClick={() => {
                            this.setState({ blurMode: null, mode: "login" })
                        }}>{i18next.t("defaults.button.close")}</p>
                    </BlurContent>
                    <BlurContent show={this.state.blurMode === "email-verification"} color="#3B97D3">
                        <i className="fa-duotone fa-check" />
                        <h2>{i18next.t("page.public.login.blur.email-verification.title")}</h2>
                        <h3>{i18next.t("page.public.login.blur.email-verification.text")}</h3>
                        <p className="cancel" onClick={() => {
                            this.setState({ blurMode: null, mode: "login" })
                        }}>{i18next.t("defaults.button.close")}</p>
                    </BlurContent>
                    <BlurContainer blur={this.state.blurMode !== null} disable={this.state.loading || this.state.blurMode !== null}>
                        <Logo src={`${scanmetrix.backendURL}/logo`} />
                        {scanmetrix.ssoType === "azure-saml" && !this.state.disableSSO && <>
                            <h1>{i18next.t("page.public.login.welcomeTo")} <b>scanmetrix Cockpit</b></h1>
                            <h2>Melden Sie sich in Ihrem Unternehmen mit Microsoft SSO an, um scanmetrix zu nutzen.</h2>
                            <a className="microsoft">
                                <img src="/login_with_microsoft.svg" onClick={() => window.open(`${scanmetrix.backendURL}/sso/azure/saml/request`)} />
                            </a>
                            <a style={{ color: "#3b97d3", textAlign: "center", cursor: "pointer", width: "100%", display: "block" }} onClick={() => this.setState({ disableSSO: true })}><i style={{ marginRight: 8 }} className="fa-duotone fa-sign-in" />Anmeldung ohne SSO</a>
                        </>}
                        {scanmetrix.session && (scanmetrix.ssoType === "none" || this.state.disableSSO) && !(this.props.location.state && this.props.location.state.logout) && <SessionContainer>
                            <h1>Willkommen zurück, {i18next.t(`defaults.salutation.${scanmetrix.session.salutation}`)} {scanmetrix.session.lastName}.</h1>
                            <h2>Fahren Sie fort oder melden Sie sich ab.</h2>
                            <Avatar size="100" email={scanmetrix.disableGravatar ? null : scanmetrix.session.email} name={`${scanmetrix.session.firstName} ${scanmetrix.session.lastName}`} className="avatar" />
                            <div className="buttons">
                                <Button thick icon="sign-in" title="Fortfahren" onClick={() => {
                                    this.props.history.push("/dashboard")
                                }} />
                                <Button thick icon="sign-out" title="Abmelden" secondary onClick={() => {
                                    fetch(scanmetrix.backendURL + "/user/logout", {
                                        method: "POST",
                                        credentials: "include",
                                        headers: {
                                            "Content-Type": "application/json"
                                        }
                                    }).then(result => result.json()).then(() => {
                                        scanmetrix.session = null
                                        location.reload()
                                    })
                                }} />
                            </div>
                        </SessionContainer>}
                        {!scanmetrix.session && (scanmetrix.ssoType === "none" || this.state.disableSSO) && <>
                            <h1>{i18next.t("page.public.login.welcomeTo")} <b>scanmetrix Cockpit</b></h1>
                            <h2>{i18next.t(`page.public.login.mode.${this.state.mode}.subtitle`)}</h2>
                            {this.state.mode !== "passwordResetChallenge" && this.state.mode !== "passwordResetVerification" && <Choice mode={this.state.mode}>
                                <p onClick={() => this.setState({ mode: "login" })}><i className="fa-duotone fa-sign-in" />{i18next.t("page.public.login.choice.login")}</p>
                                <p onClick={() => this.setState({ mode: "registration" })}><i className="fa-duotone fa-paper-plane" />{i18next.t("page.public.login.choice.registration")}</p>
                            </Choice>}
                            <Grid gap="32px">
                                {this.state.mode === "registration" && <>
                                    <GridElement>
                                        <Select adjustWidth value={this.state.salutation} onChange={salutation => this.setState({ salutation })} icon="venus-mars" label={i18next.t("defaults.input.salutation")} items={[
                                            { key: "male", title: i18next.t("defaults.salutation.male"), icon: "mars" },
                                            { key: "female", title: i18next.t("defaults.salutation.female"), icon: "venus" },
                                            { key: "maleDr", title: i18next.t("defaults.salutation.maleDr"), icon: "mars" },
                                            { key: "femaleDr", title: i18next.t("defaults.salutation.femaleDr"), icon: "venus" },
                                            { key: "maleProfDr", title: i18next.t("defaults.salutation.maleProfDr"), icon: "mars" },
                                            { key: "femaleProfDr", title: i18next.t("defaults.salutation.femaleProfDr"), icon: "venus" },
                                            { key: "maleProfDrMed", title: i18next.t("defaults.salutation.maleProfDrMed"), icon: "mars" },
                                            { key: "femaleProfDrMed", title: i18next.t("defaults.salutation.femaleProfDrMed"), icon: "venus" },
                                            { key: "maleDrMed", title: i18next.t("defaults.salutation.maleDrMed"), icon: "mars" },
                                            { key: "femaleDrMed", title: i18next.t("defaults.salutation.femaleDrMed"), icon: "venus" },
                                            { key: "maleDiplIng", title: i18next.t("defaults.salutation.maleDiplIng"), icon: "mars" },
                                            { key: "femaleDiplIng", title: i18next.t("defaults.salutation.femaleDiplIng"), icon: "venus" },
                                            { key: "maleDiplKfm", title: i18next.t("defaults.salutation.maleDiplKfm"), icon: "mars" },
                                            { key: "femaleDiplKfr", title: i18next.t("defaults.salutation.femaleDiplKfr"), icon: "venus" }
                                        ]} />
                                    </GridElement>
                                    <GridElement>
                                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                            <Input value={this.state.firstName} onChange={firstName => this.setState({ firstName })} adjustWidth label={i18next.t("defaults.input.firstName")} icon="user" margin="0 24px 0 0" />
                                            <Input value={this.state.lastName} onChange={lastName => this.setState({ lastName })} adjustWidth label={i18next.t("defaults.input.lastName")} icon="user" margin="0 0 0 24px" />
                                        </div>
                                    </GridElement>
                                </>}
                                {this.state.mode !== "passwordResetVerification" && <GridElement>
                                    <Input icon="envelope" value={this.state.email} onEnter={() => {
                                        if(this.state.mode === "passwordResetChallenge" && this.state.email) this.passwordResetChallenge()// Anmeldung-Mail
                                    }} onChange={email => this.setState({ email })} adjustWidth label={i18next.t("defaults.input.email")} />
                                </GridElement>}
                                {this.state.mode !== "passwordResetChallenge" && <GridElement>
                                    <div style={{ width: "100%", display: "flex", flexDirection:"row", lineHeight: "34px", height:"60px"}}>

                                       <Input //Anmeldung-Passwort
                                        style={{height:"100%"}}
                                        margin={this.state.mode === "registration" ? "0 24px 0 0" : 0}
                                        value={this.state.password}
                                        onEnter={() => {
                                            if(this.state.mode === "login" && this.state.email && this.state.password) this.login()
                                            if(this.state.mode === "passwordResetVerification" && this.state.passwordRepeat && this.state.password) this.passwordResetVerification()
                                        }}
                                        onChange={password => this.setState({ password })}
                                        adjustWidth label={i18next.t("defaults.input.password")}
                                        icon
                                        type={"password"}
                                        id="passwort">
                                            
                                    </Input>
                                        

                                        {(this.state.mode === "registration" || this.state.mode === "passwordResetVerification") && <Input onEnter={() => {
                                            if(this.state.mode === "passwordResetVerification" && this.state.passwordRepeat && this.state.password) this.passwordResetVerification()
                                        }} margin="0 0 0 24px" value={this.state.passwordRepeat} onChange={passwordRepeat => this.setState({ passwordRepeat })} adjustWidth label={i18next.t("defaults.input.passwordRepeat")} type="password" icon="key" />}
                                    </div>
                                </GridElement>}
                                <GridElement>
                                    <ButtonRow>
                                        {this.state.mode === "login" && <div>
                                            <Checkbox label="Benutzername merken" value={this.state.remember} onChange={remember => this.setState({ remember })} />
                                        </div>}
                                        {(this.state.mode === "passwordResetChallenge" || this.state.mode === "passwordResetVerification") && <a style={{ marginTop: 0, marginBottom: 0, textAlign: "left" }} className="passwordReset" onClick={() => this.setState({ mode: "login" })}>{i18next.t("page.public.login.mode.passwordResetChallenge.back")}</a>}
                                        {this.state.mode === "registration" && <Checkbox label={i18next.t("page.public.login.mode.registration.checkbox")} value={this.state.accepted} onChange={accepted => this.setState({ accepted })} />}

                                        <div style={{ display: "flex", justifyContent: "flex-end", width: this.state.mode === "passwordReset" ? "100%" : "auto" }}>
                                            {/*this.state.mode === "login" && <Button onClick={() => {
                                            fetch(scanmetrix.backendURL+ "/user/u2f-registration-challenge", {
                                                method: "POST",
                                                credentials: "include"
                                            }).then(result => result.json()).then(registrationRequest => {
                                                u2fApi.register(registrationRequest).then(registrationResponse => {
                                                    fetch(scanmetrix.backendURL + "/user/u2f-registration-verification", {
                                                        method: "POST",
                                                        credentials: "include",
                                                        body: JSON.stringify({
                                                            registrationRequest,
                                                            registrationResponse
                                                        }),
                                                        headers: {
                                                            "Content-Type": "application/json"
                                                        }
                                                    }).then(result => result.json()).then(result => {
                                                        console.log(result)
                                                    })
                                                }).catch(error => {
                                                    console.error(error)
                                                })
                                            })
                                        }} thick title="U2F" icon="sign-in" />*/}
                                            {this.state.mode === "passwordResetChallenge" && <Button onClick={this.passwordResetChallenge} style={{ float: "right" }} disabled={!this.state.email} loading={this.state.loading} thick title={i18next.t("defaults.button.send")} icon="envelope" />}
                                            {this.state.mode === "passwordResetVerification" && <Button onClick={this.passwordResetVerification} style={{ float: "right", position: "relative" }} disabled={!this.state.password || !this.state.passwordRepeat} loading={this.state.loading} thick title={i18next.t("defaults.button.reset")} icon="key" />}
                                            {this.state.mode === "login" && <Button onClick={this.login} disabled={!this.state.email || !this.state.password} loading={this.state.loading} thick title={i18next.t("defaults.button.login")} icon="sign-in" />} 
                                            {this.state.mode === "registration" && <Button onClick={this.register} disabled={!this.state.password || !this.state.passwordRepeat || !this.state.salutation || !this.state.firstName || !this.state.lastName || !this.state.email || !this.state.accepted} style={{ marginLeft: 16 , }} loading={this.state.loading} thick title={i18next.t("defaults.button.send")} icon="paper-plane" />}
                                        </div>
                                    </ButtonRow>
                                    {this.state.mode === "login" && <a className="passwordReset" onClick={() => this.setState({ mode: "passwordResetChallenge" })}>{i18next.t("page.public.login.mode.login.forgotPassword")}</a>}
                                </GridElement>
                            </Grid>
                        </>}
                    </BlurContainer>
                </LoginRightContainer>
            </LoginContainer>
        </BackgroundContainer>
    }
})
