import styled from "styled-components"

export default styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  background: rgba(92, 140, 202, 0.1);
  padding: 48px;
  border-radius: 16px;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
    
  img {
    height: 80%;
    -webkit-user-drag: none;
    max-height: 140px;
    max-width: 170px;
    background: white;
    padding: 0 24px;
    object-fit: contain;
      border-radius: 10px;
  }
  
  h1 {
    color: #3b97d3;
    width: 80%;
    font-weight: 400;
  }
  
  h2 {
    font-size: 1.2em;
    line-height: 1.4em;
    font-weight: 300;
    width: 70%;
    margin-top: 16px;
    
    b {
      color: #3b97d3;
    }
  }
  
  @media screen and (max-width: 2100px) {
    zoom: 0.9;
  }
  
  @media screen and (max-width: 1920px) {
    zoom: 0.75;
  }
  
  @media screen and (max-width: 1919px) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
    
    img {
      height: 100%;
    }
  }
`
