import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"
import { v4 as uuid } from "uuid"
import i18next from "i18next";

export default class extends Component {
    state = { loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ loading: false })

            this.file = null
        }}>
            <Modal.Head title={i18next.t("page.private.workFlow.createEndNodeModal.title")} icon="project-diagram" />
            <Modal.Body padding={24}>

            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.workFlow.createEndNodeModal.exitButton")} icon="project-diagram" onClick={() => {
                this.props.addNode({
                    id: uuid(),
                    data: { },
                    type: "end",
                    position: { x: 0, y: 0 }
                })

                instance.close()
            }} />} />
        </Modal>
    }
}
