import React, { Component } from "react"
import { Modal, Input } from "scanmetrix-components"
import { CirclePicker } from "react-color"
import styled from "styled-components"
import i18next from "i18next";

const CircleDesign = styled.div`
    transform: translateX(7px);
`

export default class extends Component {
    state = { id: null, budgetCategory: null, color: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={budgetCategory => this.setState({ id: budgetCategory.id, budgetCategory, color: budgetCategory.rawColor, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.updateBudgetCategoryModal.title")} icon="piggy-bank" />
            <Modal.Body padding={24}>
                <div style={{ padding: "16px", border: `3px dashed ${this.state.color || "#cccccc"}`, display: "grid", gridGap: "32px", gridTemplateColumns: "1fr", width: "380px" }}>
                    <Input.MutationProvider value={{
                        name: "updateBudgetCategory",
                        id: this.state.id,
                        data: this.state.budgetCategory,
                        onSave: () => this.props.refresh()
                    }}>
                        <Input field="name" required icon="tag" label={i18next.t("page.private.settings.modals.updateBudgetCategoryModal.name")} adjustWidth />
                        <CircleDesign>
                            <CirclePicker onChange={color => {
                                this.setState({ color: color.hex })

                                scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                        mutation($id: ID!, $color: String!) {
                                            updateBudgetCategory(id: $id, color: $color)
                                        }
                                    `,
                                    variables: {
                                        id: this.state.id,
                                        color: color.hex
                                    }
                                }).then(data => {
                                    if(data.data.updateBudgetCategory) this.props.refresh()
                                })
                            }} width="100%" />
                        </CircleDesign>
                    </Input.MutationProvider>
                </div>
            </Modal.Body>
        </Modal>
    }
}
