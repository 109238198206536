import styled from "styled-components"

export default styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 5;
  grid-row-end: 6;
  
  @media screen and (max-width: 1919px) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 8;
    grid-row-end: 9;
  }
`
