import React, { Component } from "react"
import { Modal, Button, Input } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { accountingInvoice: null, notes: null, acceptLoading: false, declineLoading: false }

    render() {
        const accountingInvoice = this.state.accountingInvoice

        return <Modal minWidth="650px" instance={this.props.instance} initialize={accountingInvoice => this.setState({ accountingInvoice, notesAccounting: accountingInvoice.notesAccounting, acceptLoading: false, declineLoading: false })}>
            <Modal.Head title={i18next.t("page.private.accounting.fiClearanceModal.title")} icon="piggy-bank" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: 32 }}>
                    {accountingInvoice && <div>
                        <Modal.Divider title={i18next.t("page.private.accounting.fiClearanceModal.document")} />
                        <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "500px" }}><i style={{ width: 24 }} className="far fa-file-invoice" />{i18next.t("page.private.accounting.fiClearanceModal.invoiceDocument")} <a style={{ textDecoration: "none", fontWeight: "bold", color: "#3b97d3" }} href={scanmetrix.backendURL + "/accounting-invoice-document/" + accountingInvoice.accountingInvoiceDocument.id} target="_blank">{accountingInvoice.accountingInvoiceDocument.name}</a></div>
                        {accountingInvoice.purchaseOrder && <div style={{ marginTop: 12, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "500px" }}><i style={{ width: 24 }} className="far fa-wrench" />{i18next.t("page.private.accounting.fiClearanceModal.orderDocument")} <a style={{ textDecoration: "none", fontWeight: "bold", color: "#3b97d3" }} href={scanmetrix.backendURL + "/purchaseorder/pdf/" + accountingInvoice.purchaseOrder.id} target="_blank">{accountingInvoice.purchaseOrder.number}{i18next.t("page.private.accounting.fiClearanceModal.pdf")}</a></div>}
                    </div>}
                    <Input label={i18next.t("page.private.accounting.fiClearanceModal.auditor")} readOnly icon="eye" textArea adjustWidth value={accountingInvoice ? (accountingInvoice.notes || "Keine Angabe") : null} />
                    <Input label={i18next.t("page.private.accounting.fiClearanceModal.bookingNote")} placeholder={i18next.t("page.private.accounting.fiClearanceModal.invoiceForm")} icon="sticky-note" textArea adjustWidth value={this.state.notesAccounting} onChange={notesAccounting => this.setState({ notesAccounting })} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
                <Button loading={this.state.acceptLoading} disabled={this.state.declineLoading} thick title={i18next.t("page.private.accounting.fiClearanceModal.invoiceMarkPosted")} icon="check-double" onClick={() => {
                    this.setState({ acceptLoading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($id: ID!, $notesAccounting: String) {
                                bookAccountingInvoice(id: $id, notesAccounting: $notesAccounting)
                            }
                        `,
                        variables: {
                            id: accountingInvoice.id,
                            notesAccounting: this.state.notesAccounting
                        }
                    }).then(data => {
                        if(data.data.bookAccountingInvoice) {
                            return this.props.fetch(true).then(() => instance.close())
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                }} />
                <Button loading={this.state.declineLoading} disabled={this.state.acceptLoading} secondary thick title={i18next.t("page.private.accounting.fiClearanceModal.exitButton")} icon="times" onClick={() => {
                    this.setState({ declineLoading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($id: ID!, $notesAccounting: String) {
                                appealAccountingInvoice(id: $id, notesAccounting: $notesAccounting)
                            }
                        `,
                        variables: {
                            id: accountingInvoice.id,
                            notesAccounting: this.state.notesAccounting
                        }
                    }).then(data => {
                        if(data.data.appealAccountingInvoice) {
                            return this.props.fetch(false).then(() => instance.close())
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                }} />
            </div>} />
        </Modal>
    }
}
