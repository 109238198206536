import React, { Component } from 'react'
import { Modal, Input, Grid, GridElement, Checkbox, Select } from 'scanmetrix-components'
import i18next from "i18next";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = { provisionIntervalVariation: null }

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={provisionIntervalVariation => {
            this.setState({ provisionIntervalVariation })
        }}>
            <Modal.Head title={i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.title")} icon="calendar-lines-pen" />
            <Modal.Body padding={24}>
                {this.state.provisionIntervalVariation && <Input.MutationProvider value={{
                    name: "updateContractProvisionIntervalVariation",
                    id: this.state.provisionIntervalVariation.id,
                    data: this.state.provisionIntervalVariation,
                    onSave: () => this.props.refresh()
                }}>
                    <Grid gap="16px" columns={[ "1fr", "1fr" ]}>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input int field="intervalAmount" icon="tally" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.intervalNumber")} adjustWidth disabled={scanmetrix.checkPermission("ContractProvisionIntervalVariation") < 3} />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Select field="intervalType" icon="calendar-week" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.intervalUnit")} adjustWidth readOnly={scanmetrix.checkPermission("ContractProvisionIntervalVariation") < 3} items={[
                                { key: "days", title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.intervalUnitTypes.days") },
                                { key: "weeks", title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.intervalUnitTypes.weeks") },
                                { key: "months", title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.intervalUnitTypes.months") },
                                { key: "years", title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.intervalUnitTypes.years") },
                            ]} />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input optional float icon="piggy-bank" field="cost" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.netCosts")} disabled={scanmetrix.checkPermission("ContractProvisionIntervalVariation") < 3} adjustWidth format={val => val !== null ? formatter.format(val) : val} />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input date field="startingAt" icon="calendar-day" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.startsOn")} adjustWidth disabled={scanmetrix.checkPermission("ContractProvisionIntervalVariation") < 3} />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={3}>
                            <Checkbox field="inactive" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalVariationModal.inactiveCheckbox")} disabled={scanmetrix.checkPermission("ContractProvisionIntervalVariation") < 3} />
                        </GridElement>
                    </Grid>
                </Input.MutationProvider>}
            </Modal.Body>
        </Modal>
    }
}
