import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    ServiceProviders {
                        nodes {
                            id
                            name
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                    LetterHeads {
                        nodes {
                            id
                            name
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ serviceProviders: data.data.ServiceProviders.nodes, subsidiaries: data.data.Subsidiaries.nodes, letterHeads: data.data.LetterHeads.nodes })
        })
    }

    state = {
        id: null,
        title: null,
        serviceProviderId: null,
        subsidiaryId: null,
        type: null,
        number: null,
        date: null,
        reference: null,
        serviceProviders: [],
        subsidiaries: [],
        letterHeads: [],
        letterHeadId: null,
        loading: false
    }

    render() {
        const purchaseOrder = this.props.purchaseOrder

        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({
            id: purchaseOrder.id,
            title: purchaseOrder.title,
            letterHeadId: purchaseOrder.letterHead?.id || null,
            serviceProviderId: purchaseOrder.serviceProvider.id,
            subsidiaryId: purchaseOrder.subsidiary.id,
            type: purchaseOrder.type,
            reference: purchaseOrder.reference,
            number: purchaseOrder.number,
            date: moment(purchaseOrder.date).format("DD.MM.YYYY"),
            loading: false
        })}>
            <Modal.Head title={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.titleForm")} icon="comment-alt-check" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Select noUnselect required adjustWidth value={this.state.serviceProviderId} onChange={serviceProviderId => this.setState({ serviceProviderId })} icon="user-tie" label={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.serviceProvider")} items={this.state.serviceProviders.map(serviceProvider => ({
                        key: serviceProvider.id,
                        title: (serviceProvider.address.postalCode && serviceProvider.address.city) ? `${serviceProvider.name} / ${serviceProvider.address.postalCode} ${serviceProvider.address.city}` : serviceProvider.name
                    }))} />
                    <Select adjustWidth value={this.state.letterHeadId} onChange={letterHeadId => this.setState({ letterHeadId })} icon="scroll" label={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.letterHead")} items={this.state.letterHeads.map(letterHead => ({
                        key: letterHead.id,
                        title: letterHead.name
                    }))} />
                    <Select noUnselect required adjustWidth value={this.state.type} onChange={type => this.setState({ type })} icon="wrench" label={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.type")} items={[
                        { key: "service", title: i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.typeTypes.service"), icon: "hard-hat" },
                        { key: "construction", title: i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.typeTypes.construction"), icon: "digging" },
                        { key: "goods", title: i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.typeTypes.goods"), icon: "truck-loading" },
                        { key: "other", title: i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.typeTypes.other"), icon: "question" },
                    ]} />
                    <Select noUnselect required adjustWidth value={this.state.subsidiaryId} onChange={subsidiaryId => this.setState({ subsidiaryId })} icon="map-marker" label={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.branch")} items={this.state.subsidiaries.map(subsidiary => ({
                        key: subsidiary.id,
                        title: `${subsidiary.name} (${subsidiary.address.postalCode} ${subsidiary.address.city})`
                    }))} />
                    <Input required label={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.number")} icon="comment-alt-check" value={this.state.number} onChange={number => this.setState({ number })} />
                    <Input required date label={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.date")} icon="calendar-day" value={this.state.date} onChange={date => this.setState({ date })} />
                    <Input required label={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.title")} icon="font" value={this.state.title} onChange={title => this.setState({ title })} />
                    <Input required label={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.offer")} icon="shopping-cart" value={this.state.reference} onChange={reference => this.setState({ reference })} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.title || !this.state.number || !this.state.serviceProviderId || !this.state.subsidiaryId || !this.state.date || !this.state.type} title={i18next.t("page.private.purchaseOrder.updatePurchaseOrderModal.button")} icon="comment-alt-check" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $title: String!, $serviceProviderId: ID!, $subsidiaryId: ID!, $type: String!, $number: String!, $date: DateTime!, $reference: String, $letterHeadId: ID) {
                            updatePurchaseOrder(id: $id, title: $title, serviceProviderId: $serviceProviderId, subsidiaryId: $subsidiaryId, type: $type, number: $number, date: $date, reference: $reference, letterHeadId: $letterHeadId)
                        }
                    `,
                    variables: {
                        id: this.state.id,
                        title: this.state.title,
                        serviceProviderId: this.state.serviceProviderId,
                        letterHeadId: this.state.letterHeadId,
                        subsidiaryId: this.state.subsidiaryId,
                        type: this.state.type,
                        reference: this.state.reference,
                        number: this.state.number,
                        date: moment(this.state.date, "DD.MM.YYYY").toDate()
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.updatePurchaseOrder) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
