import styled from "styled-components"

export default styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 6;
  border-radius: 16px;
  flex-grow: 0;
  height: 400px;
  display: flex;
  flex-direction: column;
  
  .chartContainer {
    background: linear-gradient(45deg, #2c3e50, #45607c);
    box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.15);
    padding: 32px;
    border-radius: 0 16px 16px 16px;
    height: 100%;
  }
  
  @media screen and (max-width: 1919px) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 6;
    grid-row-end: 7;
  }
`
