import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { salutation: null, firstName: null, lastName: null, email: null, telephone: null, responsibility: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={contact => this.setState({ id: contact.id, salutation: contact.salutation, firstName: contact.firstName, lastName: contact.lastName, email: contact.email, telephone: contact.telephone, responsibility: contact.responsibility, loading: false })}>
            <Modal.Head title={i18next.t("page.private.serviceProvider.updateContactModal.title")} icon="address-book" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                        <Select required adjustWidth value={this.state.salutation} onChange={salutation => this.setState({ salutation })} icon="venus-mars" label={i18next.t("defaults.input.salutation")} items={[
                            { key: "male", title: i18next.t("defaults.salutation.male"), icon: "mars" },
                            { key: "female", title: i18next.t("defaults.salutation.female"), icon: "venus" },
                            { key: "maleDr", title: i18next.t("defaults.salutation.maleDr"), icon: "mars" },
                            { key: "femaleDr", title: i18next.t("defaults.salutation.femaleDr"), icon: "venus" },
                            { key: "maleProfDr", title: i18next.t("defaults.salutation.maleProfDr"), icon: "mars" },
                            { key: "femaleProfDr", title: i18next.t("defaults.salutation.femaleProfDr"), icon: "venus" },
                            { key: "maleProfDrMed", title: i18next.t("defaults.salutation.maleProfDrMed"), icon: "mars" },
                            { key: "femaleProfDrMed", title: i18next.t("defaults.salutation.femaleProfDrMed"), icon: "venus" },
                            { key: "maleDrMed", title: i18next.t("defaults.salutation.maleDrMed"), icon: "mars" },
                            { key: "femaleDrMed", title: i18next.t("defaults.salutation.femaleDrMed"), icon: "venus" },
                            { key: "maleDiplIng", title: i18next.t("defaults.salutation.maleDiplIng"), icon: "mars" },
                            { key: "femaleDiplIng", title: i18next.t("defaults.salutation.femaleDiplIng"), icon: "venus" },
                            { key: "maleDiplKfm", title: i18next.t("defaults.salutation.maleDiplKfm"), icon: "mars" },
                            { key: "femaleDiplKfr", title: i18next.t("defaults.salutation.femaleDiplKfr"), icon: "venus" }
                        ]} />
                        <Input value={this.state.firstName} onChange={firstName => this.setState({ firstName })} icon="tag" label={i18next.t("page.private.serviceProvider.updateContactModal.firstName")} adjustWidth />
                        <Input required value={this.state.lastName} onChange={lastName => this.setState({ lastName })} icon="tag" label={i18next.t("page.private.serviceProvider.updateContactModal.lastName")} adjustWidth />
                    </div>
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <Input value={this.state.email} onChange={email => this.setState({ email })} icon="at" label={i18next.t("page.private.serviceProvider.updateContactModal.email")} adjustWidth />
                        <Input value={this.state.telephone} onChange={telephone => this.setState({ telephone })} icon="telephone" label={i18next.t("page.private.serviceProvider.updateContactModal.telephoneNumber")} adjustWidth />
                    </div>
                    <Input value={this.state.responsibility} onChange={responsibility => this.setState({ responsibility })} icon="hard-hat" label={i18next.t("page.private.serviceProvider.updateContactModal.responsibility")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => [
                <Button loading={this.state.loading} thick disabled={!this.state.lastName || !this.state.salutation} title={i18next.t("page.private.serviceProvider.updateContactModal.exitButton")} icon="address-book" onClick={() => {
                    this.setState({ loading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($salutation: String!, $firstName: String, $lastName: String!, $responsibility: String, $email: String, $telephone: String, $id: ID!) {
                                updateServiceProviderContact(salutation: $salutation, firstName: $firstName, lastName: $lastName, email: $email, telephone: $telephone, responsibility: $responsibility, id: $id)
                            }
                        `,
                        variables: {
                            salutation: this.state.salutation,
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            responsibility: this.state.responsibility,
                            email: this.state.email,
                            telephone: this.state.telephone,
                            id: this.state.id
                        }
                    }).then(result => {
                        this.setState({ loading: false })

                        if(result.data.updateServiceProviderContact) {
                            this.props.refresh()
                            instance.close()
                        }
                    })}
                } />,
                <Button loading={this.state.loading} disabled={scanmetrix.checkPermission("ServiceProviderContact") < 4} thick title="Ansprechpartner löschen" icon="trash" secondary onClick={() => {
                    this.setState({ loading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            deleteServiceProviderContact(id: $id)
                        }
                    `,
                        variables: {
                            id: this.state.id
                        }
                    }).then(result => {
                        this.setState({ loading: false })

                        if(result.data.deleteServiceProviderContact) {
                            this.props.refresh()
                            instance.close()
                        }
                    })}
                } />
            ]} />
        </Modal>
    }
}
