import styled from "styled-components"

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.05);
  margin-left: 48px;
  margin-bottom: 16px;
  padding: 16px 0;
  padding-right: 48px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 16px 0 0 16px;
  position: relative;
  
  @media screen and (max-width: 1920px) {
      margin-left: 32px;
      padding-right: 32px;
      
      .unit {
        display: none;
      }
      
      .attention {
        left: -26px !important;
        height: 20px !important;
        width: 20px !important;
        font-size: 0.7em !important;
      }
   }
  
  .attention {
    height: 28px;
    width: 28px;
    left: -38px;
    top: calc(50% - 14px);
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: white;
    border-radius: 100%;
    box-shadow: 0 0 4px 0 #e74c3c;
    color: #e74c3c;
    border: 2px solid #e74c3c;
    position: absolute;
    
    &.orange {
      color: #e67e22;
      box-shadow: 0 0 4px 0 #e67e22;
      border: 2px solid #e67e22;
    }
  }
  
  svg {
    height: 64px;
    width: 64px;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .label {
    width: 30%;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 1em;
    line-height: 1.3em;
  }
  
  .unit {
    width: 20%;
    flex-shrink: 0;
    flex-grow: 0;
    opacity: 0.75; 
  }
  
  @media screen and (max-width: 1919px) {
    flex-direction: column;
    
    .label {
      margin-bottom: 16px;
      text-align: center;
      width: 100%;
    }
  }
`
