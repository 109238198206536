import React, { Component } from 'react'
import { Page, Breadcrumbs, Section } from 'scanmetrix-components'

import TabCarDriver      from "./tabs/CarDriver"
import TabCarFlaw        from "./tabs/CarFlaw"
import TabCarLogbook     from "./tabs/CarLogbook"
import TabCarInsurance   from "./tabs/CarInsurance"
import TabCarTire        from "./tabs/CarTire"
import TabCarMaintenance from "./tabs/CarMaintenance"
import TabGeneral        from "./tabs/General"

export default class extends Component {
    state = {
        car: null,
        subsidiaries: []
    }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                inline
                            }
                        }
                    }
                    Car(id: $id) {
                        id
                        name
                        chassisNumber
                        description
                        manufacturer
                        model
                        buildYear
                        fuelType
                        type
                        transmissionType
                        licensePlate1
                        licensePlate2
                        licensePlate3
                        nextInspection
                        capacity
                        combinedUsage
                        emptyWeight
                        permissibleMaximumWeight
                        usableWeight
                        tensibleLoadWeight
                        tensibleTowingWeight
                        firstRegistration
                        thumbnailBase64
                        photos {
                            id
                        }
                        tires {
                            id
                            size
                            type
                            seasonType
                            location
                            amount
                        }
                        subsidiary {
                            id
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.carid
            }
        }).then(result => {
            this.setState({
                car: result.data.Car,
                subsidiaries: result.data.Subsidiaries.nodes
            })
        })
    }

    render() {
        const car = this.state.car
        const subsidiaries = this.state.subsidiaries

        if(!car) return null

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "car",
                    title: "Fuhrpark",
                    link: "/cars"
                },
                {
                    icon: "car-garage",
                    title: car.name
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title="Allgemeine Informationen" icon="id-card">
                    <TabGeneral history={this.props.history} car={car} subsidiaries={subsidiaries} />
                </Section.Item>
                <Section.Item title="Fahrer" icon="steering-wheel">
                    <TabCarDriver car={car} />
                </Section.Item>
                {/*<Section.Item title="Wartungen" icon="tools">
                    <TabCarMaintenance car={car} />
                </Section.Item>*/}
                <Section.Item title="Mängel" icon="bolt">
                    <TabCarFlaw car={car} />
                </Section.Item>
                <Section.Item title="Versicherungen" icon="car-crash">
                    <TabCarInsurance car={car} />
                </Section.Item>
                <Section.Item title="Fahrtenbuch" icon="book">
                    <TabCarLogbook car={car} />
                </Section.Item>
                <Section.Item title="Bereifung" icon="tire">
                    <TabCarTire car={car} />
                </Section.Item>
            </Section>
        </Page>
    }
}
