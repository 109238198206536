import React, { Component } from 'react'
import { Button, Modal } from 'scanmetrix-components'
import QRCode from 'qrcode.react'
import styled from "styled-components";
import i18next from "i18next";

const Row = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  user-select: none;
  
  .right {
    max-width: 768px;
    padding: 0 32px 0 64px;
    box-sizing: border-box;
    
    h1 {
      margin-bottom: 16px;
      color: #20242B;
    }
    
    .comment {
      margin-top: 32px;
      opacity: 0.5;
    }
  }
`

export default class extends Component {
    render() {
        return <Modal maxWidth="768px" instance={this.props.instance}>
            <Modal.Head title={i18next.t("page.private.profile.ORCodeModal.title")} icon="phone-laptop" />
            <Modal.Body padding={24}>
                <Row>
                    <div className='left'>
                        <QRCode fgColor="#353b48" renderAs="svg" size={256} value={`sx_cockpit,${scanmetrix.frontendURL},${scanmetrix.session.email}`} />
                    </div>

                    <div className='right'>
                        <h1>{i18next.t("page.private.profile.ORCodeModal.text1")}</h1>
                        <p>{i18next.t("page.private.profile.ORCodeModal.text2")}</p>

                        <p className='comment'>{i18next.t("page.private.profile.ORCodeModal.text3")}</p>
                    </div>
                </Row>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button thick title={i18next.t("page.private.profile.ORCodeModal.exitButton")} icon="check" onClick={() => {
                instance.close()
            }} />} />
        </Modal>
    }
}