import React, { Component } from "react"
import styled from "styled-components"
import Grid from "../Grid/"

const ModalContext = React.createContext("modal")

const ModalParentContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 25;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContainer = styled.div`
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.4);
  display: inline-block;
  background: white;
  min-width: ${props => props.minWidth};
  border-radius: 5px;
  overflow: hidden;
  max-width: 1280px;
  
  @media screen and (max-width: 1280px) {
    width: ${props => props.responsiveWidth};
    zoom: 0.8;
  }
`

const StyledHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0 0;
  user-select: none;
  
  &:last-child {
    border-bottom: none;
  }
  
  .title {
    font-size: 1.1em;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    .far {
      margin-right: 12px;
    }
  }
  
  .close {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.3s;
    
    &:hover {
      opacity: 1;
    }
  }
`

const StyledBody = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  padding: ${props => props.padding ? `${props.padding}px` : 0};
  overflow-x: hidden;
`

const StyledFooter = styled.div`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 0 5px 5px;
  
  &:first-child {
    border-top: none;
  }
`

const Divider = styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0;
    margin-top: ${props => props.marginTop || "12px"};
    
    p {
        color: #20242b;
        white-space: nowrap;
        user-select: none;
        font-size: 0.9em;
    }
    
    .line {
        width: 100%;
        margin-left: 16px;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
    }
`

const StyledInfo = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    border: 2px solid #3b97d3;
    margin-top: 16px;
    line-height: 1.4em;
    max-width: ${props => props.maxWidth || "100%"};
    
    .title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-weight: bold;
        color: #3b97d3;
        
        i {
            width: 24px;
        }
    }
`

const StyledError = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    border: 2px solid #c0392b;
    margin-top: 16px;
    line-height: 1.4em;
  max-width: ${props => props.maxWidth || "100%"};
    
    .title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-weight: bold;
        color: #c0392b;
        
        i {
            width: 24px;
        }
    }
`

class ModalDivider extends Component {
    render() {
        return <Divider {...this.props}>
            <p>{this.props.title}</p>
            <div className="line" />
        </Divider>
    }
}

class ModalHead extends Component {
    render() {
        return <ModalContext.Consumer>
            {value => <StyledHead>
                <p className="title">{this.props.icon && <i className={`far fa-${this.props.icon}`} />}{this.props.title}</p>
                <i className="far fa-times close" onClick={() => value.close()} />
            </StyledHead>}
        </ModalContext.Consumer>
    }
}

class ModalBody extends Component {
    render() {
        return <StyledBody style={ this.props.adjustHeight ? { minHeight: this.props.adjustHeight } : (this.props.adjustWidth ? { minWidth: this.props.adjustWidth } : {}) } padding={this.props.padding}>{this.props.children}</StyledBody>
    }
}

class ModalFooter extends Component {
    render() {
        return <ModalContext.Consumer>
            {instance => {
                const buttons = this.props.buttons(instance)

                return <StyledFooter>
                    <Grid gap="8px" columns={new Array(buttons.length).fill("max-content")}>
                        {buttons}
                    </Grid>
                </StyledFooter>
            }}
        </ModalContext.Consumer>
    }
}

class ModalError extends Component {
    render() {
        return <StyledError {...this.props}>
            <div className="title">
                <i className="fas fa-exclamation-circle" />
                {this.props.title || "Es ist ein unbekannter Fehler aufgetreten."}
            </div>
            {this.props.children}
        </StyledError>
    }
}

class ModalInfo extends Component {
    render() {
        return <StyledInfo {...this.props}>
            <div className="title">
                <i className="fas fa-info-circle" />
                {this.props.title}
            </div>
            {this.props.children}
        </StyledInfo>
    }
}

class Modal extends Component {
    state = { open: false, context: null }

    constructor(props) {
        super(props)

        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
    }

    open(context) {
        this.setState({ open: true, context })

        if(this.props.initialize) this.props.initialize(context)
    }

    close() {
        this.setState({ open: false })
    }

    componentDidMount() {
        if(this.props.instance) this.props.instance(this)
    }

    render() {
        if(!this.state.open) return null

        return <ModalParentContainer>
            <ModalContext.Provider value={{ close: this.close }}>
                <ModalContainer responsiveWidth={this.props.responsiveWidth} minWidth={this.props.minWidth || "512px"}>
                    {typeof this.props.children === "function" ? this.props.children(this.state.context) : this.props.children}
                </ModalContainer>
            </ModalContext.Provider>
        </ModalParentContainer>
    }
}

Modal.Head    = ModalHead
Modal.Body    = ModalBody
Modal.Footer  = ModalFooter
Modal.Divider = ModalDivider
Modal.Error   = ModalError
Modal.Info    = ModalInfo

export default Modal
