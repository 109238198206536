import React, { Component } from "react"
import { Page, Select } from "scanmetrix-components"
import styled from "styled-components"
import * as JsSearch from "js-search"
import BarLoader from "react-spinners/BarLoader"
import OutsideClickHandler from 'react-outside-click-handler'
import Map, { Layer, Popup, Source } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import ReactApexChart from "react-apexcharts";
import i18next from "i18next"

const StyledPopup = styled.div`
  background: white;
  color: #3f618c;
  font-weight: 400;
  padding: 5px;
  border-radius: 7px;
  position: relative;

  .close {
      right: 0;
      top: 0;
      position: absolute;
      font-size: 16px;
      cursor: pointer;

      &:hover {
          opacity: 0.75;
      }
  }

  .button {
      background: #3b97d3;
      color: white;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      border-radius: 4px;
      user-select: none;
      cursor: pointer;
      margin-top: 8px;
      transition: opacity 0.3s;
      width: 100%;
      box-sizing: border-box;
      font-size: 1.1em;

      &.red {
          background: #e74c3c;
      }

        &.dark {
        background: #20242b;
        }

      &:hover {
          opacity: 0.75;
      }

      i {
          width: 20px;
      }
  }

  img {
      height: 48px;
  }

  .facilities {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      overflow-y: auto;
      max-height: 250px;

      .facility {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          margin-bottom: 4px;

          &:last-child {
              margin-bottom: 0;
          }
      }
  }
`

/*
const MapInstance = ReactMapboxGl({
    accessToken: "pk.eyJ1Ijoic2Nhbm1ldHJpeCIsImEiOiJja20zNXFrcmUzMHJ5MnBtd25nb2RmNDhwIn0.MiGqkL0HH3Ku8vEF1YtMbQ",
    minZoom: 4,
    maxZoom: 15,
    trackResize: true,
    attributionControl: false
})*/

const MapContainer = styled.div`
    height: 100%;
    position: relative;

    #subsidiaries {
        height: 100%;
    }
`

const Search = styled.input`
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  padding: 0 32px;
  border-radius: 24px;
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.4);
  border: 2px solid transparent;
  outline: 0;
  font-size: 1.1em;

  &.focused {
    box-shadow: none;
    border-radius: 24px 24px 0 0;
  }

  &::placeholder {
    font-size: 0.9em;
  }
`

const SearchContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 30%;
  max-width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  top: 48px;
  display: flex;
  flex-direction: column;
`

const SearchDropdown = styled.div`
  width: 100%;
  max-height: 512px;
  height: auto;
  background: white;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 8px 10px -6px rgba(0, 0, 0, 0.4);
  overflow-y: scroll;
  padding: 16px;
  box-sizing: border-box;

  @media screen and (max-width: 1280px) {
    zoom: 0.8;
  }

  .subsidiary {
    width: 100%;
    user-select: none;
    cursor: pointer;
    transition: opacity 0.3s;
    display: flex;
    padding: 8px 0;

    &:first-child {
      margin-top: 0;
    }

    img {
      width: 48px;
      height: 48px;
      margin-right: 16px;
      object-fit: contain;
    }

    .branch {
      font-weight: bold;
      display: flex;
        align-items: center;

      p {
        margin-right: 12px;
        display: flex;
        align-items: center;
      font-size: 1.1em;

        &:after {
          display: block;
          margin-left: 12px;
          content: "⬤";
          font-size: 0.4em;
          color: gray;
        }
      }
    }

    &:hover {
      opacity: 0.75;
    }

    .info {
      display: flex;
      width: 100%;
      height: 48px;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`

const inlineAddress = parent => (parent.additional ? parent.additional + ", " : "") + parent.streetName + " " + parent.streetNumber + ", " + parent.postalCode + " " + parent.city


class SearchHandler extends Component {
    state = { focused: false, search: "" }

    render() {
        const search = new JsSearch.Search("id")

        search.addIndex("name")
        search.addIndex("label")
        search.addIndex(["branch", "name"])
        search.addIndex(["address", "inline"])
        search.addIndex(["address", "postalCode"])
        search.addIndex(["address", "city"])

        search.addDocuments(this.props.subsidiaries)

        return <OutsideClickHandler onOutsideClick={() => this.setState({ focused: false, search: "" })}>
            <SearchContainer>
                <Search autoComplete="new-password"
                        autoCorrect="false"
                        autoCapitalize="false"
                        spellCheck="false" value={this.state.search} onChange={event => this.setState({ search: event.target.value })} placeholder={i18next.t("page.private.map.searchPlaceholder")} className={this.state.focused ? "focused" : ""} onFocus={() => this.setState({ focused: true })} />
                {this.state.focused && <SearchDropdown>
                    {(this.state.search.trim() !== "" ? search.search(this.state.search) : this.props.subsidiaries).map((subsidiary, index) => <div onClick={() => {
                        this.setState({ focused: false, search: "" })
                        this.props.callback(subsidiary.id)
                    }} className="subsidiary" key={index}>
                        <img src={scanmetrix.backendURL + "/logo/" + subsidiary.branch.id} />
                        <div className="info">
                            <div className="branch">
                                <p style={{ color: subsidiary.branch.color }}>{subsidiary.branch.name}</p>
                                {subsidiary.name} ({subsidiary.label})
                            </div>
                            <p className="address">{subsidiary.address.inline}</p>
                        </div>
                    </div>)}
                </SearchDropdown>}
            </SearchContainer>
        </OutsideClickHandler>
    }
}

const LoaderMask = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    font-weight: 300;
    margin-bottom: 24px;
  }
`

const StyledPerspectiveChanger = styled.div`
    padding: 16px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    .title {
        grid-column-start: 1;
        grid-column-end: 3;
        font-size: 18px;
    }

    .perspective {
        display: flex;
        flex-direction: column;
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        cursor: pointer;
        user-select: none;
        border-radius: 4px;
        transition: border-color 0.3s;

        i {
            font-size: 24px;
            margin-bottom: 8px;
            transition: color 0.3s;
        }

        &.selected {
            border-color: #3b97d3;

            i {
                color: #3b97d3;
            }
        }
    }
`

class PerspectiveChanger extends Component {
    render() {
        return <StyledPerspectiveChanger>
            <p className="title">{i18next.t("page.private.map.Perspective.title")}</p>
            <div onClick={() => this.props.onChange("facilityManagement")} className={`perspective ${this.props.perspective === "facilityManagement" ? "selected" : ""}`}>
                <i className="far fa-building" />
                <p className="name">{i18next.t("page.private.map.Perspective.PerspectiveChanger")}</p>
            </div>
            <div onClick={() => this.props.onChange("workflows")} className={`perspective ${this.props.perspective === "workflows" ? "selected" : ""}`}>
                <i className="far fa-project-diagram" />
                <p className="name">{i18next.t("page.private.map.Perspective.workFlows")}</p>
            </div>
            <div onClick={() => this.props.onChange("subsidiaryTypes")} className={`perspective ${this.props.perspective === "subsidiaryTypes" ? "selected" : ""}`}>
                <i className="far fa-map-marker-edit" />
                <p className="name">{i18next.t("page.private.map.Perspective.establishmentTypes")}</p>
            </div>
            <div onClick={() => this.props.onChange("tenants")} className={`perspective ${this.props.perspective === "tenants" ? "selected" : ""}`}>
                <i className="far fa-home" />
                <p className="name">{i18next.t("page.private.map.Perspective.subtenant")}</p>
            </div>
            <div onClick={() => this.props.onChange("status")} className={`perspective ${this.props.perspective === "status" ? "selected" : ""}`}>
                <i className="far fa-comment-alt-edit" />
                <p className="name">{i18next.t("page.private.map.Perspective.status")}</p>
            </div>
        </StyledPerspectiveChanger>
    }
}

const StyledWorkflowSidebar = styled.div`
    background: white;
    z-index: 500000;
    border-radius: 5px;
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding: 16px;
    position: absolute;
    right: 48px;
    top: 48px;
    width: 25%;
    min-width: 400px;

    .apexcharts-canvas {
        margin: 0 auto;
    }
`

class PieChart extends Component {
    state = {
        options: {
            chart: {
                width: 600,
                type: 'donut'
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: '75%'
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: {
                show: false
            }
        }
    }

    render() {
        return <ReactApexChart options={{ ...this.state.options, colors: this.props.series.map(serie => serie.color), labels: this.props.series.map(serie => serie.name) }} series={this.props.series.map(serie => serie.value)} type='donut' width={350} />
    }
}

const Filters = styled.div`
    overflow-y: scroll;
    max-height: 50vh;
`

const Filter = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
    line-height: 24px;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.75;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .label {
        user-select: none;
    }

    .checkbox {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        margin-right: 16px;
        border: 1px solid rgba(0, 0, 0, 0.25);

        i {
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.3s, visibility 0.3s;
        }


      &.checked {
        i {
          visibility: visible;
          opacity: 1;
        }
      }
    }
`

class WorkflowSidebar extends Component {
    state = { filters: {} }

    getFiltered(workflowId) {
        return this.props.subsidiaries.map(sub => sub.phases.filter(phase => phase.workflow.id === workflowId)).flat().filter((v,i,a)=>a.findIndex(t=>(t.phase.id === v.phase.id))===i).map(data => {
            const phase = this.props.phases.find(phase => phase.id === data.phase.id)

            return ({
                phaseId: phase.id,
                name: phase.name,
                color: phase.color,
                value: this.props.subsidiaries.filter(sub => sub.phases.filter(phase => phase.workflow.id === workflowId)).filter(sub => sub.phases.length > 0).filter(sub => sub.phases[0].phase.id === phase.id).length
            })
        })
    }

    render() {
        const filtered = this.getFiltered(this.props.workflowId)

        const noPhase = {
            phaseId: "none",
            name: i18next.t("page.private.map.pieChartValues.noPhase"),
            color: "#95a5a6",
            value: this.props.subsidiaries.filter(sub => sub.phases.filter(phase => phase.workflow.id === this.props.workflowId).length === 0).length
        }

        return <StyledWorkflowSidebar>
            <div style={{ display: "grid", gridTemplateColumns: "100%", gridGap: "16px" }}>
                <Select
                    label={i18next.t("page.private.map.workflowChoose")}
                    icon="project-diagram"
                    adjustWidth
                    value={this.props.workflowId}
                    onChange={workflowId => {
                        if(!workflowId) {
                            this.props.onWorkflowSelect(null)
                            this.setState({ filters: {} })
                            this.props.onFilter(null)
                        } else {
                            this.props.onWorkflowSelect(workflowId)

                            let filters = {}

                            filters["none"] = true
                            this.getFiltered(workflowId).forEach(filter => filters[filter.phaseId] = true)

                            this.setState({ filters }, () => this.props.onFilter(filters))
                        }
                    }}
                    items={this.props.workflows.map(workflow => ({ key: workflow.id, title: workflow.name }))}
                />
                {this.props.workflowId && <>
                    <PieChart series={[
                        noPhase,
                        ...filtered
                    ]} />
                    <Filters>
                        {([noPhase, ...filtered]).map((filter, index) => <Filter key={index} onClick={() => {
                            let filters = this.state.filters

                            filters[filter.phaseId] = !this.state.filters[filter.phaseId]

                            this.setState({ filters }, () => this.props.onFilter(filters))
                        }}>
                            <div className={`checkbox ${!!this.state.filters[filter.phaseId] ? "checked" : ""}`}><i className="far fa-check" /></div>
                            <div className="label" style={{ color: filter.color }}>({filter.value}) {filter.name}</div>
                        </Filter>)}
                    </Filters>
                </>}
            </div>
        </StyledWorkflowSidebar>
    }
}


class SubsidiaryTypesSidebar extends Component {
    state = { filters: { none: true } }

    componentDidMount() {
        let filters = { none: true }

        const filtered = this.getFiltered()

        filtered.forEach(filter => filters[filter.subsidiaryTypeId] = true)

        this.setState({ filters })
    }

    getFiltered() {
        return this.props.subsidiaries.filter(sub => !!sub.subsidiaryType).filter((v,i,a)=>a.findIndex(t=>(t.subsidiaryType.id === v.subsidiaryType.id))===i).map(data => {
            const subsidiaryType = this.props.subsidiaryTypes.find(subsidiaryType => subsidiaryType.id === data.subsidiaryType.id)

            return ({
                subsidiaryTypeId: subsidiaryType.id,
                name: subsidiaryType.name,
                color: subsidiaryType.color,
                value: this.props.subsidiaries.filter(sub => sub.subsidiaryType && sub.subsidiaryType.id === subsidiaryType.id).length
            })
        })
    }

    render() {
        const filtered = this.getFiltered()

        const noPhase = {
            subsidiaryTypeId: "none",
            name: i18next.t("page.private.map.pieChartValues.noEstablishment"),
            color: "#95a5a6",
            value: this.props.subsidiaries.filter(sub => !sub.subsidiaryType).length
        }

        return <StyledWorkflowSidebar>
            <PieChart series={[
                noPhase,
                ...filtered
            ]} />
            <Filters>
                {([noPhase, ...filtered]).map((filter, index) => <Filter key={index} onClick={() => {
                    let filters = this.state.filters

                    filters[filter.subsidiaryTypeId] = !this.state.filters[filter.subsidiaryTypeId]

                    this.setState({ filters }, () => this.props.onFilter(filters))
                }}>
                    <div className={`checkbox ${!!this.state.filters[filter.subsidiaryTypeId] ? "checked" : ""}`}><i className="far fa-check" /></div>
                    <div className="label" style={{ color: filter.color }}>({filter.value}) {filter.name}</div>
                </Filter>)}
            </Filters>
        </StyledWorkflowSidebar>
    }
}

class StatusSidebar extends Component {
    state = { filters: { none: true } }

    componentDidMount() {
        let filters = { none: true }

        const filtered = this.getFiltered()

        filtered.forEach(filter => filters[filter.phaseId] = true)

        this.setState({ filters })
    }

    getFiltered() {
        return this.props.subsidiaries.filter(sub => !!sub.phase).filter((v,i,a)=>a.findIndex(t=>(t.phase.id === v.phase.id))===i).map(data => {
            const phase = this.props.phases.find(phase => phase.id === data.phase.id)

            return ({
                phaseId: phase.id,
                name: phase.name,
                color: phase.color,
                value: this.props.subsidiaries.filter(sub => sub.phase && sub.phase.id === phase.id).length
            })
        })
    }

    render() {
        const filtered = this.getFiltered()

        const noPhase = {
            phaseId: "none",
            name: i18next.t("page.private.map.pieChartValues.noStatus"),
            color: "#95a5a6",
            value: this.props.subsidiaries.filter(sub => !sub.phase).length
        }

        return <StyledWorkflowSidebar>
            <PieChart series={[
                noPhase,
                ...filtered
            ]} />
            <Filters>
                {([noPhase, ...filtered]).map((filter, index) => <Filter key={index} onClick={() => {
                    let filters = this.state.filters

                    filters[filter.phaseId] = !this.state.filters[filter.phaseId]

                    this.setState({ filters }, () => this.props.onFilter(filters))
                }}>
                    <div className={`checkbox ${!!this.state.filters[filter.phaseId] ? "checked" : ""}`}><i className="far fa-check" /></div>
                    <div className="label" style={{ color: filter.color }}>({filter.value}) {filter.name}</div>
                </Filter>)}
            </Filters>
        </StyledWorkflowSidebar>
    }
}


class FacilityManagementSidebar extends Component {
    state = { filters: {} }

    componentDidMount() {
        let filters = {}

        const filtered = this.getFiltered()

        filtered.forEach(filter => filters[filter.statusId] = true)

        this.setState({ filters })
    }

    getFiltered() {
        return this.props.subsidiaries.filter((v,i,a)=>a.findIndex(t=>(this.props.evaluate(t) === this.props.evaluate(v)))===i).map(data => {
            const status = this.props.evaluate(data)

            return ({
                statusId: status,
                name: status === "red" ? i18next.t("page.private.map.pieChartValues.urgentActionNeeded") : (status === "orange" ? i18next.t("page.private.map.pieChartValues.mediumActionNeeded") : (status === "green" ? i18next.t("page.private.map.pieChartValues.noActionNeeded") : i18next.t("page.private.map.pieChartValues.noDataAvailabe"))),
                color: status === "red" ? "#e74c3c" : (status === "green" ? "#1abc9c" : (status === "orange" ? "#e67e22" : "#34495e")),
                value: this.props.subsidiaries.filter(sub => this.props.evaluate(sub) === status).length
            })
        })
    }

    render() {
        const filtered = this.getFiltered()

        return <StyledWorkflowSidebar>
            <PieChart series={filtered} />
            <Filters>
                {filtered.map((filter, index) => <Filter key={index} onClick={() => {
                    let filters = this.state.filters

                    filters[filter.statusId] = !this.state.filters[filter.statusId]

                    this.setState({ filters }, () => this.props.onFilter(filters))
                }}>
                    <div className={`checkbox ${!!this.state.filters[filter.statusId] ? "checked" : ""}`}><i className="far fa-check" /></div>
                    <div className="label" style={{ color: filter.color }}>({filter.value}) {filter.name}</div>
                </Filter>)}
            </Filters>
        </StyledWorkflowSidebar>
    }
}


const TopLeft = styled.div`
    position: absolute;
    left: 48px;
    top: 48px;
    z-index: 2;
`

const Results = styled.div`
    padding: 16px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
    margin-top: 16px;
    user-select: none;
    font-size: 14px;

    b {
        color: #3b97d3;
    }
`

const ToggleView = styled.div`
    position: absolute;
    bottom: 48px;
    left: 48px;
  z-index: 2;
  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  user-select: none;
  padding: 16px;
    transition: opacity 0.3s;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }
`

class MapClass extends Component {
    state = { showElements: true, subsidiaries: null, statuses: null, subsidiary: null, perspective: "facilityManagement", phases: [], workflows: [], selectedWorkflowId: null, filters: null, center: [ 10.0317, 51.1620 ], zoom: 6 }

    evaluate = subsidiary => {
        /*
        let icon = "green"

        return icon

        const customerObjects = subsidiary.facilities.map(facility => facility.customerObjects).flat()

        if(customerObjects.length === 0) icon = "gray"

        customerObjects.forEach(customerObject => {
            if(customerObject.operative === false) icon = "orange"
        })

        let totalCrash = 0

        customerObjects.forEach(customerObject => {
            let hasFlaw = false

            customerObject.maintenances.forEach(maintenance => {
                maintenance.flaws.forEach(flaw => {
                    if(!flaw.fixedInMaintenance && customerObject.operative === false && !customerObject.discarded) hasFlaw = true
                })
            })

            if(hasFlaw) totalCrash++
        })

        if(totalCrash / customerObjects.length >= 0.1) icon = "red"

        return icon*/

        if(this.state.statuses) {
            const status = this.state.statuses.find(status => String(status.subsidiaryId) === String(subsidiary.id))

            if(status) {
                return status.status
            }
        }

        return "gray"
    }

    mapRef = React.createRef()

    constructor(props) {
        super(props)

        this.evaluate = this.evaluate.bind(this)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Phases {
                        nodes {
                            id
                            name
                            color
                        }
                    }
                    Workflows {
                        nodes {
                            id
                            name
                        }
                    }
                    SubsidiaryTypes {
                        nodes {
                            id
                            color
                            name
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            legalName
                            label
                            tenants {
                                id
                            }
                            branch {
                                id
                                name
                                color
                            }
                            address {
                                streetName
                                streetNumber
                                city
                                postalCode
                                inline
                            }
                            locationLat
                            locationLng
                            subsidiaryType {
                                id
                                color
                            }
                            phase {
                                id
                                color
                            }
                            phases {
                                id
                                workflow {
                                    id
                                }
                                phase {
                                    id
                                }
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ subsidiaries: data.data.Subsidiaries.nodes, workflows: data.data.Workflows.nodes, phases: data.data.Phases.nodes, subsidiaryTypes: data.data.SubsidiaryTypes.nodes })
        })

        fetch(scanmetrix.backendURL + "/subsidiary-statuses", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ statuses: data.statuses })
        })
    }

    render() {
        const subsidiary = this.state.subsidiary
        const filterForWorkflows = subsidiary => {
            if(!this.state.selectedWorkflowId) return true
            if(!this.state.filters) return true

            const phases = subsidiary.phases.filter(phase => phase.workflow.id === this.state.selectedWorkflowId)

            if(!phases.length) return !!this.state.filters.none
            const phase = phases[0]

            return !!this.state.filters[phase.phase.id]
        }

        const filterForSubsidiaryTypes = subsidiary => {
            if(!this.state.filters) return true
            return !!this.state.filters[subsidiary.subsidiaryType ? subsidiary.subsidiaryType.id : "none"]
        }

        const filterForStatus = subsidiary => {
            if(!this.state.filters) return true
            return !!this.state.filters[subsidiary.phase ? subsidiary.phase.id : "none"]
        }

        const filterForFacilityManagement = subsidiary => {
            if(!this.state.filters) return true
            return !!this.state.filters[this.evaluate(subsidiary)]
        }

        const subsidiaryCount = this.state.subsidiaries ? (this.state.perspective === "facilityManagement" ? this.state.subsidiaries.filter(filterForFacilityManagement).length : (this.state.perspective === "workflows" ? this.state.subsidiaries.filter(filterForWorkflows).length : this.state.subsidiaries.filter(filterForSubsidiaryTypes).length)) : 0

        return <Page {...this.props} maxSize="100%" fullHeight full>
            <MapContainer>
                <ToggleView onClick={() => this.setState({ showElements: !this.state.showElements })}>
                    {this.state.showElements && <><i className="far fa-eye-slash" /> {i18next.t("page.private.map.toggleView.hide")}</>}
                    {!this.state.showElements && <><i className="far fa-eye" /> {i18next.t("page.private.map.toggleView.show")}</>}
                </ToggleView>
                {this.state.subsidiaries && this.state.statuses && this.state.showElements && <TopLeft>
                    <PerspectiveChanger perspective={this.state.perspective} onChange={perspective => this.setState({ perspective, filters: null, selectedWorkflowId: null })} />
                    <Results><b>{subsidiaryCount}</b> von <b>{this.state.subsidiaries.length}</b> {i18next.t("page.private.map.displayedBranches")}</Results>
                </TopLeft>}
                {this.state.subsidiaries && this.state.statuses && this.state.showElements && this.state.perspective === "workflows" && <WorkflowSidebar onFilter={filters => this.setState({ filters })} phases={this.state.phases} subsidiaries={this.state.subsidiaries} workflows={this.state.workflows} workflowId={this.state.selectedWorkflowId} onWorkflowSelect={selectedWorkflowId => this.setState({ selectedWorkflowId })} />}
                {this.state.subsidiaries && this.state.statuses && this.state.showElements && this.state.perspective === "subsidiaryTypes" && <SubsidiaryTypesSidebar onFilter={filters => this.setState({ filters })} subsidiaryTypes={this.state.subsidiaryTypes} subsidiaries={this.state.subsidiaries} />}
                {this.state.subsidiaries && this.state.statuses && this.state.showElements && this.state.perspective === "status" && <StatusSidebar onFilter={filters => this.setState({ filters })} phases={this.state.phases} subsidiaries={this.state.subsidiaries} />}
                {this.state.subsidiaries && this.state.statuses && this.state.showElements && this.state.perspective === "facilityManagement" && <FacilityManagementSidebar evaluate={this.evaluate} onFilter={filters => this.setState({ filters })} subsidiaries={this.state.subsidiaries} />}
                {!this.state.subsidiaries || !this.state.statuses && <>
                    <LoaderMask>
                        <h1>{i18next.t("page.private.map.mapData")}</h1>
                        <BarLoader height={8} width="100%" loading color="#3b97d3" />
                    </LoaderMask>
                </>}
                {this.state.subsidiaries && this.state.showElements && this.state.statuses && <SearchHandler subsidiaries={this.state.subsidiaries} callback={subsidiaryId => {
                    const subsidiary = this.state.subsidiaries.find(subsidiary => subsidiary.id === subsidiaryId)

                    console.log(subsidiary)

                    //this.mapRef.current.state.map.flyTo({ essential: true, center: [ subsidiary.locationLng, subsidiary.locationLat ] })
                    this.setState({ subsidiary, center: [ subsidiary.locationLng, subsidiary.locationLat ] })
                }} />}
                <Map
                    ref={this.mapRef}
                    initialViewState={{
                        longitude: this.state.center[0],
                        latitude: this.state.center[1],
                        zoom: this.state.zoom
                    }}
                    mapStyle="mapbox://styles/scanmetrix/ckpz7zfre2o1a17oa1t8rdfax?optimize=true"
                    mapboxAccessToken="pk.eyJ1Ijoic2Nhbm1ldHJpeCIsImEiOiJja20zNXFrcmUzMHJ5MnBtd25nb2RmNDhwIn0.MiGqkL0HH3Ku8vEF1YtMbQ"
                    flyToOptions={{ speed: 0.8 }}
                    interactiveLayerIds={[ "markers" ]}
                    containerStyle={{
                        flex: 1,
                        width: "100%",
                        height: "100%"
                    }}
                    onClick={event => {
                        if(event.features && event.features.length) {
                            const subsidiaryId = event.features[0].id
                            const subsidiary = this.state.subsidiaries.find(s => String(s.id) === String(subsidiaryId))

                            if(subsidiary) {
                                this.setState({ subsidiary, center: [ subsidiary.locationLng, subsidiary.locationLat ] })
                            }
                        }
                    }}
                    onStyleLoad={map => {
                        map.resize()
                    }}>
                    {this.state.perspective === "facilityManagement" && this.state.subsidiaries && this.state.statuses && <Source type="geojson" data={{
                        type: "FeatureCollection",
                        features: this.state.subsidiaries.filter(filterForFacilityManagement).map(subsidiary => {
                            const color = this.evaluate(subsidiary)

                            return ({
                                id: subsidiary.id,
                                type: "Feature",
                                properties: { color: color === "red" ? "#e74c3c" : (color === "green" ? "#1abc9c" : (color === "orange" ? "#e67e22" : "#34495e")) },
                                geometry: {
                                    type: "Point",
                                    coordinates: [subsidiary.locationLng, subsidiary.locationLat]
                                }
                            })
                        })
                    }}>
                        <Layer type="circle" id="markers" paint={{ "circle-color": [ "get", "color" ], "circle-stroke-width": 1, "circle-stroke-color": "#fff", "circle-stroke-opacity": 1, "circle-radius": 10 }} />
                    </Source>}
                    {this.state.perspective === "subsidiaryTypes" && this.state.subsidiaries && <Source type="geojson" data={{
                        type: "FeatureCollection",
                        features: this.state.subsidiaries.filter(filterForSubsidiaryTypes).map(subsidiary => {
                            const color = subsidiary.subsidiaryType ? subsidiary.subsidiaryType.color : "#95a5a6"

                            return ({
                                id: subsidiary.id,
                                type: "Feature",
                                properties: { color },
                                geometry: {
                                    type: "Point",
                                    coordinates: [subsidiary.locationLng, subsidiary.locationLat]
                                }
                            })
                        })
                    }}>
                        <Layer type="circle" id="markers" paint={{ "circle-color": [ "get", "color" ], "circle-stroke-width": 1, "circle-stroke-color": "#fff", "circle-stroke-opacity": 1, "circle-radius": 10 }} />
                    </Source>}
                    {this.state.perspective === "status" && this.state.subsidiaries && <Source type="geojson" data={{
                        type: "FeatureCollection",
                        features: this.state.subsidiaries.filter(filterForStatus).map(subsidiary => {
                            const color = subsidiary.phase ? subsidiary.phase.color : "#95a5a6"

                            return ({
                                id: subsidiary.id,
                                type: "Feature",
                                properties: { color },
                                geometry: {
                                    type: "Point",
                                    coordinates: [subsidiary.locationLng, subsidiary.locationLat]
                                }
                            })
                        })
                    }}>
                        <Layer type="circle" id="markers" paint={{ "circle-color": [ "get", "color" ], "circle-stroke-width": 1, "circle-stroke-color": "#fff", "circle-stroke-opacity": 1, "circle-radius": 10 }} />
                    </Source>}
                    {this.state.perspective === "tenants" && this.state.subsidiaries && <Source type="geojson" data={{
                        type: "FeatureCollection",
                        features: this.state.subsidiaries.map(subsidiary => {
                            const color = subsidiary.tenants.length ? "#16a085" : "#95a5a6"

                            return ({
                                id: subsidiary.id,
                                type: "Feature",
                                properties: { color },
                                geometry: {
                                    type: "Point",
                                    coordinates: [subsidiary.locationLng, subsidiary.locationLat]
                                }
                            })
                        })
                    }}>
                        <Layer type="circle" id="markers" paint={{ "circle-color": [ "get", "color" ], "circle-stroke-width": 1, "circle-stroke-color": "#fff", "circle-stroke-opacity": 1, "circle-radius": 10 }} />
                    </Source>}
                    {this.state.perspective === "workflows" && this.state.subsidiaries && <Source type="geojson" data={{
                        type: "FeatureCollection",
                        features: this.state.subsidiaries.filter(filterForWorkflows).map(subsidiary => {
                            let phase = null

                            if(this.state.selectedWorkflowId) {
                                const phases = subsidiary.phases.filter(phase => phase.workflow.id === this.state.selectedWorkflowId)
                                if(phases.length > 0) phase = this.state.phases.find(ph => ph.id === phases[0].phase.id)
                            }

                            const color = phase ? phase.color : "#95a5a6"

                            return ({
                                id: subsidiary.id,
                                type: "Feature",
                                properties: { color },
                                geometry: {
                                    type: "Point",
                                    coordinates: [subsidiary.locationLng, subsidiary.locationLat]
                                }
                            })
                        })
                    }}>
                        <Layer type="circle" id="markers" paint={{ "circle-color": [ "get", "color" ], "circle-stroke-width": 1, "circle-stroke-color": "#fff", "circle-stroke-opacity": 1, "circle-radius": 10 }} />
                    </Source>}
                    {subsidiary && <Popup key={subsidiary.id} latitude={subsidiary.locationLat} longitude={subsidiary.locationLng}>
                        <StyledPopup>
                            <i className="close far fa-times" onClick={() => this.setState({ subsidiary: null })} />
                            <p className="title"><b>{i18next.t("page.private.map.popUp.title")}</b> {subsidiary.name} ({subsidiary.label})</p>
                            <p className="legalName"><b>{i18next.t("page.private.map.popUp.legalName")}</b> {subsidiary.legalName}</p>
                            <p className="address"><b>{i18next.t("page.private.map.popUp.address")}</b> {inlineAddress(subsidiary.address)}</p>
                            <div className="button" onClick={() => this.props.history.push(`/subsidiary/${subsidiary.id}`)}><i className="far fa-map-marker" />{i18next.t("page.private.map.popUp.button")}</div>
                        </StyledPopup>
                    </Popup>}
                </Map>
            </MapContainer>
        </Page>
    }
}

const StyledMarkerVisual = styled.div`
        width: 32px;
        height: 32px;
        background-color: red;
`

class MarkerVisual extends Component {
    render() {
        return <StyledMarkerVisual>

        </StyledMarkerVisual>
    }
}

export default class extends Component {
    render() {
        return <MapClass {...this.props} />
    }
}
