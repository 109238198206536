import React, { Component } from "react"
import { Modal, Input, Button, Select, Checkbox } from "scanmetrix-components"
import countries from "i18n-iso-countries"

import {contractTypes} from "../../dashboard/propertyManagement";
import i18next from "i18next";

countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = { }

    constructor(props) {
        super(props)

        this.getCleanState = this.getCleanState.bind(this)

        this.state = this.getCleanState()
        this.state.serviceProviders = []
        this.state.workSections = []
        this.state.branches = []
        this.state.teams = []

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    WorkSections {
                        nodes {
                            id
                            name
                            icon
                            stlbId
                        }
                    }
                    Teams {
                        nodes {
                            id
                            name
                            number
                        }
                    }
                    Branches {
                        nodes {
                            id
                            name
                            legalName
                        }
                    }
                    ServiceProviders {
                        nodes {
                            id
                            name
                            address {
                                streetName
                                streetNumber
                                additional
                                postalCode
                                city
                                country
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ branches: data.data.Branches.nodes, serviceProviders: data.data.ServiceProviders.nodes, workSections: data.data.WorkSections.nodes, teams: data.data.Teams.nodes })
        })
    }

    getCleanState() {
        return ({
            beginsAtWasSet: false,
            error: null,
            contactTelefax: null,
            contactTelephone: null,
            contactEmail: null,
            title: null,
            type: null,
            number: null,
            extendedView: true,
            description: null,
            serviceProviderId: null,
            createServiceProvider: false,
            partnerName: null,
            addressStreetName: null,
            addressStreetNumber: null,
            responsibility: null,
            workSectionId: null,
            addressAdditional: null,
            addressPostalCode: null,
            addressCity: null,
            addressCountry: null,
            branchId: null,
            cancelationType: "contractEnd",
            beginsAt: null,
            endsAt: null,
            contractDurationAmount: null,
            cancelationDurationBefore: null,
            contractDurationType: null,
            yearlyCost: null,
            yearlyCostIncrement: null,
            yearlyCostDate: null,
            perpetual: false,
            automaticRenewal: false,
            cancelationDurationAmount: null,
            cancelationDurationType: null,
            renewalDurationAmount: null,
            renewalDurationType: null,
            contractDate: null,
            updateYes: null,
            updateNo: null,
            loading: false,
            teamId: null
        })
    }

    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Modal minWidth="650px" instance={this.props.instance} initialize={( ) => this.setState(this.getCleanState())}>
            <Modal.Head title={i18next.t("page.private.contracts.createContractModal.depositContract.title")} icon="file-contract" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Checkbox label={i18next.t("page.private.contracts.createContractModal.depositContract.extendedView")} value={this.state.extendedView} onChange={extendedView => this.setState({ extendedView })} />
                    <Modal.Divider title={i18next.t("page.private.contracts.createContractModal.depositContract.contractData")} />
                    <div style={{ display: "grid", gridGap: "24px", gridTemplateColumns: "1fr 1fr" }}>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                            <Select adjustWidth value={this.state.branchId} onChange={branchId => this.setState({ branchId })} icon="project-diagram" label={i18next.t("page.private.contracts.createContractModal.depositContract.companyBranch")} items={this.state.branches.map(branch => ({ key: branch.id, legalName: branch.legalName, title: `${branch.name} / ${branch.legalName}` }))} />
                        </div>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 2 }}>
                            <Select value={this.state.workSectionId} onChange={(workSectionId, raw) => {
                                if(this.state.title || !raw) {
                                    this.setState({ workSectionId })
                                } else {
                                    this.setState({ workSectionId, title: raw.raw.name })
                                }
                            }} icon="hard-hat" label={i18next.t("page.private.contracts.createContractModal.depositContract.trade")} adjustWidth items={this.state.workSections.map(workSection => ({
                                key: workSection.id,
                                icon: workSection.icon,
                                title: `${workSection.stlbId ? (workSection.stlbId + " / ") : ""}${workSection.name}`,
                                raw: workSection
                            }))} />
                        </div>
                        <div style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
                            <Select value={this.state.teamId} onChange={teamId => this.setState({ teamId })} icon="users-crown" noUnselect required label={i18next.t("page.private.contracts.createContractModal.depositContract.workGroup")} adjustWidth items={this.state.teams.map(team => ({
                                key: team.id,
                                title: `${team.number} / ${team.name}`
                            }))} />
                        </div>
                        <Input required value={this.state.title} onChange={title => this.setState({ title })} icon="tag" label={i18next.t("page.private.contracts.createContractModal.depositContract.contractName")} adjustWidth />
                        <Select value={this.state.type} onChange={type => this.setState({ type })} icon="pencil-alt" label={i18next.t("page.private.contracts.createContractModal.depositContract.contractType")} adjustWidth items={contractTypes.map(ct => ({ key: ct.key, title: i18next.t("page.private.dashboard.propertyManagement.contractType." + ct.key) }))} />
                    </div>
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <Input value={this.state.number} onChange={number => this.setState({ number })} icon="sort-numeric-up-alt" label={i18next.t("page.private.contracts.createContractModal.depositContract.contractNumber")} adjustWidth />
                        <Input required date value={this.state.contractDate} onChange={contractDate => {
                            console.log(contractDate)
                            if(this.state.beginsAtWasSet || this.state.beginsAt) {
                                this.setState({ contractDate })
                            } else {
                                this.setState({ contractDate, beginsAtWasSet: true, beginsAt: contractDate })
                            }
                        }} icon="calendar-day" label={i18next.t("page.private.contracts.createContractModal.depositContract.contractDate")} adjustWidth />
                    </div>
                    {this.state.extendedView && <Input textArea value={this.state.description} onChange={description => this.setState({ description })} icon="font" label={i18next.t("page.private.contracts.createContractModal.depositContract.contractDescription")} adjustWidth />}
                    <Modal.Divider title={i18next.t("page.private.contracts.createContractModal.contractTerm.title")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: this.state.perpetual ? 3 : 2 }}>
                            <Input date value={this.state.beginsAt} onChange={beginsAt => this.setState({ beginsAt })} icon="hourglass-start" label={i18next.t("page.private.contracts.createContractModal.contractTerm.contractStart")} adjustWidth />
                        </div>
                        {!this.state.perpetual && <Input date value={this.state.endsAt} onChange={endsAt => this.setState({ endsAt })} icon="hourglass-end" label={i18next.t("page.private.contracts.createContractModal.contractTerm.contractEnd")} adjustWidth />}
                        {!this.state.perpetual && <Input required int value={this.state.contractDurationAmount} onChange={contractDurationAmount => this.setState({ contractDurationAmount })} icon="tally" label={i18next.t("page.private.contracts.createContractModal.contractTerm.termNumber")} adjustWidth />}
                        {!this.state.perpetual && <Select required value={this.state.contractDurationType} onChange={contractDurationType => this.setState({ contractDurationType })} icon="calendar-week" label={i18next.t("page.private.contracts.createContractModal.contractTerm.termUnit")} adjustWidth items={[
                            { key: "days", title: i18next.t("page.private.contracts.createContractModal.contractTerm.termUnitTypes.days") },
                            { key: "weeks", title: i18next.t("page.private.contracts.createContractModal.contractTerm.termUnitTypes.weeks") },
                            { key: "months", title: i18next.t("page.private.contracts.createContractModal.contractTerm.termUnitTypes.months") },
                            { key: "years", title: i18next.t("page.private.contracts.createContractModal.contractTerm.termUnitTypes.years") },
                        ]} />}
                        <Checkbox label={i18next.t("page.private.contracts.createContractModal.contractTerm.unlimitedContract")} value={this.state.perpetual} onChange={perpetual => this.setState({ perpetual })} />
                        <Checkbox label={i18next.t("page.private.contracts.createContractModal.contractTerm.automaticRenewal")} value={this.state.automaticRenewal} onChange={automaticRenewal => this.setState({ automaticRenewal })} />

                        {/*<div style={{ marginTop: 24, marginBottom: 16 }}>
                            <p>Soll eine schriftliche Ankündigung erfolgen?</p>
                        </div>*/}
                        {/*<div style={{ marginTop: 24, marginBottom: 16, display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Checkbox label="Ja" value={this.state.updateYes} onChange={updateYes => this.setState({ updateYes, updateNo: null })} /><div style={{ marginRight: 32 }} />
                            <Checkbox label="Nein" value={this.state.updateNo} onChange={updateNo => this.setState({ updateNo, updateYes: null })} />
                        </div>*/}

                        {/* TODO state correctly set + if yes, pop up "Ankündigung Wahrnehmung der Option muss ... Monate vor Vertragsende erfolgen." */}
                    </div>
                    {this.state.extendedView && <>
                        <Modal.Divider title={i18next.t("page.private.contracts.createContractModal.noticePeriod.title")} />
                        <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                            <Input int value={this.state.cancelationDurationAmount} onChange={cancelationDurationAmount => this.setState({ cancelationDurationAmount })} icon="tally" label={i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodNumber")} adjustWidth />
                            <Select value={this.state.cancelationDurationType} onChange={cancelationDurationType => this.setState({ cancelationDurationType })} icon="calendar-week" label={i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodUnit")} adjustWidth items={[
                                { key: "days", title: i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodUnitTypes.days") },
                                { key: "weeks", title: i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodUnitTypes.weeks") },
                                { key: "months", title: i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodUnitTypes.months") },
                                { key: "years", title: i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodUnitTypes.years") },
                            ]} />
                            <Select upwards value={this.state.cancelationType} noUnselect onChange={cancelationType => this.setState({ cancelationType })} icon="calendar" label={i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodType")} adjustWidth items={[
                                { key: "ongoing", title: i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodTypeTypes.ongoing") },
                                { key: "monthEnd", title: i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodTypeTypes.monthEnd") },
                                { key: "yearEnd", title: i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodTypeTypes.yearEnd") },
                                { key: "quarterEnd", title: i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodTypeTypes.quarterEnd") },
                                { key: "contractEnd", title: i18next.t("page.private.contracts.createContractModal.noticePeriod.noticePeriodTypeTypes.contractEnd") }
                            ]} />
                            {/*<Input int value={this.state.cancelationDurationBefore} onChange={cancelationDurationBefore => this.setState({ cancelationDurationBefore })} icon="calendar-week" label="Vorfrist Kündigung zum Ende der Laufzeit" adjustWidth />*/}
                        </div>
                        <Modal.Divider title={i18next.t("page.private.contracts.createContractModal.renewalPeriod.title")} />
                        <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                            <Input int value={this.state.renewalDurationAmount} onChange={renewalDurationAmount => this.setState({ renewalDurationAmount })} icon="tally" label={i18next.t("page.private.contracts.createContractModal.renewalPeriod.renewalPeriodNumber")} adjustWidth />
                            <Select value={this.state.renewalDurationType} onChange={renewalDurationType => this.setState({ renewalDurationType })} icon="calendar-week" label={i18next.t("page.private.contracts.createContractModal.renewalPeriod.renewalPeriodUnit")} adjustWidth items={[
                                { key: "days", title: i18next.t("page.private.contracts.createContractModal.renewalPeriod.renewalPeriodUnitTypes.days") },
                                { key: "weeks", title: i18next.t("page.private.contracts.createContractModal.renewalPeriod.renewalPeriodUnitTypes.weeks") },
                                { key: "months", title: i18next.t("page.private.contracts.createContractModal.renewalPeriod.renewalPeriodUnitTypes.months") },
                                { key: "years", title: i18next.t("page.private.contracts.createContractModal.renewalPeriod.renewalPeriodUnitTypes.years") },
                            ]} />
                        </div>
                        <Modal.Divider title={i18next.t("page.private.contracts.createContractModal.otherData.title")} />
                        <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                            <Input adjustWidth float label={i18next.t("page.private.contracts.createContractModal.otherData.annualCostsNet")} icon="piggy-bank" value={this.state.yearlyCost} format={val => val !== null ? formatter.format(val) : val} onChange={yearlyCost => this.setState({ yearlyCost })} />
                            <Input adjustWidth float label={i18next.t("page.private.contracts.createContractModal.otherData.annualPricePercent")} icon="percent" value={this.state.yearlyCostIncrement} format={val => val !== null ? `${val} %` : val} onChange={yearlyCostIncrement => this.setState({ yearlyCostIncrement })} />
                            <Input adjustWidth date label={i18next.t("page.private.contracts.createContractModal.otherData.settingPrice")} icon="calendar-day" value={this.state.yearlyCostDate} onChange={yearlyCostDate => this.setState({ yearlyCostDate })} />
                        </div>
                    </>}
                    <Modal.Divider title={this.state.createServiceProvider ? i18next.t("page.private.contracts.createContractModal.contractPartner.titleServiceProvider") : i18next.t("page.private.contracts.createContractModal.contractPartner.titleContractPartner")} />
                    {!this.state.createServiceProvider && <Select value={this.state.serviceProviderId} onChange={(serviceProviderId, serviceProvider) => {
                        if(!serviceProviderId) this.setState({ serviceProviderId })
                        else this.setState({
                            serviceProviderId,
                            partnerName: this.state.partnerName || serviceProvider.data.name,
                            addressStreetName: this.state.addressStreetName || serviceProvider.data.address.streetName,
                            addressStreetNumber: this.state.addressStreetNumber || serviceProvider.data.address.streetNumber,
                            addressPostalCode: this.state.addressPostalCode || serviceProvider.data.address.postalCode,
                            addressCity: this.state.addressCity || serviceProvider.data.address.city,
                            addressCountry: this.state.addressCountry || serviceProvider.data.address.country,
                            addressAdditional: this.state.addressAdditional || serviceProvider.data.address.additional,
                            createServiceProvider: false
                        })
                    }} icon="user-tie" label={i18next.t("page.private.contracts.createContractModal.contractPartner.serviceProvider")} adjustWidth items={this.state.serviceProviders.map(serviceProvider => ({ key: serviceProvider.id, title: (serviceProvider.address.postalCode && serviceProvider.address.city) ? `${serviceProvider.name} / ${serviceProvider.address.postalCode} ${serviceProvider.address.city}` : serviceProvider.name, data: serviceProvider }))} />}
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: (!this.state.serviceProviderId && this.state.partnerName) ? "2fr 0.75fr" : "1fr" }}>
                        <Input required value={this.state.partnerName} onChange={partnerName => this.setState({ partnerName })} icon="user-secret" label={i18next.t("page.private.contracts.createContractModal.contractPartner.contractPartnerName")} adjustWidth />
                        {(!this.state.serviceProviderId && this.state.partnerName) && <Checkbox label={i18next.t("page.private.contracts.createContractModal.contractPartner.createServiveProvider")} value={this.state.createServiceProvider} onChange={createServiceProvider => this.setState({ createServiceProvider, serviceProviderId: null })} />}
                    </div>
                    {this.state.createServiceProvider && <Input required value={this.state.responsibility} onChange={responsibility => this.setState({ responsibility })} icon="hard-hat" label={i18next.t("page.private.contracts.createContractModal.contractPartner.responsibility")} adjustWidth />}
                    {this.state.createServiceProvider && <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                        <Input adjustWidth label={i18next.t("page.private.contracts.createContractModal.contractPartner.email")} icon="at" value={this.state.contactEmail} onChange={contactEmail => this.setState({ contactEmail })} />
                        <Input adjustWidth label={i18next.t("page.private.contracts.createContractModal.contractPartner.telephoneNumber")} icon="phone" value={this.state.contactTelephone} onChange={contactTelephone => this.setState({ contactTelephone })} />
                        <Input adjustWidth label={i18next.t("page.private.contracts.createContractModal.contractPartner.fax")} icon="fax" value={this.state.contactTelefax} onChange={contactTelefax => this.setState({ contactTelefax })} />
                    </div>}
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "2fr 0.5fr" }}>
                        <Input required value={this.state.addressStreetName} onChange={addressStreetName => this.setState({ addressStreetName })} icon="road" label={i18next.t("page.private.contracts.createContractModal.contractPartner.street")} adjustWidth />
                        <Input required value={this.state.addressStreetNumber} onChange={addressStreetNumber => this.setState({ addressStreetNumber })} icon="bell" label={i18next.t("page.private.contracts.createContractModal.contractPartner.houseNumber")} adjustWidth />
                    </div>
                    <Input value={this.state.addressAdditional} onChange={addressAdditional => this.setState({ addressAdditional })} icon="font" label={i18next.t("page.private.contracts.createContractModal.contractPartner.additionalAddress")} adjustWidth />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 2fr" }}>
                        <Input required value={this.state.addressPostalCode} onChange={addressPostalCode => this.setState({ addressPostalCode })} icon="mailbox" label={i18next.t("page.private.contracts.createContractModal.contractPartner.zipCode")} adjustWidth />
                        <Input required value={this.state.addressCity} onChange={addressCity => this.setState({ addressCity })} icon="city" label={i18next.t("page.private.contracts.createContractModal.contractPartner.city")} adjustWidth />
                    </div>
                    <Select upwards required adjustWidth value={this.state.addressCountry} onChange={addressCountry => this.setState({ addressCountry })} icon="globe-europe" label={i18next.t("page.private.contracts.createContractModal.contractPartner.country")} items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
                    {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => {
                const disabled = (this.state.createServiceProvider && !this.state.responsibility) || !this.state.teamId || !this.state.title || !this.state.contractDate || !this.state.partnerName || !this.state.addressStreetName || !this.state.addressStreetNumber || !this.state.addressPostalCode || !this.state.addressCity || !this.state.addressCountry || (!this.state.perpetual && (!this.state.contractDurationAmount || !this.state.contractDurationType))

                const onClick = redirect => {
                    this.setState({ loading: true, error: null })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($teamId: ID!, $branchId: ID, $responsibility: String, $contactEmail: String, $contactTelefax: String, $contactTelephone: String, $title: String!, $yearlyCostDate: DateTime, $yearlyCostIncrement: Float, $yearlyCost: Float, $contractDate: DateTime!, $workSectionId: ID, $cancelationType: String!, $description: String, $number: String, $automaticRenewal: Boolean!, $beginsAt: DateTime, $endsAt: DateTime, $contractDurationAmount: Int, $contractDurationType: String, $perpetual: Boolean!, $cancelationDurationAmount: Int, $cancelationDurationType: String, $renewalDurationAmount: Int, $renewalDurationType: String, $serviceProviderId: ID, $partnerName: String!, $addressStreetName: String!, $addressStreetNumber: String!, $addressAdditional: String, $addressPostalCode: String!, $addressCity: String!, $addressCountry: String!, $type: String) {
                            createContract(teamId: $teamId, branchId: $branchId, responsibility: $responsibility, contactEmail: $contactEmail, contactTelefax: $contactTelefax, contactTelephone: $contactTelephone, title: $title, yearlyCostDate: $yearlyCostDate, yearlyCostIncrement: $yearlyCostIncrement, yearlyCost: $yearlyCost, workSectionId: $workSectionId, contractDate: $contractDate, cancelationType: $cancelationType, description: $description, number: $number, automaticRenewal: $automaticRenewal, beginsAt: $beginsAt, endsAt: $endsAt, contractDurationAmount: $contractDurationAmount, contractDurationType: $contractDurationType, perpetual: $perpetual, cancelationDurationAmount: $cancelationDurationAmount, cancelationDurationType: $cancelationDurationType, renewalDurationAmount: $renewalDurationAmount, renewalDurationType: $renewalDurationType, serviceProviderId: $serviceProviderId, partnerName: $partnerName, addressStreetName: $addressStreetName, addressStreetNumber: $addressStreetNumber, addressAdditional: $addressAdditional, addressPostalCode: $addressPostalCode, addressCity: $addressCity, addressCountry: $addressCountry, type: $type)
                        }
                    `,
                        variables: {
                            responsibility: this.state.createServiceProvider ? this.state.responsibility : null,
                            contactEmail: this.state.contactEmail,
                            contactTelefax: this.state.contactTelefax,
                            contactTelephone: this.state.contactTelephone,
                            title: this.state.title,
                            type: this.state.type,
                            description: this.state.description,
                            number: this.state.number,
                            perpetual: this.state.perpetual,
                            teamId: this.state.teamId,
                            branchId: this.state.branchId,
                            automaticRenewal: this.state.automaticRenewal,
                            beginsAt: this.state.beginsAt ? moment(this.state.beginsAt, "DD.MM.YYYY").toDate() : null,
                            contractDate: moment(this.state.contractDate, "DD.MM.YYYY").toDate(),
                            endsAt: this.state.perpetual ? null : (this.state.endsAt ? moment(this.state.endsAt, "DD.MM.YYYY").toDate() : null),
                            contractDurationAmount: this.state.perpetual ? null : this.state.contractDurationAmount,
                            contractDurationType: this.state.perpetual ? null : this.state.contractDurationType,
                            cancelationDurationAmount: this.state.cancelationDurationAmount,
                            cancelationDurationType: this.state.cancelationDurationType,
                            renewalDurationAmount: this.state.renewalDurationAmount,
                            renewalDurationType: this.state.renewalDurationType,
                            cancelationType: this.state.cancelationType,
                            workSectionId: this.state.workSectionId,
                            partnerName: this.state.partnerName,
                            serviceProviderId: this.state.serviceProviderId,
                            addressStreetName: this.state.addressStreetName,
                            addressStreetNumber: this.state.addressStreetNumber,
                            addressAdditional: this.state.addressAdditional,
                            addressPostalCode: this.state.addressPostalCode,
                            addressCity: this.state.addressCity,
                            addressCountry: this.state.addressCountry,
                            yearlyCost: this.state.yearlyCost,
                            yearlyCostIncrement: this.state.yearlyCostIncrement,
                            yearlyCostDate: this.state.yearlyCostDate ? moment(this.state.yearlyCostDate, "DD.MM.YYYY").toDate() : null
                        }
                    }).then(result => {
                        this.setState({ loading: false })

                        if(result.data.createContract) {
                            if(redirect) {
                                this.props.history.push("/contract/" + result.data.createContract)
                            } else {
                                this.props.refresh()
                                instance.close()
                            }
                        } else {
                            let error = (result.errors && result.errors.length) ? result.errors[0].message : "Anlegen fehlgeschlagen. Bitte kontaktieren Sie den Administrator."
                            if(error === "INVALID_ADDRESS") error = "Die angegebene Adresse ist ungültig oder existiert nicht."
                            this.setState({ error })
                        }
                    })}

                return [
                    <Button loading={this.state.loading} thick title={i18next.t("page.private.contracts.createContractModal.contractPartner.depositContractButton")} disabled={disabled} icon="file-contract" onClick={() => onClick(false)} />,
                    <Button loading={this.state.loading} secondary thick title={i18next.t("page.private.contracts.createContractModal.contractPartner.exitButton")} disabled={disabled} icon="file-contract" onClick={() => onClick(true)} />
                ]
            }} />
        </Modal>
    }
}
