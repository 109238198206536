import React, { Component } from "react"
import styled from "styled-components"
import {Button, Modal, Navigation, PageContainer} from "scanmetrix-components"
import MultilangModal from "./styled/MultilangModal"
import QRCode from 'qrcode.react'

const RowView = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

export default class extends Component {
    state = {
        openModal: false
    }

    constructor(props) {
        super(props)
        this.escFunction = this.escFunction.bind(this)
    }

    escFunction(event) {
        if(event.keyCode === 27) {
            if (this.state.openModal === true) {
                this.setState({ openModal: false })
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false)
    }

    render() {
        return <>
            <RowView>
                <Navigation {...this.props} globeClick={() => this.setState({ openModal: !this.state.openModal })} />
                <PageContainer padding={this.props.padding} fullHeight={this.props.fullHeight} maxSize={this.props.maxSize || "2560px"} full={this.props.full}>
                    {this.props.children}
                </PageContainer>
            </RowView>

            <MultilangModal
                style={ this.state.openModal ? { display: 'block' } : { display: 'none' } }
                closeBtn={() => this.setState({openModal: !this.state.openModal})}
                submitBtn={() => this.setState({openModal: !this.state.openModal})}
            />
        </>
    }
}
