import React, { Component } from "react"
import { Page, Breadcrumbs, Button, Input, Grid, Section, Select, Table, TextEditor } from "scanmetrix-components"
import { SortableTree } from "./SortableTree/index"
import {GridElement} from "../../../components"

import { getIntervalType } from "../object"
import UpdateBuildingInspectionTemplateIntervalVariationModal from "./modals/UpdateBuildingInspectionTemplateIntervalVariationModal"
import PDFModal from "./modals/PDFModal";

export default class extends Component {
    constructor(props) {
        super(props)

        this.createStep = this.createStep.bind(this)
        this.deleteStep = this.deleteStep.bind(this)
        this.updateStepPosition = this.updateStepPosition.bind(this)
        this.updateStepDescription = this.updateStepDescription.bind(this)
        this.updateStepInputs = this.updateStepInputs.bind(this)
        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($buildingInspectionTemplateId: ID!) {
                    BuildingInspectionReportTemplates {
                        nodes {
                            id
                            name
                        }
                    }
                    BuildingInspectionTemplate(id: $buildingInspectionTemplateId) {
                        id
                        name
                        intervalMode
                        intervalType
                        intervalAmount
                        type
                        status
                        instructions
                        startingAt
                        letterHead {
                            id
                        }
                        intervalVariations {
                            id
                            intervalAmount
                            intervalType
                            inactive
                            startingAt
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    streetName
                                    streetNumber
                                    postalCode
                                    city
                                }
                            }
                        }
                        reportTemplate {
                            id
                        }
                    }
                    BuildingInspectionTemplateSteps(filter: { buildingInspectionTemplateId_eq: $buildingInspectionTemplateId }) {
                        nodes {
                            id
                            description
                            position
                            inputs
                            parent {
                                id
                            }
                        }
                    }
                    LetterHeads {
                        nodes {
                            id
                            name
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                postalCode
                                city
                                streetName
                                streetNumber
                            }
                        }
                    }
                }
            `,
            variables: {
                buildingInspectionTemplateId: this.props.match.params.buildinginspectiontemplateid
            }
        }).then(data => {
            const nodes = data.data.BuildingInspectionTemplateSteps.nodes
            let items = []

            const getNode = node => {
                return ({
                    id: node.id,
                    text: node.description || "",
                    inputs: node.inputs,
                    children: nodes.filter(tt => tt.parent?.id === node.id).sort((a, b) => a.position - b.position).map(n => getNode(n)),
                    collapsed: true
                })
            }

            nodes.filter(node => node.parent === null).sort((a, b) => a.position - b.position).forEach(node => {
                items.push(getNode(node))
            })

            this.setState({ data: items, buildingInspectionTemplate: data.data.BuildingInspectionTemplate, reportTemplates: data.data.BuildingInspectionReportTemplates.nodes, subsidiaries: data.data.Subsidiaries.nodes, letterHeads: data.data.LetterHeads.nodes })
        })
    }

    refreshData() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($buildingInspectionTemplateId: ID!) {
                    BuildingInspectionTemplateSteps(filter: { buildingInspectionTemplateId_eq: $buildingInspectionTemplateId }) {
                        nodes {
                            id
                            description
                            position
                            inputs
                            parent {
                                id
                            }
                        }
                    }
                }
            `,
            variables: {
                buildingInspectionTemplateId: this.props.match.params.buildinginspectiontemplateid
            }
        }).then(data => {
            const nodes = data.data.BuildingInspectionTemplateSteps.nodes
            let items = []

            const getNode = node => {
                return ({
                    id: node.id,
                    text: node.description || "",
                    inputs: node.inputs,
                    children: nodes.filter(tt => tt.parent?.id === node.id).sort((a, b) => a.position - b.position).map(n => getNode(n)),
                    collapsed: true
                })
            }

            nodes.filter(node => node.parent === null).sort((a, b) => a.position - b.position).forEach(node => {
                items.push(getNode(node))
            })

            this.setState({ data: items })
        })
    }

    state = {
        data: null,
        buildingInspectionTemplate: null,
        reportTemplates: [],
        subsidiaryId: null,
        subsidiaries: [],
        letterHeads: []
    }

    createStep() {
        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($buildingInspectionTemplateId: ID!) {
                    createBuildingInspectionTemplateStep(buildingInspectionTemplateId: $buildingInspectionTemplateId)
                }
            `,
            variables: {
                buildingInspectionTemplateId: this.props.match.params.buildinginspectiontemplateid
            }
        }).then(data => {
            this.createCallback({ id: data.data.createBuildingInspectionTemplateStep, text: "", children: [], inputs: {} })
            this.refreshData()
        })
    }

    deleteStep(id) {
        return scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!) {
                    deleteBuildingInspectionTemplateStep(id: $id)
                }
            `,
            variables: {
                id
            }
        })
    }

    updateStepInputs(id, inputs) {
        if(!this.updateStepInputsTimeout) this.updateStepInputsTimeout = {}
        if(this.updateStepInputsTimeout[id]) clearTimeout(this.updateStepInputsTimeout[id])

        this.updateStepInputsTimeout[id] = setTimeout(() => {
            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($id: ID!, $inputs: JSON!) {
                        updateBuildingInspectionTemplateStep(id: $id, inputs: $inputs)
                    }
                `,
                variables: {
                    id,
                    inputs
                }
            })

            delete this.updateStepInputsTimeout[id]
        }, 300)
    }

    updateStepDescription(id, description) {
        if(!this.updateStepDescriptionTimeout) this.updateStepDescriptionTimeout = {}
        if(this.updateStepDescriptionTimeout[id]) clearTimeout(this.updateStepDescriptionTimeout[id])

        this.updateStepDescriptionTimeout[id] = setTimeout(() => {
            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($id: ID!, $description: String) {
                        updateBuildingInspectionTemplateStep(id: $id, description: $description)
                    }
                `,
                variables: {
                    id,
                    description
                }
            })

            delete this.updateStepDescriptionTimeout[id]
        }, 300)
    }

    updateStepPosition({ sourceId, targetId, type }) {
        return scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $targetId: ID!, $type: String!) {
                    updateBuildingInspectionTemplateStepPosition(id: $id, targetId: $targetId, type: $type)
                }
            `,
            variables: {
                id: sourceId,
                targetId,
                type
            }
        }).then(() => {
            this.fetch()
        })
    }

    render() {
        const buildingInspectionTemplate = this.state.buildingInspectionTemplate

        if(!buildingInspectionTemplate) return null

        return <Page {...this.props}>
            <UpdateBuildingInspectionTemplateIntervalVariationModal instance={ref => this.updateBuildingInspectionTemplateIntervalVariationModal = ref} refresh={() => this.fetch()} />
            <PDFModal buildingInspectionTemplateId={buildingInspectionTemplate.id} instance={ref => this.pdfModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "person-walking",
                    title: "Begehungen",
                    link: "/buildinginspections"
                },
                {
                    icon: "memo-circle-check",
                    title: "Begehungsvorlagen",
                    link: "/buildinginspections",
                    state: { tab: 2 }
                },
                {
                    title: buildingInspectionTemplate?.name
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title="Begehungsschritte" icon="clipboard-list">
                    <GridElement styled title="Begehungsvorlage" icon="memo-circle-check" rightContent={<Button title="PDF-Vorschau" icon="file-pdf" thick secondary onClick={() => this.pdfModal.open()} />}>
                        <Input.MutationProvider value={{
                            name: "updateBuildingInspectionTemplate",
                            id: buildingInspectionTemplate.id,
                            data: buildingInspectionTemplate
                        }}>
                            <Grid gap="16px" padding="24px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                                <GridElement>
                                    <Input label="Name" icon="tag" adjustWidth field="name" />
                                </GridElement>
                                <GridElement>
                                    <Select noUnselect field="type" icon="cog" label="Typ" adjustWidth items={[
                                        { key: "safetyInspection", title: "Sicherheitsbegehung", icon: "hard-hat" },
                                        { key: "defectView", title: "Mängelansicht", icon: "bolt" },
                                        { key: "maintenance", title: "Wartung", icon: "clipboard-list" },
                                        { key: "test", title: "Probelauf", icon: "message-smile" },
                                        { key: "visualControl", title: "Sichtkontrolle", icon: "eye" },
                                        { key: "riskAssessment", title: "Gefährdungsbeurteilung", icon: "brake-warning" },
                                        { key: "asa", title: "ASA-Begehung", icon: "user-doctor" },
                                        { key: "sibe", title: "SiBe-Begehung", icon: "shield-check" }
                                    ]} />
                                </GridElement>
                                <GridElement>
                                    <Select noUnselect field="status" icon="pencil" label="Status" adjustWidth items={[
                                        { key: "draft", title: "Entwurf", icon: "pen-ruler" },
                                        { key: "published", title: "Veröffentlicht", icon: "badge-check" },
                                        { key: "disabled", title: "Deaktiviert", icon: "pause" }
                                    ]} />
                                </GridElement>
                                <GridElement>
                                    <Select field="reportTemplate.id" icon="file-pdf" label="Berichtvorlage" adjustWidth items={this.state.reportTemplates.map(reportTemplate => ({
                                        key: reportTemplate.id,
                                        title: reportTemplate.name
                                    }))} />
                                </GridElement>
                                <GridElement>
                                    <Select field="letterHead.id" icon="scroll" label="Briefkopf" adjustWidth items={this.state.letterHeads.map(letterHead => ({
                                        key: letterHead.id,
                                        title: letterHead.name
                                    }))} />
                                </GridElement>
                            </Grid>
                        </Input.MutationProvider>
                    </GridElement>
                    <div style={{ marginTop: 24 }}>
                        <GridElement columnStart={1} columnEnd={6} styled title="Anweisungen & Instruktionen für Prüfer" icon="font">
                            <TextEditor value={buildingInspectionTemplate.instructions} onChange={value => {
                                scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                            mutation($id: ID!, $instructions: String) {
                                                updateBuildingInspectionTemplate(id: $id, instructions: $instructions)
                                            }  
                                        `,
                                    variables: {
                                        id: buildingInspectionTemplate.id,
                                        instructions: value
                                    }
                                })
                            }} />
                        </GridElement>
                    </div>
                    <Button style={{ marginTop: 16 }} thick icon="plus" title="Eintrag hinzufügen" onClick={() => this.createStep()} />
                    <div style={{ marginTop: 16 }}>
                        {this.state.data !== null && <SortableTree
                            removable
                            collapsible
                            indicator
                            defaultItems={this.state.data}
                            deleteCallback={id => this.deleteStep(id)}
                            updateCallbackText={(id, description) => this.updateStepDescription(id, description)}
                            updateCallbackInputs={(id, inputs) => this.updateStepInputs(id, inputs)}
                            updateCallbackPosition={data => this.updateStepPosition(data)}
                            createCallback={createCallback => this.createCallback = createCallback}
                        />}
                        {this.state.data !== null && this.state.data.length === 0 && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", userSelect: "none", paddingTop: 128 }}>
                            <img style={{ height: 200 }} src="/workers.svg" />
                            <h1 style={{ width: "40%", marginTop: 48, fontWeight: 400 }}>Noch keine Begehungsschritte angelegt.</h1>
                            <h2 style={{ width: "40%", marginTop: 16, fontWeight: 300 }}>Diese Liste ist noch leer. Beginnen Sie indem Sie auf <b style={{ fontWeight: 400, color: "#3b97d3" }}>„Eintrag hinzufügen”</b> klicken.</h2>
                        </div>}
                    </div>
                </Section.Item>
                <Section.Item title="Wiederholung" icon="history">
                    <Grid gap="24px">
                        <GridElement styled title="Standardintervall" icon="history">
                            <Input.MutationProvider value={{
                                name: "updateBuildingInspectionTemplate",
                                id: buildingInspectionTemplate.id,
                                data: buildingInspectionTemplate
                            }}>
                                <Grid gap="16px" padding="24px" columns={[ "1fr", "1fr", "1fr", "1fr" ]}>
                                    <GridElement>
                                        <Input date field="startingAt" icon="calendar-day" label="Startet am" adjustWidth disableEmpty />
                                    </GridElement>
                                    <GridElement>
                                        <Input int field="intervalAmount" icon="tally" label="Intervall Anzahl" adjustWidth />
                                    </GridElement>
                                    <GridElement>
                                        <Select field="intervalType" icon="calendar-week" label="Intervall Einheit" adjustWidth items={[
                                            { key: "days", title: "Tage" },
                                            { key: "weeks", title: "Wochen" },
                                            { key: "months", title: "Monate" },
                                            { key: "years", title: "Jahre" },
                                        ]} />
                                    </GridElement>
                                    <GridElement>
                                        <Select field="intervalMode" icon="history" label="Standardmodus" adjustWidth items={[
                                            { key: "none", title: "Standard für keine Niederlassung" },
                                            { key: "all", title: "Standard für alle Niederlassungen" }
                                        ]} />
                                    </GridElement>
                                </Grid>
                            </Input.MutationProvider>
                        </GridElement>
                        <GridElement styled title="Abweichungen" icon="calendar-lines-pen" rightContent={<div style={{ display: "grid", gridAutoFlow: "column", gridGap: 32 }}>
                            <div style={{ width: "450px" }}>
                                <Select value={this.state.subsidiaryId} onChange={subsidiaryId => this.setState({ subsidiaryId })} label="Niederlassung" icon="map-marker" items={this.state.subsidiaries.filter(subsidiary => !buildingInspectionTemplate.intervalVariations.find(v => v.subsidiary.id === subsidiary.id)).map(subsidiary => ({ key: subsidiary.id, title: `${subsidiary.name}${subsidiary.label ? (" / " + subsidiary.label) : ""} / ${subsidiary.address.streetName} ${subsidiary.address.streetNumber}, ${subsidiary.address.postalCode} ${subsidiary.address.city}` }))} adjustWidth />
                            </div>
                            <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                                <Button loading={this.state.loading} title="Abweichung anlegen" icon="calendar-lines-pen" thick disabled={!this.state.subsidiaryId} onClick={() => {
                                    this.setState({ loading: true })

                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($subsidiaryId: ID!, $buildingInspectionTemplateId: ID!) {
                                                createBuildingInspectionTemplateIntervalVariation(subsidiaryId: $subsidiaryId, buildingInspectionTemplateId: $buildingInspectionTemplateId)
                                            }    
                                        `,
                                        variables: {
                                            subsidiaryId: this.state.subsidiaryId,
                                            buildingInspectionTemplateId: buildingInspectionTemplate.id
                                        }
                                    }).then(() => {
                                        this.fetch().then(() => {
                                            this.setState({ subsidiaryId: null, loading: false })
                                        })
                                    })
                                }} />
                            </div>
                        </div>}>
                            <Table
                                head={[
                                    { title: "Niederlassung", width: "55%", column: "subsidiary" },
                                    { title: "Intervall", width: "20%", column: "interval" },
                                    { title: "Startet am", width: "15%", column: "startingAt" },
                                    { title: "Inaktiv", width: "10%", column: "inactive" }
                                ]}
                                onItemClick={item => this.updateBuildingInspectionTemplateIntervalVariationModal.open(item.data)}
                                contextItems={[
                                    {
                                        icon: "times",
                                        title: "Abweichung löschen",
                                        onClick: data => scanmetrix.client.mutate({
                                            mutation: scanmetrix.gql`
                                            mutation($id: ID!) {
                                                deleteBuildingInspectionTemplateIntervalVariation(id: $id)
                                            }
                                        `,
                                            variables: {
                                                id: data.id
                                            }
                                        }).then(() => this.fetch())
                                    }
                                ]}
                                items={buildingInspectionTemplate.intervalVariations.map(variation => ({
                                    data: variation,
                                    ...variation,
                                    interval: variation.intervalAmount === null || variation.intervalType === null ? <><i className="fas fa-exclamation-circle orange" /> Geerbt</> : `${variation.intervalAmount} ${getIntervalType(variation.intervalType)}`,
                                    inactive: variation.inactive ? <><i className="fas fa-check-circle red" /> Ja</> : <><i className="far fa-times-circle green" /> Nein</>,
                                    startingAt: variation.startingAt ? moment(variation.startingAt).format("DD.MM.YYYY") : <><i className="fas fa-exclamation-circle orange" /> Geerbt</>,
                                    subsidiary: `${variation.subsidiary.name}${variation.subsidiary.label ? (" / " + variation.subsidiary.label) : ""} / ${variation.subsidiary.address.streetName} ${variation.subsidiary.address.streetNumber}, ${variation.subsidiary.address.postalCode} ${variation.subsidiary.address.city}`
                                }))}
                            />
                        </GridElement>
                    </Grid>
                </Section.Item>
            </Section>
        </Page>
    }
}
