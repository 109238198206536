import React, { Component } from "react"
import { Modal, Input, Button, IconSelector } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { name: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={() => this.setState({ name: null, icon: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.users.sectionItem.createRoleModal.title")} icon="shield-halved" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16 }}>
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.users.sectionItem.createRoleModal.name")} adjustWidth />
                    <IconSelector value={this.state.icon} onChange={icon => this.setState({ icon })} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.icon} title={i18next.t("page.private.users.sectionItem.createRoleModal.exitButton")} icon="shield-halved" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $icon: String!) {
                            createRole(name: $name, icon: $icon)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        icon: this.state.icon
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createRole) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
