import React, { Component } from "react"
import { Modal, Button, Input, TextEditor } from "scanmetrix-components"
import countries from "i18n-iso-countries";
import moment from "moment"
import i18next from "i18next";
import isEmail from "validator/lib/isEmail";

export default class extends Component {
    state = { email: null, title: null, text: null, loading: false }

    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Modal minWidth="650px" instance={this.props.instance} initialize={approval => {
            this.setState({ approval, email: approval.email, loading: false, title: `${i18next.t("page.private.measure.cancelMeasureApprovalModal.text1")} ${this.props.measure.number}`, text: `<p>${i18next.t("page.private.measure.cancelMeasureApprovalModal.text2")}<br /><br />${i18next.t("page.private.measure.cancelMeasureApprovalModal.text3")} ${moment(approval.createdAt).format("DD.MM.YYYY")}.<br />${i18next.t("page.private.measure.cancelMeasureApprovalModal.text4")}<br /><br /><u>${i18next.t("page.private.measure.cancelMeasureApprovalModal.text5")}</u><br />${this.props.measure.subsidiary.legalName}<br />${this.props.measure.subsidiary.label ? (this.props.measure.subsidiary.label + "<br />") : ""}${this.props.measure.subsidiary.address.additional ? (this.props.measure.subsidiary.address.additional + "<br />") : ""}${this.props.measure.subsidiary.address.streetName} ${this.props.measure.subsidiary.address.streetNumber}<br />${this.props.measure.subsidiary.address.postalCode} ${this.props.measure.subsidiary.address.city}<br />${formattedCountries[this.props.measure.subsidiary.address.country] || this.props.measure.subsidiary.address.country}<br /><br /><br />${i18next.t("page.private.measure.cancelMeasureApprovalModal.text6")}<br /><br />${scanmetrix.session.firstName} ${scanmetrix.session.lastName}<br />${scanmetrix.session.email}<br />${this.props.measure.subsidiary.legalName}</p>` })
        }}>
            <Modal.Head title={i18next.t("page.private.measure.cancelMeasureApprovalModal.title")} icon="ban" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 24 }}>
                    <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.cancelMeasureApprovalModal.email")} icon="envelope" placeholder={i18next.t("page.private.measure.cancelMeasureApprovalModal.searchPlaceholderMustermann")} value={this.state.email} onChange={email => this.setState({ email })} required />
                    <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.cancelMeasureApprovalModal.subject")} icon="heading" placeholder={i18next.t("page.private.measure.cancelMeasureApprovalModal.searchPlaceholderPo2023")} value={this.state.title} onChange={title => this.setState({ title })} required />
                    <div style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
                        <TextEditor
                            readOnly={this.state.loading}
                            label={i18next.t("page.private.measure.cancelMeasureApprovalModal.Receiver")}
                            icon="font"
                            placeholder={i18next.t("page.private.measure.cancelMeasureApprovalModal.searchPlaceholderGreetings")}
                            value={this.state.text}
                            height={500}
                            onChange={text => this.setState({ text })}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={!this.state.email || !this.state.text || !this.state.title || !isEmail(this.state.email)} loading={this.state.loading} red thick title={i18next.t("page.private.measure.cancelMeasureApprovalModal.exitButton")} icon="ban" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $email: String!, $title: String!, $text: String!) {
                            cancelMeasureApproval(id: $id, email: $email, title: $title, text: $text)
                        }
                    `,
                    variables: {
                        id: this.state.approval.id,
                        email: this.state.email,
                        title: this.state.title,
                        text: this.state.text
                    }
                }).then(result => {
                    if(result.data.cancelMeasureApproval) {
                        this.props.refresh().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                })}
            } />} />
        </Modal>
    }
}
