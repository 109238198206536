import React, { Component } from "react"
import { Modal, Input, Button, GridElement, Grid, Checkbox, Section } from "scanmetrix-components"
import i18next from "i18next";

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/theme-xcode"
import "ace-builds/src-noconflict/ext-language_tools"

export default class extends Component {
    state = { name: null, bothPagesSame: true, module: null, firstPageHeader: null, firstPageFooter: null, secondPageHeader: null, secondPageFooter: null, marginLeft: 25, marginRight: 20, marginTop: 27, marginBottom: 40, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={() => this.setState({ name: null, bothPagesSame: true, firstPageHeader: null, firstPageFooter: null, secondPageHeader: null, secondPageFooter: null, marginLeft: 25, marginRight: 20, marginTop: 27, marginBottom: 40, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.createLetterHeadModal.title")} icon="scroll" />
            <Modal.Body padding={24}>
                <Grid gap="32px" columns={[ "1fr", "600px" ]}>
                    <GridElement>
                        <Grid gap="32px" columns={[ "1fr", "1fr" ]}>
                            <GridElement columnStart={1} columnEnd={3}>
                                <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.settings.modals.createLetterHeadModal.name")} adjustWidth />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={2}>
                                <Input required value={this.state.marginLeft} onChange={marginLeft => this.setState({ marginLeft })} icon="arrow-left-to-line" label={i18next.t("page.private.settings.modals.createLetterHeadModal.arrowLeft")} int format={val => val !== null ? `${val} mm` : ""} adjustWidth />
                            </GridElement>
                            <GridElement columnStart={2} columnEnd={3}>
                                <Input required value={this.state.marginRight} onChange={marginRight => this.setState({ marginRight })} icon="arrow-right-to-line" label={i18next.t("page.private.settings.modals.createLetterHeadModal.arrowRight")} int format={val => `${val} mm`} adjustWidth />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={2}>
                                <Input required value={this.state.marginTop} onChange={marginTop => this.setState({ marginTop })} icon="arrow-up-to-line" label={i18next.t("page.private.settings.modals.createLetterHeadModal.arrowTop")} int format={val => `${val} mm`} adjustWidth />
                            </GridElement>
                            <GridElement columnStart={2} columnEnd={3}>
                                <Input required value={this.state.marginBottom} onChange={marginBottom => this.setState({ marginBottom })} icon="arrow-down-to-line" label={i18next.t("page.private.settings.modals.createLetterHeadModal.arrowDown")} int format={val => `${val} mm`} adjustWidth />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={3}>
                                <AceEditor
                                    placeholder={i18next.t("page.private.settings.modals.createLetterHeadModal.htmlDescription")}
                                    mode="html"
                                    theme="xcode"
                                    value={this.state.module || ""}
                                    onChange={module => this.setState({ module })}
                                    name="module"
                                    editorProps={{ $blockScrolling: true }}
                                    style={{ width: "100%", height: "320px", border: "1px solid rgba(0, 0, 0, 0.1)" }}
                                />
                            </GridElement>
                        </Grid>
                    </GridElement>
                    <GridElement>
                        <Grid gap="32px">
                            <GridElement>
                                <Checkbox label={i18next.t("page.private.settings.modals.createLetterHeadModal.bothSideEqual")} value={this.state.bothPagesSame} onChange={bothPagesSame => this.setState({ bothPagesSame })} />
                            </GridElement>
                            <GridElement>
                                <Section>
                                    <Section.Item title={i18next.t("page.private.settings.modals.createLetterHeadModal.sideOneHead")} icon="border-top">
                                        <AceEditor
                                            placeholder={i18next.t("page.private.settings.modals.createLetterHeadModal.sideOneHeadHtml")}
                                            mode="html"
                                            theme="xcode"
                                            value={this.state.firstPageHeader || ""}
                                            onChange={firstPageHeader => this.setState({ firstPageHeader })}
                                            name="firstPageHeader"
                                            editorProps={{ $blockScrolling: true }}
                                            style={{ width: "100%" }}
                                        />
                                    </Section.Item>
                                    <Section.Item title={i18next.t("page.private.settings.modals.createLetterHeadModal.sideOneFoot")} icon="border-bottom">
                                        <AceEditor
                                            placeholder={i18next.t("page.private.settings.modals.createLetterHeadModal.sideOneFootHtml")}
                                            mode="html"
                                            theme="xcode"
                                            value={this.state.firstPageFooter || ""}
                                            onChange={firstPageFooter => this.setState({ firstPageFooter })}
                                            name="firstPageFooter"
                                            editorProps={{ $blockScrolling: true }}
                                            style={{ width: "100%" }}
                                        />
                                    </Section.Item>
                                    <Section.Item title={i18next.t("page.private.settings.modals.createLetterHeadModal.sideTwoHead")} icon="border-top" disabled={this.state.bothPagesSame}>
                                        <AceEditor
                                            placeholder={i18next.t("page.private.settings.modals.createLetterHeadModal.sideTwoHeadHtml")}
                                            mode="html"
                                            theme="xcode"
                                            value={this.state.secondPageHeader || ""}
                                            onChange={secondPageHeader => this.setState({ secondPageHeader })}
                                            name="secondPageHeader"
                                            editorProps={{ $blockScrolling: true }}
                                            style={{ width: "100%" }}
                                        />
                                    </Section.Item>
                                    <Section.Item title={i18next.t("page.private.settings.modals.createLetterHeadModal.sideTwoFoot")} icon="border-bottom" disabled={this.state.bothPagesSame}>
                                        <AceEditor
                                            placeholder={i18next.t("page.private.settings.modals.createLetterHeadModal.sideTwoFootHtml")}
                                            mode="html"
                                            theme="xcode"
                                            value={this.state.secondPageFooter || ""}
                                            onChange={secondPageFooter => this.setState({ secondPageFooter })}
                                            name="secondPageFooter"
                                            editorProps={{ $blockScrolling: true }}
                                            style={{ width: "100%" }}
                                        />
                                    </Section.Item>
                                </Section>
                            </GridElement>
                        </Grid>
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name} title={i18next.t("page.private.settings.modals.createLetterHeadModal.exitButton")} icon="scroll" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $module: String, $bothPagesSame: Boolean!, $firstPageHeader: String, $firstPageFooter: String, $secondPageHeader: String, $secondPageFooter: String, $marginLeft: Int!, $marginRight: Int!, $marginTop: Int!, $marginBottom: Int!) {
                            createLetterHead(name: $name, module: $module, bothPagesSame: $bothPagesSame, firstPageHeader: $firstPageHeader, firstPageFooter: $firstPageFooter, secondPageHeader: $secondPageHeader, secondPageFooter: $secondPageFooter, marginLeft: $marginLeft, marginRight: $marginRight, marginTop: $marginTop, marginBottom: $marginBottom)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        bothPagesSame: this.state.bothPagesSame,
                        firstPageHeader: this.state.firstPageHeader,
                        firstPageFooter: this.state.firstPageFooter,
                        secondPageHeader: this.state.secondPageHeader,
                        secondPageFooter: this.state.secondPageFooter,
                        marginLeft: this.state.marginLeft,
                        marginRight: this.state.marginRight,
                        marginTop: this.state.marginTop,
                        marginBottom: this.state.marginBottom,
                        module: this.state.module
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createLetterHead) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
