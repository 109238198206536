import React, { Component } from 'react'
import styled from 'styled-components'
import { Page, Modal, Button, Input, Breadcrumbs, Grid, GridElement, Section, Table } from 'scanmetrix-components'

const StyledProfilePicture = styled.div`
  width: 100%;
  height: 256px;
  background: url('https://images.unsplash.com/photo-1570476384087-32a3efa251c8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`

const StyledImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.3s;
  pointer-events: ${props => props.none ? "none !important" : "all"};
  
  &:hover {
      opacity: 0.75;
  }
`

class InputMap extends Component {
    render() {
        return <>
            { this.props.data.map(( item, index ) => {
                return <GridElement key={index} columnStart={item.columnStart} columnEnd={item.columnEnd}>
                    <Input adjustWidth label={item.label} icon={item.icon} value={item.value} readOnly />
                </GridElement>
            }) }
        </>
    }
}

export default class extends Component {
    state = { editProfile: false }

    render() {
        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "car",
                    title: "Fuhrpark",
                    link: "/carpool"
                },
                {
                    icon: "car-garage",
                    title: "Fahreug: XYZ",
                    link: "/carpool/car"
                },
                {
                    icon: "user",
                    title: `Fahrzeugführer: ${ this.state.holder }`
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title="Fahrzeughalter" icon="user-tie">
                    <Grid gap="32px" columns={[ "2fr", "1fr", "1fr" ]}>
                        <GridElement styled title="Allgemeine Informationen" icon="id-card" columnStart={1} columnEnd={3} rightContent={<>
                        { !this.state.editProfile && <Button thick title='Halterinformationen bearbeiten' icon='pencil-alt' onClick={() => this.setState({ editProfile: true })} /> }
                        { this.state.editProfile && <Button thick green title='Änderungen speichern' icon='check' onClick={() => this.setState({ editProfile: false })} /> }
                        </>}>
                            <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                                <InputMap data={
                                    [
                                        { columnStart: 1, columnEnd: 4, label: 'Vorname', icon: 'font', value: 'Max' },
                                        { columnStart: 4, columnEnd: 7, label: 'Nachname', icon: 'font', value: 'Mustermann' },
                                        { columnStart: 1, columnEnd: 7, label: 'Adresse', icon: 'map-marked', value: 'Ostackerweg 123, 47166 Duisburg' },
                                        { columnStart: 1, columnEnd: 3, label: 'E-Mail', icon: 'at', value: 'k.kuzu@scanmetrix.app' },
                                        { columnStart: 3, columnEnd: 5, label: 'Telefonnummer', icon: 'phone', value: '0252128416' },
                                        { columnStart: 5, columnEnd: 7, label: 'Mobilfunknummer', icon: 'mobile', value: '01728779946' },
                                    ]
                                } />
                            </Grid>
                        </GridElement>

                        <GridElement styled title="Bild" icon="image" columnStart={3} columnEnd={4}>
                            <StyledProfilePicture />
                        </GridElement>

                        <GridElement styled title="Führerschein" icon="id-badge" columnStart={1} columnEnd={3}>
                            <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                                <InputMap data={
                                    [
                                        { columnStart: 1, columnEnd: 4, label: 'Vorname', icon: 'font', value: 'Max Mustermann' },
                                        { columnStart: 4, columnEnd: 7, label: 'Nachname', icon: 'font', value: 'Kuzu' },
                                        { columnStart: 1, columnEnd: 7, label: 'Adresse', icon: 'map-marked', value: 'Ostackerweg 123, 47166 Duisburg' },
                                        { columnStart: 1, columnEnd: 3, label: 'Geburtsdatum', icon: 'birthday-cake', value: '19.04.2000' },
                                        { columnStart: 3, columnEnd: 5, label: 'Zugelassen seit', icon: 'calendar-check', value: '20.03.2017' },
                                        { columnStart: 5, columnEnd: 7, label: 'Gültig bis', icon: 'calendar-times', value: '19.03.2032' },
                                        { columnStart: 1, columnEnd: 4, label: 'Führerscheinnummer', icon: 'list-ol', value: 'J02005XXZ91' },
                                        { columnStart: 4, columnEnd: 7, label: 'Erlaubte Fahrzeugklassen', icon: 'cars', value: 'AM / B / L' },
                                    ]
                                } />
                            </Grid>
                        </GridElement>
                    </Grid>
                </Section.Item>
            </Section>
        </Page>
    }
}