import React, { Component } from "react"
import styled from "styled-components"

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    user-select: none;

    .icon {
        top: 0;
        bottom: 0;
        left: 0;
        color: rgba(0, 0, 0, 0.4);
    }
`

const Input = styled.input`
    outline: 0;
    border: none;
    height: 48px;
    padding-left: 16px;
    width: ${props => props.width || "auto"};
    font-size: 1.05em;
    color: gray;

    &::placeholder {
        color: rgba(0, 0, 0, 0.4);
    }
`

export default class extends Component {
    render() {
        return <InputContainer>
            {this.props.icon && <i className={`far icon fa-${this.props.icon}`} />}
            <Input
                {...this.props}
                autoComplete="false"
                autoCorrect="false"
                autoCapitalize="false"
                spellCheck="false"
            />
        </InputContainer>
    }
}