import React, { Component } from "react"
import { Modal, Button, Select } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { factSheetId: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ factSheetId: null, loading: false })
        }}>
            <Modal.Head title={i18next.t("page.private.subsidiary.createSubsidiaryFactSheetModal.title")} icon="file-spreadsheet" />
            <Modal.Body padding={24} adjustHeight="328px">
                <Select
                    adjustWidth
                    label={i18next.t("page.private.subsidiary.createSubsidiaryFactSheetModal.factSheetLabel")}
                    icon="file-spreadsheet"
                    value={this.state.factSheetId}
                    required
                    onChange={factSheetId => this.setState({ factSheetId })}
                    noUnselect
                    items={this.props.factSheets.map(factSheet => ({ key: factSheet.id, title: factSheet.name, icon: factSheet.icon }))}
                />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.factSheetId} title={i18next.t("page.private.subsidiary.createSubsidiaryFactSheetModal.exitButton")} icon="file-spreadsheet" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($factSheetId: ID!, $subsidiaryId: ID!) {
                            createSubsidiaryFactSheet(factSheetId: $factSheetId, subsidiaryId: $subsidiaryId)
                        }
                    `,
                    variables: {
                        factSheetId: this.state.factSheetId,
                        subsidiaryId: this.props.subsidiaryId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createSubsidiaryFactSheet) {
                        instance.close()
                        this.props.refresh()
                    }
                })}
            } />} />
        </Modal>
    }
}
