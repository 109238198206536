import React, { Component } from "react"
import { Modal, Input, Button, Select, Checkbox } from "scanmetrix-components"
import countries from "i18n-iso-countries";
import i18next from "i18next"

export default class extends Component {
    state = { createServiceProvider: false, title: null, type: null, serviceProviderId: null, serviceProviders: [], letterHeads: [], letterHeadId: null, loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    ServiceProviders {
                        nodes {
                            id
                            name
                            address {
                                streetName
                                streetNumber
                                additional
                                postalCode
                                city
                                country
                            }
                        }
                    }
                    LetterHeads {
                        nodes {
                            id
                            name
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ serviceProviders: data.data.ServiceProviders.nodes, letterHeads: data.data.LetterHeads.nodes })
        })
    }

    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({
            title: null,
            type: null,
            serviceProviderId: null,
            letterHeadId: null,
            loading: false,
            createServiceProvider: false,
            partnerName: null,
            addressStreetName: null,
            addressStreetNumber: null,
            responsibility: null,
            addressAdditional: null,
            addressPostalCode: null,
            addressCity: null,
            addressCountry: null,
        })}>
            <Modal.Head title={i18next.t("page.private.workOrders.createPurchaseOrderModal.titleForm")} icon="comment-alt-check" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Select adjustWidth value={this.state.letterHeadId} onChange={letterHeadId => this.setState({ letterHeadId })} icon="scroll" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.letterHead")} items={this.state.letterHeads.map(letterHead => ({
                        key: letterHead.id,
                        title: letterHead.name
                    }))} />
                    <Select required adjustWidth value={this.state.type} onChange={type => this.setState({ type })} icon="wrench" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.type")} items={[
                        { key: "service", title: i18next.t("page.private.workOrders.createPurchaseOrderModal.typeTypes.service"), icon: "hard-hat" },
                        { key: "construction", title: i18next.t("page.private.workOrders.createPurchaseOrderModal.typeTypes.construction"), icon: "digging" },
                        { key: "goods", title: i18next.t("page.private.workOrders.createPurchaseOrderModal.typeTypes.goods"), icon: "truck-loading" },
                        { key: "other", title: i18next.t("page.private.workOrders.createPurchaseOrderModal.typeTypes.other"), icon: "question" },
                    ]} />
                    <Input required value={this.state.title} onChange={title => this.setState({ title })} icon="font" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.title")} adjustWidth />
                    {this.state.createServiceProvider && <Modal.Divider title="Dienstleister" />}
                    {!this.state.createServiceProvider && <Select required upwards value={this.state.serviceProviderId} onChange={(serviceProviderId, serviceProvider) => {
                        if(!serviceProviderId) this.setState({ serviceProviderId })
                        else this.setState({
                            serviceProviderId,
                            partnerName: this.state.partnerName || serviceProvider.data.name,
                            addressStreetName: this.state.addressStreetName || serviceProvider.data.address.streetName,
                            addressStreetNumber: this.state.addressStreetNumber || serviceProvider.data.address.streetNumber,
                            addressPostalCode: this.state.addressPostalCode || serviceProvider.data.address.postalCode,
                            addressCity: this.state.addressCity || serviceProvider.data.address.city,
                            addressCountry: this.state.addressCountry || serviceProvider.data.address.country,
                            addressAdditional: this.state.addressAdditional || serviceProvider.data.address.additional,
                            createServiceProvider: false
                        })
                    }} icon="user-tie" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.serviceProvider")} adjustWidth items={this.state.serviceProviders.map(serviceProvider => ({ key: serviceProvider.id, title: (serviceProvider.address.postalCode && serviceProvider.address.city) ? `${serviceProvider.name} / ${serviceProvider.address.postalCode} ${serviceProvider.address.city}` : serviceProvider.name, data: serviceProvider }))} />}
                    {!this.state.serviceProviderId && <Checkbox label={i18next.t("page.private.workOrders.createPurchaseOrderModal.checkbox")} value={this.state.createServiceProvider} onChange={createServiceProvider => this.setState({ createServiceProvider, serviceProviderId: null })} />}
                    {this.state.createServiceProvider && <>
                        <Input required value={this.state.partnerName} onChange={partnerName => this.setState({ partnerName })} icon="user-secret" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.serviceProviderName")} adjustWidth />
                        <Input required value={this.state.responsibility} onChange={responsibility => this.setState({ responsibility })} icon="hard-hat" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.Responsibility")} adjustWidth />
                        <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                            <Input adjustWidth label={i18next.t("page.private.workOrders.createPurchaseOrderModal.email")} icon="at" value={this.state.contactEmail} onChange={contactEmail => this.setState({ contactEmail })} />
                            <Input adjustWidth label={i18next.t("page.private.workOrders.createPurchaseOrderModal.phoneNumber")} icon="phone" value={this.state.contactTelephone} onChange={contactTelephone => this.setState({ contactTelephone })} />
                            <Input adjustWidth label={i18next.t("page.private.workOrders.createPurchaseOrderModal.fax")} icon="fax" value={this.state.contactTelefax} onChange={contactTelefax => this.setState({ contactTelefax })} />
                        </div>
                        <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "2fr 0.5fr" }}>
                            <Input required value={this.state.addressStreetName} onChange={addressStreetName => this.setState({ addressStreetName })} icon="road" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.street")} adjustWidth />
                            <Input required value={this.state.addressStreetNumber} onChange={addressStreetNumber => this.setState({ addressStreetNumber })} icon="bell" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.houseNumber")} adjustWidth />
                        </div>
                        <Input value={this.state.addressAdditional} onChange={addressAdditional => this.setState({ addressAdditional })} icon="font" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.additionalAddress")} adjustWidth />
                        <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 2fr" }}>
                            <Input required value={this.state.addressPostalCode} onChange={addressPostalCode => this.setState({ addressPostalCode })} icon="mailbox" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.postalCode")} adjustWidth />
                            <Input required value={this.state.addressCity} onChange={addressCity => this.setState({ addressCity })} icon="city" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.city")} adjustWidth />
                        </div>
                        <Select upwards required adjustWidth value={this.state.addressCountry} onChange={addressCountry => this.setState({ addressCountry })} icon="globe-europe" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.country")} items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
                    </>}
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.title || (!this.state.createServiceProvider && !this.state.serviceProviderId) || !this.state.type || (!this.state.serviceProviderId && (!this.state.partnerName || !this.state.addressStreetName || !this.state.addressStreetNumber || !this.state.addressPostalCode || !this.state.addressCity || !this.state.addressCountry))} title={i18next.t("page.private.workOrders.createPurchaseOrderModal.exitButton")} icon="comment-alt-check" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($title: String!, $type: String!, $subsidiaryId: ID!, $measureId: ID, $serviceProviderId: ID, $letterHeadId: ID, $partnerName: String, $addressStreetName: String, $addressStreetNumber: String, $addressAdditional: String, $addressPostalCode: String, $addressCity: String, $addressCountry: String, $responsibility: String, $contactEmail: String, $contactTelefax: String, $contactTelephone: String) {
                            createPurchaseOrder(title: $title, type: $type, subsidiaryId: $subsidiaryId, measureId: $measureId, serviceProviderId: $serviceProviderId, letterHeadId: $letterHeadId, partnerName: $partnerName, addressStreetName: $addressStreetName, addressStreetNumber: $addressStreetNumber, addressAdditional: $addressAdditional, addressPostalCode: $addressPostalCode, addressCity: $addressCity, addressCountry: $addressCountry, responsibility: $responsibility, contactEmail: $contactEmail, contactTelephone: $contactTelephone, contactTelefax: $contactTelefax)
                        }
                    `,
                    variables: {
                        title: this.state.title,
                        type: this.state.type,
                        subsidiaryId: this.props.subsidiary.id,
                        measureId: this.props.measure.id,
                        serviceProviderId: this.state.createServiceProvider ? null : this.state.serviceProviderId,
                        letterHeadId: this.state.letterHeadId,
                        partnerName: this.state.partnerName,
                        addressStreetName: this.state.addressStreetName,
                        addressStreetNumber: this.state.addressStreetNumber,
                        addressAdditional: this.state.addressAdditional,
                        addressPostalCode: this.state.addressPostalCode,
                        addressCity: this.state.addressCity,
                        addressCountry: this.state.addressCountry,
                        responsibility: this.state.createServiceProvider ? this.state.responsibility : null,
                        contactEmail: this.state.contactEmail,
                        contactTelefax: this.state.contactTelefax,
                        contactTelephone: this.state.contactTelephone
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createPurchaseOrder) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
