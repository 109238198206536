import React, { Component } from "react"
import { Page, Grid, GridElement, Input, Button, Breadcrumbs, Table, Modal, DataForm, Section } from "scanmetrix-components"
import styled from "styled-components"
import QRCode from "qrcode.react"

import * as THREE from "three"
import { Canvas } from "react-three-fiber"
import { OrbitControls, Cylinder } from "@react-three/drei"
import Dropzone, {useDropzone} from 'react-dropzone'
import { v4 as uuid } from "uuid"

import ManualGalleryModal from './styled/ManualGalleryModal'
import { Item} from "react-contexify";
import {Select} from "../../../components";
import i18next from "i18next";

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0;
  flex-wrap: wrap;
  
  p.smid {
      width: 100%;
      text-align: center;
      margin-top: 24px;
      font-size: 1.5em;
  }
  
  p.subtitle {
      width: 100%;
      text-align: center;
      font-size: 1em;
      opacity: 0.75;
  }
`

const StyledImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.3s;
  pointer-events: ${props => props.none ? "none !important" : "all"};
  
  &:hover {
      opacity: 0.75;
  }
`

const UploadBody = styled.div`
  flex: 1;
  width: 100%;
  height: 300px;
  border: 3px dashed rgba(0, 0, 0, .25);
  color: rgba(0, 0, 0, .25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 250ms ease;
  position: relative;

  &:hover {
    opacity: 0.75;
  }
  
  input {
    display: none;
  }

  i {
    font-size: 2em;
    margin-bottom: 16px;
  }

  p {
    font-size: 1em;
    text-align: center;
    width: 75%;
  }
`

const ImageBox = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 250ms ease;
  
  &:hover {
    opacity: 0.75;
    
    .delete-hover {
      opacity: 1;
    }
  }
  
  img {
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
    box-sizing: border-box;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
    user-drag: none;
  }
  
  .delete-hover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease;
    opacity: 0;
    z-index: 15;
    
    i {
      padding: 16px;
      border-radius: 125px;
      background: #E74C3C;
      color: white;
      font-size: 2em;
    }
  }
`

const WarningBox = styled.div`
  background: linear-gradient(45deg, rgb(230, 126, 34), rgb(250, 182, 121));
  border-radius: 5px;
  padding: 16px;
  color: white;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
  user-select: none;
  box-sizing: border-box;
  
  i {
    font-size: 2em;
    margin-bottom: 16px;
  }
  
  p {
    text-align: center;
  }
`

const SingleAmount = styled.div`
  background: ${props => props.light ? "white" : (props.green ? "linear-gradient(45deg,#16a085,#27dbb8)" : (props.red ? "linear-gradient(45deg,#c0392b,#ff6252)" : props.orange ? "linear-gradient(45deg, rgb(230, 126, 34), rgb(250, 182, 121))" : "linear-gradient(45deg,#7d7d7d,#9e9e9e)"))};
  border-radius: 5px;
  grid-column-start: ${props => props.columnStart};
  grid-column-end: ${props => props.columnEnd};
  padding: 16px;
  box-sizing: border-box;
  color: ${props => props.light ? "#000" : "white"};
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, 0.4);
  display: ${props => props.flex ? "flex" : "block"};
  align-items: center;
  cursor: ${props => props.active ? "pointer" : "default"};
  transition: opacity 0.3s;
  user-select: none;
  
  &:hover {
      opacity: ${props => props.active ? 0.75 : 1};
  }
  
  .value {
    font-size: 2.2em;
    color: ${props => props.light ? "#869ead" : "white"};
    margin-bottom: ${props => props.flex ? 0 : "8px"};
    margin-right: ${props => props.flex ? "16px" : 0};
    
    b {
      color: ${props => props.light ? "#647A88" : "rgba(255, 255, 255, 0.75)"};
      font-weight: bold;
    }
  }
  
  .title {
    font-size: 1.3em;
    opacity: ${props => props.light ? 0.75 : 1};
  }
`

const Plan = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
  display: block;
`

const FullSizeImage = styled.img`
  max-width: 768px;
  max-height: 768px;
  object-fit: cover;
  display: block;
`

const ButtonView = styled.div`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

class MaintenancesModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            <Modal.Head title={i18next.t("page.private.object.pastMaintenance.title")} icon="toolbox" />
            <Modal.Body>
                <Table
                    head={[
                        { title: i18next.t("page.private.object.pastMaintenance.table.workOrder"), width: "15%", column: "workOrder" },
                        { title: i18next.t("page.private.object.pastMaintenance.table.date"), width: "15%", column: "date" },
                        //{ title: "Tätigkeit", width: "20%", column: "activity" },
                        { title: i18next.t("page.private.object.pastMaintenance.table.technician"), width: "45%", column: "technician" },
                        { title: i18next.t("page.private.object.pastMaintenance.table.operative"), width: "25%", column: "operative" }
                    ]}
                    onItemClick={maintenance => this.props.history.push(`/maintenance/${this.props.match.params.serviceproviderid}/${maintenance.id}`)}
                    items={this.props.maintenances.map(maintenance => ({
                        id: maintenance.id,
                        workOrder: maintenance.workOrder.number,
                        date: maintenance.date,
                        operative: <><i className={`far fa-${!!maintenance.operative ? "check-circle green" : (maintenance.operative === false ? "times-circle red" : "question-circle")}`} />{!!maintenance.operative ? i18next.t("page.private.object.pastMaintenance.operationReady") : (maintenance.operative === "false" ? i18next.t("page.private.object.pastMaintenance.outOfFunction") : i18next.t("page.private.object.pastMaintenance.notSpecified"))}</>,
                        //activity: <><i className={`far fa-${maintenance.activity.icon}`} />{maintenance.activity.description}</>,
                        technician: Table.DisplayUser(`${maintenance.user.firstName} ${maintenance.user.lastName}`, maintenance.user.avatarData)
                    }))}
                />
            </Modal.Body>
        </Modal>
    }
}

class ReportFlawModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            <Modal.Head title={i18next.t("page.private.object.reportDefect.title")} icon="bolt" />
            <Modal.Body>
                <Grid padding="32px" gap="32px" columns={[ "1fr" ]}>
                    <GridElement>
                        <Input adjustWidth label={i18next.t("page.private.object.reportDefect.defectName")} icon="bolt" />
                    </GridElement>
                    <GridElement>
                        <Input textArea adjustWidth label={i18next.t("page.private.object.reportDefect.detailedDescription")} icon="font" />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={modal => [ <Button onClick={() => modal.close()} icon="paper-plane" title={i18next.t("page.private.object.reportDefect.exitButton")} /> ]} />
        </Modal>
    }
}

const renderIcon = type => {
    let icon = "question"

    if(type === "structural") icon = "construction"
    if(type === "organisational") icon = "project-diagram"
    if(type === "objectional") icon = "folder-tree"

    return icon
}

const getType = type => {
    if(type === "structural") return "Baulich"
    if(type === "organisational") return "Organisatorisch"
    if(type === "objectional") return "Anlagentechnisch"
}

class PhotoModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            {context => <>
                <Modal.Head title={i18next.t("page.private.object.viewImage")} icon="images" />
                <Modal.Body>
                    <FullSizeImage src={context} />
                </Modal.Body>
            </>}
        </Modal>
    }
}

class ComponentModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            {context => <>
                <Modal.Head title={i18next.t("page.private.object.component")} icon="puzzle-piece" />
                <Modal.Body>
                    <DataForm items={JSON.parse(context.items)} data={context.data} />
                </Modal.Body>
            </>}
        </Modal>
    }
}

class NewComponentModal extends Component {
    render() {
        return <Modal instance={this.props.instance} minWidth="768px">
            {context => <>
                <Modal.Head title={i18next.t("page.private.object.componentForm.title")} icon="puzzle-piece" />
                <Modal.Body padding={32}>
                    <div className='modal-body' style={{ width: '100%', minHeight: '37.5vh' }}>
                        <Select label={i18next.t("page.private.object.componentForm.trade")} icon='building' adjustWidth data={[
                            { key: 'first', title: i18next.t("page.private.object.componentForm.testTrade") }
                        ]} />
                        <div className='grid-view' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 32, marginTop: 32 }}>
                            <Select label={i18next.t("page.private.object.componentForm.component")} icon='puzzle-piece' adjustWidth data={[
                                { key: 'fireExtinguisher', title: i18next.t("page.private.object.componentForm.fireExtinguisher") }
                            ]} />
                            <Input label={i18next.t("page.private.object.componentForm.quantity")} icon='sort-numeric-up' adjustWidth int />
                        </div>
                    </div>
                </Modal.Body>
            </>}
        </Modal>
    }
}

class FlawsModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            <Modal.Head title={i18next.t("page.private.object.defectsForm.title")} icon="bolt" />
            <Modal.Body>
                <Table
                    head={[
                        { title: i18next.t("page.private.object.defectsForm.defects"), width: "17.5%", column: "name" },
                        { title: i18next.t("page.private.object.defectsForm.description"), width: "25%", column: "description" },
                        { title: i18next.t("page.private.object.defectsForm.recorded"), width: "10%", column: "createdAt" },
                        { title: i18next.t("page.private.object.defectsForm.priority"), width: "10%", column: "priority" },
                        { title: i18next.t("page.private.object.defectsForm.deadline"), width: "10%", column: "deadline" },
                        { title: i18next.t("page.private.object.defectsForm.type"), width: "17.5%", column: "type" },
                        { title: i18next.t("page.private.object.defectsForm.condition"), width: "10%", column: "state" }
                    ]}
                    onItemClick={flaw => this.props.history.push(`/flaw/${this.props.match.params.serviceproviderid}/${flaw.id}`)}
                    items={this.props.flaws.map(flaw => ({
                        id: flaw.id,
                        name: flaw.name,
                        description: flaw.description,
                        deadline: flaw.deadline,
                        createdAt: flaw.maintenance.date,
                        priority: <><i className={`fas fa-${flaw.priority === 1 ? "check-square green" : (flaw.priority === 2 ? "exclamation-square orange" : "times-square red")}`} />{flaw.priority === 1 ? i18next.t("page.private.object.defectsForm.priorityTypes.low") : (flaw.priority === 2 ? i18next.t("page.private.object.defectsForm.priorityTypes.medium") : i18next.t("page.private.object.defectsForm.priorityTypes.high"))}</>,
                        state: <><i className={`far fa-${flaw.fixedInMaintenance ? "check-circle green" : "times-circle red"}`} />{flaw.fixedInMaintenance ? i18next.t("page.private.object.defectsForm.fixedInMaintenance.fixed") : i18next.t("page.private.object.defectsForm.fixedInMaintenance.open")}</>,
                        type: <><i className={`far fa-${renderIcon(flaw.type)}`} />{getType(flaw.type)}</>
                    }))}
                />
            </Modal.Body>
        </Modal>
    }
}

const getIntervalType = type => {
    if(type === "days") return i18next.t("page.private.object.defectsForm.getIntervalType.days")
    if(type === "weeks") return i18next.t("page.private.object.defectsForm.getIntervalType.weeks")
    if(type === "months") return i18next.t("page.private.object.defectsForm.getIntervalType.months")
    if(type === "years") return i18next.t("page.private.object.defectsForm.getIntervalType.years")
}

class Plan3D extends Component {
    state = { dimensions: null }

    render() {
        const plan = this.props.plan
        if(!plan.imageData) return null

        if(!this.loading) {
            const img = new Image()
            img.src = plan.imageData

            img.onload = () => {
                this.setState({ dimensions: { width: img.width, height: img.height } })
            }

            this.loading = true
        }

        if(!this.state.dimensions) return null

        let texture = new THREE.TextureLoader().load(plan.imageData)
        texture.wrapS = THREE.RepeatWrapping
        texture.wrapT = THREE.RepeatWrapping

        let material = new THREE.MeshLambertMaterial({ map: texture })

        const factor = 0.1

        let plane = new THREE.Mesh(new THREE.PlaneGeometry(this.state.dimensions.width * factor, this.state.dimensions.height * factor), material)
        plane.material.side = THREE.DoubleSide
        plane.frustumCulled = false
        plane.receiveShadow = true

        plane.rotation.x = -Math.PI / 2

        var spotLight = new THREE.SpotLight( 0xffffff, 1.5 );
        spotLight.position.set( 0, 150, 0 );

        spotLight.castShadow = true;

        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;

        spotLight.shadow.mapSize.width = 5120 // default
        spotLight.shadow.mapSize.height = 5120 // default
        spotLight.shadow.camera.near = 0.1 // default
        spotLight.shadow.camera.far = 500 // default
        spotLight.shadow.camera.top = -100 // default
        spotLight.shadow.camera.right = 100 // default
        spotLight.shadow.camera.left = -100 // default
        spotLight.shadow.camera.bottom = 100 // default

        const customerObject = this.props.customerObject

        return <>
            <Canvas style={{ width: "100%", height: 334, outline: 0, background: "radial-gradient(#ffffff, #d9f2ff, #5fa7f6)" }} onCreated={({ gl, scene }) => {
                gl.shadowMap.enabled = true
                gl.shadowMap.type = THREE.PCFSoftShadowMap
            }}>
                <ambientLight intensity={0.2} />
                <primitive object={spotLight} />
                <Cylinder receiveShadow castShadow position={[-this.state.dimensions.width * factor * 0.5 + (this.state.dimensions.width * factor * this.props.planX), -45, -this.state.dimensions.height * factor * 0.5 + 10 + (this.state.dimensions.height * factor * this.props.planY)]} args={[3, 5, 15, 48]}>
                    <meshStandardMaterial attach="material" color="#3b97d3" />
                </Cylinder>)
                <primitive object={plane} position={[0, -45, 0]} />
                <OrbitControls position={new THREE.Vector3(20, 200, 20)} target={new THREE.Vector3(-this.state.dimensions.width * factor * 0.5 + (this.state.dimensions.width * factor * this.props.planX), -45,  -this.state.dimensions.height * factor * 0.5 + 10 + (this.state.dimensions.height * factor * this.props.planY))} />
            </Canvas>
        </>
    }
}

export default class extends Component {
    state = {
        customerObject: null,
        manuallyCreated: false, //TODO
        editObject: false,
        loading: false,
        fileName: null,
        fileSize: null,
        files: []
    }

    constructor(props) {
        super(props)

        /*scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    customerObject(id: $id) {
                        id
                        operative
                        positionDescription
                        notes
                        intervalAmount
                        intervalType
                        inheritInterval
                        nextInspectionDate
                        customerSubsidiaryFacility {
                            id
                            name
                            intervalAmount
                            intervalType
                            building
                            nextInspectionDate
                            customerSubsidiary {
                                id
                                name
                            }
                        }
                        maintenances {
                            id
                            date
                            operative
                            workOrder {
                                number
                            }
                            user {
                                firstName
                                lastName
                                profileImageURL
                            }
                            activity {
                                id
                                description
                                icon
                            }
                            flaws {
                                id
                                name
                                description
                                type
                                priority
                                deadline
                                images {
                                    id
                                    imageURL
                                }
                                maintenance {
                                    id
                                    date
                                }
                                fixedInMaintenance {
                                    id
                                }
                            }
                            images {
                                id
                                imageURL
                            }
                        }
                        mainCustomerObjectComponent {
                            id
                            accountingArticle {
                                id
                                name
                                manufacturer {
                                    id
                                    name
                                }
                            }
                            component {
                                id
                                name
                            }
                        }
                        components {
                            id
                            amount
                            data
                            accountingArticle {
                                id
                                name
                                manufacturer {
                                    id
                                    name
                                }
                            }
                            component {
                                id
                                name
                                layout
                            }
                        }
                        smid {
                            id
                            digital
                        }
                    }
                }
            `,
            variables: {
                id: props.match.params.objectid
            }
        }).then(data => this.setState({ customerObject: data.data.customerObject }))*/

        fetch(scanmetrix.backendURL + "/object/" + this.props.match.params.serviceproviderid + "/" + this.props.match.params.objectid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ customerObject: data })
        })
    }

    render() {
        const customerObject = this.state.customerObject
        if(!customerObject) return null

        let images = []

        customerObject.maintenances.forEach(maintenance => {
            maintenance.images.forEach(image => {
                images.push(`${scanmetrix.backendURL}/maintenance-image/${this.props.match.params.serviceproviderid}/${image.id}`)
            })

            maintenance.flaws.map(flaw => flaw.images).flat().forEach(image => {
                images.push(`${scanmetrix.backendURL}/flaw-image/${this.props.match.params.serviceproviderid}/${image.id}`)
            })
        })

        let imageStacks = []

        const mainCustomerObjectComponent = customerObject.mainCustomerObjectComponent

        let length = images.length

        for(let i = 0; i < length; i += 5) {
            imageStacks.push(images.slice(i, i + 5))
        }

        if(!imageStacks.length) imageStacks = [[]]

        imageStacks.forEach(stack => {
            if(stack.length < 5) {
                let intialStackLength = stack.length
                for(var i = 0; i < (5 - intialStackLength); i++) stack.push(null)
            }
        })

        let lastMaintenanceDate = null

        customerObject.maintenances.forEach(maintenance => {
            let date = moment(maintenance.date, "DD.MM.YYYY")

            if(!lastMaintenanceDate) lastMaintenanceDate = date
            else if(date > lastMaintenanceDate) lastMaintenanceDate = date
        })

        let nextInspectionDate = null

        if(customerObject.inheritInterval) {
            nextInspectionDate = customerObject.customerSubsidiaryFacility.nextInspectionDate ? moment(customerObject.customerSubsidiaryFacility.nextInspectionDate, "DD.MM.YYYY") : null
        } else {
            nextInspectionDate = customerObject.nextInspectionDate ? moment(customerObject.nextInspectionDate, "DD.MM.YYYY") : null
        }

        let interval = null

        if(customerObject.inheritInterval) {
            if(customerObject.customerSubsidiaryFacility.intervalAmount) {
                interval = `${customerObject.customerSubsidiaryFacility.intervalAmount} ${getIntervalType(customerObject.customerSubsidiaryFacility.intervalType)}`
            }
        } else {
            if(customerObject.intervalAmount) {
                interval = `${customerObject.intervalAmount} ${getIntervalType(customerObject.intervalType)}`
            }
        }

        return <Page {...this.props}>
            <MaintenancesModal history={this.props.history} match={this.props.match} maintenances={customerObject.maintenances} instance={ref => this.maintenancesModal = ref} />
            <FlawsModal match={this.props.match} history={this.props.history} flaws={customerObject.maintenances.map(maintenance => maintenance.flaws).flat()} instance={ref => this.flawsModal = ref} />
            <PhotoModal instance={ref => this.photoModal = ref} />
            <ComponentModal instance={ref => this.componentModal = ref} />
            <NewComponentModal instance={ref => this.newComponentModal = ref} />
            <ReportFlawModal instance={ref => this.reportFlawModal = ref} />
            <ManualGalleryModal instance={ref => this.manualGalleryModal = ref} src='https://images.unsplash.com/photo-1613575607467-83d3ad295710?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80' />
            <Breadcrumbs values={[
                {
                    icon: "building",
                    title: i18next.t("page.private.object.breadCrumbsTitle"),
                    link: "/facilitymanagement"
                },
                {
                    icon: "map-marker",
                    title: `${customerObject.customerSubsidiaryFacility.customerSubsidiary.name} (${customerObject.customerSubsidiaryFacility.customerSubsidiary.label})`,
                    link: `/subsidiary/${customerObject.customerSubsidiaryFacility.customerSubsidiary.id}`
                },
                {
                    icon: "folder-tree",
                    title: `${customerObject.customerSubsidiaryFacility.name} (Gebäude „${customerObject.customerSubsidiaryFacility.building}“)`,
                    link: `/facility/${this.props.match.params.serviceproviderid}/${customerObject.customerSubsidiaryFacility.id}`
                },
                {
                    icon: "qrcode",
                    title: customerObject.smid.id
                }
            ]} />
            <Section bodyPadding="32px 0 0 0" responsiveZoom="0.8">
                <Section.Item title={i18next.t("page.private.object.general.sectionTitle")} icon="database">
                    { this.state.manuallyCreated && <ButtonView>
                        <Button thick green={this.state.editObject} title={ this.state.editObject ? i18next.t("page.private.object.general.saveDetailsButton") : i18next.t("page.private.object.general.objectEditButton") } icon={ this.state.editObject ? 'check' : 'pencil-alt' } onClick={() => this.setState({ editObject: !this.state.editObject })} />
                    </ButtonView> }
                    <Grid gap="32px" columns={[ "1fr", "4fr" ]}>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Grid gap="32px" columns={[ "1fr" ]}>
                                <GridElement styled title={i18next.t("page.private.object.general.smid.title")} icon="qrcode">
                                    <QRCodeContainer>
                                        <QRCode fgColor="#353b48" renderAs="svg" size={150} value={"scanmetrix.app/qr/" + customerObject.smid.id} />
                                        <p className="smid">{customerObject.smid.id}</p>
                                        <p className="subtitle">{customerObject.smid.digital ? i18next.t("page.private.object.general.smid.digitalID") : i18next.t("page.private.object.general.smid.physicalID")}</p>
                                    </QRCodeContainer>
                                </GridElement>

                                { !this.state.manuallyCreated && <>
                                    <SingleAmount flex green={!!customerObject.operative} red={customerObject.operative === false}>
                                        <p className="value"><i className={`far fa-${!!customerObject.operative ? "check" : (customerObject.operative === false ? "times" : "question")}-circle`} /></p>
                                        <p className="title">{!!customerObject.operative ? i18next.t("page.private.object.general.operationReady") : (customerObject.operative === false ? i18next.t("page.private.object.general.outOfFunction") : i18next.t("page.private.object.general.notSpecified"))}</p>
                                    </SingleAmount>
                                    <SingleAmount light active onClick={() => this.maintenancesModal.open()}>
                                        <p className="value"><b>{customerObject.maintenances.length}</b></p>
                                        <p className="title">{i18next.t("page.private.object.general.pastMaintenance")}</p>
                                    </SingleAmount>
                                    <SingleAmount light active onClick={() => this.flawsModal.open()}>
                                        <p className="value"><b>{customerObject.maintenances.map(maintenance => maintenance.flaws).flat().filter(flaw => flaw.fixedInMaintenance).length}</b> / {customerObject.maintenances.map(maintenance => maintenance.flaws).flat().length}</p>
                                        <p className="title">{i18next.t("page.private.object.general.correctedDefects")}</p>
                                    </SingleAmount>
                                </>}
                                { this.state.manuallyCreated && <>
                                    <WarningBox>
                                        <i className='far fa-exclamation-circle' />
                                        <p>{i18next.t("page.private.object.general.manualObjectText")}</p>
                                    </WarningBox>
                                </> }
                            </Grid>
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Grid gap="32px" columns={[ "1fr" ]}>
                                <GridElement>
                                    <Grid gap="32px" columns={[ "2fr", "1fr" ]}>
                                        {/*rightContent={<Button icon="bolt" title="Mangel melden" onClick={() => this.reportFlawModal.open()}*/}
                                        <GridElement columnStart={1} columnEnd={2} styled title={i18next.t("page.private.object.general.frameData.title")} icon="database">
                                            <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                                <GridElement columnStart={1} columnEnd={3}>
                                                    <Input readOnly={!this.state.editObject} adjustWidth label={i18next.t("page.private.object.general.frameData.positionDescription")} value={customerObject.positionDescription} icon="map-marker" />
                                                </GridElement>
                                                <GridElement columnStart={1} columnEnd={3}>
                                                    <Input readOnly={!this.state.editObject} adjustWidth label={i18next.t("page.private.object.general.frameData.notes")} value={customerObject.notes} icon="font" />
                                                </GridElement>
                                                <GridElement columnStart={1} columnEnd={2}>
                                                    <Input readOnly={!this.state.editObject} adjustWidth label={i18next.t("page.private.object.general.frameData.manufacturer")} value={mainCustomerObjectComponent ? (mainCustomerObjectComponent.manufacturer_name) : null} icon="industry" />
                                                </GridElement>
                                                <GridElement columnStart={2} columnEnd={3}>
                                                    <Input readOnly={!this.state.editObject} adjustWidth label={i18next.t("page.private.object.general.frameData.product")} value={mainCustomerObjectComponent ? (mainCustomerObjectComponent.accounting_article_name) : null} icon="box-open" />
                                                </GridElement>
                                            </Grid>
                                        </GridElement>
                                        <GridElement columnStart={2} columnEnd={3} styled title={i18next.t("page.private.object.general.PlanPosition")} icon="map-marked">
                                            {mainCustomerObjectComponent && mainCustomerObjectComponent.plan && <>
                                                <Plan3D customerObject={customerObject} planX={mainCustomerObjectComponent.planX} planY={mainCustomerObjectComponent.planY} plan={mainCustomerObjectComponent.plan} />
                                            </>}
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                                <GridElement styled title={i18next.t("page.private.object.general.defectsForm.title")} icon="clock">
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={2}>
                                            <Input readOnly={!this.state.editObject} adjustWidth label={i18next.t("page.private.object.general.defectsForm.lastMaintenance")} value={lastMaintenanceDate ? lastMaintenanceDate.format("dddd, DD. MMMM YYYY") : null} icon="hourglass-start" />
                                        </GridElement>
                                        <GridElement columnStart={2} columnEnd={3}>
                                            <Input readOnly={!this.state.editObject} adjustWidth label={i18next.t("page.private.object.general.defectsForm.interval")} value={interval} icon="history" />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Input readOnly={!this.state.editObject} adjustWidth label={i18next.t("page.private.object.general.defectsForm.nextMaintenance")} value={nextInspectionDate ? nextInspectionDate.format("MMMM / YYYY") : null} icon="hourglass-end" />
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                                <GridElement styled title={i18next.t("page.private.object.general.component.title")} icon="puzzle-piece" rightContent={ this.state.manuallyCreated && <Button disabled={!this.state.editObject} thick title={i18next.t("page.private.object.general.component.addComponentButton")} icon='puzzle-piece' onClick={() => this.newComponentModal.open()} /> }>
                                    <Table
                                        head={[
                                            { title: i18next.t("page.private.object.general.component.table.smid"), width: "20%", column: "smid" },
                                            { title: i18next.t("page.private.object.general.component.table.component"), width: "20%", column: "component" },
                                            { title: i18next.t("page.private.object.general.component.table.manufacturer"), width: "20%", column: "manufacturer" },
                                            { title: i18next.t("page.private.object.general.component.table.model"), width: "20%", column: "model" },
                                            { title: i18next.t("page.private.object.general.component.table.amount"), width: "20%", column: "amount" }
                                        ]}
                                        onItemClick={component => this.componentModal.open(component)}
                                        contextMenu={<>
                                            <Item><i className="fa fa-times" />{i18next.t("page.private.object.general.component.deleteComponent")}</Item>
                                        </>}
                                        items={customerObject.components.map((customerObjectComponent => ({ data: JSON.parse(customerObjectComponent.data), items: customerObjectComponent.component.layout, smid: <><i className="far fa-qrcode"/>{customerObject.smid.id}-{customerObjectComponent.id}</>, amount: (mainCustomerObjectComponent && customerObjectComponent.id === mainCustomerObjectComponent.id) ? <><i className="far fa-exclamation-circle blue" />{i18next.t("page.private.object.general.component.mainComponent")}</> : `${customerObjectComponent.amount} ${i18next.t("page.private.object.general.component.pieces")}`, model: customerObjectComponent.accountingArticle ? customerObjectComponent.accountingArticle.name : null, component: customerObjectComponent.component.name, manufacturer: customerObjectComponent.accountingArticle ? (customerObjectComponent.accountingArticle.manufacturer ? customerObjectComponent.accountingArticle.manufacturer.name : null) : null })))}
                                    />
                                </GridElement>
                                { !this.state.manuallyCreated ? <GridElement styled title={i18next.t("page.private.object.general.gallery")} icon="images">
                                    <Grid gap="16px" padding="16px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                                        {imageStacks.map((imageStack, index) => <React.Fragment key={index}>
                                            {imageStack.map((image, index) => <GridElement key={index} columnStart={index + 1} columnEnd={index + 2}>
                                                <StyledImage onClick={() => this.photoModal.open(image)} none={!image} src={image || "/noImage.jpg"} />
                                            </GridElement>)}
                                        </React.Fragment>)}
                                    </Grid>
                                </GridElement> :
                                <GridElement styled title='Galerie' icon='images'>
                                    <Grid gap='16px' padding='16px' columns={[ '1fr', '1fr', '1fr', '1fr' ]}>
                                        {this.state.editObject && <Dropzone accept={[ ".jpg", ".jpeg", ".png" ]} multiple={true} onDrop={acceptedFiles => {
                                            if(acceptedFiles.length && !this.state.loading) {
                                                const files = acceptedFiles

                                                this.setState({ files: this.state.files.concat(files.map(file => ({ id: uuid(), fileName: file.name, fileSize: file.size, imageFile: file }))) })
                                            }
                                        }}>
                                            {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody tabIndex={0} {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef", className: 'dropzone' })}>
                                                <input {...getInputProps()} tabIndex={-1} accept='image/jpeg, image/jpg, image/png' multiple={true} type='file' autoComplete='off' />

                                                <i className="far fa-upload" />
                                                <p className="title">{i18next.t("page.private.object.general.jpg/pngData")}</p>
                                            </UploadBody>}
                                        </Dropzone> }
                                        {this.state.files.map((item, index) => <ImageBox key={index} onClick={ () => !this.state.editObject ? (this.manualGalleryModal.open()) : null }>
                                            <img src={URL.createObjectURL(item.imageFile)} />
                                            {this.state.editObject && <div className='delete-hover' onClick={() => this.setState({ files: this.state.files.filter(file => file.id !== item.id) })}>
                                                <i className='far fa-trash' />
                                            </div> }
                                        </ImageBox>)}
                                    </Grid>
                                </GridElement>}
                            </Grid>
                        </GridElement>
                    </Grid>
                </Section.Item>
            </Section>
        </Page>
    }
}

export { getIntervalType }
