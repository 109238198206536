import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { id: null, value: null, loading: false }

    render() {
        return <Modal instance={this.props.instance} initialize={reading => this.setState({ id: reading.id, value: reading.value, readDate: reading.date, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.updateMeterHistoryModal.title")} icon="history" />
            <Modal.Body padding={24}>
                <Input required float value={this.state.value} onChange={value => this.setState({ value })} icon="monitor-heart-rate" label={i18next.t("page.private.subsidiary.styled.updateMeterHistoryModal.description") + this.state.readDate} adjustWidth />
            </Modal.Body>
            <Modal.Footer buttons={instance => [
                <Button loading={this.state.loading} thick title={i18next.t("page.private.subsidiary.styled.updateMeterHistoryModal.exitButton")} icon="history" onClick={() => {
                    this.setState({ loading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($id: ID!, $value: Float!) {
                                updateMeterReading(id: $id, value: $value)
                            }
                        `,
                        variables: {
                            id: this.state.id,
                            value: this.state.value
                        }
                    }).then(result => {
                        this.setState({ loading: false })

                        if(result.data.updateMeterReading) {
                            this.props.refresh(this.state.id, this.state.value)
                            instance.close()
                        }
                    })}
                } />
            ]} />
        </Modal>
    }
}
