import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, Input, Modal, Select, Grid, PlateInput } from "scanmetrix-components"
import Dropzone from "react-dropzone"


import CarPreview from '../styled/CarPreview'

const CarModalBody = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  
  .input-view {
    display: flex;
    flex-direction: column;
    
    .row {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 16px;
    }
  }
  
  .drop-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    .dropzone-wrapper {
      width: 100%;

      &.hidden {
        display: none;
      }
    }

    .preview {
      width: 100%;
      height: 300px;
      margin-bottom: 32px;
      z-index: 10;
      cursor: pointer;
      transition: all 250ms ease;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;

      &:hover {
        opacity: 0.75;

        .delete-view {
          opacity: 1;
        }
      }
      
      img {
        position: absolute;
        width: 100%;
        height: 100%;        
        object-fit: cover;
      }
      
      .delete-view {
        width: 100%;
        height: 300px;
        background: rgba(255, 255, 255, .5);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 15;
        opacity: 0;
        transition: all 250ms ease;

        i {
          font-size: 2em;
          color: white;
          padding: 16px;
          border-radius: 125px;
          background: #E74C3C;
        }
      }
    } 
  }
`

const CarView = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 10px;
  background: radial-gradient(at bottom left,rgb(220 220 220) 5%,rgba(235,238,243,1) 75%);  
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
`

const UploadBody = styled.div`
  width: 100%;
  height: 300px;
  margin-bottom: 32px;
  padding: 32px;
  border: 3px dashed rgba(0, 0, 0, .25);
  color: rgba(0, 0, 0, .25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 250ms ease;
  position: relative;

  &:hover {
    opacity: 0.75;
  }
  
  input {
    display: none;
  }

  i {
    font-size: 2em;
    margin-bottom: 16px;
  }

  p {
    font-size: 1em;
    text-align: center;
    width: 75%;
  }
`


const Selector = styled.div`
  width: 100%;
  margin-bottom: 32px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, .1);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 8px;
`

const SelectBox = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  box-sizing: border-box;
  background: transparent;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(30deg, #0F7ABF, #58B9F9);
    opacity: 0;
    transition: all 250ms ease;
    user-select: none;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    user-select: none;
    transition: all 250ms ease;
  }
`

export default class extends Component {
    state = {
        loading: false,
        file: null,
        numberPlate1: null,
        numberPlate2: null,
        numberPlate3: null,
        subsidiaryId: null,
        name: null,
        nextInspection: null,
        model: null,
        manufacturer: null,
        buildYear: null,
        type: "smallCar"
    }

    render() {
        const cars = [
            { type: "smallCar", icon: 'car', label: 'Kleinwagen' },
            { type: "limousine", icon: 'car', label: 'Limousine' },
            { type: "suv", icon: 'car', label: 'SUV' },
            { type: "smallTransporter", icon: 'shuttle-van', label: 'Kleintransporter' },
            { type: "bigTransporter", icon: 'shuttle-van', label: 'Großtransporter' },
            { type: "boxTransporter", icon: 'truck-moving', label: 'Boxtransporter' },
            { type: "truck", icon: 'truck-container', label: 'LKW' },
            { type: "forklift", icon: 'forklift', label: 'Gabelstapler' },
            { type: "excavator", icon: 'truck-plow', label: 'Bagger' },
            { type: "tractor", icon: 'tractor', label: 'Traktor' },
            { type: "trailer", icon: 'trailer', label: 'Anhänger' },
            { type: "transportTruck", icon: 'truck-pickup', label: 'Pritschenwagen' },
            { type: "transportLift", icon: 'truck', label: 'Steigerwagen' },
            { type: "flatbedTruck", icon: 'trailer', label: 'Tieflader' }
        ]

        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={() => this.setState({
            loading: false,
            file: null,
            numberPlate1: null,
            numberPlate2: null,
            numberPlate3: null,
            subsidiaryId: null,
            name: null,
            nextInspection: null,
            model: null,
            manufacturer: null,
            buildYear: null,
            type: "smallCar"
        })}>
            <Modal.Head title="Fahrzeug anlegen" icon="car" />
            <Modal.Body padding={24}>
                <CarModalBody>
                    <div>
                        <Modal.Divider title="Fahrzeugtyp" />
                        <Selector>
                            {cars.map((car, index) => {
                                return <SelectBox key={index} onClick={() => this.setState({ type: car.type })}>
                                    <div style={ car.type === this.state.type ? { opacity: 1 } : { opacity: 0 } } className='background' />
                                    <div className='content'>
                                        <i style={ car.type === this.state.type ? { color: 'white' } : { color: 'black' } }  className={`far fa-${car.icon}`} />
                                        <p style={ car.type === this.state.type ? { color: 'white' } : { color: 'black' } } >{car.label}</p>
                                    </div>
                                </SelectBox>
                            })}
                        </Selector>

                        <Grid columns={[ "1fr", "1fr" ]} gap="32px">
                            <div className='drop-box'>
                                <div className={ this.state.file !== null ? 'dropzone-wrapper hidden' : 'dropzone-wrapper' }>
                                    <Dropzone accept={[ ".jpg", ".jpeg", ".png" ]} multiple={false} onDrop={acceptedFiles => {
                                        if(acceptedFiles.length && !this.state.loading) {
                                            const file = acceptedFiles[0]

                                            this.setState({ file })
                                        }
                                    }}>
                                        {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody tabIndex={0} {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef", className: 'dropzone' })}>
                                            <input {...getInputProps()} tabIndex={-1} accept='image/jpeg, image/jpg, image/png' multiple={false} type='file' autoComplete='off' />

                                            <i className="far fa-upload" />
                                            <p className="title">Legen Sie hier ein Bild des Fahrzeugs ab, oder klicken Sie hier um den Dateibrowser zu öffnen.</p>
                                        </UploadBody>}
                                    </Dropzone>
                                </div>
                                { this.state.file !== null && <div className='preview'>
                                    <img src={URL.createObjectURL(this.state.file)} />
                                    <div className='delete-view' onClick={() => this.setState({ file: null })}>
                                        <i className='far fa-trash' />
                                    </div>
                                </div> }
                            </div>

                            <CarView>
                                <CarPreview height={300} enableZoom={false} minDistance={4} maxDistance={4} selectedCar={this.state.type} />
                            </CarView>
                        </Grid>
                    </div>

                    <div>
                        <div className='input-view'>
                            <Modal.Divider title="Kennzeichen" />
                            <PlateInput onChangePlate1={numberPlate1 => this.setState({ numberPlate1 })} onChangePlate2={numberPlate2 => this.setState({ numberPlate2 })} onChangePlate3={numberPlate3 => this.setState({ numberPlate3 })} />
                            <Modal.Divider title="Fahrzeuginformationen" />
                            <div className='row'>
                                <Select margin="0 0 16px 0" adjustWidth label='Niederlassung' icon='map-marker' value={this.state.subsidiaryId} onChange={subsidiaryId => this.setState({ subsidiaryId })} items={this.props.subsidiaries.map(subsidiary => ({
                                    key: subsidiary.id,
                                    title: `${subsidiary.address.streetName} ${subsidiary.address.streetNumber}, ${subsidiary.address.postalCode} ${subsidiary.address.city}`
                                }))} />
                                <Input adjustWidth label='Nächste TÜV-Prüfung' icon='calendar-times' date margin="0 0 16px 0" value={this.state.nextInspection} onChange={nextInspection => this.setState({ nextInspection })} />
                            </div>
                            <div className='row'>
                                <Input margin="0 0 16px 0" required adjustWidth label='Name' icon='tag' value={this.state.name} onChange={name => this.setState({ name })} />
                                <Input margin="0 0 16px 0" adjustWidth label='Hersteller' icon='user-tie' value={this.state.manufacturer} onChange={manufacturer => this.setState({ manufacturer })} />
                            </div>
                            <div className='row'>
                                <Input margin="0 0 128px 0" adjustWidth label='Fahrzeugmodell' icon='car' value={this.state.model} onChange={model => this.setState({ model })} />
                                <Input margin="0 0 128px 0" adjustWidth label='Baujahr' icon='wrench' value={this.state.buildYear} int onChange={buildYear => this.setState({ buildYear })} />
                            </div>
                        </div>
                    </div>
                </CarModalBody>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.name || !this.state.numberPlate1 || !this.state.numberPlate2 || !this.state.numberPlate3 || !this.state.type} thick title="Fahrzeug anlegen" icon="car" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $type: String!, $manufacturer: String, $model: String, $buildYear: Int, $numberPlate1: String!, $numberPlate2: String!, $numberPlate3: String!, $subsidiaryId: ID, $nextInspection: DateTime, $thumbnail: Upload) {
                            createCar(name: $name, type: $type, manufacturer: $manufacturer, model: $model, buildYear: $buildYear, numberPlate1: $numberPlate1, numberPlate2: $numberPlate2, numberPlate3: $numberPlate3, subsidiaryId: $subsidiaryId, nextInspection: $nextInspection, thumbnail: $thumbnail)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        manufacturer: this.state.manufacturer,
                        model: this.state.model,
                        buildYear: this.state.buildYear,
                        numberPlate1: this.state.numberPlate1,
                        numberPlate2: this.state.numberPlate2,
                        numberPlate3: this.state.numberPlate3,
                        subsidiaryId: this.state.subsidiaryId,
                        type: this.state.type,
                        nextInspection: moment(this.state.nextInspection, "DD.MM.YYYY").toDate(),
                        thumbnail: this.state.file
                    }
                }).then(data => {
                    if(data.data.createCar) {
                        instance.close()
                        this.props.refresh()
                    } else this.setState({ loading: false })
                })
            }} />} />
        </Modal>
    }
}
