import React, { Component } from 'react'
import { Modal, Input, Grid, GridElement, Checkbox, Select } from 'scanmetrix-components'

export default class extends Component {
    state = { buildingInspectionTemplateIntervalVariation: null }

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={buildingInspectionTemplateIntervalVariation => {
            this.setState({ buildingInspectionTemplateIntervalVariation })
        }}>
            <Modal.Head title="Abweichung aktualisieren" icon="calendar-lines-pen" />
            <Modal.Body adjustHeight="470px" padding={24}>
                {this.state.buildingInspectionTemplateIntervalVariation && <Input.MutationProvider value={{
                    name: "updateBuildingInspectionTemplateIntervalVariation",
                    id: this.state.buildingInspectionTemplateIntervalVariation.id,
                    data: this.state.buildingInspectionTemplateIntervalVariation,
                    onSave: () => this.props.refresh()
                }}>
                    <Grid gap="16px" columns={[ "1fr", "1fr" ]}>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input int field="intervalAmount" icon="tally" label="Intervall Anzahl" adjustWidth />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Select field="intervalType" icon="calendar-week" label="Intervall Einheit" adjustWidth items={[
                                { key: "days", title: "Tage" },
                                { key: "weeks", title: "Wochen" },
                                { key: "months", title: "Monate" },
                                { key: "years", title: "Jahre" },
                            ]} />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input date field="startingAt" icon="calendar-day" label="Startet am" adjustWidth />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Checkbox field="inactive" label="Inaktiv" />
                        </GridElement>
                    </Grid>
                </Input.MutationProvider>}
            </Modal.Body>
        </Modal>
    }
}
