import styled from "styled-components"

export default styled.div`
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 6;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 32px;

  @media screen and (max-width: 1919px) {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  @media screen and (max-width: 1279px) {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .box-body {
    width: 100%;
    height: 0;
    padding-top: 100%;
    background: white;
    position: relative;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.15);
    color: #2C3E50;
    font-size: 0.9em;
    
    &.clickable {
        cursor: pointer;
        transition: opacity 0.3s;
      
        &:hover {
          opacity: 0.75;
        }
    }
    
    @media screen and (max-width: 2200px) {
      zoom: 0.95;
    }
    
    .content {
      position: absolute;
      bottom: 12px;
      left: 20px;
      user-select: none;
      padding-right: 8px;
      
      i {
        font-size: 3em;
        height: 32px;
        opacity: 0.6;
      }
      
      .title {
        margin-top: 24px;
        margin-bottom: 8px;
        opacity: 0.75;
      }
      
      .value {
        height: 42px;
        line-height: 42px;
        text-shadow: 0 2px 2px rgba(0, 0, 0, .2);
        font-size: 2.4em;
        font-weight: bold;
      }
    }
    
    &.blue {
      background: linear-gradient(45deg, #3B97D3, #81CDFF);
      color: white;
      box-shadow: 0 4px 32px -8px #3B97D3;
    }
    
    &.map {
      background-image: url("/world-map.svg");
      background-repeat: no-repeat;
      background-position: calc(100% + 64px) calc(100% + 50px);
    }
    
    @media screen and (max-width: 2100px) {
      font-size: 0.8em;
      
      .content {
        bottom: 10px !important;
        left: 10px !important;
      }
      
      &:nth-child(5) {
        background-size: 130%;
      }
    }
  }



  .gridItem{
    width: 100%;
    height: 0px;
    background: white;
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 24px -8px;
    color: rgb(44, 62, 80);
    font-size: 0.9em;
    
      
  
    .content {
      position: absolute;
      bottom: 12px;
      left: 20px;
      user-select: none;
      padding-right: 8px;
      overflow: hidden;
      
      i {
        font-size: 3em;
        height: 32px;
        opacity: 0.6;
      }
      
      .title {
        margin-top: 24px;
        margin-bottom: 8px;
        opacity: 0.75;
      }
      
      .value {
        height: 42px;
        line-height: 42px;
        text-shadow: 0 2px 2px rgba(0, 0, 0, .2);
        font-size: 2.4em;
        font-weight: bold;
        overflow: hidden;
      }

      @media screen and (min-width: 88px) {
        font-size: 0.8em;
        .gridItem{
          overflow: hidden;
        .content {
          bottom: 10px !important;
          left: 10px !important;
          
        }
       }  
      }
    }
`
