import React, { Component } from "react"
import styled from "styled-components"

const StyledGrid = styled.div`
  width: 100%;
  padding: ${props => props.padding || 0};
  display: grid;
  grid-gap: ${props => props.gap || "64px"};
  overflow-y: ${props => props.scroll ? "scroll" : "unset"};
  max-height: ${props => props.scroll || "auto"};
  box-sizing: border-box;
  grid-template-columns: ${props => props.columns.join(" ")};
  grid-template-rows: ${props => props.rows.join(" ")};
`

export default class extends Component {
    render() {
        return <StyledGrid {...this.props} columns={this.props.columns || ["100%"]} rows={this.props.rows || ["minmax(0, 1fr)"]}>
            {this.props.children}
        </StyledGrid>
    }
}
