import React, { Component } from "react"
import { Modal, Input } from "scanmetrix-components"
import {FileManager} from "../../documents";
import i18next from "i18next";

export default class extends Component {
    state = { id: null, fileStructureTemplate: null, name: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={fileStructureTemplate => this.setState({ id: fileStructureTemplate.id, fileStructureTemplate, name: fileStructureTemplate.name, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.updateFileStructureTemplateModal.title")} icon="folder-open" />
            <Modal.Body padding={24} adjustWidth={1024}>
                <Input.MutationProvider value={{
                    name: "updateFileStructureTemplate",
                    id: this.state.id,
                    data: this.state.fileStructureTemplate,
                    onSave: () => this.props.refresh()
                }}>
                    <Input field="name" required icon="tag" label={i18next.t("page.private.settings.modals.updateFileStructureTemplateModal.name")} adjustWidth />
                    <div style={{ marginTop: 24, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5 }}>
                        <FileManager fileStructureTemplateId={this.state.id} title={i18next.t("page.private.settings.modals.updateFileStructureTemplateModal.fileStructureTemplate")} icon="folder-open" scope="fileStructureTemplate" onlyFolder />
                    </div>
                </Input.MutationProvider>
            </Modal.Body>
        </Modal>
    }
}
