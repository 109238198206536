import React, { Component } from "react"
import { Page, Breadcrumbs, Button, Modal, Section } from "scanmetrix-components"

import styled from "styled-components"
import { Document, Page as PDFPage } from "react-pdf"
import i18next from "i18next";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

const ChangesTitle = styled.h1`
  margin-bottom: 32px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const BoughtTitle = styled.h1`
  margin-bottom: 32px;
  margin-top: 32px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const BoughtContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 32px;
  width: 100%;
  
  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`

const ChangesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 32px;
  
  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const ChangeContainer = styled.div`
  user-select: none;
  cursor: pointer;
  transition: opacity 0.3s;
  position: relative;
  opacity: ${props => props.read ? 0.5 : 1};
  
  &:hover {
    opacity: 0.5;
  }
  
  .unread {
    position: absolute;
    font-weight: bold;
    right: 0;
    top: 0;
    height: 32px;
    display: flex;
    align-items: center;
      
    &::before {
      display: block;
      content: " ";
      height: 8px;
      width: 8px;
      border-radius: 8px;
      margin-right: 4px;
      background: #3b97d3;
    }
  }
  
  .date {
    background: white;
    border-radius: 4px;
    padding: 0 8px;
    height: 32px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    margin-bottom: 16px;
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
    font-size: 1.4em;
  }
  
  .description {
    line-height: 1.7em;
    text-align: justify;
  }
`

const OrderText = styled.div`
  width: 100%;
  height: 350px;
  background: #ccc;
  clip-path: polygon(0px 0px, 0px 100%, 100% 100%, 100% 10%, 85% 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  transition: background 0.3s;
  
  &:hover {
      background: gray;
  }
  
  .inner {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #EBEEF3;
    clip-path: polygon(0px 0px, 0px 100%, 100% 100%, 100% 10%, 85% 0px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    i {
      width: 32px;
      height: 32px;
      border: 2px solid gray;
      border-radius: 32px;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: gray;
      font-size: 1.1em;
    }
    
    p {
      color: gray;
      font-size: 0.9em;
    }
  }
`

class Change extends Component {
    render() {
        return <ChangeContainer read={this.props.read}>
            {!this.props.read && <div className="unread">{i18next.t("page.private.legalTexts.unread")}</div>}
            <div className="date">{this.props.date}</div>
            <p className="title">{this.props.title}</p>
            <p className="description">{this.props.description}</p>
        </ChangeContainer>
    }
}

const BoughtPaper = styled.div`
    box-shadow: 0 10px 8px -10px rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 350px;
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    
    canvas {
        width: 100% !important;
        height: 350px !important;
    }
    
    &:hover {
        transform: translateY(-4px);
        box-shadow: 0 12px 8px -10px rgba(0, 0, 0, 0.6);
    }
    
    .info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        z-index: 2;
        padding: 64px 16px 16px;
        background: linear-gradient(0deg, white, white, transparent);
        
        .title {
            font-size: 1.1em;
        }
        
        .publisher {
            font-size: 0.9em;
        }
        
        .date {
            font-size: 0.8em;
        }
    }
    
    .inner {
        background: white;
        width: 100%;
        height: 100%;
        clip-path: polygon(0px 0px, 0px 100%, 100% 100%, 100% 10%, 85% 0px);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        position: relative;
        
        &::before {
            content: " ";
            display: block;
            position: absolute;
            width: 15%;
            height: 10%;
            right: 0;
            top: 0;
            z-index: 3;
            background: white;
        }
        
        &::after {
            content: " ";
            display: block;
            position: absolute;
            width: 15%;
            height: 10%;
            right: 0;
            top: 0;
            z-index: 1;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
        }
    }
`

class BoughtItem extends Component {
    state = { loading: true }

    render() {
        return <BoughtPaper>
            <div className="inner">
                <Document options={{ withCredentials: true }} onLoadSuccess={() => this.setState({ loading: false })} file={`${scanmetrix.backendURL}/legaltext/${this.props.id}`}>
                    <PDFPage size="A4" pageNumber={1}></PDFPage>
                </Document>
            </div>
            <div className="info">
                <p className="title">{this.props.title}</p>
                <p className="publisher">{this.props.publisher}</p>
                <p className="date">{this.props.date}</p>
            </div>
        </BoughtPaper>
    }
}

class Bought extends Component {
    state = {
        bought: [
            {
                id: 1,
                date: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file1.date"),
                title: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file1.title"),
                publisher: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file1.publisher")
            },
            {
                id: 1,
                date: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file2.date"),
                title: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file2.title"),
                publisher: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file2.publisher")
            },
            {
                id: 1,
                date: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file3.date"),
                title: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file3.title"),
                publisher: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file3.publisher")
            },
            {
                id: 1,
                date: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file4.date"),
                title: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file4.title"),
                publisher: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file4.publisher")
            },
            {
                id: 1,
                date: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file5.date"),
                title: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file5.title"),
                publisher: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file5.publisher")
            },
            {
                id: 1,
                date: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file6.date"),
                title: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file6.title"),
                publisher: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file6.publisher")
            },
            {
                id: 1,
                date: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file7.date"),
                title: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file7.title"),
                publisher: i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.file7.publisher")
            }
        ]
    }

    render() {
        if(!this.state.bought) return null

        return <BoughtContainer>
            <OrderText onClick={this.props.marketplaceOnClick}>
                <div className="inner">
                    <i className="far fa-plus" />
                    <p className="title">{i18next.t("page.private.legalTexts.acquiredLegalTextsFiles.acquireFurtherTexts")}</p>
                </div>
            </OrderText>
            {this.state.bought.map((item, index) => <BoughtItem key={index} date={item.date} title={item.title} publisher={item.publisher} id={item.id} />)}
        </BoughtContainer>
    }
}

class Changes extends Component {
    state = {
        changes: [
            {
                read: false,
                date: i18next.t("page.private.legalTexts.latestChangeFiles.file1.date"),
                title: i18next.t("page.private.legalTexts.latestChangeFiles.file1.title"),
                description: i18next.t("page.private.legalTexts.latestChangeFiles.file1.description")
            },
            {
                read: true,
                date: i18next.t("page.private.legalTexts.latestChangeFiles.file2.date"),
                title: i18next.t("page.private.legalTexts.latestChangeFiles.file2.title"),
                description: i18next.t("page.private.legalTexts.latestChangeFiles.file2.description")
            },
            {
                read: true,
                date: i18next.t("page.private.legalTexts.latestChangeFiles.file3.date"),
                title: i18next.t("page.private.legalTexts.latestChangeFiles.file3.title"),
                description: i18next.t("page.private.legalTexts.latestChangeFiles.file3.description")
            }
        ]
    }

    constructor(props) {
        super(props)

    }

    render() {
        if(!this.state.changes) return null

        return <ChangesContainer>
            {this.state.changes.map((change, index) => <Change key={index} date={change.date} title={change.title} description={change.description} read={change.read} />)}
        </ChangesContainer>
    }
}

const PurchaseableDocument = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr 0.5fr 1.5fr;
    grid-gap: 24px;
    padding: 12px 24px;
    cursor: pointer;
    user-select: none;
    
    &:nth-child(even) {
        background: rgba(0, 0, 0, 0.05);
    }
    
    div {
        display: flex;
        align-items: center;
    }
    
    .buttonContainer {
        display: flex;
        justify-content: flex-end;
    }
`

class PurchaseableItem extends Component {
    render() {
        return <PurchaseableDocument>
            <div className="date">{this.props.date}</div>
            <div className="name">{this.props.name}</div>
            <div className="title">{this.props.title}</div>
            <div className="length">{this.props.length} {i18next.t("page.private.legalTexts.legalTextsFiles.pages")}</div>
            <div className="publisher">{this.props.publisher}</div>
            <div className="price">{formatter.format(this.props.price)}</div>
            <div className="buttonContainer">
                {!this.props.bought && <Button title={i18next.t("page.private.legalTexts.legalTextsFiles.shoppingCartButton")} icon="shopping-cart" />}
                {!!this.props.bought && <Button title={i18next.t("page.private.legalTexts.legalTextsFiles.alreadyPurchasedButton")} disabled icon="shopping-cart" />}
            </div>
        </PurchaseableDocument>
    }
}

class MarketplaceModal extends Component {
    render() {
        return <Modal instance={this.props.instance} responsiveWidth='80%'>
            <Modal.Head title={i18next.t("page.private.legalTexts.legalTextsFiles.title")} icon="store" />
            <Modal.Body>
                <Section>
                    <Section.Item title={i18next.t("page.private.legalTexts.legalTextsFiles.table.DIN")} icon="file-alt">

                    </Section.Item>
                    <Section.Item title={i18next.t("page.private.legalTexts.legalTextsFiles.table.EN")} icon="file-alt">

                    </Section.Item>
                    <Section.Item title={i18next.t("page.private.legalTexts.legalTextsFiles.table.ASR")} icon="file-alt">

                    </Section.Item>
                    <Section.Item title={i18next.t("page.private.legalTexts.legalTextsFiles.table.SPrüfV")} icon="file-alt">

                    </Section.Item>
                    <Section.Item title={i18next.t("page.private.legalTexts.legalTextsFiles.table.VDMA")} icon="file-alt">

                    </Section.Item>
                    <Section.Item title={i18next.t("page.private.legalTexts.legalTextsFiles.table.ISO/IEC")} icon="file-alt">

                    </Section.Item>
                    <Section.Item title={i18next.t("page.private.legalTexts.legalTextsFiles.table.VDI")} icon="file-alt">

                    </Section.Item>
                    <Section.Item title={i18next.t("page.private.legalTexts.legalTextsFiles.table.VDE")} icon="file-alt">

                    </Section.Item>
                    <Section.Item title={i18next.t("page.private.legalTexts.legalTextsFiles.table.BetrSichV")} icon="file-alt">

                    </Section.Item>
                </Section>
                <PurchaseableItem date={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem1.date")} title={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem1.title")} name={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem1.name")} publisher={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem1.publisher")} price={52.9} length={14} bought />
                <PurchaseableItem date={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem2.date")} title={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem2.title")} name={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem2.name")} publisher={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem2.publisher")} price={28.79} length={28} />
                <PurchaseableItem date={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem3.date")} title={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem3.title")} name={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem3.name")} publisher={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem3.publisher")} price={58.5} length={12} />
                <PurchaseableItem date={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem4.date")} title={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem4.title")} name={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem4.name")} publisher={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem4.publisher")} price={97.76} length={32} bought />
                <PurchaseableItem date={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem5.date")} title={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem5.title")} name={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem5.name")} publisher={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem5.publisher")} price={140.37} length={7} />
                <PurchaseableItem date={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem6.date")} title={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem6.title")} name={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem6.name")} publisher={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem6.publisher")} price={47.01} length={15} />
                <PurchaseableItem date={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem7.date")} title={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem7.title")} name={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem7.name")} publisher={i18next.t("page.private.legalTexts.legalTextsFiles.purchaseableItem7.publisher")} price={34.95} length={4} />
            </Modal.Body>
            <Modal.Footer buttons={() => ([
                <p className="cart" style={{ display: "flex", alignItems: "center" }}>{i18next.t("page.private.legalTexts.legalTextsFiles.shoppinCartText")} <b style={{ marginLeft: 8 }}>{i18next.t("page.private.legalTexts.legalTextsFiles.zeroEuro")}</b></p>,
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}><Button disabled title={i18next.t("page.private.legalTexts.legalTextsFiles.checkoutButton")} icon="cash-register" /></div>
            ])} />
        </Modal>
    }
}

export default class extends Component {
    render() {
        return <Page {...this.props}>
            <MarketplaceModal instance={ref => this.marketplaceModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "gavel",
                    title: i18next.t("page.private.legalTexts.breadCrumbsTitle")
                }
            ]} />
            <ChangesTitle>{i18next.t("page.private.legalTexts.latestChange")}{/*<Button title="Weiterlesen" icon="book" />*/}</ChangesTitle>
            <Changes />
            <BoughtTitle>{i18next.t("page.private.legalTexts.acquiredLegalTexts")}{/*<Button title="Suchen" icon="search" />*/}</BoughtTitle>
            <Bought marketplaceOnClick={() => this.marketplaceModal.open()} />
        </Page>
    }
}
