import styled from "styled-components";

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  
`
