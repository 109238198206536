import React, { Component } from "react"
import { Modal, Input, Button, Select, Checkbox } from "scanmetrix-components"
import { CirclePicker } from "react-color"
import styled from "styled-components"
import i18next from "i18next";
import isEmail from 'validator/lib/isEmail';

const CircleDesign = styled.div`
    transform: translateX(7px);
`

export default class extends Component {
    state = { description: null, durationAmount: null, durationTypes: null, statuses: [], allTypes: [], types: [], priorities: [], color: null, loading: false, additionalEmails: null }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    TicketTypes {
                        nodes {
                            id
                            name
                            color
                            icon
                            active
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ allTypes: data.data.TicketTypes.nodes })
        })
    }

    render() {
        return <Modal minWidth="500px" instance={this.props.instance} initialize={() => this.setState({ description: null, durationAmount: null, durationTypes: null, additionalEmails: null, statuses: [], types: [], priorities: [], color: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.createTicketEscalationStep.title")} icon="message-exclamation" />
            <Modal.Body padding={24}>
                <div style={{ padding: "16px", border: `3px dashed ${this.state.color || "#cccccc"}`, display: "grid", gridGap: "32px", gridTemplateColumns: "1fr", width: "480px" }}>
                    <Input required value={this.state.description} onChange={description => this.setState({ description })} icon="tag" label={i18next.t("page.private.settings.modals.createTicketEscalationStep.description")} adjustWidth />
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 16 }}>
                        <Input required int value={this.state.durationAmount} onChange={durationAmount => this.setState({ durationAmount })} icon="tally" label={i18next.t("page.private.settings.modals.createTicketEscalationStep.durationAmount")} adjustWidth />
                        <Select required value={this.state.durationType} onChange={durationType => this.setState({ durationType })} icon="calendar-week" label={i18next.t("page.private.settings.modals.createTicketEscalationStep.durationType")} adjustWidth items={[
                            { key: "minutes", title: "Minuten" },
                            { key: "hours", title: "Stunden" },
                            { key: "days", title: "Tage" },
                            { key: "weeks", title: "Wochen" },
                            { key: "months", title: "Monate" },
                            { key: "years", title: "Jahre" },
                        ]} />
                    </div>
                    <CircleDesign>
                        <CirclePicker onChange={color => this.setState({ color: color.hex })} width="100%" />
                    </CircleDesign>
                </div>
                <div style={{ marginTop: 16 }}>
                    <Input adjustWidth label={i18next.t("page.private.settings.modals.createTicketEscalationStep.additionalEmails")} textArea placeholder="max@mustermann.de,peter@mustermann.de" icon="envelope" value={this.state.additionalEmails} onChange={additionalEmails => this.setState({ additionalEmails: additionalEmails.replace(/ /g, "") })} />
                </div>
                <Modal.Divider title={i18next.t("page.private.settings.modals.createTicketEscalationStep.priorities")} />
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                    {["low", "medium", "high"].map((priority, index) => <Checkbox key={index} label={i18next.t("page.private.ticket-system.createTicketModal.priorityTypes." + priority)} value={this.state.priorities.includes(priority)} onChange={checked => checked ? this.setState({ priorities: [...this.state.priorities, priority] }) : this.setState({ priorities: this.state.priorities.filter(p => p !== priority) }) } />)}
                </div>
                <Modal.Divider title={i18next.t("page.private.settings.modals.createTicketEscalationStep.types")} />
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                    {this.state.allTypes.map(type => <Checkbox key={type.id} label={type.name} value={this.state.types.includes(type.id)} onChange={checked => checked ? this.setState({ types: [...this.state.types, type.id] }) : this.setState({ types: this.state.types.filter(p => p !== type.id) }) } />)}
                </div>
                <Modal.Divider title={i18next.t("page.private.settings.modals.createTicketEscalationStep.statuses")} />
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                    {["unassigned", "assigned", "active", "done"].map((status, index) => <Checkbox key={index} label={i18next.t("page.private.ticket-system.tickets.status." + status)} value={this.state.statuses.includes(status)} onChange={checked => checked ? this.setState({ statuses: [...this.state.statuses, status] }) : this.setState({ statuses: this.state.statuses.filter(p => p !== status) }) } />)}
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.description || !this.state.durationAmount || !this.state.durationType || !this.state.color} title={i18next.t("page.private.settings.modals.createTicketEscalationStep.button")} icon="message-exclamation" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($description: String!, $color: String!, $durationAmount: Int!, $durationType: String!, $statuses: [String!], $types: [String!], $priorities: [String!], $additionalEmails: [String!]) {
                            createTicketEscalationStep(description: $description, color: $color, durationAmount: $durationAmount, durationType: $durationType, statuses: $statuses, types: $types, priorities: $priorities, additionalEmails: $additionalEmails)
                        }
                    `,
                    variables: {
                        description: this.state.description,
                        color: this.state.color,
                        durationAmount: this.state.durationAmount,
                        durationType: this.state.durationType,
                        statuses: this.state.statuses,
                        types: this.state.types,
                        priorities: this.state.priorities,
                        additionalEmails: this.state.additionalEmails ? this.state.additionalEmails.split(",").filter(email => isEmail(email)) : []
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createTicketEscalationStep) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
