import React, { Component } from "react"
import { Page, Section, Breadcrumbs, Input, Grid, GridElement, Select, Button, Checkbox } from "scanmetrix-components"
import countries from "i18n-iso-countries"
import DeleteSubsidiaryTenantModal from "../subsidiary/styled/modals/DeleteTenantModal"
import i18next from "i18next";

countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = {
        custodian: null
    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    SubsidiaryTenant(id: $id) {
                        id
                        name
                        notes
                        telephone
                        email
                        telefax
                        securityDeposit
                        depositPaid
                        subsidiaryTransferable
                        specialty
                        easementRentalContract
                        easementRegistered
                        handoverDate
                        openingDate
                        plotArea
                        mainTenantArea
                        adjacentArea
                        general
                        features
                        rentStartDate
                        rentPaymentStartDate
                        minimumRent
                        salesRent
                        rentNotes
                        rentIncrease
                        parkingSpaceRental
                        advertisingSpaceRental
                        operatingDevicesRental
                        advancePaymentServiceCharges
                        ancillaryCostsFlatRate
                        advancePaymentServiceChargesAll
                        ancillaryCostsFlatRateAll
                        subsidiary {
                            id
                            name
                        }
                        address {
                            streetName
                            streetNumber
                            additional
                            postalCode
                            city
                            country
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.subsidiarytenantid
            }
        }).then(data => {
            this.setState({ subsidiaryTenant: data.data.SubsidiaryTenant })
        })
    }

    render() {
        if(!this.state.subsidiaryTenant) return null
        const subsidiaryTenant = this.state.subsidiaryTenant

        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Page {...this.props}>
            <DeleteSubsidiaryTenantModal id={subsidiaryTenant.id} history={this.props.history} instance={ref => this.deleteSubsidiaryTenantModal = ref} refresh={() => this.props.history.push("/subsidiary/" + subsidiaryTenant.subsidiary.id)} />
            <Breadcrumbs values={[
                {
                    icon: "building",
                    title: i18next.t("page.private.subsidiaryTenant.facilityManagementTitle"),
                    link: "/facilitymanagement"
                },
                {
                    icon: "map-marker",
                    link: "/subsidiary/" + subsidiaryTenant.subsidiary.id,
                    title: subsidiaryTenant.subsidiary.name
                },
                {
                    icon: "home",
                    title: i18next.t("page.private.subsidiaryTenant.subtenantTitle")
                },
                {
                    title: subsidiaryTenant.name
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.subsidiaryTenant.subtenant.sectionElementTitle")} icon="home">
                    <Input.MutationProvider value={{
                        name: "updateSubsidiaryTenant",
                        id: subsidiaryTenant.id,
                        data: subsidiaryTenant
                    }}>
                        <Grid columns={[ "1fr", "1fr" ]} gap="32px">
                            <GridElement styled title={i18next.t("page.private.subsidiaryTenant.subtenant.generalInformation.gridElementTitle")} icon="cog" columnStart={1} columnEnd={2} rightContent={<Button disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 4} secondary title={i18next.t("page.private.subsidiaryTenant.subtenant.generalInformation.button")} thick icon="trash" onClick={() => this.deleteSubsidiaryTenantModal.open(subsidiaryTenant)} />}>
                                <Grid gap="32px" padding="24px" columns={[ "1fr", "2fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="name" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.generalInformation.name")} icon="tag" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="notes" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.generalInformation.notes")} icon="sticky-note" />
                                    </GridElement>
                                </Grid>
                                <Grid gap="32px" padding="24px" columns={[ "1fr", "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="email" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.generalInformation.email")} icon="at" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="telephone" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.generalInformation.Telefon")} icon="phone" />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="telefax" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.generalInformation.fax")} icon="fax" />
                                    </GridElement>
                                </Grid>
                                <Grid gap="32px" padding="24px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="securityDeposit" format={val => val !== null ? formatter.format(val) : val} adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.generalInformation.deposit")} icon="piggyBank" float />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <div style={{ display: "flex", flexDirection: "column", height: 64, justifyContent: "center" }}>
                                            <Checkbox disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} label={i18next.t("page.private.subsidiaryTenant.subtenant.generalInformation.depositPaid")}field="depositPaid" />
                                        </div>
                                    </GridElement>
                                </Grid>
                            </GridElement>
                            <GridElement styled title={i18next.t("page.private.subsidiaryTenant.subtenant.address.gridElementTitle")} icon="map-marker" columnStart={2} columnEnd={3}>
                                <Grid gap="32px" padding="24px" columns={[ "1fr", "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="address.streetName" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.address.street")} icon="road" />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="address.streetNumber" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.address.houseNumber")} icon="bell" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="address.additional" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.address.additionalAddress")} icon="font" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="address.postalCode" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.address.zipCode")} icon="mailbox" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 3} field="address.city" adjustWidth label={i18next.t("page.private.subsidiaryTenant.subtenant.address.city")} icon="city" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={4}>
                                        <Select readOnly={scanmetrix.checkPermission("SubsidiaryTenant") < 3} noUnselect field="address.country" upwards adjustWidth icon="globe-europe" label={i18next.t("page.private.subsidiaryTenant.subtenant.address.country")} items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
                                    </GridElement>
                                </Grid>
                            </GridElement>
                        </Grid>
                    </Input.MutationProvider>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.subsidiaryTenant.rentalProperties.sectionElementTitle")} icon="house" disabled={scanmetrix.checkPermission("SubsidiarySubTenancySettings") === 0 && scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") === 0}>
                    <Input.MutationProvider value={{
                        name: "updateSubsidiaryTenant",
                        id: subsidiaryTenant.id,
                        data: subsidiaryTenant
                    }}>
                        <Grid gap="24px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                            {scanmetrix.checkPermission("SubsidiarySubTenancySettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiaryTenant.rentalProperties.general.gridElementTitle")} icon="info" columnStart={1} columnEnd={7}>
                                <Grid gap="32px" padding="32px" columns={[ "3fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancySettings") < 3} field="subsidiaryTransferable" adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.general.subsidiaryTransferable")} icon="question" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Grid columns={[ "1fr" ]} gap="16px">
                                            <GridElement>
                                                <Checkbox disabled={scanmetrix.checkPermission("SubsidiarySubTenancySettings") < 3} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.general.easementRentalContract")} field="easementRentalContract" />
                                            </GridElement>
                                            <GridElement>
                                                <Checkbox label={i18next.t("page.private.subsidiaryTenant.rentalProperties.general.easementRegistered")} contextualDisabled={data => !data.easementRentalContract || scanmetrix.checkPermission("SubsidiarySubTenancySettings") < 3} field="easementRegistered" />
                                            </GridElement>
                                        </Grid>
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancySettings") < 3} textArea field="features" adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.general.features")} icon="exclamation" />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                            {scanmetrix.checkPermission("SubsidiarySubTenancySettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiaryTenant.rentalProperties.plot.gridElementTitle")} icon="house" columnStart={1} columnEnd={5}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancySettings") < 3} field="plotArea" float format={val => val !== null ? `${val} m²` : null} adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.plot.plotArea")} icon="ruler-combined" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancySettings") < 3} field="mainTenantArea" float format={val => val !== null ? `${val} m²` : null} adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.plot.mainRentalArea")} icon="ruler-combined" />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancySettings") < 3} field="adjacentArea" float format={val => val !== null ? `${val} m²` : null} adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.plot.ancillaryArea")} icon="ruler-combined" />
                                    </GridElement>
                                    <GridElement columnStart={4} columnEnd={5}>
                                        <Input float format={val => val !== null ? `${val} m²` : null} adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.plot.totalArea")} icon="ruler-combined" contextualValue={data => ((data.mainTenantArea || 0) + (data.adjacentArea || 0) + " m²")} />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                            {scanmetrix.checkPermission("SubsidiarySubTenancySettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiaryTenant.rentalProperties.basicData.gridElementTitle")} icon="cog" columnStart={5} columnEnd={7}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancySettings") < 3} field="handoverDate" date adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.basicData.handoverDate")} icon="key" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancySettings") < 3} field="openingDate" date adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.basicData.openingDate")} icon="flag-checkered" />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                            {scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.gridElementTitle")} icon="house-return" columnStart={1} columnEnd={7}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="rentStartDate" adjustWidth date label={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.rentStartDate")} icon="hourglass-start" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="rentPaymentStartDate" adjustWidth date label={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.rentPaymentStartDate")} icon="hourglass-start" />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="minimumRent" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.minimumRent")} icon="piggy-bank" />
                                    </GridElement>
                                    <GridElement columnStart={4} columnEnd={5}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="salesRent" adjustWidth float format={val => val !== null ? `${val} %` : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.salesRent")} icon="empty-set" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="rentIncrease" adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.rentIncrease")} icon="chart-line" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="parkingSpaceRental" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.parkingSpaceRental")} icon="parking" />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="advertisingSpaceRental" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.advertisingSpaceRental")} icon="ad" />
                                    </GridElement>
                                    <GridElement columnStart={4} columnEnd={5}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="operatingDevicesRental" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.operatingDevicesRental")} icon="light-switch-on" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={5}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} textArea field="rentNotes" adjustWidth label={i18next.t("page.private.subsidiaryTenant.rentalProperties.rent.rentNotes")} icon="sticky-note" />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                            {scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiaryTenant.rentalProperties.serviceCharges.gridElementTitle")} icon="faucet-drip" columnStart={1} columnEnd={4}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="advancePaymentServiceCharges" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.serviceCharges.advancePaymentServiceCharges")} icon="faucet-drip" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="ancillaryCostsFlatRate" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.serviceCharges.ancillaryCostsFlatRate")} icon="faucet-drip" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="advancePaymentServiceChargesAll" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.serviceCharges.advancePaymentServiceChargesAll")} icon="hand-holding-usd" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") < 3} field="ancillaryCostsFlatRateAll" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.serviceCharges.ancillaryCostsFlatRateAll")} icon="hand-holding-usd" />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                            {scanmetrix.checkPermission("SubsidiarySubTenancyAccountingSettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiaryTenant.rentalProperties.costOverview.gridElementTitle")} icon="coins" columnStart={4} columnEnd={7}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input readOnly adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.costOverview.totalAncillaryCosts")} icon="faucet-drip" contextualValue={data => formatter.format((data.advancePaymentServiceCharges || 0) + (data.ancillaryCostsFlatRate || 0))} />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.costOverview.rentAndAncillaryCosts")} icon="equals" contextualValue={data => formatter.format((data.advancePaymentServiceCharges || 0) + (data.minimumRent || 0) + (data.ancillaryCostsFlatRate || 0))} />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.costOverview.ParkingAdvertisingOperatingCosts")} contextualValue={data => formatter.format((data.parkingSpaceRental || 0) + (data.advertisingSpaceRental || 0) + (data.operatingDevicesRental || 0) + (data.ancillaryCostsFlatRateAll || 0) + (data.advancePaymentServiceChargesAll || 0))} />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiaryTenant.rentalProperties.costOverview.totalPropertyCosts")} icon="equals" contextualValue={data => formatter.format((data.advancePaymentServiceCharges || 0) + (data.minimumRent || 0) + (data.ancillaryCostsFlatRate || 0) + (data.parkingSpaceRental || 0) + (data.advertisingSpaceRental || 0) + (data.operatingDevicesRental || 0) + (data.ancillaryCostsFlatRateAll || 0) + (data.advancePaymentServiceChargesAll || 0))} />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                        </Grid>
                    </Input.MutationProvider>
                </Section.Item>
                {/* TODO
                <Section.Item title="Niederlassungen" icon="map-marker">

                </Section.Item>
                <Section.Item title="Verträge" icon="file-contract">

                </Section.Item>*/}
            </Section>
        </Page>
    }
}
