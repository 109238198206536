import React, {Component, useRef} from "react"
import { Page } from "scanmetrix-components"
import styled from "styled-components"
import * as THREE from "three"
import {MapControls, Html, OrthographicCamera} from "@react-three/drei"
import {Canvas, useThree, useFrame} from "react-three-fiber"
import { DXFLoader } from "./converter"
import helvetiker_regular from 'three/examples/fonts/helvetiker_regular.typeface.json'
import triangulate from "delaunay-triangulate"
import CreateRoomModal from "./modals/CreateRoomModal";
import DeleteRoomModal from "./modals/DeleteRoomModal";
import CreateRoomUserModal from "./modals/CreateRoomUserModal";
import i18next from "i18next";

const RenderGroup = props => {
    if(!props.entities) return null

    return <group position={[ -props.centerX, -props.centerY, 0 ]}>
        {props.entities.map((entity, index) => {
            const recursion = (entity, color) => {
                if(entity.children.length) {
                    entity.children.forEach(child => recursion(child, color))
                } else {
                    if(entity.material && entity.material.color) {
                        if(color) {
                            if(!entity.material.originalColor) entity.material.originalColor = entity.material.color
                            entity.material.color = color
                        } else {
                            if(entity.material.originalColor) entity.material.color = entity.material.originalColor
                        }
                    }
                }
            }

            if(props.selectionMode) {
                if(props.selected.includes(index)) {
                    recursion(entity, { r: 1, g: 0, b: 1 })
                } else {
                    recursion(entity, { r: 0.15, g: 0.15, b: 0.15 })
                }
            } else {
                recursion(entity)
            }

            return entity
        }).map((entity, index) => <primitive onClick={() => {
            if(props.selectionMode) {
                if(props.selected.includes(index)) {
                    props.setState({ selected: props.selected.filter(i => i !== index) })
                } else {
                    props.setState({ selected: [...new Set([...props.selected, index])] })
                }
            }
        }} object={entity} key={index} dispose={null} />)}
    </group>
}

const LoadingContainer = styled.div`
    position: absolute;
    padding: 24px;
    color: white;
    left: 50%;
    top: 50%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transform: translate(-50%, -50%);
    z-index: 5;
    user-select: none;
`

const RoomContainer = styled.div`
    position: absolute;
    padding: 24px;
    color: white;
    left: 50%;
    top: 10%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transform: translate(-50%, -50%);
    z-index: 5;
    user-select: none;
`



export default class extends Component {
    state = { rooms: [], repositioning: false, creatingRoom: false, entities: null, points: [], centerX: 0, centerY: 0, selected: [], selectionMode: false }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()

        fetch(scanmetrix.backendURL + "/space/" + this.props.match.params.spaceid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.text()).then(data => {
            const instance = new DXFLoader()

            const loader = new THREE.FontLoader();
            const font = loader.parse(helvetiker_regular)
            instance.setFont(font)

            instance.loadString(data, data => {
                const center = data.dxf.tables.viewPort.viewPorts[0].center

                this.setState({ entities: data.entities, centerX: center.x, centerY: center.y })
            })
        })
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($spaceId: ID!) {
                    Rooms(filter: { spaceId_eq: $spaceId }) {
                        nodes {
                            id
                            name
                            area
                            geometry
                            users {
                                id
                                firstName
                                lastName
                                positionDescription
                                notes
                                user {
                                    id
                                    firstName
                                    lastName
                                    position
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                spaceId: this.props.match.params.spaceid
            }
        }).then(data => {
            this.setState({ rooms: data.data.Rooms.nodes })
        })
    }

    render() {
        let points = this.state.points.map(point => ([ point.x, point.y, 0 ]))
/*
        if(this.state.entities) {
            const recursion = entity => {
                if(entity.geometry) {
                    return entity.geometry.vertices
                } else if(entity.children) {
                    return entity.children.map(child => recursion(child))
                } else return []
            }

            points = this.state.selected.map(index => recursion(this.state.entities[index])).flat(100).filter(vector => !!vector).map(vector => [ vector.x, vector.y, vector.z ])
        }*/

        let object = null

        if(points.length >= 3) {
            //const geom = new THREE.Geometry()
            const shape = new THREE.Shape()

            points.forEach((point, index) => {
                if(index > 0 ) shape.lineTo(point[0], point[1])
                shape.moveTo(point[0], point[1])
            })

            const geom = new THREE.ShapeGeometry(shape)

            //points.forEach(point => geom.vertices.push(new THREE.Vector3(point[0], point[1], point[2])))
            //geom.vertices.push(new THREE.Vector3(points[0][0], points[0][1], points[0][2]))
/*
            let sorted = []

            points.forEach(point => {
                let found = false

                sorted.forEach(sort => {
                    if(sort[0] === point[0] && sort[1] === point[1]) found = true
                })

                if(!found) sorted.push(point)
            })

            let triangles = triangulate(sorted)

            sorted.forEach(point => {
                geom.vertices.push(new THREE.Vector3(point[0], point[1], point[2]))
            })

            triangles.forEach(triangle => {
                geom.faces.push(new THREE.Face3(triangle[0], triangle[1], triangle[2]))
            })

            geom.computeFlatVertexNormals()
*/
            object = new THREE.Mesh(geom, new THREE.MeshStandardMaterial({ color: 0x00ff00 }))
        }


        return <Page {...this.props} maxSize="100%" fullHeight full>
            <CreateRoomModal refresh={this.fetch} spaceId={this.props.match.params.spaceid} instance={ref => this.createRoomModal = ref} />
            <DeleteRoomModal refresh={this.fetch} instance={ref => this.deleteRoomModal = ref} />
            <CreateRoomUserModal refresh={this.fetch} instance={ref => this.createRoomUserModal = ref} />
            <div style={{ width: "100%", height: "100%", position: "relative" }} tabIndex={0} onKeyDown={event => {
                /*console.log(event.key)
                if(event.key === "Escape") {
                    if(this.state.creatingRoom) this.setState({ creatingRoom: false })
                    if(this.state.repositioning) this.setState({ repositioning: false })
                }*/
            }}>
                {!this.state.entities && <LoadingContainer>Lade Geometrie, nur einen Augenblick...</LoadingContainer>}
                {this.state.creatingRoom && <RoomContainer>Klicken Sie in die Mitte des Raumes, den Sie anlegen möchten. <b style={{ cursor: "pointer" }} onClick={() => this.setState({ creatingRoom: false })}>Abbrechen</b></RoomContainer>}
                {this.state.repositioning && <RoomContainer>Klicken Sie an die entsprechende Stelle, an welche Sie den Raum verschieben möchten. <b style={{ cursor: "pointer" }} onClick={() => this.setState({ repositioning: false })}>Abbrechen</b></RoomContainer>}
                <Canvas onClick={() => {
                    /*
                    let points = this.state.points

                    points.push(this.position)

                    this.setState({ points })*/
                    if(this.state.creatingRoom) {
                        this.createRoomModal.open(this.position)
                        this.setState({ creatingRoom: false })
                    } else if(this.state.repositioning) {
                        this.setState({ repositioning: false })

                        const position = this.position
                        const roomId = this.roomId

                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($roomId: ID!, $geometry: JSON!) {
                                    updateRoomGeometry(roomId: $roomId, geometry: $geometry)
                                }
                            `,
                            variables: {
                                roomId,
                                geometry: JSON.stringify({ center: { x: position.x, y: position.y } })
                            }
                        }).then(() => {
                            this.fetch()
                        })
                    }
                }} style={{ width: "100%", height: "100%", outline: 0, background: "#20242b", cursor: (this.state.creatingRoom || this.state.repositioning) ? "crosshair" : "default" }} onCreated={({ gl, scene }) => {}}>
                    <OrthographicCamera makeDefault ref={ref => {
                        this.cameraRef = ref
                    }} zoom={2} pan={[ 5000, 0 ]} position={[ 0, 0, 400 ]} near={Number.MAX_VALUE} />
                    <MousePosition update={(x, y) => this.position = ({ x, y })} />
                    {this.state.rooms.map((room, index) => <MapRoom deleteRoomUser={roomUserId => {
                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($id: ID!) {
                                    deleteRoomUser(id: $id)
                                }
                            `,
                            variables: {
                                id: roomUserId
                            }
                        }).then(() => {
                            this.fetch()
                        })
                    }} users={room.users} createRoomUser={() => this.createRoomUserModal.open(room.id)} repositioning={this.state.repositioning} reposition={() => {
                        this.setState({ repositioning: true })
                        this.roomId = room.id
                    }} area={room.area} position={JSON.parse(room.geometry).center} key={index} title={room.name} />)}
                    {object && <primitive object={object} />}
                    <RenderGroup {...this.state} setState={state => this.setState(state)} />
                    {/*points.map((point, index) => <mesh key={index} visible position={point}>
                        <sphereGeometry attach="geometry" args={[ 4, 16, 16 ]} />
                        <meshStandardMaterial
                            attach="material"
                            color="white"
                            transparent
                            roughness={0.1}
                            metalness={0.1}
                        />
                    </mesh>)*/}
                    <MapControls screenSpacePanning={true} enableRotate={false} />
                </Canvas>
            </div>
            <Controls pan={(x, y) => {
                this.cameraRef.position.x = x
                this.cameraRef.position.y = y
                this.cameraRef.position.z = 400
                this.cameraRef.up.set(0, 0, 0)
                this.cameraRef.lookAt(x, y, 0)

                this.cameraRef.updateProjectionMatrix()
            }} deleteRoom={id => this.deleteRoomModal.open(id)} rooms={this.state.rooms} disabled={this.state.creatingRoom} createRoom={() => {
                this.setState({ creatingRoom: true })
            }} />
        </Page>
    }
}

const Dropdown = styled.div`
    position: absolute;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 10px);
  min-width: 240px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 16px;
  color: #20242b;
  cursor: default;
  
  .maximum {
      font-size: 0.8em;
  }
  
  .user {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: 1px solid #3b97d3;
      padding: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 4px;
    box-sizing: border-box;
    flex-direction: column;
    
    .fa-trash {
        cursor: pointer;
      
      &:hover {
          opacity: 0.75;
      }
    }
    
    .top {
        display: flex;
      justify-content: space-between;
      width: 100%;
    }
    
      .name {
          font-weight: bold;
      }
    
      .notes {
        font-style: italic;
        font-size: 0.9em;
        margin-top: 4px;
      }
  }

  .reposition {
      width: 100%;
      text-align: center;
    text-decoration: underline;
    color: #3b97d3;
    margin-top: 8px;
    font-size: 0.9em;
    display: block;
    font-weight: bold;
    cursor: pointer;
    
    &:hover {
      opacity: 0.75;
    }
  }
  
  .button {
    border-radius: 5px;
    width: 100%;
    background: #3b97d3;
    color: white;
    cursor: pointer;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    user-select: none;
    padding: 0 12px;
    box-sizing: border-box;
    margin-top: 8px;
    font-size: 0.9em;

    &.disabled {
      background: gray;
      cursor: not-allowed;
    }

    i {
      margin-right: 8px;
    }

    &:hover {
      opacity: 0.75;
    }
  }
  
  .title {
      font-size: 1.1em;
      margin-bottom: 4px;
      font-weight: bold;
  }
  
  &:after {
      content: " ";
      display: inline-block;
      width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    position: absolute;
    bottom: -9px;
    left: calc(50% - 10px);
  }
`

const RoomOnMap = styled.div`
    color: white;
    padding: 8px;
    border: 2px solid #3b97d3;
      z-index: 10;
      user-select: none;
      display: flex;
      flex-direction: column;
      background: rgba(255, 255, 255, 0.05);
      cursor: pointer;
  position: relative;
  
  .top.title, .top.area, .top.users {
      white-space: nowrap;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  
  .top.title {
      font-weight: bold;
      margin-bottom: 4px;
  }
  
  .top.users {
      margin-top: 8px;
  }
`

function MousePosition(props) {
    const { camera, viewport } = useThree()

    const ref = useRef()

    let absoluteX, absoluteY

    useFrame(({ mouse }) => {
        const vp = viewport()
        const x = camera.position.x
        const y = camera.position.y
        const mouseX = mouse.x
        const mouseY = mouse.y
        const width = vp.width
        const height = vp.height

        absoluteX = x + mouseX * (width / 2)
        absoluteY = y + mouseY * (height / 2)

        props.update(absoluteX, absoluteY)
    })

    return <mesh frustumCulled={false} ref={ref}></mesh>
}

class FunctionalRoom extends Component {
    state = { open: false }

    render() {
        let maximum = 0

        if(this.props.area > 8) {
            maximum = Math.floor((this.props.area - 8) / 6) + 1
        }

        return <RoomOnMap onClick={() => this.setState({ open: !this.state.open })}>
            {this.state.open && !this.props.repositioning && <Dropdown onClick={e => e.stopPropagation()}>
                <p className="title">{this.props.title}</p>
                <p className="area">Fläche: {this.props.area} m²</p>
                <p className="maximum">Max. Arbeitsplätze nach ASR A1.2: <b>{maximum} Person(-en)</b></p>
                {this.props.users.map((user, index) => <div className="user" key={index}>
                    <div className="top">
                        {user.user && <p className="name">
                            {user.user.firstName} {user.user.lastName} <i className="far fa-trash" onClick={() => this.props.deleteRoomUser(user.id)} />
                        </p>}
                        {!user.user && <p className="name">
                            {user.firstName} {user.lastName} <i className="far fa-trash" onClick={() => this.props.deleteRoomUser(user.id)} />
                        </p>}
                        <p className="position">{user.user ? user.user.position : user.positionDescription}</p>
                    </div>
                    {user.notes && <div className="notes">{user.notes}</div>}
                </div>)}
                <div className="button" onClick={() => this.props.createRoomUser()}><i className="far fa-user-plus" />Person hinzufügen</div>
                <a className="reposition" onClick={() => this.props.reposition()}>Repositionieren</a>
            </Dropdown>}
            <p className="top title">{this.props.title}</p>
            <p className="top area">{this.props.area} m²</p>
            <p className="top users"><i className="far fa-users" /> {this.props.users.length} Person(-en)</p>
        </RoomOnMap>
    }
}

function MapRoom(props) {
    /*const { camera, viewport } = useThree()

    const ref = useRef()

    let absoluteX, absoluteY

    useFrame(({ mouse }) => {
        const vp = viewport()
        const x = camera.position.x
        const y = camera.position.y
        const mouseX = mouse.x
        const mouseY = mouse.y
        const width = vp.width
        const height = vp.height

        absoluteX = x + mouseX * (width / 2)
        absoluteY = y + mouseY * (height / 2)

        //props.update(absoluteX, absoluteY)
    })*/

    return <mesh frustumCulled={false} position={[ props.position.x, props.position.y, 0 ]}>
        <Html center frustumCulled={false}>
            <FunctionalRoom deleteRoomUser={roomUserId => props.deleteRoomUser(roomUserId)} users={props.users} createRoomUser={() => props.createRoomUser()} repositioning={props.repositioning} reposition={() => props.reposition()} title={props.title} area={props.area} />
        </Html>
    </mesh>
}

const StyledControls = styled.div`
    z-index: 5;
    position: fixed;
    right: ${props => props.open ? 0 : "-300px"};
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    transition: right 0.4s;
    
    .drag {
        background: white;
        padding: 16px;
        user-select: none;
        cursor: pointer;
        border-radius: 5px 0 0 5px;
    }
    
    .content {
        width: 300px;
        background: white;
        padding: 16px;
        min-height: 512px;
        box-sizing: border-box;
        border-radius: 5px 0 0 5px;
        
        .button {
            border-radius: 5px;
            width: 100%;
            background: #3b97d3;
            color: white;
            cursor: pointer;
            transition: opacity 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            user-select: none;
          
            &.disabled {
                background: gray;
                cursor: not-allowed;
            }
            
            i {
                margin-right: 8px;
            }
            
            &:hover {
                opacity: 0.75;
            }
        }
        
        .title {
            margin-top: 16px;
            font-size: 1.1em;
            user-select: none;
        }
    }
`

const StyledRoom = styled.div`
    display: flex;
    height: 48px;
    width: 100%;
    border: 1px solid #20242b;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 16px;
    margin-top: 8px;
    user-select: none;
    cursor: pointer;
    border-radius: 5px;
    justify-content: space-between;
  
    i:first-child {
        margin-right: 8px;
    }
  
    .fa-trash {
        width: 24px;
        height: 24px;
        display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
        &:hover {
            color: #20242b;
            background: white;
        }
    }
  
    &:hover {
        background: #20242b;
        color: white;
    }
`

class Room extends Component {
    render() {
        return <StyledRoom onClick={() => {
            this.props.pan()
        }}>
            <p>
                <i className="far fa-layer-group" />{this.props.title}
            </p>
            <i className="far fa-trash" onClick={e => {
                e.stopPropagation()

                this.props.delete()
            }} />
        </StyledRoom>
    }
}

const NoRooms = styled.div`
  display: block;
  margin: 32px auto 0;
  font-style: italic;
  max-width: 60%;
  opacity: 0.75;
  text-align: center;
  user-select: none;
`

class Controls extends Component {
    state = { open: false }

    render() {
        return <StyledControls open={this.state.open && !this.props.disabled}>
            <div className="drag" onClick={() => this.setState({ open: !this.state.open })}>
                <i className={`far fa-chevron-${(this.state.open && !this.props.disabled) ? "right" : "left"}`} />
            </div>
            <div className="content">
                <div className={`button ${this.props.disabled ? "disabled" : ""}`} onClick={() => this.props.createRoom()}><i className="far fa-layer-plus" />{i18next.t("page.private.space.newRoomForm.title")}</div>
                <p className="title">{i18next.t("page.private.space.newRoomForm.rooms")}</p>
                {this.props.rooms.length === 0 && <NoRooms>{i18next.t("page.private.space.newRoomForm.noRoomsCreated")}</NoRooms>}
                {this.props.rooms.map((room, index) => <Room pan={() => this.props.pan(JSON.parse(room.geometry).center.x, JSON.parse(room.geometry).center.y)} delete={() => this.props.deleteRoom(room.id)} title={room.name} key={index} />)}
            </div>
        </StyledControls>
    }
}
