import styled from "styled-components"

export default styled.div`
  
  
  height: 100%;
  background: white;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
  @media screen and (max-width: 1919px) {
    zoom: 0.85;
    
    h1 {
      font-size: 1.75em;
    }
  }




.widgetSelect{
  

  display: grid;
  grid-template-columns: repeat(2, 1fr);  /* 5 Spalten, jede 2em breit */
  position: relative;
  grid-gap: 12px;
  padding: 10px 12px 12px 12px;
  
  
  .box-body{
    width: 100%;
    height: 0;
    padding-top: 100%;
    background: white;
    position: relative;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.15);
    color: #2C3E50;
    font-size: 0.9em;
    
    &.clickable {
        cursor: pointer;
        transition: opacity 0.3s;
      
        &:hover {
          opacity: 0.75;
        }
    }

    @media screen and (max-width: 2200px) {
      zoom: 0.95;
    }
    
    .content {
      position: absolute;
      bottom: 12px;
      left: 20px;
      user-select: none;
      padding-right: 8px;
      
      i {
        font-size: 3em;
        height: 32px;
        opacity: 0.6;
      }
      
      .title {
        margin-top: 24px;
        margin-bottom: 8px;
        opacity: 0.75;
      }
      
      .value {
        height: 42px;
        line-height: 42px;
        text-shadow: 0 2px 2px rgba(0, 0, 0, .2);
        font-size: 2.4em;
        font-weight: bold;
      }
    }
  }

  
  
}

`
