import React, { Component } from "react"
import { Table, Grid, GridElement, Section } from "scanmetrix-components"
import i18next from "i18next"

import styled from "styled-components"
import Chart from "react-apexcharts"
import BarLoader from "react-spinners/BarLoader"

const ChartContainer = styled.div`
  background: white;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 8px 24px -16px rgba(0,0,0,0.4);
  margin-bottom: 48px;
`

const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  
  h1 {
    font-weight: 300;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 1600px) {
    zoom: 0.75;
  }
`

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

let categories = []

for(let i = 18; i > 0; i--) {
    categories.push(moment().subtract(i - 1, "months").format("MMM YY"))
}

export default class extends Component {
    state = { invoices: [],
        loading: true,
        options: {
            chart: {
                height: 350,
                width: "100%",
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            colors: [ "#3b97d3" ],
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: formatter.format,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories,
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#3b97d3',
                            colorTo: '#3b97d3',
                            stops: [0, 100],
                            opacityFrom: 0.75,
                            opacityTo: 0,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: formatter.format
                }

            },
            title: {
                text: i18next.t("page.private.accounting.statistics.monthlyCost"),
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: '#444'
                }
            }
        } }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($status: String!, $declined: Boolean!) {
                    AccountingInvoices(filter: { status_eq: $status, declined_eq: $declined }) {
                        nodes {
                            id
                            date
                            net
                            number
                            accountingInvoiceDocument {
                                id
                            }
                            serviceProvider {
                                id
                                name
                                responsibility
                            }
                            subsidiary {
                                id
                                name
                                label
                            }
                        }
                    }
                }
            `,
            variables: {
                status: "archived",
                declined: false
            }
        }).then(data => {
            this.setState({ invoices: data.data.AccountingInvoices.nodes, loading: false })
        })

        /*fetch(scanmetrix.backendURL + "/invoices", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ invoices: data.invoices, loading: false })
        })*/
    }

    render() {
        let dataInvoices = []
        //let dataOffers = []

        for(let i = 18; i > 0; i--) {
            let totalInvoices = 0
            //let totalOffers = 0

            this.state.invoices.forEach(invoice => {
                if(moment(invoice.date).year() === moment().subtract(i - 1, "months").year() && moment(invoice.date).month() === moment().subtract(i - 1, "months").month()) totalInvoices += invoice.net
            })

            /*this.state.invoices.filter(invoice => invoice.type === "offer").forEach(invoice => {
                if(moment(invoice.date).year() === moment().subtract(i - 1, "months").year() && moment(invoice.date).month() === moment().subtract(i - 1, "months").month()) totalOffers += invoice.total
            })*/

            dataInvoices.push(totalInvoices)
            //dataOffers.push(totalOffers)
        }

        return <>
            {this.state.loading && <Loading>
                <h1>{i18next.t("page.private.accounting.statistics.loadingBillingData")}</h1>
                <BarLoader width="100%" height="8px" loading color="#3b97d3" />
            </Loading>}
            {!this.state.loading && <>
                    <ChartContainer>
                        <Chart options={this.state.options} series={[{
                            name: 'Kosten',
                            data: dataInvoices
                        }]} type="bar" height={350} />
                    </ChartContainer>
                    <Grid gap="32px" columns={[ "1fr" ]}>
                        <GridElement styled title={i18next.t("page.private.accounting.statistics.gridElementTitle")} icon="money-bill-wave">
                            <Table
                                head={[
                                    { title: i18next.t("page.private.accounting.statistics.table.date"), width: "10%", column: "date" },
                                    { title: i18next.t("page.private.accounting.statistics.table.number"), width: "17.5%", column: "number" },
                                    { title: i18next.t("page.private.accounting.statistics.table.serviceProvider"), width: "20%", column: "serviceProvider" },
                                    { title: i18next.t("page.private.accounting.statistics.table.workSection"), width: "15%", column: "workSection" },
                                    { title: i18next.t("page.private.accounting.statistics.table.subsidiary"), width: "27.5%", column: "subsidiary" },
                                    { title: i18next.t("page.private.accounting.statistics.table.cost"), width: "10%", column: "cost", format: formatter.format }
                                ]}
                                onItemClick={item => window.open(scanmetrix.backendURL + "/accounting-invoice-document/" + item.data.accountingInvoiceDocument.id, "_blank")}
                                items={this.state.invoices.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(invoice => ({
                                    data: invoice,
                                    id: invoice.id,
                                    number: invoice.number,
                                    serviceProvider: invoice.serviceProvider.name,
                                    workSection: invoice.serviceProvider.responsibility,
                                    cost: invoice.net,
                                    date: moment(invoice.date).format("DD.MM.YYYY"),
                                    subsidiary: invoice.subsidiary ? `${invoice.subsidiary.name} (${invoice.subsidiary.label})` : null,
                                    subsidiaryId: invoice.subsidiary ? invoice.subsidiary.id : null,
                                    serviceProviderId: invoice.serviceProvider.id
                                }))}
                            />
                        </GridElement>
                    </Grid>
                    {/*<Section.Item title="Rechnungen" icon="money-bill-wave">

                    </Section.Item>
                    <Section.Item title="Angebote" icon="pencil-alt">
                        <ChartContainer>
                            <Chart options={this.state.options} series={[{
                                name: 'Kosten',
                                data: dataOffers
                            }]} type="bar" height={350} />
                        </ChartContainer>
                        <Grid gap="32px" columns={[ "1fr" ]}>
                            <GridElement styled title="Erhaltene Angebote" icon="pencil-alt">
                                <Table
                                    head={[
                                        { title: "Datum", width: "10%", column: "date" },
                                        { title: "Angebotsnummer", width: "17.5%", column: "number" },
                                        { title: "Dienstleister", width: "12.5%", column: "serviceProvider" },
                                        { title: "Gewerk", width: "15%", column: "workSection" },
                                        { title: "Betreff", width: "20%", column: "topic" },
                                        { title: "Niederlassung", width: "15%", column: "subsidiary" },
                                        { title: "Nettobetrag", width: "10%", column: "cost", format: formatter.format }
                                    ]}
                                    onItemClick={data => {
                                        window.open(scanmetrix.backendURL + "/invoice/" + data.serviceProviderId + "/" + data.id, "_blank")
                                    }}
                                    items={this.state.invoices.filter(invoice => invoice.type === "offer").sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(invoice => ({
                                        id: invoice.id,
                                        number: invoice.number,
                                        serviceProvider: invoice.serviceProvider.name,
                                        workSection: invoice.serviceProvider.responsibility,
                                        topic: invoice.subject,
                                        cost: invoice.total,
                                        date: moment(invoice.date).format("DD.MM.YYYY"),
                                        subsidiary: `${invoice.customerSubsidiary.name} (${invoice.customerSubsidiary.label})`,
                                        subsidiaryId: invoice.customerSubsidiary.id,
                                        serviceProviderId: invoice.serviceProvider.id
                                    }))}
                                />
                            </GridElement>
                        </Grid>
                    </Section.Item>
                </Section>*/}
            </>}
        </>
    }
}
