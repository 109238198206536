import styled from 'styled-components'

export default styled.div`
    min-width: 768px;
    
    h2 {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: normal;
    }
`  