import styled from "styled-components"

export default styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  transition: opacity 0.3s;
    
  &:hover {
    opacity: 0.5;
  }
    
  &:last-child {
    margin-bottom: 0;
  }
  
  .title {
    font-size: 0.9em;
    text-decoration: ${props => props.checked ? "line-through" : "none"};
    opacity: ${props => props.checked ? 0.5 : 1};
  }
  
  .checkbox {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 16px;
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.25);
  }
`
