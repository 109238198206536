import React, { Component } from "react"
import { Responsive, WidthProvider } from "react-grid-layout"
import { GridElement, Button, Modal, Input, IconSelector } from "scanmetrix-components"
import { v4 as uuid } from "uuid"
import styled from "styled-components"
import i18next from "i18next";

const ResponsiveGridLayout = WidthProvider(Responsive)

class DeleteFactSheetModal extends Component {
    state = { factSheetId: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={factSheetId => this.setState({ factSheetId, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.factSheetsFolder.DeleteFactSheetModal.title")} icon="trash" />
            <Modal.Body padding={24}>
                {i18next.t("page.private.settings.factSheetsFolder.DeleteFactSheetModal.description")}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} red thick title={i18next.t("page.private.settings.factSheetsFolder.DeleteFactSheetModal.deleteButton")} icon="trash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            deleteFactSheet(id: $id)
                        }
                    `,
                    variables: {
                        id: this.state.factSheetId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.deleteFactSheet) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}

class DeleteFactSheetElementModal extends Component {
    state = { callback: null }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={callback => this.setState({ callback })}>
            <Modal.Head title={i18next.t("page.private.settings.factSheetsFolder.DeleteFactSheetElementModal.title")} icon="trash" />
            <Modal.Body padding={24}>
                {i18next.t("page.private.settings.factSheetsFolder.DeleteFactSheetElementModal.description")}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button red thick title={i18next.t("page.private.settings.factSheetsFolder.DeleteFactSheetElementModal.deleteButton")} icon="trash" onClick={() => {
                this.state.callback()
                instance.close()
            }} />} />
        </Modal>
    }
}

class CreateFactSheetModal extends Component {
    state = { name: null, icon: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={() => this.setState({ name: null, icon: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.factSheetsFolder.CreateFactSheetModal.title")} icon="file-spreadsheet" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <GridElement>
                        <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.settings.factSheetsFolder.CreateFactSheetModal.name")} adjustWidth />
                    </GridElement>
                    <GridElement>
                        <IconSelector value={this.state.icon} onChange={icon => this.setState({ icon })} />
                    </GridElement>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.icon} title={i18next.t("page.private.settings.factSheetsFolder.CreateFactSheetModal.exitButton")} icon="file-spreadsheet" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $icon: String!) {
                            createFactSheet(name: $name, icon: $icon)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        icon: this.state.icon
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createFactSheet) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}

class UpdateFactSheetModal extends Component {
    state = { id: null, name: null, icon: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={data => this.setState({ id: data.id, name: data.name, icon: data.icon, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.factSheetsFolder.UpdateFactSheetModal.title")} icon="file-spreadsheet" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <GridElement>
                        <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.settings.factSheetsFolder.UpdateFactSheetModal.name")} adjustWidth />
                    </GridElement>
                    <GridElement>
                        <IconSelector value={this.state.icon} onChange={icon => this.setState({ icon })} />
                    </GridElement>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.icon} title={i18next.t("page.private.settings.factSheetsFolder.UpdateFactSheetModal.exitButton")} icon="file-spreadsheet" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $name: String!, $icon: String!) {
                            updateFactSheet(id: $id, name: $name, icon: $icon)
                        }
                    `,
                    variables: {
                        id: this.state.id,
                        name: this.state.name,
                        icon: this.state.icon
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.updateFactSheet) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}

class FactSheet extends Component {
    state = { elements: [] }

    constructor(props) {
        super(props)

        this.state.elements = props.data.elements

        this.save = this.save.bind(this)
    }

    save() {
        if(this.timeout) {
            clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
            this.timeout = null

            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($id: ID!, $elements: JSON) {
                        updateFactSheet(id: $id, elements: $elements)
                    }
                `,
                variables: {
                    id: this.props.data.id,
                    elements: this.state.elements
                }
            }).then(console.log)
        }, 300)
    }

    render() {
        const data = this.props.data

        return <GridElement styled title={data.name} icon={data.icon} rightContent={<div style={{ display: "grid", gridGap: 16, gridAutoFlow: "column" }}>
            <Button thick title={i18next.t("page.private.settings.factSheetsFolder.inputElementButton")} icon="plus" onClick={() => {
                let elements = this.state.elements

                elements.push({
                    id: uuid(),
                    x: 0,
                    y: Infinity,
                    h: 1,
                    w: 2,
                    minW: 2,
                    minH: 1,
                    maxH: 3,
                    maxW: 6,
                    label: null
                })

                this.setState({ elements }, this.save)
            }} />
            <Button thick title={i18next.t("page.private.settings.factSheetsFolder.updateButton")} icon="pencil-alt" onClick={() => this.props.update()} />
            <Button thick title={i18next.t("page.private.settings.factSheetsFolder.deleteButton")} red icon="trash" onClick={() => this.props.delete()} />
        </div>}>
            <ResponsiveGridLayout onLayoutChange={layout => {
                const elements = this.state.elements.map(element => {
                    const found = layout.find(item => item.i === element.id)

                    return ({
                        ...element,
                        x: found.x,
                        y: found.y,
                        w: found.w,
                        h: found.h
                    })
                })

                this.setState({ elements }, this.save)
            }} margin={[32, 32]} containerPadding={[32, 32]} measureBeforeMount className="layout" breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}} cols={{lg: 6, md: 6, sm: 6, xs: 6, xxs: 6}} rowHeight={96}>
                {this.state.elements.map(element => <div key={element.id} data-grid={element}>
                    <ItemContainer>
                        <Input label={i18next.t("page.private.settings.factSheetsFolder.fieldName")} adjustWidth value={element.label} onChange={label => {
                            const elements = this.state.elements.map(el => {
                                if(el.id !== element.id) return el

                                return ({
                                    ...element,
                                    label
                                })
                            })

                            this.setState({ elements }, this.save)
                        }} />
                        <i className="far fa-trash delete" onClick={() => this.props.deleteElement(() => {
                            const elements = this.state.elements.filter(el => el.id !== element.id)

                            this.setState({ elements }, this.save)
                        })} />
                    </ItemContainer>
                </div>)}
            </ResponsiveGridLayout>
        </GridElement>
    }
}

const FactSheetsContainer = styled.div`
  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
      z-index: 10;
  }
`

const ItemContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px;
    box-sizing: border-box;
  
    .delete {
        position: absolute;
        z-index: 9;
        right: 16px;
        bottom: 32px;
        transform: translateY(50%);
        cursor: pointer;
        font-size: 18px;
        transition: color 0.3s, opacity 0.3s;
        opacity: 0.5;
      
        &:hover {
            opacity: 1;
            color: #e74c3c;
        }
    }
`

export default class extends Component {
    state = { factSheets: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                {
                    FactSheets {
                        nodes {
                            id
                            name
                            icon
                            elements
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ factSheets: data.data.FactSheets.nodes })
        })
    }

    render() {
        return <FactSheetsContainer>
            <CreateFactSheetModal refresh={() => this.fetch()} instance={ref => this.createFactSheetModal = ref} />
            <UpdateFactSheetModal refresh={() => this.fetch()} instance={ref => this.updateFactSheetModal = ref} />
            <DeleteFactSheetModal refresh={() => this.fetch()} instance={ref => this.deleteFactSheetModal = ref} />
            <DeleteFactSheetElementModal instance={ref => this.deleteFactSheetElementModal = ref} />
            <Button title={i18next.t("page.private.settings.factSheetsFolder.createFactSheetButton")} icon="file-spreadsheet" thick onClick={() => this.createFactSheetModal.open()} />
            <div style={{ width: "100%", display: "grid", gridAutoFlow: "row", gridGap: 24, marginTop: 32 }}>
                {this.state.factSheets.map(factSheet => <FactSheet key={factSheet.id} data={factSheet} update={() => this.updateFactSheetModal.open(factSheet)} deleteElement={callback => this.deleteFactSheetElementModal.open(callback)} delete={() => this.deleteFactSheetModal.open(factSheet.id)} />)}
            </div>
        </FactSheetsContainer>
    }
}
