import styled from "styled-components"

export default styled.div`
    margin-top: 8px;
    
    @media screen and (max-width: 1600px) {
      margin: ${props => props.margin ? props.margin : "8px 0 0 0" };
    }
    
    @media screen and (max-width: 1280px) {
      zoom: ${props => props.responsiveZoom ? props.responsiveZoom : "" };
    }
`
