import React, { Component } from 'react'
import { Button, Modal } from 'scanmetrix-components'
import Dropzone from 'react-dropzone'
import styled from "styled-components"
import prettyBytes from "pretty-bytes"
import i18next from "i18next"

const UploadBody = styled.div`
  flex: 1;
  width: 500px;
  height: 250px;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 250ms ease;
  position: relative;
  box-shadow: ${props => props.isDragActive ? "inset 0 0 0 4px #3b97d3" : "inset 0 0 0 1px rgba(0, 0, 0, 0.25)"};
  border-radius: 5px;

  &:hover {
    opacity: 0.75;
  }
  
  input {
    display: none;
  }

  i {
    font-size: 28px;
    margin-bottom: 24px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 50%;
  }
`

const StyledFile = styled.div`
    padding: 24px;
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 5px;
  
    .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 350px;
        margin-right: 16px;
    }
  
    .delete {
        margin-left: 12px;
        cursor: pointer;
        color: #e74c3c;
        transition: all 0.3s;
      
        &:hover {
            opacity: 0.75;
        }
    }
`

export default class extends Component {
    state = { file: null, loading: false }

    render() {
        return <Modal instance={this.props.instance} initialize={() => this.setState({ file: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.accounting.unbooked.uploadInvoiceModal.titleForm")} icon="upload" />
            <Modal.Body padding={32}>
                {!this.state.file && <Dropzone accept={[ "application/pdf" ]} multiple={false} onDrop={acceptedFiles => {
                    if(acceptedFiles.length && !this.state.loading) {
                        const file = acceptedFiles[0]

                        this.setState({ file })
                    }
                }}>
                    {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody tabIndex={0} {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef", className: 'dropzone' })}>
                        <input {...getInputProps()} tabIndex={-1} accept='application/pdf' multiple={true} type='file' autoComplete='off' />

                        <i className="far fa-upload" />
                        <p className="title">{i18next.t("page.private.accounting.unbooked.uploadInvoiceModal.billPDF")}</p>
                    </UploadBody>}
                </Dropzone>}
                {this.state.file && <StyledFile>
                    <div className="name">{this.state.file.name}</div>
                    <div className="size">{prettyBytes(this.state.file.size)} <i className="far fa-trash delete" onClick={() => {
                        if(!this.state.loading) this.setState({ file: null })
                    }} /></div>
                </StyledFile>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.file} thick title={i18next.t("page.private.accounting.unbooked.uploadInvoiceModal.exitButton")} icon="upload" onClick={() => {
                this.setState({ loading: true })

                return scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($file: Upload!) {
                            uploadAccountingInvoiceDocument(file: $file)
                        }
                    `,
                    context: {
                        hasUpload: true
                    },
                    variables: {
                        file: this.state.file
                    }
                }).then(result => {
                    if (result.data.uploadAccountingInvoiceDocument) {
                        return this.props.fetch().then(() => instance.close())
                    }
                })
            }} />} />
        </Modal>
    }
}
