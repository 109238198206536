import React, { Component } from "react"
import { GridElement, Table, Button } from "scanmetrix-components"
import CreateCarDriverModal from "./modals/CreateCarDriverModal"

import DeleteCarDriverModal from "./modals/DeleteCarDriverModal";
import UpdateCarDriverModal from "./modals/UpdateCarDriverModal";

export default class extends Component {
    state = { drivers: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($carId: ID!) {
                    CarDrivers(filter: { carId_eq: $carId }) {
                        nodes {
                            id
                            firstName
                            lastName
                            telephone
                            email
                            birthday
                            residency
                            privateUseAllowed
                            driversLicenseSince
                            driversLicenseValidUntil
                            driversLicenseId
                            driversLicenseTypes
                            since
                            until
                        }
                    }
                }
            `,
            variables: {
                carId: this.props.car.id
            }
        }).then(data => {
            this.setState({ drivers: data.data.CarDrivers.nodes })
        })
    }

    render() {
        return <>
            <CreateCarDriverModal instance={ref => this.createCarDriverModal = ref} car={this.props.car} refresh={() => this.fetch()} />
            <DeleteCarDriverModal instance={ref => this.deleteCarDriverModal = ref} refresh={() => this.fetch()} />
            <UpdateCarDriverModal instance={ref => this.updateCarDriverModal = ref} refresh={() => this.fetch()} />
            <GridElement styled title="Fahrer" icon="steering-wheel" rightContent={<Button thick icon="steering-wheel" title="Fahrer erstellen" onClick={() => this.createCarDriverModal.open()} />}>
                <Table
                    head={[
                        { title: "Name", width: "22.5%", column: "name" },
                        { title: "Wohnsitz", width: "10%", column: "residency" },
                        { title: "Führerscheinnr.", width: "10%", column: "driversLicenseId" },
                        { title: "Gültig bis", width: "7.5%", column: "driversLicenseValidUntil" },
                        { title: "Telefonnr.", width: "12.5%", column: "telephone" },
                        { title: "E-Mail", width: "12.5%", column: "email" },
                        { title: "Ab", width: "7.5%", column: "since" },
                        { title: "Bis", width: "7.5%", column: "until" },
                        { title: "Priv. Nutzung", width: "10%", column: "privateUseAllowed" }
                    ]}
                    items={this.state.drivers ? this.state.drivers.map(driver => ({
                        ...driver,
                        driver,
                        since: driver.since ? moment(driver.since).format("DD.MM.YYYY") : null,
                        until: driver.until ? moment(driver.until).format("DD.MM.YYYY") : null,
                        driversLicenseValidUntil: driver.driversLicenseValidUntil ? moment(driver.driversLicenseValidUntil).format("DD.MM.YYYY") : null,
                        privateUseAllowed: driver.privateUseAllowed ? <div><i className="far fa-check-circle" />Ja</div> : <div><i className="far fa-times-circle" />Nein</div>,
                        name: `${driver.firstName} ${driver.lastName}`
                    })) : null}
                    onItemClick={item => this.updateCarDriverModal.open(item.driver)}
                    contextItems={[
                        { title: "Eintrag löschen", icon: "trash", onClick: item => this.deleteCarDriverModal.open(item.id)}
                    ]}
                />
            </GridElement>
        </>
    }
}
