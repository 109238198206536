import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h1, h2 {
    text-align: center;
  }
  
  .buttons {
    display: flex;
    margin-top: 32px;
    flex-wrap: nowrap;
    
    button:first-child {
      margin-right: 12px;
    }
  }
`