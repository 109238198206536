import React, { Component } from "react"
import { Modal, Button, Select, Checkbox } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { workSectionId: null, standardEquipment: true, items: [], loading: false }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetch()
    }

    fetch() {
        fetch(scanmetrix.backendURL + "/marketplace/work_sections", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(!data.error) this.setState({ items: data.items })
        })
    }

    render() {
        return <Modal minWidth="512px" instance={this.props.instance} initialize={() => this.setState({ workSectionId: null, standardEquipment: true, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.importWorksectionModal.title")} icon="download" />
            <Modal.Body padding={24} adjustHeight={328}>
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16 }}>
                    <Select label={i18next.t("page.private.settings.modals.importWorksectionModal.tradeChooseLabel")} adjustWidth value={this.state.workSectionId} onChange={workSectionId => this.setState({ workSectionId })} items={this.state.items.map(item => ({
                        key: item.id,
                        icon: item.icon,
                        title: item.stlb_id ? `${item.stlb_id} / ${item.name}` : item.name
                    }))} />
                    <Checkbox value={this.state.standardEquipment} onChange={standardEquipment => this.setState({ standardEquipment })} label={i18next.t("page.private.settings.modals.importWorksectionModal.standardBuildingEquipment")} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.workSectionId} title={i18next.t("page.private.settings.modals.importWorksectionModal.exitButton")} icon="download" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $standardEquipment: Boolean!) {
                            createWorkSectionFromCFDS(id: $id, standardEquipment: $standardEquipment)
                        }
                    `,
                    variables: {
                        id: this.state.workSectionId,
                        standardEquipment: this.state.standardEquipment
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createWorkSectionFromCFDS) {
                        this.props.refresh()
                        this.fetch()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
