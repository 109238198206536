import React, { Component } from 'react'
import { Button, Modal, GridElement, Input, Select } from 'scanmetrix-components'
import { Document, Page } from 'react-pdf'
import styled from "styled-components"
import { MoonLoader as LoadingAnimation } from "react-spinners"
import i18next from "i18next"
import moment from "moment"
import accounting from 'accounting'
import { ProvidedRequiredArgumentsRule } from 'graphql'
import { parse } from 'handlebars'


const pageScale = 2.5
const pageWidth = 210 * pageScale
const pageHeight = 297 * pageScale

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

const StyledPage = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 16px;
  
    .controls {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      
        .page {
            user-select: none;
            flex-shrink: 0;
            width: ${pageWidth / 3.5 + "px"};
            text-align: center;
        }
    }
  
    .pageDocument {
        width: ${pageWidth + "px"};
        height: ${pageHeight + "px"};
        background: white;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        user-select: none;
        transition: opacity 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 5px;
      
        &:hover {
            opacity: 0.75;
        }
    }
`

const StyledModal = styled.div`
    display: grid;
    grid-template-columns: ${pageWidth + "px"} 1fr;
    grid-gap: 32px;

    .right {
        .grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 32px;
        }
    }
`

const LoadingContainer = styled.div`
    padding: 64px 0;
`

class Loading extends Component {
    render() {
        return <LoadingContainer>
            <LoadingAnimation sizeUnit="px" size={64} color="#3b97d3" />
        </LoadingContainer>
    }
}

export default class extends Component {
    state = {
        accountingInvoiceDocument: null,
        numPages: null,
        loading: false,
        page: 1,
        date: null,
        number: null,
        net: null,
        gross: null,
        serviceProviderId: null,
        subsidiaryId: null,
        purchaseOrderId: null,
        contractId: null,
        subsidiaries: [],
        serviceProviders: [],
        contracts: [],
        purchaseOrders: []
    }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    ServiceProviders {
                        nodes {
                            id
                            name
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({
                serviceProviders: data.data.ServiceProviders.nodes,
                subsidiaries: data.data.Subsidiaries.nodes,
            })
        })
    }


    render() {
        console.log(this.state.date)
        const accountingInvoiceDocument = this.state.accountingInvoiceDocument

        return <Modal instance={this.props.instance} initialize={ async accountingInvoiceDocument => {
            this.setState({
                accountingInvoiceDocument,
                page: 1,
                numPages: null,
                loading: true,
                date: null,
                number: null,
                net: null,
                gross: null,
                serviceProviderId: null,
                subsidiaryId: null,
                purchaseOrderId: null,
                contractId: null,
            })
                try {
                    this.setState({
                        loading: true
                    })
                    const result = await scanmetrix.client.mutate({

                        mutation: scanmetrix.gql`
                            mutation($id: ID!) {
                                ocrAccountingInvoiceDocument(id: $id)
                            }
                        `,
                        variables: {
                            id: accountingInvoiceDocument.id,
                        }

                    })

                    if (result) {

                        const newNet = result.data.ocrAccountingInvoiceDocument.entities.find(d => d.type === "net_amount")?.mentionText

                        const newInvoice = result.data.ocrAccountingInvoiceDocument.entities.find(d => d.type === "invoice_id")?.mentionText

                        const newDate = result.data.ocrAccountingInvoiceDocument.entities.find(d => d.type === "invoice_date")?.mentionText

                        const newGross = result.data.ocrAccountingInvoiceDocument.entities.find(d => d.type === "total_amount")?.mentionText

                        this.setState({
                            net: parseFloat(newNet),
                            gross: parseFloat(newGross),
                            number: newInvoice,
                            date: newDate ? moment(new Date(newDate)).format("DD.MM.YYYY") : null,
                            loading: false
                         })

                    } else {
                        console.error(error);
                    }
                    console.log(result)
                        } catch (error) {
                              console.error(error);
                        } finally {
                              this.setState({ loading: false });
                        }

            }}>
            <Modal.Head title={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.titleForm")} icon="book-arrow-right" />
            {accountingInvoiceDocument && <Modal.Body padding={32}>
                <StyledModal>
                    <StyledPage>
                        <div className="pageDocument" onClick={() => window.open(scanmetrix.backendURL + "/accounting-invoice-document/" + accountingInvoiceDocument.id, "_blank")}>
                            <Document loading={<Loading />} options={{ withCredentials: true }} file={scanmetrix.backendURL + "/accounting-invoice-document/" + accountingInvoiceDocument.id} onLoadSuccess={({ numPages }) => {
                                this.setState({ numPages })
                            }}>
                                <Page pageNumber={this.state.page} width={pageWidth} height={pageHeight} />
                            </Document>
                        </div>
                        <div className="controls">
                            <Button secondary thick icon="arrow-left-to-line" noIconMargin disabled={this.state.page === 1 || this.state.numPages === null} onClick={() => this.setState({ page: 1 })} />
                            <Button secondary thick icon="arrow-left" title={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.back")} disabled={this.state.page === 1 || this.state.numPages === null} onClick={() => this.setState({ page: this.state.page - 1 })} />
                            {this.state.numPages !== null && <div className="page">{i18next.t("page.private.accounting.unbooked.bookInvoiceModal.page")} {this.state.page} von {this.state.numPages}</div>}
                            {this.state.numPages === null && <div className="page">{i18next.t("page.private.accounting.unbooked.bookInvoiceModal.uploadPDFdata")}</div>}
                            <Button secondary thick icon="arrow-right" title={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.further")} disabled={this.state.page === this.state.numPages || this.state.numPages === null} onClick={() => this.setState({ page: this.state.page + 1 })} />
                            <Button secondary thick icon="arrow-right-to-line" noIconMargin disabled={this.state.page === this.state.numPages || this.state.numPages === null} onClick={() => this.setState({ page: this.state.numPages })} />
                        </div>
                    </StyledPage>
                    {scanmetrix.checkPermission("AccountingInvoice") >= 2 && <div className="right">
                        <div className="grid">
                            <GridElement columnStart={1} columnEnd={2}>
                                <Input readOnly={this.state.loading} label={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.billDate")} icon="calendar-day" date required adjustWidth value={this.state.date} onChange={date => {
                                    console.log(date)
                                    this.setState({ date })}} />
                            </GridElement>
                            <GridElement columnStart={2} columnEnd={3}>
                                <Input readOnly={this.state.loading} label={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.billNumber")} icon="file-invoice" required adjustWidth value={this.state.number} onChange={number => this.setState({ number })} />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={2}>
                                <Input readOnly={this.state.loading} label={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.netto")} icon="piggy-bank" format={val => val !== null ? formatter.format(val) : val} float required adjustWidth value={this.state.net} onChange={net => this.setState({ net })} />
                            </GridElement>
                            <GridElement columnStart={2} columnEnd={3}>
                                <Input readOnly={this.state.loading} label={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.brutto")} icon="piggy-bank" format={val => val !== null ? formatter.format(val) : val} float required adjustWidth value={this.state.gross} onChange={gross => this.setState({ gross })} />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={3}>
                                <Select readOnly={this.state.loading} label={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.serviceProvider")} adjustWidth required icon="user-tie" items={this.state.serviceProviders.map(serviceProvider => ({
                                    key: serviceProvider.id,
                                    title: (serviceProvider.address.postalCode && serviceProvider.address.city) ? `${serviceProvider.name} / ${serviceProvider.address.postalCode} ${serviceProvider.address.city}` : serviceProvider.name
                                }))} value={this.state.serviceProviderId} onChange={serviceProviderId => {
                                    if(this.state.contractId) {
                                        const contract = this.state.contracts.find(c => c.id === this.state.contractId)

                                        if(!contract) return this.setState({ serviceProviderId })
                                        if(contract.serviceProvider && contract.serviceProvider.id !== serviceProviderId) return this.setState({ serviceProviderId, contractId: null })

                                        this.setState({ serviceProviderId })
                                    } else {
                                        this.setState({ serviceProviderId })
                                    }

                                    if(this.state.purchaseOrderId) {
                                        const purchaseOrder = this.state.purchaseOrders.find(c => c.id === this.state.purchaseOrderId)

                                        if(!purchaseOrder) return this.setState({ serviceProviderId })
                                        if(purchaseOrder.serviceProvider.id !== serviceProviderId) return this.setState({ serviceProviderId, purchaseOrderId: null })

                                        this.setState({ serviceProviderId })
                                    } else {
                                        this.setState({ serviceProviderId })
                                    }
                                }} />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={3}>
                                <Modal.Divider title={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.optionalInformation")} />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={3}>
                                <Select readOnly={this.state.loading} label={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.branch")} adjustWidth icon="map-marker" items={this.state.subsidiaries.map(subsidiary => ({
                                    key: subsidiary.id,
                                    title: subsidiary.name + (subsidiary.label ? (" (" + subsidiary.label + ")") : "") + " / " + subsidiary.address.postalCode + " " + subsidiary.address.city
                                }))} value={this.state.subsidiaryId} onChange={subsidiaryId => {
                                    this.setState({ subsidiaryId, purchaseOrders: [], contracts: [], purchaseOrderId: null, contractId: null })

                                    scanmetrix.client.query({
                                        query: scanmetrix.gql`
                                            query($subsidiaryId: ID!) {
                                                PurchaseOrders(filter: { subsidiaryId_eq: $subsidiaryId }) {
                                                    nodes {
                                                        id
                                                        number
                                                        date
                                                        title
                                                        serviceProvider {
                                                            id
                                                            name
                                                        }
                                                    }
                                                }
                                                SubsidiaryContracts(filter: { subsidiaryId_eq: $subsidiaryId }) {
                                                    nodes {
                                                        id
                                                        contract {
                                                            id
                                                            number
                                                            title
                                                            partnerName
                                                            serviceProvider {
                                                                id
                                                                name
                                                            }
                                                        }
                                                    }
                                                }
                                            }   
                                        `,
                                        variables: {
                                            subsidiaryId
                                        }
                                    }).then(data => {
                                        this.setState({
                                            purchaseOrders: data.data.PurchaseOrders.nodes,
                                            contracts: data.data.SubsidiaryContracts.nodes.map(sc => sc.contract)
                                        })
                                    })
                                }} />
                            </GridElement>
                            {this.state.subsidiaryId && <>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Select readOnly={this.state.loading} label="Auftrag (PO)" adjustWidth icon="wrench" items={this.state.purchaseOrders.filter(purchaseOrder => {
                                        if(!this.state.serviceProviderId) return true

                                        return purchaseOrder.serviceProvider.id === this.state.serviceProviderId
                                    }).map(purchaseOrder => ({
                                        key: purchaseOrder.id,
                                        title: `${purchaseOrder.number} / ${moment(purchaseOrder.date).format("DD.MM.YYYY")} / ${purchaseOrder.title} / ${purchaseOrder.serviceProvider.name}`
                                    }))} value={this.state.purchaseOrderId} onChange={purchaseOrderId => this.setState({ purchaseOrderId })} />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Select readOnly={this.state.loading} label="Vertrag" adjustWidth icon="file-contract" items={this.state.contracts.filter(contract => {
                                        if(!this.state.serviceProviderId) return false
                                        if(!contract.serviceProvider) return false

                                        return contract.serviceProvider.id === this.state.serviceProviderId
                                    }).map(contract => ({
                                        key: contract.id,
                                        title: `${contract.title} ${contract.number ? (" (" + contract.number + ") ") : ""}/ ${contract.serviceProvider ? contract.serviceProvider.name : contract.partnerName}`,
                                        serviceProviderId: contract.serviceProvider?.id
                                    }))} value={this.state.contractId} onChange={(contractId, data) => {
                                        if(this.state.serviceProviderId || !data || !data.serviceProviderId) {
                                            this.setState({ contractId })
                                        } else {
                                            this.setState({ contractId, serviceProviderId: data.serviceProviderId })
                                        }
                                    }} />
                                </GridElement>
                            </>}
                        </div>
                    </div>}
                </StyledModal>
            </Modal.Body>}
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={scanmetrix.checkPermission("AccountingInvoice") < 2 || !this.state.date || !this.state.number || !this.state.gross || !this.state.net || !this.state.serviceProviderId} thick title={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.exitButton")} icon="book-arrow-right" onClick={() => {
                this.setState({ loading: true })

                let variables = {
                    serviceProviderId: this.state.serviceProviderId,
                    number: this.state.number,
                    net: this.state.net,
                    gross: this.state.gross,
                    date: moment(this.state.date, "DD.MM.YYYY").toDate(),
                    accountingInvoiceDocumentId: this.state.accountingInvoiceDocument.id,
                    subsidiaryId: this.state.subsidiaryId,
                    purchaseOrderId: this.state.subsidiaryId ? this.state.purchaseOrderId : null,
                    contractId: this.state.subsidiaryId ? this.state.contractId : null
                }

                return scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($accountingInvoiceDocumentId: ID!, $serviceProviderId: ID!, $number: String!, $net: Float!, $gross: Float!, $date: DateTime!, $subsidiaryId: ID, $purchaseOrderId: ID, $contractId: ID) {
                            createAccountingInvoice(accountingInvoiceDocumentId: $accountingInvoiceDocumentId, serviceProviderId: $serviceProviderId, number: $number, net: $net, gross: $gross, date: $date, subsidiaryId: $subsidiaryId, purchaseOrderId: $purchaseOrderId, contractId: $contractId)
                        }   
                    `,
                    variables
                }).then(data => {
                    if(data.data.createAccountingInvoice) {
                        return this.props.fetch().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                })
            }} />} />
        </Modal>
    }
}
