import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = {
        approvedBy: null,
        approvedAt: moment().format("DD.MM.YYYY"),
        notes: null,
        loading: false
    }

    render() {
        const purchaseOrderId = this.props.purchaseOrderId

        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({
            approvedBy: null,
            approvedAt: moment().format("DD.MM.YYYY"),
            notes: null,
            loading: false
        })}>
            <Modal.Head title={i18next.t("page.private.purchaseOrder.approvePurchaseOrderModal.title")} icon="check" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Input required label={i18next.t("page.private.purchaseOrder.approvePurchaseOrderModal.releasedBy")} icon="user" value={this.state.approvedBy} onChange={approvedBy => this.setState({ approvedBy })} />
                    <Input required date label={i18next.t("page.private.purchaseOrder.approvePurchaseOrderModal.releaseDate")} icon="calendar-day" value={this.state.approvedAt} onChange={approvedAt => this.setState({ approvedAt })} />
                    <Input label={i18next.t("page.private.purchaseOrder.approvePurchaseOrderModal.notes")} icon="font" value={this.state.notes} onChange={notes => this.setState({ notes })} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.approvedBy || !this.state.approvedAt} title={i18next.t("page.private.purchaseOrder.approvePurchaseOrderModal.button")} icon="check" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $status: String!, $data: JSON!) {
                            updatePurchaseOrderStatus(id: $id, status: $status, data: $data)
                        }
                    `,
                    variables: {
                        id: purchaseOrderId,
                        status: "commission",
                        data: {
                            approvedBy: this.state.approvedBy,
                            approvedAt: this.state.approvedAt,
                            approvalNotes: this.state.notes
                        }
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.updatePurchaseOrderStatus) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
