import React, {Component, createRef} from "react"
import { Page, Breadcrumbs, GridElement, Button, Modal, Input, Section } from "scanmetrix-components"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import i18next from "i18next"

import Avatar from "react-avatar"
import Dropzone from "react-dropzone"
import filesize from "filesize"
import { Menu, Item } from 'react-contexify'
import { toast } from "react-toastify"
import { contextMenu } from 'react-contexify'
import fileDialog from "file-dialog"

const dropzoneRef = createRef()

const FileTable = styled.div`
  width: 100%;
  
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline;
    padding-right: 16px;
  }
  
  p:nth-child(1) {
    width: 32.5%;
  }
  
  p:nth-child(2) {
    width: 10%;
  }
  
  p:nth-child(3) {
    width: 15%;
  }
  
  p:nth-child(4) {
    width: 12.5%;
  }
  
  p:nth-child(5) {
    width: 12.5%;
  }
  
  p:nth-child(6) {
    width: 7.5%;
  }
  
  p:nth-child(7) {
    width: 10%;
    text-align: right;
    padding-right: 0;
    justify-content: flex-end;
       
    i {
      margin-right: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
`

const FileHead = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  user-select: none;
  padding: 16px 26px 16px 16px;
`

const FileBody = styled.div`
  box-shadow: ${props => props.isDragActive ? "inset 0 0 0 4px #3b97d3" : "inset 0 0 0 0 #3b97d3"};
  transition: all 0.3s;
  border: none !important;
  outline: 0 !important;
  position: relative;
  min-height: 256px;
  overflow-y: scroll;
  max-height: 960px;
  
  * {
    opacity: ${props => props.isDragActive ? 0.5 : 1};
    filter: ${props => props.isDragActive ? "blur(3px)" : "none"};
    transition: opacity 0.3s, filter 0.3s;
  }
  
  .upload {
    opacity: ${props => props.isDragActive ? 1 : 0};
    visibility: ${props => props.isDragActive ? "visible" : "hidden"};
  }
`

const File = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  transition: all 0.3s;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  
  .fas {
      color: #ccc;
      font-size: 1.2em;
      width: 32px;
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-right: 8px;
  }
`

const Back = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  transition: all 0.3s;
  cursor: pointer;
  user-select: none;
  
  .far {
      color: #ccc;
      font-size: 1.2em;
      width: 32px;
  }
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const BackPlaceholder = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  user-select: none;
  cursor: default;
  
  .far {
      color: #ccc;
      font-size: 1.2em;
      width: 32px;
  }
`

const Folder = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-right: 8px;
  }
  
  .fa-folder, .fa-folders {
      color: #3b97d3;
      font-size: 1.2em;
      width: 32px;
  }
`

const Upload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  filter: none !important;
  background: rgba(255, 255, 255, 0.25);
  z-index: 10;
  user-select: none;
  transition: all 0.3s;
  
  * {
      opacity: 1 !important;
      filter: none !important;
  }
  
  i {
    margin-bottom: 32px;
    font-size: 3.4em;
    color: #3B97D3;
  }
  
  p {
    width: auto !important;
    font-size: 1.4em;
    line-height: 1.8em;
  }
`

const parseFileIcon = type => {
    if(type === "pdf") return "file-pdf"
    if(type === "zip") return "file-archive"
    if(type === "ppt") return "file-powerpoint"
    if(type === "pptx") return "file-powerpoint"
    if(type === "mp3") return "file-audio"
    if(type === "wav") return "file-audio"
    if(type === "ogg") return "file-audio"
    if(type === "docx") return "file-word"
    if(type === "doc") return "file-word"
    if(type === "txt") return "file-word"
    if(type === "rtf") return "file-word"
    if(type === "odt") return "file-word"
    if(type === "xlsx") return "file-excel"
    if(type === "ods") return "file-excel"
    if(type === "xls") return "file-excel"
    if(type === "csv") return "file-excel"
    if(type === "jpg") return "file-image"
    if(type === "jpeg") return "file-image"
    if(type === "png") return "file-image"
    if(type === "bmp") return "file-image"
    if(type === "svg") return "file-image"
    if(type === "mp4") return "file-video"
    if(type === "avi") return "file-video"
    if(type === "wmv") return "file-video"
    if(type === "mov") return "file-video"
    if(type === "exe") return "file-code"
    if(type === "sql") return "file-code"
    if(type === "db") return "file-code"

    return "file"
}

class FileManager extends Component {
    state = { entryPoint: "/", data: {}, uploading: false, subsidiaries: [], serviceProviders: [] }

    constructor(props) {
        super(props)

        if(props.entryPoint) this.state.entryPoint = props.entryPoint

        this.onUpload = this.onUpload.bind(this)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        if(this.props.scope === "google") {
            return fetch(scanmetrix.backendURL + "/integration/google_workspace/files", {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(result => result.json()).then(data => {
                if(data.status === "SUCCESSFUL") this.setState({ data: data.files })
            })
        } else {
            let extraFilters = ""
            let extraVariables = ""
            let extraVariableData = {}

            if(this.props.measureId) {
                extraFilters = extraFilters + ", measureId_eq: $measureId"
                extraVariables = extraVariables + ", $measureId: ID!"
                extraVariableData.measureId = this.props.measureId
            }

            if(this.props.contractProvisionProofId) {
                extraFilters = extraFilters + ", contractProvisionProofId_eq: $contractProvisionProofId"
                extraVariables = extraVariables + ", $contractProvisionProofId: ID!"
                extraVariableData.contractProvisionProofId = this.props.contractProvisionProofId
            }

            if(this.props.subsidiaryId) {
                extraFilters = extraFilters + ", subsidiaryId_eq: $subsidiaryId"
                extraVariables = extraVariables + ", $subsidiaryId: ID!"
                extraVariableData.subsidiaryId = this.props.subsidiaryId
            }

            if(this.props.fileStructureTemplateId) {
                extraFilters = extraFilters + ", fileStructureTemplateId_eq: $fileStructureTemplateId"
                extraVariables = extraVariables + ", $fileStructureTemplateId: ID!"
                extraVariableData.fileStructureTemplateId = this.props.fileStructureTemplateId
            }

            if(this.props.contractId) {
                extraFilters = extraFilters + ", contractId_eq: $contractId"
                extraVariables = extraVariables + ", $contractId: ID!"
                extraVariableData.contractId = this.props.contractId
            }

            if(this.props.purchaseOrderId) {
                extraFilters = extraFilters + ", purchaseOrderId_eq: $purchaseOrderId"
                extraVariables = extraVariables + ", $purchaseOrderId: ID!"
                extraVariableData.purchaseOrderId = this.props.purchaseOrderId
            }

            if(this.props.utilitiesStatementId) {
                extraFilters = extraFilters + ", utilitiesStatementId_eq: $utilitiesStatementId"
                extraVariables = extraVariables + ", $utilitiesStatementId: ID!"
                extraVariableData.utilitiesStatementId = this.props.utilitiesStatementId
            }

            if(this.props.tenantUtilitiesStatementId) {
                extraFilters = extraFilters + ", tenantUtilitiesStatementId_eq: $tenantUtilitiesStatementId"
                extraVariables = extraVariables + ", $tenantUtilitiesStatementId: ID!"
                extraVariableData.tenantUtilitiesStatementId = this.props.tenantUtilitiesStatementId
            }

            return scanmetrix.client.query({
                query: scanmetrix.gql`
                query($scope: String!${extraVariables}) {
                    Files(filter: { scope_eq: $scope${extraFilters} }) {
                        nodes {
                            id
                            scope
                            createdAt
                            updatedAt
                            name
                            path
                            fileFormat
                            size
                            type
                            ${this.props.scope === "serviceProvider" ? `serviceProvider {
                                id
                                name
                            }` : ""}
                            ${this.props.scope === "subsidiary" ? `subsidiary {
                                id
                            }` : ""}
                            ${this.props.scope === "contract" ? `contract {
                                id
                            }` : ""}
                            ${this.props.scope === "purchaseOrder" ? `purchaseOrder {
                                id
                            }` : ""}
                            ${this.props.scope === "utilitiesStatement" ? `utilitiesStatement {
                                id
                            }` : ""}
                            ${this.props.scope === "tenantUtilitiesStatement" ? `tenantUtilitiesStatement {
                                id
                            }` : ""}
                            ${this.props.scope === "facility" ? `serviceProviderFacilityFileContainer {
                                id
                                facilityId
                                serviceProvider {
                                    id
                                }
                            }` : ""}
                            ${this.props.scope === "contractProvisionProof" ? `contractProvisionProof {
                                id
                            }` : ""}
                            ${this.props.scope === "measure" ? `measure {
                                id
                            }` : ""}
                            ${this.props.scope === "fileStructureTemplate" ? `fileStructureTemplate {
                                id
                            }` : ""}
                            user {
                                id
                                email
                                firstName
                                lastName
                            }
                        }
                    }
                    ${this.props.scope === "subsidiary" && !this.props.subsidiaryId ? `Subsidiaries {
                        nodes {
                            id
                            name
                            label
                        }
                    }` : ""}
                    ${this.props.scope === "contract" && !this.props.contractId ? `Contracts {
                        nodes {
                            id
                            title
                            partnerName
                            number
                        }
                    }` : ""}
                    ${this.props.scope === "purchaseOrder" && !this.props.purchaseOrderId ? `PurchaseOrders {
                        nodes {
                            id
                            number
                        }
                    }` : ""}
                    ${this.props.scope === "utilitiesStatement" && !this.props.utilitiesStatementId ? `UtilitiesStatements {
                        nodes {
                            id
                        }
                    }` : ""}
                    ${this.props.scope === "tenantUtilitiesStatement" && !this.props.tenantUtilitiesStatementId ? `SubsidiaryTenantUtilitiesStatements {
                        nodes {
                            id
                        }
                    }` : ""}
                    ${this.props.scope === "facility" && !this.props.facilityId ? `ServiceProviderFacilityFileContainers {
                        nodes {
                            id
                            facilityId
                            serviceProvider {
                                id
                            }
                        }
                    }` : ""}
                    ${this.props.scope === "contractProvisionProof" && !this.props.contractProvisionProofId ? `ContractProvisionProofs {
                        nodes {
                            id
                            name
                            date
                        }
                    }` : ""}
                    ${this.props.scope === "measure" && !this.props.measureId ? `Measures {
                        nodes {
                            id
                            number
                            createdAt
                            title
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    postalCode
                                    city
                                }
                            }
                        }
                    }` : ""}
                    ${this.props.scope === "fileStructureTemplate" && !this.props.fileStructureTemplateId ? `FileStructureTemplates {
                        nodes {
                            id
                            name
                        }
                    }` : ""}
                    ${this.props.scope === "serviceProvider" && !this.props.serviceProviderId ? `ServiceProviders {
                        nodes {
                            id
                            name
                            responsibility
                        }
                    }` : ""}
                }
            `,
                variables: {
                    scope: this.props.scope,
                    ...extraVariableData
                }
            }).then(result => {
                const files = result.data.Files.nodes

                console.log(files)

                const getData = files => {
                    let data = {}

                    files.filter(file => file.type === "folder").sort((a, b) => a.path.split("/").length - b.path.split("/").length, 0).forEach(folder => {
                        if(folder.path === "/") {
                            data[folder.name] = {
                                id: folder.id,
                                size: 0,
                                created: moment(folder.createdAt).valueOf(),
                                modified: moment(folder.updatedAt).valueOf(),
                                user: folder.user,
                                serviceProvider: folder.serviceProvider,
                                name: folder.name,
                                type: "folder",
                                path: folder.path,
                                data: {}
                            }
                        } else {
                            let split = folder.path.split("/")
                            split.pop()
                            split.shift()

                            let found = data

                            split.forEach(folderName => {
                                if(found[folderName]) found = found[folderName].data
                            })

                            found[folder.name] = {
                                id: folder.id,
                                size: 0,
                                created: moment(folder.createdAt).valueOf(),
                                modified: moment(folder.updatedAt).valueOf(),
                                user: folder.user,
                                serviceProvider: folder.serviceProvider,
                                type: "folder",
                                name: folder.name,
                                path: folder.path,
                                data: {}
                            }
                        }
                    })

                    files.filter(file => file.type === "file").forEach(file => {
                        if(file.path === "/") {
                            data[file.name] = {
                                id: file.id,
                                size: file.size,
                                created: moment(file.createdAt).valueOf(),
                                modified: moment(file.updatedAt).valueOf(),
                                serviceProvider: file.serviceProvider,
                                user: file.user,
                                format: file.fileFormat,
                                type: "file",
                                name: file.name,
                                path: file.path
                            }
                        } else {
                            let split = file.path.split("/")
                            split.pop()
                            split.shift()

                            let found = data

                            split.forEach(folderName => {
                                if(found[folderName]) found = found[folderName].data
                            })

                            found[file.name] = {
                                id: file.id,
                                size: file.size,
                                format: file.fileFormat,
                                created: moment(file.createdAt).valueOf(),
                                modified: moment(file.updatedAt).valueOf(),
                                user: file.user,
                                serviceProvider: file.serviceProvider,
                                type: "file",
                                name: file.name,
                                path: file.path
                            }
                        }
                    })

                    const recursion = data => {
                        let size = 0

                        Object.keys(data).forEach(name => {
                            if(data[name].type === "folder") {
                                const val = recursion(data[name].data)
                                size += val
                                data[name].size += val
                            } else {
                                size += data[name].size
                            }
                        })

                        return size
                    }

                    recursion(data)

                    return data
                }

                let data = {}

                if(this.props.scope === "subsidiary") {
                    if(this.props.subsidiaryId) {
                        data = getData(files.filter(file => file.subsidiary && file.subsidiary.id === this.props.subsidiaryId))
                    } else {
                        result.data.Subsidiaries.nodes.forEach(subsidiary => {
                            const subData = getData(files.filter(file => file.subsidiary && file.subsidiary.id === subsidiary.id))

                            const name = `${subsidiary.id}: ${subsidiary.name}${subsidiary.label ? ("(" + subsidiary.label + ")") : ""}`.replace(/\//g, " ")

                            data[name] = {
                                id: subsidiary.id,
                                name,
                                size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                                created: null,
                                modified: null,
                                user: null,
                                serviceProvider: null,
                                type: "folder",
                                path: "/",
                                subsidiaryId: subsidiary.id,
                                contractId: null,
                                purchaseOrderId: null,
                                utilitiesStatementId: null,
                                tenantUtilitiesStatementId: null,
                                contractProvisionProofId: null,
                                measureId: null,
                                serviceProviderFacilityFileContainerId: null,
                                fileStructureTemplateId: null,
                                data: subData
                            }
                        })
                    }
                } else if(this.props.scope === "fileStructureTemplate") {
                    if(this.props.fileStructureTemplateId) {
                        data = getData(files.filter(file => file.fileStructureTemplate && file.fileStructureTemplate.id === this.props.fileStructureTemplateId))
                    } else {
                        result.data.FileStructureTemplates.nodes.forEach(fileStructureTemplate => {
                            const subData = getData(files.filter(file => file.fileStructureTemplate && file.fileStructureTemplate.id === fileStructureTemplate.id))

                            const name = `${fileStructureTemplate.id}: ${fileStructureTemplate.name}`.replace(/\//g, " ")

                            data[name] = {
                                id: fileStructureTemplate.id,
                                name,
                                size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                                created: null,
                                modified: null,
                                user: null,
                                serviceProvider: null,
                                type: "folder",
                                path: "/",
                                contractId: null,
                                purchaseOrderId: null,
                                utilitiesStatementId: null,
                                tenantUtilitiesStatementId: null,
                                contractProvisionProofId: null,
                                measureId: null,
                                serviceProviderFacilityFileContainerId: null,
                                fileStructureTemplateId: fileStructureTemplate.id,
                                subsidiaryId: null,
                                data: subData
                            }
                        })
                    }
                } else if(this.props.scope === "contract") {
                    if(this.props.contractId) {
                        data = getData(files.filter(file => file.contract && file.contract.id === this.props.contractId))
                    } else {
                        result.data.Contracts.nodes.forEach(contract => {
                            const subData = getData(files.filter(file => file.contract && file.contract.id === contract.id))

                            const name = `${contract.id}: ${contract.partnerName}, ${contract.title}${contract.number ? (" (" + contract.number + ")") : ""}`.replace(/\//g, " ")

                            data[name] = {
                                id: contract.id,
                                name,
                                size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                                created: null,
                                modified: null,
                                user: null,
                                serviceProvider: null,
                                type: "folder",
                                path: "/",
                                contractId: contract.id,
                                fileStructureTemplateId: null,
                                contractProvisionProofId: null,
                                measureId: null,
                                serviceProviderFacilityFileContainerId: null,
                                subsidiaryId: null,
                                data: subData
                            }
                        })
                    }
                } else if(this.props.scope === "utilitiesStatement") {
                    if(this.props.utilitiesStatementId) {
                        data = getData(files.filter(file => file.utilitiesStatement && file.utilitiesStatement.id === this.props.utilitiesStatementId))
                    } else {
                        result.data.UtilitiesStatements.nodes.forEach(utilitiesStatement => {
                            const subData = getData(files.filter(file => file.utilitiesStatement && file.utilitiesStatement.id === utilitiesStatement.id))

                            const name = utilitiesStatement.number.replace(/\//g, " ")

                            data[name] = {
                                id: utilitiesStatement.id,
                                name,
                                size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                                created: null,
                                modified: null,
                                user: null,
                                serviceProvider: null,
                                type: "folder",
                                path: "/",
                                purchaseOrderId: null,
                                tenantUtilitiesStatementId: null,
                                utilitiesStatementId: utilitiesStatement.id,
                                contractId: null,
                                fileStructureTemplateId: null,
                                contractProvisionProofId: null,
                                measureId: null,
                                serviceProviderFacilityFileContainerId: null,
                                subsidiaryId: null,
                                data: subData
                            }
                        })
                    }
                } else if(this.props.scope === "tenantUtilitiesStatement") {
                    if(this.props.tenantUtilitiesStatementId) {
                        data = getData(files.filter(file => file.tenantUtilitiesStatement && file.tenantUtilitiesStatement.id === this.props.tenantUtilitiesStatementId))
                    } else {
                        result.data.SubsidiaryTenantUtilitiesStatements.nodes.forEach(tenantUtilitiesStatement => {
                            const subData = getData(files.filter(file => file.tenantUtilitiesStatement && file.tenantUtilitiesStatement.id === tenantUtilitiesStatement.id))

                            const name = tenantUtilitiesStatement.number.replace(/\//g, " ")

                            data[name] = {
                                id: tenantUtilitiesStatement.id,
                                name,
                                size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                                created: null,
                                modified: null,
                                user: null,
                                serviceProvider: null,
                                type: "folder",
                                path: "/",
                                purchaseOrderId: null,
                                tenantUtilitiesStatementId: tenantUtilitiesStatement.id,
                                utilitiesStatementId: null,
                                contractId: null,
                                fileStructureTemplateId: null,
                                contractProvisionProofId: null,
                                measureId: null,
                                serviceProviderFacilityFileContainerId: null,
                                subsidiaryId: null,
                                data: subData
                            }
                        })
                    }
                } else if(this.props.scope === "purchaseOrder") {
                    if(this.props.purchaseOrderId) {
                        data = getData(files.filter(file => file.purchaseOrder && file.purchaseOrder.id === this.props.purchaseOrderId))
                    } else {
                        result.data.PurchaseOrders.nodes.forEach(purchaseOrder => {
                            const subData = getData(files.filter(file => file.purchaseOrder && file.purchaseOrder.id === purchaseOrder.id))

                            const name = purchaseOrder.number.replace(/\//g, " ")

                            data[name] = {
                                id: purchaseOrder.id,
                                name,
                                size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                                created: null,
                                modified: null,
                                user: null,
                                serviceProvider: null,
                                type: "folder",
                                path: "/",
                                purchaseOrderId: purchaseOrder.id,
                                utilitiesStatementId: null,
                                tenantUtilitiesStatementId: null,
                                contractId: null,
                                fileStructureTemplateId: null,
                                contractProvisionProofId: null,
                                measureId: null,
                                serviceProviderFacilityFileContainerId: null,
                                subsidiaryId: null,
                                data: subData
                            }
                        })
                    }
                } else if(this.props.scope === "facility") {
                    if(this.props.facilityId) {
                        data = getData(files.filter(file => file.serviceProviderFacilityFileContainer && file.serviceProviderFacilityFileContainer.serviceProvider.id === this.props.facilityId.split(":")[0] && file.serviceProviderFacilityFileContainer.facilityId === this.props.facilityId.split(":")[1]))
                    } else {
                        result.data.ServiceProviderFacilityFileContainers.nodes.forEach(fileContainer => {
                            const subData = getData(files.filter(file => file.serviceProviderFacilityFileContainer && file.serviceProviderFacilityFileContainer.id === fileContainer.id))

                            const name = `${fileContainer.id}`.replace(/\//g, " ")

                            data[name] = {
                                id: fileContainer.id,
                                name,
                                size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                                created: null,
                                modified: null,
                                user: null,
                                serviceProvider: null,
                                type: "folder",
                                path: "/",
                                contractId: null,
                                purchaseOrderId: null,
                                utilitiesStatementId: null,
                                tenantUtilitiesStatementId: null,
                                fileStructureTemplateId: null,
                                contractProvisionProofId: null,
                                measureId: null,
                                serviceProviderFacilityFileContainerId: fileContainer.id,
                                subsidiaryId: null,
                                data: subData
                            }
                        })
                    }
                } else if(this.props.scope === "contractProvisionProof") {
                    if(this.props.contractProvisionProofId) {
                        data = getData(files.filter(file => file.contractProvisionProof && String(file.contractProvisionProof.id) === String(this.props.contractProvisionProofId)))
                    } else {
                        result.data.ContractProvisionProofs.nodes.forEach(contractProvisionProof => {
                            const subData = getData(files.filter(file => file.contractProvisionProof && file.contractProvisionProof.id === contractProvisionProof.id))

                            const name = `${contractProvisionProof.id}: ${contractProvisionProof.name}, ${moment(contractProvisionProof.date).format("DD.MM.YYYY")}`.replace(/\//g, " ")

                            data[name] = {
                                id: contractProvisionProof.id,
                                name,
                                size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                                created: null,
                                modified: null,
                                user: null,
                                serviceProvider: null,
                                type: "folder",
                                path: "/",
                                purchaseOrderId: null,
                                utilitiesStatementId: null,
                                tenantUtilitiesStatementId: null,
                                contractId: null,
                                contractProvisionProofId: contractProvisionProof.id,
                                serviceProviderFacilityFileContainerId: null,
                                fileStructureTemplateId: null,
                                subsidiaryId: null,
                                data: subData
                            }
                        })
                    }
                } else if(this.props.scope === "measure") {
                    if(this.props.measureId) {
                        data = getData(files.filter(file => file.measure && String(file.measure.id) === String(this.props.measureId)))
                    } else {
                        result.data.Measures.nodes.forEach(measure => {
                            const subData = getData(files.filter(file => file.measure && file.measure.id === measure.id))

                            const name = `${measure.id}: ${measure.number}, ${moment(measure.createdAt).format("DD.MM.YYYY")} - ${measure.subsidiary.name}${measure.subsidiary.label ? (" (" + measure.subsidiary.label + ")") : ""} / ${measure.title}`.replace(/\//g, " ")

                            data[name] = {
                                id: measure.id,
                                name,
                                size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                                created: null,
                                modified: null,
                                user: null,
                                serviceProvider: null,
                                type: "folder",
                                path: "/",
                                purchaseOrderId: null,
                                utilitiesStatementId: null,
                                tenantUtilitiesStatementId: null,
                                contractId: null,
                                measureId: measure.id,
                                contractProvisionProofId: null,
                                serviceProviderFacilityFileContainerId: null,
                                fileStructureTemplateId: null,
                                subsidiaryId: null,
                                data: subData
                            }
                        })
                    }
                } else if(this.props.scope === "serviceProvider") {
                    result.data.ServiceProviders.nodes.forEach(serviceProvider => {
                        const subData = getData(files.filter(file => file.serviceProvider && file.serviceProvider.id === serviceProvider.id))

                        const name = `${serviceProvider.id}: ${serviceProvider.name} (${serviceProvider.responsibility})`.replace(/\//g, " ")

                        data[name] = {
                            id: serviceProvider.id,
                            size: Object.keys(subData).map(key => subData[key].size || 0).reduce((a, b) => a + b, 0),
                            name,
                            created: null,
                            modified: null,
                            user: null,
                            serviceProvider: serviceProvider.id,
                            type: "folder",
                            path: "/",
                            subsidiaryId: null,
                            contractId: null,
                            purchaseOrderId: null,
                            utilitiesStatementId: null,
                            tenantUtilitiesStatementId: null,
                            measureId: null,
                            contractProvisionProofId: null,
                            serviceProviderFacilityFileContainerId: null,
                            fileStructureTemplateId: null,
                            data: subData
                        }
                    })
                } else if(this.props.scope === "user") {
                    data = getData(files)
                } else if(this.props.scope === "global") {
                    data = getData(files)
                }

                this.setState({ data, subsidiaries: result.data.Subsidiaries ? result.data.Subsidiaries.nodes : [], serviceProviders: result.data.ServiceProviders ? result.data.ServiceProviders.nodes : [] })
            })
        }
    }

    onUpload(acceptedFiles) {
        if(acceptedFiles.length && !this.state.uploading) {
            this.setState({ uploading: true })

            return Promise.all(acceptedFiles.map((file, index) => {
                const uploadToastId = toast.info(<><i className="far fa-upload" /> {i18next.t("page.private.documents.topData")} {index + 1} / {acceptedFiles.length} {i18next.t("page.private.documents.transmitted")}</>, {
                    progress: 0,
                    autoClose: false,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    closeOnClick: false,
                    newestOnTop: true,
                    draggable: false,
                    closeButton: false
                })

                let variables = {
                    file,
                    path: this.state.entryPoint,
                    scope: this.props.scope
                }

                if(this.props.scope === "subsidiary" && !this.props.subsidiaryId) {
                    variables.subsidiaryId = this.state.data[this.state.entryPoint.split("/")[1]].subsidiaryId
                    variables.path = `/${this.state.entryPoint.split("/").slice(2).join("/")}`
                } else if(this.props.subsidiaryId) {
                    variables.subsidiaryId = this.props.subsidiaryId
                }

                if(this.props.scope === "contract" && !this.props.contractId) {
                    variables.contractId = this.state.data[this.state.entryPoint.split("/")[1]].contractId
                    variables.path = `/${this.state.entryPoint.split("/").slice(2).join("/")}`
                } else if(this.props.contractId) {
                    variables.contractId = this.props.contractId
                }

                if(this.props.scope === "tenantUtilitiesStatement" && !this.props.tenantUtilitiesStatementId) {
                    variables.tenantUtilitiesStatementId = this.state.data[this.state.entryPoint.split("/")[1]].tenantUtilitiesStatementId
                    variables.path = `/${this.state.entryPoint.split("/").slice(2).join("/")}`
                } else if(this.props.tenantUtilitiesStatementId) {
                    variables.tenantUtilitiesStatementId = this.props.tenantUtilitiesStatementId
                }

                if(this.props.scope === "utilitiesStatement" && !this.props.utilitiesStatementId) {
                    variables.utilitiesStatementId = this.state.data[this.state.entryPoint.split("/")[1]].utilitiesStatementId
                    variables.path = `/${this.state.entryPoint.split("/").slice(2).join("/")}`
                } else if(this.props.utilitiesStatementId) {
                    variables.utilitiesStatementId = this.props.utilitiesStatementId
                }

                if(this.props.scope === "purchaseOrder" && !this.props.purchaseOrderId) {
                    variables.purchaseOrderId = this.state.data[this.state.entryPoint.split("/")[1]].purchaseOrderId
                    variables.path = `/${this.state.entryPoint.split("/").slice(2).join("/")}`
                } else if(this.props.purchaseOrderId) {
                    variables.purchaseOrderId = this.props.purchaseOrderId
                }

                if(this.props.scope === "facility" && !this.props.facilityId) {
                    variables.facilityId = this.state.data[this.state.entryPoint.split("/")[1]].facilityId
                    variables.path = `/${this.state.entryPoint.split("/").slice(2).join("/")}`
                } else if(this.props.facilityId) {
                    variables.facilityId = this.props.facilityId
                }

                if(this.props.scope === "contractProvisionProof" && !this.props.contractProvisionProofId) {
                    variables.contractProvisionProofId = this.state.data[this.state.entryPoint.split("/")[1]].contractProvisionProofId
                    variables.path = `/${this.state.entryPoint.split("/").slice(2).join("/")}`
                } else if(this.props.contractProvisionProofId) {
                    variables.contractProvisionProofId = this.props.contractProvisionProofId
                }

                if(this.props.scope === "measure" && !this.props.measureId) {
                    variables.measureId = this.state.data[this.state.entryPoint.split("/")[1]].measureId
                    variables.path = `/${this.state.entryPoint.split("/").slice(2).join("/")}`
                } else if(this.props.measureId) {
                    variables.measureId = this.props.measureId
                }

                if(this.props.scope === "fileStructureTemplate" && !this.props.fileStructureTemplateId) {
                    variables.fileStructureTemplateId = this.state.data[this.state.entryPoint.split("/")[1]].fileStructureTemplateId
                    variables.path = `/${this.state.entryPoint.split("/").slice(2).join("/")}`
                } else if(this.props.fileStructureTemplateId) {
                    variables.fileStructureTemplateId = this.props.fileStructureTemplateId
                }

                return scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($file: Upload!, $path: String!, $scope: String!, $subsidiaryId: ID, $measureId: ID, $contractProvisionProofId: ID, $contractId: ID, $fileStructureTemplateId: ID, $facilityId: ID, $purchaseOrderId: ID, $utilitiesStatementId: ID, $tenantUtilitiesStatementId: ID) {
                            uploadFile(file: $file, path: $path, scope: $scope, subsidiaryId: $subsidiaryId, measureId: $measureId, contractProvisionProofId: $contractProvisionProofId, facilityId: $facilityId, contractId: $contractId, purchaseOrderId: $purchaseOrderId, utilitiesStatementId: $utilitiesStatementId, tenantUtilitiesStatementId: $tenantUtilitiesStatementId, fileStructureTemplateId: $fileStructureTemplateId)
                        }
                    `,
                    context: {
                        hasUpload: true,
                        fetchOptions: {
                            onUploadProgress: ev => {
                                const progress = ev.loaded / ev.total

                                toast.update(uploadToastId, {
                                    progress
                                })

                                //this.setState({ uploadProgress: progress })
                            }
                        }
                    },
                    variables
                }).then(result => {
                    if(result.data.uploadFile) {
                        toast.update(uploadToastId, {
                            render: <><i className="far fa-check" /> {i18next.t("page.private.documents.bottomData")} {index + 1} / {acceptedFiles.length} {i18next.t("page.private.documents.successfullyTransmitted")}</>,
                            progress: 1
                        })

                        setTimeout(() => toast.dismiss(uploadToastId), 2500)

                        //this.setState({ uploading: false, uploadProgress: 0}, this.fetch)
                    }
                })
            })).then(() => {
                this.setState({ uploading: false }, this.fetch)
            })
        }
    }

    render() {
        const entryPoint = this.state.entryPoint
        let data = this.state.data

        let backDisplay = "/"

        if(entryPoint !== "/") {
            const split = entryPoint.split("/")

            split.forEach((folder, index) => {
                if(data[folder] && index < split.length - 2) backDisplay = backDisplay + data[folder].name + "/"
                if(data[folder]) data = data[folder].data
            })
        }

        const back = this.state.entryPoint.split("/").filter((_, index) => index !== this.state.entryPoint.split("/").length - 2).join("/")
        const current = this.state.data[this.state.entryPoint.split("/")[1]]

        return <>
            <Menu id='file_menu'>
                <Item onClick={({ props }) => window.open(scanmetrix.backendURL + (this.props.scope === "google" ? "/integration/google_workspace/file/" : "/file/") + props.id, "_blank")}><i style={{ width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }} className="fa fa-download"/>{i18next.t("page.private.documents.generalDocuments.rightClickDataMenu.dataDownload")}</Item>
                <Item disabled={this.props.scope === "serviceProvider" || this.props.scope === "google" || scanmetrix.checkPermission("File") < 4} onClick={({ props }) => this.deleteItemModal.open(props)}><i style={{ width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }} className="fa fa-trash"/>{i18next.t("page.private.documents.generalDocuments.rightClickDataMenu.dataDelete")}</Item>
                <Item disabled={this.props.scope === "serviceProvider" || this.props.scope === "google" || scanmetrix.checkPermission("File") < 3} onClick={({ props }) => this.renameItemModal.open(props)}><i style={{ width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }} className="fa fa-pencil"/>{i18next.t("page.private.documents.generalDocuments.rightClickDataMenu.dataRename")}</Item>
                {/*<Separator />
                <Item disabled><IconFont className="fa fa-copy"/>Duplizieren</Item>
                <Item disabled><IconFont className="fa fa-cut"/>Ausschneiden</Item>
                <Separator />
                <Submenu disabled label="Berechtigungen">
                    <Item><IconFont className="fa fa-lock-open"/>Alle</Item>
                    <Separator />
                    <Item><IconFont className="fa fa-lock"/>Nur ich</Item>
                    <Item><IconFont className="fa fa-users"/>Nur ich und bestimmte Personen</Item>
                    <Item><IconFont className="fa fa-user-tie"/>Nur ich und bestimmte Dienstleister</Item>
                    <Item><IconFont className="fa fa-map-marker"/>Nur ich und Niederlassung</Item>
                    <Separator />
                    <Item><IconFont className="fa fa-cog"/>Benutzerdefiniert</Item>
                </Submenu>*/}
            </Menu>
            <Menu id='folder_menu'>
                <Item disabled={(((this.props.scope === "subsidiary" && !this.props.subsidiaryId) || (this.props.scope === "facility" && !this.props.facilityId) || (this.props.scope === "utilitiesStatement" && !this.props.utilitiesStatementId)  || (this.props.scope === "tenantUtilitiesStatement" && !this.props.tenantUtilitiesStatementId) || (this.props.scope === "purchaseOrder" && !this.props.purchaseOrderId) || (this.props.scope === "contract" && !this.props.contractId) || (this.props.scope === "measure" && !this.props.measureId) || (this.props.scope === "contractProvisionProof" && !this.props.contractProvisionProofId) || (this.props.scope === "fileStructureTemplate" && !this.props.fileStructureTemplateId)) && entryPoint === "/") || this.props.scope === "serviceProvider" || this.props.scope === "google"} onClick={({ props }) => window.open(scanmetrix.backendURL + "/folder/zip/" + props.id, "_blank")}><i style={{ width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }} className="fa fa-file-archive"/>{i18next.t("page.private.documents.personalDocuments.rightClickfileMenu.fileDownload")}</Item>
                <Item disabled={(((this.props.scope === "subsidiary" && !this.props.subsidiaryId) || (this.props.scope === "facility" && !this.props.facilityId) || (this.props.scope === "utilitiesStatement" && !this.props.utilitiesStatementId)  || (this.props.scope === "tenantUtilitiesStatement" && !this.props.tenantUtilitiesStatementId) || (this.props.scope === "purchaseOrder" && !this.props.purchaseOrderId) || (this.props.scope === "contract" && !this.props.contractId) || (this.props.scope === "measure" && !this.props.measureId) || (this.props.scope === "contractProvisionProof" && !this.props.contractProvisionProofId) || (this.props.scope === "fileStructureTemplate" && !this.props.fileStructureTemplateId)) && entryPoint === "/") || this.props.scope === "serviceProvider" || this.props.scope === "google" || scanmetrix.checkPermission("File") < 4} onClick={({ props }) => this.deleteItemModal.open(props)}><i style={{ width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }} className="fa fa-trash"/>{i18next.t("page.private.documents.personalDocuments.rightClickfileMenu.fileDelete.fileDeleteTitle")}</Item>
                <Item disabled={(((this.props.scope === "subsidiary" && !this.props.subsidiaryId) || (this.props.scope === "facility" && !this.props.facilityId) || (this.props.scope === "utilitiesStatement" && !this.props.utilitiesStatementId)  || (this.props.scope === "tenantUtilitiesStatement" && !this.props.tenantUtilitiesStatementId) || (this.props.scope === "purchaseOrder" && !this.props.purchaseOrderId) || (this.props.scope === "contract" && !this.props.contractId) || (this.props.scope === "measure" && !this.props.measureId) || (this.props.scope === "contractProvisionProof" && !this.props.contractProvisionProofId) || (this.props.scope === "fileStructureTemplate" && !this.props.fileStructureTemplateId)) && entryPoint === "/") || this.props.scope === "serviceProvider" || this.props.scope === "google" || scanmetrix.checkPermission("File") < 3} onClick={({ props }) => this.renameItemModal.open(props)}><i style={{ width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }} className="fa fa-pencil"/>{i18next.t("page.private.documents.personalDocuments.rightClickfileMenu.fileRename.fileRenameTitle")}</Item>
                {/*<Separator />
                <Item disabled><IconFont className="fa fa-copy"/>Duplizieren</Item>
                <Item disabled><IconFont className="fa fa-cut"/>Ausschneiden</Item>
                <Separator />
                <Submenu disabled label="Berechtigungen">
                    <Item><IconFont className="fa fa-lock-open"/>Alle</Item>
                    <Separator />
                    <Item><IconFont className="fa fa-lock"/>Nur ich</Item>
                    <Item><IconFont className="fa fa-users"/>Nur ich und bestimmte Personen</Item>
                    <Item><IconFont className="fa fa-user-tie"/>Nur ich und bestimmte Dienstleister</Item>
                    <Item><IconFont className="fa fa-map-marker"/>Nur ich und Niederlassung</Item>
                    <Separator />
                    <Item><IconFont className="fa fa-cog"/>Benutzerdefiniert</Item>
                </Submenu>*/}
            </Menu>
            <RenameItemModal refresh={() => this.fetch()} instance={ref => this.renameItemModal = ref} />
            <DeleteItemModal refresh={() => this.fetch()} instance={ref => this.deleteItemModal = ref} />
            <CreateFolderModal scope={this.props.scope} refresh={() => this.fetch()} fileStructureTemplateId={(this.props.scope === "fileStructureTemplate" ? (this.props.fileStructureTemplateId || current?.fileStructureTemplateId) : null)} contractProvisionProofId={this.props.scope === "contractProvisionProof" ? (this.props.contractProvisionProofId || current?.contractProvisionProofId) : null} measureId={this.props.scope === "measure" ? (this.props.measureId || current?.measureId) : null} tenantUtilitiesStatementId={this.props.scope === "tenantUtilitiesStatement" ? (this.props.tenantUtilitiesStatementId || current?.tenantUtilitiesStatementId) : null} utilitiesStatementId={this.props.scope === "utilitiesStatement" ? (this.props.utilitiesStatementId || current?.utilitiesStatementId) : null} purchaseOrderId={this.props.scope === "purchaseOrder" ? (this.props.purchaseOrderId || current?.purchaseOrderId) : null} facilityId={this.props.scope === "facility" ? (this.props.facilityId || current?.facilityId) : null} contractId={this.props.scope === "contract" ? (this.props.contractId || current?.contractId) : null} subsidiaryId={(this.props.scope === "subsidiary") ? (this.props.subsidiaryId || current?.subsidiaryId) : null} path={((this.props.scope === "subsidiary" && !this.props.subsidiaryId) || (this.props.scope === "contract" && !this.props.contractId)) ? `/${this.state.entryPoint.split("/").slice(2).join("/")}` : this.state.entryPoint} instance={ref => this.createFolderModal = ref} />
            <GridElement padding="0" styled title={this.props.title} iconType={this.props.iconType || "far"} icon={this.props.icon} rightContent={(!(this.state.entryPoint === "/" && ((this.props.scope === "subsidiary" && !this.props.subsidiaryId) || (this.props.scope === "tenantUtilitiesStatement" && !this.props.tenantUtilitiesStatementId) || (this.props.scope === "utilitiesStatement" && !this.props.utilitiesStatementId)|| (this.props.scope === "purchaseOrder" && !this.props.purchaseOrderId) || (this.props.scope === "measure" && !this.props.measureId) || (this.props.scope === "contractProvisionProof" && !this.props.contractProvisionProofId) || (this.props.scope === "facility" && !this.props.facilityId) || (this.props.scope === "contract" && !this.props.contractId) || (this.props.scope === "fileStructureTemplate" && !this.props.fileStructureTemplateId))) && !this.props.readOnly && this.props.scope !== "google") && <div style={{ display: "flex", alignItems: "center" }}>
                {scanmetrix.checkPermission("File") > 1 && <>
                    <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
                        {!this.props.onlyFolder &&
                        <p style={{ height: "100%", display: "flex", alignItems: "center" }}>{i18next.t("page.private.documents.generalDocuments.text")}</p>}
                        <Button secondary onClick={() => this.createFolderModal.open()} title={i18next.t("page.private.documents.generalDocuments.createFile")} thick icon="folder-plus"/>
                        <Button disabled={scanmetrix.checkPermission("File") < 2 || this.props.readOnly || this.props.scope === "fileStructureTemplate" || (this.state.entryPoint === "/" && ((this.props.scope === "subsidiary" && !this.props.subsidiaryId) || (this.props.scope === "tenantUtilitiesStatement" && !this.props.tenantUtilitiesStatementId) || (this.props.scope === "utilitiesStatement" && !this.props.utilitiesStatementId) || (this.props.scope === "purchaseOrder" && !this.props.purchaseOrderId) || (this.props.scope === "measure" && !this.props.measureId) || (this.props.scope === "contractProvisionProof" && !this.props.contractProvisionProofId) || (this.props.scope === "contract" && !this.props.contractId) || (this.props.scope === "facility" && !this.props.facilityId) || (this.props.scope === "fileStructureTemplate" && !this.props.fileStructureTemplateId)))} onClick={() => {
                            fileDialog({ multiple: true, accept: "*" }).then(files => this.onUpload(Array.from(files)))
                        }} title={i18next.t("page.private.documents.generalDocuments.uploadFile")} thick icon="file-upload"/>
                    </div>
                </>}
            </div>}>
                <FileTable>
                    <FileHead>
                        <p>{i18next.t("page.private.documents.documentsTable.name")}</p>
                        <p>{i18next.t("page.private.documents.documentsTable.type")}</p>
                        <p>{i18next.t("page.private.documents.documentsTable.owner")}</p>
                        <p>{i18next.t("page.private.documents.documentsTable.modified")}</p>
                        <p>{i18next.t("page.private.documents.documentsTable.created")}</p>
                        <p>{i18next.t("page.private.documents.documentsTable.size")}</p>
                        <p>{i18next.t("page.private.documents.documentsTable.release")}</p>
                    </FileHead>
                    <Dropzone ref={dropzoneRef} disabled={scanmetrix.checkPermission("File") < 2 || this.props.readOnly || this.props.scope === "fileStructureTemplate" || (this.state.entryPoint === "/" && ((this.props.scope === "subsidiary" && !this.props.subsidiaryId) || (this.props.scope === "tenantUtilitiesStatement" && !this.props.tenantUtilitiesStatementId) || (this.props.scope === "utilitiesStatement" && !this.props.utilitiesStatementId) || (this.props.scope === "purchaseOrder" && !this.props.purchaseOrderId) || (this.props.scope === "measure" && !this.props.measureId) || (this.props.scope === "contractProvisionProof" && !this.props.contractProvisionProofId) || (this.props.scope === "contract" && !this.props.contractId) || (this.props.scope === "facility" && !this.props.facilityId) || (this.props.scope === "fileStructureTemplate" && !this.props.fileStructureTemplateId)))} multiple={true} noClick onDrop={this.onUpload}>
                        {({ getRootProps, isDragAccept, isDragReject, isDragActive, rootRef }) => {
                            return <FileBody ref={rootRef} {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef" })}>
                                {!this.props.readOnly && <Upload className="upload">
                                    <i className="fal fa-upload" />
                                    <p>{i18next.t("page.private.documents.dataUpload")}</p>
                                </Upload>}
                                {back && <Back onClick={() => this.setState({ entryPoint: back }, () => {
                                    if(this.props.onPathChange) this.props.onPathChange(back)
                                })}><i className="far fa-arrow-left" />{i18next.t("page.private.documents.generalDocuments.back")} {backDisplay}</Back>}
                                {!back && <BackPlaceholder><i className="far fa-folder" />{i18next.t("page.private.documents.generalDocuments.bottomText")}</BackPlaceholder>}
                                {Object.keys(data).sort((a, b) => a.localeCompare(b)).map((key, index) => {
                                    const dataSet = data[key]

                                    if(dataSet.type === "folder") return <Folder key={index} onContextMenu={event => {
                                        event.preventDefault()
                                        contextMenu.show({ id: "folder_menu", event, props: dataSet  })
                                    }} onClick={() => this.setState({ entryPoint: `${this.state.entryPoint}${key}/` }, () => {
                                        if(this.props.onPathChange) this.props.onPathChange(this.state.entryPoint)
                                    })}>
                                        <p title={key}><i className={`fas fa-${Object.keys(dataSet.data).length > 0 ? "folders" : "folder"}`} />{dataSet.name}</p>
                                        <p>{i18next.t("page.private.documents.File")}</p>
                                        <p>
                                            {dataSet.user && <>
                                                <Avatar size="32" email={scanmetrix.disableGravatar ? null: dataSet.user.email} name={`${dataSet.user.firstName} ${dataSet.user.lastName}`} className="avatar" />{`${dataSet.user.firstName} ${dataSet.user.lastName}`}
                                            </>}
                                            {dataSet.serviceProvider && dataSet.serviceProvider.name}
                                        </p>
                                        <p>{dataSet.modified && moment(dataSet.modified).fromNow()}</p>
                                        <p>{dataSet.created && moment(dataSet.created).fromNow()}</p>
                                        <p>{filesize(dataSet.size)}</p>
                                        <p><i className="fas fa-lock-open perm" />{i18next.t("page.private.documents.FolderAll")}</p>
                                    </Folder>

                                    return null
                                })}
                                {Object.keys(data).sort((a, b) => a.localeCompare(b)).map((key, index) => {
                                    const dataSet = data[key]

                                    if(dataSet.type === "file") return <File key={index} onContextMenu={event => {
                                        event.preventDefault()
                                        contextMenu.show({ id: "file_menu", event, props: dataSet  })
                                    }} onClick={() => window.open(scanmetrix.backendURL + (this.props.scope === "google" ? "/integration/google_workspace/file/" : "/file/") + dataSet.id, "_blank")}>
                                        <p title={key}><i className={`fas fa-${parseFileIcon(dataSet.format)}`} />{dataSet.name}</p>
                                        <p>{dataSet.format}</p>
                                        <p>
                                            {dataSet.user && <>
                                                <Avatar size="32" email={scanmetrix.disableGravatar ? null : dataSet.user.email} name={`${dataSet.user.firstName} ${dataSet.user.lastName}`} className="avatar" />{`${dataSet.user.firstName} ${dataSet.user.lastName}`}
                                            </>}
                                            {dataSet.serviceProvider && dataSet.serviceProvider.name}
                                        </p>
                                        <p>{dataSet.modified && moment(dataSet.modified).fromNow()}</p>
                                        <p>{dataSet.created && moment(dataSet.created).fromNow()}</p>
                                        <p>{filesize(dataSet.size)}</p>
                                        <p><i className="fas fa-lock-open perm" />{i18next.t("page.private.documents.normalFileAll")}</p>
                                    </File>

                                    return null
                                })}
                            </FileBody>
                        }}
                    </Dropzone>
                </FileTable>
            </GridElement>
        </>
    }
}

class RenameItemModal extends Component {
    state = { name: null, id: null }

    render() {
        return <Modal instance={this.props.instance} initialize={(props) => {
            let suffix = null
            let name = props.name + ""
            if(props.type === "file") {
                let args = props.name.split(".")

                if(args.length > 1) {
                    suffix = "." + args[args.length - 1]
                    name = name.substr(0, name.length - suffix.length)
                }
            }
            this.setState({ name, id: props.id, suffix: props.type === "file" ? suffix : null})
        }}>
            <Modal.Head title={i18next.t("page.private.documents.personalDocuments.rightClickfileMenu.fileRename.elementRenameTitle")} icon="pencil" />
            <Modal.Body padding={24}>
                <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="pencil" label="Name" adjustWidth />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button thick disabled={!this.state.name} title={i18next.t("page.private.documents.personalDocuments.rightClickfileMenu.fileRename.elementRenameButton")} icon="pencil" onClick={() => scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($name: String!, $id: ID!) {
                        renameFileOrFolder(name: $name, id: $id)
                    }
                `,
                variables: {
                    name: this.state.name + (this.state.suffix || ""),
                    id: this.state.id
                }
            }).then(result => {
                if(result.data.renameFileOrFolder) {
                    this.props.refresh()
                    instance.close()
                }
            })} />} />
        </Modal>
    }
}


class DeleteItemModal extends Component {
    state = { name: null, id: null, error: null, loading: false }

    render() {
        return <Modal instance={this.props.instance} initialize={(props) => this.setState({ name: props.name, loading: false, confirmation: null, id: props.id, error: null })}>
            <Modal.Head title={i18next.t("page.private.documents.personalDocuments.rightClickfileMenu.fileDelete.elementDeleteTitle")} icon="trash" />
            <Modal.Body padding={24}>
            {i18next.t("page.private.documents.personalDocuments.rightClickfileMenu.fileDelete.elementDeleteText")} <b>{i18next.t("page.private.documents.personalDocuments.rightClickfileMenu.fileDelete.elementDeleteText1")}</b>
                {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.documents.personalDocuments.rightClickfileMenu.fileDelete.elementDeleteButton")} icon="trash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                    mutation($id: ID!) {
                        deleteFileOrFolder(id: $id)
                    }
                `,
                    variables: {
                        id: this.state.id
                    }
                }).then(result => {
                    if(result.data.deleteFileOrFolder) {
                        Promise.resolve(this.props.refresh()).then(() => {
                            instance.close()
                        }).catch(error => {
                            this.setState({ loading: false, error: String(error) })
                        })
                    } else {
                        this.setState({ loading: false, error: String(result.errors) })
                    }
                })
            }} />} />
        </Modal>
    }
}

class CreateFolderModal extends Component {
    state = { folderName: null, error: null, loading: false }

    render() {
        return <Modal instance={this.props.instance} initialize={() => this.setState({ folderName: null, error: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.documents.generalDocuments.createFileForm.createFileTitle")} icon="folder-plus" />
            <Modal.Body padding={24}>
                <Input required value={this.state.folderName} onChange={folderName => this.setState({ folderName })} icon="folder" label={i18next.t("page.private.documents.generalDocuments.createFileForm.ordnerNameLabel")} adjustWidth />
                {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.folderName} title={i18next.t("page.private.documents.generalDocuments.createFileForm.createFileButton")} icon="folder-plus" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                    mutation($name: String!, $path: String!, $scope: String!, $subsidiaryId: ID, $measureId: ID, $contractProvisionProofId: ID, $contractId: ID, $fileStructureTemplateId: ID, $facilityId: ID, $purchaseOrderId: ID, $tenantUtilitiesStatementId: ID, $utilitiesStatementId: ID) {
                        createFolder(name: $name, path: $path, scope: $scope, subsidiaryId: $subsidiaryId, measureId: $measureId, contractProvisionProofId: $contractProvisionProofId, contractId: $contractId, facilityId: $facilityId, utilitiesStatementId: $utilitiesStatementId, tenantUtilitiesStatementId: $tenantUtilitiesStatementId, purchaseOrderId: $purchaseOrderId, fileStructureTemplateId: $fileStructureTemplateId)
                    }
                `,
                    variables: {
                        name: this.state.folderName,
                        path: this.props.path,
                        scope: this.props.scope,
                        subsidiaryId: this.props.subsidiaryId,
                        contractId: this.props.contractId,
                        measureId: this.props.measureId,
                        contractProvisionProofId: this.props.contractProvisionProofId,
                        purchaseOrderId: this.props.purchaseOrderId,
                        utilitiesStatementId: this.props.utilitiesStatementId,
                        tenantUtilitiesStatementId: this.props.tenantUtilitiesStatementId,
                        fileStructureTemplateId: this.props.fileStructureTemplateId,
                        facilityId: this.props.facilityId
                    }
                }).then(result => {
                    if(result.data.createFolder) {
                        Promise.resolve(this.props.refresh()).then(() => {
                            instance.close()
                        }).catch(error => {
                            this.setState({ loading: false, error: String(error) })
                        })
                    } else {
                        this.setState({ loading: false, error: String(result.errors) })
                    }
                })
            }} />} />
        </Modal>
    }
}

const FileStats = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
    margin-bottom: 32px;
`

const FileStat = styled.div`
    background: white;
    height: 170px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 8px 24px -16px rgba(0,0,0,0.4);
    box-sizing: border-box;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;

    .single {
        font-size: 1.6em;
        color: gray;
    }

    .title {
        font-size: 1.4em;

        i {
            margin-right: 16px;
            color: gray;
        }
    }

    .value {
        display: flex;
        font-size: 1.3em;

        .actual {
            font-weight: bold;
            color: #3b97d3;
        }

        .slash {
            margin: 0 4px;
        }

        .total {
            color: gray;
        }
    }

    .progress {
        width: 100%;
        height: 24px;
        border: 1px solid #ccc;
        border-radius: 24px;
        position: relative;
        margin-top: 8px;

        .inner {
            position: absolute;
            top: 4px;
            left: 4px;
            height: 16px;
            border-radius: 16px;
            background: linear-gradient(140deg,#3B97D3,#81c6f3);
            box-shadow: 0 0 6px 0 rgba(59, 151, 211, 0.5);
        }
    }
`

export default withRouter(class extends Component {
    state = { serviceProviderPath: "/", subsidiaryPath: "/", globalPath: "/", userPath: "/", measurePath: "/", contractProvisionProofPath: "/", contractPath: "/", purchaseOrderPath: "/", utilitiesStatementPath: "/", tenantUtilitiesStatementPath: "/", facilityPath: "/", fileStructureTemplatePath: "/", statistics: {
        totalSize: 0,
        files: 0,
        folders: 0,
        maxSize: 0
    } }

    constructor(props) {
        super(props)

        fetch(scanmetrix.backendURL + "/document-statistics", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ statistics: data })
        })
    }


    render() {
        return <Page {...this.props} style={{ boxSizing: 'border-box' }}>
            <Breadcrumbs values={[
                {
                    icon: "folder-tree",
                    title: i18next.t("page.private.documents.generalDocuments.breadCrumbsTitle")
                }
            ]} />
            {/*<FileStats>
                <FileStat>
                    <p className="title"><i className="far fa-memory" />Belegter Speicher</p>
                    <div>
                        <div className="value">
                            <p className="actual">{prettyBytes(this.state.statistics.totalSize, { locale: "de" })}</p>
                            <p className="slash">/</p>
                            <p className="total">{prettyBytes(this.state.statistics.maxSize)}</p>
                        </div>
                        <div className="progress">
                            <div className="inner" style={{ width: `${Number((this.state.statistics.totalSize / this.state.statistics.maxSize) * 100).toFixed(2)}%` }} />
                        </div>
                    </div>
                </FileStat>
                <FileStat>
                    <p className="title"><i className="far fa-file" />Dateien</p>
                    <p className="single">{this.state.statistics.files} Stück</p>
                </FileStat>
                <FileStat>
                    <p className="title"><i className="far fa-folders" />Ordner</p>
                    <p className="single">{this.state.statistics.folders} Stück</p>
                </FileStat>
            </FileStats>*/}
            <Section bodyPadding="32px 0 0 0">
                {/*<Section.Item title="Globale Dokumente" icon="globe-europe">
                    <GridElement padding="0" styled title="Globale Dokumente" icon="globe-europe" rightContent={<div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginRight: 16 }}>Ziehen Sie Dateien in das Suchfenster, um diese hochzuladen.</p>
                        <Button onClick={() => this.createFolderModal.open()} title="Ordner anlegen" icon="folder-plus" />
                    </div>}>
                        <FileManager refresh={refresh => this.refresh = refresh} entryPoint={this.state.path} onPathChange={path => this.setState({ path })} />
                    </GridElement>
                </Section.Item>*/}
                <Section.Item title={i18next.t("page.private.documents.generalDocuments.generalDocumentsTitle")} icon="globe-europe">
                    <FileManager title={i18next.t("page.private.documents.generalDocuments.generalDocumentsTableTitle")} icon="globe-europe" scope="global" onPathChange={globalPath => this.setState({ globalPath })} />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.documents.branchDocuments")} icon="map-marker">
                    <FileManager title={i18next.t("page.private.documents.branchDocumentsTableTitle")} icon="map-marker" scope="subsidiary" onPathChange={subsidiaryPath => this.setState({ subsidiaryPath })} />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.documents.contractDocument")} icon="file-contract">
                    <FileManager title={i18next.t("page.private.documents.contractDocumentTableTitle")} icon="file-contract" scope="contract" onPathChange={contractPath => this.setState({ contractPath })} />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.documents.performanceProof")} icon="file-certificate">
                    <FileManager title={i18next.t("page.private.documents.performanceProofTableTitle")} icon="file-certificate" scope="contractProvisionProof" onPathChange={contractProvisionProofPath => this.setState({ contractProvisionProofPath })} />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.documents.measures")} icon="network-wired">
                    <FileManager title={i18next.t("page.private.documents.measuresTableTitle")} icon="network-wired" scope="measure" onPathChange={measurePath => this.setState({ measurePath })} />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.documents.serviceProviderDocuments")} icon="user-tie">
                    <FileManager readOnly title={i18next.t("page.private.documents.serviceProviderDocumentsTableTitle")} icon="user-tie" scope="serviceProvider" onPathChange={serviceProviderPath => this.setState({ serviceProviderPath })} />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.documents.personalDocuments.personalDocumentsTitle")} icon="user">
                    <FileManager title={i18next.t("page.private.documents.personalDocuments.personalDocumentsTableTitle")} icon="user" scope="user" onPathChange={userPath => this.setState({ userPath })} />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.documents.googleDrive")} icon="google-drive" iconType="fab">
                    <FileManager iconType="fab" title={i18next.t("page.private.documents.googleDriveTableTitle")} icon="google-drive" scope="google" onPathChange={googlePath => this.setState({ googlePath })} />
                </Section.Item>
                {/*<Section.Item title="Persönliche Dokumente" icon="user">
                    <GridElement padding="0" styled title="Persönliche Dokumente" icon="user" rightContent={<div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginRight: 16 }}>Ziehen Sie Dateien in das Suchfenster, um diese hochzuladen.</p>
                        <Button onClick={() => this.createFolderModal.open()} title="Ordner anlegen" icon="folder-plus" />
                    </div>}>
                        <FileManager refresh={refresh => this.refresh = refresh} entryPoint={this.state.path} onPathChange={path => this.setState({ path })} />
                    </GridElement>
                </Section.Item>*/}
            </Section>
        </Page>
    }
})

export { FileManager }
