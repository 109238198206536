import React, { Component } from 'react'
import { Button, Modal, Input } from 'scanmetrix-components'
import i18next from "i18next";

export default class extends Component {
    state = { loading: false, enterCode: false, code: null }

    render() {
        return <Modal maxWidth="768px" instance={this.props.instance} initialize={() => this.setState({ loading: false, enterCode: false, code: null })}>
            <Modal.Head title={i18next.t("page.private.integrations.googleWorkspaceModal.title")} icon="sync" />
            <Modal.Body padding={24}>
                {!this.state.enterCode && <p>{i18next.t("page.private.integrations.googleWorkspaceModal.description")}</p>}
                {this.state.enterCode && <Input adjustWidth value={this.state.code} onChange={code => this.setState({ code })} textArea label="Authentifizierungscode" icon="sync" />}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={this.state.enterCode && !this.state.code} loading={this.state.loading} thick iconType={this.state.enterCode ? "far" : "fab"} title={this.state.enterCode ? i18next.t("page.private.integrations.googleWorkspaceModal.linkButton") : i18next.t("page.private.integrations.googleWorkspaceModal.registrationButton")} icon={this.state.enterCode ? "link" : "google"} onClick={() => {
                if(!this.state.loading && !this.state.enterCode) {
                    this.setState({ loading: true })

                    fetch(scanmetrix.backendURL + "/integration/google_workspace/request", {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(result => result.json()).then(data => {
                        const authUrl = data.authUrl

                        window.open(authUrl, "_blank")

                        this.setState({ enterCode: true, loading: false })
                    })
                } else if(this.state.enterCode && !this.state.loading) {
                    this.setState({ loading: true })

                    fetch(scanmetrix.backendURL + "/integration/google_workspace/authorize", {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify({ code: this.state.code }),
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(result => result.json()).then(data => {
                        if(data.status === "SUCCESSFUL") {
                            instance.close()
                            this.props.refresh()
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                }
            }} />} />
        </Modal>
    }
}