import React, { Component } from "react"
import { Page, Breadcrumbs, Table, GridElement, Button, Section } from "scanmetrix-components"
import CreateContractModal from "./modals/CreateContractModal"
import i18next from "i18next"
import EndTracingModal from "./modals/EndTracingModal"
import ContractProvisionIntervals from "./tabs/ContractProvisionIntervals";
import ContractProvisionProofs from "./tabs/ContractProvisionProofs";
import MissingFiles from "./tabs/MissingFiles";
import ShowSubsidiariesModal from "./modals/ShowSubsidiariesModal";

import DuplicateContractModal from "./modals/DuplicateContractModal";

export default class extends Component {
    state = { isToggled: false, contracts: null, proofs: [], subsidiaries: [],  serviceProviders: [], orderBy: "dateDESC", filterSubsidiaryId: null, filterServiceProviderId: null, filterType: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Contracts {
                        nodes {
                            id
                            beginsAt
                            endsAt
                            title
                            type
                            number
                            perpetual
                            automaticRenewal
                            partnerName
                            contractDurationAmount
                            contractDurationType
                            workSection {
                                id
                                name
                                icon
                                stlbId
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ contracts: data.data.Contracts.nodes })
        })
    }


    toggle = () => {             // Modal Trigger
        this.setState((prevState) => ({
          isToggled: !prevState.isToggled,
        }));
      };

    render() {










        return <Page  {...this.props} >
            <DuplicateContractModal refresh={() => this.fetch()} history={this.props.history} instance={ref => this.showDuplicateContractModal = ref} />
            <ShowSubsidiariesModal history={this.props.history} instance={ref => this.showSubsidiariesModal = ref} />
            <CreateContractModal refresh={() => this.fetch()} instance={ref => this.createContractModal = ref} history={this.props.history} />
            <EndTracingModal refresh={() => this.fetch()} instance={ref => this.endTracingModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "file-contract",
                    title: i18next.t("page.private.contracts.breadCrumbsTitle")
                }
            ]} />

            <Section bodyPadding="32px 0 0 0" initialSelected={this.props.location.state ? (this.props.location.state.tab || 0) : 0}>
                <Section.Item title={i18next.t("page.private.contracts.contractsTable.sectionTitle")} icon="file-contract" amount={this.state.contracts ? this.state.contracts.length : 0}>
                    <GridElement styled title={i18next.t("page.private.contracts.contractsTable.gridElementTitle")} icon="file-contract" rightContent={<Button thick icon="file-contract" disabled={scanmetrix.checkPermission("Contract") < 2} title={i18next.t("page.private.contracts.contractsTable.button")} onClick={() => this.createContractModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.contracts.contractsTable.table.title"), width: "15%", column: "title" },
                                { title: i18next.t("page.private.contracts.contractsTable.table.type"), width: "12.5%", column: "type" },
                                { title: i18next.t("page.private.contracts.contractsTable.table.workSection"), width: "10%", column: "workSection" },
                                { title: i18next.t("page.private.contracts.contractsTable.table.number"), width: "10%", column: "number" },
                                { title: i18next.t("page.private.contracts.contractsTable.table.partnerName"), width: "15%", column: "partnerName" },
                                { title: i18next.t("page.private.contracts.contractsTable.table.duration"), width: "7.5%", column: "duration" },
                                { title: i18next.t("page.private.contracts.contractsTable.table.beginsAt"), width: "10%", column: "beginsAt" },
                                { title: i18next.t("page.private.contracts.contractsTable.table.endsAt"), width: "10%", column: "endsAt" },
                                { title: i18next.t("page.private.contracts.contractsTable.table.branch"), width: "10%", column: "subsidiaries" }
                            ]}
                            onItemClick={contract => this.props.history.push(`/contract/${contract.id}`)}
                            items={this.state.contracts ? this.state.contracts.map(contract => ({
                                ...contract,
                                subsidiaries: <Button thick title="Anzeigen" icon="map-marker" stopPropagation onClick={() => {
                                    this.showSubsidiariesModal.open(contract.id)
                                }}/>,
                                workSection: contract.workSection ? `${contract.workSection.stlbId ? (contract.workSection.stlbId + " / ") : ""}${contract.workSection.name}` : "",
                                type: contract.type ? i18next.t("page.private.dashboard.propertyManagement.contractType." + contract.type) : "",
                                duration: contract.perpetual ? i18next.t("page.private.contracts.contractsTable.durationUnlimited") : `${contract.contractDurationAmount} ${i18next.t("defaults.durationType." + contract.contractDurationType)}`,
                                beginsAt: contract.beginsAt ? moment(contract.beginsAt).format("DD.MM.YYYY") : i18next.t("page.private.contracts.contractsTable.beginsAtUnavailabe"),
                                endsAt: contract.endsAt ? moment(contract.endsAt).format("DD.MM.YYYY") : ((contract.contractDurationAmount && contract.contractDurationType && !contract.perpetual && contract.contractDurationAmount && contract.contractDurationType && contract.beginsAt) ? moment(contract.beginsAt).add(contract.contractDurationAmount, contract.contractDurationType).format("DD.MM.YYYY") : (contract.perpetual ? i18next.t("page.private.contracts.contractsTable.endsAtUnlimited") : i18next.t("page.private.contracts.contractsTable.endsAtUnavailable"))),
                                automaticRenewal: contract.automaticRenewal ? <><i className="far fa-check-circle" />{i18next.t("page.private.contracts.contractsTable.yes")}</> : <><i className="far fa-times-circle" />{i18next.t("page.private.contracts.contractsTable.no")}</>
                            })) : null}
                            contextItems={[
                                { title: i18next.t("page.private.contracts.contractsTable.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: contract => window.open(`/contract/${contract.id}`, '_blank')},
                                { title: "Duplizieren", icon: "fa-duotone fa-copy", onClick: contract => this.showDuplicateContractModal.open(contract.id) },
                            ]}//contractId={this.state.selectedContractId}
                        />

                    </GridElement>

                </Section.Item>
                <ContractProvisionProofs endTracingModal={data => this.endTracingModal.open(data)} />
                <ContractProvisionIntervals />
                <MissingFiles />

            </Section>

        </Page>
    }
}

