import React, { Component } from "react"
import styled from "styled-components"
import ContentLoader from "react-content-loader";

import StyledStat from './Stat'
import PieChart from './PieChart'
import i18next from "i18next";

const RatingBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  box-sizing: border-box;
  margin-bottom: 25px;
  user-select: none;
  
  .top-title {
    width: 100%;
    padding: 16px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    
    i, .toptitle {
      color: #20242B;
      margin-right: 8px;
    }
  }
  
  .inner-view {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: white;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
  }
`

const ItemBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .title, .label {
    color: #20242B;
    text-align: center;
    margin: 16px 0;
  }
`

const getEvaluationColor = percentage => {
    if(percentage < 0.25) return "#c0392b"
    if(percentage < 0.5) return "#e67e22"
    if(percentage < 0.75) return "#e67e22"
    if(percentage < 0.9) return "#2ecc71"

    return "#1abc9c"
}

class Stat extends Component {
    render() {
        const value = this.props.value

        return <StyledStat>
            {!(value === null || value === undefined) && value <= 0.75 && value > 0.5 && <div className="orange attention animate__animated animate__flash animate__slower animate__infinite"><i className="fas fa-do-not-enter" /></div>}
            {!(value === null || value === undefined) && value <= 0.5 && <div className="attention animate__animated animate__flash animate__slower animate__infinite"><i className="fas fa-exclamation" /></div>}
            <PieChart style={{ color: !value ? "black" : getEvaluationColor(value) }}>
                {!(value === null || value === undefined) && <svg viewBox="0 0 36 36">
                    <path
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#cccccc"
                        strokeWidth="3"
                        strokeDasharray="100, 100"
                    />
                    <path
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                        stroke={getEvaluationColor(value)}
                        strokeWidth="3"
                        strokeDasharray={`${value * 100}, 100`}
                    />
                </svg>}
                {(value === null || value === undefined) && <ContentLoader
                    speed={2}
                    width={72}
                    height={72}
                    viewBox="0 0 72 72"
                    foregroundColor="rgba(255, 255, 255, 0.25)"
                    backgroundColor="rgba(0, 0, 0, 0.1)"
                >
                    <circle cx="36" cy="36" r="36" />
                </ContentLoader>}
                {!(value === null || value === undefined) && <p className="value">{Math.round(value * 100)}</p>}
            </PieChart>
        </StyledStat>
    }
}

class Item extends Component {
    render() {
        return<ItemBody>
            <p className='title'>{this.props.title}</p>
            <Stat value={this.props.value} />
            <p className='label'>{i18next.t("page.private.subsidiary.styled.ratingView.percent")}</p>
        </ItemBody>
    }
}

export default class extends Component {

    render() {
        return <RatingBody>
            <div className='top-title'>
                <i className='far fa-tachometer' />
                <p className='title-label'>{i18next.t("page.private.subsidiary.styled.ratingView.overallRating")}</p>
            </div>

            <div className='inner-view'>
                <Item
                    title={i18next.t("page.private.subsidiary.styled.ratingView.functionalObjects")}
                    value={this.props.operative}
                />
                <Item
                    title={i18next.t("page.private.subsidiary.styled.ratingView.correctedDefects")}
                    value={this.props.fixedFlaws}
                />
                <Item
                    title={i18next.t("page.private.subsidiary.styled.ratingView.complianceInspectionDeadlines")}
                    value={this.props.validIntervals}
                />
            </div>

        </RatingBody>
    }
}
