import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import countries from "i18n-iso-countries"
import i18next from "i18next"

countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

export default class extends Component {
    state = { name: null, legalName: null, branchId: null, label: null, addressStreetName: null, addressStreetNumber: null, addressPostalCode: null, addressCity: null, addressCountry: null, addressAdditional: null, branches: [], loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Branches {
                        nodes {
                            id
                            name
                            legalName
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ branches: data.data.Branches.nodes })
        })
    }

    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ name: null, legalName: null, branchId: null, label: null, addressStreetName: null, addressStreetNumber: null, addressPostalCode: null, addressCity: null, addressCountry: null, addressAdditional: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.titleForm")} icon="map-marker" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Modal.Divider title={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.generalInformation")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "2fr 1fr" }}>
                        <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.name")} adjustWidth />
                        <Input value={this.state.label} onChange={label => this.setState({ label })} icon="store" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.abbreviation")} adjustWidth />
                    </div>
                    <Select required adjustWidth value={this.state.branchId} onChange={(branchId, branch) => this.setState({ branchId, legalName: this.state.legalName || branch.legalName })} icon="project-diagram" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.businessBranch")} items={this.state.branches.map(branch => ({ key: branch.id, legalName: branch.legalName, title: `${branch.name} / ${branch.legalName}` }))} />
                    <Input required value={this.state.legalName} onChange={legalName => this.setState({ legalName })} icon="gavel" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.legalName")} disabled={!this.state.branchId} adjustWidth />
                    <Modal.Divider title={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.address")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "2fr 0.5fr" }}>
                        <Input required value={this.state.addressStreetName} onChange={addressStreetName => this.setState({ addressStreetName })} icon="road" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.street")} adjustWidth />
                        <Input required value={this.state.addressStreetNumber} onChange={addressStreetNumber => this.setState({ addressStreetNumber })} icon="bell" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.houseNumber")} adjustWidth />
                    </div>
                    <Input value={this.state.addressAdditional} onChange={addressAdditional => this.setState({ addressAdditional })} icon="font" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.additionalAddress")} adjustWidth />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 2fr" }}>
                        <Input required value={this.state.addressPostalCode} onChange={addressPostalCode => this.setState({ addressPostalCode })} icon="mailbox" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.postalCode")} adjustWidth />
                        <Input required value={this.state.addressCity} onChange={addressCity => this.setState({ addressCity })} icon="city" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.city")} adjustWidth />
                    </div>
                    <Select upwards required adjustWidth value={this.state.addressCountry} onChange={addressCountry => this.setState({ addressCountry })} icon="globe-europe" label={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.country")} items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
               </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.legalName || !this.state.branchId || !this.state.addressStreetName || !this.state.addressStreetNumber || !this.state.addressPostalCode || !this.state.addressCity || !this.state.addressCountry} title={i18next.t("page.private.facilityManagement.branches.createSubsidiaryModal.exitButton")} icon="map-marker" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $legalName: String!, $label: String, $branchId: ID!, $addressStreetName: String!, $addressStreetNumber: String!, $addressAdditional: String, $addressPostalCode: String!, $addressCity: String!, $addressCountry: String!) {
                            createSubsidiary(name: $name, legalName: $legalName, label: $label, branchId: $branchId, addressStreetName: $addressStreetName, addressStreetNumber: $addressStreetNumber, addressAdditional: $addressAdditional, addressPostalCode: $addressPostalCode, addressCity: $addressCity, addressCountry: $addressCountry)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        legalName: this.state.legalName,
                        label: this.state.label,
                        branchId: this.state.branchId,
                        addressStreetName: this.state.addressStreetName,
                        addressStreetNumber: this.state.addressStreetNumber,
                        addressAdditional: this.state.addressAdditional,
                        addressPostalCode: this.state.addressPostalCode,
                        addressCity: this.state.addressCity,
                        addressCountry: this.state.addressCountry
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createSubsidiary) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
