import React, { Component } from "react"
import { Section, GridElement, Table, Button } from "scanmetrix-components"
import { contractTypes } from "../../dashboard/propertyManagement"
import { withRouter } from "react-router-dom"
import OpenContractProvisionProofDocumentsModal from "../../contract/modals/OpenContractProvisionProofDocumentsModal";
import i18next from "i18next";

export default withRouter(class extends Component {
    state = { missingContractFiles: null, missingContractProvisionProofFiles: null }

    constructor(props) {
        super(props)

        fetch(scanmetrix.backendURL + "/missing-contract-files", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState(data)
        })
    }

    render() {
        return <Section.Item title={i18next.t("page.private.contracts.missingFiles.sectionTitle")} icon="file-circle-question" {...this.props} amount={this.state.missingContractFiles ? (this.state.missingContractFiles.length + this.state.missingContractProvisionProofFiles.length) : 0}>
            <OpenContractProvisionProofDocumentsModal instance={ref => this.openContractProvisionProofDocumentsModal = ref} />
            <div style={{ display: "grid", gridGap: 32, gridAutoFlow: "row" }}>
                <GridElement styled title={i18next.t("page.private.contracts.missingFiles.gridElementContract")} icon="file-contract">
                    <Table
                        items={this.state.missingContractFiles ? this.state.missingContractFiles.map(contract => ({
                            ...contract,
                            type: contract.type ? contractTypes.find(type => type.key === contract.type).title : "",
                            beginsAt: contract.begins_at ? moment(contract.begins_at).format("DD.MM.YYYY") : i18next.t("page.private.contracts.missingFiles.contractTable.beginsAtUnavailabe"),
                            endsAt: contract.ends_at ? moment(contract.ends_at).format("DD.MM.YYYY") : ((contract.contract_duration_amount && contract.contract_duration_type && !contract.perpetual && contract.contract_duration_amount && contract.contract_duration_type && contract.begins_at) ? moment(contract.begins_at).add(contract.contract_duration_amount, contract.contract_duration_type).format("DD.MM.YYYY") : (contract.perpetual ? i18next.t("page.private.contracts.missingFiles.contractTable.endsAtUnlimited") : i18next.t("page.private.contracts.missingFiles.contractTable.endsAtUnavailable"))),
                            automaticRenewal: contract.automatic_renewal ? <><i className="far fa-check-circle" />{i18next.t("page.private.contracts.missingFiles.contractTable.yes")}</> : <><i className="far fa-times-circle" />{i18next.t("page.private.contracts.missingFiles.contractTable.no")}</>
                        })) : null}
                        onItemClick={item => {
                            this.props.history.push(`/contract/${item.id}`)
                        }}
                        head={[
                            { title: i18next.t("page.private.contracts.missingFiles.contractTable.title"), width: "22.5%", column: "title" },
                            { title: i18next.t("page.private.contracts.missingFiles.contractTable.type"), width: "17.5%", column: "type" },
                            { title: i18next.t("page.private.contracts.missingFiles.contractTable.number"), width: "10%", column: "number" },
                            { title: i18next.t("page.private.contracts.missingFiles.contractTable.partner_name"), width: "20%", column: "partner_name" },
                            { title: i18next.t("page.private.contracts.missingFiles.contractTable.beginsAt"), width: "10%", column: "beginsAt" },
                            { title: i18next.t("page.private.contracts.missingFiles.contractTable.endsAt"), width: "10%", column: "endsAt" },
                            { title: i18next.t("page.private.contracts.missingFiles.contractTable.automaticRenewal"), width: "10%", column: "automaticRenewal" }
                        ]}
                        contextItems={[ 
                            { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: item => window.open(`/contract/${item.id}`, '_blank')}
                        ]}
                        

                    />
                </GridElement>
                <GridElement styled title={i18next.t("page.private.contracts.missingFiles.gridElementPerformanceProof")} icon="file-certificate">
                    <Table
                        items={this.state.missingContractProvisionProofFiles ? this.state.missingContractProvisionProofFiles.map(contract => ({
                            ...contract,
                            date: moment(contract.date).format("DD.MM.YYYY"),
                            type: contract.type ? contractTypes.find(type => type.key === contract.type).title : "",
                            beginsAt: contract.begins_at ? moment(contract.begins_at).format("DD.MM.YYYY") :  i18next.t("page.private.contracts.missingFiles.performanceTable.beginsAtUnavailabe"),
                            endsAt: contract.ends_at ? moment(contract.ends_at).format("DD.MM.YYYY") : ((contract.contract_duration_amount && contract.contract_duration_type && !contract.perpetual && contract.contract_duration_amount && contract.contract_duration_type && contract.begins_at) ? moment(contract.begins_at).add(contract.contract_duration_amount, contract.contract_duration_type).format("DD.MM.YYYY") : (contract.perpetual ?  i18next.t("page.private.contracts.missingFiles.performanceTable.endsAtUnlimited") :  i18next.t("page.private.contracts.missingFiles.performanceTable.endsAtUnavailable"))),
                            automaticRenewal: contract.automatic_renewal ? <><i className="far fa-check-circle" />{i18next.t("page.private.contracts.missingFiles.performanceTable.yes")}</> : <><i className="far fa-times-circle" />{i18next.t("page.private.contracts.missingFiles.performanceTable.no")}</>,
                            files: <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <Button noIconMargin icon="folder-tree" thick stopPropagation onClick={() => this.openContractProvisionProofDocumentsModal.open(contract.id)} />
                            </div>
                        })) : null}
                        onItemClick={item => {
                            this.props.history.push(`/contract/${item.contractId}`)
                        }}
                        head={[
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.date"), width: "10%", column: "date" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.branch"), width: "17.5%", column: "subsidiaryName" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.name"), width: "20%", column: "name" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.title"), width: "22.5%", column: "title" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.type"), width: "17.5%", column: "type" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.number"), width: "10%", column: "number" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.partner_name"), width: "20%", column: "partner_name" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.beginsAt"), width: "10%", column: "beginsAt" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.endsAt"), width: "10%", column: "endsAt" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.automaticRenewal"), width: "10%", column: "automaticRenewal" },
                            { title: i18next.t("page.private.contracts.missingFiles.performanceTable.files"), width: "10%", column: "files" }
                        ]}
                        contextItems={[ 
                            { title: i18next.t("page.private.contracts.missingFiles.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: item => window.open(`/contract/${item.contractId}`, '_blank')}
                        ]}
                    />
                </GridElement>
            </div>
        </Section.Item>
    }
})
