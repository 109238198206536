import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  user-select: none;
  
  h1 {
    color: #3b97d3;
    margin: 32px 0 32px 48px;
    font-size: 1.8em;
    
     @media screen and (max-width: 1920px) {
      margin: 32px 0 32px 32px;
    }
  }
  
  @media screen and (max-width: 1919px) {
    h1 {
      font-size: 1.75em;
      zoom: 0.85;
    }
  }
`
