import React, { Component } from "react"
import { Document, Page as PDFPage } from "react-pdf"
import { MoonLoader as LoadingAnimation } from "react-spinners"
import { Modal } from "scanmetrix-components"
import styled from "styled-components"
import i18next from "i18next"

const StyledDocument = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.loading ? "48px 0" : 0};

    .react-pdf__Document {
        cursor: pointer;
        user-select: none;
        user-drag: none;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.75;
        }
    }
`

const LoadingContainer = styled.div`
    padding: 64px 0;
`

class Loading extends Component {
    render() {
        return <LoadingContainer>
            <LoadingAnimation sizeUnit="px" size={64} color="#3b97d3" />
        </LoadingContainer>
    }
}

export default class extends Component {
    state = { buildingInspectionId: null }

    render() {
        const url = scanmetrix.backendURL + "/buildinginspection/pdf/" + this.state.buildingInspectionId

        return <Modal instance={this.props.instance} initialize={buildingInspectionId => this.setState({ buildingInspectionId })}>
            <Modal.Head title={i18next.t("page.private.buildingInspection.inspectionTemplates.pdfModal.title")} icon="file-pdf" />
            <Modal.Body>
                <StyledDocument>
                    <Document error={<div style={{ width: 500, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: 32 }}>
                        <img src="/illustrations/no_pdf.svg" style={{ maxWidth: 200 }} />
                        <h1 style={{ color: "#3b97d3", textAlign: "center", marginTop: 32 }}>{i18next.t("page.private.buildingInspection.inspectionTemplates.pdfModal.upperDescription")}</h1>
                        <h2 style={{ fontWeight: 300, marginTop: 16, fontSize: 18, lineHeight: "26px", textAlign: "center" }}>{i18next.t("page.private.buildingInspection.inspectionTemplates.pdfModal.lowerDescription")}</h2>
                    </div>} options={{ withCredentials: true }} onClick={() => window.open(url, "_blank")} onLoadSuccess={() => this.setState({ loading: false })} loading={<Loading />} file={url}>
                        <PDFPage pageNumber={1}></PDFPage>
                    </Document>
                </StyledDocument>
            </Modal.Body>
        </Modal>
    }
}
