import styled from "styled-components";

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e3e7;
  background-size: cover;
  background-image: linear-gradient(45deg, #20242bfc, #20242bc9), url(/background.jpg);
  z-index: 5;
`
