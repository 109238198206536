import styled from 'styled-components'

export default styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 32px;
  font-size: 1.2em;
  text-transform: uppercase;
  opacity: 0.5;
  user-select: none;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    margin-top: 8px;
    display: block;
    background: rgba(0, 0, 0, .4);
  }
`
