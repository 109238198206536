import React, { Component } from "react"
import { Modal, Button, Input } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { accountingInvoice: null, date: null, loading: false }

    render() {
        const accountingInvoice = this.state.accountingInvoice

        return <Modal minWidth="650px" instance={this.props.instance} initialize={accountingInvoice => this.setState({ accountingInvoice, date: moment().format("DD.MM.YYYY"), loading: false })}>
            <Modal.Head title={i18next.t("page.private.accounting.paymentModal.title")} icon="coins" />
            <Modal.Body padding={24} adjustHeight="400px">
                <Input adjustWidth label={i18next.t("page.private.accounting.paymentModal.paymentDate")} icon="calendar-day" date value={this.state.date} onChange={date => this.setState({ date })} required />
            </Modal.Body>
            <Modal.Footer buttons={instance => <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
                <Button loading={this.state.loading} disabled={!this.state.date} thick title={i18next.t("page.private.accounting.paymentModal.exitButton")} icon="coins" onClick={() => {
                    this.setState({ loading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($id: ID!, $date: DateTime!) {
                                payAccountingInvoice(id: $id, date: $date)
                            }
                        `,
                        variables: {
                            id: accountingInvoice.id,
                            date: moment(this.state.date, "DD.MM.YYYY").toDate()
                        }
                    }).then(data => {
                        if(data.data.payAccountingInvoice) {
                            return this.props.fetch().then(() => instance.close())
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                }} />
            </div>} />
        </Modal>
    }
}
