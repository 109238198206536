import React, { Component } from "react"
import { Button } from "scanmetrix-components"
import Wheels from "./styled/Wheels"
import TopButtonView from "./styled/TopButtonView"
import CreateCarTireModal from "./modals/CreateCarTireModal"
import DeleteCarTireModal from "./modals/DeleteCarTireModal"

export default class extends Component {
    state = { tires: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($carId: ID!) {
                    CarTires(filter: { carId_eq: $carId }) {
                        nodes {
                            id
                            seasonType
                            type
                            location
                            size
                            amount
                        }
                    }
                }
            `,
            variables: {
                carId: this.props.car.id
            }
        }).then(data => {
            this.setState({ tires: data.data.CarTires.nodes })
        })
    }

    render() {
        return <>
            <CreateCarTireModal carId={this.props.car.id} instance={ref => this.createCarTireModal = ref} refresh={() => this.fetch()} />
            <DeleteCarTireModal instance={ref => this.deleteCarTireModal = ref} refresh={() => this.fetch()} />
            <TopButtonView>
                <Button thick title='Reifen anlegen' icon='tire' onClick={() => this.createCarTireModal.open()} />
            </TopButtonView>
            <Wheels data={this.state.tires} delete={carTireId => this.deleteCarTireModal.open(carTireId)} refresh={() => this.fetch()} />
        </>
    }
}