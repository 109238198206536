import styled from "styled-components"

export default styled.div`
  max-width: 100%;
  margin-bottom: 48px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  
  






  &:hover {
    opacity: 0.75;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .date {
    margin-bottom: 8px;
    font-size: 0.9em;
    opacity: 0.75;
    
    i {
      margin-right: 8px;
    }
  }
  
  .content {
    display: flex;
    
    .thumbnail {
      width: 80px;
      height: 80px;
      flex-shrink: 0;
      border-radius: 4px;
      box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      
      i {
        color: white;
        font-size: 2.4em;
      }
    }
    
    .text {
      padding-left: 32px;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      
      .title {
        font-size: 1.1em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      
      .description {
        margin-top: 8px;
        line-height: 1.6em;
        font-size: 0.8em;
        max-height: 80px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        opacity: 0.75;
      }
    }
  }
  
  @media screen and (max-width: 1920px) {
    .content .thumbnail {
      width: 64px;
      height: 64px;
      
    }
    
    .content .text {
      padding-left: 16px;
    }
  }
`
