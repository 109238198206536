import React, { Component } from "react"

import { GridElement, Button, Table, Section } from "scanmetrix-components"
import UploadInvoiceModal from "./modals/UploadInvoiceModal"
import BookInvoiceModal from "./modals/BookInvoiceModal"

import prettyBytes from "pretty-bytes";
import DeleteAccountingInvoiceDocumentModal from "./modals/DeleteAccountingInvoiceDocumentModal";
import Avatar from "react-avatar";
import i18next from "i18next"

export default class extends Component {
    state = { accountingInvoiceDocuments: null,  loading: false}

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    AccountingInvoiceDocuments {
                        nodes {
                            id
                            name
                            size
                            createdAt
                            accountingInvoices {
                                id
                            }
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            serviceProvider {
                                id
                                name
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ accountingInvoiceDocuments: data.data.AccountingInvoiceDocuments.nodes.filter(aid => aid.accountingInvoices.length === 0) }, () => {
                this.props.onAmount(this.state.accountingInvoiceDocuments.length)
            })
        })
    }

    render() {
        const email = scanmetrix.frontendURL.startsWith("https://") ? `invoice@${scanmetrix.frontendURL.substring(8).split(".")[0]}.cp.mail.scanmetrix.io` : "unbekannt"

        return <Section.Item title={i18next.t("page.private.accounting.unbooked.sectionTitle")} icon="layer-group" {...this.props} amount={this.props.amount}>
            <UploadInvoiceModal instance={ref => this.uploadInvoiceModal = ref} fetch={() => this.fetch()} />
            <BookInvoiceModal instance={ref => this.bookInvoiceModal = ref} fetch={() => {
                this.props.addFactualClearance()
                return this.fetch()
            }} />
            <DeleteAccountingInvoiceDocumentModal instance={ref => this.deleteAccountingInvoiceDocumentModal = ref} fetch={() => this.fetch()} />
            <GridElement styled title={i18next.t("page.private.accounting.unbooked.gridElementTitle")} icon="layer-group" rightContent={<div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                <p>Sende Rechnungen als PDF an <a href={`mailto:${email}`} style={{ color: "#3b97d3", fontWeight: "bold", textDecoration: "none" }}>{email}</a>, um diese automatisch hochzuladen.</p>
                <Button disabled={scanmetrix.checkPermission("AccountingInvoiceDocument") < 2} title={i18next.t("page.private.accounting.unbooked.button")} icon="upload" thick onClick={() => this.uploadInvoiceModal.open()} />
            </div>}>
                <Table
                    head={[
                        { title: i18next.t("page.private.accounting.unbooked.table.createdAt"), width: "12.5%", column: "createdAt" },
                        { title: i18next.t("page.private.accounting.unbooked.table.size"), width: "7.5%", column: "size" },
                        { title: i18next.t("page.private.accounting.unbooked.table.name"), width: "45%", column: "name" },
                        { title: i18next.t("page.private.accounting.unbooked.table.source"), width: "35%", column: "source" }
                    ]}
                    contextItems={scanmetrix.checkPermission("AccountingInvoiceDocument") < 4 ? null : [
                        { title: i18next.t("page.private.accounting.unbooked.deleteButton"), icon: "trash", onClick: item => this.deleteAccountingInvoiceDocumentModal.open(item.id)}
                    ]}
                    onItemClick={item => this.bookInvoiceModal.open(item)}
                    items={this.state.accountingInvoiceDocuments ? this.state.accountingInvoiceDocuments.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(accountingInvoiceDocument => ({
                        ...accountingInvoiceDocument,
                        createdAt: moment(accountingInvoiceDocument.createdAt).format("DD.MM.YYYY HH:mm"),
                        size: prettyBytes(accountingInvoiceDocument.size),
                        name: accountingInvoiceDocument.name,
                        source: accountingInvoiceDocument.user ? <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>{i18next.t("page.private.accounting.unbooked.table.manuallyUploaded")} <b style={{ marginLeft: 8 }}>{accountingInvoiceDocument.user.firstName} {accountingInvoiceDocument.user.lastName}</b> <Avatar size="32" round email={scanmetrix.disableGravatar ? null : accountingInvoiceDocument.user.email} name={`${accountingInvoiceDocument.user.firstName} ${accountingInvoiceDocument.user.lastName}`} className="avatar" style={{ marginLeft: 8 }} /></div> : (accountingInvoiceDocument.serviceProvider ? <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>{i18next.t("page.private.accounting.unbooked.table.automaticallyUploaded")} von <b style={{ marginLeft: 8 }}>{accountingInvoiceDocument.serviceProvider.name}</b></div> : "Abruf aus E-Mail-Postfach")
                    })) : null}
                />
            </GridElement>
        </Section.Item>
    }
}
