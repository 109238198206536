import React, { Component } from 'react'
import { Page, Breadcrumbs, Section, Button } from 'scanmetrix-components'
import styled from "styled-components"
import i18next from "i18next";

import GoogleWorkspaceModal from "./modals/GoogleWorkspaceModal"

const IntegrationContainer = styled.div`
    background: white;
    width: 100%;
    height: 512px;
    padding: 24px;
    border-radius: 5px;
    position: relative;
    box-shadow:  ${props => props.soon ? "none" : "0 4px 24px -8px rgb(0 0 0 / 15%)"};
    user-select: none;
    filter: ${props => props.soon ? "blur(4px)" : "none"};
    pointer-events: ${props => props.soon ? "none" : "all"};
    opacity: ${props => props.soon ? 0.25 : 1};
    box-sizing: border-box;
  
    h2 {
        margin-top: 16px;
        font-weight: normal;
    }
  
    h3 {
        margin: 4px 0;
        font-weight: normal;
    }
  
    p.description {
      height: 220px;
      overflow-y: scroll;
      padding-right: 16px;
      line-height: 1.6em;
      margin-top: 8px;

      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.075);
        border-radius: 4px;
      }
      
      &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.1);
          border-radius: 4px;
      }
    }
  
    .buttonContainer {
        position: absolute;
        left: 50%;
        bottom: 8px;
        transform: translate(-50%, -50%);
    }
  
    .header {
        height: 96px;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 16px;
        border-radius: 5px;
      
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            -webkit-user-drag: none;
        }
    }
`

const Integrations = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
`

class Integration extends Component {
    state = { loading: true, disabled: null }

    constructor(props) {
        super(props)

        if(props.disabled) {
            props.disabled().then(disabled => this.setState({ disabled, loading: false }))
            props.refresh(() => {
                this.setState({ loading: true })
                props.disabled().then(disabled => this.setState({ disabled, loading: false }))
            })
        }
        else this.state.loading = false
    }

    render() {
        const data = this.props.data

        return <div style={{ position: "relative" }}>
            {this.props.data.soon && <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", userSelect: "none", fontSize: "1.2em", opacity: 0.25, background: "#20242b", padding: "6px 12px", color: "white", borderRadius: "5px" }}><i className="far fa-clock" /> {i18next.t("page.private.integrations.deactivated")}</div>}
            <IntegrationContainer soon={this.props.data.soon}>
                <div className="header">
                    <img src={data.icon} />
                </div>
                <h2>{data.title}</h2>
                <h3>{i18next.t("page.private.integrations.version")}</h3>
                <p className="description">{data.description}</p>
                <div className="buttonContainer">
                    <Button loading={this.state.loading} red={this.state.disabled} disabled={this.state.loading || this.state.disabled === null} thick title={this.state.disabled ? i18next.t("page.private.integrations.unlink") : (this.state.disabled === false ? i18next.t("page.private.integrations.IntegrationInstallation") : i18next.t("page.private.integrations.integrationNotPossible"))} icon={this.state.disabled ? "unlink" : "download"} onClick={() => {
                        if(this.props.onClick) {
                            if(this.state.disabled) {
                                this.props.onDisable().then(() => this.setState({ loading: false, disabled: false }))

                                this.setState({ loading: true })
                            } else {
                                this.props.onClick()
                            }
                        }
                    }} />
                </div>
            </IntegrationContainer>
        </div>
    }
}

export default class extends Component {
    state = {
        modalOpen: false
    }

    render() {
        return <Page {...this.props}>
            <GoogleWorkspaceModal instance={ref => this.googleWorkspaceModal = ref} refresh={() => this.refreshGoogle()} />
            <Breadcrumbs values={[
                {
                    icon: "puzzle-piece",
                    title: i18next.t("page.private.integrations.Integration")
                }
            ]} />

            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.integrations.marketPlace")} icon="store">
                    <Integrations>
                        <Integration onDisable={() => {
                            return fetch(scanmetrix.backendURL + "/integration/google_workspace/disable", {
                                method: "POST",
                                credentials: "include",
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            }).then(result => result.json()).then(result => {
                                return result.status === "SUCCESSFUL"
                            })
                        }} refresh={refresh => this.refreshGoogle = refresh} disabled={() => {
                            return fetch(scanmetrix.backendURL + "/integration/google_workspace/request", {
                                method: "GET",
                                credentials: "include",
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            }).then(result => result.json()).then(result => {
                                return result.status === "ALREADY_AUTHORIZED"
                            })
                        }} onClick={() => this.googleWorkspaceModal.open()} data={{
                            icon: "https://lh3.googleusercontent.com/sYGCKFdty43En6yLGeV94mfNGHXfVj-bQYitHRndarB7tHmQq_kyVxhlPejeCBVEEYUbnKG2_jUzgNXoPoer6XJm71V3uz2Z6q0CmNw=w0",
                            description: i18next.t("page.private.integrations.description.title"),
                            title: i18next.t("page.private.integrations.googleworkspace.title")
                        }} />
                        <Integration data={{
                            icon: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Todoist_logo.png",
                            description: i18next.t("page.private.integrations.description.todoList"),
                            title: i18next.t("page.private.integrations.googleworkspace.todoList"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://upload.wikimedia.org/wikipedia/commons/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg",
                            description: i18next.t("page.private.integrations.description.OutlookCalendar"),
                            title: i18next.t("page.private.integrations.googleworkspace.OutlookCalendar"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://hotelkit.net/de/wp-content/uploads/sites/2/2020/02/hotelkit-logo-rgb.svg",
                            description: i18next.t("page.private.integrations.description.hotelKit"),
                            title: i18next.t("page.private.integrations.googleworkspace.hotelKit"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Slack_Technologies_Logo.svg/1280px-Slack_Technologies_Logo.svg.png",
                            description: i18next.t("page.private.integrations.description.slackChatbot"),
                            title: i18next.t("page.private.integrations.googleworkspace.slackChatbot"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://www.inneo.de/files/content/Landingpages/home-office-corona/xlogo-microsoft-teams.png.pagespeed.ic.1HOGgWk-4P.png",
                            description: i18next.t("page.private.integrations.description.microsoftTeams"),
                            title: i18next.t("page.private.integrations.googleworkspace.microsoftTeams"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Beuth_Verlag_Logo.svg/2000px-Beuth_Verlag_Logo.svg.png",
                            description: i18next.t("page.private.integrations.description.beuth"),
                            title: i18next.t("page.private.integrations.googleworkspace.beuth"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://www.getmyinvoices.com/wp-content/uploads/2020/03/logo.png",
                            description: i18next.t("page.private.integrations.description.getMyInvoices"),
                            title: i18next.t("page.private.integrations.googleworkspace.getMyInvoices"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://camo.githubusercontent.com/a074f0beacc94a224c9179d5a453a102375549f458e6872d62e827169776bb7c/68747470733a2f2f7261772e6769746875622e636f6d2f6d61696c67756e2f6d656469612f6d61737465722f4d61696c67756e5f5072696d6172792e706e67",
                            title: i18next.t("page.private.integrations.googleworkspace.mailGun"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Twilio-logo-red.svg",
                            title: i18next.t("page.private.integrations.googleworkspace.twilio"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://cdn.worldvectorlogo.com/logos/microsoft-hololens.svg",
                            title: i18next.t("page.private.integrations.googleworkspace.holoLens"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://images.squarespace-cdn.com/content/v1/5a900e4f5ffd203a470a5da7/1550071957856-7T3OX5T86E7H6UVXUJH6/ke17ZwdGBToddI8pDm48kKptNKD-zFUQOBLWubZAQzBZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZamWLI2zvYWH8K3-s_4yszcp2ryTI0HqTOaaUohrI8PIdn3mJRFRpe69PJ-CU2YVyfEmxVBCmv9BfV7yu1BG6XY/csm_Logo-Loxone-green-RGB-WEB_8d4c9f4b11.png",
                            title: i18next.t("page.private.integrations.googleworkspace.Loxone"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://devcn.djicdn.com/img/7571dc9.svg",
                            title: i18next.t("page.private.integrations.googleworkspace.droneRecording"),
                            soon: true
                        }} />
                        <Integration data={{
                            icon: "https://matterport.com/themes/custom/matterport/images/logo.svg",
                            title: i18next.t("page.private.integrations.googleworkspace.3Drecording"),
                            soon: true
                        }} />
                    </Integrations>
                </Section.Item>
                {/*<Section.Item disabled title="Sonderintegrationen" icon="brackets-curly">

                </Section.Item>
                <Section.Item disabled title="Schnittstellendokumentation" icon="laptop-code">

                </Section.Item>*/}
            </Section>
        </Page>
    }
}