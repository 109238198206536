import styled from "styled-components";

export default styled.div`
  transition: all 0.5s;
  filter: ${props => props.blur ? "blur(6px)" : "none"};
  opacity: ${props => props.blur ? 0.4 : 1};
  pointer-events: ${props => props.disable ? "none !important" : "all"};
  user-select: ${props => props.disable ? "none !important" : "auto"};
  
  .microsoft {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 128px;
    
    img {
        cursor: pointer;
        transition: opacity 0.3s;
        height: 48px;
        
        &:hover {
            opacity: 0.75;
        }
    }
  }
  
  .avatar {
    border-radius: 100%;
    margin: 0 auto;
    display: block !important;
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.15);
  }

  .passwordReset {
    font-size: 0.9em;
    cursor: pointer;
    transition: opacity 0.3s;
    color: #3B97D3;
    user-select: none;
    white-space: nowrap;
    text-align: right;
    width: 100%;
    margin-top: 16px;
    display: block;
    margin-bottom: -16px;

    &:hover {
      opacity: 0.5;
    }
  }
`
