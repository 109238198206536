import React, { Component } from "react"
import { Modal, Button, Select, Section, Checkbox, Input } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { selectedIndex: 0, subsidiaryId: null, subsidiaries: [], selected: [], search: null, loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                streetName
                                streetNumber
                                additional
                                postalCode
                                city
                                country
                                inline
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ subsidiaries: data.data.Subsidiaries.nodes.filter(s => !this.props.subsidiaryIds.includes(s.id)) })
        })
    }

    render() {
        const searchFilter = subsidiary => {
            if(!this.state.search) return true

            const search = this.state.search.trim().toLowerCase()
            if(search.length === 0) return true

            const searchString = `${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""} / ${subsidiary.address.inline}`.toLowerCase().trim()

            return searchString.includes(search)
        }

        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ selectedIndex: 0, subsidiaryId: null, loading: false, search: null, selected: [] })}>
            <Modal.Head title={i18next.t("page.private.contract.modals.createSubsidiaryContractModal.title")} icon="map-marker" />
            <Modal.Body padding={24} adjustHeight={400}>
                <Section selected={this.state.selectedIndex} onSelected={index => this.setState({ selectedIndex: index })}>
                    <Section.Item disabled={this.state.loading && this.state.selectedIndex !== 0} icon="map-marker" title={i18next.t("page.private.contract.modals.createSubsidiaryContractModal.linkIndividually")}>
                        <div style={{ marginTop: 16 }}>
                            <Select adjustWidth label={i18next.t("page.private.contract.modals.createSubsidiaryContractModal.branch")} icon='map-marker' value={this.state.subsidiaryId} onChange={subsidiaryId => this.setState({ subsidiaryId })} items={this.state.subsidiaries.map(subsidiary => ({
                                key: subsidiary.id,
                                title: `${subsidiary.name}${subsidiary.label ? (" / " + subsidiary.label) : ""} / ${subsidiary.address.streetName} ${subsidiary.address.streetNumber}, ${subsidiary.address.postalCode} ${subsidiary.address.city}`
                            }))} />
                        </div>
                    </Section.Item>
                    <Section.Item disabled={this.state.loading && this.state.selectedIndex !== 1} icon="list" title={i18next.t("page.private.contract.modals.createSubsidiaryContractModal.linkMore")}>
                        <div style={{ display: "grid", gridTemplateColumns: "min-content min-content", gridGap: 16, marginTop: 16, marginBottom: 16 }}>
                            <Button thick disabled={this.state.subsidiaries.filter(searchFilter).length === 0 || this.state.loading} title={i18next.t("page.private.contract.modals.createSubsidiaryContractModal.selectAllVisible") + this.state.subsidiaries.filter(searchFilter).length + ")"} icon="check-double" onClick={() => {
                                let subs = this.state.subsidiaries.filter(searchFilter)

                                let selected = this.state.selected
                                selected.push(...subs.map(s => s.id))

                                this.setState({ selected })
                            }} />
                            <Button thick disabled={this.state.subsidiaries.filter(searchFilter).length === 0 || this.state.loading} title={i18next.t("page.private.contract.modals.createSubsidiaryContractModal.deselectAllVisible") + this.state.subsidiaries.filter(searchFilter).length + ")"} secondary icon="times" onClick={() => {
                                let subIds = this.state.subsidiaries.filter(searchFilter).map(s => s.id)

                                let selected = this.state.selected.filter(id => !subIds.includes(id))

                                this.setState({ selected })
                            }} />
                        </div>
                        <div style={{ marginBottom: 16, opacity: 0.75 }}>
                            {this.state.selected.length} / {this.state.subsidiaries.length} {i18next.t("page.private.contract.modals.createSubsidiaryContractModal.selected")}
                        </div>
                        <Input icon="search" label={i18next.t("page.private.contract.modals.createSubsidiaryContractModal.searchPlaceholder")} adjustWidth value={this.state.search} onChange={search => this.setState({ search })} />
                        <div style={{ height: 400, overflowY: "scroll", display: "flex", flexDirection: "column", marginTop: 16 }}>
                            {this.state.subsidiaries.filter(searchFilter).map(subsidiary => <div key={subsidiary.id} style={{ marginTop: 16 }}>
                                <Checkbox label={`${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""}`} value={this.state.selected.includes(subsidiary.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selected
                                        selected.push(subsidiary.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selected })
                                    } else {
                                        let selected = this.state.selected.filter(id => id !== subsidiary.id)
                                        this.setState({ selected })
                                    }
                                }} />
                            </div>)}
                        </div>
                    </Section.Item>
                </Section>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.contract.modals.createSubsidiaryContractModal.exitButton")} disabled={(!this.state.subsidiaryId && this.state.selectedIndex === 0) || (this.state.selectedIndex === 1 && this.state.selected.length === 0)} icon="map-marker" onClick={() => {
                this.setState({ loading: true })

                if(this.state.selectedIndex === 0) {
                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($subsidiaryId: ID!, $contractId: ID!) {
                            createSubsidiaryContract(subsidiaryId: $subsidiaryId, contractId: $contractId)
                        }
                    `,
                        variables: {
                            contractId: this.props.contractId,
                            subsidiaryId: this.state.subsidiaryId
                        }
                    }).then(result => {
                        this.setState({ loading: false })

                        if(result.data.createSubsidiaryContract) {
                            this.props.refresh()
                            instance.close()
                        }
                    })
                } else if(this.state.selectedIndex === 1) {
                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($subsidiaryIds: [ID!], $contractId: ID!) {
                            createSubsidiaryContracts(subsidiaryIds: $subsidiaryIds, contractId: $contractId)
                        }
                    `,
                        variables: {
                            contractId: this.props.contractId,
                            subsidiaryIds: this.state.selected
                        }
                    }).then(result => {
                        this.setState({ loading: false })

                        if(result.data.createSubsidiaryContracts) {
                            this.props.refresh()
                            instance.close()
                        }
                    })
                }
            }} />} />
        </Modal>
    }
}
