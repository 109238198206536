import React, { Component } from "react"
import { Modal } from "scanmetrix-components"
import {FileManager} from "../../documents";
import i18next from "i18next";

export default class extends Component {
    state = { contractProvisionProofId: null, contractPath: null }

    render() {
        return <Modal minWidth="1024px" instance={this.props.instance} initialize={contractProvisionProofId => {
            this.setState({ contractProvisionProofId, contractPath: null })
            console.log(contractProvisionProofId)
        }}>
            <Modal.Head title={i18next.t("page.private.contract.modals.openContractProvisionProofDocumentsModal.title")} icon="folder-tree" />
            <Modal.Body>
                <FileManager contractProvisionProofId={this.state.contractProvisionProofId} title={i18next.t("page.private.contract.modals.openContractProvisionProofDocumentsModal.performanceDocuments")} icon="file-certificate" scope="contractProvisionProof" onPathChange={contractPath => this.setState({ contractPath })} />
            </Modal.Body>
        </Modal>
    }
}
