import React, { Component } from 'react'
import { Modal, Input, Grid, GridElement, Checkbox } from 'scanmetrix-components'

export default class extends Component {
    state = { driver: null }

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={driver => this.setState({ driver })}>
            <Modal.Head title="Fahrer aktualisieren" icon="user-tie" />
            <Modal.Body padding={24}>
                {this.state.driver && <Input.MutationProvider value={{
                    name: "updateCarDriver",
                    id: this.state.driver.id,
                    data: this.state.driver,
                    onSave: () => this.props.refresh()
                }}>
                    <Modal.Divider title="Person" />
                    <Grid columns={[ "1fr", "1fr" ]} gap="16px">
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input required label='Vorname' icon='font' adjustWidth field="firstName" />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input required label='Nachname' icon='font' adjustWidth field="lastName" />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input label='Telefonnummer' icon='phone' adjustWidth field="telephone" />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input label='E-Mail Adresse' icon='envelope-open' adjustWidth field="email" />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input label='Geburtsdatum' icon='birthday-cake' date adjustWidth field="birthday" />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input label='Wohnsitz' icon='map-marker' adjustWidth field="residency" />
                        </GridElement>
                    </Grid>
                    <Modal.Divider title="Führerscheinangaben" marginTop="32px" />
                    <Grid columns={[ "1fr", "1fr" ]} gap="16px">
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input label='Ausgestellt am' icon='calendar-check' date adjustWidth field="driversLicenseSince" />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input label='Gültig bis' icon='calendar-times' date adjustWidth field="driversLicenseValidUntil" />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input label='Führerscheinnummer' icon='list-ol' adjustWidth field="driversLicenseId" />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input label='Erlaubte Fahrzeugklassen' icon='cars' adjustWidth field="driversLicenseTypes" />
                        </GridElement>
                    </Grid>
                    <Modal.Divider title="Fahrereigenschaft" marginTop="32px" />
                    <Grid columns={[ "1fr", "1fr", "1fr" ]} gap="16px">
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input label='Fahrer ab' icon='calendar-check' date adjustWidth field="since" />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input label='Fahrer bis' icon='calendar-check' date adjustWidth field="until" />
                        </GridElement>
                        <GridElement columnStart={3} columnEnd={4} height="100%">
                            <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                                <Checkbox label='Private Nutzung erlaubt' field="privateUseAllowed" />
                            </div>
                        </GridElement>
                    </Grid>
                </Input.MutationProvider>}
            </Modal.Body>
        </Modal>
    }
}