import React, { Component } from "react"
import { Modal, Button, Table } from "scanmetrix-components"

export default class extends Component {
    state = { contractId: null, loading: false, subsidiaries: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    SubsidiaryContracts(filter: { contractId_eq: $id }) {
                        nodes {
                            id
                            subsidiary {
                                id
                                label
                                name
                                legalName
                                phase {
                                    id
                                    name
                                    color
                                }
                                subsidiaryType {
                                    id
                                    name
                                    color
                                }
                                address {
                                    postalCode
                                    city
                                    streetName
                                    streetNumber
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.state.contractId
            }
        }).then(data => {
            this.setState({ subsidiaries: data.data.SubsidiaryContracts.nodes.map(n => n.subsidiary) })
        })
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={contractId => {
            this.setState({ contractId, loading: false }, this.fetch)
        }}>
            <Modal.Head title="Niederlassungen im Vertrag" icon="house" />
            <Modal.Body padding={24}>
                <Table
                    head={[
                        { title: "Name", width: "15%", column: "name" },
                        { title: "Kennz.", width: "10%", column: "label" },
                        { title: "Rechtliche Bezeichnung", width: "17.5%", column: "legalName" },
                        { title: "Straße", width: "20%", column: "street" },
                        { title: "PLZ", width: "7.5%", column: "postalCode" },
                        { title: "Stadt", width: "10%", column: "city" },
                        { title: "Art", width: "10%", column: "type" },
                        { title: "Status", width: "10%", column: "phase" }
                    ]}
                    onItemClick={subsidiary => this.props.history.push(`/subsidiary/${subsidiary.id}`)}
                    additionalSearchIndexes={[ "rawName" ]}
                    items={this.state.subsidiaries ? this.state.subsidiaries.map(subsidiary => {
                        return ({
                            id: subsidiary.id,
                            label: subsidiary.label,
                            rawName: subsidiary.name,
                            name: subsidiary.name,
                            legalName: subsidiary.legalName,
                            street: `${subsidiary.address.streetName} ${subsidiary.address.streetNumber}`,
                            postalCode: subsidiary.address.postalCode,
                            city: subsidiary.address.city,
                            type: subsidiary.subsidiaryType ? <b style={{ color: subsidiary.subsidiaryType.color }}>{subsidiary.subsidiaryType.name}</b> : null,
                            phase: subsidiary.phase ? <b style={{ color: subsidiary.phase.color }}>{subsidiary.phase.name}</b> : null,
                            phaseId: subsidiary.phase ? subsidiary.phase.id : null
                        })
                    }) : null}
                    contextItems={[ 
                        { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: subsidiary => window.open(`/subsidiary/${subsidiary.id}`, '_blank')}
                    ]}
                />
            </Modal.Body>
        </Modal>
    }
}
