import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { year: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ year: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.createUtilitiesStatementEffluentModal.title")} icon="tint-slash" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Input required value={this.state.year} onChange={year => this.setState({ year })} icon="calendar" int label={i18next.t("page.private.subsidiary.styled.createUtilitiesStatementEffluentModal.year")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.year} title={i18next.t("page.private.subsidiary.styled.createUtilitiesStatementEffluentModal.exitButton")} icon="tint-slash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($year: Int!, $subsidiaryId: ID!, $type: String!) {
                            createUtilitiesStatement(year: $year, subsidiaryId: $subsidiaryId, type: $type)
                        }
                    `,
                    variables: {
                        year: this.state.year,
                        subsidiaryId: this.props.subsidiary.id,
                        type: "effluent"
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createUtilitiesStatement) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
