import qs from "qs"

export default function() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    if(params["password-reset"] && params.id) {
        this.props.history.push("/login")

        const passwordResetToken = params["password-reset"]
        const userId = params.id

        this.passwordResetToken = passwordResetToken
        this.userId = userId

        this.setState({ mode: "passwordResetVerification" })
    }
}
