import styled from "styled-components"

export default styled.div`
  display: flex;
  align-items: center;
  margin-right: 48px;
  user-select: none;
  cursor: pointer;
  
  &:last-child {
    margin-right: 0;
  }
    
    &:hover {
        .avatar {
          opacity: 0.75;
        }
    }
  
  .avatar {
    position: relative;
    transition: opacity 0.3s;
      border-radius: 100%;
    
    .sb-avatar {
      box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.25);
    }
    
    img {
      -webkit-user-drag: none;
      height: 48px;
      width: 48px;
      object-fit: cover;
      border-radius: 100%;
    }
    
    .status {
      position: absolute;
      z-index: 2;
      width: 10px;
      height: 10px;
      bottom: 2px;
      right: 2px;
      border: 2px solid white;
      border-radius: 100%;
      
      &.green {
        background: #1abc9c;
      }
      
      &.yellow {
        background: #f1c40f;
      }
      
      &.red {
        background: #e74c3c;
      }
    }
  }
  
  .right {
    padding-left: 16px;
    
    .name {
      font-size: 1.2em;
      max-height: 2.4em;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
    }
    
    .status {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }
`
