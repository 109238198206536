import React, {Component} from "react"
import styled from "styled-components"
import { GridElement, Grid, Button, Select, Input } from "scanmetrix-components"
import i18next from "i18next";

const StyledExport = styled.div`
    width: 100%;
    padding: 16px;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 16px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 16px;
    user-select: none;
    cursor: ${props => props.enabled ? "pointer" : "default"};
    border: ${props => props.selected ? "1px solid #3b97d3" : "1px solid rgba(0, 0, 0, 0.1)"};
    background: ${props => props.selected ? "#3b97d30d" : "transparent"};
    transition: border-color 0.3s, background 0.3s;
    position: relative;
  
    .notAvailable {
        right: 0;
        top: 0;
        height: 20px;
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 0 8px;
        background: #ebebeb;
        position: absolute;
        border-radius: 0 5px 0 5px;
    }
  
    &:last-child {
        margin-bottom: 0;
    }
  
    i {
        width: 40px;
        height: 40px;
        color: ${props => props.selected ? "white" : "#3b97d3"};
        border: 1px solid #3b97d3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: ${props => props.selected ? "#3b97d3" : "transparent"};
        transition: background 0.3s, color 0.3s;
    }
  
    .right {
        display: flex;
        flex-direction: column;
      
        .title {
            font-weight: bold;
        }
      
        .description {
            margin-top: 4px;
            font-size: 14px;
            line-height: 20px;
        }
    }
`

class Export extends Component {
    render() {
        return <StyledExport selected={this.props.selected} enabled={this.props.enabled} onClick={this.props.onClick}>
            <div className="left">
                <i className={`fa-duotone fa-${this.props.icon}`} />
            </div>
            <div className="right">
                <p className="title">{this.props.title}</p>
                <p className="description">{this.props.description}</p>
            </div>
            {!this.props.enabled && <div className="notAvailable">{i18next.t("page.private.settings.ExportsFolder.notAvailable")}</div>}
        </StyledExport>
    }
}

const StyledExports = styled.div`
    width: 100%;
    height: 768px;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 16px;
`

export default class extends Component {
    state = { exports: [], subsidiaries: [], selected: null }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                inline
                                postalCode
                                city
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({
                subsidiaries: data.data.Subsidiaries.nodes,
            })
        })

        fetch(scanmetrix.backendURL + "/exports/meta", {
            method: "GET",
            credentials: "include"
        }).then(result => result.json()).then(({ exports }) => {
            console.log(exports)
            this.setState({ exports })
        })
    }

    render() {
        return <Grid columns={[ "1fr", "3fr" ]} gap="32px">
            <GridElement styled title={i18next.t("page.private.settings.ExportsFolder.exportList")} icon="file-export">
                <StyledExports>
                    {this.state.exports.map(exportClass => <Export onClick={() => {
                        if(exportClass.enabled) {
                            this.query = {}
                            this.setState({ selected: this.state.selected === exportClass.id ? null : exportClass.id })
                        }
                    }} selected={this.state.selected === exportClass.id} key={exportClass.id} title={exportClass.title} icon={exportClass.icon} description={exportClass.description} enabled={exportClass.enabled} />)}
                </StyledExports>
            </GridElement>
            <GridElement styled title={i18next.t("page.private.settings.ExportsFolder.exportFeatures")} icon="cog" rightContent={<Button disabled={this.state.selected === null} thick primary title={i18next.t("page.private.settings.ExportsFolder.exportDownloadButton")} icon="download" onClick={() => {
                const url = `${scanmetrix.backendURL}/exports/${this.state.selected}/download?${new URLSearchParams(this.query).toString()}`
                console.log(url)
                window.open(url, "_blank")
            }} />}>
                {this.state.selected === null && <StyledPlaceholder>
                    <img src="/export.svg" />
                    <h2>{i18next.t("page.private.settings.ExportsFolder.noExportDescription")}</h2>
                    <h3>{i18next.t("page.private.settings.ExportsFolder.configurationDescription")}</h3>
                </StyledPlaceholder>}
                {this.state.selected !== null && this.state.exports.find(ex => ex.id === this.state.selected)?.params.length === 0 && <StyledPlaceholder>
                    <img src="/settings.svg" />
                    <h2>{i18next.t("page.private.settings.ExportsFolder.noConfigurationDescription")}</h2>
                    <h3>{i18next.t("page.private.settings.ExportsFolder.exportButtonDescription")}</h3>
                </StyledPlaceholder>}
                {this.state.selected !== null && this.state.exports.find(ex => ex.id === this.state.selected)?.params.length > 0 && <StyledExportSettings>
                    {this.state.exports.find(ex => ex.id === this.state.selected)?.params.map(param => {
                        if(param.type === "subsidiary") return <Select onChange={value => {
                            if(!this.query) this.query = {}
                            this.query[param.key] = value
                        }} adjustWidth label={i18next.t("page.private.settings.ExportsFolder.branch")} icon="map-marker" items={this.state.subsidiaries.map(subsidiary => ({
                            key: subsidiary.id,
                            title: `${subsidiary.name}${subsidiary.label ? (" / " + subsidiary.label) : ""} / ${subsidiary.address.inline}`
                        }))} />

                        if(param.type === "date") return <Input date onChange={value => {
                            if(!this.query) this.query = {}
                            this.query[param.key] = value
                        }} adjustWidth label={param.label || i18next.t("page.private.settings.ExportsFolder.date")} icon="calendar" />

                        return null
                    })}
                </StyledExportSettings>}
            </GridElement>
        </Grid>
    }
}

const StyledExportSettings = styled.div`
    padding: 24px;
    display: grid;
    grid-gap: 16px;
    grid-auto-flow: row;
`

const StyledPlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 512px;
    flex-direction: column;
    text-align: center;
    user-select: none;
  
    img {
        height: 128px;
    }
  
    h2 {
        margin-top: 32px;
    }
  
    h3 {
        width: 50%;
        margin-top: 16px;
        font-weight: 400;
        line-height: 30px;
    }
`
