export default function () {
    this.setState({ loading: true })

    fetch(scanmetrix.backendURL + "/user/registration", {
        method: "POST",
        body: JSON.stringify({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            salutation: this.state.salutation,
            email: this.state.email,
            password: this.state.password,
            passwordRepeat: this.state.passwordRepeat
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(result => result.json()).then(result => {
        const status = result.status

        if(status === "REGISTRATION_SUCCESSFUL") {
            this.setState({ loading: false, blurMode: "registration-successful", firstName: null, lastName: null, password: null, email: null, passwordRepeat: null, salutation: null, accepted: false })
        } else if(status === "ERROR") {
            this.setState({ loading: false, blurMode: "error", error: result.error })
        }
    })
}
