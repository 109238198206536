import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"
import i18next from "i18next";


export default class extends Component {
    state = { title: null, description: null, date: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ title: null, description: null, date: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.createCaseModal.titleForm")} icon="history" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Input required value={this.state.title} onChange={title => this.setState({ title })} icon="tag" label={i18next.t("page.private.subsidiary.styled.createCaseModal.title")} adjustWidth />
                    <Input required value={this.state.date} date onChange={date => this.setState({ date })} icon="calendar-day" label={i18next.t("page.private.subsidiary.styled.createCaseModal.date")} adjustWidth />
                    <Input textArea required value={this.state.description} onChange={description => this.setState({ description })} icon="font" label={i18next.t("page.private.subsidiary.styled.createCaseModal.caseDescription")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.title || !this.state.date || !this.state.description} title={i18next.t("page.private.subsidiary.styled.createCaseModal.exitButton")} icon="history" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($title: String!, $description: String!, $date: DateTime!, $subsidiaryId: ID!) {
                            createCase(title: $title, description: $description, date: $date, subsidiaryId: $subsidiaryId)
                        }
                    `,
                    variables: {
                        title: this.state.title,
                        description: this.state.description,
                        date: moment(this.state.date, "DD.MM.YYYY").toDate(),
                        subsidiaryId: this.props.subsidiaryId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createCase) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
