export default function() {
    this.setState({ loading: true })

    fetch(scanmetrix.backendURL + "/user/password-reset-challenge", {
        method: "POST",
        body: JSON.stringify({
            email: this.state.email
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(result => result.json()).then(result => {
        const status = result.status

        if(status === "ERROR") {
            this.setState({ loading: false, blurMode: "error", error: result.error })
        } else if(status === "CHALLENGE_SUCCESSFUL") {
            this.setState({ loading: false, email: null, password: null, blurMode: "password-reset-challenge" })
        }
    })
}
