import React, { Component } from "react"
import { Page, Breadcrumbs, Table, GridElement, Button } from "scanmetrix-components"
import CreateSpaceModal from "./modals/CreateSpaceModal"
import i18next from "i18next"

export default class extends Component {
    state = { spaces: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Spaces {
                        nodes {
                            id
                            name
                            type
                            rooms {
                                id
                            }
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    postalCode
                                    city
                                }
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ spaces: data.data.Spaces.nodes })
        })
    }

    render() {
        return <Page {...this.props}>
            <CreateSpaceModal refresh={() => this.fetch()} instance={ref => this.createSpaceModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "chair-office",
                    title: i18next.t("page.private.spaceManagement.breadCrumbsTitle")
                }
            ]} />
            <GridElement styled title={i18next.t("page.private.spaceManagement.gridElementTitle")} icon="chair-office" rightContent={<Button thick icon="chair-office" title={i18next.t("page.private.spaceManagement.deleteButton")} onClick={() => this.createSpaceModal.open()} />}>
                <Table
                    head={[
                        { title: i18next.t("page.private.spaceManagement.table.name"), width: "25%", column: "name" },
                        { title: i18next.t("page.private.spaceManagement.table.types"), width: "25%", column: "type" },
                        { title: i18next.t("page.private.spaceManagement.table.branch"), width: "40%", column: "subsidiary" },
                        { title: i18next.t("page.private.spaceManagement.table.rooms"), width: "10%", column: "rooms" }
                    ]}
                    onItemClick={space => window.open(`/space/${space.id}`, '_blank')}
                    items={this.state.spaces ? this.state.spaces.map(space => ({
                        ...space,
                        subsidiary: `${space.subsidiary.name} (${space.subsidiary.address.postalCode} ${space.subsidiary.address.city}, ${space.subsidiary.label || space.subsidiary.id})`,
                        rooms: `${space.rooms.length} ${i18next.t("page.private.spaceManagement.pieces")}`
                    })) : null}
                />
            </GridElement>
        </Page>
    }
}
