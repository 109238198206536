import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"

import {Checkbox} from "../../../../../components";
import i18next from "i18next";

export default class extends Component {
    state = { id: null, name: null, position: null, number: null, qrcode: null, factor: null, type: null, deliveryBegin: null, maloId: null, meloId: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={meter => this.setState({ id: meter.id, active: meter.active, qrcode: meter.qrcode, name: meter.name, type: meter.type, position: meter.position, number: meter.number, meloId: meter.meloId, maloId: meter.maloId, factor: meter.factor, deliveryBegin: meter.deliveryBegin ? moment(meter.deliveryBegin).format("DD.MM.YYYY") : null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.updateMeterModal.title")} icon="pencil" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Checkbox label={i18next.t("page.private.subsidiary.styled.updateMeterModal.active")} value={this.state.active} onChange={active => this.setState({ active })} />
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.subsidiary.styled.updateMeterModal.name")} adjustWidth />
                    <Input date value={this.state.deliveryBegin} adjustWidth onChange={deliveryBegin => this.setState({ deliveryBegin })} icon="hourglass-start" label={i18next.t("page.private.subsidiary.styled.updateMeterModal.deliveryStart")} />
                    <Select required adjustWidth value={this.state.type} onChange={type => this.setState({ type })} icon="tachometer" label={i18next.t("page.private.subsidiary.styled.createMeterModal.counterUnit")} items={[
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.electricity"), key: "electricity", icon: "plug" },
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.water"), key: "water", icon: "water" },
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.gas"), key: "gas", icon: "burn" },
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.teleheating"), key: "teleheating", icon: "heat" },
                        { title: i18next.t("page.private.subsidiary.styled.createMeterModal.counterTypes.cold"), key: "cold", icon: "snowflake" }
                    ]} />
                    <Input required value={this.state.position} onChange={position => this.setState({ position })} icon="map-marker" label={i18next.t("page.private.subsidiary.styled.updateMeterModal.position")} adjustWidth />
                    <Input value={this.state.number} onChange={number => this.setState({ number })} icon="sort-numeric-up-alt" label={i18next.t("page.private.subsidiary.styled.updateMeterModal.counterNumber")} adjustWidth />
                    <Input value={this.state.maloId} onChange={maloId => this.setState({ maloId })} icon="sort-numeric-up-alt" label={i18next.t("page.private.subsidiary.styled.updateMeterModal.marketLocationNumber")} adjustWidth />
                    <Input value={this.state.meloId} onChange={meloId => this.setState({ meloId })} icon="sort-numeric-up-alt" label={i18next.t("page.private.subsidiary.styled.updateMeterModal.measurementLocationNumber")} adjustWidth />
                    <Input value={this.state.qrcode} onChange={qrcode => this.setState({ qrcode })} icon="qrcode" label="QR-Code" adjustWidth />
                    <Input float value={this.state.factor} onChange={factor => this.setState({ factor })} icon="wave-square" label={i18next.t("page.private.subsidiary.styled.updateMeterModal.factor")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.position || !this.state.type} title={i18next.t("page.private.subsidiary.styled.updateMeterModal.exitButton")} icon="pencil" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $qrcode: String, $active: Boolean!, $name: String!, $position: String!, $number: String, $factor: Float, $type: String!, $deliveryBegin: DateTime, $meloId: String, $maloId: String) {
                            updateMeter(id: $id, qrcode: $qrcode, active: $active, name: $name, position: $position, number: $number, factor: $factor, type: $type, deliveryBegin: $deliveryBegin, meloId: $meloId, maloId: $maloId)
                        }
                    `,
                    variables: {
                        id: this.state.id,
                        name: this.state.name,
                        position: this.state.position,
                        type: this.state.type,
                        number: this.state.number,
                        factor: this.state.factor,
                        qrcode: this.state.qrcode,
                        maloId: this.state.maloId,
                        active: this.state.active,
                        meloId: this.state.meloId,
                        deliveryBegin: this.state.deliveryBegin ? moment(this.state.deliveryBegin, "DD.MM.YYYY").toDate() : null
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.updateMeter) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
