import React, { Component } from "react"
import { Section, Button, GridElement, Modal } from "scanmetrix-components"
import styled from "styled-components"
import CreateCaseModal from "./modals/CreateCaseModal"
import i18next from "i18next";

const HistoryBody = styled.div`
  width: 100%;
  height: 100%;
  
  .noCases {
      display: flex;
      flex-direction: column;
      align-items: center;
      user-select: none;
      padding: 64px 0;
      
      h2 {
          font-weight: normal;
      }
      
      img {
          -webkit-user-drag: none;
          width: 250px;
          margin-top: 32px;
          opacity: 0.75;
          filter: grayscale(100%);
      }
  }
  
  .delete {
      width: 20px; 
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(30deg, #0F7ABF, #58B9F9);
    border-radius: 5px;
    padding: 12px;
    box-sizing: border-box;
    transition: all 250ms ease;
    cursor: pointer;
    
    &:hover {
      opacity: 0.5;
    }
    
    i {
      color: white;
      font-size: 0.85em;
    }
  }
  
  .inner-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    
    .top-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      padding: 16px;
      box-sizing: border-box;
      position: relative;
      
      i {
        margin-right: 8px;
        font-weight: 400;
      }
    }
    
    .data-body {
      width: 100%;
      height: 100%;
      max-height: 768px;
      padding: 0 16px 16px 16px;
      display: flex;
      flex-direction: column;
      flex: 1;
      background: white;
      border-radius: 0 0 5px 5px;
      box-sizing: border-box;
      overflow-y: scroll;
      
      @media screen and (max-width: 1600px) {
        zoom: 0.8;
      }
      
      &::-webkit-scrollbar {
        width: 5px;
      }
      
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 125px;
      }
      
      h2 {
        color: #20242B;
        margin: 32px 0;
      }
      
      .item-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: -1px;
        position: relative;
        
        .data-inner {
          width: 100%;
          padding: 10px 16px 10px 0;
          display: flex;
          flex-direction: column;
          flex: 1;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(0, 0, 0, .125);
          overflow: hidden;
          
          .title {
            font-weight: 600;
            font-size: 1.35em;
            color: #20242B;
            user-select: none;
          }
          
          .description {
            font-size: 1.125em;
            color: #20242B;
            margin-top: 10px;
            user-select: none;
          }
          
          .date {
            font-size: 0.9em;
            color: #20242B;
            margin-top: 5px;
            user-select: none;
          }
        }
        
        .tree {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 100%;
          margin-right: 16px;
          
          &::before {
            content: '';
            width: 3px;
            height: 100%;
            background: rgba(0, 0, 0, .125);
            display: block;
            position: absolute;
            top: 0;
          }
          
          &::after {
            content: '';
            width: 50%;
            height: 3px;
            margin-left: auto;
            background: rgba(0, 0, 0, .125);
            display: block;
          }
          
          .circle {
            width: 10px;
            height: 10px;
            border-radius: 125px;
            border: 3px solid #3B97D3;
            background: white;
            position: absolute;
            z-index: 5;
          }
        }
        
        &:last-child {
          .data-inner {
            border-bottom: none;
          }
        
          .tree::before {
            height: 50%;
          }
        }
      }
    }
  }
`

const FilesBoxInner = styled.div`
    margin: 20px 0 10px 0;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .125);
    background: rgba(0, 0, 0, .0325);
    box-sizing: border-box;
    overflow-x: scroll;
    
    &::-webkit-scrollbar {
      height: 10px;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 125px;
      background: rgba(0, 0, 0, .125);
    }
    
    .file {
      width: 125px;
      height: 100px;
      padding-right: 15px;
      display: flex;
      flex-direction: column;
      
      .figure {
        width: 60px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: white;
        clip-path: polygon(75% 0, 100% 25%, 100% 100%, 0 100%, 0 0);
        box-shadow: 0 0 5px rgba(0, 0, 0, .25);
        box-sizing: border-box;
        cursor: pointer;
        transition: all 250ms ease;
        
        &:hover {
          opacity: 0.75;
        }
        
        i {
          font-size: 2em;
          color: rgba(0, 0, 0, .25);
          user-select: none;
        }
      }
      
      .file-text {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        user-select: none;
      
        .file-name {
          color: #20242B;
          max-width: 100px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          
          b {
            font-weight: inherit;
          }
        }
        
        .file-ending {
          color: #20242B;
        }
      }
    }
`

class FilesBox extends Component {
    render() {
        return <FilesBoxInner>
            {this.props.files.map((file, index) => <div className='file' key={index}>
                <div className='figure'>
                    <i className='fas fa-file-pdf' />
                </div>
                <div className='file-text'>
                    <p className='file-name'>{file.name.split(".")[0]}</p>
                    <p className='file-ending'>.{file.name.split(".")[1]}</p>
                </div>
            </div>)}
        </FilesBoxInner>
    }
}


class DeleteCaseModal extends Component {
    state = { id: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={item => this.setState({ id: item.id, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.cases.title")} icon="trash" />
            <Modal.Body padding={24}>
                {i18next.t("page.private.subsidiary.styled.cases.description")}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.subsidiary.styled.cases.deleteButton")} icon="trash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            deleteCase(id: $id)
                        }
                    `,
                    variables: {
                        id: this.state.id
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.deleteCase) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}

export default class extends Component {
    state = { cases: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    Cases(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            title
                            description
                            date
                            attachments {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.subsidiary.id
            }
        }).then(data => {
            const cases = data.data.Cases.nodes.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())

            this.setState({ cases })
        })
    }

    render() {
        return <Section.Item title={i18next.t("page.private.subsidiary.styled.cases.sectionElementTitle")} icon="history" amount={this.state.cases.length} {...this.props}>
            <DeleteCaseModal refresh={() => this.fetch()} instance={ref => this.deleteCaseModal = ref} />
            <CreateCaseModal refresh={() => this.fetch()} instance={ref => this.createCaseModal = ref} subsidiaryId={this.props.subsidiary.id} />
            <GridElement styled title={i18next.t("page.private.subsidiary.styled.cases.gridElementTitle")} icon="history" rightContent={<Button icon="history" thick title={i18next.t("page.private.subsidiary.styled.cases.createCaseButton")} disabled={scanmetrix.checkPermission("Case") < 2} onClick={() => this.createCaseModal.open()} />}>
                <HistoryBody>
                    <div className="inner-body">
                        {this.state.cases.length > 0 && <div className='data-body'>
                            {this.state.cases.map(( item, index ) => {
                                return <div className='item-row' key={index}>
                                    <div className='tree'>
                                        <div className='circle' />
                                    </div>
                                    <div className='data-inner'>
                                        <h3 className='title'>{item.title}</h3>
                                        <p className='description'>{item.description}</p>
                                        <p className='date'>{moment(item.date).format("DD.MM.YYYY")}</p>

                                        {!!item.attachments.length && <FilesBox files={item.attachments} />}
                                    </div>
                                    {scanmetrix.checkPermission("Case") >= 4 && <div className="delete" onClick={() => this.deleteCaseModal.open(item)}>
                                        <i className="far fa-trash" />
                                    </div>}
                                </div>
                            })}
                        </div>}
                        {this.state.cases.length === 0 && <div className="noCases">
                            <h2>{i18next.t("page.private.subsidiary.styled.cases.paragraph")}</h2>
                            <img src="/no_cases.svg" />
                        </div>}
                    </div>
                </HistoryBody>
            </GridElement>
        </Section.Item>
    }
}
