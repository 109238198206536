import React, { Component } from "react"
import { Modal, Grid, Button, Select } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { workflowId: null, loading: false, subsidiaries: [], teams: [], subsidiaryId: null, contextType: null, teamId: null }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                {
                    Teams {
                        nodes {
                            id
                            name
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                inline
                            }
                        }
                    }
                }
            `
        }).then(data => this.setState({ subsidiaries: data.data.Subsidiaries.nodes, teams: data.data.Teams.nodes }))
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ workflowId: null, loading: false, subsidiaryId: null, contextType: null, teamId: null })
        }}>
            <Modal.Head title={i18next.t("page.private.workFlows.executions.createWorkflowExecutionModal.titleForm")} icon="play" />
            <Modal.Body padding={24} adjustHeight="490px">
                <Grid gap="16px">
                    <Select
                        adjustWidth
                        label={i18next.t("page.private.workFlows.executions.createWorkflowExecutionModal.workflow")}
                        icon="project-diagram"
                        value={this.state.workflowId}
                        required
                        onChange={workflowId => this.setState({ workflowId })}
                        noUnselect
                        items={this.props.workflows.map(workflow => ({ key: workflow.id, title: workflow.name }))}
                    />
                    <Select
                        adjustWidth
                        label={i18next.t("page.private.workFlows.executions.createWorkflowExecutionModal.context")}
                        icon="brackets"
                        value={this.state.contextType}
                        required
                        noUnselect
                        onChange={contextType => this.setState({ contextType })}
                        items={[
                            { key: "subsidiary", title: i18next.t("page.private.workFlows.executions.createWorkflowExecutionModal.contextTypes.branch") },
                            { key: "user", title: i18next.t("page.private.workFlows.executions.createWorkflowExecutionModal.contextTypes.user") },
                            { key: "team", title: i18next.t("page.private.workFlows.executions.createWorkflowExecutionModal.contextTypes.team") }
                        ]}
                    />
                    {this.state.contextType === "subsidiary" && <Select
                        adjustWidth
                        label="Niederlassung"
                        icon="map-marker"
                        value={this.state.subsidiaryId}
                        required
                        noUnselect
                        onChange={subsidiaryId => this.setState({ subsidiaryId })}
                        items={this.state.subsidiaries.map(subsidiary => ({ key: subsidiary.id, title: `${subsidiary.name} (${subsidiary.label}), ${subsidiary.address.inline}` }))}
                    />}
                    {this.state.contextType === "team" && <Select
                        adjustWidth
                        label="Arbeitsgruppe"
                        icon="users-crown"
                        value={this.state.teamId}
                        required
                        noUnselect
                        onChange={teamId => this.setState({ teamId })}
                        items={this.state.teams.map(team => ({ key: team.id, title: team.name }))}
                    />}
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={() => <Button loading={this.state.loading} thick green disabled={!this.state.workflowId || !this.state.contextType || (this.state.contextType === "subsidiary" && !this.state.subsidiaryId) || (this.state.contextType === "team" && !this.state.teamId)} title={i18next.t("page.private.workFlows.executions.createWorkflowExecutionModal.exitButton")} icon="play" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($workflowId: ID!, $contextType: String!, $subsidiaryId: ID, $teamId: ID) {
                            createWorkflowExecution(workflowId: $workflowId, contextType: $contextType, subsidiaryId: $subsidiaryId, teamId: $teamId)
                        }
                    `,
                    variables: {
                        workflowId: this.state.workflowId,
                        contextType: this.state.contextType,
                        subsidiaryId: this.state.subsidiaryId,
                        teamId: this.state.teamId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createWorkflowExecution) {
                        this.props.history.push("/workflow-execution/" + result.data.createWorkflowExecution)
                    }
                })}
            } />} />
        </Modal>
    }
}
