import React, { useRef, Suspense } from 'react'
import styled from 'styled-components'
import * as THREE from "three"
import {Canvas, render, useFrame, useThree} from 'react-three-fiber'
import { useGLTF } from '@react-three/drei/useGLTF'
import {Box} from "@react-three/drei";

function SmallCar(props) {
    const { nodes, materials } = useGLTF('/smallCar.glb')
    const smallCar = useRef()
    const scale = 0.6

    rotate(smallCar)

    return (
        <group ref={smallCar} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
            <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
            <mesh receiveShadow castShadow material={materials.Glass} geometry={nodes.Cube_2.geometry} />
            <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_3.geometry} />
            <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube_4.geometry} />
            <mesh receiveShadow castShadow material={materials['Felgen Hinter']} geometry={nodes.Cube_5.geometry} />
            <mesh receiveShadow castShadow material={materials['Felgen Vorne']} geometry={nodes.Cube_6.geometry} />
            <mesh receiveShadow castShadow material={materials['Felge Platte']} geometry={nodes.Cube_7.geometry} />
            <mesh receiveShadow castShadow material={materials.Light} geometry={nodes.Cube_8.geometry} />
            <mesh receiveShadow castShadow material={materials.Redlight} geometry={nodes.Cube_9.geometry} />
            <mesh receiveShadow castShadow material={materials.Backlight} geometry={nodes.Cube_10.geometry} />
        </group>
    )
}

function Limousine(props) {
    const { nodes, materials } = useGLTF('/limousine.glb')
    const limousine = useRef()
    const scale = 0.4

    rotate(limousine)

    return (
        <group ref={limousine} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
            <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
            <mesh receiveShadow castShadow material={materials.Window} geometry={nodes.Cube_2.geometry} />
            <mesh receiveShadow castShadow material={materials.Handle} geometry={nodes.Cube_3.geometry} />
            <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube_4.geometry} />
            <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_5.geometry} />
            <mesh receiveShadow castShadow material={materials.Felge} geometry={nodes.Cube_6.geometry} />
            <mesh receiveShadow castShadow material={materials.PlasticDark} geometry={nodes.Cube_7.geometry} />
            <mesh receiveShadow castShadow material={materials.Backlight} geometry={nodes.Cube_8.geometry} />
            <mesh receiveShadow castShadow material={materials.BacklightWhite} geometry={nodes.Cube_9.geometry} />
            <mesh receiveShadow castShadow material={materials.Light} geometry={nodes.Cube_10.geometry} />
        </group>
    )
}

function SUV(props) {
    const { nodes, materials } = useGLTF('/suv.glb')
    const suv = useRef()
    const scale = 0.35

    rotate(suv)

    return (
        <group ref={suv} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
            <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
            <mesh receiveShadow castShadow material={materials.PlasticDark} geometry={nodes.Cube_2.geometry} />
            <mesh receiveShadow castShadow material={materials.Windows} geometry={nodes.Cube_3.geometry} />
            <mesh receiveShadow castShadow material={materials.Backlight} geometry={nodes.Cube_4.geometry} />
            <mesh receiveShadow castShadow material={materials.Light} geometry={nodes.Cube_5.geometry} />
            <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube_6.geometry} />
            <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_7.geometry} />
            <mesh receiveShadow castShadow material={materials.Felge} geometry={nodes.Cube_8.geometry} />
        </group>
    )
}

function SmallTransporter(props) {
    const { nodes, materials } = useGLTF('/smallTransporter.glb')
    const smallTransporter = useRef()
    const scale = 0.5

    rotate(smallTransporter)

    return (
        <group ref={smallTransporter} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
            <mesh receiveShadow castShadow material={materials.Material} geometry={nodes.Cube_1.geometry} />
            <mesh receiveShadow castShadow material={materials.Window} geometry={nodes.Cube_2.geometry} />
            <mesh receiveShadow castShadow material={materials.FrontLight} geometry={nodes.Cube_3.geometry} />
            <mesh receiveShadow castShadow material={materials.Backlight} geometry={nodes.Cube_4.geometry} />
            <mesh receiveShadow castShadow material={materials['Backwards Light']} geometry={nodes.Cube_5.geometry} />
            <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_6.geometry} />
            <mesh receiveShadow castShadow material={materials.Wheel} geometry={nodes.Cube_7.geometry} />
            <mesh receiveShadow castShadow material={materials.Felgen} geometry={nodes.Cube_8.geometry} />
        </group>
    )
}

function BigTransporter(props) {
    const { nodes, materials } = useGLTF('/bigTransporter.glb')
    const bigTransporter = useRef()
    const scale = 0.25

    rotate(bigTransporter)

    return <group ref={bigTransporter} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh receiveShadow castShadow material={materials.PlasticSubColor} geometry={nodes.Cube_2.geometry} />
        <mesh receiveShadow castShadow material={materials.Windows} geometry={nodes.Cube_3.geometry} />
        <mesh receiveShadow castShadow material={materials.Backlight} geometry={nodes.Cube_4.geometry} />
        <mesh receiveShadow castShadow material={materials.Frontlight} geometry={nodes.Cube_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube_6.geometry} />
        <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_7.geometry} />
        <mesh receiveShadow castShadow material={materials.Felge} geometry={nodes.Cube_8.geometry} />
    </group>
}

function BoxTransporter(props) {
    const { nodes, materials } = useGLTF('/boxTransporter.glb')
    const boxTransporter = useRef()
    const scale = 0.3

    rotate(boxTransporter)

    return <group ref={boxTransporter} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh receiveShadow castShadow material={materials.Glass} geometry={nodes.Cube_2.geometry} />
        <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_3.geometry} />
        <mesh receiveShadow castShadow material={materials.Light} geometry={nodes.Cube_4.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheel} geometry={nodes.Cube_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Felge} geometry={nodes.Cube_6.geometry} />
        <mesh receiveShadow castShadow material={materials.Container} geometry={nodes.Cube_7.geometry} />
    </group>
}

function Truck(props) {
    const { nodes, materials } = useGLTF('/truck.glb')
    const truck = useRef()
    const scale = 0.25

    rotate(truck)

    return <group ref={truck} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh receiveShadow castShadow material={materials.Glass} geometry={nodes.Cube_2.geometry} />
        <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_3.geometry} />
        <mesh receiveShadow castShadow material={materials.BackLight} geometry={nodes.Cube_4.geometry} />
        <mesh receiveShadow castShadow material={materials.BackRed} geometry={nodes.Cube_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Light} geometry={nodes.Cube_6.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheel} geometry={nodes.Cube_7.geometry} />
        <mesh receiveShadow castShadow material={materials.Felge} geometry={nodes.Cube_8.geometry} />
        <mesh receiveShadow castShadow material={materials.Container} geometry={nodes.Cube_9.geometry} />
    </group>
}

function Trailer(props) {
    const { nodes, materials } = useGLTF('/anhaenger.glb')
    const trailer = useRef()
    const scale = 0.275

    rotate(trailer)

    return <group ref={trailer} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyMetallic} geometry={nodes.Cube_1.geometry} />
        <mesh receiveShadow castShadow material={materials.SubMetallic} geometry={nodes.Cube_2.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube_3.geometry} />
        <mesh receiveShadow castShadow material={materials.Felge} geometry={nodes.Cube_4.geometry} />
        <mesh receiveShadow castShadow material={materials.PlasticDark} geometry={nodes.Cube_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Backlight} geometry={nodes.Cube_6.geometry} />
    </group>
}

function Bagger(props) {
    const { nodes, materials } = useGLTF('/bagger.glb')
    const bagger = useRef()
    const scale = 0.225

    rotate(bagger)

    return <group ref={bagger} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh receiveShadow castShadow material={materials.Window} geometry={nodes.Cube_2.geometry} />
        <mesh receiveShadow castShadow material={materials.SubColor} geometry={nodes.Cube_3.geometry} />
        <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_4.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Redlight} geometry={nodes.Cube_6.geometry} />
    </group>
}

function ForkLift(props) {
    const { nodes, materials } = useGLTF('/forkLift.glb')
    const forkLift = useRef()
    const scale = 0.45

    rotate(forkLift)

    return <group ref={forkLift} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh receiveShadow castShadow material={materials.SubColor} geometry={nodes.Cube_2.geometry} />
        <mesh receiveShadow castShadow material={materials.Backlight} geometry={nodes.Cube_3.geometry} />
        <mesh receiveShadow castShadow material={materials.ThirdBodyColor} geometry={nodes.Cube_4.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Felgen} geometry={nodes.Cube_6.geometry} />
        <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_7.geometry} />
    </group>
}

function Tractor(props) {
    const { nodes, materials } = useGLTF('/tractor.glb')
    const tractor = useRef()
    const scale = 0.45

    rotate(tractor)

    return <group ref={tractor} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube001.geometry} />
        <mesh receiveShadow castShadow material={materials.Windows} geometry={nodes.Cube001_1.geometry} />
        <mesh receiveShadow castShadow material={materials['Darker Material']} geometry={nodes.Cube001_2.geometry} />
        <mesh receiveShadow castShadow material={materials.Metal} geometry={nodes.Cube001_3.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube001_4.geometry} />
        <mesh receiveShadow castShadow material={materials.Felge} geometry={nodes.Cube001_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Redlight} geometry={nodes.Cube001_6.geometry} />
        <mesh receiveShadow castShadow material={materials.Light} geometry={nodes.Cube001_7.geometry} />
    </group>
}

function TransportLift(props) {
    const { nodes, materials } = useGLTF('/transportLift.glb')
    const transportLift = useRef()
    const scale = 0.25

    rotate(transportLift)

    return <group ref={transportLift} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh receiveShadow castShadow material={materials.PlasticDark} geometry={nodes.Cube_2.geometry} />
        <mesh receiveShadow castShadow material={materials.Windows} geometry={nodes.Cube_3.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube_4.geometry} />
        <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Felge} geometry={nodes.Cube_6.geometry} />
        <mesh receiveShadow castShadow material={materials.Light} geometry={nodes.Cube_7.geometry} />
    </group>
}

function TransportTruck(props) {
    const { nodes, materials } = useGLTF('/transportTruck.glb')
    const transportTruck = useRef()
    const scale = 0.325

    rotate(transportTruck)

    return <group ref={transportTruck} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh receiveShadow castShadow material={materials.SubColor} geometry={nodes.Cube_2.geometry} />
        <mesh receiveShadow castShadow material={materials.PlasticDark} geometry={nodes.Cube_3.geometry} />
        <mesh receiveShadow castShadow material={materials.Windows} geometry={nodes.Cube_4.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube_6.geometry} />
        <mesh receiveShadow castShadow material={materials.Felge} geometry={nodes.Cube_7.geometry} />
        <mesh receiveShadow castShadow material={materials.Light} geometry={nodes.Cube_8.geometry} />
        <mesh receiveShadow castShadow material={materials.Backlight} geometry={nodes.Cube_9.geometry} />
    </group>
}

function FlatbedTruck(props) {
    const { nodes, materials } = useGLTF('/flatbedTruck.glb')
    const flatbedTruck = useRef()
    const scale = 0.4

    rotate(flatbedTruck)

    return <group ref={flatbedTruck} position={[0, -1.6, 0]} scale={[scale, scale, scale]}>
        <mesh receiveShadow castShadow material={materials.BodyColor} geometry={nodes.Cube001.geometry} />
        <mesh receiveShadow castShadow material={materials.SecondColor} geometry={nodes.Cube001_1.geometry} />
        <mesh receiveShadow castShadow material={materials.Metallic} geometry={nodes.Cube001_2.geometry} />
        <mesh receiveShadow castShadow material={materials.Plating} geometry={nodes.Cube001_3.geometry} />
        <mesh receiveShadow castShadow material={materials.Windows} geometry={nodes.Cube001_4.geometry} />
        <mesh receiveShadow castShadow material={materials.Wheels} geometry={nodes.Cube001_5.geometry} />
        <mesh receiveShadow castShadow material={materials.Felgen} geometry={nodes.Cube001_6.geometry} />
        <mesh receiveShadow castShadow material={materials.Frontlight} geometry={nodes.Cube001_7.geometry} />
        <mesh receiveShadow castShadow material={materials.Backlight} geometry={nodes.Cube001_8.geometry} />
        <mesh receiveShadow castShadow material={materials.BackwardsLight} geometry={nodes.Cube001_9.geometry} />
    </group>
}

const rotate = ref => {
    useFrame(({ clock }) => {
        if(ref.current) ref.current.rotation.y = clock.elapsedTime * 0.5
    })
}

export default (props) => {
    const planeGeometry = new THREE.PlaneGeometry(24, 24, 32)
    const material = new THREE.MeshLambertMaterial({ color: 0xffffff, side: THREE.DoubleSide })
    const plane = new THREE.Mesh(planeGeometry, material)
    plane.rotation.x = Math.PI / 2
    plane.position.y = -1.6
    plane.frustumCulled = false
    plane.receiveShadow = true

    return (
        <div style={{ width: '100%', height: props.height ? (props.height + 'px') : '100%', position: 'relative' }}>
            <Canvas shadowMap style={{ background: "#e8f4fc" }}>
                <ambientLight intensity={0.25} />
                <directionalLight castShadow intensity={0.5} position={[ 3, 4, 0 ]} />
                <directionalLight intensity={0.5} position={[ -3, 1, 0 ]} />
                <primitive object={plane} />
                <Suspense fallback={null}>
                    { props.selectedCar === "smallCar" && <SmallCar carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "limousine" && <Limousine carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "suv" && <SUV carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "smallTransporter" && <SmallTransporter carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "bigTransporter" && <BigTransporter carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "boxTransporter" && <BoxTransporter carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "truck" && <Truck carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "forklift" && <ForkLift carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "excavator" && <Bagger carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "tractor" && <Tractor carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "trailer" && <Trailer carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "transportTruck" && <TransportTruck carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "transportLift" && <TransportLift carPos={props.carPos || [0, 0, 0]} /> }
                    { props.selectedCar === "flatbedTruck" && <FlatbedTruck carPos={props.carPos || [0, 0, 0]} /> }
                </Suspense>
            </Canvas>
        </div>
    )
}
/*
useGLTF.preload('smallCar.glb')
useGLTF.preload('limousine.glb')
useGLTF.preload('suv.glb')
useGLTF.preload('smallTransporter.glb')
useGLTF.preload('bigTransporter.glb')
useGLTF.preload('boxTransporter.glb')
useGLTF.preload('truck.glb')
useGLTF.preload('forkLift.glb')
useGLTF.preload('bagger.glb')
useGLTF.preload('tractor.glb')
useGLTF.preload('anhaenger.glb')
useGLTF.preload('transportTruck.glb')
useGLTF.preload('transportLift.glb')
useGLTF.preload('flatbedTruck.glb')*/