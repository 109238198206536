import React, { Component } from "react"
import { Modal, Button, Select } from "scanmetrix-components"
import { v4 as uuid } from "uuid"
import i18next from "i18next";

export default class extends Component {
    state = { phaseId: null, loading: false }

    constructor(props) {
        super(props)
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ phaseId: null, loading: false })

            this.file = null
        }}>
            <Modal.Head title={i18next.t("page.private.workFlow.createPhaseNodeModal.title")} icon="project-diagram" />
            <Modal.Body adjustHeight="328px" padding={24}>
                <Select
                    label="Phase"
                    icon="wrench"
                    value={this.state.phaseId}
                    required
                    noUnselect
                    adjustWidth
                    onChange={phaseId => this.setState({ phaseId })}
                    items={this.props.phases.map(phase => ({ key: phase.id, title: phase.name }))}
                />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.phaseId} title={i18next.t("page.private.workFlow.createPhaseNodeModal.exitButton")} icon="project-diagram" onClick={() => {
                this.props.addNode({
                    id: uuid(),
                    data: { phaseId: this.state.phaseId },
                    type: "phase",
                    position: { x: 0, y: 0 }
                })

                instance.close()
            }} />} />
        </Modal>
    }
}
