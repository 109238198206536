import React, { Component } from "react"
import { Modal, Input, IconSelector, Checkbox } from "scanmetrix-components"
import { CirclePicker } from "react-color"
import styled from "styled-components"
import i18next from "i18next";

const CircleDesign = styled.div`
    transform: translateX(7px);
`

export default class extends Component {
    state = { id: null, ticketType: null, color: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={ticketType => this.setState({ ticketType: ticketType.raw, ...ticketType.raw, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.updateTicketType.title")} icon="ticket" />
            <Modal.Body padding={24}>
                <Input.MutationProvider value={{
                    name: "updateTicketType",
                    id: this.state.id,
                    data: this.state.ticketType,
                    onSave: () => this.props.refresh()
                }}>
                    <div style={{ padding: "16px", boxSizing: "border-box", border: `3px dashed ${this.state.color || "#cccccc"}`, display: "grid", gridGap: "32px", gridTemplateColumns: "1fr", width: "400px" }}>
                        <Input required field="name" icon="tag" label={i18next.t("page.private.settings.modals.createTicketType.name")} adjustWidth />
                        <CircleDesign>
                            <CirclePicker onChange={color => {
                                this.setState({ color: color.hex })

                                scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                        mutation($id: ID!, $color: String!) {
                                            updateTicketType(id: $id, color: $color)
                                        }
                                    `,
                                    variables: {
                                        id: this.state.id,
                                        color: color.hex
                                    }
                                }).then(data => {
                                    if(data.data.updateTicketType) this.props.refresh()
                                })
                            }} width="100%" />
                        </CircleDesign>
                        <Checkbox label={i18next.t("page.private.settings.modals.updateTicketType.active")} field="active" />
                        <IconSelector value={this.state.icon} onChange={icon => {
                            this.setState({ icon })

                            scanmetrix.client.mutate({
                                mutation: scanmetrix.gql`
                                mutation($id: ID!, $icon: String!) {
                                    updateTicketType(id: $id, icon: $icon)
                                }
                            `,
                                variables: {
                                    id: this.state.id,
                                    icon
                                }
                            }).then(data => {
                                if(data.data.updateTicketType) this.props.refresh()
                            })
                        }} />
                    </div>
                </Input.MutationProvider>
            </Modal.Body>
        </Modal>
    }
}
