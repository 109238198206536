import React from 'react'
import { useTransition, animated } from 'react-spring'
import styled from 'styled-components'

const views = (value, icon) => [
    ({ style }) => <animated.div className='inner' style={{ ...style }}><p>{value}</p></animated.div>,
    ({ style }) => <animated.div className='inner' style={{ ...style }}><i className={`far fa-${icon}`} /><StyledTextInput value={value} /></animated.div>
]

const Body = styled.div`
  width: 100%;
  height: ${props => props.height || '40px'};
  margin-bottom: ${props => props.marginBottom || 0};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  will-change: transform, opacity;
  
  .inner {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    p {
      font-size: 1em;
      font-weight: normal;
    }
    
    i {
      margin-right: 8px;
      font-size: 1em;
    }
  }
`

const StyledTextInput = styled.input`
  flex: 1;
  outline: none;
  padding: 2.5px 0;
  padding-right: 16px;
  font-size: ${props => props.fontSize || '1em'};
  font-weight: ${props => props.fontWeight || 'normal'};
  border: none;
  border-bottom: 1px solid #20242B;
  box-shadow: none;
  background: transparent;
`

export default function AnimatedDiv(props) {
    const transitions = useTransition(props.index, p => p, {
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    })

    const value = props.value
    const icon = props.icon

    return <Body height={props.height}>
        {transitions.map(( { item, props, index } ) => {
            const View = views(value, icon)[item]
            
            return <View key={index} multiline style={props} />
        })}
    </Body>
}
