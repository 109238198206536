import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"
import styled from "styled-components"
import Script from "react-load-script";
import i18next from "i18next";

const Options = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 24px;
    width: 600px;
  
    .option {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 24px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        user-select: none;
        cursor: pointer;
        transition: all 0.3s;
        
        &:hover {
            border-color: #3b97d3;  
            background: rgb(59 151 211 / 9%);
        }
      
        .right {
            display: flex;
            flex-direction: column;
            font-size: 18px;
          
            .title {
                font-weight: bold;
                margin-bottom: 4px;
            }
          
            .description {
                line-height: 28px;
            }
        }
      
        i {
            font-size: 40px;
            color: #3b97d3;
            flex-shrink: 0;
            margin-right: 32px;
            width: 48px;
        }
    }
`


const Steps = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 32px;
    
    .step {
        display: flex;
        
        .number {
            width: 32px;
            height: 32px;
            background: #20242b;
            border-radius: 100%;
            margin-right: 16px;
            flex-shrink: 0;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9em;
        }
        
        .right {
            display: flex;
            flex-direction: column;
            
            .title {
                margin-bottom: 4px;
                font-weight: bold;
                font-size: 1.1em;
            }
        }
    }
`

export default class extends Component {
    state = {
        loading: false
    }

    render() {
        const purchaseOrderId = this.props.purchaseOrderId

        return <Modal minWidth="650px" instance={instance => {
            this.instance = instance
            this.props.instance(instance)
        }} initialize={() => this.setState({
            loading: false
        })}>
            <Modal.Head title={i18next.t("page.private.purchaseOrder.commissionPurchaseOrderModal.title")} icon="paper-plane" />
            <Modal.Body padding={24}>
                <Options>
                    <div className="option" onClick={() => {
                        this.instance.close()
                        this.props.sendEmail()
                    }}>
                        <i className="fa-duotone fa-paper-plane" />
                        <div className="right">
                            <p className="title">{i18next.t("page.private.purchaseOrder.commissionPurchaseOrderModal.emailTitle")}</p>
                            <p className="description">{i18next.t("page.private.purchaseOrder.commissionPurchaseOrderModal.emailDescription")}</p>
                        </div>
                    </div>
                    <div className="option" onClick={() => {
                        this.instance.close()
                        this.props.cfds()
                    }}>
                        <i className="fa-duotone fa-sync" />
                        <div className="right">
                            <p className="title">{i18next.t("page.private.purchaseOrder.commissionPurchaseOrderModal.cfdsTitle")}</p>
                            <p className="description">{i18next.t("page.private.purchaseOrder.commissionPurchaseOrderModal.cfdsDescription")}</p>
                        </div>
                    </div>
                    <div className="option" onClick={() => {
                        this.instance.close()

                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                        mutation($id: ID!, $status: String!) {
                            updatePurchaseOrderStatus(id: $id, status: $status)
                        }
                    `,
                            variables: {
                                id: purchaseOrderId,
                                status: "confirmation"
                            }
                        }).then(result => {
                            if(result.data.updatePurchaseOrderStatus) {
                                this.props.refresh()
                            }
                        })
                    }}>
                        <i className="fa-duotone fa-check" />
                        <div className="right">
                            <p className="title">{i18next.t("page.private.purchaseOrder.commissionPurchaseOrderModal.commissionTitle")}</p>
                            <p className="description">{i18next.t("page.private.purchaseOrder.commissionPurchaseOrderModal.commissionDescription")}</p>
                        </div>
                    </div>
                </Options>
            </Modal.Body>
        </Modal>
    }
}
