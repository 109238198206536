import React, { Component } from "react"
import {Page, Breadcrumbs, Section, Button} from "scanmetrix-components"
import styled from "styled-components"
import i18next from "i18next";

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
`

const GridBody = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  border-radius: 10px;
  
  .title-row {
    width: 100%;
    padding: 24px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    
    h1 {
      font-size: 1.6em;
      color: #20242B;
      font-weight: normal;
    }
  }
  
  .grid-row {
    padding: 8px 32px 8px 0;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr repeat(2, 50px) 25px repeat(${props => props.items}, 50px);
    grid-auto-rows: 50px;
    user-select: none;
    
    @media screen and (max-width: 1280px) {
      padding: 8px 16px 8px 0;
      grid-gap: 16px;
      zoom: 0.75;
    }
    
    .tags {
      display: flex;
      justify-content: center;
      align-items: center;
      
      p {
        color: #20242B;
        font-size: 1.2em;
      }
    }
    
    .text-view {
      padding-left: 32px;
      display: flex;
      align-items: center;
      
      p {
        font-size: 1.2em;
      }
    }
    
    .checkbox {
      background: linear-gradient(45deg, #DEDEDE, #F1F1F1);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
      cursor: pointer;
      transition: all 250ms ease;
      
      &:hover {
        transform: scale(1.025);
      }
      
      i {
        font-size: 1.6em;
        color: black;
        opacity: 0;
        transition: all 250ms ease;
      }
      
      &.active {
        
        i {
          opacity: 1;
        }
      }
    }
  }
`


const notifications = [
    {
        type: "email",
        icon: "at"
    },
    {
        type: "group",
        icon: "layer-group"
    }
]

const periodHours = [ 0, 1, 4, 8, 24, 3 * 24, 7 * 24, 14 * 24, 30 * 24, 60 * 24, 90 * 24, 180 * 24 ]

class GridItem extends Component {
    state = { checked: [], types: [] }

    constructor(props) {
        super(props)

        const initialSettings = props.initialSettings

        if(initialSettings[this.props.item.id]) {
            const settings = initialSettings[this.props.item.id]

            this.state.types = settings.types
            this.state.checked = settings.intervals
        }
    }


    togglePeriod(hours) {
        let checked = this.state.checked

        if(checked.includes(hours)) checked = checked.filter(hour => hour !== hours)
        else checked.push(hours)

        this.setState({ checked }, () => this.props.mutate(checked, this.state.types))
    }

    toggleType(type) {
        let types = this.state.types

        if(types.includes(type)) types = types.filter(target => target !== type)
        else types.push(type)

        this.setState({ types }, () => this.props.mutate(this.state.checked, types))
    }

    render() {
        const item = this.props.item

        return <div className='grid-row'>
            <div className='text-view'>
                <p>{item.title}</p>
            </div>
            {notifications.map(not => <div onClick={() => this.toggleType(not.type)} className={`checkbox ${this.state.types.includes(not.type) ? "active" : ""}`} key={not.type}>
                <i className='far fa-check' />
            </div>)}
            <div className='spacer' />
            {periodHours.map(periodHour => {
                if(item.types && !item.types.includes(periodHour)) return null

                return <div onClick={() => this.togglePeriod(periodHour)} className={`checkbox ${this.state.checked.includes(periodHour) ? "active" : ""}`} key={periodHour}>
                    <i className='far fa-check' />
                </div>
            })}
        </div>
    }
}

class GridList extends Component {
    render() {
        return <GridBody items={periodHours.length}>
            <div className='title-row'>
                <div className='title-view'>
                    <h1>{this.props.title}</h1>
                </div>
                {/*<ButtonView>
                    <Button title='Alle deaktivieren' icon='bell-slash' thick secondary onClick={() => {
                        this.props.data.forEach(item => {
                            scanmetrix.client.mutate({
                                mutation: scanmetrix.gql`
                                    mutation($type: String!, $notifyAt: [Int]!, $notifyTypes: [String]!) {
                                        updateNotificationSetting(type: $type, notifyAt: $notifyAt, notifyTypes: $notifyTypes)
                                    }
                                `,
                                variables: {
                                    type: item.id,
                                    notifyAt: [],
                                    notifyTypes: []
                                }
                            }).then(() => {})
                        })
                    }} />
                </ButtonView>*/}
            </div>
            <div className="grid-row">
                <div className='spacer' />
                {notifications.map(not => <div key={not.type} className='tags'>
                    <i className={'far fa-' + not.icon} />
                </div>)}
                <div className='spacer' />
                {periodHours.map(periodHour => <div key={periodHour} className="tags">
                    {periodHour === 0 && <p>{i18next.t("page.private.notificationSettings.immediately")}</p>}
                    {periodHour !== 0 && periodHour < 24 && <p>{periodHour} {i18next.t("page.private.notificationSettings.hours")}</p>}
                    {periodHour >= 24 && <p>{periodHour / 24} {i18next.t("page.private.notificationSettings.days")}</p>}
                </div>)}
            </div>
            {this.props.data.map(item => {
                return <GridItem initialSettings={this.props.initialSettings} item={item} key={item.id} mutate={(checked, types) => {
                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($type: String!, $notifyAt: [Int]!, $notifyTypes: [String]!) {
                                updateNotificationSetting(type: $type, notifyAt: $notifyAt, notifyTypes: $notifyTypes)
                            }
                        `,
                        variables: {
                            type: item.id,
                            notifyAt: checked,
                            notifyTypes: types
                        }
                    }).then(() => {})
                }} />
            })}
        </GridBody>
    }
}

export default class extends Component {
    state = { notificationSettings: null }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                {
                    Session {
                        id
                        notificationSettings
                    }
                }
            `
        }).then(data => {
            this.setState({ notificationSettings: data.data.Session.notificationSettings })
        })
    }

    render() {

        const settings = [
            {
                title: i18next.t("page.private.notificationSettings.facilityManagement.title"),
                items: [
                    {
                        id: "dueDate",
                        title: i18next.t("page.private.notificationSettings.facilityManagement.deadlineTechnicalInstallations")
                    },
                    {
                        id: "flawDueDate",
                        title: i18next.t("page.private.notificationSettings.facilityManagement.deadlineDefect")
                    }
                ]
            },
            {
                title: i18next.t("page.private.notificationSettings.contractManagement.title"),
                items: [
                    {
                        id: "contractStart",
                        title: i18next.t("page.private.notificationSettings.contractManagement.contractStart")
                    },
                    {
                        id: "contractEnd",
                        title: i18next.t("page.private.notificationSettings.contractManagement.contractEnd")
                    },
                    {
                        id: "contractCancelationDueDate",
                        title: i18next.t("page.private.notificationSettings.contractManagement.terminationPeriod")
                    },
                    {
                        id: "contractExtensionOption",
                        title: i18next.t("page.private.notificationSettings.contractManagement.pendingExtensionOption")
                    },
                    {
                        id: "contractRenewal",
                        title: i18next.t("page.private.notificationSettings.contractManagement.pendingContractExtension")
                    },
                    {
                        id: "contractProvisionInterval",
                        title: i18next.t("page.private.notificationSettings.contractManagement.upcomingServiceCycle")
                    }
                ]
            },
            {
                title: i18next.t("page.private.notificationSettings.calendar.title"),
                items: [
                    {
                        id: "appointment",
                        title: i18next.t("page.private.notificationSettings.calendar.appointmentStart")
                    }
                ]
            },
            {
                title: i18next.t("page.private.notificationSettings.inspection.title"),
                items: [
                    {
                        id: "buildingInspection",
                        title: i18next.t("page.private.notificationSettings.inspection.dueInspection")
                    }
                ]
            },
            {
                title: i18next.t("page.private.notificationSettings.tickets.title"),
                items: [
                    {
                        id: "newTicket",
                        title: i18next.t("page.private.notificationSettings.tickets.newTicket"),
                        types: [ 0 ]
                    },
                    {
                        id: "ticketAssignedToYou",
                        title: i18next.t("page.private.notificationSettings.tickets.assignedTicket"),
                        types: [ 0 ]
                    },
                    {
                        id: "ticketEscalation",
                        title: i18next.t("page.private.notificationSettings.tickets.escalation"),
                        types: [ 0 ]
                    }
                ]
            }
        ]

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "cog",
                    title: i18next.t("page.private.notificationSettings.breadCrumbsTitle")
                }
            ]} />

            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.notificationSettings.gridElementTitle")} icon="bell">
                    <Body>
                        {this.state.notificationSettings && settings.map((setting, index) => <GridList initialSettings={this.state.notificationSettings} key={index} title={setting.title} data={setting.items} />)}
                    </Body>
                </Section.Item>
            </Section>
        </Page>
    }
}
