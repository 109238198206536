import styled from "styled-components"

export default styled.div`
  padding: 0 48px;
  display: flex;
  flex-direction: column;
  user-select: none;
  
  h1 {
    color: #3b97d3;
    margin: 32px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8em;
    
    i {
      width: 24px;
      height: 24px;
      border-radius: 32px;
      border: 3px solid rgba(0, 0, 0, 0.3);
      color: rgba(0, 0, 0, 0.3);
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      font-size: 0.5em;
      cursor: pointer;
      transition: all 0.3s;
      
      &:hover {
        color: #3b97d3;
        border-color: #3b97d3;
      }
    }
  }
  
  @media screen and (max-width: 1920px) {
    padding: 0 32px;
  }
  
  @media screen and (max-width: 1919px) {
    font-size: 0.8em;
    
    img {
      height: 50%;
    }
  }
`
