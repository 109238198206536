import styled from "styled-components"

export default styled.img`
  width: 100% !important;
  height: 100% !important;
  max-width: unset !important;
  max-height: unset !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  transform: scale(1.2);
  margin: 0 auto;
  object-fit: contain;
  filter: grayscale() brightness(1);
  opacity: 0.04;
  user-select: none;
  margin-bottom: 0 !important;
`
