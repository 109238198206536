
import React, { Component } from "react"
import { Button,  } from "scanmetrix-components"
import AdressModal from "./AdressModal"










//Benutzen um Kontaktbuch zu exportieren
export default class extends Component {
    state = { accountingInvoiceDocumentId: null, loading: false }
    
    handleEmailSelect = (email) => {
        console.log('Ausgewählte E-Mail:', email);
        if (email) {
    
            this.props.onEmailSelected(email);
        }
        this.AdressModal.close()
    };

  


    
    render() { 
       

        return <>
         <AdressModal instance={ref => this.AdressModal = ref} fetch={() => this.fetch()}  isOpen={this.state.modalOpen} onClose={this.closeModal} onEmailSelect={this.handleEmailSelect}   />
         <Button title={"Kontakte"} onEmailSelect={this.handleEmailSelect} icon="address-book" thick onClick={() => this.AdressModal.open()} />
        
        </>
    }
}


