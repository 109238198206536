import React, { Component } from 'react'
import { Button, Modal, Input } from 'scanmetrix-components'

export default class extends Component {
    state = { name: null, area: null, loading: false }

    render() {
        return <Modal instance={this.props.instance} initialize={() => {
            this.setState({ name: null, area: null, loading: false })
        }}>
            <Modal.Head title="Raum anlegen" icon="table-layout" />
            <Modal.Body padding={24}>
                <div style={{
                    display: "grid",
                    gridGap: "32px",
                    gridTemplateColumns: "1fr"
                }}>
                    <Input label="Name" icon="tag" value={this.state.name} required onChange={name => this.setState({name})} adjustWidth/>
                    <Input format={value => value ? `${value} m²` : ""} float label="Fläche in m²" icon="table-layout" value={this.state.area} required onChange={area => this.setState({area})} adjustWidth/>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading}
                                                       disabled={!this.state.name || this.state.area === null} thick
                                                       title="Raum anlegen" icon="table-layout" onClick={() => {
                this.setState({ loading: true })

                fetch(`${scanmetrix.nestURL}/v2/room`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        name: this.state.name,
                        area: this.state.area,
                        subsidiary_id: this.props.subsidiaryId
                    })
                }).then(result => {
                    if(result.status === 201) {
                        this.props.refresh().then(() => {
                            instance.close()
                        })
                    } else {
                        this.setState({ loading: false })
                    }
                }).catch(() => this.setState({ loading: false }))
            }} />} />
        </Modal>
    }
}
