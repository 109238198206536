import React, { Component } from "react"
import { Section, GridElement, Table, Select, Button, Grid, Modal, Input } from "scanmetrix-components"
import styled from "styled-components"
import {FileManager} from "../../documents";
import Avatar from "react-avatar";
import DeleteContractProvisionProofCommentModal from "../modals/DeleteContractProvisionProofCommentModal";
import { withRouter } from "react-router-dom"
import i18next from "i18next";
import RemindServiceProviderModal from "../modals/RemindServiceProviderModal";
import { contractTypes } from "../../dashboard/propertyManagement"

export default withRouter(class extends Component {
    state = { proofs: [], subsidiaries: [], serviceProviders: [], contracts: [], orderBy: "dateDESC" }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        fetch(scanmetrix.backendURL + "/contract-provision-proofs", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            console.log(data)
            this.setState({ proofs: data.contractProvisionProofs })
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    ServiceProviders {
                        nodes {
                            id
                            name
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                        }
                    }
                    Contracts {
                        nodes {
                            id
                            beginsAt
                            endsAt
                            title
                            type
                            number
                            perpetual
                            automaticRenewal
                            partnerName
                            contractDurationAmount
                            contractDurationType
                            workSection {
                                id
                                name
                                icon
                                stlbId
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ contracts: data.data.Contracts.nodes, serviceProviders: data.data.ServiceProviders.nodes, subsidiaries: data.data.Subsidiaries.nodes })
        })
    }

    render() {
        const proofSort = (a, b) => {
            if(this.state.orderBy === "dateDESC") return moment(b.date, "DD.MM.YYYY").valueOf() - moment(a.date, "DD.MM.YYYY").valueOf()

            return moment(a.date, "DD.MM.YYYY").valueOf() - moment(b.date, "DD.MM.YYYY").valueOf()
        }

        const proofFilter = proof => {
            if(this.state.filterSubsidiaryId) {
                if(!proof.subsidiary) return false
                if(String(proof.subsidiary.id) !== String(this.state.filterSubsidiaryId)) return false
            }

            if(this.state.filterServiceProviderId) {
                if(!proof.contractProvisionInterval.contract.serviceProvider) return false
                if(String(proof.contractProvisionInterval.contract.serviceProvider.id) !== String(this.state.filterServiceProviderId)) return false
            }

            if(this.state.filterType) {
                if(!proof.contractProvisionInterval.contract.type) return false
                if(String(proof.contractProvisionInterval.contract.type) !== String(this.state.filterType)) return false
            }

            return true
        }

        console.log(this.state.proofs)

        return <>
            <RemindServiceProviderModal refresh={() => this.fetch()} instance={ref => this.remindServiceProviderModal = ref} />
            <Section.Item {...this.props} title={i18next.t("page.private.contracts.performanceRecords.sectionTitle")} icon="file-certificate" amount={this.state.proofs.length} amountColor={this.state.proofs.length > 0 ? "#e67e22" : "#16a085"}>
                {/*this.props.selected && <Section>
                    <Section.Item title={"Listenansicht"} icon="list">*/}

                        <GridElement styled title={i18next.t("page.private.contracts.performanceRecords.filterSortation.gridElementTitle")} icon="filter" rightContent={<div style={{ display: "grid", gridTemplateColumns: "250px 250px 250px 250px", gridGap: 16 }}>
                            <Select label={i18next.t("page.private.contracts.performanceRecords.filterSortation.searchPlaceholderBranches")} icon="map-marker" items={this.state.subsidiaries.map(subsidiary => ({
                                key: subsidiary.id,
                                title: `${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""}`
                            }))} adjustWidth value={this.state.filterSubsidiaryId} onChange={filterSubsidiaryId => this.setState({ filterSubsidiaryId })} />
                            <Select label={i18next.t("page.private.contracts.performanceRecords.filterSortation.searchPlaceholderContractType")} icon="pencil-alt" items={contractTypes.map(ct => ({ key: ct.key, title: i18next.t("page.private.dashboard.propertyManagement.contractType." + ct.key) }))} adjustWidth value={this.state.filterType} onChange={filterType => this.setState({ filterType })} />
                            <Select label={i18next.t("page.private.contracts.performanceRecords.filterSortation.searchPlaceholderServiceProvider")} icon="user-tie" items={this.state.serviceProviders.map(serviceProvider => ({
                                key: serviceProvider.id,
                                title: serviceProvider.name
                            }))} adjustWidth value={this.state.filterServiceProviderId} onChange={filterServiceProviderId => this.setState({ filterServiceProviderId })} />
                            <Select noUnselect label={i18next.t("page.private.contracts.performanceRecords.filterSortation.sortation.title")} icon="sort-up" items={[
                                { key: "dateASC", title: i18next.t("page.private.contracts.performanceRecords.filterSortation.sortation.serviceDateAscending") },
                                { key: "dateDESC", title: i18next.t("page.private.contracts.performanceRecords.filterSortation.sortation.serviceDateDescending") }
                            ]} adjustWidth value={this.state.orderBy} onChange={orderBy => this.setState({ orderBy })} />



                        </div>} />
                        <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 32, marginTop: 32 }}>
                            {this.state.proofs.filter(proofFilter).sort(proofSort).map((proof, index) => <GridElement styled title={<><b style={{ color: proof.priority === "medium" ? "#e67e22" : (proof.priority === "high" ? "rgb(231, 76, 60)" : "#1abc9c")}}>({proof.priority === "medium" ? i18next.t("page.private.contracts.performanceRecords.prioritySection.medium") : (proof.priority === "high" ? i18next.t("page.private.contracts.performanceRecords.prioritySection.high") : i18next.t("page.private.contracts.performanceRecords.prioritySection.low"))} {i18next.t("page.private.contracts.performanceRecords.prioritySection.priority")})</b> {i18next.t("page.private.contracts.performanceRecords.prioritySection.trackingLN")} <b>{proof.name}</b></>} icon="file-certificate" key={index} rightContent={<div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
                                <div style={{ display: "flex", alignItems: "center", color: "rgb(231, 76, 60)" }}>
                                    <i className="far fa-exclamation-triangle" style={{ marginRight: 8 }} />
                                    <b>{moment(proof.date, "DD.MM.YYYY").fromNow()} ({moment(proof.date, "DD.MM.YYYY").format("DD.MM.YYYY")})</b>
                                </div>

                                <Button disabled={scanmetrix.checkPermission("ContractProvisionProof") < 3} title={i18next.t("page.private.contracts.performanceRecords.prioritySection.trackingButton")} green thick icon="check-circle" onClick={() => this.props.endTracingModal({ proofId: proof.id, callback: () => this.fetch() })} />
                            </div>}>
                                <Section>
                                    <Section.Item title={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.basicDataTitle")} icon="cog">
                                        <div style={{ padding: 32 }}>
                                            <Grid gap="16px" columns={[ "1fr", "1fr", "1fr", "1fr" ]}>
                                                {proof.subsidiary && <>
                                                    <GridElement columnStart={1} columnEnd={5}>
                                                        <Modal.Divider title={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.branchInformation")} />
                                                    </GridElement>
                                                    <GridElement columnStart={1} columnEnd={2}>
                                                        <Input icon="tag" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.branchInformationName")} adjustWidth readOnly value={proof.subsidiary.name} />
                                                    </GridElement>
                                                    <GridElement columnStart={2} columnEnd={3}>
                                                        <Input icon="store" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.abbreviation")} adjustWidth readOnly value={proof.subsidiary.label} />
                                                    </GridElement>
                                                    <GridElement columnStart={3} columnEnd={5}>
                                                        <Input icon="map-marker" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.address")} adjustWidth readOnly value={proof.subsidiary.address.inline} />
                                                    </GridElement>
                                                </>}
                                                <GridElement columnStart={1} columnEnd={5}>
                                                    <Modal.Divider title={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.contractInformation")} />
                                                </GridElement>
                                                <GridElement columnStart={1} columnEnd={2}>
                                                    <Input icon="sort-numeric-up-alt" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.contractNumner")} adjustWidth readOnly value={proof.contractProvisionInterval.contract.number} />
                                                </GridElement>
                                                <GridElement columnStart={2} columnEnd={3}>
                                                    <Input icon="tag" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.contractName")} adjustWidth readOnly value={proof.contractProvisionInterval.contract.title} />
                                                </GridElement>
                                                <GridElement columnStart={3} columnEnd={4}>
                                                    <Input icon="pencil-alt" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.contractType")} adjustWidth readOnly value={i18next.t("page.private.dashboard.propertyManagement.contractType." + proof.contractProvisionInterval.contract.type)} />
                                                </GridElement>
                                                <GridElement columnStart={4} columnEnd={5}>
                                                    <Input icon="user-tie" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.contractPartner")} adjustWidth readOnly value={proof.contractProvisionInterval.contract.serviceProvider ? proof.contractProvisionInterval.contract.serviceProvider.name : proof.contractProvisionInterval.contract.partnerName} />
                                                </GridElement>
                                                <GridElement columnStart={1} columnEnd={5}>
                                                    <Modal.Divider title={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.serviceCycle")} />
                                                </GridElement>
                                                <GridElement columnStart={1} columnEnd={3}>
                                                    <Input icon="tag" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.serviceCycleName")} adjustWidth readOnly value={proof.contractProvisionInterval.name} />
                                                </GridElement>
                                                <GridElement columnStart={3} columnEnd={4}>
                                                    <Input icon="history" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.interval")} adjustWidth readOnly value={`${proof.contractProvisionInterval.intervalAmount} ${[
                                                        { key: "days", title: i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.intervalType.days") },
                                                        { key: "weeks", title: i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.intervalType.weeks") },
                                                        { key: "months", title: i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.intervalType.months") },
                                                        { key: "years", title: i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.intervalType.years") }].find(d => d.key === proof.contractProvisionInterval.intervalType).title}`} />
                                                </GridElement>
                                                <GridElement columnStart={4} columnEnd={5}>
                                                    <Input icon="hourglass-start" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.start")} adjustWidth readOnly value={proof.contractProvisionInterval.startingAt || null} />
                                                </GridElement>
                                                <GridElement columnStart={1} columnEnd={5}>
                                                    <Modal.Divider title={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.proofOfPerformance")} />
                                                </GridElement>
                                                <GridElement columnStart={1} columnEnd={2}>
                                                    <Input icon="tag" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.proofOfPerformanceName")} adjustWidth readOnly value={proof.name} />
                                                </GridElement>
                                                <GridElement columnStart={2} columnEnd={3}>
                                                    <Input icon="calendar-day" label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.date")} adjustWidth readOnly value={moment(proof.date, "DD.MM.YYYY").format("DD.MM.YYYY")} />
                                                </GridElement>
                                                <GridElement columnStart={3} columnEnd={4}>
                                                    <Input icon={proof.continueInterval ? "check" : "times"} label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.continueInterval")} adjustWidth readOnly value={proof.continueInterval ? "Ja" : "Nein"} />
                                                </GridElement>
                                                <GridElement columnStart={4} columnEnd={5}>
                                                    <Input icon={proof.continueFromDate ? "check" : "times"} label={i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.serviceDateInterval")} adjustWidth readOnly value={proof.continueFromDate ? "Ja" : "Nein"} />
                                                </GridElement>
                                            </Grid>
                                        </div>
                                    </Section.Item>
                                    <Section.Item title={i18next.t("page.private.contracts.performanceRecords.prioritySection.comments.sectionElementTitle")} icon="comments-alt" amount={proof.comments.length}>
                                        <Comments comments={proof.comments} proofId={proof.id} />
                                    </Section.Item>
                                    <Section.Item title={i18next.t("page.private.contracts.performanceRecords.prioritySection.fileBox.sectionElementTitle")} icon="folder-tree" amount={proof.files.length}>
                                        <FileManager contractProvisionProofId={proof.id} title={i18next.t("page.private.contracts.performanceRecords.prioritySection.fileBox.performanceDocuments")} icon="file-certificate" scope="contractProvisionProof" onPathChange={() => {}} />
                                    </Section.Item>
                                </Section>
                            </GridElement>)}
                        </div>
                {/*</Section.Item>

                    {/*<Section.Item title={"Tabellenansicht"} icon="table">


                        <GridElement styled title={i18next.t("page.private.contracts.contractsTable.gridElementTitle")} icon="file-contract">
                            {this.state.proofs.filter(proofFilter).sort(proofSort).map((proof, index) =>
                                <Table
                                    head={[


                                        { title: i18next.t("page.private.contracts.contractsTable.table.title"), width: "15%", column: "title" },
                                        { title: i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.branchInformationName"), width: "15%", column: "subsidiaries" },
                                        { title: i18next.t("page.private.contracts.contractsTable.table.type"), width: "12.5%", column: "type" },
                                        { title: i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.abbreviation"), width: "10%", column: "workSection" },
                                        { title: i18next.t("page.private.contracts.performanceRecords.prioritySection.basicData.interval"), width: "7.5%", column: "duration" },
                                        { title: i18next.t("page.private.contracts.missingFiles.performanceTable.files"), width: "10%", column: "files" },
                                        { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.due"), width: "15%", column: "due" },
                                    ]}
                                    onItemClick={contract => this.props.history.push(`/contract/${contract.id}`)}
                                    items={this.state.contracts ? this.state.contracts.map(contract => ({

                                        title: contract.title,

                                        type: contract.type,

                                        subsidiaries: <Button thick title="Anzeigen" icon="map-marker" stopPropagation onClick={() => {
                                            this.showSubsidiariesModal.open(contract.id)
                                        }}/>,
                                        duration: `${proof.contractProvisionInterval.intervalAmount} ${[{ key: "days", title: i18next.t("page.private.contracts.contractProvisionIntervals.intervalTypes.days") },
                                            { key: "weeks", title: i18next.t("page.private.contracts.contractProvisionIntervals.intervalTypes.weeks") },
                                            { key: "months", title: i18next.t("page.private.contracts.contractProvisionIntervals.intervalTypes.months") },
                                            { key: "years", title: i18next.t("page.private.contracts.contractProvisionIntervals.intervalTypes.years") }].find(d => d.key === proof.contractProvisionInterval.intervalType).title}`,

                                        workSection: proof.subsidiary.label,

                                        files: <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                            <Button noIconMargin icon="folder-tree" thick stopPropagation onClick={() => this.openContractProvisionProofDocumentsModal.open(provisionProof.id)} />
                                        </div>,
                                        due: <b>{moment(proof.date, "DD.MM.YYYY").fromNow()} ({moment(proof.date, "DD.MM.YYYY").format("DD.MM.YYYY")})</b>,

                                    })) : null}

                                    contextItems={[
                                        { title: i18next.t("page.private.contracts.contractsTable.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: contract => window.open(`/contract/${contract.id}`, '_blank')},

                                    ]}
                                    contractId={this.state.selectedContractId}
                                />
                            )}
                        </GridElement>
                    </Section.Item>
                </Section>*/}
            </Section.Item>
        </>
    }
})


class Comments extends Component {
    state = { comments: [], comment: null, loading: false }

    constructor(props) {
        super(props)

        this.save = this.save.bind(this)
        this.refresh = this.refresh.bind(this)

        this.refresh().then(data => {
            this.setState({
                comments: data.data.ContractProvisionProofComments.nodes
            })
        })
    }

    refresh() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    ContractProvisionProofComments(filter: { contractProvisionProofId_eq: $id }) {
                        nodes {
                            id
                            date
                            content
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.proofId
            }
        })
    }

    save() {
        this.setState({ loading: true })

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($contractProvisionProofId: ID!, $content: String!) {
                    createContractProvisionProofComment(contractProvisionProofId: $contractProvisionProofId, content: $content)
                }
            `,
            variables: {
                contractProvisionProofId: this.props.proofId,
                content: this.state.comment
            }
        }).then(data => {
            if(data.data.createContractProvisionProofComment) {
                this.refresh().then(data => {
                    this.setState({ comment: null, comments: data.data.ContractProvisionProofComments.nodes, loading: false })
                })
            }
        })
    }

    render() {
        return <>
            <DeleteContractProvisionProofCommentModal instance={ref => this.deleteContractProvisionProofCommentModal = ref} refresh={() => this.refresh().then(data => {
                this.setState({
                    comments: data.data.ContractProvisionProofComments.nodes
                })
            })} />
            <div style={{ display: "flex", alignItems: "center", padding: "32px 32px 0 32px" }}>
                <Input disabled={this.state.loading} label={i18next.t("page.private.contracts.performanceRecords.prioritySection.comments.searchPlaceholder")} adjustWidth icon="font" value={this.state.comment} onChange={comment => this.setState({ comment })} />
                <div style={{ marginLeft: 16 }}>
                    <Button loading={this.state.loading} icon="comments-alt" title={i18next.t("page.private.contracts.performanceRecords.prioritySection.comments.commentButton")} thick onClick={this.save} disabled={scanmetrix.checkPermission("ContractProvisionProofComment") < 2 || !this.state.comment || (this.state.comment && this.state.comment.trim().length === 0)} />
                </div>
            </div>
            <StyledComments>
                {this.state.comments.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map((comment, id) => <div key={id} className="comment">
                    <div className="avatar">
                        <div className="pic">
                            <Avatar size="32" email={scanmetrix.disableGravatar ? null : comment.user.email} name={`${comment.user.firstName} ${comment.user.lastName}`} className="avatar" />
                        </div>
                        <p>{comment.user.firstName} {comment.user.lastName}</p>
                    </div>
                    {scanmetrix.session.id === comment.user.id && <div className="delete">
                        <i className="far fa-trash" onClick={() => this.deleteContractProvisionProofCommentModal.open(comment.id)} />
                    </div>}
                    <div className="body">
                        <div className="date">
                            {moment(comment.date).fromNow()}
                        </div>
                        <div className="content">
                            {comment.content}
                        </div>
                    </div>
                </div>)}
            </StyledComments>
        </>
    }
}

const StyledComments = styled.div`
    padding: 32px;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .comment {
        display: grid;
        grid-template-columns: 1fr 32px;
        grid-gap: 0 16px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 16px;
        border-radius: 4px;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.075);
    }

    .avatar {
        display: flex;
        align-items: center;
        grid-column-start: 1;
        grid-column-end: 2;
        height: 32px;

        .pic {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 8px;
        }
    }

    .delete {
        grid-column-start: 2;
        grid-column-end: 3;
        display: flex;
        align-items: center;
        height: 32px;
        justify-content: flex-end;

        i {
            cursor: pointer;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        grid-column-start: 1;
        grid-column-end: 3;

        .date {
            margin-bottom: 16px;
            margin-top: 16px;
            font-size: 0.8em;
            font-weight: 400;
            opacity: 0.75;
        }

        .content {
            font-size: 18px;
            line-height: 26px;
        }
    }
`
