import React, { Component } from "react"
import { Modal, Input, Button, Checkbox } from "scanmetrix-components"
import styled from "styled-components"
import i18next from "i18next";

const List = styled.div`
    margin-bottom: 16px;
  
    li {
        margin-left: 16px;
    }
`

export default class extends Component {
    state = { email: null, accepted: false, loading: false }

    render() {
        return <Modal maxWidth="650px" minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ email: this.props.email || null, accepted: false, loading: false })}>
            <Modal.Head title={i18next.t("page.private.serviceProvider.inviteServiceProviderModal.title")} icon="address-book" />
            <Modal.Body padding={24}>
                <div style={{ width: "650px", lineHeight: "1.6em", marginBottom: "32px" }}>
                    <b style={{ color: "#3b97d3" }}>{i18next.t("page.private.serviceProvider.inviteServiceProviderModal.text1")} </b>{i18next.t("page.private.serviceProvider.inviteServiceProviderModal.text2")}<br />{i18next.t("page.private.serviceProvider.inviteServiceProviderModal.text3")}
                    <br /><br />
                    {i18next.t("page.private.serviceProvider.inviteServiceProviderModal.text4")}
                    <List>
                        <li>{i18next.t("page.private.serviceProvider.inviteServiceProviderModal.list1")}</li>
                        <li>{i18next.t("page.private.serviceProvider.inviteServiceProviderModal.list2")}</li>
                    </List>
                    {i18next.t("page.private.serviceProvider.inviteServiceProviderModal.list3")}
                </div>
                <Modal.Divider title={i18next.t("page.private.serviceProvider.inviteServiceProviderModal.serviceProvider")} />
                <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.serviceProvider.inviteServiceProviderModal.serviceProvideremail")} icon="envelope-open" value={this.state.email} onChange={email => this.setState({ email })} />
                <div style={{ marginTop: 16 }}>
                    <Checkbox disabled={this.state.loading} label={i18next.t("page.private.serviceProvider.inviteServiceProviderModal.noteCheckbox")} value={this.state.accepted} onChange={accepted => this.setState({ accepted })} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => [
                <Button disabled={!this.state.accepted || !this.state.email} loading={this.state.loading} thick title={i18next.t("page.private.serviceProvider.inviteServiceProviderModal.button")} icon="paper-plane" onClick={() => {
                    this.setState({ loading: true })

                    fetch(scanmetrix.backendURL + "/serviceprovider/" + this.props.serviceProviderId + "/invite/" + this.state.email, {
                        method: "POST",
                        credentials: "include"
                    }).then(result => result.json()).then(data => {
                        if(data.status === "SUCCESSFUL") instance.close()
                        else this.setState({ loading: false })
                    })
                }} />
            ]} />
        </Modal>
    }
}
