import React, { Component } from "react"
import { Page, Breadcrumbs, GridElement, Table, Button } from "scanmetrix-components"
import i18next from "i18next"

import CreatePurchaseOrderModal from "./modals/CreatePurchaseOrderModal";
import DuplicateOrderModal from "./modals/DuplicateOrderModal";

const getType = type => {
    switch(type) {
        case "construction":
            return {title: i18next.t("page.private.workOrders.switchCases.constructionProject"), icon: "digging", color: "#e67e22"}
        case "goods":
            return {title: i18next.t("page.private.workOrders.switchCases.purchaseGoods"), icon: "truck-loading", color: "#1abc9c"}
        case "service":
            return {title: i18next.t("page.private.workOrders.switchCases.service"), icon: "hard-hat", color: "#3498db"}
        case "other":
            return {title: i18next.t("page.private.workOrders.switchCases.other"), icon: "question", color: "#34495e"}
    }
}


const getStatus = status => {
    switch(status) {
        case "draft":
            return {title: i18next.t("page.private.workOrders.switchCases.draft"), icon: "drafting-compass", color: "#3b97d3"}
        case "approval":
            return {title: i18next.t("page.private.workOrders.switchCases.internalRelease"), icon: "pencil-ruler", color: "#E67E22"}
        case "commission":
            return {title: i18next.t("page.private.workOrders.switchCases.commissioningPending"), icon: "paper-plane", color: "#1abc9c"}
        case "confirmation":
            return {title: i18next.t("page.private.workOrders.switchCases.commissionedWaitingAB"), icon: "check", color: "#34495e"}
        case "execution":
            return {title: i18next.t("page.private.workOrders.switchCases.commissionedWaitingCompletion"), icon: "user-check", color: "#34495e"}
        case "done":
            return {title: i18next.t("page.private.workOrders.switchCases.completed"), icon: "check-double", color: "#2ECC71"}
    }
}


export default class extends Component {
    state = { purchaseOrders: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    PurchaseOrders {
                        nodes {
                            id
                            title
                            number
                            status
                            date
                            type
                            positions
                            letterHead {
                                id
                                name
                            }
                            subsidiary {
                                id
                                name
                                label
                                locationLat
                                locationLng
                                address {
                                    postalCode
                                    city
                                }
                            }
                            serviceProvider {
                                id
                                name
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({
                purchaseOrders: data.data.PurchaseOrders.nodes
            })
        })
    }

    render() {
        return <Page {...this.props}>
            <CreatePurchaseOrderModal instance={ref => this.createPurchaseOrderModal = ref} refresh={this.fetch} />
            <DuplicateOrderModal refresh={() => this.fetch()} history={this.props.history} instance={ref => this.showDuplicateOrderModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "comment-alt-check",
                    title: i18next.t("page.private.workOrders.breadCrumbsTitle")
                }
            ]} />
            <GridElement styled title={i18next.t("page.private.workOrders.gridElementTitle")} icon="comment-alt-check" rightContent={<Button thick icon="comment-alt-check" title={i18next.t("page.private.workOrders.button")} disabled={scanmetrix.checkPermission("PurchaseOrder") < 2} onClick={() => this.createPurchaseOrderModal.open()} />}>
                <Table
                    head={[
                        { title: i18next.t("page.private.workOrders.table.date"), width: "7.5%", column: "date" },
                        { title: i18next.t("page.private.workOrders.table.status"), width: "15%", column: "status" },
                        { title: i18next.t("page.private.workOrders.table.number"), width: "7.5%", column: "number" },
                        { title: i18next.t("page.private.workOrders.table.letterHead"), width: "7.5%", column: "letterHead" },
                        { title: i18next.t("page.private.workOrders.table.type"), width: "12.5%", column: "type" },
                        { title: i18next.t("page.private.workOrders.table.branch"), width: "15%", column: "subsidiary" },
                        { title: i18next.t("page.private.workOrders.table.serviceProvider"), width: "12.5%", column: "serviceProvider" },
                        { title: i18next.t("page.private.workOrders.table.title"), width: "15%", column: "title" },
                        { title: i18next.t("page.private.workOrders.table.sum"), width: "7.5%", column: "sum" },
                    ]}
                    onItemClick={purchaseOrder => this.props.history.push(`/purchaseorder/${purchaseOrder.id}`)}
                    items={this.state.purchaseOrders ? this.state.purchaseOrders.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(purchaseOrder => {
                        return ({
                            ...purchaseOrder,
                            status: <><i className={`fa-duotone fa-${getStatus(purchaseOrder.status).icon}`} style={{ color: getStatus(purchaseOrder.status).color, marginRight: 8 }} /> {getStatus(purchaseOrder.status).title}</>,
                            type: <><i className={`fa-duotone fa-${getType(purchaseOrder.type).icon}`} style={{ color: getType(purchaseOrder.type).color, marginRight: 8 }} /> {getType(purchaseOrder.type).title}</>,
                            letterHead: purchaseOrder.letterHead ? purchaseOrder.letterHead.name : null,
                            serviceProvider: purchaseOrder.serviceProvider.name,
                            date: moment(purchaseOrder.date).format("DD.MM.YYYY"),
                            subsidiary: purchaseOrder.subsidiary.name + (purchaseOrder.subsidiary.label ? (" (" + purchaseOrder.subsidiary.label + ")") : ""),
                            sum: formatter.format(((typeof purchaseOrder.positions === "string" ? JSON.parse(purchaseOrder.positions) : purchaseOrder.positions) || []).map(item => item.amount * item.price).reduce((a, b) => a + b, 0))
                        })
                    }) : null}
                    contextItems={[
                        //{ title: i18next.t("page.private.contracts.contractsTable.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: contract => window.open(`/contract/${contract.id}`, '_blank')},
                        { title: "Duplizieren", icon: "fa-duotone fa-copy", onClick: purchaseOrder => this.showDuplicateOrderModal.open(purchaseOrder) },
                    ]}
                />
            </GridElement>
        </Page>
    }
}

export { getType, getStatus }

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})
