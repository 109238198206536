import React, { Component } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"

const StyledBreadcrumbs = styled.div`
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  
  @media screen and (max-width: 1600px) {
    margin: ${props => props.margin ? props.margin : "0 0 32px 0" };
  }
`

const Breadcrumb = styled.div`
  display: flex;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.25);
  background: white;
  margin-right: 2px;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  cursor: ${props => props.link ? "pointer" : "default"};
  transition: opacity 0.3s;
  user-select: none;
  
   &:hover {
       opacity: ${props => props.link ? 0.75 : 1};
   }
  
  &:first-child {
      border-radius: 5px 0 0 5px;
  }
  
  &:last-child {
      border-radius: 0 5px 5px 0;
  }
  
  &:only-child {
      border-radius: 5px;
  }
  
  i {
      margin-right: 8px;
  }
`

export default withRouter(class Breadcrumbs extends Component {
    render() {
        return <StyledBreadcrumbs margin={this.props.margin}>
            {this.props.values.map((breadcrumb, index) => <Breadcrumb link={breadcrumb.link} onClick={() => {
                if(breadcrumb.link) this.props.history.push(breadcrumb.link, breadcrumb.state)
            }} key={index}>
                {breadcrumb.icon && <i className={`far fa-${breadcrumb.icon}`} />}
                <p className="title">{breadcrumb.title}</p>
            </Breadcrumb>)}
        </StyledBreadcrumbs>
    }
})

