import styled from "styled-components"

export default styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  background: white;
  border-radius: 16px;
  position: relative;
  user-select: none;
  box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 20px 0px 20px 0px;
 
  
  
  .improve {
  
      position: absolute;
      background: #3b97d3;
      color: white;
      bottom: 0;
      right: 0;
      font-size: 14px;
      border-radius: 5px 0 0 0;
      padding:  4px 4px 4px 4px;
      cursor: pointer;
      transition: opacity 0.3s;
     //width: 300px;
      &:hover {
          opacity: 0.75;
      }
  }
  
  .info {
    padding-left: 48px;
    box-sizing: border-box;
    
    .title {
      font-size: 1.6em;
    }
    
    .subtitle {
      font-size: 1.2em;
      margin-top: 16px;
    }
    
    ul {
      li {
        font-size: 0.9em;
        opacity: 0.75;
        list-style-type: none;
        box-sizing: border-box;
        padding-left: 8px;
      }
    }
  }
  
  .right {
    position: relative;
    margin-right: 16px;
    box-sizing: border-box;
    margin-top: 16px;
    
    text.apexcharts-text.apexcharts-datalabel-value {
      font-family: "Roboto", sans-serif !important;
      font-weight: bold;
    }
  }
  
  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.2em;
  }
  
  @media screen and (max-width: 2100px) {
    zoom: 0.9;
  }
  
  @media screen and (max-width: 1920px) {
    
    .info {
      padding-left: 24px !important;
    }
    
    .right {
      margin-right: 0 !important;
    }
  }
  
  @media screen and (max-width: 1919px) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 2; 
  }
`
