import React, { Component } from 'react'
import styled from 'styled-components'
import { Page, Modal, Button, Input, Breadcrumbs, Grid, GridElement, Section, Table } from 'scanmetrix-components'
import Dropzone from 'react-dropzone'
import { v4 as uuid } from 'uuid'

import VehicleCar from './styled/VehicleCar'
import ViewPictureModal from './tabs/General/modals/ViewPictureModal'

const LeftWrapper = styled.div`
  width: 100%;
`

const Status = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;

  i, p {
    color: white;
  }

  i {
    font-size: 2.2em;
    margin-right: 16px;
  }
  
  p {
    font-size: 1.3em;
  }
`

const FlawInformation = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  box-sizing: border-box;
`

const CarModelBody = styled.div`
  width: 100%;
  height: 256px;
  background: radial-gradient(at bottom left,rgb(220 220 220) 5%,rgba(235,238,243,1) 75%);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
`

const UploadBody = styled.div`
  flex: 1;
  width: 100%;
  height: 256px;
  border: 3px dashed rgba(0, 0, 0, .25);
  color: rgba(0, 0, 0, .25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 250ms ease;
  position: relative;
  user-select: none;
  outline: none;

  &:hover {
    opacity: 0.75;
  }
  
  input {
    display: none;
  }

  i {
    font-size: 2em;
    margin-bottom: 16px;
  }

  p {
    font-size: 1em;
    text-align: center;
    width: 75%;
  }
`

const ImageBox = styled.div`
  width: 100%;
  height: 256px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 250ms ease;
  
  &:hover {
    opacity: 0.75;
    
    .delete-hover {
      opacity: 1;
    }
  }
  
  img {
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
    box-sizing: border-box;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
    user-drag: none;
  }
  
  .delete-hover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease;
    opacity: 0;
    z-index: 15;
    
    i {
      padding: 16px;
      border-radius: 125px;
      background: #E74C3C;
      color: white;
      font-size: 2em;
    }
  }
`

const ProgressBody = styled.div`
  width: 100%;
  margin-bottom: 32px;
  display: grid;
  grid-gap: 48px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  
  .box {
    width: 100%;
    background: white;
    border: 3px solid rgba(0, 0, 0, .4);
    border-radius: 10px;
    padding: 16px;
    box-sizing: border-box;
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr auto;
    user-select: none;
    
    h1 {
      margin-bottom: 16px;
      font-size: 1.4em;
    }
    
    .progress-description {
      margin-bottom: 16px;
    }
    
    &.active {
      border: 3px solid #3B97D3;
    }
    
    &::after {
      content: '\f061';
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 1.4em;
      font-family: "Font Awesome 5 Pro";
      background: #3B97D3;
      box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
      border-radius: 125px;
      position: absolute;
      top: 50%;
      right: -44px;
      transform: translateY(-50%);
    }
    
    &:last-child::after {
      display: none;
    }
  }
`

const InsuranceBody = styled.div`
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(4, auto);
  position: relative;
  
  .logo-view {
    padding-bottom: 16px;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    
    img {
      width: auto;
      height: 100px;
      pointer-events: none;
      user-select: none;
      user-drag: none;
    }
  }

  .relink {
    font-size: 12pt;
    text-decoration: none;
    color: rgba(0, 0, 0, .25);
    transition: all 250ms ease;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    user-select: none;

    &:hover {
      opacity: 0.75;
    }
  }
`

function getBackground(status) {
    if (status === 'no-progress') return 'linear-gradient(45deg,#c0392b,#ff6252)'
    if (status === 'in-progress') return 'linear-gradient(45deg, rgb(230, 126, 34), rgb(250, 182, 121))'
    if (status === 'repaired') return 'linear-gradient(45deg,#16a085,#27dbb8)'

    return 'linear-gradient(45deg,#7d7d7d,#9e9e9e)'
}

class StatusBox extends Component {
    render() {
        return <Status style={{ background: getBackground(this.props.status) }}>
            <i className='far fa-times-circle' />
            <p>Nicht repariert</p>
        </Status>
    }
}

class InputMap extends Component {
    render() {
        return <>
            { this.props.data.map(( item, index ) => {
                return <GridElement key={index} columnStart={item.columnStart} columnEnd={item.columnEnd}>
                    <Input adjustWidth label={item.label} icon={item.icon} value={item.value} readOnly />
                </GridElement>
            }) }
        </>
    }
}

class VirtualCar extends Component {
    state = {  }

    render() {
        return <CarModelBody>
            <VehicleCar height={256} carPos={[0, 0.375, 0]} />
        </CarModelBody>
    }
}

class ProgressView extends Component {
    state = {  }

    render() {
        return <ProgressBody>
            <div className='box active'>
                <h1>Keine Reparatur geplant</h1>
                <p className='progress-description'>Kontaktieren Sie den Versicherer, um den Schaden zu reparieren.</p>

                <Button thick title='Versicherer kontaktiert' icon='arrow-right' />
            </div>
            <div className='box'>
                <h1>Reparatur eingeleitet</h1>
                <p className='progress-description'>Der Fall kann nach erfolgreicher Schadensbehebung abgeschlossen werden.</p>

                <Button disabled thick title='Versicherer kontaktiert' icon='arrow-right' />
            </div>
            <div className='box'>
                <h1>Reparatur abgeschlossen</h1>
                <p className='progress-description'>Das Fahrzeug wurde repariert.</p>

                <Button disabled thick title='Versicherer kontaktiert' icon='arrow-right' />
            </div>
        </ProgressBody>
    }
}

class Insurance extends Component {
    state = { logoURL: null }

    constructor(props) {
        super(props)

        fetch("https://autocomplete.clearbit.com/v1/companies/suggest?query=" + props.insurer).then(result => result.json()).then(result => {
            if(result.length) this.setState({ logoURL: result[0].logo })
        })
    }

    render() {
        return <InsuranceBody insurer={this.props.insurer}>
            <div className='logo-view'>
                <img src={this.state.logoURL} alt={this.props.insurer} />
            </div>

            <Input adjustWidth label='Versicherer' icon='user-shield' value={this.props.insurer} />
            <Input adjustWidth label='Versicherungstyp' icon='tag' value={this.props.insuranceType} />
            <Input adjustWidth label='Versicherungsnummer' icon='barcode' value={this.props.insuranceNumber} />
            <Input adjustWidth label='E-Mail' icon='at' value={this.props.mail} />
            <Input adjustWidth label='Telefonnummer' icon='phone' value={this.props.phone} />
            <Input adjustWidth label='Telefax' icon='fax' value={this.props.fax} />

            {this.state.logoURL !== null && <a className='relink' href='https://clearbit.com' target='_blank'>Logo provided by Clearbit</a>}
        </InsuranceBody>
    }
}

export default class extends Component {
    state = { repairStatus: 'no-progress', galleryEditable: false, flawEditable: false, loading: false, fileName: null, fileSize: null, files: [] }

    render() {
        return <Page {...this.props}>
            <ViewPictureModal instance={ref => this.manualGalleryModal = ref} src='https://images.unsplash.com/photo-1613575607467-83d3ad295710?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80' />
            <Breadcrumbs values={[
                {
                    icon: "car",
                    title: "Fuhrpark",
                    link: "/carpool"
                },
                {
                    icon: "car-garage",
                    title: "Fahreug: XYZ",
                    link: "/carpool/car"
                },
                {
                    icon: "bolt",
                    title: `Mangel`
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title="Mangel" icon="bolt">
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 32 }}>
                        <Button thick secondary title='Mangel entfernen' icon='times' onClick={() => alert('Delete me')} style={{ marginRight: 16 }} />
                        { !this.state.flawEditable && <Button thick title='Angaben bearbeiten' icon='pencil-alt' onClick={() => this.setState({ flawEditable: true, galleryEditable: true })} /> }
                        { this.state.flawEditable && <Button thick green title='Änderungen speichern' icon='check' onClick={() => this.setState({ flawEditable: false, galleryEditable: false })} /> }
                    </div>

                    <Grid gap="32px" columns={[ "minmax(224px, 1fr)", "minmax(0, 3fr)" ]}>
                        <LeftWrapper>
                            <Grid columns={[ "minmax(0, 1fr)" ]} gap="32px">
                                <StatusBox status={this.state.repairStatus} />
                                <GridElement styled title='Schadenserfassung' icon='car-crash'>
                                    <VirtualCar />

                                    <FlawInformation>
                                        <Input readOnly={!this.state.flawEditable} adjustWidth margin="0 0 16px 0" label='Fahrer' icon='user' />
                                        <Input readOnly={!this.state.flawEditable} adjustWidth margin="0 0 16px 0" label='Mangel' icon='font' />
                                        <Input readOnly={!this.state.flawEditable} adjustWidth margin="0 0 16px 0" label='Ursache' icon='bolt' />
                                        <Input readOnly={!this.state.flawEditable} adjustWidth margin="0 0 16px 0" label='Schaden (Position)' icon='crosshairs' />
                                        <Input readOnly={!this.state.flawEditable} adjustWidth margin="0 0 16px 0" label='Beschreibung' icon='font' />
                                        <Input readOnly={!this.state.flawEditable} adjustWidth margin="0 0 16px 0" label='Erfasst am' icon='calendar-times' date />
                                    </FlawInformation>
                                </GridElement>
                            </Grid>
                        </LeftWrapper>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <ProgressView status={this.state.repairStatus} />

                            <div style={{ marginBottom: 32 }}>
                                <GridElement styled title='Fahrzeug Informationen' icon='car'>
                                    <Grid gap="16px" padding="32px" columns={[ "repeat(6, minmax(0, 1fr))" ]}>
                                        <InputMap data={
                                            [
                                                { columnStart: 1, columnEnd: 3, label: 'Name', icon: 'font', value: '' },
                                                { columnStart: 3, columnEnd: 5, label: 'Fahrgestellnummer', icon: 'list-ol', value: '9876543210' },
                                                { columnStart: 5, columnEnd: 7, label: 'Kennzeichen', icon: 'tags', value: 'MH SX 5876' },
                                                { columnStart: 1, columnEnd: 3, label: 'Hersteller', icon: 'industry', value: 'Tesla' },
                                                { columnStart: 3, columnEnd: 5, label: 'Modell', icon: 'cubes', value: 'Model S' },
                                                { columnStart: 5, columnEnd: 7, label: 'Baujahr', icon: 'calendar', value: 'Oktober 2020' },
                                                { columnStart: 1, columnEnd: 3, label: 'Erstzulassung', icon: 'calendar-day', value: '01.01.2021' },
                                                { columnStart: 3, columnEnd: 5, label: 'Sprittyp', icon: 'gas-pump', value: 'Elektro' },
                                                { columnStart: 5, columnEnd: 7, label: 'Typ', icon: 'info', value: 'Elektrofahrzeug' },
                                                { columnStart: 1, columnEnd: 4, label: 'Beschreibung', icon: 'pencil', value: '' },
                                                { columnStart: 4, columnEnd: 7, label: 'Getriebeart', icon: 'steering-wheel', value: 'Voll-Automatik' },
                                            ]
                                        } />
                                    </Grid>
                                </GridElement>
                            </div>

                            <div style={{ marginBottom: 32 }}>
                                <GridElement styled title='Versicherung' icon='user-shield'>
                                    <Insurance
                                        insurer='LVM Versicherung'
                                        insuranceType='Vollkasko'
                                        insuranceNumber='VK-1-5897364'
                                        mail='info@scanmetrix.fm'
                                        phone='0172 8779946'
                                        fax=''
                                    />
                                </GridElement>
                            </div>

                            <GridElement styled title='Galerie' icon='images'>
                                <Grid gap='16px' padding='16px' columns={[ '1fr', '1fr', '1fr', '1fr' ]}>
                                    {this.state.galleryEditable && <Dropzone accept={[ ".jpg", ".jpeg", ".png" ]} multiple={true} onDrop={acceptedFiles => {
                                        if(acceptedFiles.length && !this.state.loading) {
                                            const files = acceptedFiles

                                            this.setState({ files: this.state.files.concat(files.map(file => ({ id: uuid(), fileName: file.name, fileSize: file.size, imageFile: file }))) })
                                        }
                                    }}>
                                        {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody tabIndex={0} {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef", className: 'dropzone' })}>
                                            <input {...getInputProps()} tabIndex={-1} accept='image/jpeg, image/jpg, image/png' multiple={true} type='file' autoComplete='off' />

                                            <i className="far fa-upload" />
                                            <p className="title">Legen Sie hier beliebige Bilder des Mangels im JPG/JPEG oder PNG Format ab, oder klicken Sie hier um den Dateibrowser zu öffnen.</p>
                                        </UploadBody>}
                                    </Dropzone> }
                                    { this.state.files.length > 0 ? this.state.files.map((item, index) => <ImageBox key={index} onClick={ () => !this.state.galleryEditable ? (this.manualGalleryModal.open()) : null }>
                                        <img src={URL.createObjectURL(item.imageFile)} />
                                        {this.state.galleryEditable && <div className='delete-hover' onClick={() => this.setState({ files: this.state.files.filter(file => file.id !== item.id) })}>
                                            <i className='far fa-trash' />
                                        </div> }
                                    </ImageBox>) : !this.state.galleryEditable && <img style={{ width: '100%', height: '256px', objectFit: 'cover', userDrag: 'none', pointerEvents: 'none', userSelect: 'none' }} src="/noImage.jpg" /> }
                                </Grid>
                            </GridElement>
                        </div>
                    </Grid>
                </Section.Item>
            </Section>
        </Page>
    }
}