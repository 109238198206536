import React, { Component } from "react"
import { Modal, Input } from "scanmetrix-components"

export default class extends Component {
    state = {}

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={room => this.setState(room)}>
            <Modal.Head title="Raum aktualisieren" icon="table-layout" />
            <Modal.Body padding={24}>
                <Input.PostProvider value={{
                    url: `/v2/room/${this.state.id}`,
                    data: this.state,
                    onSave: () => this.props.refresh()
                }}>
                    <div style={{
                        display: "grid",
                        gridGap: "32px",
                        gridTemplateColumns: "1fr"
                    }}>
                        <Input label="Name" required icon="tag" field="name" adjustWidth/>
                        <Input format={value => `${value} m²`} float label="Fläche in m²" required icon="table-layout" field="area" adjustWidth />
                    </div>
                </Input.PostProvider>
            </Modal.Body>
        </Modal>
    }
}
