import React, { Component } from "react"
import { Page, Section, Breadcrumbs, GridElement, Button } from "scanmetrix-components"
import styled from "styled-components"
import DeleteRoleModal from "./modals/DeleteRoleModal"
import UpdateRoleModal from "./modals/UpdateRoleModal"
import {Switch} from "../../../components";
import i18next from "i18next";

export default class extends Component {
    state = {
        role: null,
        structure: {},
        grants: []
    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()

        fetch(scanmetrix.backendURL + "/permission-structure", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(result => {
            this.setState({ structure: result })
        })
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    Role(id: $id) {
                        id
                        name
                        icon
                        grants {
                            id
                            value
                            grant
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.roleid
            }
        }).then(data => {
            this.setState({ role: data.data.Role, grants: data.data.Role.grants })
        })
    }

    render() {
        if(!this.state.role) return null
        const role = this.state.role

        return <Page {...this.props}>
            <DeleteRoleModal history={this.props.history} instance={ref => this.deleteRoleModal = ref} />
            <UpdateRoleModal history={this.props.history} instance={ref => this.updateRoleModal = ref} refresh={() => this.fetch()} />
            <Breadcrumbs values={[
                {
                    icon: "user",
                    title: i18next.t("page.private.role.users"),
                    link: "/users"
                },
                {
                    icon: "shield-halved",
                    title: i18next.t("page.private.role.roles")
                },
                {
                    title: role.name,
                    icon: role.icon
                }
            ]} />
            <GridElement styled title={i18next.t("page.private.role.gridElementTitle")} icon="shield-halved" rightContent={
                <div style={{ display: "grid", gridTemplateColumns: "max-content max-content", gridGap: "16px" }}>
                    <Button disabled={!scanmetrix.session.administrative} thick icon="pencil" title={i18next.t("page.private.role.roleUpadteButton")} onClick={() => this.updateRoleModal.open(role)} />
                    <Button disabled={!scanmetrix.session.administrative} thick secondary icon="trash" title={i18next.t("page.private.role.roleDeleteButton")} onClick={() => this.deleteRoleModal.open(role)} />
                </div>}>
            </GridElement>
            <div style={{ marginTop: 16 }}>
                <Section bodyPadding="16px 0 0 0">
                    {Object.keys(this.state.structure).map(categoryName => {
                        const category = this.state.structure[categoryName]
                        const { title, icon, grants } = category

                        return <Section.Item key={categoryName} title={title} icon={icon}>
                            <div style={{ display: "grid", width: "min-content", float: "right", gridTemplateColumns: "auto repeat(4, 80px)", transform: "translateY(16px)", gridGap: 16, padding: 16, marginTop: -64, fontSize: "14px", userSelect: "none", fontWeight: 600, color: "rgba(0, 0, 0, 0.75)" }}>
                                <div></div>
                                <div>{i18next.t("page.private.role.enableButtons.read")}</div>
                                <div>{i18next.t("page.private.role.enableButtons.create")}</div>
                                <div>{i18next.t("page.private.role.enableButtons.change")}</div>
                                <div>{i18next.t("page.private.role.enableButtons.delete")}</div>
                            </div>
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16 }}>
                                {grants.map(grant => {
                                    const found = this.state.grants.find(g => g.grant === grant.name)

                                    return <Grant value={found ? found.value : 0} roleId={role.id} key={grant.name} data={grant} refresh={value => {
                                        const grants = this.state.grants.filter(gg => value === 0 ? gg.grant !== grant.name : true).map(t => {
                                            if(t.grant === grant.name) return ({
                                                ...t,
                                                value
                                            })

                                            return t
                                        })

                                        this.setState({ grants })
                                    }} />
                                })}
                            </div>
                        </Section.Item>
                    })}
                </Section>
            </div>
        </Page>
    }
}

class Grant extends Component {
    state = {
        value: 0
    }

    constructor(props) {
        super(props)

        this.state.value = props.value
    }

    set(value) {
        this.setState({ value })

        this.props.refresh(value)

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($roleId: ID!, $grant: String!, $value: Int!) {
                    updateGrant(roleId: $roleId, grant: $grant, value: $value)
                }
            `,
            variables: {
                roleId: this.props.roleId,
                grant: this.props.data.name,
                value
            }
        })
    }

    render() {
        const grant = this.props.data
        const currentValue = this.state.value

        return <GridElement styled>
            <StyledGrant>
                <div className="name">
                    <div className="title">
                        <i className={`fa-duotone fa-${grant.icon}`} />
                        {grant.title}
                    </div>
                    <div className="description">
                        {grant.description}
                    </div>
                </div>
                {[ 1, 2, 3, 4 ].map(val => {
                    const switchValue = currentValue >= val

                    if(!grant.options.includes(val)) return <div key={val} className="switchContainer" />

                    return <div key={val} className="switchContainer">
                        <Switch disabled={grant.default >= val || !scanmetrix.session.administrative} value={switchValue || grant.default >= val} onChange={value => value ? this.set(Math.max(currentValue, val)) : this.set(Math.min(currentValue, val - 1))} />
                    </div>
                })}
            </StyledGrant>
        </GridElement>
    }
}

const StyledGrant = styled.div`
    display: grid;
    grid-template-columns: auto repeat(4, 80px);
    grid-gap: 16px;
    padding: 16px 16px;
  
    .switchContainer {
        display: flex;
        height: 100%;
        align-items: center;
    }
  
    .name {
        display: flex;
        flex-direction: column;
        
        .description {
            opacity: 0.75;
        }
      
        .title {
            margin-bottom: 4px;
            font-size: 18px;
          
            i {
                width: 32px;
            }
        }
    }
`
