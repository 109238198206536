import styled from 'styled-components'

export default styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 32px;
  flex-direction: row;
  justify-content: space-evenly;
  user-select: none;
  
  .avatar {
    border-radius: 100%;
  }

  .text-view {
    width: 60%;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 1.2em;
      margin-bottom: 8px;
    }

    .position {
      font-size: 0.9em;
      margin-bottom: 16px;
    }
  }
`
