import React, { Component } from 'react'
import { Button, Modal, Input, Grid, GridElement, Checkbox } from 'scanmetrix-components'


export default class extends Component {
    constructor(props) {
        super(props)

        this.state = this.getCleanStateVars()
    }

    getCleanStateVars = () => ({
        loading: false,
        firstName: null,
        lastName: null,
        telephone: null,
        email: null,
        birthday: null,
        residency: null,
        driversLicenseSince: null,
        driversLicenseValidUntil: null,
        driversLicenseId: null,
        driversLicenseTypes: null,
        privateUseAllowed: false,
        since: null,
        until: null
    })

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={() => this.setState(this.getCleanStateVars())}>
            <Modal.Head title="Fahrer erstellen" icon="user-tie" />
            <Modal.Body padding={24}>
                <Modal.Divider title="Person" />
                <Grid columns={[ "1fr", "1fr" ]} gap="16px">
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input required label='Vorname' icon='font' adjustWidth value={this.state.firstName} onChange={firstName => this.setState({ firstName })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input required label='Nachname' icon='font' adjustWidth value={this.state.lastName} onChange={lastName => this.setState({ lastName })} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input label='Telefonnummer' icon='phone' adjustWidth value={this.state.telephone} onChange={telephone => this.setState({ telephone })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input label='E-Mail Adresse' icon='envelope-open' adjustWidth value={this.state.email} onChange={email => this.setState({ email })} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input label='Geburtsdatum' icon='birthday-cake' date adjustWidth value={this.state.birthday} onChange={birthday => this.setState({ birthday })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input label='Wohnsitz' icon='map-marker' adjustWidth value={this.state.residency} onChange={residency => this.setState({ residency })} />
                    </GridElement>
                </Grid>
                <Modal.Divider title="Führerscheinangaben" marginTop="32px" />
                <Grid columns={[ "1fr", "1fr" ]} gap="16px">
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input label='Ausgestellt am' icon='calendar-check' date adjustWidth value={this.state.driversLicenseSince} onChange={driversLicenseSince => this.setState({ driversLicenseSince })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input label='Gültig bis' icon='calendar-times' date adjustWidth value={this.state.driversLicenseValidUntil} onChange={driversLicenseValidUntil => this.setState({ driversLicenseValidUntil })} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input label='Führerscheinnummer' icon='list-ol' adjustWidth value={this.state.driversLicenseId} onChange={driversLicenseId => this.setState({ driversLicenseId })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input label='Erlaubte Fahrzeugklassen' icon='cars' adjustWidth value={this.state.driversLicenseTypes} onChange={driversLicenseTypes => this.setState({ driversLicenseTypes })} />
                    </GridElement>
                </Grid>
                <Modal.Divider title="Fahrereigenschaft" marginTop="32px" />
                <Grid columns={[ "1fr", "1fr", "1fr" ]} gap="16px">
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input label='Fahrer ab' icon='calendar-check' date adjustWidth value={this.state.since} onChange={since => this.setState({ since })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input label='Fahrer bis' icon='calendar-check' date adjustWidth value={this.state.until} onChange={until => this.setState({ until })} />
                    </GridElement>
                    <GridElement columnStart={3} columnEnd={4} height="100%">
                        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                            <Checkbox label='Private Nutzung erlaubt' value={this.state.privateUseAllowed} onChange={privateUseAllowed => this.setState({ privateUseAllowed })} />
                        </div>
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.firstName || !this.state.lastName} thick title="Fahrer erstellen" icon="steering-wheel" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($carId: ID!, $firstName: String!, $lastName: String!, $privateUseAllowed: Boolean!, $telephone: String, $email: String, $birthday: DateTime, $residency: String, $driversLicenseSince: DateTime, $driversLicenseValidUntil: DateTime, $driversLicenseId: String, $driversLicenseTypes: String, $since: DateTime, $until: DateTime) {
                            createCarDriver(carId: $carId, firstName: $firstName, lastName: $lastName, telephone: $telephone, email: $email, birthday: $birthday, residency: $residency, driversLicenseSince: $driversLicenseSince, driversLicenseValidUntil: $driversLicenseValidUntil, driversLicenseId: $driversLicenseId, driversLicenseTypes: $driversLicenseTypes, since: $since, until: $until, privateUseAllowed: $privateUseAllowed)
                        }
                    `,
                    variables: {
                        carId: this.props.car.id,
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        privateUseAllowed: this.state.privateUseAllowed,
                        telephone: this.state.telephone,
                        email: this.state.email,
                        birthday: this.state.birthday ? moment(this.state.birthday, "DD.MM.YYYY").toDate() : null,
                        residency: this.state.residency,
                        driversLicenseSince: this.state.driversLicenseSince ? moment(this.state.driversLicenseSince, "DD.MM.YYYY").toDate() : null,
                        driversLicenseValidUntil: this.state.driversLicenseValidUntil ? moment(this.state.driversLicenseValidUntil, "DD.MM.YYYY").toDate() : null,
                        driversLicenseId: this.state.driversLicenseId,
                        driversLicenseTypes: this.state.driversLicenseTypes,
                        since: this.state.since ? moment(this.state.since, "DD.MM.YYYY").toDate() : null,
                        until: this.state.until ? moment(this.state.until, "DD.MM.YYYY").toDate() : null
                    }
                }).then(data => {
                    if(data.data.createCarDriver) {
                        this.props.refresh()
                        instance.close()
                    } else {
                        this.setState({ loading: false })
                    }
                })
            }} />} />
        </Modal>
    }
}
