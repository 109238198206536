import React, { Component } from 'react'
import styled from 'styled-components'

const SwitchBody = styled.div`
  width: 48px !important;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  cursor: ${props => props.disabled ? "default" : "pointer"};
  opacity: ${props => props.disabled ? 0.35 : 1};
  transition: all 0.3s;

  .button {
    width: 24px;
    height: 24px;
    background: linear-gradient(30deg, rgb(98 173 0), rgb(166 215 15));
    box-shadow: 0 2px 8px -3px #3b97d3;
    border-radius: 100%;
    position: absolute;
    left: 0;
    transition: all 250ms ease;
    z-index: 2;
    filter: grayscale(100%);
    
    &.active {
      filter: grayscale(0%);
      left: 24px;
    }
  }

  .background {
    width: 48px;
    position: absolute;
    left: 0;
    top: 4px;
    height: 16px;
    border-radius: 125px;
    background: rgba(0, 0, 0, .1);
    transition: all 250ms ease;

    &.active {
      background: rgba(98, 173, 0, 0.5);
    }
  }
`

export default class extends React.PureComponent {
    state = {
        value: false
    }

    constructor(props) {
        super(props)

        if(props.value === false || props.value === true) this.state.value = props.value
    }

    componentWillReceiveProps(props) {
        if(props.value !== undefined) this.setState({ value: props.value })
        /*console.log(nextProps.value)
        console.log(prevState.value)
        if(nextProps.value === false || nextProps.value === true) {
            if(nextProps.value !== prevState.value) return {
                value: nextProps.value
            }
        }

        return {}*/
    }

    render() {
        return <SwitchBody disabled={this.props.disabled} onClick={() => {
            if(!this.props.disabled) {
                this.setState({ value: !this.state.value }, () => {
                    if(this.props.onChange) this.props.onChange(this.state.value)
                })
            }
        }}>
            <div className={this.state.value ? 'button active' : 'button'} />
            <div className={this.state.value ? 'background active' : 'background'} />
        </SwitchBody>
    }
}
