import React, { Component } from 'react'
import { Button, Input, Select } from 'scanmetrix-components'
import styled from 'styled-components'

import NewInsuranceModal from '../modals/CreateCarInsuranceModal'
import DeleteCarInsuranceModal from "../modals/DeleteCarInsuranceModal"

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

const EmptyView = styled.div`
  width: 100%;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 64px;
    font-size: 1.4em;
    font-weight: 500;
    color: black;
    user-select: none;

    b {
      color: #3B97D3;
      font-weight: inherit;
    }
  }
  
  img {
    width: 100%;
    max-width: 512px;
    pointer-events: none;
    user-select: none;
    user-drag: none;
  }
`

const Header = styled.div`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  
  .info-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    
    .info {
      margin-right: 16px;
      
      p {
        font-size: 1.2em;
        font-weight: 600;
        color: black;
        
        b {
          font-weight: inherit;
          color: #3B97D3;
        }
      }
    }
  }
`

const InsuranceBody = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: minmax(256px, 1fr);
  grid-gap: 32px;
  
  @media screen and (max-width: 1280px) {
    zoom: 0.75;
  }
`

const Item = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 2fr 1fr;
`

const Contact = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  background: #3B97D3;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  
  .contact-header {
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0 8px 24px -16px rgba(0, 0 , 0, .4);
    background: white;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
    
    img {
      width: auto;
      height: 100px;
      pointer-events: none;
      user-select: none;
      user-drag: none;
    }
  }
  
  .backdrop {
    position: absolute;
    bottom: -5%;
    right: -10%;
    transform: translate(-5%, 10%);
    color: rgba(255, 255, 255, .0625);
    font-size: 22.5em;
    user-select: none;
    pointer-events: none;
  }
  
  .contact-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 32px;
    box-sizing: border-box;
    
    h1 {
      margin-bottom: 32px;
      color: white;
      text-shadow: 0 2px 2px rgb(255 255 255 / 20%);
      user-select: none;
    }

    .row {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;

      i, p {
        color: white;
        font-size: 1.2em;
        transition: all 250ms ease;

        &::selection {
          background: rgba(255, 255, 255, .25);
        }
      }

      i {
        margin-right: 8px;
      }
    } 
  }
  
  .relink {
    font-size: 12pt;
    color: rgba(255, 255, 255, .5);
    text-decoration: none;
    position: absolute;
    bottom: 16px;
    right: 16px;
    transition: all 250ms ease;
    
    &:hover {
      opacity: 0.75;
    }
  }
`

const Box = styled.div`
  width: 100%;
  padding: 32px;
  border-radius: 10px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  background: white;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  
  .button-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 32px;
  }
  
  .status-view {
    padding: 8px 16px;
    position: absolute;
    border-radius: 0 0 10px 0;
    top: 0;
    left: 0;
    box-sizing: border-box;

    h1 {
      font-size: 1.4em;
      user-select: none;
      color: white;
      font-weight: 600;
      text-shadow: 0 5px 5px rgba(255 255 255 / 20%);
    }
  }
  
  .input-body {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 16px;
  }
`

function getColor(type) {
    if (type === 'Vollkasko') return '#2ECC71'
    if (type === 'Teilkasko') return '#E67E22'
    if (type === 'Haftpflichtversicherung') return '#E74C3C'

    return '#20242B'
}

class InsuranceItem extends Component {
    state = { readOnly: true, logoURL: null }

    constructor(props) {
        super(props)

        fetch("https://autocomplete.clearbit.com/v1/companies/suggest?query=" + props.insurer).then(result => result.json()).then(result => {
            if(result.length) this.setState({ logoURL: result[0].logo })
        })
    }

    render() {
        const insurance = this.props.insurance

        return <Item>
            <Box>
                <div className='status-view' style={{ background: getColor(this.props.type) }}>
                    <h1>{this.props.type === "liability" ? "Haftpflicht" : (this.props.type === "partiallyComprehensive" ? "Teilkasko" : "Vollkasko")}</h1>
                </div>

                <div className='button-view'>
                    <Button thick secondary title='Versicherung löschen' icon='trash' onClick={() => this.props.delete()} />
                    {/*<Button thick green={!this.state.readOnly} title={this.state.readOnly ? 'Daten anpassen' : 'Angaben speichern' } icon={this.state.readOnly ? 'pencil-alt': 'save' } onClick={() => this.setState({ readOnly: !this.state.readOnly })} />*/}
                </div>

                <div className='input-body'>
                    <Input.MutationProvider value={{
                        name: "updateCarInsurance",
                        id: insurance.id,
                        data: insurance,
                        onSave: () => this.props.refresh()
                    }}>
                        <Input field="name" adjustWidth label='Name' icon='font' />
                        <Input field="insurer" adjustWidth label='Versicherer' icon='user-shield' />
                        <Input field="insuranceNumber" adjustWidth label='Versicherungsnummer' icon='barcode' />

                        <Input field="deductible" adjustWidth label='Selbstbeteiligung' icon='money-bill-wave' float format={val => val !== null ? formatter.format(val) : val} />
                        <Input field="contractBegin" adjustWidth label='Vertragsbeginn' icon='calendar-day' date />
                        <Input field="contractEnd" adjustWidth label='Vertragsende' icon='calendar-day' date />

                        <Input field="subscriptionIntervalFee" adjustWidth label='Vertragsgebühren' icon='euro-sign' float format={val => val !== null ? formatter.format(val) : val} />
                        <Select adjustWidth label='Zahlungsintervall' upwards icon='hourglass' field="subscriptionIntervalType" items={[
                            { key: 'one', title: 'Monatlich' },
                            { key: 'three', title: 'Alle 3 Monate' },
                            { key: 'six', title: 'Alle 6 Monate' },
                            { key: 'nine', title: 'Alle 9 Monate' },
                            { key: 'twelve', title: 'Jährlich' },
                        ]} />
                        <Input field="description" adjustWidth label='Beschreibung' icon='font' />
                    </Input.MutationProvider>
                </div>
            </Box>

            <Contact>
                <div className='contact-header'>
                    <img src={this.state.logoURL} alt={this.props.insurer} />
                </div>

                <i className='far fa-car-crash backdrop'/>

                <div className='contact-body'>
                    <h1>Kontakt</h1>

                    <div className='row'>
                        <i className='far fa-user-shield' />
                        <p>{this.props.insurer}</p>
                    </div>

                    <div className='row'>
                        <i className='far fa-at'/>
                        <p>{this.props.email}</p>
                    </div>
                    <div className='row'>
                        <i className='far fa-phone'/>
                        <p>{this.props.telephone}</p>
                    </div>
                </div>

                {this.state.logoURL !== null && <a className='relink' href='https://clearbit.com' target='_blank'>Logo provided by Clearbit</a>}
            </Contact>
        </Item>
    }
}

export default class extends Component {
    state = { readOnly: true }

    render() {
        return <>
            <DeleteCarInsuranceModal instance={ref => this.deleteCarInsuranceModal = ref} refresh={this.props.refresh} />
            <NewInsuranceModal refresh={this.props.refresh} instance={ ref => this.newInsuranceModal = ref} carId={this.props.car.id} />
            <Header>
                {/*<div className='info-view'>
                    <div className='info'>
                        <p>Versicherungen: <b>{this.props.insurances.length || 0}</b></p>
                    </div>
                    <div className='info'>
                        <p>Gesamtbetrag: <b>{this.props.costTotal || '0,00'} €</b></p>
                    </div>
                </div>*/}
                <Button thick title='Versicherung hinzufügen' icon='car-crash' onClick={() => this.newInsuranceModal.open()} />
            </Header>
            {this.props.insurances.length ?
                <InsuranceBody>
                    {this.props.insurances.map(item => {
                        return <>
                            <InsuranceItem
                                key={item.id}
                                delete={() => this.deleteCarInsuranceModal.open(item.id)}
                                type={item.type}
                                name={item.name}
                                insurer={item.insurer}
                                insuranceNumber={item.insuranceNumber}
                                deductible={item.deductible}
                                contractBegin={item.contractBegin}
                                contractEnd={item.contractEnd}
                                subscriptionIntervalFee={item.subscriptionIntervalFee}
                                subscriptionIntervalType={item.subscriptionIntervalType}
                                description={item.description}
                                email={item.email}
                                telephone={item.telephone}
                                insurance={item}
                                refresh={this.props.refresh}
                            />
                        </>
                    })}
                </InsuranceBody>
                :
                <EmptyView>
                    <p>Hier befinden sich noch keine <b>Versicherungen</b>.</p>
                    <img src="/empty.svg"/>
                </EmptyView>
            }
        </>
    }
}
