import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"
import styled from "styled-components"
import i18next from "i18next";

const Image = styled.img`
    height: 200px;
    display: inline-block;
`

export default class extends Component {
    state = { code: null, error: null, loading: false }

    render() {
        return <Modal maxWidth="650px" minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ code: null, error: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.serviceProvider.connectServiceProviderModal.title")} icon="code" />
            <Modal.Body padding={24}>
                <div style={{ display: "flex", width: "100%", justifyContent: "center", marginBottom: 16 }}>
                    <Image src="/sync.svg" />
                </div>
                <Input adjustWidth readOnly={this.state.loading} label={i18next.t("page.private.serviceProvider.connectServiceProviderModal.couplingCodeInput")} icon="code" value={this.state.code} onChange={code => this.setState({ code, error: null })} />
                <Modal.Info maxWidth="650px"><b>{i18next.t("page.private.serviceProvider.connectServiceProviderModal.text1")}</b> {i18next.t("page.private.serviceProvider.connectServiceProviderModal.text2")} <a style={{ color: "#3b97d3" }} href="https://scanmetrix.app">{i18next.t("page.private.serviceProvider.connectServiceProviderModal.moreInformationLink")}</a></Modal.Info>
                {this.state.error && <Modal.Error maxWidth="650px">{this.state.error}</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => [
                <Button disabled={!this.state.code} loading={this.state.loading} thick title={i18next.t("page.private.serviceProvider.connectServiceProviderModal.exitButton")} icon="code" onClick={() => {
                    this.setState({ loading: true, error: null })

                    fetch(scanmetrix.backendURL + "/serviceprovider/" + this.props.serviceProviderId + "/connect/" + this.state.code, {
                        method: "POST",
                        credentials: "include"
                    }).then(result => result.json()).then(data => {
                        console.log(data)

                        if(data.status === "SUCCESSFUL") instance.close()
                        else this.setState({ loading: false, error: data.message })
                    })
                }} />
            ]} />
        </Modal>
    }
}
