import React, { Component } from 'react'
import { Button, Modal, Input, Grid, GridElement } from 'scanmetrix-components'
import i18next from "i18next"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/theme-xcode"
import "ace-builds/src-noconflict/ext-language_tools"

export default class extends Component {
    state = { content: null, name: null, loading: false }

    render() {
        return <Modal minWidth="768px" instance={this.props.instance} initialize={() => this.setState({ content: null, name: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.buildingInspection.reportTemplates.createBuildingInspectionsReportTemplateModal.title")} icon="file-pdf" />
            <Modal.Body padding={24}>
                <Grid gap="16px">
                    <GridElement>
                        <Input label={i18next.t("page.private.buildingInspection.reportTemplates.createBuildingInspectionsReportTemplateModal.name")} icon="tag" value={this.state.name} required onChange={name => this.setState({ name })} adjustWidth />
                    </GridElement>
                    <GridElement>
                        <AceEditor
                            placeholder={i18next.t("page.private.buildingInspection.reportTemplates.createBuildingInspectionsReportTemplateModal.searchPlaceholder")}
                            mode="html"
                            theme="xcode"
                            value={this.state.content || ""}
                            onChange={content => this.setState({ content })}
                            name="createBuildingInspectionReportContent"
                            editorProps={{ $blockScrolling: true }}
                            style={{ width: "100%" }}
                        />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.name} thick title={i18next.t("page.private.buildingInspection.reportTemplates.createBuildingInspectionsReportTemplateModal.exitButton")} icon="file-pdf" onClick={() => {
                this.setState({ loading: true })

                return scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $content: String) {
                            uploadBuildingInspectionReportTemplate(name: $name, content: $content)
                        }
                    `,
                    context: {
                        hasUpload: true
                    },
                    variables: {
                        name: this.state.name,
                        content: this.state.content
                    }
                }).then(result => {
                    if (result.data.uploadBuildingInspectionReportTemplate) {
                        return this.props.refresh().then(() => instance.close())
                    }
                })
            }} />} />
        </Modal>
    }
}
