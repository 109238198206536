import React, { Component } from "react"
import { Page, Breadcrumbs } from "scanmetrix-components"
import styled from "styled-components"
import Chart from "react-apexcharts";
//import {DemoModal} from "../budget";

const Thermostat = styled.div`
  width: 256px;
  height: 256px;
  border-radius: 256px;
  padding: 16px;
  position: absolute;
  left: -144px;
  overflow: hidden;
  background: white;
  box-shadow: 0 12px 16px -6px rgba(0, 0, 0, 0.2);
  zoom: 0.6;
  z-index: 2;
  top: 32px;
  
  &:before {
    display: block;
    content: " ";
    border-radius: 256px;
    width: 256px;
      height: 256px;
      background: linear-gradient(90deg,#3b97d3,#a900ff);
      position: absolute;
      left: 16px;
      top: 16px;
  }
  
  &:after {
    display: block;
    content: " ";
    z-index: 2;
    position: absolute;
    width: 128px;
    height: 128px;
    background: white;
    bottom: -64px;
    left: 80px;
    transform: rotate(45deg);
    box-shadow: 0 0 48px 48px white;
  }
 
  .inner {
    width: 240px;
    height: 240px;
    border-radius: 240px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;
    user-select: none;
    position: absolute;
    left: 24px;
    top: 24px;
    color: #3B97D3;
    font-size: 3.2em;
    font-weight: bold;
    
    &:before {
      content: " ";
      display: block;
      background: white;
      position: absolute;
      width: 128px;
      height: 128px;
      left: 120px;
      top: 120px;
      z-index: 0;
    }
    
    i {
      font-size: 0.7em;
      color: black;
      opacity: 0.5;
      z-index: 1;
    }
    
    p {
      margin: 16px 0;
      z-index: 1;
    }
  }
`
const getAccountingChartOptions = (formatter, colors) => {
    return {
        colors,
        chart: {
            toolbar: {
                show: false
            },
            animations: {
                enabled: false
            },
            sparkline: {
                enabled: true
            },
            dropShadow: {
                enabled: true,
                opacity: 0.3,
                blur: 5,
                left: -2,
                top: 8
            },
        },
        xaxis: {
            categories: ["01.07.2020", "02.07.2020", "03.07.2020", "04.07.2020", "05.07.2020", "06.07.2020", "07.07.2020", "08.07.2020", "09.07.2020", "10.07.2020", "11.07.2020", "12.07.2020", "13.07.2020", "14.07.2020", "15.07.2020", "16.07.2020"],
            axisBorder: {
                show: false
            },
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
        yaxis: {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
                left: 0,
                right: 0
            },
            labels: {
                formatter
            }
        },
        stroke: {
            curve: 'smooth',
            colors
        },
        dataLabels: {
            enabled: false,
            style: {
                colors
            }
        },
        legend: {
            show: false,
            labels: {
                colors
            }
        },
        markers: {
            strokeColor: colors
        },
        grid: {
            show: false,
            padding: {
                left: 0,
                right: 0,
                bottom: 0
            }
        }
    }
}

const Value = styled.div`
  color: white;
  width: 100%;
  text-align: right;
  padding: 16px;
  box-sizing: border-box;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.75);
  
  .top {
    font-size: 1.4em;
    
    b {
      font-size: 1.7em;
    }
  }
  
  .bottom {
    font-size: 0.9em;
    opacity: 0.75;
    text-shadow: none;
  }
`

const Devices = styled.div`
  width: 100%;
  background: white;
  border-radius: 16px;
  margin-bottom: 48px;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 12px 24px -12px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`

const Device = styled.div`
  width: 100%;
  height: 160px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  background: linear-gradient(45deg, #189a00, #e6ff83);
  position: relative;
  box-shadow: 0 8px 16px -4px rgb(129 206 67 / 79%);
  color: white;
  overflow: hidden;
  
  &.flawed {
    background: linear-gradient(45deg,#b30800,#ffcd17);
  box-shadow: 0 8px 16px -4px rgb(206 67 67 / 64%);
  }
  
  &.off {
    background: linear-gradient(45deg,#202035,#5681ad);
  box-shadow: 0 8px 16px -4px rgb(24 60 105 / 79%);
  }
  
  i {
    font-size: 2.5em;
    z-index: 2;
  }
  
  p.title {
    margin-top: 16px;
    margin-bottom: 4px;
    font-size: 1.6em;
    z-index: 2;
    text-shadow: 0 2px 2px black;
  }
  
  p.device {
    font-size: 1em;
    z-index: 2;
    text-shadow: 0 2px 2px black;
  }
  
  .on, .off, .flawed {
    color: white;
    padding: 6px 12px;
    background: #20242b;
    position: absolute;
    right: -4px;
    top: 16px;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.15);
  }
  
  .on {
    background: #2ecc71;
  }
  
  .flawed {
    background: #e74c3c;
  }
  
  &:last-child {
    margin-right: 0;
  }
  
  .chart {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.25;
    z-index: 0;
  }
`

const Energy = styled.div`
  h1 {
      font-weight: normal;
      margin-bottom: 16px;
      font-size: 1.4em;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      
      button {
        background: linear-gradient(35deg, #20459a, #568df1);
        box-shadow: 0 4px 8px -2px rgb(32 69 154 / 60%);
        outline: 0;
        border: none;
        color: white;
        padding: 12px 16px;
        border-radius: 4px;
        font-size: 0.6em;
      }
  }
`

export default class extends Component {
    render() {
        return <Page {...this.props}>
            {/*<DemoModal instance={ref => ref.open()} />*/}
            <Breadcrumbs values={[
                {
                    icon: "house-signal",
                    title: "Smart Building"
                },
                {
                    icon: "map-marker",
                    title: "Standort Duisburg (VKST 1327)"
                }
            ]} />
            <Energy>
                <h1>Elektrotechnische Anlagen</h1>
                <Devices>
                    <Device className="on">
                        <i className="fal fa-air-conditioner" />
                        <p className="title">Klima 1. UG</p>
                        <p className="device">SAMSUNG AR35</p>
                        <p className="on">Aktiv</p>
                        <div className="chart">
                            <Chart
                                options={getAccountingChartOptions(val => `${val} €`, ["#ffffff"])}
                                series={[
                                    {
                                        name: "Kosten Soll",
                                        data: [625, 123, 425, 124, 453, 236, 434, 256, 236, 634, 324, 563, 180, 244]
                                    }
                                ]}
                                type="area"
                                height="90"
                                width="450"
                            />
                        </div>
                    </Device><Device className="off">
                    <i className="fal fa-air-conditioner" />
                    <p className="title">Klima EG</p>
                    <p className="device">SAMSUNG AR35</p>
                    <p className="off">Inaktiv</p>
                    <div className="chart">
                        <Chart
                            options={getAccountingChartOptions(val => `${val} €`, ["#ffffff"])}
                            series={[
                                {
                                    name: "Kosten Soll",
                                    data: [200, 200, 200, 200, 200]
                                }
                            ]}
                            type="area"
                            height="90"
                            width="450"
                        />
                    </div>
                </Device><Device className="flawed">
                    <i className="fal fa-air-conditioner" />
                    <p className="title">Klima 1. OG</p>
                    <p className="device">SAMSUNG AR35</p>
                    <p className="flawed">Störung</p>
                </Device><Device className="off">
                    <i className="fal fa-air-conditioner" />
                    <p className="title">Klima Pausenraum</p>
                    <p className="device">SAMSUNG AR35</p>
                    <p className="off">Inaktiv</p>
                    <div className="chart">
                        <Chart
                            options={getAccountingChartOptions(val => `${val} €`, ["#ffffff"])}
                            series={[
                                {
                                    name: "Kosten Soll",
                                    data: [200, 200, 200, 200, 200]
                                }
                            ]}
                            type="area"
                            height="90"
                            width="450"
                        />
                    </div>
                </Device><Device className="on">
                    <i className="fal fa-air-conditioner" />
                    <p className="title">Klima Küche</p>
                    <p className="device">SAMSUNG AR35</p>
                    <p className="on">Aktiv</p>
                    <div className="chart">
                        <Chart
                            options={getAccountingChartOptions(val => `${val} €`, ["#ffffff"])}
                            series={[
                                {
                                    name: "Kosten Soll",
                                    data: [500, 60, 816, 250, 350, 300, 400, 300, 320, 500, 200, 100, 250, 300]
                                }
                            ]}
                            type="area"
                            height="90"
                            width="450"
                        />
                    </div>
                </Device><Device className="off">
                    <i className="fal fa-air-conditioner" />
                    <p className="title">Klima Kantinenbereich</p>
                    <p className="device">SAMSUNG AR35</p>
                    <p className="off">Inaktiv</p>
                    <div className="chart">
                        <Chart
                            options={getAccountingChartOptions(val => `${val} €`, ["#ffffff"])}
                            series={[
                                {
                                    name: "Kosten Soll",
                                    data: [200, 200, 200, 200, 200]
                                }
                            ]}
                            type="area"
                            height="90"
                            width="450"
                        />
                    </div>
                </Device>
                </Devices>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "calc(50% - 24px)" }}>
                        <h1>Stromverbrauch durch Kälte-/Klima-/Sanitäranlagen</h1>
                        <div style={{ display: "flex", float: "left", position: "relative", background: "linear-gradient(45deg, rgb(87 17 150), rgb(14 179 228))", width: "100%", height: "400px", borderRadius: "16px", flexDirection: "column", justifyContent: "space-between", boxShadow: "rgb(49 99 190 / 80%) 0px 8px 32px -4px" }}>
                            <Thermostat>
                                <div className="inner">
                                    <i className="far fa-chevron-up" />
                                    <p>21.0° C</p>
                                    <i className="far fa-chevron-down" />
                                </div>
                            </Thermostat>
                            <Value>
                                <p className="top"><b>11,71 €</b> 40,3 kWh</p>
                                <p className="bottom">In den letzten 24 Stunden</p>
                            </Value>
                            <Chart
                                options={getAccountingChartOptions(val => `${val} €`, ["#ffffff", "#2ecc71"])}
                                series={[
                                    {
                                        name: "Kosten Soll",
                                        data: [625, 123, 425, 124, 453, 236, 434, 256, 236, 634, 324, 563, 180, 244]
                                    },
                                    {
                                        name: "Kosten Ist",
                                        data: [500, 60, 816, 250, 350, 300, 400, 300, 320, 500, 200, 100, 250, 300]
                                    }
                                ]}
                                type="line"
                                height="300"
                                width="676"
                            />
                        </div>
                    </div>
                    <div style={{ width: "calc(50% - 24px)" }}>
                        <h1 style={{ float: "right" }}>Energieverbrauch in den letzten Wochen</h1>
                        <div style={{ display: "flex", float: "right", boxSizing: "border-box", overflow: "hidden", padding: 16, position: "relative", background: "white", width: "100%", height: "400px", borderRadius: "16px", flexDirection: "column", justifyContent: "space-between", boxShadow: "rgb(0 0 0 / 35%) 0px 8px 32px -4px" }}>
                            <Chart
                                options={{
                                    chart: {
                                        height: 350,
                                        type: 'heatmap',
                                        sparkline: {
                                            enabled: true
                                        }
                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    colors: ["#3b97d3"]
                                }}
                                series={[
                                    {
                                        name: "Series 1",
                                        data: [{
                                            x: 'W1',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W2',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W3',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W4',
                                            y: Math.random() * 30
                                        },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            }]
                                    },
                                    {
                                        name: "Series 2",
                                        data: [{
                                            x: 'W1',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W2',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W3',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W4',
                                            y: Math.random() * 30
                                        },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            }]
                                    },
                                    {
                                        name: "Series 2",
                                        data: [{
                                            x: 'W1',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W2',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W3',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W4',
                                            y: Math.random() * 30
                                        },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            }]
                                    },
                                    {
                                        name: "Series 2",
                                        data: [{
                                            x: 'W1',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W2',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W3',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W4',
                                            y: Math.random() * 30
                                        },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            }]
                                    },
                                    {
                                        name: "Series 2",
                                        data: [{
                                            x: 'W1',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W2',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W3',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W4',
                                            y: Math.random() * 30
                                        },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            }]
                                    },
                                    {
                                        name: "Series 2",
                                        data: [{
                                            x: 'W1',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W2',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W3',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W4',
                                            y: Math.random() * 30
                                        },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            }]
                                    },
                                    {
                                        name: "Series 2",
                                        data: [{
                                            x: 'W1',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W2',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W3',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W4',
                                            y: Math.random() * 30
                                        },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            }]
                                    },
                                    {
                                        name: "Series 2",
                                        data: [{
                                            x: 'W1',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W2',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W3',
                                            y: Math.random() * 30
                                        }, {
                                            x: 'W4',
                                            y: Math.random() * 30
                                        },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            },
                                            {
                                                x: 'W4',
                                                y: Math.random() * 30
                                            }]
                                    }
                                ]}
                                type="heatmap"
                                height="100%"
                                width="100%"
                            />
                        </div>
                    </div>
                </div>


            </Energy>
        </Page>
    }
}
