import styled from "styled-components"

export default styled.div`
  width: 100%;
  height: 100%;
  background: white;
  padding: 16px;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  
  .tags-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`