import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"
import { CirclePicker } from "react-color"
import styled from "styled-components"
import Dropzone from "react-dropzone";
import prettyBytes from "pretty-bytes";
import i18next from "i18next";

const UploadBody = styled.div`
    box-shadow: ${props => props.isDragActive ? "inset 0 0 0 4px #3b97d3" : "inset 0 0 0 1px rgba(0, 0, 0, 0.25)"};
    padding: 24px;
    user-select: none;
    
    i {
        margin-right: 8px;
    }
`

const File = styled.div`
    padding: 24px;
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: 16px;
`

export default class extends Component {
    state = { name: null, legalName: null, color: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={() => this.setState({ name: null, fileName: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.createStandardFileModal.title")} icon="file-check" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%", marginBottom: 24 }}>
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.settings.modals.createStandardFileModal.name")} adjustWidth />
                </div>
                <Dropzone accept={[ ".dwg", ".dxf" ]} multiple={false} onDrop={acceptedFiles => {
                    if(acceptedFiles.length && !this.state.loading) {
                        const file = acceptedFiles[0]
                        this.file = file

                        this.setState({ fileName: file.name, fileSize: file.size })
                    }
                }}>
                    {({ getRootProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef" })}>
                        <p className="title"><i className="far fa-upload" /> {i18next.t("page.private.settings.modals.createStandardFileModal.uploadFile")}</p>
                    </UploadBody>}
                </Dropzone>
                {this.state.fileName && <File>
                    <p className="name">{this.state.fileName}</p>
                    <p className="size">{prettyBytes(this.state.fileSize, { locale: "de" })}</p>
                </File>}
                {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.fileName} title={i18next.t("page.private.settings.modals.createStandardFileModal.exitButton")} icon="file-check" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $file: Upload!) {
                            createStandardFile(name: $name, file: $file)
                        }
                    `,
                    context: {
                        hasUpload: true
                    },
                    variables: {
                        name: this.state.name,
                        file: this.file
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createStandardFile) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
