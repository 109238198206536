import React, { Component, useEffect } from "react"
import { Section, GridElement, Button } from "scanmetrix-components"
import styled from "styled-components"
import CreateMeterModal from "./modals/CreateMeterModal"
import UpdateMeterModal from "./modals/UpdateMeterModal"
import DeleteMeterModal from "./modals/DeleteMeterModal"
import CreateMeterReadingModal from "./modals/CreateMeterReadingModal"
import MeterReadingsHistoryModal from "./modals/MeterReadingHistoryModal"
import i18next from "i18next";

import CreateMeterGroupModal from "./modals/CreateMeterGroupModal";
import {
    GridContextProvider,
    GridDropZone,
    GridItem,
    swap,
    move
} from "react-grid-dnd";
import DeleteMeterGroupModal from "./modals/DeleteMeterGroupModal";
import UpdateMeterGroupModal from "./modals/UpdateMeterGroupModal";

const CounterComponent = styled.div`
  width: 100%;
  padding: 15px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  user-select: none;
  max-width: 445px;
  
  .styled-counter {
    width: 100%;
    min-height: 225px;
    display: flex;
    flex: 1;
    padding: 16px;
    border-radius: 15px;
    background: #ebeef3;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgb(169 177 191);
    position: relative;
    overflow: hidden;
    outline: ${props => props.active ? "none" : "3px solid #e74c3c"};
    
    &:hover {
        .overlay {
            visibility: visible;
            opacity: 1;
        }
    }
    
    .overlay {
        top: 0;    
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background: rgba(255, 255, 255, 0.9);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.3s, opacity 0.3s;
        
        .buttons {
            grid-gap: 12px;
            display: grid;
            width: 100%;
            grid-template-columns: calc(50% - 6px) calc(50% - 6px);
            padding: 16px;
            box-sizing: border-box;
        }
    }
    
    .white-box {
      width: 100%;
      padding: 10px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      background: white;
      border-radius: 10px;
      box-sizing: border-box;
      
      .counter {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        
        .item {
          position: absolute;
          display: inline-block;
          border-radius: 14px;
          background: #20242B;
          box-sizing: border-box;
          padding: 5px;
        
          
          .inner-data {
            padding: 0 5px;
            background: #20242B;
            border: 3px solid white;
            border-radius: 10px;
            box-sizing: border-box;
            
            p {
              color: white;
              font-weight: 600;
              letter-spacing: 10px;
              
              &:last-child {
                margin-right: -5px;
              }
            }
          }
        }
        
        .factor {
            padding-top: 60px;
            font-size: 0.9em;
            font-weight: bold;
        }
      }
      
      .data-body {
        width: 100%;
        margin-top: 24px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        background: #2c3e50;
        border-radius: 5px;
        box-sizing: border-box;
        
        .label-row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 5px;
          
          .label {
            width: 120px;
            text-align: left;
            color: white;
            font-weight: 500;
            opacity: 0.75;
          }
          
          .data {
            flex: 1;
            color: white;
            text-overflow: ellipsis;
            word-wrap: anywhere;
            overflow: hidden;
            max-height: 2.6em;
            line-height: 1.3em;
            display: block;
            display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
          }
          
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`

function padDigits(number, digits) {
    return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
}

const MetersContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
`

class Meters extends Component {
    render() {
        return null
    }
}

const NoMeters = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    padding: 64px 0;
    
    h2 {
      font-weight: normal;
    }
    
    img {
      -webkit-user-drag: none;
      width: 250px;
      margin-top: 32px;
      opacity: 0.75;
      filter: grayscale(100%);
    }
`

export default class extends Component {
    state = {
        meters: null,
        meterCount: 0,
        meterGroups: []
    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    MeterGroups(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            name
                        }
                    }
                    Meters(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            name
                            position
                            type
                            qrcode
                            number
                            factor
                            deliveryBegin
                            meterGroupIndex
                            meloId
                            maloId
                            updatedAt
                            active
                            meterGroup {
                                id
                            }
                            readings {
                                id
                                value
                                createdAt
                                image
                                user {
                                    id
                                    firstName
                                    lastName
                                    email
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.subsidiary.id
            }
        }).then(data => {
            let meters = { empty: [] }

            data.data.MeterGroups.nodes.forEach(meterGroup => meters[meterGroup.id] = [])

            data.data.Meters.nodes.sort((a, b) => {
                const n = (a.meterGroupIndex || 0) - (b.meterGroupIndex || 0)

                if(n !== 0) return n

                return moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()
            }).forEach(meter => {
                const meterGroup = meter.meterGroup ? meter.meterGroup.id : "empty"

                if(!meters[meterGroup]) meters[meterGroup] = []

                meters[meterGroup].push(meter)
            })

            this.setState({ meters, meterCount: data.data.Meters.nodes.length, meterGroups: data.data.MeterGroups.nodes })
        })
    }

    render() {
        const meters = this.state.meters

        if(meters === null) return <Section.Item title={i18next.t("page.private.subsidiary.styled.counters.counterOne")} icon="monitor-heart-rate" amount={this.state.meterCount} {...this.props}></Section.Item>

        return <Section.Item title={i18next.t("page.private.subsidiary.styled.counters.counterTwo")} icon="monitor-heart-rate" amount={this.state.meterCount} {...this.props}>
            <CreateMeterModal subsidiaryId={this.props.subsidiary.id} instance={ref => this.createMeterModal = ref} refresh={() => this.fetch()} />
            <CreateMeterGroupModal subsidiaryId={this.props.subsidiary.id} instance={ref => this.createMeterGroupModal = ref} refresh={() => this.fetch()} />
            <UpdateMeterModal instance={ref => this.updateMeterModal = ref} refresh={() => this.fetch()} />
            <DeleteMeterModal instance={ref => this.deleteMeterModal = ref} refresh={() => this.fetch()} />
            <DeleteMeterGroupModal instance={ref => this.deleteMeterGroupModal = ref} refresh={() => this.fetch()} />
            <MeterReadingsHistoryModal instance={ref => this.meterReadingsHistoryModal = ref} refresh={() => this.fetch()} />
            <UpdateMeterGroupModal instance={ref => this.updateMeterGroupModal = ref} refresh={() => this.fetch()} />
            <CreateMeterReadingModal refresh={() => this.fetch()} instance={ref => this.createMeterReadingModal = ref} />
            <GridElement styled title={i18next.t("page.private.subsidiary.styled.counters.gridElementTitle")} icon="monitor-heart-rate" rightContent={<div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: 16 }}>{i18next.t("page.private.subsidiary.styled.counters.text1")} <b style={{ color: "#3b97d3" }}><i style={{ marginRight: 0 }} className="far fa-house-signal" /> {i18next.t("page.private.subsidiary.styled.counters.text2")}</b> {i18next.t("page.private.subsidiary.styled.counters.text3")}</p>
                <Button style={{ marginRight: 16 }} disabled={scanmetrix.checkPermission("MeterGroup") < 2} secondary thick title={i18next.t("page.private.subsidiary.styled.counters.createCounterGroupButton")} icon="object-intersect" onClick={() => this.createMeterGroupModal.open()} />
                <Button disabled={scanmetrix.checkPermission("Meter") < 2} thick title={i18next.t("page.private.subsidiary.styled.counters.createCounterButton")} icon="monitor-heart-rate" onClick={() => this.createMeterModal.open()} />
            </div>}>
                {this.state.meterCount === 0 && <NoMeters>
                    <h2>{i18next.t("page.private.subsidiary.styled.counters.noMetersDescription")}</h2>
                    <img src="/no_cases.svg" />
                </NoMeters>}
                {meters !== null && <MeterWrapper refresh={() => this.fetch()} updateMeterGroupModal={item => this.updateMeterGroupModal.open(item)} deleteMeterGroupModal={id => this.deleteMeterGroupModal.open(id)} meterGroups={this.state.meterGroups} data={meters} meterReadingHistoryModal={readings => this.meterReadingsHistoryModal.open(readings)} deleteMeterModal={meter => this.deleteMeterModal.open(meter)} createMeterReadingModal={meter => this.createMeterReadingModal.open(meter)} updateMeterModal={meter => this.updateMeterModal.open(meter)} />}
            </GridElement>
        </Section.Item>
    }
}

function MeterWrapper(props) {
    const [items, setItems] = React.useState(props.data)

    useEffect(() => {
        setItems(props.data);
    }, [props.data])

    function onChange(sourceId, sourceIndex, targetIndex, targetId) {
        if (targetId) {
            const result = move(
                items[sourceId],
                items[targetId],
                sourceIndex,
                targetIndex
            );

            const item = items[sourceId][sourceIndex]
            const meterGroupId = targetId
            const meterGroupIndex = targetIndex

            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($id: ID!, $meterGroupId: ID, $meterGroupIndex: Int!) {
                        updateMeterPosition(id: $id, meterGroupId: $meterGroupId, meterGroupIndex: $meterGroupIndex)
                    }
                `,
                variables: {
                    id: item.id,
                    meterGroupId: meterGroupId,
                    meterGroupIndex
                }
            })

            return setItems({
                ...items,
                [sourceId]: result[0],
                [targetId]: result[1]
            }, props.refresh);
        }

        const result = swap(items[sourceId], sourceIndex, targetIndex);

        const item = items[sourceId][sourceIndex]
        const meterGroupIndex = targetIndex

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $meterGroupId: ID, $meterGroupIndex: Int!) {
                    updateMeterPosition(id: $id, meterGroupId: $meterGroupId, meterGroupIndex: $meterGroupIndex)
                }
            `,
            variables: {
                id: item.id,
                meterGroupId: null,
                meterGroupIndex
            }
        }, props.refresh)

        return setItems({
            ...items,
            [sourceId]: result
        });
    }

    return <GridContextProvider onChange={onChange}>
        <div className="container" style={{ paddingBottom: 1 }}>
            {props.meterGroups.map(meterGroup => <StyledMeterGroup key={meterGroup.id}>
                <div className="groupTitle">
                    <div className="name">{meterGroup.name}</div>
                    <div className="actions">
                        <Button onClick={() => props.updateMeterGroupModal(meterGroup)} thick title={i18next.t("page.private.subsidiary.styled.counters.editButton")} icon="pencil" />
                        <Button onClick={() => props.deleteMeterGroupModal(meterGroup.id)} secondary noIconMargin thick title={<i className="fa-duotone fa-trash" />} />
                    </div>
                </div>
                <GridDropZone
                    className="dropzone"
                    style={{ height: 360 * Math.max(1, Math.floor(((items[meterGroup.id]?.length || 0) + 3) / 4)) }}
                    id={meterGroup.id}
                    boxesPerRow={4}
                    rowHeight={360}
                >
                    {(items[meterGroup.id] || []).map(item => (
                        <GridItem key={item.id}>
                            <div className="grid-item" style={{ height: "100%", display: "flex", alignItems: "center" }}>
                                <div className="grid-item-content">
                                    <Meter item={item} meterReadingHistoryModal={props.meterReadingHistoryModal} deleteMeterModal={props.deleteMeterModal} createMeterReadingModal={props.createMeterReadingModal} updateMeterModal={props.updateMeterModal} />
                                </div>
                            </div>
                        </GridItem>
                    ))}
                </GridDropZone>
            </StyledMeterGroup>)}
            <GridDropZone className="dropzone" boxesPerRow={4} rowHeight={360} id="empty" style={{ height: 360 * Math.max(1, Math.floor((items.empty.length + 3) / 4)) }}>
                {items.empty.map(item => {
                    return <GridItem key={item.id}>
                        <div className="grid-item" style={{ height: "100%", display: "flex", alignItems: "center" }}>
                            <div className="grid-item-content">
                                <Meter item={item} meterReadingHistoryModal={readings => props.meterReadingHistoryModal(readings)} deleteMeterModal={meter => props.deleteMeterModal(meter)} createMeterReadingModal={meter => props.createMeterReadingModal(meter)} updateMeterModal={meter => props.updateMeterModal(meter)} />
                            </div>
                        </div>
                    </GridItem>
                })}
            </GridDropZone>
        </div>
    </GridContextProvider>
}

const StyledMeterGroup = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 16px;
    border-radius: 5px;
  
    .groupTitle {
        background: #ebeef3;
        padding: 12px;
        width: calc(100% - 24px);
        display: flex;
        justify-content: space-between;
        align-items: center;
      
        .name {
            font-size: 18px;
        }
      
        .actions {
            display: grid;
            grid-auto-flow: column;
            grid-gap: 16px;
        }
    }
`

class Meter extends Component {
    render() {
        const item = this.props.item

        return <CounterComponent type={item.type} active={item.active}>
            <div className='styled-counter'>
                <div className="overlay">
                    <div className="buttons">
                        <Button disabled={scanmetrix.checkPermission("MeterReading") < 2} onClick={() => this.props.createMeterReadingModal(item)} adjustWidth thick title={i18next.t("page.private.subsidiary.styled.counters.imageSection.enterStand")} icon="clock" />
                        <Button onClick={() => this.props.meterReadingHistoryModal(item.readings.map(reading => ({ ...reading, type: item.type })))} adjustWidth secondary thick title={i18next.t("page.private.subsidiary.styled.counters.imageSection.Standhistorie")} icon="history" />
                        <Button disabled={scanmetrix.checkPermission("Meter") < 3} onClick={() => this.props.updateMeterModal(item)} adjustWidth secondary thick title={i18next.t("page.private.subsidiary.styled.counters.imageSection.editButton")} icon="pencil" />
                        <Button disabled={scanmetrix.checkPermission("Meter") < 4} onClick={() => this.props.deleteMeterModal(item)} adjustWidth secondary thick title={i18next.t("page.private.subsidiary.styled.counters.imageSection.deleteButton")} icon="trash" />
                    </div>
                </div>
                <div className='white-box'>
                    <div className='counter'>
                        <div className='item'>
                            <div className='inner-data'>
                                <p>{padDigits((item.readings.length ? (item.readings.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())[0].value) : 0), 10)}</p>
                            </div>
                        </div>
                        {item.factor && <p className='factor'><i className="far fa-times" /> {item.factor}</p>}
                    </div>

                    <div className='data-body'>
                        <div className='label-row'>
                            <p className='label'>{i18next.t("page.private.subsidiary.styled.counters.imageSection.type")}</p>
                            <p className='data'>{item.type === "electricity" ? i18next.t("page.private.subsidiary.styled.counters.imageSection.electricity") : (item.type === "water" ? i18next.t("page.private.subsidiary.styled.counters.imageSection.water") : (item.type === "gas" ? i18next.t("page.private.subsidiary.styled.counters.imageSection.gas") : (item.type === "teleheating" ? i18next.t("page.private.subsidiary.styled.counters.imageSection.teleheating") : i18next.t("page.private.subsidiary.styled.counters.imageSection.cold"))))}</p>
                        </div>
                        <div className='label-row'>
                            <p className='label'>{i18next.t("page.private.subsidiary.styled.counters.imageSection.name")}</p>
                            <p className='data'>{item.name}</p>
                        </div>
                        <div className='label-row'>
                            <p className='label'>{i18next.t("page.private.subsidiary.styled.counters.imageSection.location")}</p>
                            <p className='data'>{item.position}</p>
                        </div>
                        <div className='label-row'>
                            <p className='label'>{i18next.t("page.private.subsidiary.styled.counters.imageSection.number")}</p>
                            <p className='data'>{item.number}</p>
                        </div>
                        <div className='label-row'>
                            <p className='label'>{i18next.t("page.private.subsidiary.styled.counters.imageSection.recorded")}</p>
                            <p className='data'>{(item.readings.length ? moment(item.readings.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())[0].createdAt).format("DD.MM.YYYY") : "Noch nie")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </CounterComponent>
    }
}
