import styled from "styled-components"

export default styled.div`
  width: fit-content;
  margin: 5px 10px 5px 0;
  padding: 5px 10px;
  border-radius: 125px;
  border: 1px solid rgba(0, 0, 0, .4);
  transition: all 250ms ease;
  position: relative;
  cursor: default;

  i {
    top: 50%;
    left: 10px;
    position: absolute;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 400ms ease;
    cursor: default;
  }
  
  &.editable {
    &:hover {
      padding-left: 25px;
      opacity: 1;

      i {
        opacity: 100%;
        cursor: pointer;
      }
    } 
  }
`