import React, { Component } from "react"
import { Page, Breadcrumbs, GridElement, Table } from "scanmetrix-components"
import styled from "styled-components"

import { withRouter } from "react-router-dom"

import i18next from "i18next"

/*
import ReactExport from "react-data-export"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelSheet*/

const DueDates = styled.div`
  .dot {
      height: 100%;
      display: inline-flex;
      align-items: center;
      margin-right: 8px;

      &.red:after {
        background: #c0392b;
      }

      &.yellow:after {
        background: #e67e22;
      }

      &.green:after {
        background: #27ae60;
      }

      &:after {
        content: " ";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
  }
`

export { DueDates }

export default withRouter(class extends Component {
    state = { dueDates: null }

    constructor(props) {
        super(props)

        fetch(scanmetrix.backendURL + "/due-dates", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => this.setState({ dueDates: data.facilities }))
    }

    render() {
        /*const items = [
            { region: <><b>0</b>XXXX</>, due: <><b>393</b> Objekte aus <b>24</b> Anlagen in <b>9</b> Niederlassungen</>, cost: 43453.46 },
            { region: <><b>1</b>XXXX</>, due: <><b>234</b> Objekte aus <b>14</b> Anlagen in <b>7</b> Niederlassungen</>, cost: 35427.86 },
            { region: <><b>2</b>XXXX</>, due: <><b>835</b> Objekte aus <b>34</b> Anlagen in <b>8</b> Niederlassungen</>, cost: 55843.12 },
            { region: <><b>3</b>XXXX</>, due: <><b>13</b> Objekte aus <b>3</b> Anlagen in <b>2</b> Niederlassungen</>, cost: 6491.55 },
            { region: <><b>4</b>XXXX</>, due: <><b>367</b> Objekte aus <b>15</b> Anlagen in <b>5</b> Niederlassungen</>, cost: 12344.8 },
            { region: <><b>5</b>XXXX</>, due: <><b>286</b> Objekte aus <b>8</b> Anlagen in <b>3</b> Niederlassungen</>, cost: 13451.25 },
            { region: <><b>6</b>XXXX</>, due: <><b>531</b> Objekte aus <b>12</b> Anlagen in <b>7</b> Niederlassungen</>, cost: 32215.4 },
            { region: <><b>7</b>XXXX</>, due: <><b>472</b> Objekte aus <b>5</b> Anlagen in <b>4</b> Niederlassungen</>, cost: 26410.2 },
            { region: <><b>8</b>XXXX</>, due: <><b>772</b> Objekte aus <b>7</b> Anlagen in <b>6</b> Niederlassungen</>, cost: 49440.98 },
            { region: <><b>9</b>XXXX</>, due: <><b>119</b> Objekte aus <b>4</b> Anlagen in <b>3</b> Niederlassungen</>, cost: 9244.5 }
        ]

        const result = items.map(item => item.cost).reduce((a, b) => a + b)*/

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "history",
                    title: i18next.t("page.private.dueDates.breadCrumbsTitle")
                }
            ]} />
            {/*<Grid columns={[ "1fr", "2fr" ]}>
                <GridElement columnStart={1} columnEnd={2}>
                    <Grid padding="0" gap="32px" columns={[ "1fr" ]}>
                        <GridElement styled title="Planung" icon="cog" rightContent={<Button title="Auswerten" icon="function" />}>
                            <Grid gap="16px" padding="16px" columns={[ "1fr" ]}>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input label="Jahr" icon="calendar" adjustWidth />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input label="Zeitraum" icon="hourglass" adjustWidth />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input label="Gruppierung" icon="object-group" adjustWidth />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input label="Varianz" icon="arrow-up" adjustWidth />
                                </GridElement>
                            </Grid>
                        </GridElement>
                        <GridElement styled title="Bereinigtes Ergebnis" icon="coins" rightContent={<Result>{formatter.format(result)}</Result>} />
                    </Grid>
                </GridElement>
                <GridElement padding="0" styled title="Fälligkeiten" icon="history" columnStart={2} columnEnd={3}>
                    <Table
                        head={[
                            { title: "Region", width: "10%", column: "region" },
                            { title: "Fälligkeiten", width: "70%", column: "due" },
                            { title: "Wartungskosten", width: "20%", column: "cost", format: formatter.format }
                        ]}
                        items={items}
                    />
                </GridElement>
            </Grid>*/
            <GridElement padding="0" styled title={i18next.t("page.private.dueDates.gridElementTitle")} icon="history" columnStart={2} columnEnd={3}>
                <DueDates>
                    <Table
                        head={[
                            { title: i18next.t("page.private.dueDates.table.branch"), width: "10%", column: "branch" },
                            { title: i18next.t("page.private.dueDates.table.code"), width: "12.5%", column: "label" },
                            { title: i18next.t("page.private.dueDates.table.location"), width: "20%", column: "location" },
                            { title: i18next.t("page.private.dueDates.table.component"), width: "10%", column: "component" },
                            { title: i18next.t("page.private.dueDates.table.duration"), width: "17.5%", column: "amount" },
                            { title: i18next.t("page.private.dueDates.table.building"), width: "12.5%", column: "building" },
                            { title: i18next.t("page.private.dueDates.table.due"), width: "12.5%", column: "due" },
                            { title: i18next.t("page.private.dueDates.table.plant"), width: "15%", column: "planned" }
                        ]}
                        onItemClick={facility => this.props.history.push(`/facility/${facility.serviceProvider.id}/${facility.id}`)}
                        contextItems={[ 
                            { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: facility => window.open(`/facility/${facility.serviceProvider.id}/${facility.id}`, '_blank')}
                        ]}
                        items={this.state.dueDates ? this.state.dueDates.map(dueDate => {
                            const due = moment(dueDate.due, "DD.MM.YYYY")

                            const workOrders = dueDate.workOrders.filter(wo => !!wo.planned_date).sort((a, b) => moment(a.planned_date, "YYYY-MM-DD HH:mm:ss").valueOf() - moment(b.planned_date, "YYYY-MM-DD HH:mm:ss").valueOf())
                            const workOrder = workOrders.length ? workOrders[0] : null

                            const appointments = dueDate.appointments

                            return ({
                                component: dueDate.component,
                                id: dueDate.id,
                                label: dueDate.label,
                                branch: <img style={{ height: 48, width: "100%", objectFit: "contain" }} src={scanmetrix.backendURL + "/logo/" + dueDate.subsidiary.branch.id} />,
                                location: `${dueDate.postalCode} ${dueDate.city}`,
                                amount: `${dueDate.amount || "?"} Stk. (${dueDate.name})`,
                                building: dueDate.building,
                                subsidiaryId: dueDate.subsidiary.id,
                                due: <><div className={`dot ${due.isSameOrBefore(moment()) ? "red" : (moment().add(dueDate.intervalAmount / 10, dueDate.intervalType).isSameOrBefore(due) ? "green" : "yellow")}`} />{due.fromNow()}</>,
                                planned: workOrder ? <><div className="dot green" />KW {moment(workOrder.planned_date, "YYYY-MM-DD HH:mm:ss").week()} / {moment(workOrder.planned_date, "YYYY-MM-DD HH:mm:ss").format('MMMM YY')}</> : (appointments.length > 0 ? <><div className="dot green" />KW {moment(appointments[0].date, "YYYY-MM-DD HH:mm:ss").week()} / {moment(appointments[0].date, "YYYY-MM-DD HH:mm:ss").format('MMMM YY')}</> : null),
                                serviceProvider: dueDate.serviceProvider
                            })
                        }) : null}
                    />
                </DueDates>
            </GridElement>}
        </Page>
    }
})
/*<ExcelFile filename="Fälligkeiten" element={<Button onClick={() => {}} green thick title="Als Excel-Datei exportieren" icon="file-excel" />}>
                {/*<ExcelSheet dataSet={[
                    {
                        columns: [
                            { title: "Zweig", width: { wpx: 150 }, style: { font: { sz: "13" } } },
                            { title: "Kennzeichnung", width: { wpx: 200 }, style: { font: { sz: "13" } } },
                            { title: "Ort", width: { wpx: 200 }, style: { font: { sz: "13" } } },
                            { title: "Bestand", width: { wpx: 250 }, style: { font: { sz: "13" } } },
                            { title: "Gebäude", width: { wpx: 200 }, style: { font: { sz: "13" } } },
                            { title: "Fällig", width: { wpx: 100 }, style: { font: { sz: "13" }, alignment: { horizontal: "right" } } },
                        ],
                        data: this.state.dueDates ? this.state.dueDates.map(dueDate => [
                            { value: String(dueDate.subsidiary.branch.name), style: { font: { sz: "9" } } },
                            { value: String(dueDate.label) },
                            { value: String(`${dueDate.postalCode} ${dueDate.city}`) },
                            { value: String(`${dueDate.amount || "?"} Stk. (${dueDate.name})`), style: { font: { sz: "9" } } },
                            { value: String(dueDate.building), style: { font: { sz: "9" } } },
                            { value: String(moment(dueDate.due, "DD.MM.YYYY").format("MM / YYYY")), style: { font: { sz: "9" }, alignment: { horizontal: "right" } } }
                        ]) : []
                    }
                ]} name="Fälligkeiten" />
            </ExcelFile>*/
