import styled from "styled-components"

export default styled.div`
  display: flex;
  height: 64px;
  align-items: flex-end;
  
  .exampleData {
    text-align: right;
    align-self: center;
    font-size: 0.8em;
    color: #3B97D3;
    user-select: none;
    
    @media screen and (max-width: 1920px) {
      font-size: 0.7em;
    }
  }
  
  .item {
    list-style: none;
    height: 56px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 16px;
    background: white;
    color: #20242b;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;
    font-size: 0.8em;
      white-space: nowrap;
      flex-direction: column;
      
      @media screen and (max-width: 1920px) {
      padding: 0 8px;
      font-size: 0.7em;
    }
      
      &.locked {
        cursor: not-allowed;
        opacity: 1 !important;
        background: rgba(255, 255, 255, 0.5);
      }
      
      i.fa-lock {
        color: #000;
        font-size: 1.1em;
        display: flex;
        align-items: center;
        margin-right: 0 !important;
      }
    
    .value {
      display: flex;
      margin-top: 8px;
      font-size: 0.8em;
      font-weight: bold;
      justify-content: center;
      height: 16px;
      
      div {
        padding: 0 4px;
        height: 16px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        transition: all 0.3s;
      }
      
      &.red {
        color: #e74c3c;
      }
      
      &.green {
        color: #2ecc71;
      }
      
      i {
        margin-right: 6px;
      }
    }
    
    &:hover {
      opacity: 0.9;
    }
    
    &:nth-child(3) {
      border-radius: 0 16px 0 0;
    }
    
    &:first-child {
      border-radius: 16px 0 0 0;
    }
    
    &.selected {
      border-radius: 8px 8px 0 0;
      background: #45607c;
      color: white;
      height: 64px;
      
      .value div {
        background: white;
      }
    }
  }
  
  @media screen and (max-width: 1919px) {
    .exampleData {
      padding-left: 32px;
    }
  }
`
