import React, { Component } from "react"
import { Page, Breadcrumbs, Table, GridElement, Button, Section } from "scanmetrix-components"
import CreateWorkflowModal from "./modals/CreateWorkflowModal"
import CreateWorkflowExecutionModal from "./modals/CreateWorkflowExecutionModal"

import Avatar from "react-avatar";
import styled from "styled-components";
import DeleteWorkflowExecutionModal from "./modals/DeleteWorkflowExecutionModal";
import DeleteWorkflowModal from "./modals/DeleteWorkflowModal";
import i18next from "i18next"

const Status = styled.div`
    position: absolute;
    left: -16px;
    width: 32px;
    height: 32px;
    background: ${props => props.yellow ? "#e67e22" : (!!props.status ? "#27ae60" : (props.status === false ? "#c0392b" : "#2c3e50"))};
    border-radius: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 16px);
    font-size: 0.9em;
    box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);

    i {
        margin: 0 !important;
    }
`

export default class extends Component {
    state = { workflows: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Workflows {
                        nodes {
                            id
                            name
                            executions {
                                id
                                finishedAt
                            }
                        }
                    }
                    WorkflowExecutions {
                        nodes {
                            id
                            contextType
                            createdAt
                            finishedAt
                            phase {
                                id
                                color
                                name
                            }
                            steps {
                                id
                                data
                            }
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            team {
                                id
                                name
                            }
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    postalCode
                                    city
                                }
                            }
                            workflow {
                                name
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({
                workflows: data.data.Workflows.nodes,
                workflowExecutions: data.data.WorkflowExecutions.nodes
            })
        })
    }

    render() {
        return <Page {...this.props}>
            <CreateWorkflowModal refresh={() => this.fetch()} instance={ref => this.createWorkflowModal = ref} />
            <CreateWorkflowExecutionModal history={this.props.history} workflows={this.state.workflows || []} instance={ref => this.createWorkflowExecutionModal = ref} />
            <DeleteWorkflowExecutionModal refresh={() => this.fetch()} instance={ref => this.deleteWorkflowExecutionModal = ref} />
            <DeleteWorkflowModal refresh={() => this.fetch()} instance={ref => this.deleteWorkflowModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "project-diagram",
                    title: i18next.t("page.private.workFlows.title")
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.workFlows.executions.sectionTitle")} icon="play" amount={this.state.workflowExecutions ? this.state.workflowExecutions.length : 0}>
                    <GridElement styled title={i18next.t("page.private.workFlows.executions.gridElementTitle")} icon="play" rightContent={<Button thick icon="play" title={i18next.t("page.private.workFlows.executions.button")} green onClick={() => this.createWorkflowExecutionModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.workFlows.executions.table.workflow"), width: "17.5%", column: "workflow" },
                                { title: i18next.t("page.private.workFlows.executions.table.context"), width: "12.5%", column: "context" },
                                { title: i18next.t("page.private.workFlows.executions.table.branch"), width: "15%", column: "subsidiary" },
                                { title: i18next.t("page.private.workFlows.executions.table.team"), width: "12.5%", column: "team" },
                                { title: i18next.t("page.private.workFlows.executions.table.user"), width: "12.5%", column: "user" },
                                { title: i18next.t("page.private.workFlows.executions.table.createdAt"), width: "10%", column: "createdAt" },
                                { title: i18next.t("page.private.workFlows.executions.table.phase"), width: "15%", column: "phase" },
                                { title: i18next.t("page.private.workFlows.executions.table.openSteps"), width: "10%", column: "openSteps" }
                            ]}
                            onItemClick={workflowExecution => this.props.history.push(`/workflow-execution/${workflowExecution.id}`)}
                            items={this.state.workflowExecutions ? this.state.workflowExecutions.sort((a, b) => (a.finishedAt ? moment(a.finishedAt).valueOf() : 0) - (b.finishedAt ? moment(b.finishedAt).valueOf() : 0)).map(execution => ({
                                id: execution.id,
                                workflow: <div style={{ paddingLeft: "16px" }}><Status yellow={!execution.finishedAt} status={true}><i className={`far fa-${execution.finishedAt ? "check" : "play"}`} /></Status>{execution.workflow.name}</div>,
                                team: execution.team ? execution.team.name : null,
                                user: execution.user ? <div style={{ display: "flex", alignItems: "center" }}><div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}><Avatar size="32" email={scanmetrix.disableGravatar ? null : execution.user.email} name={`${execution.user.firstName} ${execution.user.lastName}`} className="avatarInner" /></div>{execution.user.firstName} {execution.user.lastName}</div> : null,
                                subsidiary: execution.subsidiary ? `${execution.subsidiary.name} (${execution.subsidiary.label}) / ${execution.subsidiary.address.postalCode} ${execution.subsidiary.address.city}` : null,
                                context: execution.contextType === "user" ? <><i className="far fa-user" />{i18next.t("page.private.workFlows.executions.user")}</> : (execution.contextType === "subsidiary" ? <><i className="far fa-map-marker" />{i18next.t("page.private.workFlows.executions.branch")}</> : (execution.contextType === "team" ? <><i className="far fa-users-crown" />{i18next.t("page.private.workFlows.executions.team")}</> : (execution.contextType === "flaw" ? <><i className="far fa-bolt" />{i18next.t("page.private.workFlows.executions.defect")}</> : <><i className="far fa-question" />{i18next.t("page.private.workFlows.executions.other")}</>))),
                                createdAt: moment(execution.createdAt).format("DD.MM.YYYY"),
                                openSteps: <div style={{ color: "white", background: execution.steps.filter(step => step.data && !step.data.done).length > 0 ? "#3b97d3" : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{execution.steps.filter(step => step.data && !step.data.done).length} {i18next.t("page.private.workFlows.executions.tasks")}</div>,
                                phase: <div style={{ color: "white", background: execution.phase ? execution.phase.color : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{execution.phase ? execution.phase.name : i18next.t("page.private.workFlows.none")}</div>
                            })) : null}
                            contextItems={[
                                { title: i18next.t("page.private.workFlows.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: workflowExecution => window.open(`/workflow-execution/${workflowExecution.id}`, '_blank')},
                                { title: i18next.t("page.private.workFlows.entryDelete"), icon: "trash", onClick: item => this.deleteWorkflowExecutionModal.open(item.id)}
                            ]}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.workFlows.work-Flows.sectionTitle")} icon="project-diagram" amount={this.state.workflows ? this.state.workflows.length : 0}>
                    <GridElement styled title={i18next.t("page.private.workFlows.work-Flows.gridElementTitle")} icon="project-diagram" rightContent={<Button thick icon="project-diagram" title={i18next.t("page.private.workFlows.work-Flows.button")} onClick={() => this.createWorkflowModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.workFlows.work-Flows.table.name"), width: "80%", column: "name" },
                                { title: i18next.t("page.private.workFlows.work-Flows.table.executions"), width: "20%", column: "executions" }
                            ]}
                            onItemClick={workflow => this.props.history.push(`/workflow/${workflow.id}`)}

                            items={this.state.workflows ? this.state.workflows.map(workflow => ({
                                ...workflow,
                                executions: <div style={{ color: "white", background: workflow.executions.filter(exec => !exec.finishedAt).length > 0 ? "#3b97d3" : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{workflow.executions.filter(exec => !exec.finishedAt).length} {i18next.t("page.private.workFlows.work-Flows.status")}</div>
                            })) : null}
                            contextItems={[
                                { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: workflow => window.open(`/workflow/${workflow.id}`, '_blank')},
                                { title: i18next.t("page.private.workFlows.work-Flows.deleteButton"), icon: "trash", onClick: item => this.deleteWorkflowModal.open(item.id)}
                            ]}
                        />
                    </GridElement>
                </Section.Item>
                {/*<Section.Item title="Reporting" icon="file-chart-line" disabled>
                </Section.Item>*/}
            </Section>
        </Page>
    }
}
