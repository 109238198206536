import React, { Component } from "react"

import { Modal, Button, Select, Input, Grid, GridElement } from "scanmetrix-components"

import i18next from "i18next";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = { startingAt: null, intervalAmount: null, intervalType: null, name: null, cost: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ cost: null, customName: null, startingAt: null, intervalAmount: null, intervalType: null, name: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.title")} icon="history" />
            <Modal.Body adjustHeight={328} padding={24}>
                <Grid gap="16px" columns={[ "1.5fr", "1fr", "0.75fr" ]}>
                    <GridElement columnStart={1} columnEnd={4}>
                        <Input date value={this.state.startingAt} onChange={startingAt => this.setState({ startingAt })} icon="hourglass-start" label={i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.startsOn")} adjustWidth />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={3}>
                        <Select required adjustWidth label={i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.name")} icon="tag" value={this.state.name} onChange={name => this.setState({ name })} items={[
                            { key: "Instandhaltung", title: "Instandhaltung" },
                            { key: "Wartung", title: "Wartung" },
                            { key: "Service", title: "Service" },
                            { key: "Prüfung", title: "SV-Prüfung" },
                            { key: "custom", title: "Benutzerdefiniert" }
                        ]} />
                    </GridElement>
                    <GridElement columnStart={3} columnEnd={4}>
                        <Input optional float value={this.state.cost} icon="piggy-bank" onChange={cost => this.setState({ cost })} label={i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.netCosts")} adjustWidth format={val => val !== null ? formatter.format(val) : val} />
                    </GridElement>
                    {this.state.name === "custom" && <GridElement columnStart={1} columnEnd={3}>
                        <Input required adjustWidth label={i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.userDefinedDescription")} icon="font" value={this.state.customName} onChange={customName => this.setState({ customName })} />
                    </GridElement>}
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input required int value={this.state.intervalAmount} onChange={intervalAmount => this.setState({ intervalAmount })} icon="tally" label={i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.intervalQuantity")} adjustWidth />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={4}>
                        <Select required value={this.state.intervalType} onChange={intervalType => this.setState({ intervalType })} icon="calendar-week" label={i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.intervalUnit")} adjustWidth items={[
                            { key: "days", title: i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.intervalUnitTypes.days") },
                            { key: "weeks", title: i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.intervalUnitTypes.weeks") },
                            { key: "months", title: i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.intervalUnitTypes.months") },
                            { key: "years", title: i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.intervalUnitTypes.years") },
                        ]} />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.contract.modals.createContractProvisionIntervalModal.exitButton")} disabled={!this.state.name || (this.state.name === "custom" && !this.state.customName) || !this.state.intervalAmount || !this.state.intervalType} icon="history" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($contractId: ID!, $startingAt: DateTime, $name: String!, $intervalAmount: Int!, $intervalType: String!, $cost: Float) {
                            createContractProvisionInterval(contractId: $contractId, startingAt: $startingAt, name: $name, intervalAmount: $intervalAmount, intervalType: $intervalType, cost: $cost)
                        }
                    `,
                    variables: {
                        contractId: this.props.contractId,
                        startingAt: this.state.startingAt ? moment(this.state.startingAt, "DD.MM.YYYY").toDate() : null,
                        name: this.state.name === "custom" ? this.state.customName : this.state.name,
                        intervalAmount: this.state.intervalAmount,
                        intervalType: this.state.intervalType,
                        cost: this.state.cost
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createContractProvisionInterval) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
