import React, { Component } from "react"
import { Page, Section, Button, Grid, GridElement, Checkbox } from "scanmetrix-components"
import styled from "styled-components"
import Avatar from "react-avatar"

import Header from './styled/Header'
import Box from './styled/Box'
import TransparentTitle from './styled/TransparentTitle'
import AboutBody from './styled/AboutBody'
import AgendaBody from './styled/AgendaBody'
import ContactPerson from './styled/ContactPerson'
import PermissionBox from './styled/PermissionBox'
{/*import Devices from './styled/Devices'*/}
import AnimatedDiv from './styled/AnimatedDiv'
import QRCodeModal from './modals/QRCodeModal'
import SocialMediaModal from './modals/SocialMediaModal'
import i18next from "i18next";

import Post from '../serviceProvider/styled/Post'
import QRCode from "qrcode.react";

const ProfileView = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  
  @media screen and (max-width: 1280px) {
    zoom: 0.8;
  }
`

const Body = styled.div`
  width: 100%;
`

const GridBody = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const ContactBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    padding: 0;
    resize: none;
    height: 32px;
    border-radius: 10px;
    max-height: 500px;
    font-size: 1em;
    font-family: inherit;
    border: 1px solid transparent;
    box-shadow: none;
    outline: none;
    cursor: default;
    pointer-events: none;
    transition: all 250ms ease;

    &.editable {
      resize: vertical;
      border: 1px solid rgba(59, 141, 211, .5);
      padding: 8px;
      cursor: text;
      pointer-events: auto;
    }
  }
  
  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .label {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
`

const Tag = styled.div`
  width: fit-content;
  margin: 5px 10px 5px 0;
  padding: 5px 10px;
  border-radius: 125px;
  border: 1px solid rgba(0, 0, 0, .4);
  transition: all 250ms ease;
  position: relative;
  cursor: pointer;
  display: flex;
  
  input {
      outline: 0;
      margin-left: 6px;
      border: 1px solid rgba(0, 0, 0, 0.4);
      border-radius: 6px;
      padding: 4px;
      font-family: inherit;
  }
  
  &.inactive {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    opacity: 0.75;
  }
`

const ExistingTag = styled.div`
  width: fit-content;
  margin: 5px 10px 5px 0;
  padding: 5px 10px;
  border-radius: 125px;
  border: 1px solid rgba(0, 0, 0, .4);
  transition: all 250ms ease;
  position: relative;
  cursor: default;

  i {
    top: 50%;
    left: 10px;
    position: absolute;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 400ms ease;
    cursor: default;
  }
  
  &.editable {
    &:hover {
      padding-left: 25px;
      opacity: 1;

      i {
        opacity: 100%;
        cursor: pointer;
      }
    } 
  }
`

const ButtonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 32px;
`

const Devices = styled.div`
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  user-select: none;

  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  grid-auto-rows: 512px;
  grid-gap: 32px;
  box-sizing: border-box;
  
  .device-box {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 10px;
    box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    
    .type {
      padding: 8px 16px;
      display: inline;
      background: #20242B;
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      border-radius: 0 0 5px 0;
      font-weight: bold;
      user-select: none;
      z-index: 15;
    }
    
    .logo-view {
      width: 100%;
      height: 50%;
      background: linear-gradient(45deg, #3B97D3, #7CCBFF);
      display: flex;
      justify-content: center;
      align-items: center;
      
      .logo-inner {
        width: 75px;
        height: 75px;
        background: white;
        border-radius: 125px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: #20242B;
          font-size: 3em;
        }
      }
    }
    
    .info-view {
      width: 100%;
      height: 50%;
      background: white;
      display: grid;
      grid-template-rows: repeat(3, minmax(0, 1fr));
      
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, .0625);
        
        &:last-child {
          border-bottom: none;
        }
        
        h1 {
          padding: 8px;
          box-sizing: border-box;
          font-size: 1.4em;
          text-align: center;
        }
      }
      
      .item {
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        
        p {
          text-align: center;
          color: #20242B;
          
          &:first-child {
            margin-bottom: 8px;
            font-size: 1.2em;
          }
          
          &:last-child {
            font-size: 0.9em;
          }
        }
      }
    }
  }
`

const EmptyGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  
  img {
    max-width: 512px;
    height: auto;
    pointer-events: none;
    user-drag: none;
    filter: grayscale(1);
    opacity: 0.25;
  }
  
  .text-view {
    margin-left: 48px;
    max-width: 512px;
    
    h1 {
      font-size: 1.6em;
      line-height: 1.2em;
      margin-bottom: 16px;
      font-weight: normal;
    }
    
    p {
      font-size: 1.2em;
      
      b {
        color: #3B97D3;
        font-weight: inherit;
      }
    }
  }
`

class DeviceGrid extends Component {
    render() {
        {/*
        return <Devices>
            {this.props.data.map((device, index) => {
                return <div className='device-box' key={index}>
                    <div className='image'>
                        <img src={ device.type === 'phone' ? './iphonexs.png' : device.type === 'tablet' ? './tablet.png' : null } />
                    </div>
                    <div className='info'>
                        <div className='text-view'>
                            <h1>{device.name}</h1>
                            <p className='model'>{device.model}</p>
                            <p className='uuid'>{device.uuid}</p>
                        </div>
                        <div className='button-view'>
                            <Button red thick title='Gerät entfernen' icon='trash' />
                        </div>
                    </div>
                </div>
            })}
        </Devices>*/}

        return <>
            {this.props.data.length > 0 ?
            <Devices>
                { this.props.data.map(( device, index ) => {
                    return <div className='device-box' key={index}>
                        <div className='type'>{ device.type === 'phone' ? 'Smartphone' : device.type === 'tablet' ? 'Tablet' : null }</div>
                        <div className='logo-view'>
                            <div className='logo-inner'>
                                <i className={ device.os === 'ios' ? 'fab fa-apple' : device.os === 'android' ? 'fab fa-android' : null } />
                            </div>
                        </div>
                        <div className='info-view'>
                            <div className='center'>
                                <h1 className='title'>{device.name}</h1>
                            </div>
                            <div className='item'>
                                <p className='model'>{device.model}</p>
                                <p className='uuid'>{device.uuid}</p>
                            </div>
                            <div className='center'>
                                <Button thick red title={i18next.t("page.private.profile.device.removeDeviceButton")} icon='trash' />
                            </div>
                        </div>
                    </div>
                })}
            </Devices> :
            <EmptyGrid>
                <img src='/illustrations/devices.svg' />
                <div className='text-view'>
                    <h1>{i18next.t("page.private.profile.device.deviceText1")}</h1>
                    <p>{i18next.t("page.private.profile.device.deviceText2")}</p>
                </div>
            </EmptyGrid>
            }
        </>
    }
}


class Skills extends Component {
    state = { typing: false, newTag: null }

    render() {
        return <>
            {this.props.tags.map((tag, index ) => {
                return <ExistingTag className={this.props.profileEditable ? 'editable' : ''} key={index}>
                    <i className='far fa-times' onClick={() => this.props.deleteTag(index)} />
                    <p>{tag}</p>
                </ExistingTag>
            })}
            <Tag onClick={() => this.setState({ typing: true })} className={this.props.profileEditable ? '' : 'inactive' }>
                <i className='far fa-plus' />
                {this.state.typing && <input spellCheck={false} value={this.state.newTag || ""} onChange={e => this.setState({ newTag: e.target.value })} onKeyDown={e => {
                    if(e.key === "Enter") {
                        this.props.newTag(this.state.newTag)
                        this.setState({ newTag: null, typing: false })
                    }}}
                />}
            </Tag>
        </>
    }
}

class ContactedPeople extends Component {
    state = {

    }

    render() {
        return <>
            {this.props.data.map(( item, index ) => {
                return <ContactPerson key={index}>
                    <Avatar className="avatar" size="50" email={scanmetrix.disableGravatar ? null : scanmetrix.session.email} name={`${scanmetrix.session.firstName} ${scanmetrix.session.lastName}`} />
                    <div className='text-view'>
                        <h2>{item.name}</h2>
                        <p className='position'>{item.position}</p>

                        <Button title={i18next.t("page.private.profile.chatButton")} icon='paper-plane' />
                    </div>
                </ContactPerson>
            })}
        </>
    }
}

export default class extends Component {
    state = {
        devices: [],
        tags: [],
        profileEditable: false,
        dailyReport: false,
        showHints: false,
        description: "",
        landline: null,
        mobile: null,
        fax: null,
        loading: false,
        profileSettings: {}
    }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                {
                    Session {
                        id
                        dailyReport
                        showHints
                        profileSettings
                    }
                }
            `
        }).then(data => this.setState({ tags: data.data.Session.profileSettings.tags || [], description: data.data.Session.profileSettings.description, landline: data.data.Session.profileSettings.landline, mobile: data.data.Session.profileSettings.mobile, fax: data.data.Session.profileSettings.fax, dailyReport: data.data.Session.dailyReport, showHints: data.data.Session.showHints }))
    }

    render() {
        return <Page {...this.props} maxSize="1600px" fullHeight full>
            <QRCodeModal instance={ref => this.linkDevice = ref} />
            <SocialMediaModal instance={ref => this.linkSocialMedia = ref} />
            <ProfileView>
                <Header>
                    <div className='header-left'>
                        <Avatar className="avatar" size="128" email={scanmetrix.disableGravatar ? null : scanmetrix.session.email} name={`${scanmetrix.session.firstName} ${scanmetrix.session.lastName}`} />
                        <div className='text-view'>
                            <h1>{scanmetrix.session.firstName} {scanmetrix.session.lastName}</h1>
                            <div style={{gap:"4px", display: "flex", flexDirection: "column"}}>
                            <Checkbox label={i18next.t("page.private.profile.searchPlaceholder")} value={this.state.dailyReport} onChange={dailyReport => {
                                scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                    mutation($dailyReport: Boolean!) {
                                        updateDailyReport(dailyReport: $dailyReport)
                                    }        
                                `,
                                    variables: {
                                        dailyReport
                                    }
                                })
                                this.setState({ dailyReport })
                            }} />
                            <Checkbox 
                                    label={"Anzeigen von Hints"} 
                                    value={this.state.showHints} 
                                    onChange={showHints => {
                                        console.log(showHints);
                                        
                                        scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($showHints: Boolean!) {
                                            updateShowHints(showHints: $showHints)
                                            }        
                                        `,
                                        variables: {
                                            showHints
                                        }
                                        });

                                        if (!showHints) {

                                            if(!document.getElementById("pf-style")){
                                                const style = document.createElement('style');
                                            style.setAttribute("id", "pf-style")
                                            document.head.appendChild(style);
                                            style.type = "text/css";
                                            style.appendChild(document.createTextNode(`
                                                .productfruits--container {
                                            display: none !important;
                                                }
                                            `));
                                            }
                                            
                                        }else {
                                            const name = document.getElementById("pf-style")
                                            console.log(name)
                                                if (name) {
                                                    console.log("Remove")
                                                   name.parentNode.removeChild(name);
                                                }
                                        }
                                        this.setState({ showHints });
                                    }} 
                                    />

                            </div>
                            
                            <AnimatedDiv value={scanmetrix.session.position} index={this.state.profileEditable ? 0 : 0 } icon='hard-hat' />
                            <Button loading={this.state.loading} thick green={this.state.profileEditable} title={ this.state.profileEditable ? i18next.t("page.private.profile.profileButton.saveInformation") : i18next.t("page.private.profile.profileButton.editProfile") } icon={ this.state.profileEditable ? 'clipboard-check' : 'pencil-alt' } onClick={() => {
                                if(this.state.profileEditable) {
                                    this.setState({ loading: true, profileEditable: false })

                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($settings: JSON!) {
                                                updateProfileSettings(settings: $settings)
                                            }
                                        `,
                                        variables: {
                                            settings: {
                                                description: this.state.description,
                                                tags: this.state.tags,
                                                landline: this.state.landline,
                                                mobile: this.state.mobile,
                                                fax: this.state.fax
                                            }
                                        }
                                    }).then(() => this.setState({ loading: false }))
                                } else {
                                    this.setState({ profileEditable: true })
                                }
                            }} />
                        </div>
                    </div>{/*
                    <div className='header-right'>
                        <div className='info-view'>
                            <div className='row'>
                                <div className='label'>
                                    <p>Erreichbarkeit:</p>
                                </div>
                                <div className='input'>
                                    <AnimatedDiv value='Jederzeit' index={this.state.profileEditable ? 1 : 0 } icon='wrench' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='label'>
                                    <p>Standort:</p>
                                </div>
                                <div className='input'>
                                    <AnimatedDiv value='45478 Mülheim an der Ruhr' index={this.state.profileEditable ? 1 : 0 } icon='map-marker' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='label'>
                                    <p>Anmeldedatum:</p>
                                </div>
                                <div className='input'>
                                    <AnimatedDiv value='01.04.2020' index={this.state.profileEditable ? 1 : 0 } icon='calendar-day' />
                                </div>
                            </div>
                        </div>

                        {/*
                        <div className='social-media'>
                            <i className={`fab ${ this.state.profileEditable ? '' : 'disabled' } fa-linkedin`} onClick={() => this.linkSocialMedia.open()} />
                            <i className={`fab ${ this.state.profileEditable ? '' : 'disabled' } fa-xing`} onClick={() => this.linkSocialMedia.open()} />
                            <i className={`fab ${ this.state.profileEditable ? '' : 'disabled' } fa-facebook`} onClick={() => this.linkSocialMedia.open()} />
                            <i className={`fab ${ this.state.profileEditable ? '' : 'disabled' } fa-twitter`} onClick={() => this.linkSocialMedia.open()} />
                        </div>
                    </div>*/}
                </Header>

                <Body>
                    <Section bodyPadding="32px 0 0 0">
                        <Section.Item title={i18next.t("page.private.profile.profileTitle")} icon='id-card'>
                            <Grid gap="32px" columns={[ "256px", "1fr", "256px"]}>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <GridBody>
                                        <Box>
                                            <div className='header'>
                                                <i className='far fa-id-card' />
                                                <p>{i18next.t("page.private.profile.contact.title")}</p>
                                            </div>
                                            <div className='body'>
                                                <ContactBody>
                                                    <div className='row'>
                                                        <p className='label'>{i18next.t("page.private.profile.contact.landlineNumber")}</p>
                                                        <input readOnly={!this.state.profileEditable} spellCheck={false} autoCorrect={false} onChange={e => {
                                                            this.setState({ landline: e.target.value })
                                                        }} placeholder={i18next.t("page.private.profile.contact.noInfo1")} className={ this.state.profileEditable ? 'editable' : '' } value={this.state.landline || (this.state.profileEditable ? "" : i18next.t("page.private.profile.contact.noInfo4"))} />
                                                    </div>
                                                    <div className='row'>
                                                        <p className='label'>{i18next.t("page.private.profile.contact.mobileNumber")}</p>
                                                        <input readOnly={!this.state.profileEditable} spellCheck={false} autoCorrect={false} onChange={e => {
                                                            this.setState({ mobile: e.target.value })
                                                        }} placeholder={i18next.t("page.private.profile.contact.noInfo2")} className={ this.state.profileEditable ? 'editable' : '' } value={this.state.mobile || (this.state.profileEditable ? "" : i18next.t("page.private.profile.contact.noInfo5"))} />
                                                    </div>
                                                    <div className='row'>
                                                        <p className='label'>{i18next.t("page.private.profile.contact.faxNumber")}</p>
                                                        <input readOnly={!this.state.profileEditable} spellCheck={false} autoCorrect={false} onChange={e => {
                                                            this.setState({ fax: e.target.value })
                                                        }} placeholder={i18next.t("page.private.profile.contact.noInfo3")} className={ this.state.profileEditable ? 'editable' : '' } value={this.state.fax || (this.state.profileEditable ? "" : i18next.t("page.private.profile.contact.noInfo6"))} />
                                                    </div>
                                                    <div className='row'>
                                                        <p className='label'>{i18next.t("page.private.profile.contact.email")}</p>
                                                        {scanmetrix.session.email}
                                                    </div>
                                                </ContactBody>
                                            </div>
                                        </Box>
                                    </GridBody>
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={4}>
                                    <AboutBody>
                                        <section className='about-text'>
                                            <h1>{i18next.t("page.private.profile.description.title")}</h1>
                                            <textarea spellCheck={false} autoCorrect={false} onChange={e => {
                                                this.setState({ description: e.target.value })
                                            }} placeholder={i18next.t("page.private.profile.description.searchPlaceholder")} className={ this.state.profileEditable ? 'editable' : '' } value={this.state.description || (this.state.profileEditable ? "" : i18next.t("page.private.profile.description.noProfileDescription"))} />
                                        </section>
                                        <section className='skills-view'>
                                            <h1>{i18next.t("page.private.profile.description.activity")}</h1>
                                            <div className='row'>
                                                <Skills deleteTag={index => {
                                                    this.setState({ tags: this.state.tags.filter((_, target) => index !== target) })
                                                }} newTag={tag => {
                                                    this.state.tags.push(tag)
                                                }} tags={this.state.tags} profileEditable={this.state.profileEditable} />
                                            </div>
                                        </section>{/*
                                        <section className='language-view'>
                                            <h1>Sprachen</h1>
                                        </section>*/}
                                    </AboutBody>
                                </GridElement>
                                {/*<GridElement columnStart={3} columnEnd={4}>
                                    <GridBody>
                                        <Box>
                                            <div className='header'>
                                                <i className='far fa-tasks' />
                                                <p>Persönliche Agenda</p>
                                            </div>
                                            <AgendaBody className='body'>
                                                <p>Sie haben nichts auf Ihrer Agenda</p>
                                                <img src="/illustrations/agenda_empty.svg" />
                                            </AgendaBody>
                                        </Box>

                                        <TransparentTitle>Häufig kontaktiert</TransparentTitle>

                                        <ContactedPeople data={
                                            [
                                                { name: 'Jack Hull', position: 'Geschäftsführer' },
                                                { name: 'Volker Schreiber', position: 'Geschäftsführer' },
                                            ]
                                        } />
                                    </GridBody>
                                </GridElement>*/}
                            </Grid>
                        </Section.Item>
                        {/*<Section.Item title='Verknüpfte Geräte' icon='phone-laptop' disabled>
                            <ButtonView>
                                <Button thick title='Gerät verknüpfen' icon='link' onClick={() => this.linkDevice.open()} />
                            </ButtonView>
                            <DeviceGrid data={this.state.devices} />
                        </Section.Item>*/}
                        {/*<Section.Item title='Rechteverwaltung' icon='user-lock' disabled>
                            <Grid columns={[ "1fr", "1fr", "1fr" ]} gap="32px" padding="32px 0 0 0">
                                <GridElement columnStart={1} columnEnd={2}>
                                    <PermissionBox>
                                        <div className='header'>
                                            <i className='far fa-user-lock' />
                                            <p>Generelle Berechtigungen</p>
                                        </div>
                                        <div className='body'>
                                            <div className='row'>
                                                <p className='label'>Rechteverwaltung Titel</p>
                                                <Switch />
                                            </div>
                                        </div>
                                    </PermissionBox>
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <PermissionBox>
                                        <div className='header'>
                                            <i className='far fa-puzzle-piece' />
                                            <p>Module</p>
                                        </div>
                                        <div className='body'>
                                            <div className='row'>
                                                <p className='label'>Rechteverwaltung Titel</p>
                                                <Switch />
                                            </div>
                                            <div className='row'>
                                                <p className='label'>Rechteverwaltung Titel</p>
                                                <Switch />
                                            </div>
                                            <div className='row'>
                                                <p className='label'>Rechteverwaltung Titel</p>
                                                <Switch />
                                            </div>
                                            <div className='row'>
                                                <p className='label'>Rechteverwaltung Titel</p>
                                                <Switch />
                                            </div>
                                        </div>
                                    </PermissionBox>
                                </GridElement>
                                <GridElement columnStart={3} columnEnd={4}>
                                    <PermissionBox>
                                        <div className='header'>
                                            <i className='far fa-marker' />
                                            <p>Niederlassungen</p>
                                        </div>
                                        <div className='body'>
                                            <div className='row'>
                                                <p className='label'>Ein sehr langer Platzhalter für den Titel einer Rechteverwaltung</p>
                                                <Switch />
                                            </div>
                                            <div className='row'>
                                                <p className='label'>Ein sehr langer Platzhalter für den Titel einer Rechteverwaltung</p>
                                                <Switch />
                                            </div>
                                            <div className='row'>
                                                <p className='label'>Ein sehr langer Platzhalter für den Titel einer Rechteverwaltung</p>
                                                <Switch />
                                            </div>
                                        </div>
                                    </PermissionBox>
                                </GridElement>
                            </Grid>
                        </Section.Item>*/}
                    </Section>
                </Body>
            </ProfileView>
        </Page>
    }
}
