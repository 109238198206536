import React, { Component } from "react"
import { Modal, Input, Button, Select, Checkbox } from "scanmetrix-components"
import countries from "i18n-iso-countries"

import {contractTypes} from "../../dashboard/propertyManagement";
import i18next from "i18next";

countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})


export default class extends Component {
    state = {}

    constructor(props) {
        super(props)

        this.getCleanState = this.getCleanState.bind(this)

        this.state = this.getCleanState()
        this.state.serviceProviders = []
        this.state.workSections = []
        this.state.teams = []
        this.state.branches = []

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    WorkSections {
                        nodes {
                            id
                            name
                            icon
                            stlbId
                        }
                    }
                    Branches {
                        nodes {
                            id
                            name
                            legalName
                        }
                    }
                    Teams {
                        nodes {
                            id
                            name
                            number
                        }
                    }
                    ServiceProviders {
                        nodes {
                            id
                            name
                            address {
                                streetName
                                streetNumber
                                additional
                                postalCode
                                city
                                country
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ branches: data.data.Branches.nodes, serviceProviders: data.data.ServiceProviders.nodes, workSections: data.data.WorkSections.nodes, teams: data.data.Teams.nodes })
        })
    }

    getCleanState() {
        return ({
            id: null,
            title: null,
            type: null,
            number: null,
            contractDate: null,
            description: null,
            serviceProviderId: null,
            branchId: null,
            teamId: null,
            partnerName: null,
            addressStreetName: null,
            addressStreetNumber: null,
            addressAdditional: null,
            addressPostalCode: null,
            addressCity: null,
            addressCountry: null,
            beginsAt: null,
            endsAt: null,
            notes: null,
            contractDurationAmount: null,
            contractDurationType: null,
            perpetual: false,
            automaticRenewal: false,
            cancelationType: null,
            cancelationDurationAmount: null,
            workSectionId: null,
            cancelationDurationType: null,
            renewalDurationAmount: null,
            signed: false,
            yearlyCost: null,
            yearlyCostIncrement: null,
            yearlyCostDate: null,
            renewalDurationType: null,
            loading: false
        })
    }
    handleSelectionChange = (workSectionId) => {

        const selectedWorkSection = this.state.workSections.find(workSection => workSection.id === workSectionId);
        this.setState({
            workSectionId,
            title: `${selectedWorkSection.stlbId ? (selectedWorkSection.stlbId + " / ") : ""}${selectedWorkSection.name}`,
        });
    }
    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Modal minWidth="650px" instance={this.props.instance} initialize={contract => {
            this.setState({
                ...Object.assign(this.getCleanState(), contract),
                beginsAt: contract.beginsAt ? moment(contract.beginsAt).format("DD.MM.YYYY") : null,
                contractDate: moment(contract.contractDate).format("DD.MM.YYYY"),
                endsAt: contract.endsAt ? moment(contract.endsAt).format("DD.MM.YYYY") : null,
                notes: contract.notes,
                serviceProviderId: contract.serviceProvider ? contract.serviceProvider.id : null,
                teamId: contract.team ? contract.team.id : null,
                branchId: contract.branch ? contract.branch.id : null,
                addressStreetName: contract.address.streetName,
                addressStreetNumber: contract.address.streetNumber,
                addressAdditional: contract.address.additional,
                addressPostalCode: contract.address.postalCode,
                signed: contract.signed,
                cancelationType: contract.cancelationType,
                yearlyCost: contract.yearlyCost,
                yearlyCostIncrement: contract.yearlyCostIncrement,
                yearlyCostDate: contract.yearlyCostDate ? moment(contract.yearlyCostDate).format("DD.MM.YYYY") : null,
                workSectionId: contract.workSection ? contract.workSection.id : null,
                addressCity: contract.address.city,
                addressCountry: contract.address.country
            })
        }}>
            <Modal.Head title={i18next.t("page.private.contract.modals.updateContractModal.updateContract.title")} icon="file-contract" />
            <Modal.Body padding={24}>
                {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Modal.Divider title={i18next.t("page.private.contract.modals.updateContractModal.updateContract.contractData")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                            <Select adjustWidth value={this.state.branchId} onChange={branchId => this.setState({ branchId })} icon="project-diagram" label={i18next.t("page.private.contract.modals.updateContractModal.updateContract.companyBranch")} items={this.state.branches.map(branch => ({ key: branch.id, legalName: branch.legalName, title: `${branch.name} / ${branch.legalName}` }))} />
                        </div>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 2 }}>
                            <Select
                                value={this.state.workSectionId}
                                onChange={this.handleSelectionChange}
                                icon="hard-hat"
                                label={i18next.t("page.private.contract.modals.updateContractModal.updateContract.trade")}
                                adjustWidth
                                onClick={(item) => this.state.title = this.state.item}
                                items={this.state.workSections.map(workSection => ({
                                   key: workSection.id,
                                   icon: workSection.icon,
                                   title: `${workSection.stlbId ? (workSection.stlbId + " / ") : ""}${workSection.name}`
                            }))} />
                        </div>
                        <div style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
                            <Select value={this.state.teamId} onChange={teamId => this.setState({ teamId }) } icon="users-crown" noUnselect required label={i18next.t("page.private.contract.modals.updateContractModal.updateContract.workGroup")} adjustWidth items={this.state.teams.map(team => ({
                                key: team.id,
                                title: `${team.number} / ${team.name}`
                            }))} />
                        </div>
                        <Input required value={this.state.title} onChange={title => this.setState({ title })} icon="tag" label={i18next.t("page.private.contract.modals.updateContractModal.updateContract.contractName")} adjustWidth />
                        <Select value={this.state.type} onChange={type => this.setState({ type })} icon="pencil-alt" label={i18next.t("page.private.contract.modals.updateContractModal.updateContract.contractType")} adjustWidth items={contractTypes.map(ct => ({ key: ct.key, title: i18next.t("page.private.dashboard.propertyManagement.contractType." + ct.key) }))} />
                    </div>
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <Input value={this.state.number} onChange={number => this.setState({ number })} icon="sort-numeric-up-alt" label={i18next.t("page.private.contract.modals.updateContractModal.updateContract.contractNumber")} adjustWidth />
                        <Input required date value={this.state.contractDate} onChange={contractDate => this.setState({ contractDate })} icon="calendar-day" label={i18next.t("page.private.contract.modals.updateContractModal.updateContract.contractDate")} adjustWidth />
                    </div>
                    <Input textArea value={this.state.description} onChange={description => this.setState({ description })} icon="font" label={i18next.t("page.private.contract.modals.updateContractModal.updateContract.contractDescription")} adjustWidth />
                    <Checkbox label={i18next.t("page.private.contract.modals.updateContractModal.updateContract.signed")} value={this.state.signed} onChange={signed => this.setState({ signed })} />
                    <Modal.Divider title={i18next.t("page.private.contract.modals.updateContractModal.contractTerm.title")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: this.state.perpetual ? 3 : 2 }}>
                            <Input date value={this.state.beginsAt} onChange={beginsAt => this.setState({ beginsAt })} icon="hourglass-start" label={i18next.t("page.private.contract.modals.updateContractModal.contractTerm.contractStart")} adjustWidth />
                        </div>
                        {!this.state.perpetual && <Input date value={this.state.endsAt} onChange={endsAt => this.setState({ endsAt })} icon="hourglass-end" label={i18next.t("page.private.contract.modals.updateContractModal.contractTerm.contractEnd")} adjustWidth />}
                        {!this.state.perpetual && <Input required int value={this.state.contractDurationAmount} onChange={contractDurationAmount => this.setState({ contractDurationAmount })} icon="tally" label={i18next.t("page.private.contract.modals.updateContractModal.contractTerm.termNumber")} adjustWidth />}
                        {!this.state.perpetual && <Select required value={this.state.contractDurationType} onChange={contractDurationType => this.setState({ contractDurationType })} icon="calendar-week" label={i18next.t("page.private.contract.modals.updateContractModal.contractTerm.termUnit")} adjustWidth items={[
                            { key: "days", title: i18next.t("page.private.contract.modals.updateContractModal.contractTerm.termUnitTypes.days") },
                            { key: "weeks", title: i18next.t("page.private.contract.modals.updateContractModal.contractTerm.termUnitTypes.weeks") },
                            { key: "months", title: i18next.t("page.private.contract.modals.updateContractModal.contractTerm.termUnitTypes.months") },
                            { key: "years", title: i18next.t("page.private.contract.modals.updateContractModal.contractTerm.termUnitTypes.years") },
                        ]} />}
                        <Checkbox label={i18next.t("page.private.contract.modals.updateContractModal.contractTerm.unlimitedContract")} value={this.state.perpetual} onChange={perpetual => this.setState({ perpetual })} />
                        <Checkbox label={i18next.t("page.private.contract.modals.updateContractModal.contractTerm.automaticRenewal")} value={this.state.automaticRenewal} onChange={automaticRenewal => this.setState({ automaticRenewal })} />
                    </div>
                    <Modal.Divider title={i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.title")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                        <Input int value={this.state.cancelationDurationAmount} onChange={cancelationDurationAmount => this.setState({ cancelationDurationAmount })} icon="tally" label={i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodNumber")} adjustWidth />
                        <Select value={this.state.cancelationDurationType} onChange={cancelationDurationType => this.setState({ cancelationDurationType })} icon="calendar-week" label={i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodUnit")} adjustWidth items={[
                            { key: "days", title: i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodUnitTypes.days") },
                            { key: "weeks", title: i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodUnitTypes.weeks") },
                            { key: "months", title: i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodUnitTypes.months") },
                            { key: "years", title: i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodUnitTypes.years") },
                        ]} />
                        <Select upwards value={this.state.cancelationType} noUnselect onChange={cancelationType => this.setState({ cancelationType })} icon="calendar" label={i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodType")} adjustWidth items={[
                            { key: "ongoing", title: i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodTypeTypes.ongoing") },
                            { key: "monthEnd", title: i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodTypeTypes.monthEnd") },
                            { key: "yearEnd", title: i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodTypeTypes.yearEnd") },
                            { key: "quarterEnd", title: i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodTypeTypes.quarterEnd") },
                            { key: "contractEnd", title: i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.noticePeriodTypeTypes.contractEnd") }
                        ]} />
                        <Checkbox value={this.state.cancellationPossiblePriorEnd} onChange={cancellationPossiblePriorEnd => this.setState({ cancellationPossiblePriorEnd })} label={i18next.t("page.private.contract.modals.updateContractModal.noticePeriod.contractCancellationPossible")} />
                    </div>
                    <Modal.Divider title={i18next.t("page.private.contract.modals.updateContractModal.renewalPeriod.title")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <Input int value={this.state.renewalDurationAmount} onChange={renewalDurationAmount => this.setState({ renewalDurationAmount })} icon="tally" label={i18next.t("page.private.contract.modals.updateContractModal.renewalPeriod.renewalPeriodNumber")} adjustWidth />
                        <Select value={this.state.renewalDurationType} onChange={renewalDurationType => this.setState({ renewalDurationType })} icon="calendar-week" label={i18next.t("page.private.contract.modals.updateContractModal.renewalPeriod.renewalPeriodUnit")} adjustWidth items={[
                            { key: "days", title: i18next.t("page.private.contract.modals.updateContractModal.renewalPeriod.renewalPeriodUnitTypes.days") },
                            { key: "weeks", title: i18next.t("page.private.contract.modals.updateContractModal.renewalPeriod.renewalPeriodUnitTypes.weeks") },
                            { key: "months", title: i18next.t("page.private.contract.modals.updateContractModal.renewalPeriod.renewalPeriodUnitTypes.months") },
                            { key: "years", title: i18next.t("page.private.contract.modals.updateContractModal.renewalPeriod.renewalPeriodUnitTypes.years") },
                        ]} />
                    </div>
                    <Modal.Divider title={i18next.t("page.private.contract.modals.updateContractModal.contractPartner.titleContractPartner")} />
                    <Select value={this.state.serviceProviderId} onChange={(serviceProviderId, serviceProvider) => {
                        this.setState({
                            serviceProviderId,
                            partnerName: serviceProvider.data.name,
                            addressStreetName: serviceProvider.data.address.streetName,
                            addressStreetNumber: serviceProvider.data.address.streetNumber,
                            addressPostalCode: serviceProvider.data.address.postalCode,
                            addressCity: serviceProvider.data.address.city,
                            addressCountry: serviceProvider.data.address.country,
                            addressAdditional: serviceProvider.data.address.additional
                        })
                    }} icon="user-tie" label={i18next.t("page.private.contract.modals.updateContractModal.contractPartner.serviceProvider")} adjustWidth items={this.state.serviceProviders.map(serviceProvider => ({ key: serviceProvider.id, title: (serviceProvider.address.postalCode && serviceProvider.address.city) ? `${serviceProvider.name} / ${serviceProvider.address.postalCode} ${serviceProvider.address.city}` : serviceProvider.name, data: serviceProvider }))} />
                    <Input required value={this.state.partnerName} onChange={partnerName => this.setState({ partnerName })} icon="user-secret" label={i18next.t("page.private.contract.modals.updateContractModal.contractPartner.contractPartnerName")} adjustWidth />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "2fr 0.5fr" }}>
                        <Input required value={this.state.addressStreetName} onChange={addressStreetName => this.setState({ addressStreetName })} icon="road" label={i18next.t("page.private.contract.modals.updateContractModal.contractPartner.street")} adjustWidth />
                        <Input required value={this.state.addressStreetNumber} onChange={addressStreetNumber => this.setState({ addressStreetNumber })} icon="bell" label={i18next.t("page.private.contract.modals.updateContractModal.contractPartner.houseNumber")} adjustWidth />
                    </div>
                    <Input value={this.state.addressAdditional} onChange={addressAdditional => this.setState({ addressAdditional })} icon="font" label={i18next.t("page.private.contract.modals.updateContractModal.contractPartner.additionalAddress")} adjustWidth />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 2fr" }}>
                        <Input required value={this.state.addressPostalCode} onChange={addressPostalCode => this.setState({ addressPostalCode })} icon="mailbox" label={i18next.t("page.private.contract.modals.updateContractModal.contractPartner.zipCode")} adjustWidth />
                        <Input required value={this.state.addressCity} onChange={addressCity => this.setState({ addressCity })} icon="city" label={i18next.t("page.private.contract.modals.updateContractModal.contractPartner.city")} adjustWidth />
                    </div>
                    <Select upwards required adjustWidth value={this.state.addressCountry} onChange={addressCountry => this.setState({ addressCountry })} icon="globe-europe" label={i18next.t("page.private.contract.modals.updateContractModal.contractPartner.country")} items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
                    <Modal.Divider title={i18next.t("page.private.contract.modals.updateContractModal.otherData.title")} />
                    <Input textArea value={this.state.notes} onChange={notes => this.setState({ notes })} icon="sticky-note" label={i18next.t("page.private.contract.modals.updateContractModal.otherData.notes")} adjustWidth />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                        <Input adjustWidth float label={i18next.t("page.private.contract.modals.updateContractModal.otherData.annualCostsNet")} icon="piggy-bank" value={this.state.yearlyCost} format={val => val !== null ? formatter.format(val) : val} onChange={yearlyCost => this.setState({ yearlyCost })} />
                        <Input adjustWidth float label={i18next.t("page.private.contract.modals.updateContractModal.otherData.annualPricePercent")} icon="percent" value={this.state.yearlyCostIncrement} format={val => val !== null ? `${val} %` : val} onChange={yearlyCostIncrement => this.setState({ yearlyCostIncrement })} />
                        <Input adjustWidth date label={i18next.t("page.private.contract.modals.updateContractModal.otherData.settingPrice")} icon="calendar-day" value={this.state.yearlyCostDate} onChange={yearlyCostDate => this.setState({ yearlyCostDate })} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.contract.modals.updateContractModal.otherData.exitButton")} disabled={!this.state.title || !this.state.contractDate || !this.state.partnerName || !this.state.addressStreetName || !this.state.addressStreetNumber || !this.state.addressPostalCode || !this.state.addressCity || !this.state.addressCountry || (!this.state.perpetual && (!this.state.contractDurationAmount || !this.state.contractDurationType))} icon="file-contract" onClick={() => {
                this.setState({ loading: true, error: null })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $teamId: ID!, $cancellationPossiblePriorEnd: Boolean!, $branchId: ID, $yearlyCostIncrement: Float, $yearlyCostDate: DateTime, $workSectionId: ID, $contractDate: DateTime!, $title: String!, $description: String, $number: String, $yearlyCost: Float, $cancelationType: String!, $signed: Boolean!, $automaticRenewal: Boolean!, $notes: String, $beginsAt: DateTime, $endsAt: DateTime, $contractDurationAmount: Int, $contractDurationType: String, $perpetual: Boolean!, $cancelationDurationAmount: Int, $cancelationDurationType: String, $renewalDurationAmount: Int, $renewalDurationType: String, $serviceProviderId: ID, $partnerName: String!, $addressStreetName: String!, $addressStreetNumber: String!, $addressAdditional: String, $addressPostalCode: String!, $addressCity: String!, $addressCountry: String!, $type: String) {
                            updateContract(id: $id, teamId: $teamId, cancellationPossiblePriorEnd: $cancellationPossiblePriorEnd, branchId: $branchId, yearlyCostIncrement: $yearlyCostIncrement, yearlyCostDate: $yearlyCostDate, workSectionId: $workSectionId, contractDate: $contractDate, title: $title, description: $description, yearlyCost: $yearlyCost, cancelationType: $cancelationType, signed: $signed, number: $number, automaticRenewal: $automaticRenewal, notes: $notes, beginsAt: $beginsAt, endsAt: $endsAt, contractDurationAmount: $contractDurationAmount, contractDurationType: $contractDurationType, perpetual: $perpetual, cancelationDurationAmount: $cancelationDurationAmount, cancelationDurationType: $cancelationDurationType, renewalDurationAmount: $renewalDurationAmount, renewalDurationType: $renewalDurationType, serviceProviderId: $serviceProviderId, partnerName: $partnerName, addressStreetName: $addressStreetName, addressStreetNumber: $addressStreetNumber, addressAdditional: $addressAdditional, addressPostalCode: $addressPostalCode, addressCity: $addressCity, addressCountry: $addressCountry, type: $type)
                        }
                    `,
                    variables: {
                        id: this.state.id,
                        teamId: this.state.teamId,
                        contractDate: moment(this.state.contractDate, "DD.MM.YYYY").toDate(),
                        title: this.state.title,
                        type: this.state.type,
                        branchId: this.state.branchId,
                        description: this.state.description,
                        number: this.state.number,
                        perpetual: this.state.perpetual,
                        automaticRenewal: this.state.automaticRenewal,
                        notes: this.state.notes,
                        signed: this.state.signed,
                        workSectionId: this.state.workSectionId,
                        cancelationType: this.state.cancelationType,
                        yearlyCost: this.state.yearlyCost,
                        yearlyCostIncrement: this.state.yearlyCostIncrement,
                        yearlyCostDate: this.state.yearlyCostDate ? moment(this.state.yearlyCostDate, "DD.MM.YYYY").toDate() : null,
                        beginsAt: this.state.beginsAt ? moment(this.state.beginsAt, "DD.MM.YYYY").toDate() : null,
                        endsAt: this.state.perpetual ? null : (this.state.endsAt ? moment(this.state.endsAt, "DD.MM.YYYY").toDate() : null),
                        contractDurationAmount: this.state.perpetual ? null : this.state.contractDurationAmount,
                        contractDurationType: this.state.perpetual ? null : this.state.contractDurationType,
                        cancelationDurationAmount: this.state.cancelationDurationAmount,
                        cancelationDurationType: this.state.cancelationDurationType,
                        renewalDurationAmount: this.state.renewalDurationAmount,
                        renewalDurationType: this.state.renewalDurationType,
                        partnerName: this.state.partnerName,
                        serviceProviderId: this.state.serviceProviderId,
                        addressStreetName: this.state.addressStreetName,
                        addressStreetNumber: this.state.addressStreetNumber,
                        addressAdditional: this.state.addressAdditional,
                        addressPostalCode: this.state.addressPostalCode,
                        addressCity: this.state.addressCity,
                        addressCountry: this.state.addressCountry,
                        cancellationPossiblePriorEnd: this.state.cancellationPossiblePriorEnd
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.updateContract) {
                        this.props.refresh()
                        instance.close()
                    } else {
                        this.setState({ error: result.errors[0].message })
                    }
                })}
            } />} />
        </Modal>
    }
}
