import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"
import {Checkbox} from "../../../../components";
import i18next from "i18next";

export default class extends Component {
    state = { loading: false, search: null, selected: [], featureBlacklist: [] }

    render() {
        const searchFilter = subsidiary => {
            if(!this.state.search) return true

            const search = this.state.search.trim().toLowerCase()
            if(search.length === 0) return true

            const searchString = `${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""} / ${subsidiary.address.inline}`.toLowerCase().trim()

            return searchString.includes(search)
        }

        return <Modal minWidth="650px" instance={this.props.instance} initialize={user => this.setState({ ...user, search: null, selected: user.subsidiaries.map(s => String(s)), loading: false })}>
            <Modal.Head title={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.title")} icon="bell-concierge" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: 16, gridAutoFlow: "row" }}>
                    <Modal.Divider title={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.profileData")} />
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 16 }}>
                        <Input required value={this.state.firstName} onChange={firstName => this.setState({ firstName })} icon="user" label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.firstname")} adjustWidth />
                        <Input required value={this.state.lastName} onChange={lastName => this.setState({ lastName })} icon="user" label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.lastname")} adjustWidth />
                    </div>
                    <Input disabled required value={this.state.email} onChange={email => this.setState({ email })} icon="envelope-open" label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.email")} adjustWidth />
                    <Modal.Divider title={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.functionDeactivate")} />
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 16 }}>
                        <Checkbox label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.counterDeactivate")} value={this.state.featureBlacklist.includes("meters")} onChange={active => active ? this.setState({ featureBlacklist: [ ...this.state.featureBlacklist, "meters" ] }) : this.setState({ featureBlacklist: this.state.featureBlacklist.filter(type => type !== "meters") })} />
                        <Checkbox label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.inspectionsDeactivate")} value={this.state.featureBlacklist.includes("buildingInspections")} onChange={active => active ? this.setState({ featureBlacklist: [ ...this.state.featureBlacklist, "buildingInspections" ] }) : this.setState({ featureBlacklist: this.state.featureBlacklist.filter(type => type !== "buildingInspections") })} />
                        <Checkbox label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.ticketWithoutInspection")} value={this.state.featureBlacklist.includes("ticketWithoutInspection")} onChange={active => active ? this.setState({ featureBlacklist: [ ...this.state.featureBlacklist, "ticketWithoutInspection" ] }) : this.setState({ featureBlacklist: this.state.featureBlacklist.filter(type => type !== "ticketWithoutInspection") })} />
                        <Checkbox label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.dataViewDeactivate")} value={this.state.featureBlacklist.includes("data")} onChange={active => active ? this.setState({ featureBlacklist: [ ...this.state.featureBlacklist, "data" ] }) : this.setState({ featureBlacklist: this.state.featureBlacklist.filter(type => type !== "data") })} />
                    </div>
                    <Modal.Divider title={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.accessRestrictions")} />
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 16 }}>
                        <Checkbox label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.usersActive")} value={this.state.active} onChange={active => this.setState({ active })} />
                        <Checkbox label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.restrictAccess")} value={this.state.enableSubsidiaryWhitelist} onChange={enableSubsidiaryWhitelist => this.setState({ enableSubsidiaryWhitelist })} />
                    </div>
                    {this.state.enableSubsidiaryWhitelist && <div>
                        <div style={{ display: "grid", gridTemplateColumns: "min-content min-content", gridGap: 16, marginTop: 16, marginBottom: 16 }}>
                            <Button thick disabled={this.props.subsidiaries.filter(searchFilter).length === 0 || this.state.loading} title={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.selectAllVisible") + this.props.subsidiaries.filter(searchFilter).length + ")"} icon="check-double" onClick={() => {
                                let subs = this.props.subsidiaries.filter(searchFilter)

                                let selected = this.state.selected
                                selected.push(...subs.map(s => s.id))

                                this.setState({ selected })
                            }} />
                            <Button thick disabled={this.props.subsidiaries.filter(searchFilter).length === 0 || this.state.loading} title={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.deselectAllVisible") + this.props.subsidiaries.filter(searchFilter).length + ")"} secondary icon="times" onClick={() => {
                                let subIds = this.props.subsidiaries.filter(searchFilter).map(s => s.id)

                                let selected = this.state.selected.filter(id => !subIds.includes(id))

                                this.setState({ selected })
                            }} />
                        </div>
                        <div style={{ marginBottom: 16, opacity: 0.75 }}>
                            {this.state.selected.length} / {this.props.subsidiaries.length} {i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.selected")}
                        </div>
                        <Input icon="search" label={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.branchLabel")} adjustWidth value={this.state.search} onChange={search => this.setState({ search })} />
                        <div style={{ height: 400, overflowY: "scroll", display: "flex", flexDirection: "column", marginTop: 16 }}>
                            {this.props.subsidiaries.filter(searchFilter).map(subsidiary => <div key={subsidiary.id} style={{ marginTop: 16 }}>
                                <Checkbox label={`${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""}`} value={this.state.selected.includes(subsidiary.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selected
                                        selected.push(subsidiary.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selected })
                                    } else {
                                        let selected = this.state.selected.filter(id => id !== subsidiary.id)
                                        this.setState({ selected })
                                    }
                                }} />
                            </div>)}
                        </div>
                    </div>}
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.firstName || !this.state.lastName} title={i18next.t("page.private.users.sectionItem.updateServiceDeskUserModal.saveUserButton")} icon="bell-concierge" onClick={() => {
                this.setState({ loading: true })

                console.log(this.state)

                fetch(scanmetrix.backendURL + "/service-desk-user/" + this.state.id, {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify({
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        active: this.state.active,
                        enableSubsidiaryWhitelist: this.state.enableSubsidiaryWhitelist,
                        featureBlacklist: this.state.featureBlacklist,
                        subsidiaries: this.state.selected
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(result => result.json()).then(data => {
                    console.log(data)
                    this.props.refresh().then(() => instance.close())
                })
            }} />} />
        </Modal>
    }
}
