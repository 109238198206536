import React, { Component } from "react"
import { Page, Breadcrumbs, GridElement, Table, Button, Section } from "scanmetrix-components"
import CreateMeasureModal from "./modals/CreateMeasureModal";
import DeleteMeasureModal from "./modals/DeleteMeasureModal";
import i18next from "i18next"
import Avatar from "react-avatar";
import moment from "moment/moment";
import { getStatus as getApprovalStatus } from "../measure";

const getType = type => {
    switch(type) {
        case "service":
            return {title: i18next.t("page.private.measures.switchCases.service"), icon: "digging", color: "#e67e22"}
        case "construction":
            return {title: i18next.t("page.private.measures.switchCases.construction"), icon: "truck-loading", color: "#1abc9c"}
        case "goods":
            return {title: i18next.t("page.private.measures.switchCases.goods"), icon: "hard-hat", color: "#3498db"}
        case "other":
            return {title: i18next.t("page.private.measures.switchCases.other"), icon: "question", color: "#34495e"}
    }
}


const getStatus = status => {
    switch(status) {
        case "draft":
            return {title: i18next.t("page.private.measures.switchCases.draft"), icon: "drafting-compass", color: "#3b97d3"}
        case "ongoing":
            return {title: i18next.t("page.private.measures.switchCases.ongoing"), icon: "wrench", color: "#E67E22"}
        case "cancelled":
            return {title: i18next.t("page.private.measures.switchCases.canceled"), icon: "times", color: "#e74c3c"}
        case "done":
            return {title: i18next.t("page.private.measures.switchCases.completed"), icon: "check-double", color: "#2ECC71"}
    }
}


export default class extends Component {
    state = { measures: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($status: String!) {
                    MeasureApprovals(filter: { status_eq: $status }) {
                        nodes {
                            id
                            status
                            createdAt
                            email
                            fee
                            name
                            text
                            cancelTitle
                            cancelledAt
                            cancelEmail
                            cancelText
                            cancelledBy {
                                id
                                firstName
                                lastName
                                email
                            }
                            title
                            approvedAt
                            measureOffers {
                                id
                                net
                            }
                            measure {
                                id
                                number
                            }
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                    }
                    Measures {
                        nodes {
                            id
                            title
                            one
                            locked
                            kind
                            number
                            status
                            type
                            begin
                            end
                            createdAt
                            letterHead {
                                id
                                name
                            }
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            subsidiary {
                                id
                                name
                                label
                                locationLat
                                locationLng
                                address {
                                    postalCode
                                    city
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                status: "approval"
            }
        }).then(data => {
            this.setState({
                measures: data.data.Measures.nodes,
                approvals: data.data.MeasureApprovals.nodes
            })
        })
    }

    render() {
        return <Page {...this.props}>
            <DeleteMeasureModal refresh={() => this.fetch()} instance={ref => this.deleteMeasureModal = ref} />
            <CreateMeasureModal instance={ref => this.createMeasureModal = ref} refresh={() => this.fetch()} />
            <Breadcrumbs values={[
                {
                    icon: "network-wired",
                    title: i18next.t("page.private.measures.breadCrumbsTitle")
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.measures.measureTable.sectionTitle")} icon="network-wired" amount={this.state.measures ? this.state.measures.length : 0}>
                    <GridElement styled title={i18next.t("page.private.measures.gridElementTitle")} icon="network-wired" rightContent={<Button thick icon="network-wired" title={i18next.t("page.private.measures.button")} disabled={scanmetrix.checkPermission("Measure") < 2} onClick={() => this.createMeasureModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.measures.table.number"), width: "10%", column: "number" },
                                { title: i18next.t("page.private.measures.table.user"), width: "12.5%", column: "user" },
                                { title: i18next.t("page.private.measures.table.status"), width: "10%", column: "status" },
                                { title: i18next.t("page.private.measures.table.type"), width: "12.5%", column: "type" },
                                { title: i18next.t("page.private.measures.table.title"), width: "15%", column: "title" },
                                { title: i18next.t("page.private.measures.table.begin"), width: "7.5%", column: "begin" },
                                { title: i18next.t("page.private.measures.table.end"), width: "7.5%", column: "end" },
                                { title: scanmetrix.one.enabled ? "Art" : i18next.t("page.private.measures.table.letterHead"), width: "10%", column: scanmetrix.one.enabled ? "kind" : "letterHead" },
                                { title: i18next.t("page.private.measures.table.subsidiary"), width: "15%", column: "subsidiary" }
                            ]}
                            contextItems={scanmetrix.checkPermission("Measure") < 4 ? null : [
                                { title: i18next.t("page.private.measures.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: measure => window.open(`/measure/${measure.id}`, '_blank')},
                                { title: i18next.t("page.private.measures.deleteButton"), icon: "trash", onClick: item => this.deleteMeasureModal.open(item.id)}
                            ]}
                            onItemClick={measure => this.props.history.push(`/measure/${measure.id}`,)}
                            items={this.state.measures ? this.state.measures.sort((a, b) => b.number.localeCompare(a.number)).map(measure => {
                                return ({
                                    ...measure,
                                    kind: measure.kind ? (measure.one ? (measure.locked ? <><i style={{ color: "#e74c3c" }} className="fa-duotone fa-lock" /> {i18next.t("page.private.measures.kind." + measure.kind)}</> : <><i style={{ color: "#3b97d3" }} className="fa-duotone fa-sync" /> {i18next.t("page.private.measures.kind." + measure.kind)}</>) : i18next.t("page.private.measures.kind." + measure.kind)) : null,
                                    user: <div style={{ display: "flex", alignItems: "center" }}><div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}><Avatar size="32" email={scanmetrix.disableGravatar ? null : measure.user.email} name={`${measure.user.firstName} ${measure.user.lastName}`} className="avatarInner" /></div>{measure.user.firstName} {measure.user.lastName}</div>,
                                    status: <><i className={`fa-duotone fa-${getStatus(measure.status).icon}`} style={{ color: getStatus(measure.status).color, marginRight: 8 }} /> {getStatus(measure.status).title}</>,
                                    type: <><i className={`fa-duotone fa-${getType(measure.type).icon}`} style={{ color: getType(measure.type).color, marginRight: 8 }} /> {getType(measure.type).title}</>,
                                    letterHead: measure.letterHead ? measure.letterHead.name : null,
                                    begin: measure.begin ? moment(measure.begin).format("DD.MM.YYYY") : i18next.t("page.private.measures.noInformationStart"),
                                    end: measure.end ? moment(measure.end).format("DD.MM.YYYY") : i18next.t("page.private.measures.noInformationEnd"),
                                    subsidiary: measure.subsidiary.name + (measure.subsidiary.label ? (" (" + measure.subsidiary.label + ")") : "")
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.measures.approvalTable.sectionTitle")} icon="check-to-slot" amount={this.state.approvals ? this.state.approvals.length : 0} disabled={scanmetrix.checkPermission("MeasureApproval") < 2}>
                    <GridElement styled title={i18next.t("page.private.measures.approvalTable.sectionTitle")} icon="check-to-slot">
                        <Table
                            head={[
                                { title: i18next.t("page.private.measures.table.number"), width: "10%", column: "measureNumber" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.user"), width: "10%", column: "user" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.createdAt"), width: "10%", column: "createdAt" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.email"), width: "12.5%", column: "email" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.status"), width: "12.5%", column: "status" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.approvedAt"), width: "7.5%", column: "approvedAt" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.proof"), width: "15%", column: "proof" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.net"), width: "10%", column: "net" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.title"), width: "12.5%", column: "title" },
                            ]}
                            onItemClick={approval => this.props.history.push(`/measure/${approval.measure.id}`,)}
                            items={this.state.approvals ? this.state.approvals.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(approval => ({
                                ...approval,
                                raw: approval,
                                email: approval.email || <><i style={{ color: "#3b97d3" }} className="fa-duotone fa-users" /> Mehrere Empfänger</>,
                                statusRaw: approval.status,
                                measureNumber: approval.measure.number,
                                fee: `${approval.fee} % (${formatter.format(approval.measureOffers.map(o => o.net).reduce((a, b) => a + b, 0) * (approval.fee / 100))})`,
                                net: formatter.format(approval.measureOffers.map(o => o.net).reduce((a, b) => a + b, 0)),
                                status: approval.cancelledAt ? <><i style={{ color: "#e74c3c" }} className="fa-duotone fa-exclamation-circle" /> {i18next.t("page.private.measure.quoteReleased.canceledOn")} {moment(approval.cancelledAt).format("DD.MM.YYYY")}</> : <><i style={{ color: getApprovalStatus(approval.status).color }} className={`fa-duotone fa-${getApprovalStatus(approval.status).icon}`} /> {getApprovalStatus(approval.status).title}</>,
                                createdAt: moment(approval.createdAt).format("DD.MM.YYYY HH:mm"),
                                approvedAt: approval.cancelledAt ? "" : (approval.approvedAt ? moment(approval.approvedAt).format("DD.MM.YYYY") : i18next.t("page.private.measure.quoteReleased.createdAtNotYet")),
                                proof: approval.cancelledAt ? "" : (approval.approvedAt ? ((approval.name == "audit_log" && approval.email === null ? <><i style={{ color: "#3b97d3" }} className="fa-duotone fa-file-signature" /> eSignaturen</> : approval.name) || <div><i style={{ color: "#e74c3c" }} className="fa-duotone fa-exclamation-circle" /> {i18next.t("page.private.measure.quoteReleased.withoutProof")}</div>) : i18next.t("page.private.measure.quoteReleased.noneYet")),
                                user: <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : approval.user.email} name={`${approval.user.firstName} ${approval.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {approval.user.firstName} {approval.user.lastName}
                                </div>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
            </Section>
        </Page>
    }
}

export { getType, getStatus }

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})
