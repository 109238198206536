import React, { Component } from "react"
import Insurances from "./styled/Insurances"

export default class extends Component {
    state = { insurances: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($carId: ID!) {
                    CarInsurances(filter: { carId_eq: $carId }) {
                        nodes {
                            id
                            name
                            insurer
                            type
                            insuranceNumber
                            deductible
                            contractBegin
                            contractEnd
                            subscriptionIntervalFee
                            subscriptionIntervalType
                            description
                            email
                            telephone
                        }
                    }
                }
            `,
            variables: {
                carId: this.props.car.id
            }
        }).then(data => {
            this.setState({ insurances: data.data.CarInsurances.nodes })
        })
    }

    render() {
        return <Insurances car={this.props.car} insurances={this.state.insurances} refresh={() => this.fetch()} />
    }
}
