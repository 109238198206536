import React, { Component } from "react"
import ExistingTag from "./styled/ExistingTag"
import Tag from "./styled/Tag"

export default class extends Component {
    render() {
        return <div className='tags-view'>
            {this.props.tagMap.map((item, index) => {
                return <ExistingTag className={this.props.carEditable ? 'editable' : ''} key={index}>
                    <i className='far fa-times' />
                    <p>{item.tag}</p>
                </ExistingTag>
            })}
            <Tag>
                <i className='far fa-plus' />
            </Tag>
        </div>
    }
}