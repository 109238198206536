import styled from "styled-components";

export default styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 32px;
  user-select: none;
  
  p {
    width: 100%;
    box-sizing: border-box;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    color: ${props => props.mode === "login" ? "#3b97d3" : "rgba(0, 0, 0, 0.5)"};
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    
    i {
      margin-right: 8px;
    }
    
    &:hover {
      opacity: 0.5;
    }
  
      &:last-child {
        color: ${props => props.mode === "registration" ? "#3b97d3" : "rgba(0, 0, 0, 0.5)"};
        
        &:after {
          background: ${props => props.mode === "registration" ? "#3b97d3" : "transparent"};
        width: ${props => props.mode === "registration" ? "100%" : "0%"};
        }
      }
      
      &:after {
        transition: all 0.3s;
        display: block;
        position: relative;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        content: " ";
        width: ${props => props.mode === "login" ? "100%" : "0%"};
        height: 2px;
        background: ${props => props.mode === "login" ? "#3b97d3" : "transparent"};
        margin-top: 12px;
      }
  }
`
