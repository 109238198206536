import React, { Component } from "react"
import { Page, Section, Breadcrumbs, GridElement, Table, Button } from "scanmetrix-components"
import CreateBranchModal from "./modals/CreateBranchModal"
import UpdateBranchModal from "./modals/UpdateBranchModal"
import DeleteBranchModal from "./modals/DeleteBranchModal"
import CreatePhaseModal from "./modals/CreatePhaseModal"
import DeletePhaseModal from "./modals/DeletePhaseModal"
import UpdatePhaseModal from "./modals/UpdatePhaseModal"
import CreateBudgetCategoryModal from "./modals/CreateBudgetCategoryModal"
import DeleteBudgetCategoryModal from "./modals/DeleteBudgetCategoryModal"
import UpdateBudgetCategoryModal from "./modals/UpdateBudgetCategoryModal"
import CreateSubsidiaryTypeModal from "./modals/CreateSubsidiaryTypeModal"
import DeleteSubsidiaryTypeModal from "./modals/DeleteSubsidiaryTypeModal"
import UpdateSubsidiaryTypeModal from "./modals/UpdateSubsidiaryTypeModal"
import CreateFileStructureTemplateModal from "./modals/CreateFileStructureTemplateModal"
import DeleteFileStructureTemplateModal from "./modals/DeleteFileStructureTemplateModal"
import UpdateFileStructureTemplateModal from "./modals/UpdateFileStructureTemplateModal"
import ApplyFileStructureTemplateModal from "./modals/ApplyFileStructureTemplateModal"
import FactSheets from "./FactSheets"
import CreateWorkSectionModal from "./modals/CreateWorkSectionModal"
import ImportWorkSectionModal from "./modals/ImportWorkSectionModal"
import UpdateWorkSectionModal from "./modals/UpdateWorkSectionModal";
import DeleteWorkSectionModal from "./modals/DeleteWorkSectionModal";
import UpdateWorkSectionCFDSModal from "./modals/UpdateWorkSectionCFDSModal";
import CreateLetterHeadModal from "./modals/CreateLetterHeadModal";
import UpdateLetterHeadModal from "./modals/UpdateLetterHeadModal";
import DeleteLetterHeadModal from "./modals/DeleteLetterHeadModal";
import Exports from "./exports"
import CreateStandardFileModal from "./modals/CreateStandardFileModal";
import TicketEscalationSteps from "./ticketEscalationSteps"
import TicketTypes from "./ticketTypes"
import DeleteStandardFileModal from "./modals/DeleteStandardFileModal";
import prettyBytes from "pretty-bytes";
import i18next from "i18next";


export default class extends Component {
    state = { standardFiles: null, ticketEscalationSteps: null, ticketTypes: null, budgetCategories: [], phases: [], branches: [], subsidiaryTypes: [], workSections: [], factSheets: [], letterHeads: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Phases {
                        nodes {
                            id
                            name
                            color
                        }
                    }
                    TicketTypes {
                        nodes {
                            id
                            name
                            color
                            icon
                            active
                        }
                    }
                    TicketEscalationSteps {
                        nodes {
                            id
                            description
                            color
                            durationAmount
                            durationType
                            additionalEmails
                            statuses
                            priorities
                            types
                        }
                    }
                    LetterHeads {
                        nodes {
                            id
                            name
                            module
                            marginLeft
                            marginRight
                            marginTop
                            marginBottom
                            firstPageHeader
                            firstPageFooter
                            secondPageHeader
                            secondPageFooter
                            bothPagesSame
                            purchaseOrderHeader
                            purchaseOrderFooter
                        }
                    }
                    StandardFiles {
                        nodes {
                            id
                            name
                            fileName
                            size
                            type
                            createdAt
                        }
                    }
                    FactSheets {
                        nodes {
                            id
                        }
                    }
                    Branches {
                        nodes {
                            id
                            name
                            legalName
                            color
                        }
                    }
                    BudgetCategories {
                        nodes {
                            id
                            name
                            color
                        }
                    }
                    SubsidiaryTypes {
                        nodes {
                            id
                            name
                            color
                        }
                    }
                    FileStructureTemplates {
                        nodes {
                            id
                            name
                        }
                    }
                    WorkSections {
                        nodes {
                            id
                            name
                            gid
                            stlbId
                            icon
                            standardEquipment
                            objectClasses {
                                id
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({
                phases: data.data.Phases.nodes,
                budgetCategories: data.data.BudgetCategories.nodes,
                branches: data.data.Branches.nodes,
                subsidiaryTypes: data.data.SubsidiaryTypes.nodes,
                fileStructureTemplates: data.data.FileStructureTemplates.nodes,
                workSections: data.data.WorkSections.nodes,
                factSheets: data.data.FactSheets.nodes,
                standardFiles: data.data.StandardFiles.nodes,
                letterHeads: data.data.LetterHeads.nodes,
                ticketTypes: data.data.TicketTypes.nodes,
                ticketEscalationSteps: data.data.TicketEscalationSteps.nodes
            })
        })
    }

    render() {
        return <Page {...this.props}>
            <CreateLetterHeadModal instance={ref => this.createLetterHeadModal = ref} refresh={() => this.fetch()} />
            <UpdateLetterHeadModal instance={ref => this.updateLetterHeadModal = ref} refresh={() => this.fetch()} />
            <DeleteLetterHeadModal instance={ref => this.deleteLetterHeadModal = ref} refresh={() => this.fetch()} />
            <CreatePhaseModal instance={ref => this.createPhaseModal = ref} refresh={() => this.fetch()} />
            <UpdatePhaseModal instance={ref => this.updatePhaseModal = ref} refresh={() => this.fetch()} />
            <DeletePhaseModal instance={ref => this.deletePhaseModal = ref} refresh={() => this.fetch()} />
            <CreateBudgetCategoryModal instance={ref => this.createBudgetCategoryModal = ref} refresh={() => this.fetch()} />
            <UpdateBudgetCategoryModal instance={ref => this.updateBudgetCategoryModal = ref} refresh={() => this.fetch()} />
            <DeleteBudgetCategoryModal instance={ref => this.deleteBudgetCategoryModal = ref} refresh={() => this.fetch()} />
            <CreateSubsidiaryTypeModal instance={ref => this.createSubsidiaryTypeModal = ref} refresh={() => this.fetch()} />
            <UpdateSubsidiaryTypeModal instance={ref => this.updateSubsidiaryTypeModal = ref} refresh={() => this.fetch()} />
            <DeleteSubsidiaryTypeModal instance={ref => this.deleteSubsidiaryTypeModal = ref} refresh={() => this.fetch()} />
            <UpdateBranchModal instance={ref => this.updateBranchModal = ref} refresh={() => this.fetch()} />
            <DeleteBranchModal instance={ref => this.deleteBranchModal = ref} refresh={() => this.fetch()} />
            <CreateBranchModal instance={ref => this.createBranchModal = ref} refresh={() => this.fetch()} />
            <CreateStandardFileModal instance={ref => this.createStandardFileModal = ref} refresh={() => this.fetch()} />
            <DeleteStandardFileModal instance={ref => this.deleteStandardFileModal = ref} refresh={() => this.fetch()} />
            <CreateFileStructureTemplateModal instance={ref => this.createFileStructureTemplateModal = ref} refresh={() => this.fetch()} />
            <DeleteFileStructureTemplateModal instance={ref => this.deleteFileStructureTemplateModal = ref} refresh={() => this.fetch()} />
            <UpdateFileStructureTemplateModal instance={ref => this.updateFileStructureTemplateModal = ref} refresh={() => this.fetch()} />
            <CreateWorkSectionModal instance={ref => this.createWorkSectionModal = ref} refresh={() => this.fetch()} />
            <DeleteWorkSectionModal instance={ref => this.deleteWorkSectionModal = ref} refresh={() => this.fetch()} />
            <UpdateWorkSectionModal instance={ref => this.updateWorkSectionModal = ref} refresh={() => this.fetch()} />
            <UpdateWorkSectionCFDSModal instance={ref => this.updateWorkSectionCFDSModal = ref} refresh={() => this.fetch()} />
            <ImportWorkSectionModal instance={ref => this.importWorkSectionModal = ref} refresh={() => this.fetch()} />
            <ApplyFileStructureTemplateModal instance={ref => this.applyFileStructureTemplateModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "cog",
                    title: i18next.t("page.private.settings.breadCrumbsTitle")
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                {/*<Section.Item title="Allgemein" icon="cog">
                </Section.Item>*/}
                <Section.Item title={i18next.t("page.private.settings.companyBranch.sectionElementTitle")} icon="code-branch" amount={this.state.branches ? this.state.branches.length : 0}>
                    <GridElement styled title={i18next.t("page.private.settings.companyBranch.gridElementTitle")} icon="code-branch" rightContent={<Button thick title={i18next.t("page.private.settings.companyBranch.button")} icon="code-branch" onClick={() => this.createBranchModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.settings.companyBranch.table.logo"), width: "10%", column: "logo" },
                                { title: i18next.t("page.private.settings.companyBranch.table.name"), width: "35%", column: "name" },
                                { title: i18next.t("page.private.settings.companyBranch.table.legalName"), width: "40%", column: "legalName" },
                                { title: i18next.t("page.private.settings.companyBranch.table.color"), width: "15%", column: "color" }
                            ]}
                            onItemClick={branch => this.updateBranchModal.open(branch)}
                            contextItems={[
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.settings.companyBranch.deleteButton"),
                                    onClick: data => {
                                        this.deleteBranchModal.open(data.id)
                                    }
                                }
                            ]}
                            items={this.state.branches ? this.state.branches.map(branch => {
                                return ({
                                    id: branch.id,
                                    logo: <img style={{ height: 48, width: "100%", objectFit: "contain" }} src={scanmetrix.backendURL + "/logo/" + branch.id} />,
                                    legalName: branch.legalName,
                                    name: branch.name,
                                    rawColor: branch.color,
                                    color: <div style={{ float: "right", width: "fit-content", backgroundColor: branch.color, color: "white", padding: 6, borderRadius: 3, boxShadow: `0 3px 4px -3px ${branch.color}` }}>{branch.color}</div>
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.workflowPhase.sectionElementTitle")} icon="comment-alt-edit" amount={this.state.phases ? this.state.phases.length : 0}>
                    <GridElement styled title={i18next.t("page.private.settings.workflowPhase.gridElementTitle")} icon="comment-alt-edit" rightContent={<Button thick title={i18next.t("page.private.settings.workflowPhase.button")} icon="comment-alt-edit" onClick={() => this.createPhaseModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.settings.workflowPhase.table.name"), width: "85%", column: "name" },
                                { title: i18next.t("page.private.settings.workflowPhase.table.color"), width: "15%", column: "color" }
                            ]}
                            onItemClick={phase => this.updatePhaseModal.open(phase)}
                            contextItems={[
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.settings.workflowPhase.deleteButton"),
                                    onClick: data => {
                                        this.deletePhaseModal.open(data.id)
                                    }
                                }
                            ]}
                            items={this.state.phases ? this.state.phases.map(phase => {
                                return ({
                                    id: phase.id,
                                    name: phase.name,
                                    rawColor: phase.color,
                                    color: <div style={{ float: "right", width: "fit-content", backgroundColor: phase.color, color: "white", padding: 6, borderRadius: 3, boxShadow: `0 3px 4px -3px ${phase.color}` }}>{phase.color}</div>
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.letterHead.sectionElementTitle")} icon="scroll" amount={this.state.letterHeads ? this.state.letterHeads.length : 0}>
                    <GridElement styled title={i18next.t("page.private.settings.letterHead.gridElementTitle")} icon="scroll" rightContent={<Button thick title={i18next.t("page.private.settings.letterHead.button")} icon="scroll" onClick={() => this.createLetterHeadModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.settings.letterHead.table.name"), width: "45%", column: "name" },
                                { title: i18next.t("page.private.settings.letterHead.table.marginLeft"), width: "10%", column: "marginLeft" },
                                { title: i18next.t("page.private.settings.letterHead.table.marginRight"), width: "10%", column: "marginRight" },
                                { title: i18next.t("page.private.settings.letterHead.table.marginTop"), width: "10%", column: "marginTop" },
                                { title: i18next.t("page.private.settings.letterHead.table.marginBottom"), width: "10%", column: "marginBottom" },
                                { title: i18next.t("page.private.settings.letterHead.table.bothPagesSame"), width: "15%", column: "bothPagesSame" }
                            ]}
                            onItemClick={letterHead => this.updateLetterHeadModal.open(letterHead.data)}
                            contextItems={[
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.settings.letterHead.deleteButton"),
                                    onClick: data => {
                                        this.deleteLetterHeadModal.open(data.id)
                                    }
                                }
                            ]}
                            items={this.state.letterHeads ? this.state.letterHeads.map(letterHead => ({
                                ...letterHead,
                                data: letterHead,
                                marginLeft: `${letterHead.marginLeft} mm`,
                                marginRight: `${letterHead.marginRight} mm`,
                                marginTop: `${letterHead.marginTop} mm`,
                                marginBottom: `${letterHead.marginBottom} mm`,
                                bothPagesSame: <><i className={`far fa-${letterHead.bothPagesSame ? "check-circle" : "times-circle"}`} /> {letterHead.bothPagesSame ? i18next.t("page.private.settings.letterHead.yes") : i18next.t("page.private.settings.letterHead.no")}</>,
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.budgetCategory.sectionElementTitle")} icon="piggy-bank" amount={this.state.budgetCategories ? this.state.budgetCategories.length : 0}>
                    <GridElement styled title={i18next.t("page.private.settings.budgetCategory.gridElementTitle")} icon="piggy-bank" rightContent={<Button thick title={i18next.t("page.private.settings.budgetCategory.button")} icon="piggy-bank" onClick={() => this.createBudgetCategoryModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.settings.budgetCategory.table.name"), width: "85%", column: "name" },
                                { title: i18next.t("page.private.settings.budgetCategory.table.color"), width: "15%", column: "color" }
                            ]}
                            onItemClick={budgetCategory => this.updateBudgetCategoryModal.open(budgetCategory)}
                            contextItems={[
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.settings.budgetCategory.deleteButton"),
                                    onClick: data => {
                                        this.deleteBudgetCategoryModal.open(data.id)
                                    }
                                }
                            ]}
                            items={this.state.budgetCategories ? this.state.budgetCategories.map(budgetCategory => {
                                return ({
                                    id: budgetCategory.id,
                                    name: budgetCategory.name,
                                    rawColor: budgetCategory.color,
                                    color: <div style={{ float: "right", width: "fit-content", backgroundColor: budgetCategory.color, color: "white", padding: 6, borderRadius: 3, boxShadow: `0 3px 4px -3px ${budgetCategory.color}` }}>{budgetCategory.color}</div>
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.branchTypes.sectionElementTitle")} icon="map-marker-edit" amount={this.state.subsidiaryTypes ? this.state.subsidiaryTypes.length : 0}>
                    <GridElement styled title={i18next.t("page.private.settings.branchTypes.gridElementTitle")} icon="map-marker-edit" rightContent={<Button thick title={i18next.t("page.private.settings.branchTypes.button")} icon="map-marker-edit" onClick={() => this.createSubsidiaryTypeModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.settings.branchTypes.table.name"), width: "85%", column: "name" },
                                { title: i18next.t("page.private.settings.branchTypes.table.color"), width: "15%", column: "color" }
                            ]}
                            onItemClick={subsidiaryType => this.updateSubsidiaryTypeModal.open(subsidiaryType)}
                            contextItems={[
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.settings.branchTypes.deleteButton"),
                                    onClick: data => {
                                        this.deleteSubsidiaryTypeModal.open(data.id)
                                    }
                                }
                            ]}
                            items={this.state.subsidiaryTypes ? this.state.subsidiaryTypes.map(subsidiaryType => {
                                return ({
                                    id: subsidiaryType.id,
                                    name: subsidiaryType.name,
                                    rawColor: subsidiaryType.color,
                                    color: <div style={{ float: "right", width: "fit-content", backgroundColor: subsidiaryType.color, color: "white", padding: 6, borderRadius: 3, boxShadow: `0 3px 4px -3px ${subsidiaryType.color}` }}>{subsidiaryType.color}</div>
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.file.sectionElementTitle")} icon="folder-open" amount={this.state.fileStructureTemplates ? this.state.fileStructureTemplates.length : 0}>
                    <GridElement styled title={i18next.t("page.private.settings.file.gridElementTitle")} icon="folder-open" rightContent={<Button thick title={i18next.t("page.private.settings.file.button")} icon="folder-open" onClick={() => this.createFileStructureTemplateModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.settings.file.name"), width: "100%", column: "name" }
                            ]}
                            onItemClick={fileStructureTemplate => this.updateFileStructureTemplateModal.open(fileStructureTemplate)}
                            contextItems={[
                                {
                                    icon: "folder-open",
                                    title: i18next.t("page.private.settings.file.fileStructureTemplateBranch"),
                                    onClick: data => {
                                        this.applyFileStructureTemplateModal.open(data.id)
                                    }
                                },
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.settings.file.fileStructureTemplateDeleteButton"),
                                    onClick: data => {
                                        this.deleteFileStructureTemplateModal.open(data.id)
                                    }
                                }
                            ]}
                            items={this.state.fileStructureTemplates ? this.state.fileStructureTemplates.map(fileStructureTemplate => {
                                return ({
                                    id: fileStructureTemplate.id,
                                    name: fileStructureTemplate.name
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.factSheets.sectionElementTitle")} icon="file-spreadsheet" amount={this.state.factSheets ? this.state.factSheets.length : 0}>
                    <FactSheets />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.trade.sectionElementTitle")} icon="hard-hat" amount={this.state.workSections ? this.state.workSections.length : 0}>
                    <GridElement styled title={i18next.t("page.private.settings.trade.gridElementTitle")} icon="hard-hat" rightContent={<div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
                        <Button thick title={i18next.t("page.private.settings.trade.tradeImportButton")} icon="download" onClick={() => this.importWorkSectionModal.open()} />
                        <Button secondary thick title={i18next.t("page.private.settings.trade.tradeCreateButton")} icon="hard-hat" onClick={() => this.createWorkSectionModal.open()} />
                    </div>}>
                        <Table
                            head={[
                                { title: "Zielgewerk ID", width: "7.5%", column: "id" },
                                { title: i18next.t("page.private.settings.trade.table.modus"), width: "12.5%", column: "modus" },
                                { title: i18next.t("page.private.settings.trade.table.stlbId"), width: "10%", column: "stlbId" },
                                { title: i18next.t("page.private.settings.trade.table.name"), width: "45%", column: "name" },
                                { title: i18next.t("page.private.settings.trade.table.objectClasses"), width: "15%", column: "objectClasses" },
                                { title: i18next.t("page.private.settings.trade.table.standardEquipment"), width: "10%", column: "standardEquipment" }
                            ]}
                            onItemClick={workSection => {
                                if(!workSection.data.gid) this.updateWorkSectionModal.open(workSection.data)
                                else this.updateWorkSectionCFDSModal.open(workSection.data)
                            }}
                            contextItems={[
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.settings.trade.deleteButton"),
                                    disabled: data => !!data.data.gid,
                                    onClick: data => {
                                        if(!data.data.gid) this.deleteWorkSectionModal.open(data.id)
                                    }
                                }
                            ]}
                            items={this.state.workSections ? this.state.workSections.sort((a, b) => a.stlbId - b.stlbId).map(workSection => {
                                return ({
                                    data: workSection,
                                    id: workSection.id,
                                    modus: <><i style={{ color: workSection.gid ? "#3b97d3" : "#000000" }} className={`far fa-${workSection.gid ? "sync" : "cog"}`} /> {workSection.gid ? i18next.t("page.private.settings.trade.cfds") : i18next.t("page.private.settings.trade.userDefined")}</>,
                                    name: <><i className={`far fa-${workSection.icon}`} /> {workSection.name}</>,
                                    stlbId: workSection.stlbId,
                                    standardEquipment: <><i className={`far fa-${workSection.standardEquipment ? "check-circle" : "times-circle"}`} /> {workSection.standardEquipment ? i18next.t("page.private.settings.trade.yes") : i18next.t("page.private.settings.trade.no")}</>,
                                    objectClasses: <div style={{ color: "white", background: workSection.objectClasses.length ? "#3b97d3" : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{workSection.objectClasses.length} {i18next.t("page.private.settings.trade.objectClasses")}</div>
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.Standarddocumente.sectionElementTitle")} icon="file-check" amount={this.state.standardFiles ? this.state.standardFiles.length : 0}>
                    <GridElement styled title={i18next.t("page.private.settings.Standarddocumente.gridElementTitle")} icon="file-check" rightContent={<Button thick icon="file-check" title={i18next.t("page.private.settings.Standarddocumente.button")} onClick={() => this.createStandardFileModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.settings.Standarddocumente.table.name"), width: "35%", column: "name" },
                                { title: i18next.t("page.private.settings.Standarddocumente.table.fileName"), width: "35%", column: "fileName" },
                                { title: i18next.t("page.private.settings.Standarddocumente.table.type"), width: "10%", column: "type" },
                                { title: i18next.t("page.private.settings.Standarddocumente.table.size"), width: "10%", column: "size" },
                                { title: i18next.t("page.private.settings.Standarddocumente.table.createdAt"), width: "10%", column: "createdAt" }
                            ]}

                            contextItems={[
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.settings.Standarddocumente.deleteButton"),
                                    onClick: data => {
                                        this.deleteStandardFileModal.open(data.id)
                                    }
                                }
                            ]}
                            items={this.state.standardFiles ? this.state.standardFiles.map(standardFile => ({
                                ...standardFile,
                                size: prettyBytes(standardFile.size),
                                createdAt: moment(standardFile.createdAt).format("DD.MM.YYYY HH:mm")
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.Exports.sectionElementTitle")} icon="file-export">
                    <Exports />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.TicketEscalationSteps.sectionElementTitle")} icon="message-exclamation" amount={this.state.ticketEscalationSteps ? this.state.ticketEscalationSteps.length : 0}>
                    <TicketEscalationSteps fetch={() => this.fetch()} data={this.state.ticketEscalationSteps} />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.settings.TicketTypes.sectionElementTitle")} icon="ticket" amount={this.state.ticketTypes ? this.state.ticketTypes.length : 0}>
                    <TicketTypes fetch={() => this.fetch()} data={this.state.ticketTypes} />
                </Section.Item>
                {/*<Section.Item disabled title="CFDS-Zuordnungen" icon="sync" amount={0}>
                </Section.Item>*/}
            </Section>
        </Page>
    }
}
