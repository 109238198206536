import React from 'react';

const Progress_bar = ({ currentStep }) => {
  // Calculate percentage progress based on the current step
  const progress = (currentStep - 1) * (100 / 7);

  const Beforeprogressbar = {
    height: 24, // Assuming default height if not provided
    width: '124.9%',
    // borderRadius: 10,
    marginBottom: 30,
    marginLeft: -41,
    backgroundColor: '#3B97D3',
    border: '1px ridge #fff' // Border color for the parent div
  };

  const Afterprogressbar = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: '#fff', // Assuming default progress bar color if not provided
    // borderRadius: 10,
  };

  const progresstext = {
    color: 'black',
    fontWeight: 900,
    padding: 185,
  };

  return (
    <div style={Beforeprogressbar}>
      <div style={Afterprogressbar}>
        <span style={progresstext}>{`${Math.round(progress)}%`}</span>
      </div>
    </div>
  );
};

export default Progress_bar;
