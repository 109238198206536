import styled from "styled-components"

export default styled.h2`
    font-weight: normal;
    padding: 16px;
    font-size: 1.2em;

    i {
        margin-right: 8px;
        color: #c0392b;
    }
`
