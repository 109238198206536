import React, { Component } from "react"
import { Modal, Input } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { id: null, meterGroup: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={meterGroup => this.setState({ id: meterGroup.id, meterGroup, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.updateMeterGroupModal.title")} icon="object-intersect" />
            <Modal.Body padding={24}>
                <Input.MutationProvider value={{
                    name: "updateMeterGroup",
                    id: this.state.id,
                    data: this.state.meterGroup,
                    onSave: () => this.props.refresh()
                }}>
                    <Input field="name" required icon="tag" label={i18next.t("page.private.subsidiary.styled.updateMeterGroupModal.description")} adjustWidth />
                </Input.MutationProvider>
            </Modal.Body>
        </Modal>
    }
}
