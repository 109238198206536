import React, { Component } from "react"
import { Modal, Button, Section, Input, Select } from "scanmetrix-components"
import moment from "moment"
import styled from "styled-components"
import Avatar from "react-avatar"
import {getChannelAsTag, getTypeAsTag, getPriorityAsTag} from "../index"
import DeleteTicketCommentModal from "./DeleteTicketCommentModal";
import DeleteTicketChatModal from "./DeleteTicketChatModal";
import TicketEmailModal from "./TicketEmailModal";
import PDFModal from "../../buildingInspection/modals/PDFModal"
import i18next from "i18next"
import Dropzone from "react-dropzone";
import CreateMeasureModal from "./CreateMeasureModal";


const UploadBody = styled.div`
  flex: 1;
  z-index: 0;
  min-width: 150px;
  min-height: 150px;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 250ms ease;
  position: relative;
  box-shadow: ${props => props.isDragActive ? "inset 0 0 0 4px #3b97d3" : "inset 0 0 0 1px rgba(0, 0, 0, 0.25)"};
  border-radius: 5px;

  &:hover {
    opacity: 0.75;
  }
  
  input {
    display: none;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 80%;
    pointer-events: none;
    position: absolute;
    left: 10%;
  }
`


const StyledTicketAction = styled.div`
    padding: 16px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    user-select: none;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  
    p {
        i {
            width: 24px;
            color: #3b97d3;
        }
    }
  
    .date {
        float: right;
        font-size: 14px;
        margin-top: 2px;
        padding-left: 16px;
    }
`

const getIconFromTicketActionType = type => {
    switch(type) {
        case "creation":
            return "plus"
        case "email":
            return "envelope"
        case "clearance":
            return "check-to-slot"
        case "uploadFile":
            return "file"
        case "archived":
            return "box-archive"
        case "unarchived":
            return "trash-undo"
        case "deleteFile":
            return "trash"
        case "assignment":
            return "user"
        case "escalation":
            return "message-exclamation"
        case "status":
            return "rotate"
    }
}

const getLangByStatus = status => {
    switch(status) {
        case "unassigned":
            return "Unzugewiesen"
        case "assigned":
            return "Zugewiesen"
        case "active":
            return "In Bearbeitung"
        case "done":
            return "Erledigt"
    }
}

function truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}

const getLanguageFromTicketAction = (data, ticket) => {
    const json = data.data
    const channel = ticket.channel

    switch(data.type) {
        case "creation":
            return channel.type === "manual" ?
                <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.creation.text1")} <b>{channel.user.firstName} {channel.user.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.creation.text2")}</>
                : (channel.type === "servicedesk" ?
                    <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.creation.text3")} <b>{channel.user.firstName} {channel.user.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.creation.text4")}</>
                    : (channel.type === "buildingInspection" ? <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.creation.text5")} <b>{channel.user.firstName} {channel.user.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.creation.text6")}</> : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.creation.text7")}</>))
        case "archived":
            return json.archivedBy ?
                <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.archived.text1")} <b>{json.archivedBy.firstName} {json.archivedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.archived.text2")}</>
                : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.archived.text3")}</>
        case "unarchived":
            return json.unarchivedBy ?
                <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.unarchived.text1")} <b>{json.unarchivedBy.firstName} {json.unarchivedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.unarchived.text2")}</>
                : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.unarchived.text3")}</>
        case "clearance":
            return json.clearedBy ?
                (json.description ? <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.clearance.text1")} <b>{json.clearedBy.firstName} {json.clearedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.clearance.text2")} <span style={{ fontStyle: "italic" }}>{json.description}</span></> : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.clearance.text3")} <b>{json.clearedBy.firstName} {json.clearedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.clearance.text4")}</>)
                : (json.description ? <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.clearance.text5")} <span style={{ fontStyle: "italic" }}>{json.description}</span></> : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.clearance.text6")}</>)
        case "deleteFile":
            return json.deletedBy ?
                <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.deleteFile.text1")} <b>{truncate(json.file.name, 40)}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.deleteFile.text2")} <b>{json.deletedBy.firstName} {json.deletedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.deleteFile.text3")}</>
                : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.deleteFile.text4")} <b>{truncate(json.file.name, 40)}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.deleteFile.text5")}</>
        case "uploadFile":
            return json.uploadedBy ?
                <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.uploadFile.text1")} <b>{truncate(json.file.name, 40)}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.uploadFile.text2")} <b>{json.uploadedBy.firstName} {json.uploadedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.uploadFile.text3")}</>
                : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.uploadFile.text4")} <b>{truncate(json.file.name, 40)}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.uploadFile.text5")}</>
        case "status":
            return json.changedBy ?
                <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.status.text1")} <b>{json.changedBy.firstName} {json.changedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.status.text2")} <b>{getLangByStatus(json.status)}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.status.text3")} ({i18next.t("page.private.ticket-system.updateTicketModal.switchCases.status.text4")} <b>{getLangByStatus(json.statusBefore)}</b>).</>
                : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.status.text5")} <b>{getLangByStatus(json.status)}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.status.text6")} ({i18next.t("page.private.ticket-system.updateTicketModal.switchCases.status.text7")} <b>{getLangByStatus(json.statusBefore)}</b>){i18next.t("page.private.ticket-system.updateTicketModal.switchCases.status.text8")}</>
        case "escalation":
            return json.stepBefore ?
                <span dangerouslySetInnerHTML={{ __html: i18next.t("page.private.ticket-system.updateTicketModal.switchCases.escalation.text1", { step: json.step.description, stepBefore: json.stepBefore.description }) }}></span>
                : <span dangerouslySetInnerHTML={{ __html: i18next.t("page.private.ticket-system.updateTicketModal.switchCases.escalation.text2", { step: json.step.description }) }}></span>
        case "email":
            return <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.email.text1")} <b>{json.sentBy.firstName} {json.sentBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.email.text2")} <b>{json.email}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.email.text3")}{json.text && <><br />{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.email.text4")} <i>{json.text}</i></>}</>
        case "assignment":
            return json.assignedBy ?
                (json.assignedToBefore ?
                    (json.assignedTo ?
                        //assignedTo && assignedToBefore && assignedBy
                        <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text1")} <b>{json.assignedBy.firstName} {json.assignedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text2")} <b>{json.assignedTo.firstName} {json.assignedTo.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text3")} ({i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text4")} <b>{json.assignedToBefore.firstName} {json.assignedToBefore.lastName}</b>){i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text5")}</>
                        //assignedBy && assignedToBefore
                        : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text6")} <b>{json.assignedBy.firstName} {json.assignedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text7")} <b>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text8")}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text9")} ({i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text10")} <b>{json.assignedToBefore.firstName} {json.assignedToBefore.lastName}</b>){i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text11")}</>)
                    //assignedBy && assignedTo
                    : (json.assignedTo ? <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text12")} <b>{json.assignedBy.firstName} {json.assignedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text13")} <b>{json.assignedTo.firstName} {json.assignedTo.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text14")}</>
                        //assignedBy
                        : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text15")} <b>{json.assignedBy.firstName} {json.assignedBy.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text16")} <b>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text17")}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text18")}</>))
                //assignedToBefore && assignedTo
                : (json.assignedToBefore ? (json.assignedTo ? <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text19")} <b>{json.assignedTo.firstName} {json.assignedTo.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text20")} ({i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text21")} <b>{json.assignedToBefore.firstName} {json.assignedToBefore.lastName}</b>){i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text22")}</>
                    //assignedToBefore
                    : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text23")} <b>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text24")}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text25")} ({i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text26")} <b>{json.assignedToBefore.firstName} {json.assignedToBefore.lastName}</b>){i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text27")}</>)
                    //assignedTo
                    : (json.assignedTo ? <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text28")} <b>{json.assignedTo.firstName} {json.assignedTo.lastName}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text29")}</>
                        //none
                        : <>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text30")} <b>{i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text31")}</b> {i18next.t("page.private.ticket-system.updateTicketModal.switchCases.assignment.text32")}</>))
    }
}

class TicketAction extends Component {
    render() {
        const data = this.props.data

        return <StyledTicketAction>
            <p><i className={`fa-duotone fa-${getIconFromTicketActionType(data.type)}`} /> {getLanguageFromTicketAction(data, this.props.ticket)}</p>
            <div className="date">{moment(data.createdAt).fromNow()}</div>
        </StyledTicketAction>
    }
}

export default class extends Component {
    state = {
        ticket: null,
        uploadLoading: false,
        archiveLoading: false,
        actions: [],
        types: [],
        files: [],
        measures: []
    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
    }

    fetch(ticketId) {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($ticketId: ID!) {
                    TicketTypes {
                        nodes {
                            id
                            color
                            name
                            active
                            icon
                        }
                    }
                    Ticket(id: $ticketId) {
                        id
                        title
                        description
                        priority
                        status
                        channel
                        type {
                            id
                        }
                        room {
                            id
                            name
                        }
                        number
                        createdAt
                        updatedAt
                        smid
                        channel
                        archived
                        ticketEscalationStep {
                            id
                            description
                            color
                        }
                        buildingInspection {
                            id
                            createdAt
                            structure
                            buildingInspectionTemplate {
                                id
                                name
                            }
                        }
                        measure {
                            id
                        }
                        buildingInspectionTemplateStepId
                        user {
                            id
                            firstName
                            lastName
                            email
                        }
                        subsidiary {
                            id
                            name
                            label
                            measures {
                                id
                                number
                                title
                                createdAt
                            }
                            address {
                                inline
                            }
                        }
                        files {
                            id
                            name
                            size
                        }
                        actions {
                            id
                            type
                            data
                            createdAt
                        }
                    }
                }
            `,
            variables: {
                ticketId
            }
        }).then(data => {
            this.setState({ types: data.data.TicketTypes.nodes, files: data.data.Ticket.files, measures: data.data.Ticket.subsidiary.measures, ticket: data.data.Ticket, actions: data.data.Ticket.actions })
        })
    }

    render() {
        const actions = this.state.actions
        const ticket = this.state.ticket
        const files = this.state.files

        let steps = []

        if(ticket && ticket.buildingInspection) {
            const structure = ticket.buildingInspection.structure
            const stepId = ticket.buildingInspectionTemplateStepId

            if(stepId && structure && structure.steps) {
                const step = structure.steps.find(s => String(s.id) === String(stepId))

                if(step) {
                    steps.push(step.description)

                    const find = step => {
                        if(step.parent_id) {
                            const parent = steps.find(s => s.id === step.parent_id)

                            if(parent) {
                                steps.push(parent.description)
                                find(parent)
                            }
                        }
                    }

                    find(step)
                }
            }
        }

        const stepDescription = steps.reverse().join(" > ")

        return <>
            <Modal minWidth="1120px" maxWidth="1120px" instance={this.props.instance} initialize={ticketId => {
                this.setState({
                    ticket: null,
                    uploadLoading: false,
                    actions: []
                })

                this.ticketId = ticketId

                this.fetch(ticketId)
            }}>
                <Modal.Head title={i18next.t("page.private.ticket-system.updateTicketModal.title")} icon="comments-alt" />
                {ticket && <Modal.Body padding={24}>
                    <div style={{ display: "grid", gridTemplateColumns: "4fr 1fr", gridGap: 24 }}>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 2, display: "grid", gridTemplateRows: "min-content min-content min-content min-content min-content", gridGap: 24 }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ borderRadius: "100%", width: 50, height: 50, overflow: "hidden" }}>
                                    <Avatar className="avatar" size="50" email={scanmetrix.disableGravatar ? null : ticket.channel.user.email} name={`${ticket.channel.user.firstName} ${ticket.channel.user.lastName}`} />
                                </div>
                                <div style={{ marginLeft: 12, userSelect: "none" }}>
                                    <p style={{ fontSize: "20px" }}>{`${ticket.channel.user.firstName} ${ticket.channel.user.lastName}`}</p>
                                    <p style={{ fontSize: "16px", fontWeight: 300 }}>{ticket.channel.user.email}</p>
                                </div>
                            </div>
                            <div style={{ fontSize: "24px", fontWeight: 600 }}><p style={{ fontWeight: 300, fontSize: "22px", marginBottom: "4px" }}>{ticket.number}</p>{ticket.title}</div>
                            <div style={{ fontSize: "18px", fontWeight: 300 }}>{ticket.description || <div style={{ fontStyle: "italic" }}>{i18next.t("page.private.ticket-system.updateTicketModal.noDescriptionGiven")}</div>}</div>
                            <div>
                                <hr style={{ border: "none", height: 1, background: "rgba(0, 0, 0, 0.1)", width: "100%", marginBottom: 24 }} />
                                <div>
                                    <p style={{ fontSize: "18px", fontWeight: "300", marginBottom: 16, userSelect: "none" }}><i className="fa-duotone fa-map-marker" style={{ width: 24, color: "#3b97d3" }} /> {i18next.t("page.private.ticket-system.updateTicketModal.branch")}</p>
                                    <div style={{ marginBottom: 16 }}>
                                        {ticket.subsidiary.name}{ticket.subsidiary.label ? (" (" + ticket.subsidiary.label + ")") : ""} - {ticket.subsidiary.address.inline}
                                    </div>
                                    {ticket.room && <div style={{ marginBottom: 16, userSelect: "none" }}>
                                        <i className="fa-duotone fa-table-layout" style={{ color: "#3b97d3", marginRight: 8 }} /> <b>Raum:</b> {ticket.room.name}
                                    </div>}
                                    <div style={{ display: "grid", gridTemplateColumns: ticket.measure ? "1fr" : "1fr min-content", gridGap: 24 }}>
                                        <Input.MutationProvider value={{
                                            name: "updateTicket",
                                            id: ticket.id,
                                            data: ticket,
                                            onSave: () => {
                                                this.fetch(this.ticketId)
                                            }
                                        }}>
                                            <Select adjustWidth value={ticket.measure ? ticket.measure.id : null} link={measureId => `/measure/${measureId}`} field="measure.id" label={i18next.t("page.private.ticket-system.updateTicketModal.measure")} icon='network-wired' items={this.state.measures.map(measure => ({
                                                key: measure.id,
                                                title: `${measure.number} vom ${moment(measure.createdAt).format("DD.MM.YYYY")}${measure.title ? (" / " + measure.title) : ""}`
                                            }))} />
                                            {!ticket.measure && <div style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                                                <Button adjustWidth thick primary icon="plus" title="Maßnahme erstellen" onClick={() => this.createMeasureModal.open(ticket)} />
                                            </div>}
                                        </Input.MutationProvider>
                                    </div>
                                </div>
                            </div>
                            {ticket.buildingInspection && <div>
                                <hr style={{ border: "none", height: 1, background: "rgba(0, 0, 0, 0.1)", width: "100%", marginBottom: 24 }} />
                                <div>
                                    <p style={{ fontSize: "18px", fontWeight: "300", marginBottom: 16, userSelect: "none" }}><i className="fa-duotone fa-person-walking" style={{ width: 24, color: "#3b97d3" }} /> {i18next.t("page.private.ticket-system.updateTicketModal.inspection")}</p>
                                    <div>
                                        <b>{ticket.buildingInspection.buildingInspectionTemplate.name}</b> {i18next.t("page.private.ticket-system.updateTicketModal.from")} {moment(ticket.buildingInspection.createdAt).format("DD.MM.YYYY HH:mm")} (<a style={{ color: "#3b97d3", textDecoration: "none", fontWeight: "bold", cursor: "pointer" }} onClick={() => this.pdfModal.open(ticket.buildingInspection.id)}>{i18next.t("page.private.ticket-system.updateTicketModal.pdf")}</a>)
                                        {stepDescription && <i style={{ display: "block", marginTop: 4 }}>{stepDescription}</i>}
                                    </div>
                                </div>
                            </div>}
                            <div>
                                <hr style={{ border: "none", height: 1, background: "rgba(0, 0, 0, 0.1)", width: "100%", marginBottom: 24 }} />
                                <div>
                                    <p style={{ fontSize: "18px", fontWeight: "300", marginBottom: 16, userSelect: "none", display: "flex", alignItems: "center", justifyContent: "space-between" }}><div><i className="fa-duotone fa-files" style={{ width: 24, color: "#3b97d3" }} /> {i18next.t("page.private.ticket-system.updateTicketModal.attachements")}</div></p>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gridGap: 16 }}>
                                        {files.map((file, index) => <StyledFile style={{ backgroundSize: "cover", backgroundPosition: "center", backgroundImage: file.name.match(/.(jpg|jpeg|png|gif)$/i) ? `url(${scanmetrix.backendURL}/ticket/file/${file.id})` : "none" }} key={index} onClick={() => window.open(`${scanmetrix.backendURL}/ticket/file/${file.id}`, "_blank")}>
                                            <div className="fileName" style={{ opacity: file.name.match(/.(jpg|jpeg|png|gif)$/i) ? 0 : 1 }}>
                                                <i className="far fa-download" /> {truncate(file.name, 50)}
                                            </div>
                                            {scanmetrix.checkPermission("TicketFile") >= 4 && <div className="trash" onClick={e => {
                                                e.stopPropagation()

                                                return scanmetrix.client.mutate({
                                                    mutation: scanmetrix.gql`
                                                    mutation($id: ID!) {
                                                        deleteTicketFile(id: $id)
                                                    }
                                                `,
                                                    variables: {
                                                        id: file.id
                                                    }
                                                }).then(() => {
                                                    this.fetch(ticket.id)
                                                })
                                            }}><i className="far fa-trash" /></div>}
                                        </StyledFile>)}
                                        <Dropzone disabled={scanmetrix.checkPermission("TicketFile") < 2} multiple={false} onDrop={acceptedFiles => {
                                            if(acceptedFiles.length && !this.state.loading) {
                                                const file = acceptedFiles[0]

                                                scanmetrix.client.mutate({
                                                    mutation: scanmetrix.gql`
                                            mutation($file: Upload!, $ticketId: ID!) {
                                                createTicketFile(file: $file, ticketId: $ticketId)
                                            }
                                        `,
                                                    context: {
                                                        hasUpload: true
                                                    },
                                                    variables: {
                                                        file,
                                                        ticketId: ticket.id
                                                    }
                                                }).then(() => {
                                                    this.setState({ uploadLoading: false }, () => this.fetch(ticket.id))
                                                })
                                            }
                                        }}>
                                            {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody tabIndex={0} {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef", className: 'dropzone' })}>
                                                <input {...getInputProps()} tabIndex={-1} multiple={false} type='file' autoComplete='off' />
                                                <p className="title"><i className="far fa-upload" /> {i18next.t("page.private.ticket-system.updateTicketModal.attachementsDescription")}</p>
                                            </UploadBody>}
                                        </Dropzone>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <hr style={{ border: "none", height: 1, background: "rgba(0, 0, 0, 0.1)", width: "100%", marginBottom: 24 }} />
                                <div>
                                    <Section bodyPadding="16px 0 0 0">
                                        <Section.Item title={i18next.t("page.private.ticket-system.updateTicketModal.chronology")} icon="list-dots">
                                            {/*<p style={{ fontSize: "18px", fontWeight: "300", marginBottom: 16, userSelect: "none" }}><i className="fa-duotone fa-list-dots" style={{ width: 24, color: "#3b97d3" }} /> Chronologie</p>*/}
                                            <StyledActions>
                                                {actions.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(action => <TicketAction key={action.id} data={action} ticket={ticket} />)}
                                            </StyledActions>
                                        </Section.Item>
                                        <Comments id={ticket.id} />
                                        <Chat id={ticket.id} refresh={() => this.props.refresh()} />
                                    </Section>
                                </div>
                            </div>
                        </div>
                        <div style={{ gridColumnStart: 2, gridColumnEnd: 3, display: "grid", gridAutoFlow: "row", gridGap: 24, height: "min-content" }}>
                            <Button disabled={scanmetrix.checkPermission("Ticket") < 3} thick title={ticket.archived ? i18next.t("page.private.ticket-system.updateTicketModal.dearchiveButton") : i18next.t("page.private.ticket-system.updateTicketModal.archiveButton")} loading={this.state.archiveLoading} icon={ticket.archived ? "trash-undo" : "box-archive"} adjustWidth secondary onClick={() => {
                                this.setState({ archiveLoading: true })

                                scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                    mutation($id: ID!, $archived: Boolean!) {
                                        updateTicketArchived(id: $id, archived: $archived)
                                    }
                                `,
                                    variables: {
                                        id: ticket.id,
                                        archived: !ticket.archived
                                    }
                                }).then(() => {
                                    this.setState({ archiveLoading: false, ticket: { ...ticket, archived: !ticket.archived } })
                                    this.props.refresh()
                                })
                            }} />
                            <Button thick title={i18next.t("page.private.ticket-system.updateTicketModal.email")} icon="envelope" adjustWidth secondary onClick={() => this.emailModal.open(this.state.ticket.id)} />
                            <SideElement title={i18next.t("page.private.ticket-system.updateTicketModal.createdAt")} value={moment(ticket.createdAt).format("DD.MM.YYYY HH:mm")}></SideElement>
                            <SideElement title={i18next.t("page.private.ticket-system.updateTicketModal.lastEdited")} value={moment(ticket.updatedAt).format("DD.MM.YYYY HH:mm")}></SideElement>
                            <SideElement title={i18next.t("page.private.ticket-system.updateTicketModal.assigned")} value={ticket.user ? <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ borderRadius: "100%", width: 40, height: 40, overflow: "hidden" }}>
                                    <Avatar className="avatar" size="40" email={scanmetrix.disableGravatar ? null : ticket.user.email} name={`${ticket.user.firstName} ${ticket.user.lastName}`} />
                                </div>
                                <div style={{ marginLeft: 12, userSelect: "none" }}>
                                    <p style={{ fontSize: "18px" }}>{`${ticket.user.firstName} ${ticket.user.lastName}`}</p>
                                    <p style={{ fontSize: "14px", fontWeight: 300 }}>{ticket.user.email}</p>
                                </div>
                            </div> : <i>{i18next.t("page.private.ticket-system.updateTicketModal.nobody")}</i>}></SideElement>
                            <SideElement title={i18next.t("page.private.ticket-system.updateTicketModal.priority")} value={<Tag style={{ background: getPriorityAsTag(ticket.priority).bgcolor }}>{getPriorityAsTag(ticket.priority).title}</Tag>} />
                            {ticket.ticketEscalationStep && <SideElement title={i18next.t("page.private.ticket-system.updateTicketModal.ticketEscalationStep")} value={<Tag style={{ background: ticket.ticketEscalationStep.color }}>{ticket.ticketEscalationStep.description}</Tag>} />}
                            <SideElement title={i18next.t("page.private.ticket-system.updateTicketModal.ticketType")} value={<Tag style={{ background: getTypeAsTag(ticket.type.id, this.state.types).bgcolor }}>{getTypeAsTag(ticket.type.id, this.state.types).title}</Tag>} />
                            <SideElement title={i18next.t("page.private.ticket-system.updateTicketModal.source")} value={<Tag style={{ background: getChannelAsTag(ticket.channel).bgcolor }}>{getChannelAsTag(ticket.channel).title}</Tag>} />
                            <SideElement title={i18next.t("page.private.ticket-system.updateTicketModal.smid")} value={ticket.smid || <i>{i18next.t("page.private.ticket-system.updateTicketModal.no")}</i>}></SideElement>
                        </div>
                    </div>
                </Modal.Body>}
            </Modal>
            <TicketEmailModal instance={ref => this.emailModal = ref} refresh={() => this.fetch(this.state.ticket.id)} />
            <PDFModal instance={ref => this.pdfModal = ref} />
            <CreateMeasureModal instance={ref => this.createMeasureModal = ref} refresh={() => this.fetch(this.state.ticket.id)} />
        </>
    }
}

const Tag = styled.div`
    color: white;
    border-radius: 4px;
    padding: 4px 8px;
    user-select: none;
    width: min-content;
    font-size: 14px;
    white-space: nowrap;
`

const StyledActions = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 16px;
    overflow-y: scroll;
    padding-right: 16px;
    max-height: 400px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
    }
`

const StyledFile = styled.div`
    display: flex;
    align-items: flex-start;
    user-select: none;
    cursor: pointer;
    transition: border-color 0.3s;
    width: 100%;
    border: 1px solid #e5e5e5;
    padding: 16px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    min-height: 128px;
    position: relative;
    overflow: hidden;

    .trash {
        opacity: 0;
        position: absolute;
        width: 32px;
        height: 32px;
        background: #20242b;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        bottom: 0;
        cursor: pointer;
        border-radius: 4px 0 0 0;
        transition: opacity 0.3s;
        z-index: 2;
        user-select: none;

        &:hover {
            opacity: 0.75 !important;
        }
    }

    .fileName {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background: rgba(255, 255, 255, 0.75);
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        box-sizing: border-box;
        padding: 16px;
        opacity: 0;
        transition: opacity 0.3s;

        i {
          margin-right: 8px;
        }
    }

    &:hover {
        border-color: #3b97d3;

        .fileName {
            opacity: 1 !important;
        }

        .trash {
            opacity: 1;
        }
    }
`

const StyledSideElement = styled.div`
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: min-content;

    .title {
        margin-bottom: 8px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        user-select: none;
    }
`

class SideElement extends Component {
    render() {
        return <StyledSideElement>
            <p className="title">{this.props.title}</p>
            <div className="content">{this.props.value}</div>
        </StyledSideElement>
    }
}

const NewComment = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    
    input {
        width: 100%;
        box-sizing: border-box;
        height: 40px;
        padding: 8px;
        padding-right: 56px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        outline: 0;
        transition: border-color 0.3s;  
      
        &:focus {
            border-color: #3b97d3;
        }
    }
    
    .button {
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 5px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        background: ${props => props.disabled ? "gray !important" : "#3b97d3"};
        right: 0;
        cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
        transition: all 0.3s;
        box-shadow: ${props => props.disabled ? "none" : "0 2px 8px -3px #3b97d3"};
        font-size: 18px;
      
        &:hover {
            background: #6fb5e3;
        }
    }
`

const StyledNoComments = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 400px;
    user-select: none;
    margin-top: 16px;
    
    img {
        width: 250px;
        margin-bottom: 24px;
    }
  
    .title {
        font-size: 24px;
    }
  
    .subtitle {
        font-size: 18px;
        font-weight: 300;
        margin-top: 4px;
    }
`

class Comments extends Component {
    state = {
        comments: [],
        message: null,
        loading: true
    }

    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    TicketComments(filter: { ticketId_eq: $id }) {
                        nodes {
                            id
                            message
                            createdAt
                            user {
                                id
                                email
                                firstName
                                lastName
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.id
            }
        }).then(data => {
            this.setState({ comments: data.data.TicketComments.nodes, loading: false })
        })
    }

    submit() {
        if(this.state.loading || !this.state.message || (this.state.message && this.state.message.trim().length === 0)) return false

        const message = this.state.message

        this.setState({ loading: true, message: null })

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $message: String!) {
                    createTicketComment(ticketId: $id, message: $message)
                }
            `,
            variables: {
                id: this.props.id,
                message
            }
        }).then(this.fetch)
    }

    render() {
        return <Section.Item title={i18next.t("page.private.ticket-system.updateTicketModal.comments")} icon="comments" amount={this.state.comments.length} {...this.props}>
            <DeleteTicketCommentModal refresh={this.fetch} instance={ref => this.deleteCommentModal = ref} />
            <NewComment disabled={!this.state.message || (this.state.message && this.state.message.trim().length === 0)}>
                <input readOnly={this.state.loading || scanmetrix.checkPermission("Ticket") < 3} onKeyPress={e => {
                    if(e.key === "Enter") this.submit()
                }} value={this.state.message || ""} onChange={e => this.setState({ message: e.target.value || null })} placeholder={i18next.t("page.private.ticket-system.updateTicketModal.searchPlaceholderComment")} />
                <div className="button" onClick={() => this.submit()}>
                    <i className="fa-duotone fa-paper-plane" />
                </div>
            </NewComment>
            {this.state.comments.length === 0 && <StyledNoComments>
                <img src="/comments.svg" />
                <p className="title">{i18next.t("page.private.ticket-system.updateTicketModal.noComments")}</p>
                <p className="subtitle">{i18next.t("page.private.ticket-system.updateTicketModal.noUserCommentDescription")}</p>
            </StyledNoComments>}
            {this.state.comments.length > 0 && <StyledComments>
                {this.state.comments.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(comment => <Comment delete={() => this.deleteCommentModal.open(comment.id)} key={comment.id} data={comment} />)}
            </StyledComments>}
        </Section.Item>
    }
}


class Chat extends Component {
    state = {
        chats: [],
        message: null,
        loading: true
    }

    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    TicketChats(filter: { ticketId_eq: $id }) {
                        nodes {
                            id
                            fromEmail
                            deleted
                            message
                            createdAt
                            user {
                                id
                                email
                                firstName
                                lastName
                            }
                            serviceDeskUser {
                                id
                                email
                                firstName
                                lastName
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.id
            }
        }).then(data => {
            this.setState({ chats: data.data.TicketChats.nodes.map(chat => ({
                ...chat,
                user: chat.serviceDeskUser || chat.user
            })), loading: false })
        })
    }

    submit() {
        if(this.state.loading || !this.state.message || (this.state.message && this.state.message.trim().length === 0)) return false

        const message = this.state.message

        this.setState({ loading: true, message: null })

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $message: String!) {
                    createTicketChat(ticketId: $id, message: $message)
                }
            `,
            variables: {
                id: this.props.id,
                message
            }
        }).then(() => {
            this.fetch()
            this.props.refresh()
        })
    }

    render() {
        return <Section.Item title={i18next.t("page.private.ticket-system.updateTicketModal.chat")} icon="message-smile" amount={this.state.chats.length} {...this.props}>
            <DeleteTicketChatModal refresh={this.fetch} instance={ref => this.deleteChatModal = ref} />
            <NewComment disabled={!this.state.message || (this.state.message && this.state.message.trim().length === 0)}>
                <input readOnly={this.state.loading || scanmetrix.checkPermission("Ticket") < 3} onKeyPress={e => {
                    if(e.key === "Enter") this.submit()
                }} value={this.state.message || ""} onChange={e => this.setState({ message: e.target.value || null })} placeholder={i18next.t("page.private.ticket-system.updateTicketModal.searchPlaceholderChat")} />
                <div className="button" onClick={() => this.submit()}>
                    <i className="fa-duotone fa-paper-plane" />
                </div>
            </NewComment>
            {this.state.chats.length === 0 && <StyledNoComments>
                <img src="/chats.svg" />
                <p className="title">{i18next.t("page.private.ticket-system.updateTicketModal.noChats")}</p>
                <p className="subtitle">{i18next.t("page.private.ticket-system.updateTicketModal.noUserChatDescription")}</p>
            </StyledNoComments>}
            {this.state.chats.length > 0 && <StyledChats>
                {this.state.chats.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(chat => <ChatMessage left={(chat.user?.email || chat.fromEmail).toLowerCase() !== scanmetrix.session.email.toLowerCase()} delete={() => this.deleteChatModal.open(chat.id)} key={chat.id} data={chat} />)}
            </StyledChats>}
        </Section.Item>
    }
}

const StyledChats = styled.div`
  overflow-y: scroll;
  padding-right: 16px;
  height: 400px;
  margin-top: 16px;
`

const StyledComments = styled.div`
  overflow-y: scroll;
  padding-right: 16px;
  height: 400px;
  margin-top: 16px;
`

const StyledComment = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 16px 0;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
    &:last-child {
        border-bottom: none;
    }
  
    .avatar {
        border-radius: 100%;
    }
  
    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
      
        .timestamp {
            display: flex;
            align-items: center;
            
            p {
                font-size: 12px;
            }

            .delete {
                cursor: pointer;
                user-select: none;
                margin-left: 8px;
                height: 16px;
                width: 16px;
                transition: color 0.3s;
                
                &:hover {
                  color: #e74c3c;
                }
            }
        }
      
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            user-select: none;
          
            .user {
                font-weight: bold;
            }
        }
      
        .message {
            width: 100%;
            margin-top: 4px;
        }
    }
`


const StyledChat = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${props => props.left ? "flex-start" : "flex-end"};
    align-items: flex-end;
    gap: 12px;
    margin-bottom: 16px;
    
    .content {
        max-width: 100%;
        background: ${props => props.left ? "whitesmoke" : "#3b97d3"};
        color: ${props => props.left ? "black" : "white"};
        padding: 12px;
        border-radius: ${props => props.left ? "8px 8px 8px 0" : "8px 8px 0 8px"};
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      line-height: 22px;
      
      .bottom {
          width: 100%;
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          opacity: 0.75;
          gap: 8px;
          
          .delete {
                cursor: pointer;
          }
          
          .timestamp {
            display: flex;
            gap: 16px;
            align-items: center;
          }
      }
    }
    
    .avatar {
        border-radius: 100%;
        overflow: hidden;
    }
`

class Comment extends Component {
    render() {
        const comment = this.props.data

        return <StyledComment>
            <div className="avatar">
                <Avatar size="40" email={scanmetrix.disableGravatar ? null : comment.user.email} name={`${comment.user.firstName} ${comment.user.lastName}`} className="avatar" />
            </div>
            <div className="content">
                <div className="top">
                    <div className="user">
                        {comment.user.firstName} {comment.user.lastName}
                    </div>
                    <div className="timestamp">
                        <p>{moment(comment.createdAt).fromNow()}</p>{scanmetrix.session.id === comment.user.id && <i className="fa-duotone fa-trash delete" onClick={() => this.props.delete()} />}
                    </div>
                </div>
                <div className="message">{comment.message}</div>
            </div>
        </StyledComment>
    }
}

class ChatMessage extends Component {
    render() {
        const chat = this.props.data

        return <StyledChat left={this.props.left}>
            {this.props.left && <div className="avatar">
                <Avatar size="40" email={scanmetrix.disableGravatar ? null : (chat.user?.email || chat.fromEmail)} name={chat.user ? `${chat.user.firstName} ${chat.user.lastName}` : ""} className="avatar" />
            </div>}
            <div className="content">
                <div className="message">{chat.deleted ? <i style={{ opacity: 0.75 }}>Diese Nachricht wurde gelöscht.</i> : chat.message}</div>
                <div className="bottom">
                    {chat.user && <div className="user">
                        {chat.user.firstName} {chat.user.lastName}
                    </div>}
                    {!chat.user && <div className="user">
                        {chat.fromEmail} per E-Mail
                    </div>}
                    <div className="timestamp">
                        <p>{moment(chat.createdAt).fromNow()}</p>{scanmetrix.session.id === chat.user?.id && !this.props.left && !chat.deleted && <i className="fa-duotone fa-trash delete" onClick={() => this.props.delete()} />}
                    </div>
                </div>
            </div>
            {!this.props.left && <div className="avatar">
                <Avatar size="40" email={scanmetrix.disableGravatar ? null : (chat.user?.email || chat.fromEmail)} name={chat.user ? `${chat.user.firstName} ${chat.user.lastName}` : ""} className="avatar" />
            </div>}
        </StyledChat>
    }
}
