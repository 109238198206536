import styled from "styled-components"

export default styled.div`
  width: fit-content;
  height: 70px;
  background: white;
  padding: 10px 16px 10px 48px;
  border: 3px solid #20242B;
  border-radius: 5px;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: all 250ms ease;
  
  img {
    height: 64px;
    width: 32px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    user-select: none;
    user-drag: none;
  }
  
  b {
    width: 29px;
    font-family: "Euro Plate";
    position: absolute;
    left: 0;
    bottom: 4px;
    text-align: center;
    color: white;
    font-weight: normal;
  }
  
  p {
    font-family: "Euro Plate";
    font-size: 2.6em;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    word-spacing: 8px;
  }

  .letter-input, .number-input {
    width: auto;
    font-family: "Euro Plate";
    font-size: 2.6em;
    text-align: center;
    display: inline;
    outline: none;
    border: none;
  }
  
  .letter-input {
    margin-right: 16px;
  }
  
  .number-input {
    max-width: calc(4ch + 10px);
  }
`