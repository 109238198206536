import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 10px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  user-select: none;

  section {
    margin-bottom: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 1.2em;
      margin-bottom: 16px;
    }

    &:last-child {
      border-bottom: none;
    }
    
    &.skills-view {
      
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    
    &.about-text {
      
      textarea {
        padding: 0;
        resize: none;
        min-height: 128px;
        border-radius: 10px;
        max-height: 500px;
        font-size: 1em;
        font-family: inherit;
        border: 1px solid transparent;
        box-shadow: none;
        outline: none;
        cursor: default;
        pointer-events: none;
        transition: all 250ms ease;
        
        &.editable {
          resize: vertical;
          border: 1px solid rgba(59, 141, 211, .5);
          padding: 8px;
          cursor: text;
          pointer-events: auto;
        }
      }
    }
  }
`
