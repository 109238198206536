import styled from "styled-components"

export default styled.div`
    width: min-content !important;
    height: 48px;
    display: flex;
    justify-content: ${props => (props.selected && !props.alwaysCenter) ? "space-between" : "center"};
    align-items: center;
    border-bottom: ${props => props.selected ? "3px solid" : "1px solid"};
    border-color: ${props => props.selected ? "#3B97D3" : "rgba(0,0,0,0.1)"};
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    padding: 0 12px;
    padding-bottom: ${props => props.selected ? 0 : "2px"};
    box-sizing: border-box;
    color: ${props => props.disabled ? "gray !important" : (props.selected ? "black" : "gray")};
    transition: color 0.3s, border-color 0.3s;
    user-select: none;
    margin-top: 8px;
    opacity: ${props => props.disabled ? "0.5 !important" : 1};

    .amount {
        color: white;
        background: #3B97D3;
        min-width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 0.9em;
        text-align: center;
        line-height: 24px;
        transition: background 0.3s, opacity 0.3s;
        padding: 0 6px;
        box-sizing: border-box;
        white-space: nowrap;

        &:hover {
            opacity: 0.75;
        }
    }

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: none;
        white-space: nowrap;
        margin: 0 8px;
    }

    .arrowButton {
        color: white;
        background: #3B97D3;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        text-align: center;
        line-height: 24px;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.75;
        }
    }

    .arrowButtonLeft {
        visibility: ${props => props.showLeftArrow ? "visible" : "hidden"};
        pointer-events: ${props => props.showLeftArrow ? "auto" : "none"};
    }

    .arrowButtonRight {
        visibility: ${props => props.showRightArrow ? "visible" : "hidden"};
        pointer-events: ${props => props.showRightArrow ? "auto" : "none"};
    }

    .mainIcon {
        margin-right: 8px;
    }

    &:hover {
        color: black;
    }
`
