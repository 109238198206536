import React, { Component } from "react"
import { Modal, TextEditor } from "scanmetrix-components"
import moment from "moment"
import i18next from "i18next";

export default class extends Component {
    state = { text: null }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={measureOfferRequest => this.setState({ ...measureOfferRequest })}>
            <Modal.Head title={i18next.t("page.private.measure.showMeasureOfferRequestModal.quoteTitle")} icon="comments-question" />
            <Modal.Body padding={24}>
                <div style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
                    <TextEditor
                        readOnly
                        label={i18next.t("page.private.measure.showMeasureOfferRequestModal.quoteTitleReceiver")}
                        icon="font"
                        placeholder={i18next.t("page.private.measure.showMeasureOfferRequestModal.quoteTitleSearchPlaceholderGreetings")}
                        value={this.state.text}
                    />
                </div>
                {this.state.cancelledAt && <>
                    <Modal.Divider title={`{i18next.t("page.private.measure.showMeasureOfferRequestModal.cancellationTitle")} ${moment(this.state.cancelledAt).format("DD.MM.YYYY")} {i18next.t("page.private.measure.showMeasureOfferRequestModal.by")} ${this.state.cancelledBy.firstName} ${this.state.cancelledBy.lastName} an ${this.state.cancelEmail}`} />
                    <div style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
                        <TextEditor
                            readOnly
                            label={i18next.t("page.private.measure.showMeasureOfferRequestModal.cancellationReceiver")}
                            icon="font"
                            placeholder={i18next.t("page.private.measure.showMeasureOfferRequestModal.cancellationSearchPlaceholderGreetings")}
                            value={this.state.cancelText}
                        />
                    </div>
                </>}
            </Modal.Body>
        </Modal>
    }
}
