import React, { Component } from "react"
import styled from "styled-components"

const Button = styled.button`
    outline: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.disabled ? (props.secondary ? "#a1a1a1" : "linear-gradient(140deg,#3B97D3,#81c6f3)") : ( props.red ? "linear-gradient(45deg, rgb(192, 57, 43), rgb(255, 98, 82))" : (props.green ? "linear-gradient(140deg,#27ae60,#2ecc71)" : (props.secondary ? "#20242b" : (props.thick ? "linear-gradient(30deg,#0f7abf,#58b9f9)" : "linear-gradient(140deg,#3B97D3,#81c6f3)"))) )};
    filter: ${props => props.disabled ? "grayscale(100%)" : "grayscale(0%)"};
    cursor: ${props => props.disabled ? "not-allowed" : (props.loading ? "default" : "pointer")};
    color: white;
    height: ${props => props.thick ? "40px" : "32px"};
    padding: ${props => props.thick ? "8px 24px" : "0 12px"};
    border-radius: ${props => props.thick ? "5px" : "32px"};
    user-select: none;
    transition: all 0.3s;
    box-shadow: ${props => props.disabled ? "none" : ( props.red ? '0 2px 8px -3px #E74C3C' : (props.green ? "0 2px 8px -3px #27ae60" : (props.secondary ? "0 2px 8px -3px #20242b" : "0 2px 8px -3px #3B97D3")) )};
    width: ${props => props.adjustWidth ? "100%" : "fit-content"};
    float: ${props => props.float ? props.float : "none"};
    position: relative;
    
    .content {
      visibility: ${props => props.loading ? "hidden" : "visible"};
      display: flex;
      align-items: center;
      
      .title {
          white-space: nowrap;
      }
    }

    &:hover {
        opacity: ${props => props.disabled ? 1 : 0.75};
    }

    i {
        margin-left: ${props => props.noIconMargin ? 0 : "8px"};
        margin-right: 0 !important;
    }
`

const Spinner = styled.div`
  .lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: 75px;
  height: 25px;
  left: 50%;
  top: 8px;
  transform: translateX(-50%);
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  top: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

`

const uploadRef = React.createRef()

export default class extends Component {
    render() {
        return <Button {...this.props} onClick={e => {
            if(this.props.stopPropagation) e.stopPropagation()
            if(!this.props.disabled && !this.props.loading && this.props.onClick) this.props.onClick()
            if(this.props.fileUpload) uploadRef.current.click()
        }}>
            {this.props.fileUpload && <input ref={uploadRef} multiple={false} type="file" hidden onChange={this.props.fileUpload} />}
            <div className="content">
                <p className="title">{this.props.title}</p>
                {this.props.icon && <i className={this.props.iconType ? `${this.props.iconType} fa-${this.props.icon}` : `far fa-${this.props.icon}`} />}
            </div>
            {this.props.loading && <Spinner>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div></Spinner>}
        </Button>
    }
}
