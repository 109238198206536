import React, { Component } from "react"
import {Modal, Input, Button} from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { name: null, area: 0, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={position => {
            this.setState({ name: null, area: 0, position, loading: false })

            this.file = null
        }}>
            <Modal.Head title={i18next.t("page.private.space.createRoomModal.title")} icon="layer-plus" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.space.createRoomModal.name")} adjustWidth />
                    <Input required value={this.state.area} onChange={area => this.setState({ area })} icon="ruler-combined" label={i18next.t("page.private.space.createRoomModal.area")} float adjustWidth />
                </div>
                {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.area} title={i18next.t("page.private.space.createRoomModal.exitButton")} icon="layer-plus" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $area: Float!, $spaceId: ID!, $geometry: JSON!) {
                            createRoom(name: $name, area: $area, spaceId: $spaceId, geometry: $geometry)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        area: this.state.area,
                        spaceId: this.props.spaceId,
                        geometry: JSON.stringify({ center: this.state.position })
                    }
                }).then(result => {
                    if(result.data.createRoom) {
                        this.props.refresh()
                        instance.close()
                    } else {
                        this.setState({ loading: false })
                        this.setState({ error: "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut." })
                    }
                })}
            } />} />
        </Modal>
    }
}
