import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"
import styled from "styled-components"
import Dropzone from "react-dropzone"
import prettyBytes from "pretty-bytes"
import i18next from "i18next";

const Steps = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 32px;
    
    .step {
        display: flex;
        
        .number {
            width: 32px;
            height: 32px;
            background: #20242b;
            border-radius: 100%;
            margin-right: 16px;
            flex-shrink: 0;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9em;
        }
        
        .right {
            display: flex;
            flex-direction: column;
            
            .title {
                margin-bottom: 4px;
                font-weight: bold;
                font-size: 1.1em;
            }
        }
    }
`

const UploadBody = styled.div`
    box-shadow: ${props => props.isDragActive ? "inset 0 0 0 4px #3b97d3" : "inset 0 0 0 1px rgba(0, 0, 0, 0.25)"};
    padding: 24px;
    user-select: none;
    
    i {
        margin-right: 8px;
    }
`

const File = styled.div`
    padding: 24px;
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: 16px;
`

export default class extends Component {
    state = {
        fileName: null,
        fileSize: null,
        loading: false
    }

    render() {
        const purchaseOrderId = this.props.purchaseOrderId

        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({
                fileName: null,
                fileSize: null,
                loading: false
            })

            this.file = null
        }}>
            <Modal.Head title={i18next.t("page.private.purchaseOrder.confirmPurchaseOrderModal.title")} icon="spell-check" />
            <Modal.Body padding={24}>
                <Dropzone accept="application/pdf" multiple={false} onDrop={acceptedFiles => {
                    if(acceptedFiles.length && !this.state.loading) {
                        const file = acceptedFiles[0]
                        this.file = file

                        this.setState({ fileName: file.name, fileSize: file.size })
                        /*
                        this.setState({ uploading: true })

                        const file = acceptedFiles[0]

                        let variables = {
                            file
                        }

                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($file: Upload!, $purchaseOrderId: ID) {
                                    uploadPurchaseOrderConfirmation(file: $file, purchaseOrderId: $purchaseOrderId)
                                }
                            `,
                            context: {
                                hasUpload: true,
                                fetchOptions: {
                                    onUploadProgress: ev => {
                                        const progress = ev.loaded / ev.total

                                        this.setState({ uploadProgress: progress })
                                    }
                                }
                            },
                            variables
                        }).then(result => {
                            if(result.data.uploadPurchaseOrderConfirmation) {
                                this.setState({ uploading: false, uploadProgress: 1 })
                            }
                        })*/
                    }
                }}>
                    {({ getRootProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef" })}>
                        <p className="title"><i className="far fa-upload" /> {i18next.t("page.private.purchaseOrder.confirmPurchaseOrderModal.pdfDescription")}</p>
                    </UploadBody>}
                </Dropzone>
                {this.state.fileName && <File>
                    <p className="name">{this.state.fileName}</p>
                    <p className="size">{prettyBytes(this.state.fileSize, { locale: "de" })}</p>
                </File>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.purchaseOrder.confirmPurchaseOrderModal.button")} icon="spell-check" onClick={() => {
                this.setState({ loading: true })

                const mutateStatus = () => scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                                mutation($id: ID!, $status: String!) {
                                    updatePurchaseOrderStatus(id: $id, status: $status)
                                }
                            `,
                    variables: {
                        id: this.props.purchaseOrderId,
                        status: "execution"
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.updatePurchaseOrderStatus) {
                        this.props.refresh()
                        instance.close()
                    }
                })

                if(this.state.fileName) {
                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($file: Upload!, $id: ID!) {
                            uploadPurchaseOrderConfirmation(file: $file, id: $id)
                        }
                    `,
                        context: {
                            hasUpload: true
                        },
                        variables: {
                            id: this.props.purchaseOrderId,
                            file: this.file
                        }
                    }).then(result => {
                        if(result.data.uploadPurchaseOrderConfirmation) {
                            mutateStatus()
                        }
                    })
                } else {
                    mutateStatus()
                }
            }} />} />
        </Modal>
    }
}
