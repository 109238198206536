import React, { Component } from "react"
import styled from "styled-components"
import iconsArray from "./icons-array.json"
import i18next from "i18next"

const IconContainer = styled.div`
    height: 400px;
    overflow-y: scroll;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 48px 48px 48px 48px 48px 48px;
    grid-template-rows: repeat(10, 48px);
  
    .icon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid rgba(0, 0, 0, 0.2);
        transition: color 0.3s, background 0.3s, border-color 0.3s;
        
        &.selected {
            border-color: #3b97d3;
            background: #3b97d3;
            color: white;
        }
    }
`

const TopContainer = styled.div`
    .label {
        margin-bottom: 8px;
        font-size: 14px;
        user-select: none;
    }
  
  input {
      width: 100%;
      margin-bottom: 8px;
      height: 32px;
      font-family: inherit;
      box-sizing: border-box;
      padding: 0 8px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.2);
  }
`

export default class extends Component {
    state = { icon: null, search: null }

    constructor(props) {
        super(props)

        if(props.value) this.state.icon = props.value
    }

    render() {
        return <TopContainer>
            <div className="label">{i18next.t("component.iconSelector_factsheets.label")}</div>
            <input placeholder={i18next.t("component.iconSelector_factsheets.searchPlaceholder")} value={this.state.search || ""} onChange={e => this.setState({ search: e.target.value || null })} />
            <IconContainer>
                {iconsArray.filter(icon => icon !== "ad" && icon !== "font-awesome-logo-full").filter(icon => !this.state.search || (this.state.search && icon.toLowerCase().includes(this.state.search.trim().toLowerCase()))).map(icon => <div onClick={() => this.setState({ icon }, () => {
                    this.props.onChange(icon)
                })} key={icon} className={`icon ${this.state.icon === icon ? "selected" : ""}`}>
                    <i className={`far fa-${icon}`} />
                </div>)}
            </IconContainer>
        </TopContainer>
    }
}
