import React, {useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {
    Announcements,
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragStartEvent,
    DragOverlay,
    DragMoveEvent,
    DragEndEvent,
    DragOverEvent,
    MeasuringStrategy,
    DropAnimation,
    Modifier,
    defaultDropAnimation,
    UniqueIdentifier,
} from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
    buildTree,
    flattenTree,
    getProjection,
    getChildCount,
    removeItem,
    removeChildrenOf,
    setProperty,
} from './utilities';
import {sortableTreeKeyboardCoordinates} from './keyboardCoordinates';
import {SortableTreeItem} from './components';
import {CSS} from '@dnd-kit/utilities';
import styled from "styled-components";

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always,
    },
};

const dropAnimationConfig = {
    keyframes({transform}) {
        return [
            {opacity: 1, transform: CSS.Transform.toString(transform.initial)},
            {
                opacity: 0,
                transform: CSS.Transform.toString({
                    ...transform.final,
                    x: transform.final.x + 5,
                    y: transform.final.y + 5,
                }),
            },
        ];
    },
    easing: 'ease-out',
    sideEffects({active}) {
        active.node.animate([{opacity: 0}, {opacity: 1}], {
            duration: defaultDropAnimation.duration,
            easing: defaultDropAnimation.easing,
        });
    },
};

function modifyItems(items, id, newText) {
    items.forEach(item => {
        if(item.id === id) item.text = newText
        else if(item.children) item.children = modifyItems(item.children, id, newText)
    })

    return items
}

function modifyItemsInputs(items, id, newInputs) {
    items.forEach(item => {
        if(item.id === id) item.inputs = newInputs
        else if(item.children) item.children = modifyItems(item.children, id, newInputs)
    })

    return items
}

export function SortableTree({
     collapsible,
     defaultItems,
     createCallback,
     updateCallbackText,
     updateCallbackInputs,
     deleteCallback,
     updateCallbackPosition,
     indicator = false,
     indentationWidth = 50,
     removable,
 }) {
    const [items, setItems] = useState(() => defaultItems);
    const [activeId, setActiveId] = useState(null);
    const [overId, setOverId] = useState(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(null);

    createCallback(newItem => setItems([...items, newItem]))

    const flattenedItems = useMemo(() => {
        const flattenedTree = flattenTree(items);
        const collapsedItems = flattenedTree.reduce(
            (acc, {children, collapsed, id}) =>
                collapsed && children.length ? [...acc, id] : acc,
                []
        );

        return removeChildrenOf(
            flattenedTree,
            activeId ? [activeId, ...collapsedItems] : collapsedItems
        );
    }, [activeId, items])

    const projected =
        activeId && overId
            ? getProjection(
            flattenedItems,
            activeId,
            overId,
            offsetLeft,
            indentationWidth
            )
            : null;
    const sensorContext = useRef({
        items: flattenedItems,
        offset: offsetLeft,
    });
    const [coordinateGetter] = useState(() =>
        sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
    );
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter,
        })
    );

    const sortedIds = useMemo(() => flattenedItems.map(({id}) => id), [
        flattenedItems,
    ]);
    const activeItem = activeId
        ? flattenedItems.find(({id}) => id === activeId)
        : null;

    useEffect(() => {
        sensorContext.current = {
            items: flattenedItems,
            offset: offsetLeft,
        };
    }, [flattenedItems, offsetLeft]);

    const announcements = {
        onDragStart({active}) {
            return `Picked up ${active.id}.`;
        },
        onDragMove({active, over}) {
            return getMovementAnnouncement('onDragMove', active.id, over?.id);
        },
        onDragOver({active, over}) {
            return getMovementAnnouncement('onDragOver', active.id, over?.id);
        },
        onDragEnd({active, over}) {
            return getMovementAnnouncement('onDragEnd', active.id, over?.id);
        },
        onDragCancel({active}) {
            return `Moving was cancelled. ${active.id} was dropped in its original position.`;
        },
    };

    return <StyledContainer>
        <DndContext
            accessibility={{announcements}}
            sensors={sensors}
            collisionDetection={closestCenter}
            measuring={measuring}
            onDragStart={handleDragStart}
            onDragMove={handleDragMove}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
                {flattenedItems.map(({id, text, children, collapsed, inputs, formattedIndex, depth}) => (
                    <SortableTreeItem
                        key={id}
                        id={id}
                        value={id}
                        text={text}
                        inputs={inputs}
                        number={formattedIndex}
                        onChange={newText => {
                            updateCallbackText(id, newText)
                            const modified = modifyItems(items, id, newText)
                            setItems([...modified])
                        }}
                        onInputsChange={newInputs => {
                            updateCallbackInputs(id, newInputs)
                            const modified = modifyItemsInputs(items, id, newInputs)
                            setItems([...modified])
                        }}
                        depth={id === activeId && projected ? projected.depth : depth}
                        indentationWidth={indentationWidth}
                        indicator={indicator}
                        collapsed={Boolean(collapsed && children.length)}
                        onCollapse={
                            collapsible && children.length
                                ? () => handleCollapse(id)
                                : undefined
                        }
                        onRemove={removable ? () => {
                            deleteCallback(id)
                            return handleRemove(id)
                        } : undefined}
                    />
                ))}
                {createPortal(
                    <DragOverlay
                        dropAnimation={dropAnimationConfig}
                        modifiers={indicator ? [adjustTranslate] : undefined}
                    >
                        {activeId && activeItem ? (
                            <SortableTreeItem
                                id={activeId}
                                depth={activeItem.depth}
                                clone
                                text={activeItem.text}
                                inputs={activeItem.inputs}
                                childCount={getChildCount(items, activeId) + 1}
                                value={activeId.toString()}
                                indentationWidth={indentationWidth}
                            />
                        ) : null}
                    </DragOverlay>,
                    document.body
                )}
            </SortableContext>
        </DndContext>
    </StyledContainer>;

    function handleDragStart({active: {id: activeId}}) {
        setActiveId(activeId);
        setOverId(activeId);

        const activeItem = flattenedItems.find(({id}) => id === activeId);

        if (activeItem) {
            setCurrentPosition({
                parentId: activeItem.parentId,
                overId: activeId,
            });
        }

        document.body.style.setProperty('cursor', 'grabbing');
    }

    function handleDragMove({delta}) {
        setOffsetLeft(delta.x);
    }

    function handleDragOver({over}) {
        setOverId(over?.id ?? null);
    }

    function handleDragEnd({active, over}) {
        resetState();

        if (projected && over) {
            const {depth, parentId} = projected;
            const clonedItems = JSON.parse(
                JSON.stringify(flattenTree(items))
            );
            const overIndex = clonedItems.findIndex(({id}) => id === over.id);
            const activeIndex = clonedItems.findIndex(({id}) => id === active.id);
            const activeTreeItem = clonedItems[activeIndex];

            clonedItems[activeIndex] = {...activeTreeItem, depth, parentId};

            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
            const newItems = buildTree(sortedItems);

            updateCallbackPosition(getMovementAnnouncementRaw('onDragEnd', active.id, over?.id))

            setItems(newItems);
        }
    }

    function handleDragCancel() {
        resetState();
    }

    function resetState() {
        setOverId(null);
        setActiveId(null);
        setOffsetLeft(0);
        setCurrentPosition(null);

        document.body.style.setProperty('cursor', '');
    }

    function handleRemove(id) {
        setItems((items) => removeItem(items, id));
    }

    function handleCollapse(id) {
        setItems((items) =>
            setProperty(items, id, 'collapsed', (value) => {
                return !value;
            })
        );
    }


    function getMovementAnnouncementRaw(
        eventName,
        activeId,
        overId
    ) {
        if (overId && projected) {
            if (eventName !== 'onDragEnd') {
                if (
                    currentPosition &&
                    projected.parentId === currentPosition.parentId &&
                    overId === currentPosition.overId
                ) {
                    return;
                } else {
                    setCurrentPosition({
                        parentId: projected.parentId,
                        overId,
                    });
                }
            }

            const clonedItems = JSON.parse(
                JSON.stringify(flattenTree(items))
            );
            const overIndex = clonedItems.findIndex(({id}) => id === overId);
            const activeIndex = clonedItems.findIndex(({id}) => id === activeId);
            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

            const previousItem = sortedItems[overIndex - 1];

            let announcement;

            if (!previousItem) {
                const nextItem = sortedItems[overIndex + 1];
                announcement = { sourceId: activeId, targetId: nextItem.id, type: "before" }
            } else {
                if (projected.depth > previousItem.depth) {
                    announcement = { sourceId: activeId, targetId: previousItem.id, type: "under" }
                } else {
                    let previousSibling = previousItem;
                    while (previousSibling && projected.depth < previousSibling.depth) {
                        const parentId = previousSibling.parentId;
                        previousSibling = sortedItems.find(({id}) => id === parentId);
                    }

                    if (previousSibling) {
                        announcement = { sourceId: activeId, targetId: previousSibling.id, type: "after" }
                    }
                }
            }

            return announcement;
        }

        return;
    }

    function getMovementAnnouncement(
        eventName,
        activeId,
        overId
) {
        if (overId && projected) {
            if (eventName !== 'onDragEnd') {
                if (
                    currentPosition &&
                    projected.parentId === currentPosition.parentId &&
                    overId === currentPosition.overId
                ) {
                    return;
                } else {
                    setCurrentPosition({
                        parentId: projected.parentId,
                        overId,
                    });
                }
            }

            const clonedItems = JSON.parse(
                JSON.stringify(flattenTree(items))
            );
            const overIndex = clonedItems.findIndex(({id}) => id === overId);
            const activeIndex = clonedItems.findIndex(({id}) => id === activeId);
            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

            const previousItem = sortedItems[overIndex - 1];

            let announcement;
            const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
            const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

            if (!previousItem) {
                const nextItem = sortedItems[overIndex + 1];
                announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
            } else {
                if (projected.depth > previousItem.depth) {
                    announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
                } else {
                    let previousSibling = previousItem;
                    while (previousSibling && projected.depth < previousSibling.depth) {
                        const parentId = previousSibling.parentId;
                        previousSibling = sortedItems.find(({id}) => id === parentId);
                    }

                    if (previousSibling) {
                        announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
                    }
                }
            }

            return announcement;
        }

        return;
    }
}


const adjustTranslate = ({transform}) => {
    return {
        ...transform,
        y: transform.y - 25,
    };
};

const StyledContainer = styled.div`
  .Wrapper {
    list-style: none;
    box-sizing: border-box;
    padding-left: var(--spacing);
    margin-bottom: -1px;
  }
  .Wrapper.clone {
    display: inline-block;
    pointer-events: none;
    padding: 0;
    padding-left: 10px;
    padding-top: 5px;
  }
  .Wrapper.clone .TreeItem {
    --vertical-padding: 5px;
    padding-right: 24px;
    border-radius: 4px;
    box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
  }
  .Wrapper.ghost.indicator {
    opacity: 1;
    position: relative;
    z-index: 1;
    margin-bottom: -1px;
  }
  .Wrapper.ghost.indicator .TreeItem {
    position: relative;
    padding: 0;
    height: 8px;
    border-color: #3b97d3;
    background-color: #3b97d3;
  }
  .Wrapper.ghost.indicator .TreeItem:before {
    position: absolute;
    left: -8px;
    top: -4px;
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #3b97d3;
    background-color: #ffffff;
  }
  .Wrapper.ghost.indicator .TreeItem > * {
    /* Items are hidden using height and opacity to retain focus */
    opacity: 0;
    height: 0;
  }
  .Wrapper.ghost:not(.indicator) {
    opacity: 0.5;
  }
  .Wrapper.ghost .TreeItem > * {
    box-shadow: none;
    background-color: transparent;
  }
  .TreeItem {
    --vertical-padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: var(--vertical-padding) 10px;
    background-color: #fff;
    border: 1px solid #dedede;
    color: #222;
    box-sizing: border-box;
  }

  .TreeItem > .top {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
  }

  .TreeItem > .bottom {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(6, 1fr);
    margin-top: 8px;
    width: 100%;
  }

  .Text {
    flex-grow: 1;
    padding-left: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .Count {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #2389ff;
    font-size: 0.8rem;
    font-weight: 600;
    color: #fff;
  }
  .disableInteraction {
    pointer-events: none;
  }
  .disableSelection .Text, .clone .Text, .disableSelection .Count, .clone .Count {
    user-select: none;
    -webkit-user-select: none;
  }
  .Collapse svg {
    transition: transform 250ms ease;
  }
  .Collapse.collapsed svg {
    transform: rotate(-90deg);
  }
  .Action {
    display: flex;
    width: 12px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    touch-action: none;
    cursor: var(--cursor, pointer);
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
  @media (hover: hover) {
    .Action:hover {
      background-color: var(--action-background, rgba(0, 0, 0, 0.05));
    }
    .Action:hover svg {
      fill: #6f7b88;
    }
  }
  .Action svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #919eab;
  }
  .Action:active {
    background-color: var(--background, rgba(0, 0, 0, 0.05));
  }
  .Action:active svg {
    fill: var(--fill, #788491);
  }
  .Action:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
  }


`
