import styled from "styled-components"

export default styled.div`
  background: white;
  grid-column-start: 1;
  grid-column-end: ${props => props.columnEnd || 6};
  grid-row-start: ${props => props.rowStart || 7};
  grid-row-end: ${props => props.rowEnd || 8};
  box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  padding: 22px 32px;
  box-sizing: border-box;
  position: relative;
  margin-right: 10%;
  z-index: 1;
  height: 112px;
  
  .rightButton {
    width: calc(10% + 32px);
    padding: 16px 0;
    padding-left: 40px;
    padding-right: 8px;
    box-sizing: border-box;
    position: absolute;
    right: calc(-10% - 16px);
    top: 0;
    background: #3b97d3;
    color: white;
    border-radius: 0 16px 16px 0;
    z-index: 0;
    box-shadow: inset 50px 0 24px -32px rgba(0, 0, 0, 0.2);
    font-size: 0.9em;
    user-select: none;
    cursor: pointer;
    transition: opacity 0.3s;
    
    i {
      display: none;
    }
    
    &:hover {
      opacity: 0.75;
    }
    
    &:before {
      content: " ";
      display: block;
      height: 100%;
      width: 16px;
      background: white;
      border-radius: 0 16px 0 0;
      position: absolute;
      left: 0;
      top: 0;
    }
    
    &.disabled {
      background: gray;
      opacity: 1;
      cursor: not-allowed;
    }
  }
  
  @media screen and (max-width: 1919px) {
    grid-row-start: 10;
    grid-row-end: 11;
    
    .rightButton {
      padding: 16px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      
      p {
        display: none;
      }
      
      i {
        display: block;
        font-size: 1.5em;
        padding-left: 16px;
      }
    }
  }
`
