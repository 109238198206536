import React, { Component } from 'react'
import { Page, Breadcrumbs, Section, Button, GridElement, Checkbox } from 'scanmetrix-components'
import styled from "styled-components"
import { MoonLoader } from "react-spinners"
import i18next from "i18next";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

const ObjectClassContainer = styled.div`
  background: white;
  width: 100%;
  border-radius: 5px;
  position: relative;
  box-shadow: ${props => props.soon ? "none" : "0 4px 24px -8px rgb(0 0 0 / 15%)"};
  user-select: none;
  filter: ${props => props.soon ? "blur(4px)" : "none"};
  pointer-events: ${props => props.soon ? "none" : "all"};
  opacity: ${props => props.soon ? 0.25 : 1};
  box-sizing: border-box;
  padding: 24px 24px 90px;
  height: 100%;

  ul {
    list-style-type: none;
    margin: 8px 0;

    li {
      display: inline-block;
      margin: 4px;
      padding: 4px;
      background: rgba(0, 0, 0, 0.075);
      border-radius: 4px;
      font-size: 12px;
    }
  }

  .price {
    margin-bottom: 8px;
    font-size: 1em;
    position: absolute;
    right: -10px;
    top: 24px;
    background: #3b97d3;
    color: white;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    padding-left: 16px;
    border-radius: 4px 0 4px 4px;
    user-select: none;

    &::before {
      display: block;
      content: " ";
      position: absolute;
      right: 0;
      top: -8px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #16669a;
      transform: rotate(-135deg);
      z-index: -1;
    }
  }

  h2 {
    font-weight: 300;
    padding-right: 64px;
  }

  h3 {
    margin: 4px 0;
    font-weight: 300;
    margin-top: 8px;
    font-size: 1.1em;
    line-height: 1.5em;
    opacity: 0.75;

    i {
      color: #3b97d3;
      width: 24px;
    }
  }

  p.components, p.inspections {
    i {
      width: 24px;
    }
  }

  p.inspections {
    margin-top: 8px;
  }

  p.description {
    padding-right: 16px;
    line-height: 1.6em;
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 16px;

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.075);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }

  .buttonContainer {
    position: absolute;
    left: 50%;
    bottom: 8px;
    transform: translate(-50%, -50%);
  }

  .header {
    height: 96px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 16px;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      -webkit-user-drag: none;
    }
  }
`

const ObjectClasses = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    grid-column-start: 1;
    grid-column-end: 2;
    overflow-y: scroll;
    padding-right: 32px;
    box-sizing: border-box;
    height: calc(100vh - 300px);
    padding-bottom: 64px;
    grid-template-rows: min-content min-content min-content;
    
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
  
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
    }
`

class ObjectClass extends Component {
    state = { loading: false, disabled: null }

    render() {
        const data = this.props.data

        return <div style={{ position: "relative" }}>
            {this.props.data.soon && <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", userSelect: "none", fontSize: "1.2em", opacity: 0.25, background: "#20242b", padding: "6px 12px", color: "white", borderRadius: "5px" }}><i className="far fa-clock" /> {i18next.t("page.private.marketPlace.activateObjectClasses.soonAvailable")}</div>}
            <ObjectClassContainer soon={this.props.data.soon}>
                <h2>{data.name}</h2>
                {/*<p className="price">{formatter.format(data.price)}</p>*/}
                <p className="price">{i18next.t("page.private.marketPlace.activateObjectClasses.priceClassA")}</p>
                <h3><i className={`far fa-${data.workSectionIcon}`} /> {data.workSectionName}</h3>
                <p className="components"><i className="far fa-puzzle-piece" /> {data.componentAmount} {i18next.t("page.private.marketPlace.activateObjectClasses.component")}</p>
                <ul>
                    {data.components.map((component, index) => <li key={index}>{component.name}</li>)}
                </ul>
                <p className="inspections"><i className="far fa-list-ul" /> {data.inspectionAmount} {i18next.t("page.private.marketPlace.activateObjectClasses.tests")}</p>
                <ul>
                    {data.inspections.map((inspection, index) => <li key={index}>{inspection.name}</li>)}
                </ul>
                <div className="buttonContainer">
                    <Button loading={this.state.loading} secondary={this.props.status !== "available"} disabled={!(this.props.status === "available" || this.props.status === "basket")} thick title={this.props.status === "available" ? i18next.t("page.private.marketPlace.activateObjectClasses.activationListButton.inActivationList") : (this.props.status === "basket" ? i18next.t("page.private.marketPlace.activateObjectClasses.activationListButton.outActivationList") : this.props.status === "bought" ? i18next.t("page.private.marketPlace.activateObjectClasses.activationListButton.alreadyActivated") : i18next.t("page.private.marketPlace.activateObjectClasses.activationListButton.unknown"))} icon={this.props.status === "available" ? "shopping-cart" : "times"} onClick={() => {
                        if(this.props.disabled) return false

                        if(this.props.status === "available") this.props.onAddBasket()
                        else if(this.props.status === "basket") this.props.onRemoveBasket()
                    }} />
                </div>
            </ObjectClassContainer>
        </div>
    }
}

const MainGrid = styled.div`
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 400px;
    overflow: hidden;
`

const Items = styled.div`
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    padding-right: 16px;
  
    &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.1);
    }
  
    &::-webkit-scrollbar-thumb {
        background: #3b97d3;
        border-radius: 4px;
    }
`

const StyledItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  
    &:first-child {
        margin-top: 0;
    }
  
    .price {
        font-weight: bold;
    }
  
    .title {
        font-size: 1.2em;
    }
  
    .workSection {
        font-size: 0.9em;
        opacity: 0.75;
        margin-top: 4px;
        line-height: 1.4em;
    }
`

class Item extends Component {
    render() {
        return <StyledItem>
            <div className="left">
                <p className="title">{this.props.title}</p>
                <p className="workSection"><i className={`far fa-${this.props.workSection.icon}`} /> {this.props.workSection.title}</p>
            </div>
            <div className="price">{formatter.format(this.props.price)}</div>
        </StyledItem>
    }
}

const Total = styled.div`
    width: 100%;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
    .row {
        display: flex;
        width: 100%;
        justify-content: space-between;
      
        &:first-child {
            font-size: 1.1em;
        }
      
        &:last-child {
            font-size: 0.9em;
            opacity: 0.75;
            margin-top: 4px;
        }
    }
`

const StyledSearchBar = styled.div`
    width: 100%;
    height: 48px;
    background: white;
    grid-column-start: 1;
    grid-column-end: 3;
    box-shadow: 0 4px 24px -8px rgb(0 0 0 / 15%);
    border-radius: 24px;
    display: flex;
    position: relative;
    overflow: hidden;
  
    .amount {
        user-select: none;
        position: absolute;
        right: 16px;
        height: 48px;
        line-height: 48px;
        white-space: nowrap;
        color: #3B97D3;
    }
  
    i {
        position: absolute;
        height: 48px;
        left: 20px;
        display: flex;
        align-items: center;
    }
  
    input {
        outline: 0;
        border: none;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-left: 48px;
        font-family: inherit;
        font-size: 14px;
      
        &::placeholder {
            font-family: inherit;
            color: rgba(0, 0, 0, 0.5);
        }
    }
`

const ObjectClassesParent = styled.div`
    position: relative;
    
    &:after {
        content: " ";
        display: block;
        position: absolute;
        width: calc(100% - 16px);
        height: 64px;
        bottom: 0;
        left: 0;
        z-index: 5;
        pointer-events: none;
        background: linear-gradient(0deg, #EBEEF3, transparent);
    }
`

class Searchbar extends Component {
    render() {
        return <StyledSearchBar>
            <i className="far fa-search" />
            <input onChange={e => this.props.onChange(e.target.value)} placeholder={i18next.t("page.private.marketPlace.activateObjectClasses.searchPlaceholder")} autoCorrect={false} spellCheck={false} />
            <p className="amount">{this.props.amount} {i18next.t("page.private.marketPlace.activateObjectClasses.results")}</p>
        </StyledSearchBar>
    }
}

const WorkSectionTitle = styled.div`
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
    font-size: 1.4em;
    align-items: center;
    white-space: nowrap;
  
    &:after {
        content: " ";
        display: block;
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
    }
    
    i {
        margin-right: 16px;
        background: #3b97d3;
        color: white;
        height: 40px;
        width: 40px;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 40px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 8px -3px #3b97d3;
    }
`

const NoBasket = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    padding: 0 32px;
    height: 100%;
  
    img {
        width: 80%;
        max-height: 128px;
        margin-bottom: 16px;
        filter: grayscale(1);
        opacity: 0.5;
    }
  
    h2 {
        font-weight: normal;
        margin-bottom: 8px;
        font-size: 22px;
    }
  
    p {
        opacity: 0.75;
    }
`

export default class extends Component {
    state = {
        modalOpen: false,
        items: [],
        owned: [],
        basket: [],
        terms: false,
        loading: false,
        search: null,
        initialLoading: true
    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetch()
    }

    fetch(disableLoading) {
        return fetch(scanmetrix.backendURL + "/marketplace/object_classes", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            console.log(data)
            if(!data.error) this.setState({ initialLoading: false, items: data.items, owned: data.owned, loading: disableLoading ? false : this.state.loading, basket: disableLoading ? [] : this.state.basket, terms: disableLoading ? false : this.state.terms })
        })
    }

    render() {
        let items = [ ...this.state.items ]

        if(this.state.search && this.state.search.trim()) {
            const search = this.state.search.trim().toLowerCase()

            items = items.filter(item => {
                return item.name.toLowerCase().includes(search) || item.workSectionName.toLowerCase().includes(search) || item.description.toLowerCase().includes(search)
            })
        }

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "store-alt",
                    title: i18next.t("page.private.marketPlace.breadCrumbsTitle")
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.marketPlace.activateObjectClasses.sectionTitle")} icon="shopping-cart">
                    <MainGrid>
                        <ObjectClassesParent>
                            <ObjectClasses>
                                <Searchbar amount={items.length} onChange={search => this.setState({ search })} />
                                {this.state.initialLoading && <div style={{ gridColumnStart: 1, gridColumnEnd: 3, width: "100%", display: "flex", justifyContent: "center" }}>
                                    <MoonLoader
                                        color="#3b97d3"
                                    />
                                </div>}
                                {items.sort((a, b) => a.workSectionName.localeCompare(b.workSectionName)).filter((v,i,a)=>a.findIndex(t=>(t.workSectionId === v.workSectionId))===i).map((item, index) => {
                                    const filtered = items.filter(target => target.workSectionId === item.workSectionId).sort((a, b) => a.name.localeCompare(b.name))

                                    if(filtered.length === 0) return null

                                    return <>
                                        <WorkSectionTitle key={index}>
                                            <i className={`far fa-${item.workSectionIcon}`} />
                                            {item.workSectionName}
                                        </WorkSectionTitle>
                                        {filtered.map(item => <ObjectClass disabled={this.state.loading} onRemoveBasket={() => {
                                            let basket = this.state.basket.filter(bas => bas.id !== item.id)
                                            this.setState({ basket })
                                        }} onAddBasket={() => {
                                            let basket = this.state.basket
                                            basket.push(item)
                                            this.setState({ basket })
                                        }} status={this.state.owned.find(own => String(own.id) === String(item.id)) ? "bought" : (this.state.basket.find(b => b.id === item.id) ? "basket" : "available")} key={item.id} data={item} />)}
                                    </>
                                })}
                            </ObjectClasses>
                        </ObjectClassesParent>
                        <GridElement styled title={i18next.t("page.private.marketPlace.activateObjectClasses.activationList.title")} icon="shopping-cart" padding="16px">
                            <Items>
                                {this.state.basket.map((item, index) => <Item key={index} title={item.name} workSection={{ icon: item.workSectionIcon, title: item.workSectionName }} price={item.price} />)}
                                {this.state.basket.length === 0 && <NoBasket>
                                    <img src="/empty_cart.svg" />
                                    <h2>{i18next.t("page.private.marketPlace.activateObjectClasses.activationList.text1")}</h2>
                                    <p>{i18next.t("page.private.marketPlace.activateObjectClasses.activationList.text2")}</p>
                                </NoBasket>}
                            </Items>
                            {/*<Total>
                                <div className="row">
                                    <p>Gesamt Netto</p>
                                    <p>{formatter.format(this.state.basket.map(i => i.price).reduce((a, b) => a + b, 0))}</p>
                                </div>
                                <div className="row">
                                    <p>Gesamt Brutto</p>
                                    <p>{formatter.format(this.state.basket.map(i => i.price).reduce((a, b) => a + b, 0) * 1.19)}</p>
                                </div>
                            </Total>*/}
                            <Checkbox disabled={this.state.loading} value={this.state.terms} onChange={terms => this.setState({ terms })} label={i18next.t("page.private.marketPlace.activateObjectClasses.activationList.legalCondition")} />
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: 16 }}>
                                <Button loading={this.state.loading} disabled={!this.state.terms || this.state.basket.length === 0} title={i18next.t("page.private.marketPlace.activateObjectClasses.activationList.exitButton")} thick icon="shopping-cart" onClick={() => {
                                    this.setState({ loading: true })

                                    fetch(scanmetrix.backendURL + "/marketplace/checkout", {
                                        method: "POST",
                                        credentials: "include",
                                        body: JSON.stringify(this.state.basket),
                                        headers: {
                                            "Content-Type": "application/json"
                                        }
                                    }).then(result => result.json()).then(data => {
                                        if(!data.error) this.fetch(true)
                                        else alert(data.error)
                                    })
                                }} />
                            </div>
                        </GridElement>
                    </MainGrid>
                </Section.Item>
                <Section.Item disabled title={i18next.t("page.private.marketPlace.activatedObjectClasses.sectionTitle")} icon="puzzle-piece">
                </Section.Item>
            </Section>
        </Page>
    }
}
