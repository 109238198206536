import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 10px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  user-select: none;
  
  .header {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    
    i {
      margin-right: 8px;
    }
  }
  
  .body {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 25px 0;

      .label {
        max-width: 50%;
      }
    }
  }
`
