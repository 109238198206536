import styled from "styled-components"

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    user-select: none;
    
    img {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        object-fit: cover;
        box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.5);
        padding: 4px;
    }
    
    .name {
      font-size: 1.2em;
      margin-top: 16px;
    }
    
    .email {
      font-size: 0.9em;
      margin-top: 8px;
      opacity: 0.75;
    }
    
    .avatar {
      border-radius: 100%;
    }
    
    button {
      outline: 0;
      border: none;
      height: 32px;
      padding: 0 16px;
      margin-top: 16px;
      color: #20242b;
      background: white;
      border-radius: 32px;
      cursor: pointer;
      transition: opacity 0.3s;
      box-shadow: 0 3px 8px -4px rgba(0, 0, 0, 0.4);
      
      &:disabled {
        opacity: 1 !important;
        cursor: not-allowed;
        background: gray !important;
        color: white !important;
      }
      
      &:hover {
        opacity: 0.75;
      }
      
      i {
        margin-right: 8px;
      }
      
      &.primary {
        background: #3B97D3;
        color: white;
      }
    }
`
