import i18n from "i18next"

import de from "./languages/de.json"
import en from "./languages/en.json"

export default () => {
    return i18n.init({
        lng: scanmetrix.session ? scanmetrix.session.language.split("_")[0] : "de",
        fallbackLng: scanmetrix.session ? scanmetrix.session.language.split("_")[0] : "de",
        resources: { ...de, ...en }
    })
}
