import React, { Component } from 'react'
import { Modal, Input, Grid, GridElement, Select, Section, Table, Button, Checkbox } from 'scanmetrix-components'

import UpdateContractProvisionIntervalVariationModal from "./UpdateContractProvisionIntervalVariationModal";
import { getIntervalType } from "../../object"
import i18next from "i18next";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = { loading: false, subsidiaryId: null, subsidiaries: [], variations: null, provisionInterval: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
    }

    fetch(provisionInterval) {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!, $contractId: ID!) {
                    ContractProvisionIntervalVariations(filter: { contractProvisionIntervalId_eq: $id }) {
                        nodes {
                            id
                            cost
                            intervalAmount
                            intervalType
                            inactive
                            startingAt
                            subsidiaryContract {
                                id
                                subsidiary {
                                    id
                                    name
                                    label
                                    address {
                                        postalCode
                                        city
                                        streetName
                                        streetNumber
                                    }
                                }
                            }
                        }
                    }
                    SubsidiaryContracts(filter: { contractId_eq: $contractId }) {
                        nodes {
                            id
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    postalCode
                                    city
                                    streetName
                                    streetNumber
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                id: provisionInterval.id,
                contractId: this.props.contractId
            }
        }).then(data => {
            this.setState({ variations: data.data.ContractProvisionIntervalVariations.nodes, subsidiaries: data.data.SubsidiaryContracts.nodes.map(n => ({ rawId: n.id, ...n.subsidiary })) })
        })
    }

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={provisionInterval => {
            this.setState({ subsidiaryId: null, subsidiaries: [], variations: [], provisionInterval, loading: false })

            this.fetch(provisionInterval)
        }}>
            <UpdateContractProvisionIntervalVariationModal instance={ref => this.updateContractProvisionIntervalVariationModal = ref} refresh={() => this.fetch(this.state.provisionInterval)} />
            <Modal.Head title={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.title")} icon="history" />
            <Modal.Body padding={24}>
                <Section bodyPadding="32px 0 0 0">
                    <Section.Item title={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.sectionElementTitle")} icon="hourglass">
                        {this.state.provisionInterval && <Input.MutationProvider value={{
                            name: "updateContractProvisionInterval",
                            id: this.state.provisionInterval.id,
                            data: this.state.provisionInterval,
                            onSave: () => this.props.refresh()
                        }}>
                            <Grid gap="16px" columns={[ "1.5fr", "1fr", "0.75fr" ]}>
                                <GridElement columnStart={1} columnEnd={4}>
                                    <Input disabled={scanmetrix.checkPermission("ContractProvisionInterval") < 3} date field="startingAt" icon="hourglass-start" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.startsOn")} adjustWidth />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Input disabled={scanmetrix.checkPermission("ContractProvisionInterval") < 3} required adjustWidth label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.name")} icon="tag" field="name" />
                                </GridElement>
                                <GridElement columnStart={3} columnEnd={4}>
                                    <Input disabled={scanmetrix.checkPermission("ContractProvisionInterval") < 3} optional float field="cost" icon="piggy-bank" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.netCosts")} adjustWidth format={val => val !== null ? formatter.format(val) : val} />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input disabled={scanmetrix.checkPermission("ContractProvisionInterval") < 3} required int field="intervalAmount" icon="tally" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.intervalQuantity")} adjustWidth />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={4}>
                                    <Select disabled={scanmetrix.checkPermission("ContractProvisionInterval") < 3} required field="intervalType" icon="calendar-week" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.intervalUnit")} adjustWidth items={[
                                        { key: "days", title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.intervalUnitTypes.days") },
                                        { key: "weeks", title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.intervalUnitTypes.weeks") },
                                        { key: "months", title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.intervalUnitTypes.months") },
                                        { key: "years", title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.intervalUnitTypes.years") },
                                    ]} />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={4}>
                                    <Checkbox disabled={scanmetrix.checkPermission("ContractProvisionInterval") < 3} field="disabled" label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.performanceCycle.deactivateCheckbox")} />
                                </GridElement>
                            </Grid>
                        </Input.MutationProvider>}
                    </Section.Item>
                    <Section.Item title={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.sectionElementTitle")} icon="calendar-lines-pen" amount={this.state.variations ? this.state.variations.length : 0}>
                        <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 32 }}>
                            <Select value={this.state.subsidiaryId} onChange={subsidiaryId => this.setState({ subsidiaryId })} label={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.branch")} icon="map-marker" items={this.state.subsidiaries.filter(subsidiary => !this.state.variations.find(v => v.subsidiaryContract.subsidiary.id === subsidiary.id)).map(subsidiary => ({ key: subsidiary.rawId, title: `${subsidiary.name}${subsidiary.label ? (" / " + subsidiary.label) : ""} / ${subsidiary.address.streetName} ${subsidiary.address.streetNumber}, ${subsidiary.address.postalCode} ${subsidiary.address.city}` }))} adjustWidth />
                            <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                                <Button loading={this.state.loading} title={i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.deviationsButton")} icon="calendar-lines-pen" thick disabled={!this.state.subsidiaryId || scanmetrix.checkPermission("ContractProvisionIntervalVariation") < 2} onClick={() => {
                                    this.setState({ loading: true })

                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($subsidiaryContractId: ID!, $contractProvisionIntervalId: ID!) {
                                                createContractProvisionIntervalVariation(subsidiaryContractId: $subsidiaryContractId, contractProvisionIntervalId: $contractProvisionIntervalId)
                                            }    
                                        `,
                                        variables: {
                                            subsidiaryContractId: this.state.subsidiaryId,
                                            contractProvisionIntervalId: this.state.provisionInterval.id
                                        }
                                    }).then(() => {
                                        this.fetch(this.state.provisionInterval).then(() => {
                                            this.setState({ subsidiaryId: null, loading: false })
                                        })
                                    })
                                }} />
                            </div>
                        </div>
                        <Table
                            head={[
                                { title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.table.branch"), width: "40%", column: "subsidiary" },
                                { title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.table.interval"), width: "20%", column: "interval" },
                                { title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.table.startingAt"), width: "15%", column: "startingAt" },
                                { title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.table.cost"), width: "15%", column: "cost" },
                                { title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.table.inactive"), width: "10%", column: "inactive" }
                            ]}
                            menuName="ContractProvisionIntervalVariations"
                            onItemClick={item => this.updateContractProvisionIntervalVariationModal.open(item.data)}
                            contextItems={scanmetrix.checkPermission("ContractProvisionIntervalVariation") === 4 ? [
                                {
                                    icon: "times",
                                    title: i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.deleteButton"),
                                    onClick: data => scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($id: ID!) {
                                                deleteContractProvisionIntervalVariation(id: $id)
                                            }
                                        `,
                                        variables: {
                                            id: data.id
                                        }
                                    }).then(() => this.fetch(this.state.provisionInterval))
                                }
                            ] : null}
                            noControls
                            items={this.state.variations ? this.state.variations.map(variation => ({
                                data: variation,
                                ...variation,
                                interval: variation.intervalAmount === null || variation.intervalType === null ? <><i className="fas fa-exclamation-circle orange" /> {i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.intervalInherited")}</> : `${variation.intervalAmount} ${getIntervalType(variation.intervalType)}`,
                                cost: variation.cost !== null ? formatter.format(variation.cost) : <><i className="fas fa-exclamation-circle orange" /> {i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.costInherited")}</>,
                                inactive: variation.inactive ? <><i className="fas fa-check-circle red" /> {i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.inactiveYes")}</> : <><i className="far fa-times-circle green" /> {i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.inactiveNo")}</>,
                                startingAt: variation.startingAt ? moment(variation.startingAt).format("DD.MM.YYYY") : <><i className="fas fa-exclamation-circle orange" /> {i18next.t("page.private.contract.modals.updateContractProvisionIntervalModal.deviations.startingAtInherited")}</>,
                                subsidiary: `${variation.subsidiaryContract.subsidiary.name}${variation.subsidiaryContract.subsidiary.label ? (" / " + variation.subsidiaryContract.subsidiary.label) : ""} / ${variation.subsidiaryContract.subsidiary.address.streetName} ${variation.subsidiaryContract.subsidiary.address.streetNumber}, ${variation.subsidiaryContract.subsidiary.address.postalCode} ${variation.subsidiaryContract.subsidiary.address.city}`
                            })) : null}
                        />
                    </Section.Item>
                </Section>
            </Modal.Body>
        </Modal>
    }
}
