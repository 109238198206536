import styled from "styled-components"

export default styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto auto auto auto auto auto 0.5fr;
  grid-gap: 32px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 48px;
  margin: 0 auto;
  max-width: 1440px;
  
  &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      transition: background 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.125);
    }
  }
  
  &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.025);
  }
    
  @media screen and (max-width: 1919px) {
    grid-template-rows: auto auto auto auto auto auto auto auto auto 0.5fr;
  }
`

