import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import {Checkbox} from "../../../../components";
import i18next from "i18next";

export default class extends Component {
    state = { measureOffer: null, title: null, type: null, loading: false, letterHeads: [], letterHeadId: null, ocr: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    LetterHeads {
                        nodes {
                            id
                            name
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ letterHeads: data.data.LetterHeads.nodes })
        })
        
    }
 
   
    
    render() 
    
    {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={measureOffer => this.setState({
            title: this.props.measure.title,
            letterHeadId: null,
            type: this.props.measure.type,
            measureOffer,
            loading: false
        })}>
            <Modal.Head title={i18next.t("page.private.measure.createPurchaseOrderModal.titleForm")} icon="comment-alt-check" />
            <Modal.Body padding={24} adjustHeight={290}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Select required adjustWidth value={this.state.type} noUnselect onChange={type => this.setState({ type })} icon="wrench" label={i18next.t("page.private.measure.createPurchaseOrderModal.type")} items={[
                        { key: "service", title: i18next.t("page.private.measure.createPurchaseOrderModal.typeTypes.service"), icon: "hard-hat" },
                        { key: "construction", title: i18next.t("page.private.measure.createPurchaseOrderModal.typeTypes.construction"), icon: "digging" },
                        { key: "goods", title: i18next.t("page.private.measure.createPurchaseOrderModal.typeTypes.goods"), icon: "truck-loading" },
                        { key: "other", title: i18next.t("page.private.measure.createPurchaseOrderModal.typeTypes.other"), icon: "question" },
                    ]} />
                    <Select noUnselect required adjustWidth value={this.state.letterHeadId} onChange={letterHeadId => this.setState({ letterHeadId })} icon="scroll" label={i18next.t("page.private.measure.createPurchaseOrderModal.letterHead")} items={this.state.letterHeads.map(letterHead => ({
                        key: letterHead.id,
                        title: letterHead.name
                    }))} />
                    <Input required value={this.state.title} onChange={title => this.setState({ title })} icon="font" label={i18next.t("page.private.measure.createPurchaseOrderModal.title")} adjustWidth />
                    <Checkbox label={i18next.t("page.private.measure.createPurchaseOrderModal.ocrCheckbox")} 
                              value={this.state.ocr}
                              onChange={ocr => this.setState({ ocr })}
                              
                              />
                </div>
            </Modal.Body>
            <Modal.Footer 
               buttons={instance => <Button 
                                       loading={this.state.loading} 
                                       thick disabled={!this.state.title || !this.state.letterHeadId || !this.state.type} 
                                       title={i18next.t("page.private.measure.createPurchaseOrderModal.exitButton")} 
                                       icon="comment-alt-check" 
                                       onClick={() => {this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($title: String!, $type: String!, $ocr: Boolean, $subsidiaryId: ID!, $serviceProviderId: ID, $measureOfferId: ID, $letterHeadId: ID) {
                            createPurchaseOrder(title: $title, type: $type, ocr: $ocr, subsidiaryId: $subsidiaryId, serviceProviderId: $serviceProviderId, measureOfferId: $measureOfferId, letterHeadId: $letterHeadId)
                        }
                    `,
                    variables: {
                        title: this.state.title,
                        type: this.state.type,
                        subsidiaryId: this.props.measure.subsidiary.id,
                        serviceProviderId: this.state.measureOffer.raw.serviceProvider ? this.state.measureOffer.raw.serviceProvider.id : this.state.measureOffer.request.serviceProvider.id,
                        measureOfferId: this.state.measureOffer.id,
                        letterHeadId: this.state.letterHeadId,
                        ocr: this.state.ocr
                        
                    }
                }).then(result => {
                    if(result.data.createPurchaseOrder) {
                        this.props.refresh().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                })}
            } />} />
        </Modal>
    }
}
