import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"
import { CirclePicker } from "react-color"
import styled from "styled-components"
import i18next from "i18next";

const CircleDesign = styled.div`
    transform: translateX(7px);
`

export default class extends Component {
    state = { name: null, color: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={() => this.setState({ name: null, color: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.createSubsidiaryTypeModal.title")} icon="map-marker-edit" />
            <Modal.Body padding={24}>
                <div style={{ padding: "16px", border: `3px dashed ${this.state.color || "#cccccc"}`, display: "grid", gridGap: "32px", gridTemplateColumns: "1fr", width: "380px" }}>
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.settings.modals.createSubsidiaryTypeModal.name")} adjustWidth />
                    <CircleDesign>
                        <CirclePicker onChange={color => this.setState({ color: color.hex })} width="100%" />
                    </CircleDesign>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.color} title={i18next.t("page.private.settings.modals.createSubsidiaryTypeModal.exitButton")} icon="map-marker-edit" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $color: String!) {
                            createSubsidiaryType(name: $name, color: $color)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        color: this.state.color
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createSubsidiaryType) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
