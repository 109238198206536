import React, {Component} from "react"
import { GridElement, Table, Button } from "scanmetrix-components"
import i18next from "i18next"
import CreateTicketEscalationStepModal from "./modals/CreateTicketEscalationStepModal";
import UpdateTicketEscalationStepModal from "./modals/UpdateTicketEscalationStepModal";
import DeleteTicketEscalationStepModal from "./modals/DeleteTicketEscalationStepModal";

export default class extends Component {
    render() {
        return <>
            <CreateTicketEscalationStepModal instance={ref => this.createTicketEscalationStepModal = ref} refresh={() => this.props.fetch()} />
            <UpdateTicketEscalationStepModal instance={ref => this.updateTicketEscalationStepModal = ref} refresh={() => this.props.fetch()} />
            <DeleteTicketEscalationStepModal instance={ref => this.deleteTicketEscalationStepModal = ref} refresh={() => this.props.fetch()} />
            <GridElement styled title={i18next.t("page.private.settings.TicketEscalationSteps.gridElementTitle")} icon="message-exclamation" rightContent={<Button thick title={i18next.t("page.private.settings.TicketEscalationSteps.button")} icon="message-exclamation" onClick={() => this.createTicketEscalationStepModal.open()} />}>
                <Table
                    head={[
                        { title: i18next.t("page.private.settings.TicketEscalationSteps.table.description"), width: "40%", column: "description" },
                        { title: i18next.t("page.private.settings.TicketEscalationSteps.table.duration"), width: "15%", column: "duration" },
                        { title: i18next.t("page.private.settings.TicketEscalationSteps.table.statuses"), width: "10%", column: "statuses" },
                        { title: i18next.t("page.private.settings.TicketEscalationSteps.table.types"), width: "10%", column: "types" },
                        { title: i18next.t("page.private.settings.TicketEscalationSteps.table.priorities"), width: "10%", column: "priorities" },
                        { title: i18next.t("page.private.settings.TicketEscalationSteps.table.color"), width: "15%", column: "color" }
                    ]}
                    onItemClick={ticketEscalationStep => this.updateTicketEscalationStepModal.open(ticketEscalationStep)}
                    contextItems={[
                        {
                            icon: "trash",
                            title: i18next.t("page.private.settings.TicketEscalationSteps.deleteButton"),
                            onClick: data => {
                                this.deleteTicketEscalationStepModal.open(data.id)
                            }
                        }
                    ]}
                    items={this.props.data ? this.props.data.map(ticketEscalationStep => {
                        return ({
                            raw: ticketEscalationStep,
                            id: ticketEscalationStep.id,
                            additionalEmails: ticketEscalationStep.additionalEmails,
                            duration: `${ticketEscalationStep.durationAmount} ${i18next.t("page.private.settings.TicketEscalationSteps.table.durationTypes." + ticketEscalationStep.durationType)}`,
                            description: ticketEscalationStep.description,
                            rawColor: ticketEscalationStep.color,
                            types: ticketEscalationStep.types.length,
                            statuses: ticketEscalationStep.statuses.length,
                            priorities: ticketEscalationStep.priorities.length,
                            color: <div style={{ float: "right", width: "fit-content", backgroundColor: ticketEscalationStep.color, color: "white", padding: 6, borderRadius: 3, boxShadow: `0 3px 4px -3px ${ticketEscalationStep.color}` }}>{ticketEscalationStep.color}</div>
                        })
                    }) : null}
                />
            </GridElement>
        </>
    }
}
