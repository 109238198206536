import React, { Component } from "react"
import { Page, Section, Breadcrumbs, GridElement, Grid, Input, Table, Button } from "scanmetrix-components"
import styled from "styled-components"

import { contractTypes } from "../dashboard/propertyManagement";
import UpdateServiceProviderModal from "./modals/UpdateServiceProviderModal"
import CreateContactModal from "./modals/CreateContactModal"
import UpdateContactModal from "./modals/UpdateContactModal"

import i18next from "i18next"

import DeleteServiceProviderModal from "./modals/DeleteServiceProviderModal"
import InviteServiceProviderModal from "./modals/InviteServiceProviderModal"
import ConnectServiceProviderModal from "./modals/ConnectServiceProviderModal"

const ChartContainer = styled.div`
  background: white;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 8px 24px -16px rgba(0,0,0,0.4);
  margin-bottom: 48px;
`

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

const Status = styled.div`
  position: absolute;
  left: -16px;
  width: 32px;
  height: 32px;
  background: #16a085;
  border-radius: 32px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 16px);
  font-size: 0.9em;
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);
  
  i {
      margin: 0 !important;
  }
`


const getStatus = status => {
    switch(status) {
        case "draft":
            return {title: i18next.t("page.private.workOrders.switchCases.draft"), icon: "drafting-compass", color: "#3b97d3"}
        case "approval":
            return {title: i18next.t("page.private.workOrders.switchCases.internalRelease"), icon: "pencil-ruler", color: "#E67E22"}
        case "commission":
            return {title: i18next.t("page.private.workOrders.switchCases.commissioningPending"), icon: "paper-plane", color: "#1abc9c"}
        case "confirmation":
            return {title: i18next.t("page.private.workOrders.switchCases.commissionedWaitingAB"), icon: "check", color: "#34495e"}
        case "execution":
            return {title: i18next.t("page.private.workOrders.switchCases.commissionedWaitingCompletion"), icon: "user-check", color: "#34495e"}
        case "done":
            return {title: i18next.t("page.private.workOrders.switchCases.completed"), icon: "check-double", color: "#2ECC71"}
    }
}
const getType = type => {
    switch(type) {
        case "construction":
            return {title: i18next.t("page.private.workOrders.switchCases.constructionProject"), icon: "digging", color: "#e67e22"}
        case "goods":
            return {title: i18next.t("page.private.workOrders.switchCases.purchaseGoods"), icon: "truck-loading", color: "#1abc9c"}
        case "service":
            return {title: i18next.t("page.private.workOrders.switchCases.service"), icon: "hard-hat", color: "#3498db"}
        case "other":
            return {title: i18next.t("page.private.workOrders.switchCases.other"), icon: "question", color: "#34495e"}
    }
}

export default class extends Component {
    render() {
        return <Page {...this.props}>
            <ServiceProviderPage {...this.props} />
        </Page>
    }
}

class ServiceProviderPage extends Component {
    state = {
        serviceProvider: null,
        contacts: [],
        subsidiaries: [],
        invoices: [],
        dataInvoices: [],
        syncs: null
    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetchContacts = this.fetchContacts.bind(this)
        this.fetchArchive = this.fetchArchive.bind(this)
        this.fetchOrders = this.fetchOrders.bind(this)


        this.fetch()
        this.fetchContacts()
        this.fetchArchive()
        this.fetchOrders()
    }

    fetchContacts() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($serviceProviderId: ID!) {
                    ServiceProviderContacts(filter: { serviceProviderId_eq: $serviceProviderId }) {
                        nodes {
                            id
                            email
                            telephone
                            responsibility
                            salutation
                            firstName
                            lastName
                        }
                    }
                }
            `,
            variables: {
                serviceProviderId: this.props.match.params.serviceproviderid
            }
        }).then(data => {
            this.setState({ contacts: data.data.ServiceProviderContacts.nodes })
        })
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    ServiceProvider(id: $id) {
                        id
                        globalId
                        responsibility
                        name
                        contactEmail
                        contactTelephone
                        contactTelefax
                        contracts {
                            id
                            beginsAt
                            endsAt
                            title
                            type
                            number
                            perpetual
                            automaticRenewal
                            partnerName
                            contractDurationAmount
                            contractDurationType
                            workSection {
                                id
                                name
                                icon
                                stlbId
                            }
                        }
                        syncs {
                            id
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    postalCode
                                    city
                                }
                                createdAt
                            }
                        }
                        address {
                            streetName
                            streetNumber
                            additional
                            postalCode
                            city
                            country
                            inline
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.serviceproviderid
            }
        }).then(data => {
            this.setState({ serviceProvider: data.data.ServiceProvider, syncs: data.data.ServiceProvider.syncs, contracts: data.data.ServiceProvider.contracts })
        })
    }
    fetchArchive() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($status: String!) {
                    AccountingInvoices(filter: { status_eq: $status }) {
                        nodes {
                            id
                            number
                            date
                            net
                            gross
                            notes
                            status
                            declined
                            accountingInvoiceDocument {
                                id
                                name
                            }
                            serviceProvider {
                                id
                                name
                            }
                            purchaseOrder {
                                id
                                number
                                date
                                positions
                            }
                            subsidiary {
                                id
                                name
                                label
                            }
                        }
                    }
                }
            `,
            variables: {
                status: "archived"
            }
        }).then(data => {
            this.setState({ accountingInvoices: data.data.AccountingInvoices.nodes })
        })
    }

    fetchOrders() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    PurchaseOrders(filter: { serviceProviderId_eq: $id }) {
                        nodes {
                            id
                            title
                            number
                            status
                            date
                            type
                            positions
                            letterHead {
                                id
                                name
                            }
                            subsidiary {
                                id
                                name
                                label
                                locationLat
                                locationLng
                                address {
                                    postalCode
                                    city
                                }
                            }
                            serviceProvider {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.serviceproviderid
            }
        }).then(data => {
            this.setState({
                purchaseOrders: data.data.PurchaseOrders.nodes
            })
        })
    }

    render() {
        if(!this.state.serviceProvider) return null
        const serviceProvider = this.state.serviceProvider

        return <>
            <CreateContactModal serviceProvider={serviceProvider} instance={ref => this.createContactModal = ref} refresh={() => this.fetchContacts()} />
            <DeleteServiceProviderModal history={this.props.history} instance={ref => this.deleteServiceProviderModal = ref} />
            <UpdateContactModal instance={ref => this.updateContactModal = ref} refresh={() => this.fetchContacts()} />
            <UpdateServiceProviderModal instance={ref => this.updateServiceProviderModal = ref} refresh={() => this.fetch()} />
            <InviteServiceProviderModal serviceProviderId={serviceProvider.id} email={serviceProvider.contactEmail} instance={ref => this.inviteServiceProviderModal = ref} />
            <ConnectServiceProviderModal serviceProviderId={serviceProvider.id} instance={ref => this.connectServiceProviderModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "user-tie",
                    title: i18next.t("page.private.serviceProvider.breadCrumbsTitle"),
                    link: "/serviceproviders"
                },
                {
                    title: serviceProvider.name
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.serviceProvider.generalInformation.sectionTitle")} icon="id-card">
                    <GridElement styled title={i18next.t("page.private.serviceProvider.generalInformation.gridElementTitle")} icon="id-card" rightContent={<div style={{ display: "grid", gridTemplateColumns: "max-content max-content", gridGap: "16px" }}>
                        <Button disabled={scanmetrix.checkPermission("ServiceProvider") < 3} thick icon="pencil" title={i18next.t("page.private.serviceProvider.generalInformation.updateButton")} onClick={() => this.updateServiceProviderModal.open(serviceProvider)} />
                        <Button disabled={scanmetrix.checkPermission("ServiceProvider") < 4} thick secondary icon="trash" title={i18next.t("page.private.serviceProvider.generalInformation.deleteButton")} onClick={() => this.deleteServiceProviderModal.open(serviceProvider)} />
                    </div>}>
                        <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                            <GridElement columnStart={1} columnEnd={4}>
                                <Input adjustWidth label={i18next.t("page.private.serviceProvider.generalInformation.table.name")} icon="font" value={serviceProvider.name} readOnly />
                            </GridElement>
                            <GridElement columnStart={4} columnEnd={7}>
                                <Input adjustWidth label={i18next.t("page.private.serviceProvider.generalInformation.table.responsibility")} icon="gavel" value={serviceProvider.responsibility} readOnly />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={7}>
                                <Input adjustWidth label={i18next.t("page.private.serviceProvider.generalInformation.table.address")} icon="map-marked" value={serviceProvider.address.inline} readOnly />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={3}>
                                <Input adjustWidth label={i18next.t("page.private.serviceProvider.generalInformation.table.email")} icon="at" value={serviceProvider.contactEmail} readOnly />
                            </GridElement>
                            <GridElement columnStart={3} columnEnd={5}>
                                <Input adjustWidth label={i18next.t("page.private.serviceProvider.generalInformation.table.telephoneNumber")} icon="phone" value={serviceProvider.contactTelephone} readOnly />
                            </GridElement>
                            <GridElement columnStart={5} columnEnd={7}>
                                <Input adjustWidth label={i18next.t("page.private.serviceProvider.generalInformation.table.fax")} icon="fax" value={serviceProvider.contactTelefax} readOnly />
                            </GridElement>

                        </Grid>
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.serviceProvider.contactPerson.sectionTitle")} icon="address-book" amount={this.state.contacts.length}>
                    <GridElement styled title={i18next.t("page.private.serviceProvider.contactPerson.gridElementTitle")} icon="address-book" rightContent={<Button disabled={scanmetrix.checkPermission("ServiceProviderContact") < 2} thick title={i18next.t("page.private.serviceProvider.contactPerson.button")} icon="address-book" onClick={() => this.createContactModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.serviceProvider.contactPerson.table.salutation"), width: "10%", column: "salutationFormatted" },
                                { title: i18next.t("page.private.serviceProvider.contactPerson.table.name"), width: "20%", column: "name" },
                                { title: i18next.t("page.private.serviceProvider.contactPerson.table.email"), width: "20%", column: "email" },
                                { title: i18next.t("page.private.serviceProvider.contactPerson.table.telephone"), width: "20%", column: "telephone" },
                                { title: i18next.t("page.private.serviceProvider.contactPerson.table.responsibility"), width: "30%", column: "responsibility" }
                            ]}
                            onItemClick={contact => scanmetrix.checkPermission("ServiceProviderContact") > 2 ? this.updateContactModal.open(contact) : {}}
                            items={this.state.contacts.map(contact => ({
                                id: contact.id,
                                firstName: contact.firstName,
                                lastName: contact.lastName,
                                name: `${contact.firstName ? (contact.firstName + " ") : ""}${contact.lastName}`,
                                salutation: contact.salutation,
                                salutationFormatted: i18next.t(`defaults.salutation.${contact.salutation}`),
                                email: contact.email,
                                telephone: contact.telephone,
                                responsibility: contact.responsibility
                            }))}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.serviceProvider.Synchronisation.sectionTitle")} icon="sync" amount={this.state.serviceProvider.syncs ? this.state.serviceProvider.syncs.length : 0}>
                    <GridElement styled title={i18next.t("page.private.serviceProvider.Synchronisation.gridElementTitle")} icon="sync" rightContent={<div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: serviceProvider.globalId ? "auto auto" : "auto" }}>
                        {serviceProvider.globalId && <p style={{ display: "flex", alignItems: "center", height: "100%" }}><i className="far fa-check-circle" style={{ color: "#1abc9c", marginRight: 8 }} /> {i18next.t("page.private.serviceProvider.Synchronisation.text1")}&nbsp;<b style={{ color: "#3b97d3" }}>{i18next.t("page.private.serviceProvider.Synchronisation.text2")}</b>{i18next.t("page.private.serviceProvider.Synchronisation.text3")}</p>}
                        {/*<Button disabled secondary title="Dienstleister für CFDS einladen" icon="paper-plane" thick onClick={() => this.inviteServiceProviderModal.open()} />*/}
                        <Button disabled={!!serviceProvider.globalId || scanmetrix.checkPermission("ConnectServiceProvider") < 3} title={i18next.t("page.private.serviceProvider.Synchronisation.button")} icon="code" thick onClick={() => this.connectServiceProviderModal.open()} />
                    </div>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.serviceProvider.Synchronisation.table.condition"), width: "15%", column: "status" },
                                { title: i18next.t("page.private.serviceProvider.Synchronisation.table.branch"), width: "45%", column: "subsidiary" },
                                { title: i18next.t("page.private.serviceProvider.Synchronisation.table.place"), width: "25%", column: "location" },
                                { title: i18next.t("page.private.serviceProvider.Synchronisation.table.date"), width: "15%", column: "date" }
                            ]}
                            items={this.state.serviceProvider.syncs ? this.state.serviceProvider.syncs.map(sync => ({
                                id: sync.id,
                                status: <div style={{ paddingLeft: "16px" }}><Status><i className="far fa-check" /></Status>Erfolgreich</div>,
                                subsidiary: `${sync.subsidiary.name}${sync.subsidiary.label ? (" (" + sync.subsidiary.label + ")") : ""}`,
                                location: `${sync.subsidiary.address.postalCode} ${sync.subsidiary.address.city}`,
                                date: moment(sync.createdAt).format("DD.MM.YYYY HH:mm")
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.serviceProvider.contracts.sectionTitle")} icon="file-contract" amount={this.state.contracts ? this.state.contracts.length : 0}>
                    <GridElement styled title={i18next.t("page.private.serviceProvider.contracts.gridElementTitle")} icon="file-contract">
                        <Table
                            head={[
                                { title: i18next.t("page.private.serviceProvider.contracts.table.title"), width: "15%", column: "title" },
                                { title: i18next.t("page.private.serviceProvider.contracts.table.type"), width: "12.5%", column: "type" },
                                { title: i18next.t("page.private.serviceProvider.contracts.table.workSection"), width: "10%", column: "workSection" },
                                { title: i18next.t("page.private.serviceProvider.contracts.table.number"), width: "10%", column: "number" },
                                { title: i18next.t("page.private.serviceProvider.contracts.table.partnerName"), width: "15%", column: "partnerName" },
                                { title: i18next.t("page.private.serviceProvider.contracts.table.duration"), width: "7.5%", column: "duration" },
                                { title: i18next.t("page.private.serviceProvider.contracts.table.beginsAt"), width: "10%", column: "beginsAt" },
                                { title: i18next.t("page.private.serviceProvider.contracts.table.endsAt"), width: "10%", column: "endsAt" },
                                { title: i18next.t("page.private.serviceProvider.contracts.table.automaticRenewal"), width: "10%", column: "automaticRenewal" }
                            ]}
                            onItemClick={contract => this.props.history.push(`/contract/${contract.id}`)}
                            items={this.state.contracts ? this.state.contracts.map(contract => ({
                                ...contract,
                                workSection: contract.workSection ? `${contract.workSection.stlbId ? (contract.workSection.stlbId + " / ") : ""}${contract.workSection.name}` : "",
                                type: contract.type ? contractTypes.find(type => type.key === contract.type).title : "",
                                duration: contract.perpetual ? i18next.t("page.private.serviceProvider.contracts.durationUnlimited") : `${contract.contractDurationAmount} ${i18next.t("defaults.durationType." + contract.contractDurationType)}`,
                                beginsAt: contract.beginsAt ? moment(contract.beginsAt).format("DD.MM.YYYY") : i18next.t("page.private.serviceProvider.contracts.beginsAtUnavailabe"),
                                endsAt: contract.endsAt ? moment(contract.endsAt).format("DD.MM.YYYY") : ((contract.contractDurationAmount && contract.contractDurationType && !contract.perpetual && contract.contractDurationAmount && contract.contractDurationType && contract.beginsAt) ? moment(contract.beginsAt).add(contract.contractDurationAmount, contract.contractDurationType).format("DD.MM.YYYY") : (contract.perpetual ? i18next.t("page.private.serviceProvider.contracts.endsAtUnlimited") : i18next.t("page.private.serviceProvider.contracts.endsAtUnavailable"))),
                                automaticRenewal: contract.automaticRenewal ? <><i className="far fa-check-circle" />{i18next.t("page.private.serviceProvider.contracts.yes")}</> : <><i className="far fa-times-circle" />{i18next.t("page.private.serviceProvider.contracts.no")}</>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.workOrders.gridElementTitle")} icon="comment-alt-check" amount={this.state.purchaseOrders ? this.state.purchaseOrders.length : 0}>
                    <GridElement styled title={i18next.t("page.private.workOrders.gridElementTitle")} icon="comment-alt-check">
                    <Table
                    head={[
                        { title: i18next.t("page.private.workOrders.table.date"), width: "7.5%", column: "date" },
                        { title: i18next.t("page.private.workOrders.table.status"), width: "20%", column: "status" },
                        { title: i18next.t("page.private.workOrders.table.number"), width: "7.5%", column: "number" },
                        { title: i18next.t("page.private.workOrders.table.letterHead"), width: "10%", column: "letterHead" },
                        { title: i18next.t("page.private.workOrders.table.type"), width: "12.5%", column: "type" },
                        { title: i18next.t("page.private.workOrders.table.branch"), width: "15%", column: "subsidiary" },
                        { title: i18next.t("page.private.workOrders.table.title"), width: "20%", column: "title" },
                        { title: i18next.t("page.private.workOrders.table.sum"), width: "7.5%", column: "sum" },
                    ]}
                    onItemClick={purchaseOrder => window.open(`/purchaseorder/${purchaseOrder.id}`, '_blank')}
                    items={this.state.purchaseOrders ? this.state.purchaseOrders.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(purchaseOrder => {
                        return ({
                            ...purchaseOrder,
                            status: <><i className={`fa-duotone fa-${getStatus(purchaseOrder.status).icon}`} style={{ color: getStatus(purchaseOrder.status).color, marginRight: 8 }} /> {getStatus(purchaseOrder.status).title}</>,
                            type: <><i className={`fa-duotone fa-${getType(purchaseOrder.type).icon}`} style={{ color: getType(purchaseOrder.type).color, marginRight: 8 }} /> {getType(purchaseOrder.type).title}</>,
                            letterHead: purchaseOrder.letterHead ? purchaseOrder.letterHead.name : null,
                            serviceProvider: purchaseOrder.serviceProvider.name,
                            date: moment(purchaseOrder.date).format("DD.MM.YYYY"),
                            subsidiary: purchaseOrder.subsidiary.name + (purchaseOrder.subsidiary.label ? (" (" + purchaseOrder.subsidiary.label + ")") : ""),
                            sum: formatter.format(((typeof purchaseOrder.positions === "string" ? JSON.parse(purchaseOrder.positions) : purchaseOrder.positions) || []).map(item => item.amount * item.price).reduce((a, b) => a + b, 0))
                        })
                    }) : null}
                    contextItems={[
                        //{ title: i18next.t("page.private.contracts.contractsTable.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: contract => window.open(`/contract/${contract.id}`, '_blank')},
                        { title: "Duplizieren", icon: "fa-duotone fa-copy", onClick: purchaseOrder => this.showDuplicateOrderModal.open(purchaseOrder) },
                    ]}
                />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("navigation.sideNavigation.fiCo.accounting")} icon="coins" amount={this.state.accountingInvoices ? this.state.accountingInvoices.length : 0}>
                <GridElement styled icon="box-archive" title={i18next.t("page.private.accounting.unbooked.sectionTitle")}>
                <Table
                    head={[
                        { title: i18next.t("page.private.accounting.archive.table.number"), width: "12.5%", column: "number" },
                        { title: i18next.t("page.private.accounting.archive.table.date"), width: "10%", column: "date" },
                        //{ title: i18next.t("page.private.accounting.archive.table.serviceProvider"), width: "15%", column: "serviceProvider" },
                        { title: i18next.t("page.private.accounting.archive.table.net"), width: "10%", column: "net" },
                        { title: i18next.t("page.private.accounting.archive.table.gross"), width: "10%", column: "gross" },
                        { title: i18next.t("page.private.accounting.archive.table.branch"), width: "17.5%", column: "subsidiary" },
                        { title: i18next.t("page.private.accounting.archive.table.purchaseOrder"), width: "12.5%", column: "purchaseOrder" },
                        { title: i18next.t("page.private.accounting.archive.table.contract"), width: "12.5%", column: "contract" }
                    ]}
                    onItemClick={item => window.open(scanmetrix.backendURL + "/accounting-invoice-document/" + item.data.accountingInvoiceDocument.id, "_blank")}

                    items={this.state.accountingInvoices ? this.state.accountingInvoices.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(accountingInvoice => ({
                        ...accountingInvoice,
                        data: accountingInvoice,
                        net: formatter.format(accountingInvoice.net),
                        gross: formatter.format(accountingInvoice.gross),
                        date: moment(accountingInvoice.date).format("DD.MM.YYYY"),
                        serviceProvider: accountingInvoice.serviceProvider.name,
                        subsidiary: accountingInvoice.subsidiary ? (accountingInvoice.subsidiary.name + (accountingInvoice.subsidiary.label ? (" (" + accountingInvoice.subsidiary.label + ")") : "")) : null,
                        purchaseOrder: accountingInvoice.purchaseOrder ? `${accountingInvoice.purchaseOrder.number} / ${moment(accountingInvoice.purchaseOrder.date).format("DD.MM.YYYY")}` : null,
                        contract: accountingInvoice.contract ? `${accountingInvoice.contract.title} ${accountingInvoice.contract.number ? (" (" + accountingInvoice.contract.number + ")") : ""}` : null,
                        number: <div style={{ paddingLeft: "16px" }}><Status status={accountingInvoice.declined ? false : true}><i className={`far fa-${accountingInvoice.declined ? "times" : "check"}`} /></Status>{accountingInvoice.number}</div>,
                    })) : null}
                />
            </GridElement>
                </Section.Item>


                {/*<Section.Item title="Niederlassungen" icon="map-marker">
                    <GridElement styled title="Niederlassungen" icon="map-marker">
                        <Table
                            head={[
                                { title: "Name", width: "20%", column: "name" },
                                { title: "Rechtliche Bezeichnung", width: "30%", column: "legalName" },
                                { title: "Straße", width: "25%", column: "street" },
                                { title: "Postleitzahl", width: "5%", column: "postalCode" },
                                { title: "Stadt", width: "20%", column: "city" }
                            ]}
                            items={this.state.subsidiaries.map(subsidiary => {
                                return ({
                                    id: subsidiary.id,
                                    name: subsidiary.name,
                                    legalName: subsidiary.legalName,
                                    street: `${subsidiary.address.streetName} ${subsidiary.address.streetNumber}`,
                                    postalCode: subsidiary.address.postalCode,
                                    city: subsidiary.address.city
                                })
                            })}
                        />
                    </GridElement>
                </Section.Item>*/}
                {/*<Section.Item title="Faktura" icon="coins">

                    <Grid gap="32px" columns={[ "1fr" ]}>
                        <ChartContainer>
                            <Chart options={{
                                chart: {
                                    height: 350,
                                    width: "100%",
                                    type: 'bar',
                                    toolbar: {
                                        show: false
                                    }
                                },
                                colors: [ "#3b97d3" ],
                                plotOptions: {
                                    bar: {
                                        dataLabels: {
                                            position: 'top', // top, center, bottom
                                        }
                                    }
                                },
                                dataLabels: {
                                    enabled: true,
                                    formatter: formatter.format,
                                    offsetY: -20,
                                    style: {
                                        fontSize: '12px',
                                        colors: ["#304758"]
                                    }
                                },

                                xaxis: {
                                    categories: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
                                    position: 'top',
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false
                                    },
                                    crosshairs: {
                                        fill: {
                                            type: 'gradient',
                                            gradient: {
                                                colorFrom: '#3b97d3',
                                                colorTo: '#3b97d3',
                                                stops: [0, 100],
                                                opacityFrom: 0.75,
                                                opacityTo: 0,
                                            }
                                        }
                                    },
                                    tooltip: {
                                        enabled: true,
                                    }
                                },
                                yaxis: {
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false,
                                    },
                                    labels: {
                                        show: false,
                                        formatter: formatter.format
                                    }

                                },
                                title: {
                                    text: 'Monatliche Kosten',
                                    floating: true,
                                    offsetY: 330,
                                    align: 'center',
                                    style: {
                                        color: '#444'
                                    }
                                }
                            }} series={[{
                                name: 'Kosten',
                                data: this.state.dataInvoices
                            }]} type="bar" height={350} />
                        </ChartContainer>
                        <GridElement styled title="Erhaltene Rechnungen" icon="money-bill-wave">
                            <Table
                                head={[
                                    { title: "Datum", width: "10%", column: "date" },
                                    { title: "Rechnungsnummer", width: "17.5%", column: "number" },
                                    { title: "Dienstleister", width: "12.5%", column: "serviceProvider" },
                                    { title: "Gewerk", width: "15%", column: "workSection" },
                                    { title: "Betreff", width: "20%", column: "topic" },
                                    { title: "Niederlassung", width: "15%", column: "subsidiary" },
                                    { title: "Nettobetrag", width: "10%", column: "cost", format: formatter.format }
                                ]}
                                onItemClick={data => {
                                    window.open(scanmetrix.backendURL + "/invoice/" + data.subsidiaryId + "/" + data.id, "_blank")
                                }}
                                items={this.state.invoices.filter(invoice => invoice.type === "offer").sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(invoice => ({
                                    id: invoice.id,
                                    number: invoice.number,
                                    serviceProvider: "scanmetrix.fm GmbH",
                                    workSection: "Brandschutztechnik",
                                    topic: invoice.subject,
                                    cost: invoice.total,
                                    date: moment(invoice.date).format("DD.MM.YYYY"),
                                    subsidiary: `${invoice.customerSubsidiary.name} (${invoice.customerSubsidiary.label})`,
                                    subsidiaryId: invoice.customerSubsidiary.id
                                }))}
                            />
                        </GridElement>
                    </Grid>
                </Section.Item>*/}
                {/*<Section.Item disabled title="Konversationen" icon="comment-lines">
                    <StyledSort>
                        Sortieren nach <b>Aktuellste</b><i className="far fa-arrow-down" />
                    </StyledSort>
                    <Post topic="Wie wir das Facility Management digitalisieren können" author={{
                        name: "Jack Hull",
                        title: "Software Entwickler",
                        avatarURL: "https://www.xing.com/image/3_3_7_72f408598_26331075_3/jack-hull-foto.1024x1024.jpg"
                    }}
                          comments={[
                              {
                                  author: {
                                      name: "Volker Schreiber",
                                      title: "Gesellschafter-Geschäftsführer",
                                      avatarURL: "https://merics.org/sites/default/files/styles/ct_team_member_default/public/2020-04/avatar-placeholder.png?itok=Vhm0RCa3"
                                  },
                                  date: "14 Stunden",
                                  content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore."
                              },
                              {
                                  author: {
                                      name: "Kerem Kuzu",
                                      title: "Software-Entwickler",
                                      avatarURL: "https://merics.org/sites/default/files/styles/ct_team_member_default/public/2020-04/avatar-placeholder.png?itok=Vhm0RCa3"
                                  },
                                  date: "11 Stunden",
                                  content: "@Volker_Schreiber Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est."
                              },
                              {
                                  author: {
                                      name: "Jack Hull",
                                      title: "Geschäftsleitung",
                                      avatarURL: "https://www.xing.com/image/3_3_7_72f408598_26331075_3/jack-hull-foto.1024x1024.jpg"
                                  },
                                  date: "4 Stunden",
                                  content: "@Kerem_Kuzu Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est."
                              },
                              {
                                  author: {
                                      name: "Volker Schreiber",
                                      title: "Gesellschafter-Geschäftsführer",
                                      avatarURL: "https://merics.org/sites/default/files/styles/ct_team_member_default/public/2020-04/avatar-placeholder.png?itok=Vhm0RCa3"
                                  },
                                  date: "14 Stunden",
                                  content: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore."
                              },
                              {
                                  author: {
                                      name: "Kerem Kuzu",
                                      title: "Software-Entwickler",
                                      avatarURL: "https://merics.org/sites/default/files/styles/ct_team_member_default/public/2020-04/avatar-placeholder.png?itok=Vhm0RCa3"
                                  },
                                  date: "11 Stunden",
                                  content: "@Volker_Schreiber Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est."
                              },
                              {
                                  author: {
                                      name: "Jack Hull",
                                      title: "Geschäftsleitung",
                                      avatarURL: "https://www.xing.com/image/3_3_7_72f408598_26331075_3/jack-hull-foto.1024x1024.jpg"
                                  },
                                  date: "4 Stunden",
                                  content: "@Kerem_Kuzu Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est."
                              }
                          ]}
                          date="16 Stunden"
                          content="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam @SM-1-6324 nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est." />
                </Section.Item>*/}
            </Section>
        </>
    }
}
