import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"
import { v4 as uuid } from "uuid"
import i18next from "i18next";

export default class extends Component {
    state = { name: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ name: null, loading: false })

            this.file = null
        }}>
            <Modal.Head title="Neue Verzweigung anlegen" icon="project-diagram" />
            <Modal.Body padding={24}>
                <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label="Name" adjustWidth />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name} title="Node anlegen" icon="project-diagram" onClick={() => {
                const id = uuid()

                this.props.addNode({
                    id,
                    data: {
                        id,
                        label: this.state.name,
                        outcomes: []
                    },
                    type: "branch",
                    position: { x: 0, y: 0 }
                })

                instance.close()
            }} />} />
        </Modal>
    }
}
