import React, { Component } from "react"
import { Modal, Button, Input } from "scanmetrix-components"

import styled from "styled-components"
import i18next from "i18next";

const Template = styled.div`
    box-sizing: border-box;
    padding: 16px;
    background: rgba(0, 0, 0, 0.075);
    border-radius: 5px;
    margin-top: 16px;
    width: 100%;
`

export default class extends Component {
    state = { canceledAt: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ canceledAt: moment().format("DD.MM.YYYY"), loading: false })}>
            <Modal.Head title={this.props.contract.canceledAt ? i18next.t("page.private.contract.modals.cancelContractModal.revokeTerminationTitle") : i18next.t("page.private.contract.modals.cancelContractModal.cancelContractTitle")} icon="ban" />
            <Modal.Body padding={24}>
                {!this.props.contract.canceledAt && <Input date value={this.state.canceledAt} onChange={canceledAt => this.setState({ canceledAt })} adjustWidth icon="calendar-day" label={i18next.t("page.private.contract.modals.cancelContractModal.terminationDate")} />}
                {this.props.contract.canceledAt && <Template style={{ marginTop: 0 }}>
                    <i>{i18next.t("page.private.contract.modals.cancelContractModal.text1")}</i><br /><br /><br />
                    {this.props.contract.partnerName}<br />
                    {this.props.contract.address.streetName} {this.props.contract.address.streetNumber}<br />
                    {this.props.contract.address.additional}{this.props.contract.address.additional && <br />}
                    {this.props.contract.address.postalCode} {this.props.contract.address.city}<br /><br /><br />
                    {i18next.t("page.private.contract.modals.cancelContractModal.text2")}<br /><br />
                    {i18next.t("page.private.contract.modals.cancelContractModal.text3")} {moment(this.props.contract.canceledAt).format("DD.MM.YYYY")} {i18next.t("page.private.contract.modals.cancelContractModal.text4")} {moment(this.props.contract.contractDate).format("DD.MM.YYYY")}{this.props.contract.number ? ` mit der Vertragsnr. ${this.props.contract.number}` : ""}.<br /><br />
                    {i18next.t("page.private.contract.modals.cancelContractModal.text5")}<br /><br /><br />
                    {i18next.t("page.private.contract.modals.cancelContractModal.text6")}
                </Template>}
                {!this.props.contract.canceledAt && <Template>
                    <i>{i18next.t("page.private.contract.modals.cancelContractModal.text7")}</i><br /><br /><br />
                    {this.props.contract.partnerName}<br />
                    {this.props.contract.address.streetName} {this.props.contract.address.streetNumber}<br />
                    {this.props.contract.address.addditional}{this.props.contract.address.additional && <br />}
                    {this.props.contract.address.postalCode} {this.props.contract.address.city}<br /><br /><br />
                    {i18next.t("page.private.contract.modals.cancelContractModal.text8")}<br /><br />
                    {i18next.t("page.private.contract.modals.cancelContractModal.text9")} {moment(this.props.contract.contractDate).format("DD.MM.YYYY")} {this.props.contract.number ? `mit der Vertragsnr. ${this.props.contract.number} ` : ""}{this.props.contract.perpetual ? "" : "fristgemäß "} {this.props.currentEnd ? `zum ${this.props.currentEnd.format("DD.MM.YYYY")}.` : "mit sofortiger Wirkung."}<br /><br />
                    {i18next.t("page.private.contract.modals.cancelContractModal.text10")}<br /><br /><br />
                    {i18next.t("page.private.contract.modals.cancelContractModal.text11")}
                </Template>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={!this.state.canceledAt} loading={this.state.loading} thick title={this.props.contract.canceledAt ? i18next.t("page.private.contract.modals.cancelContractModal.revokeTerminationButton") : i18next.t("page.private.contract.modals.cancelContractModal.cancelledMarkButton")} icon="ban" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $canceledAt: DateTime) {
                            cancelContract(id: $id, canceledAt: $canceledAt)
                        }
                    `,
                    variables: {
                        id: this.props.contract.id,
                        canceledAt: this.props.contract.canceledAt ? null : moment(this.state.canceledAt, "DD.MM.YYYY").toDate()
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.cancelContract) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
