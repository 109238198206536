import styled from "styled-components";

export default styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 48px;
  transition: all 0.5s;
  flex-direction: column;
  visibility: ${props => props.show ? "visible" : "hidden"};
  opacity: ${props => props.show ? 1 : 0};
  user-select: none;
  
  .cancel {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 16px;
    font-size: 1.1em;
    transition: opacity 0.3s;
    opacity: 0.5;
    user-select: none;
    
    &:hover {
      opacity: 1;
    }
  }
  
  i {
    font-size: 3em;
    color: ${props => props.color};
    border: 5px solid;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    text-align: center;
    line-height: 80px;
  }
  
  h2 {
    font-size: 1.8em !important;
    opacity: 1 !important;
    color: black;
    margin-top: 32px;
    margin-bottom: 16px !important;
    width: 100%;
  }
  
  h3 {
    font-size: 1.2em;
    line-height: 1.4em;
    width: 90%;
  }
  
  h2, h3 {
    font-weight: normal;
    text-align: center;
  }
`
