import React, { Component } from "react"
import { Section, Table, Modal } from "scanmetrix-components"
import i18next from "i18next"
import styled from "styled-components"
import Avatar from "react-avatar";
import moment from 'moment';


const Status = styled.div`
  position: absolute;
  left: -16px;
  width: 32px;
  height: 32px;
  background: ${props => props.yellow ? "#e67e22" : (!!props.status ? "#27ae60" : (props.status === false ? "#c0392b" : "#2c3e50"))};
  border-radius: 32px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 16px);
  font-size: 0.9em;
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);
  
  i {
      margin: 0 !important;
  }
`



export default class extends Component {
    state = { accountingInvoiceDocumentId: null, loading: false ,
      subsidiaries: null, statuses: [], phases: [], custodians: []

    }
    constructor(props) {
      super(props)

      this.fetchServiceDeskUsers = this.fetchServiceDeskUsers.bind(this)
      this.fetchServiceDeskUsers()

      this.fetch = this.fetch.bind(this)
      this.fetch()

      this.subsidiaryFetch = this.subsidiaryFetch.bind(this)
      this.subsidiaryFetch()
      this.evaluate = this.evaluate.bind(this)

      this.userFetch = this.userFetch.bind(this)
      this.userFetch()

      this.fetchContacts = this.fetchContacts.bind(this)
      this.fetchContacts()


  }

  fetchServiceDeskUsers() {
   return fetch(scanmetrix.backendURL + "/service-desk-users", {
       method: "GET",
       credentials: "include",
       headers: {
           "Content-Type": "application/json"
       }
   }).then(result => result.json()).then(data => {
       this.setState({ serviceDeskUsers: data })
   })
  }
  fetch() {
   scanmetrix.client.query({
       query: scanmetrix.gql`
           query {
               ServiceProviders {
                   nodes {
                       id
                       name
                       responsibility
                       globalId
                       address {
                           streetName
                           streetNumber
                           postalCode
                           city
                       }
                       contactEmail
                       contactTelephone
                   }
               }
           }
       `
   }).then(data => {
       this.setState({ serviceProviders: data.data.ServiceProviders.nodes })
   })
}
subsidiaryFetch() {
   scanmetrix.client.query({
       query: scanmetrix.gql`
           query {
              
               Subsidiaries {
                   nodes {
                       id
                       label
                       name
                       legalName
                       contactEmail
                       
                       subsidiaryType {
                           id
                           name
                           color
                       }
                       address {
                           postalCode
                           city
                           streetName
                           streetNumber
                       }
                   }
               }
               Custodians {
                   nodes {
                       id
                       name
                       type
                       address {
                           inline
                       }
                   }
               }
           }
       `
   }).then(data => {
       this.setState({
           subsidiaries: data.data.Subsidiaries.nodes,
           custodians: data.data.Custodians.nodes,
           phases: data.data.Phases.nodes
       })
   })

   fetch(scanmetrix.backendURL + "/subsidiary-statuses", {
       method: "GET",
       credentials: "include",
       headers: {
           "Content-Type": "application/json"
       }
   }).then(result => result.json()).then(data => {
       this.setState({ statuses: data.statuses })
   })
}
userFetch() {
   scanmetrix.client.query({
       query: scanmetrix.gql`
           query {
               Teams {
                   nodes {
                       id
                       name
                       number
                       users {
                           id
                           role
                           isAdmin
                           user {
                               id
                               email  
                               firstName
                               lastName
                           }
                       }
                   }
               }
               Roles {
                   nodes {
                       id
                       name
                       icon
                       users {
                           id
                       }
                   }
               }
               Subsidiaries {
                   nodes {
                       id
                       name
                       label
                       address {
                           streetName
                           streetNumber
                           additional
                           postalCode
                           city
                           country
                           inline
                       }
                   }
               }
               Users {
                   nodes {
                       id
                       salutation
                       firstName
                       lastName
                       email
                       position
                       lastActivity
                       profileSettings
                       language
                       role {
                           id
                           name
                           icon
                       }
                   }
               }
           }
       `
   }).then(data => {
       const users = data.data.Users.nodes
       const teams = data.data.Teams.nodes
       const roles = data.data.Roles.nodes
       const subsidiaries = data.data.Subsidiaries.nodes

       this.setState({ users, teams, roles, subsidiaries })
   })
}
fetchContacts() {
   scanmetrix.client.query({
       query: scanmetrix.gql`
           query {
               SubsidiaryContacts {
                   nodes {
                       id
                       email
                       telephone
                       responsibility
                       salutation
                       firstName
                       lastName
                       type
                       mobileTelephone
                       telefax
                       company
                   }
               }
           }
       `

   }).then(data => {
       this.setState({ contacts: data.data.SubsidiaryContacts.nodes })
   })
}

evaluate = subsidiary => {
   if(this.state.statuses) {
       const status = this.state.statuses.find(status => String(status.subsidiaryId) === String(subsidiary.id))

       if(status) {
           return status.status
       }}
   return "gray"
}



handleItemClick = (userEmail,  ) => {
   this.props.onEmailSelect(userEmail);
};


    render()  {
        return<Modal  minWidth="650px"  instance={this.props.instance} initialize={accountingInvoiceDocumentId => this.setState({ accountingInvoiceDocumentId, loading: false })}>



            <Modal.Head title={"Kontaktbuch"} icon="address-book" />

            <div style={{display:"flex", alignItems:"center", flexDirection: "row", padding:"24px", gridGap:"12px"}}>
            {/*<Button style={{width:"80%"}} loading={this.state.loading} thick title={"Benutzer"} icon="user-crown" onClick={() => {
                this.UserModal.open()
             }}/>
             <Button style={{width:"80%"}} loading={this.state.loading} thick title={"Service-Desk Benutzer"} icon="user-tie" onClick={() => {
                this.SDUserModal.open()
             }}/>
             <Button style={{width:"80%"}} loading={this.state.loading} thick title={"Niederlassungen"} icon="house" onClick={() => {
                this.SubsidiaryModal.open()
             }}/>
             <Button style={{width:"80%"}} loading={this.state.loading} thick title={"Dienstleister"} icon="user" onClick={() => {
                this.ServiceProviderModal.open()
             }}/>
             <Button style={{width:"80%"}} loading={this.state.loading} thick title={"NL-Ansprechpartner"} icon="contact-book" onClick={() => {
                this.NLModal.open()
             }}/>*/}
             <Section>
               <Section.Item title={"Benutzer"} icon={"users"}>
               <Table
                            head={[
                                { title: i18next.t("page.private.users.sectionItem.user.table.salutation"), width: "7.5%", column: "salutation" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.display"), width: "12.5%", column: "display" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.email"), width: "15%", column: "email" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.position"), width: "10%", column: "position" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.mobile"), width: "10%", column: "mobile" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.fax"), width: "10%", column: "fax" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.role"), width: "12.5%", column: "role" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.language"), width: "5%", column: "language" },
                                { title: i18next.t("page.private.users.sectionItem.user.table.lastActivity"), width: "7.5%", column: "lastActivity" }
                            ]}
                            onItemClick={user => this.handleItemClick(user.email)}

                            items={this.state.users ? this.state.users.sort((a, b) => (b.lastActivity ? moment(b.lastActivity).valueOf() : 0) - (a.lastActivity ? moment(a.lastActivity).valueOf() : 0)).map(user => ({
                                ...user,
                                salutation: i18next.t(`defaults.salutation.${user.salutation}`),
                                landline: user.profileSettings?.landline,
                                mobile: user.profileSettings?.mobile,
                                fax: user.profileSettings?.fax,
                                lastActivity: user.lastActivity ? moment(user.lastActivity).fromNow() : i18next.t("page.private.users.sectionItem.user.lastActivityNeverBefore"),
                                role: user.role ? <div><i className={`fa-duotone fa-${user.role.icon}`} /> {user.role.name}</div> : i18next.t("page.private.users.sectionItem.user.roleNone"),
                                display: <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : user.email} name={`${user.firstName} ${user.lastName}`} className="avatarInner" />
                                    </div>
                                    {user.firstName} {user.lastName}
                                </div>
                            })) : null}
                        />
               </Section.Item>
               <Section.Item title={"Service-Desk Benutzer"} icon={"fa-duotone fa-bell-concierge mainIcon"}>
               <Table
                            head={[
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.active"), width: "5%", column: "activeStyled" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.avatar"), width: "5%", column: "avatar" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.firstname"), width: "12.5%", column: "firstName" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.lastname"), width: "12.5%", column: "lastName" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.email"), width: "17.5%", column: "email" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.propertiesRealEstate"), width: "7.5%", column: "subsidiariesStyled" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.right"), width: "30%", column: "permissions" },
                                { title: i18next.t("page.private.users.sectionItem.serviceDesk.table.userSince"), width: "7.5%", column: "createdAt" }
                            ]}
                            onItemClick={serviceDesk => this.handleItemClick(serviceDesk.email)}
                            items={this.state.serviceDeskUsers ? this.state.serviceDeskUsers.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(user => {
                                const hasAllSubsidiaries = user.enableSubsidiaryWhitelist ? (user.subsidiaries.length === this.state.subsidiaries.length) : true

                                return ({
                                    ...user,
                                    activeStyled: <div style={{ color: "white", background: user.active ? "#16a085" : "#e74c3c", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{user.active ? i18next.t("page.private.users.sectionItem.serviceDesk.userActiveYes") : i18next.t("page.private.users.sectionItem.serviceDesk.userActiveNo")}</div>,
                                    subsidiariesStyled: <div style={{ color: "white", background: hasAllSubsidiaries ? "#16a085" : "#3b97d3", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{hasAllSubsidiaries ? i18next.t("page.private.users.sectionItem.serviceDesk.allSubsidiaries") : `${user.subsidiaries.length} von ${this.state.subsidiaries.length}`}</div>,
                                    permissions: <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div style={{ whiteSpace: "nowrap", color: "white", background: user.featureBlacklist.includes("meters") ? "#e74c3c" : "#16a085", padding: "4px 8px", display: "inline-flex", alignItems: "center", fontSize: 14 }}><i className="fa-duotone fa-monitor-waveform" />{i18next.t("page.private.users.sectionItem.serviceDesk.permission.counter")}</div>
                                        <div style={{ whiteSpace: "nowrap", color: "white", background: user.featureBlacklist.includes("buildingInspections") ? "#e74c3c" : "#16a085", padding: "4px 8px", display: "inline-flex", alignItems: "center", fontSize: 14 }}><i className="fa-duotone fa-person-walking" />{i18next.t("page.private.users.sectionItem.serviceDesk.permission.inspections")}</div>
                                        <div style={{ whiteSpace: "nowrap", color: "white", background: user.featureBlacklist.includes("data") ? "#e74c3c" : "#16a085", padding: "4px 8px", display: "inline-flex", alignItems: "center", fontSize: 14 }}><i className="fa-duotone fa-qrcode" />{i18next.t("page.private.users.sectionItem.serviceDesk.permission.dataInsight")}</div>
                                        <div style={{ whiteSpace: "nowrap", color: "white", background: user.featureBlacklist.includes("ticketWithoutInspection") ? "#e74c3c" : "#16a085", padding: "4px 8px", display: "inline-flex", alignItems: "center", fontSize: 14 }}><i className="fa-duotone fa-comments-alt" />{i18next.t("page.private.users.sectionItem.serviceDesk.permission.ticketWithoutInspection")}</div>
                                    </div>,
                                    avatar: <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                            <Avatar size="32" email={scanmetrix.disableGravatar ? null : user.email} name={`${user.firstName} ${user.lastName}`} className="avatarInner" />
                                        </div>
                                    </div>,
                                    createdAt: moment(user.createdAt).format("DD.MM.YYYY")
                                })
                            }) : null}
                        />
               </Section.Item>
               <Section.Item title={"Dienstleister"} icon={"user-tie"}>
               <Table
                    head={[
                        { title: i18next.t("page.private.serviceProviders.table.name"), width: "20%", column: "name" },
                        { title: i18next.t("page.private.serviceProviders.table.responsibility"), width: "27.5%", column: "responsibility" },
                        { title: i18next.t("page.private.serviceProviders.table.city"), width: "15%", column: "city" },
                        { title: i18next.t("page.private.serviceProviders.table.contactEmail"), width: "17.5%", column: "contactEmail" },
                        { title: i18next.t("page.private.serviceProviders.table.contactTelephone"), width: "15%", column: "contactTelephone" }
                    ]}
                    additionalSearchIndexes={["nameRaw"]}
                    onItemClick={serviceProviders => this.handleItemClick(serviceProviders.contactEmail)}
                    contextItems={[
                        { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: serviceProvider => window.open(`/serviceprovider/${serviceProvider.id}`)}
                    ]}
                    items={this.state.serviceProviders ? this.state.serviceProviders.map(serviceProvider => ({
                        ...serviceProvider,
                        nameRaw: serviceProvider.name,
                        name: <div style={{ paddingLeft: "16px" }}><Status blue={!!serviceProvider.globalId}><i className={`far fa-${serviceProvider.globalId ? "sync" : "print"}`} /></Status>{serviceProvider.name}</div>,
                        city: serviceProvider.address.postalCode && serviceProvider.address.city ? `${serviceProvider.address.postalCode} ${serviceProvider.address.city}` : (serviceProvider.address.city || "N/A")
                    })) : null}
                />
               </Section.Item>
               <Section.Item title={"Niederlassungen"} icon={"house"}>
               <Table
                            head={[
                                { title: i18next.t("page.private.facilityManagement.branches.table.name"), width: "15%", column: "name" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.label"), width: "10%", column: "label" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.legalName"), width: "17.5%", column: "legalName" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.street"), width: "20%", column: "street" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.postalCode"), width: "7.5%", column: "postalCode" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.city"), width: "10%", column: "city" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.type"), width: "10%", column: "type" },
                                { title: "Email", width:"5%", column: "contactEmail"},
                                { title: i18next.t("page.private.facilityManagement.branches.table.phase"), width: "10%", column: "phase" }
                            ]}
                            onItemClick={Subsidiaries => this.handleItemClick(Subsidiaries.contactEmail)}
                            additionalSearchIndexes={[ "rawName" ]}
                            filters={[
                                {
                                    type: "select",
                                    items: this.state.phases.map(phase => ({ key: phase.id, title: phase.name })),
                                    label: "Status",
                                    apply: (items, key) => items.filter(item => {
                                        if(!key) return true

                                        return item.phaseId === key
                                    })
                                }
                            ]}
                            items={this.state.subsidiaries ? this.state.subsidiaries.map(subsidiary => {
                                const evaluation = this.evaluate(subsidiary)

                                return ({
                                    id: subsidiary.id,
                                    label: subsidiary.label,
                                    rawName: subsidiary.name,
                                    name: <div style={{ paddingLeft: "16px" }}><Status yellow={evaluation === "orange"} status={evaluation === "gray" ? null : (evaluation === "red" ? false : true)}><i className={`far fa-${evaluation === "gray" ? "clock" : (evaluation === "green" ? "check-double" : "bolt")}`} /></Status>{subsidiary.name}</div>,
                                    legalName: subsidiary.legalName,
                                    street: `${subsidiary.address.streetName} ${subsidiary.address.streetNumber}`,
                                    postalCode: subsidiary.address.postalCode,
                                    city: subsidiary.address.city,
                                    type: subsidiary.subsidiaryType ? <b style={{ color: subsidiary.subsidiaryType.color }}>{subsidiary.subsidiaryType.name}</b> : null,
                                    phase: subsidiary.phase ? <b style={{ color: subsidiary.phase.color }}>{subsidiary.phase.name}</b> : null,

                                    phaseId: subsidiary.phase ? subsidiary.phase.id : null,


                                })
                            }) : null}
                            contextItems={[
                                { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: subsidiary => window.open(`/subsidiary/${subsidiary.id}`, '_blank')}
                            ]}
                        />
               </Section.Item>
               <Section.Item title={"NL-Ansprechpartner"} icon={"address-book"}>
               <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.salutationFormatted"), width: "10%", column: "salutationFormatted" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.name"), width: "15%", column: "name" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.email"), width: "15%", column: "email" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.telephone"), width: "15%", column: "telephone" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.type"), width: "15%", column: "type" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.company"), width: "15%", column: "company" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.responsibility"), width: "15%", column: "responsibility" }
                            ]}
                            onItemClick={contact => this.handleItemClick(contact.email)}
                            items={this.state.contacts ? this.state.contacts.map(contact => ({
                                id: contact.id,
                                firstName: contact.firstName,
                                lastName: contact.lastName,
                                name: `${contact.firstName ? (contact.firstName + " ") : ""}${contact.lastName}`,
                                salutation: contact.salutation,
                                salutationFormatted: i18next.t(`defaults.salutation.${contact.salutation}`),
                                email: contact.email,
                                telephone: contact.telephone,
                                mobileTelephone: contact.mobileTelephone,
                                type: contact.type,
                                company: contact.company,
                                telefax: contact.telefax,
                                responsibility: contact.responsibility
                            })) : null}
                        />
               </Section.Item>
               <Section.Item title={i18next.t("page.private.facilityManagement.landlord.breadCrumbsTitle")} icon="house-user" amount={this.state.custodians ? this.state.custodians.length: 0}>

                        <Table
                            head={[
                                { title: i18next.t("page.private.facilityManagement.landlord.table.type"), width: "12.5%", column: "type" },
                                { title: i18next.t("page.private.facilityManagement.landlord.table.name"), width: "17.5%", column: "name" },
                                { title: i18next.t("page.private.facilityManagement.landlord.table.address"), width: "70%", column: "address" }
                            ]}
                            onItemClick={custodian => this.props.history.push(`/custodian/${custodian.id}`)}
                            items={this.state.custodians ? this.state.custodians.map(custodian => {
                                return ({
                                    id: custodian.id,
                                    name: custodian.name,
                                    type: custodian.type === "landlord" ? <div style={{ display: "flex", alignItems: "center" }}><i className="far fa-user-tie" />{i18next.t("page.private.facilityManagement.landlordBottom")}</div> : <div style={{ display: "flex", alignItems: "center" }}><i className="far fa-cog" />{i18next.t("page.private.facilityManagement.administrativeBottom")}</div>,
                                    address: custodian.address.inline
                                })
                            }) : null}
                            contextItems={[
                                { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: custodian => window.open(`/custodian/${custodian.id}`, '_blank')}
                            ]}
                        />

                </Section.Item>
             </Section>
            </div>
        </Modal>
    }
}
