import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { salutation: null, firstName: null, lastName: null, email: null, telephone: null, responsibility: null, type: null, telefax: null, company: null, mobileTelephone: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={contact => this.setState({ id: contact.id, salutation: contact.salutation, firstName: contact.firstName, lastName: contact.lastName, email: contact.email, telephone: contact.telephone, responsibility: contact.responsibility,  type: contact.type, telefax: contact.telefax, company: contact.company, mobileTelephone: contact.mobileTelephone, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.updateContactModal.title")} icon="address-book" />
            <Modal.Body padding={24} adjustHeight={384}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                        <Select readOnly={scanmetrix.checkPermission("SubsidiaryContact") < 3} required adjustWidth value={this.state.salutation} onChange={salutation => this.setState({ salutation })} icon="venus-mars" label={i18next.t("defaults.input.salutation")} items={[
                            { key: "male", title: i18next.t("defaults.salutation.male"), icon: "mars" },
                            { key: "female", title: i18next.t("defaults.salutation.female"), icon: "venus" },
                            { key: "maleDr", title: i18next.t("defaults.salutation.maleDr"), icon: "mars" },
                            { key: "femaleDr", title: i18next.t("defaults.salutation.femaleDr"), icon: "venus" },
                            { key: "maleProfDr", title: i18next.t("defaults.salutation.maleProfDr"), icon: "mars" },
                            { key: "femaleProfDr", title: i18next.t("defaults.salutation.femaleProfDr"), icon: "venus" },
                            { key: "maleProfDrMed", title: i18next.t("defaults.salutation.maleProfDrMed"), icon: "mars" },
                            { key: "femaleProfDrMed", title: i18next.t("defaults.salutation.femaleProfDrMed"), icon: "venus" },
                            { key: "maleDrMed", title: i18next.t("defaults.salutation.maleDrMed"), icon: "mars" },
                            { key: "femaleDrMed", title: i18next.t("defaults.salutation.femaleDrMed"), icon: "venus" },
                            { key: "maleDiplIng", title: i18next.t("defaults.salutation.maleDiplIng"), icon: "mars" },
                            { key: "femaleDiplIng", title: i18next.t("defaults.salutation.femaleDiplIng"), icon: "venus" },
                            { key: "maleDiplKfm", title: i18next.t("defaults.salutation.maleDiplKfm"), icon: "mars" },
                            { key: "femaleDiplKfr", title: i18next.t("defaults.salutation.femaleDiplKfr"), icon: "venus" }
                        ]} />
                        <Input disabled={scanmetrix.checkPermission("SubsidiaryContact") < 3} value={this.state.firstName} onChange={firstName => this.setState({ firstName })} icon="tag" label={i18next.t("page.private.subsidiary.styled.updateContactModal.firstName")} adjustWidth />
                        <Input disabled={scanmetrix.checkPermission("SubsidiaryContact") < 3} required value={this.state.lastName} onChange={lastName => this.setState({ lastName })} icon="tag" label={i18next.t("page.private.subsidiary.styled.updateContactModal.lastName")} adjustWidth />
                    </div>
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <Input disabled={scanmetrix.checkPermission("SubsidiaryContact") < 3} value={this.state.email} onChange={email => this.setState({ email })} icon="at" label={i18next.t("page.private.subsidiary.styled.updateContactModal.email")} adjustWidth />
                        <Input disabled={scanmetrix.checkPermission("SubsidiaryContact") < 3} value={this.state.telefax} onChange={telefax => this.setState({ telefax })} icon="fax" label={i18next.t("page.private.subsidiary.styled.updateContactModal.faxNumber")} adjustWidth />
                    </div>
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <Input disabled={scanmetrix.checkPermission("SubsidiaryContact") < 3} value={this.state.responsibility} onChange={responsibility => this.setState({ responsibility })} icon="hard-hat" label={i18next.t("page.private.subsidiary.styled.updateContactModal.responsibility")} adjustWidth />
                        <Input disabled={scanmetrix.checkPermission("SubsidiaryContact") < 3} value={this.state.type} onChange={type => this.setState({ type })} icon="hard-hat" label={i18next.t("page.private.subsidiary.styled.updateContactModal.type")} adjustWidth />
                    </div>
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <Input disabled={scanmetrix.checkPermission("SubsidiaryContact") < 3} value={this.state.telephone} onChange={telephone => this.setState({ telephone })} icon="phone" label={i18next.t("page.private.subsidiary.styled.updateContactModal.telephoneNumber")} adjustWidth />
                        <Input disabled={scanmetrix.checkPermission("SubsidiaryContact") < 3} value={this.state.mobileTelephone} onChange={mobileTelephone => this.setState({ mobileTelephone })} icon="mobile" label={i18next.t("page.private.subsidiary.styled.updateContactModal.mobile")} adjustWidth />
                    </div>
                    <Input disabled={scanmetrix.checkPermission("SubsidiaryContact") < 3} value={this.state.company} onChange={company => this.setState({ company })} icon="building" label={i18next.t("page.private.subsidiary.styled.updateContactModal.company")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => [
                <Button loading={this.state.loading} thick disabled={!this.state.lastName || !this.state.salutation || scanmetrix.checkPermission("SubsidiaryContact") < 3} title={i18next.t("page.private.subsidiary.styled.updateContactModal.exitButton")} icon="address-book" onClick={() => {
                    this.setState({ loading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($salutation: String!, $firstName: String, $lastName: String!, $responsibility: String, $email: String, $telephone: String, $id: ID!, $type: String, $mobileTelephone: String, $telefax: String, $company: String) {
                            updateSubsidiaryContact(salutation: $salutation, firstName: $firstName, lastName: $lastName, email: $email, telephone: $telephone, responsibility: $responsibility, id: $id, type: $type, telefax: $telefax, mobileTelephone: $mobileTelephone, company: $company)
                        }
                    `,
                        variables: {
                            salutation: this.state.salutation,
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            responsibility: this.state.responsibility,
                            email: this.state.email,
                            telephone: this.state.telephone,
                            id: this.state.id,
                            type: this.state.type,
                            telefax: this.state.telefax,
                            company: this.state.company,
                            mobileTelephone: this.state.mobileTelephone
                        }
                    }).then(result => {
                        this.setState({ loading: false })

                        if(result.data.updateSubsidiaryContact) {
                            this.props.refresh()
                            instance.close()
                        }
                    })}
                } />,
                <Button disabled={scanmetrix.checkPermission("SubsidiaryContact") < 4} loading={this.state.loading} thick title={i18next.t("page.private.subsidiary.styled.updateContactModal.deleteButton")} icon="trash" secondary onClick={() => {
                    this.setState({ loading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            deleteSubsidiaryContact(id: $id)
                        }
                    `,
                        variables: {
                            id: this.state.id
                        }
                    }).then(result => {
                        this.setState({ loading: false })

                        if(result.data.deleteSubsidiaryContact) {
                            this.props.refresh()
                            instance.close()
                        }
                    })}
                } />
            ]} />
        </Modal>
    }
}
