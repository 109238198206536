import React, { Component } from "react"
import { Page, Breadcrumbs, Grid, GridElement, Button } from "scanmetrix-components"
import ReactFlow, { Background, Handle, useUpdateNodeInternals, ReactFlowProvider } from "react-flow-renderer"
import ScrollContainer from "react-indiana-drag-scroll"
import styled, { keyframes } from "styled-components"
import ConnectionLine from './ConnectionLine'
import { v4 as uuid } from "uuid"

import CreateBranchNodeModal from "./modals/CreateBranchNodeModal"
import CreateSimpleNodeModal from "./modals/CreateSimpleNodeModal"
import CreatePhaseNodeModal from "./modals/CreatePhaseNodeModal"
import CreateWorkflowNodeModal from "./modals/CreateWorkflowNodeModal"
import CreateEndNodeModal from "./modals/CreateEndNodeModal"

import {contextMenu, Item, Menu} from 'react-contexify'
import i18next from "i18next";

const StyledNodeTemplate = styled.div`
    display: flex;
    max-width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 8px;
    user-select: none;
    padding: 16px;
    box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    cursor: pointer;
    transition: border-color 0.3s;
    min-width: 300px;
    position: relative;
  
    &:first-child {
        margin-left: 0;
    }
  
    &:hover {
        border-color: #20242b;
    }
  
    .title {
        font-size: 1.1em;
        margin-bottom: 8px;
    }
  
    .description {
        line-height: 1.4em;
        opacity: 0.75;
    }
  
    i {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 32px;
        flex-shrink: 0;
    }
    
    .todo {
        background: #20242b;
        color: white;
        height: fit-content;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 6px 0 6px 0;
        padding: 4px 8px;
        font-size: 0.9em;
    }
`

class NodeTemplate extends Component {
    render() {
        return <StyledNodeTemplate onClick={this.props.onClick}>
            <i style={{ backgroundColor: this.props.color, boxShadow: `0 2px 8px -3px ${this.props.color}` }} className={`far fa-${this.props.icon}`} />
            {this.props.todo && <div className="todo">{i18next.t("page.private.workFlow.soonAvailable")}</div>}
            <div className="right">
                <p className="title">{this.props.title}</p>
                <p className="description">{this.props.description}</p>
            </div>
        </StyledNodeTemplate>
    }
}

const Outcomes = styled.div`
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
`

const Outcome = styled.div`
    background: #9b59b6;
    color: white;
    margin: 0 4px;
    padding: 6px;
    position: relative;
    border-radius: 4px;
  
    &:first-child {
        margin-left: 0;
    }
  
    &:last-child {
        margin-right: 0;
    }
  
    p.title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 0.7em;
    }
`

class AddBranch extends Component {
    state = { writing: false, name: null }

    render() {
        const nameOkay = this.state.name && this.state.name.trim().length > 0

        return <div style={{ position: "absolute", transform: "translateX(100%)", right: -8, top: 10, minWidth: 24, height: 24, boxSizing: "border-box", borderRadius: 3, background: "white", color: "#3b97d3", cursor: "pointer", display: "flex", alignItems: "center", fontSize: "10px" }}>
            <i className={`far fa-${this.state.writing ? "times" : "plus"}`} style={{ width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => this.setState({ writing: !this.state.writing, name: null })} />
            {this.state.writing && <input placeholder={i18next.t("page.private.workFlow.searchPlaceholder")} value={this.state.name || ""} onChange={e => this.setState({ name: e.target.value })} autoCorrect="false" spellCheck="false" style={{ background: "transparent", paddingRight: 12, outline: 0, fontSize: "inherit", fontFamily: "inherit", border: "none", height: "100%" }} />}
            {this.state.writing && <div onClick={() => {
                if(nameOkay) {
                    this.props.add(this.state.name)
                    this.setState({ writing: false, name: null })
                }
            }} style={{ height: 18, width: 18, borderRadius: 18, background: nameOkay ? "#2ecc71" : "gray", display: "flex", color: "white", position: "absolute", transition: "background 0.3s", justifyContent: "center", alignItems: "center", cursor: nameOkay ? "pointer" : "not-allowed", right: -9 }}><i className="far fa-check" /></div>}
        </div>
    }
}

const BranchNodeComponent = props => {
    const data = props.data
    const change = props.change

    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            border: "2px solid #9b59b6",
            fontSize: "12px",
            borderRadius: 4,
            position: "relative"
        }}>
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ fontWeight: "bold", fontSize: "10px" }}>{i18next.t("page.private.workFlow.nodes.branching")}</div>
            <div><i className="far fa-project-diagram" /> {data.label}</div>
            <AddBranch add={title => {
                data.outcomes.push({
                    id: uuid(),
                    title
                })

                change(props)
            }} />
            <Outcomes>
                {data.outcomes.map((outcome, index) => <Outcome key={index}>
                    <p className="title">{outcome.title}</p>
                    <Handle
                        type="source"
                        position="bottom"
                        id={outcome.id}
                        style={{ left: "50%", backgroundColor: "#9b59b6" }}
                    />
                </Outcome>)}
            </Outcomes>
        </div>
    )
}

const StartNodeComponent = ({ data }) => {
    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            borderRadius: 12
        }}>
            <Handle type="source" position="bottom" style={{ backgroundColor: "#9b59b6" }} />
            <div style={{ fontWeight: "bold" }}>{data.label}</div>
        </div>
    )
}

const SimpleNodeComponent = ({ data }) => {
    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            border: "2px solid #3b97d3",
            borderRadius: 4
        }}>
            <Handle type="source" position="bottom" style={{ backgroundColor: "#9b59b6" }} />
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ fontWeight: "bold", fontSize: "10px" }}>{i18next.t("page.private.workFlow.nodes.workingSteps")}</div>
            <div>{data.label}</div>
        </div>
    )
}

const PhaseNodeComponent = ({ data, phases }) => {
    const phase = phases.find(ph => ph.id === data.phaseId)

    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            border: "2px solid #1abc9c",
            borderRadius: 4
        }}>
            <Handle type="source" position="bottom" style={{ backgroundColor: "#9b59b6" }} />
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ fontWeight: "bold", fontSize: "10px" }}>{i18next.t("page.private.workFlow.nodes.phase")}</div>
            <div>{phase ? phase.name : "Unbekannte Phase"}</div>
        </div>
    )
}

const WorkflowNodeComponent = ({ data, workflows }) => {
    const workflow = workflows.find(wf => wf.id === data.workflowId)

    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            border: "2px solid #1abc9c",
            borderRadius: 4
        }}>
            <Handle type="source" position="bottom" style={{ backgroundColor: "#9b59b6" }} />
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ fontWeight: "bold", fontSize: "10px" }}>{i18next.t("page.private.workFlow.nodes.workflowTrigger")}</div>
            <div>{workflow ? workflow.name : "Unbekannter Workflow"}</div>
        </div>
    )
}

const EndNodeComponent = ({ data }) => {
    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            border: "2px solid #e74c3c",
            borderRadius: 12
        }}>
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ color: "#e74c3c" }}>{i18next.t("page.private.workFlow.nodes.workflowEnd")}</div>
        </div>
    )
}

const Spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
  
    100% {
        transform: rotate(360deg);
    }
`

const Save = styled.div`
    position: absolute;
    color: white;
    display: flex;
    bottom: 16px;
    right: 16px;
    z-index: 10;
    user-select: none;
    align-items: center;
  
    .fa-spinner-third {
        animation: ${Spin} 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
  
    i {
        margin-right: 8px;
    }
`

export default class extends Component {
    state = { workflow: null, elements: [], defaultPosition: null, saving: false, saved: false }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.addElement = this.addElement.bind(this)
        this.save = this.save.bind(this)

        this.fetch()
    }

    save() {
        this.setState({ saving: true })

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $elements: JSON!) {
                    updateWorkflow(id: $id, elements: $elements)
                }
            `,
            variables: {
                id: this.state.workflow.id,
                elements: JSON.stringify(this.state.elements)
            }
        }).then(data => {
            if(data.data.updateWorkflow) {
                this.setState({ saving: false, saved: true })

                setTimeout(() => this.setState({ saved: false }), 1500)
            }
        })
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($workflowId: ID!) {
                    Workflow(id: $workflowId) {
                        id
                        name
                        elements
                    }
                    Workflows {
                        nodes {
                            id
                            name
                        }
                    }
                    Phases {
                        nodes {
                            id
                            name
                        }
                    }
                }
            `,
            variables: {
                workflowId: this.props.match.params.workflowid
            }
        }).then(data => {
            this.setState({ workflow: data.data.Workflow, elements: data.data.Workflow.elements || [], phases: data.data.Phases.nodes, workflows: data.data.Workflows.nodes })
        })
    }

    addElement(element) {
        const elements = this.state.elements

        elements.push(element)

        this.setState({ elements: [] })

        setTimeout(() => this.setState({ elements }, () => this.save()), 1)
    }

    render() {
        const workflow = this.state.workflow

        if(!workflow) return null

        return <Page {...this.props}>
            <CreateBranchNodeModal addNode={node => this.addElement(node)} instance={ref => this.createBranchNodeModal = ref} />
            <CreateSimpleNodeModal addNode={node => this.addElement(node)} instance={ref => this.createSimpleNodeModal = ref} />
            <CreateEndNodeModal addNode={node => this.addElement(node)} instance={ref => this.createEndNodeModal = ref} />
            <CreatePhaseNodeModal phases={this.state.phases} addNode={node => this.addElement(node)} instance={ref => this.createPhaseNodeModal = ref} />
            <CreateWorkflowNodeModal workflows={this.state.workflows} addNode={node => this.addElement(node)} instance={ref => this.createWorkflowNodeModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "project-diagram",
                    title: i18next.t("page.private.workFlow.breadCrumbsWorkflows"),
                    link: "/workflows"
                },
                {
                    title: i18next.t("page.private.workFlow.breadCrumbsEditor"),
                    icon: "comment-alt-edit"
                },
                {
                    title: workflow.name
                }
            ]} />
            <Grid gap="32px">
                <GridElement styled title={i18next.t("page.private.workFlow.nodePalette.gridElementTitle")} icon="swatchbook">
                    <ScrollContainer horizontal={true} vertical={false} style={{ display: "flex", overflowY: "visible", padding: "8px 0", boxSizing: "border-box", marginRight: 16, marginLeft: 16 }}>
                        <NodeTemplate color="#9b59b6" title={i18next.t("page.private.workFlow.nodePalette.branchingTitle")} icon="project-diagram" description={i18next.t("page.private.workFlow.nodePalette.branchingDescription")} onClick={() => this.createBranchNodeModal.open()} />
                        <NodeTemplate color="#3b97d3" title={i18next.t("page.private.workFlow.nodePalette.workingStepsTitle")} icon="file-user" description={i18next.t("page.private.workFlow.nodePalette.workingStepsDescription")} onClick={() => this.createSimpleNodeModal.open()} />
                        <NodeTemplate color="#1abc9c" title={i18next.t("page.private.workFlow.nodePalette.phaseTitle")} icon="comment-alt-edit" description={i18next.t("page.private.workFlow.nodePalette.phaseDescription")} onClick={() => this.createPhaseNodeModal.open()} />
                        <NodeTemplate color="#e74c3c" title={i18next.t("page.private.workFlow.nodePalette.conclusionTitle")} icon="spell-check" description={i18next.t("page.private.workFlow.nodePalette.conclusionDescription")} onClick={() => this.createEndNodeModal.open()} />
                        <NodeTemplate color="#2ecc71" title={i18next.t("page.private.workFlow.nodePalette.workflowTriggerTitle")} icon="project-diagram" description={i18next.t("page.private.workFlow.nodePalette.workflowTriggerDescription")} onClick={() => this.createWorkflowNodeModal.open()} />
                        <NodeTemplate todo color="#34495e" title={i18next.t("page.private.workFlow.nodePalette.notificationTitle")} icon="comments-alt" description={i18next.t("page.private.workFlow.nodePalette.notificationDescription")} onClick={() => this.createMessageNodeModal.open()} />
                        <NodeTemplate todo color="#e67e22" title={i18next.t("page.private.workFlow.nodePalette.agendaTitle")} icon="tasks" description={i18next.t("page.private.workFlow.nodePalette.agendaDescription")} onClick={() => this.createAgendaNodeModal.open()} />
                        <NodeTemplate todo color="#7f8c8d" title={i18next.t("page.private.workFlow.nodePalette.calendarTitle")} icon="calendar" description={i18next.t("page.private.workFlow.nodePalette.calendarDescription")} onClick={() => this.createCalendarNodeModal.open()} />
                    </ScrollContainer>
                </GridElement>
                <GridElement styled title={i18next.t("page.private.workFlow.workflowEditor.gridElementTitle")} icon="chart-network" rightContent={<div style={{ display: "flex", alignItems: "center" }}>
                    {!this.state.elements.find(el => el.type === "end") && !this.state.saving && <p style={{ marginRight: 16 }}><b style={{ fontWeight: "bold", color: "#e74c3c" }}>{i18next.t("page.private.workFlow.workflowEditor.warning")}</b> {i18next.t("page.private.workFlow.workflowEditor.noConclusion")}</p>}
                    <Button disabled title={i18next.t("page.private.workFlow.workflowEditor.settingsButton")} primary icon="cog" thick onClick={() => {
                        //TODO: Settings modal
                    }} />
                </div>}>
                    <div ref={element => {
                        if(element && !this.state.defaultPosition) this.setState({ defaultPosition: [ element.clientWidth / 2 - 75, element.clientHeight / 2 - 25 ] })
                    }} style={{ height: "768px", width: "100%", backgroundColor: "#20242b", borderRadius: "0 0 5px 5px", position: "relative" }}>
                        {this.state.contextItems && <Menu id='context_menu'>
                            {this.state.contextItems.map((item, key) => {
                                return <Item key={key} onClick={() => {
                                    item.onClick()
                                }}>
                                    <i style={{ width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }} className={`far fa-${item.icon}`} />{item.title}
                                </Item>
                            })}
                        </Menu>}
                        {this.state.defaultPosition && <ReactFlowProvider>
                            <ProviderWrapper contextMenu={(event, contextItems) => {
                                this.setState({
                                    contextItems
                                }, () => {
                                    contextMenu.show({ id: "context_menu", event, props: {} })
                                })
                            }} phases={this.state.phases} workflows={this.state.workflows} removeElement={(element, type) => {
                                let elements = this.state.elements.filter(el => el.id !== element.id)

                                if(type === "node") {
                                    elements = elements.filter(el => {
                                        return el.source !== element.id && el.target !== element.id
                                    })
                                }

                                this.setState({ elements }, () => this.save())
                            }} addElement={this.addElement} defaultPosition={this.state.defaultPosition} elements={this.state.elements} change={(data, callback) => {
                                let elements = this.state.elements.map(el => {
                                    if(el.id === data.id) {
                                        let mapped = { ...el, data: data.data }

                                        if(data.position) mapped.position = data.position

                                        return mapped
                                    }

                                    return el
                                })

                                this.setState({ elements }, () => {
                                    if(callback) callback()

                                    this.save()
                                })
                            }} />
                        </ReactFlowProvider>}
                        {this.state.saving && <Save>
                            <i className="far fa-spinner-third" />
                            <p>{i18next.t("page.private.workFlow.workflowEditor.savedChanges")}</p>
                        </Save>}
                        {this.state.saved && <Save>
                            <i className="far fa-check-circle" />
                            <p>{i18next.t("page.private.workFlow.workflowEditor.saved")}</p>
                        </Save>}
                    </div>
                </GridElement>
                <div style={{ userSelect: "none", transform: "translateY(-16px)", opacity: 0.5 }}>
                    <i className="far fa-mouse" style={{ marginRight: 8 }} />
                    {i18next.t("page.private.workFlow.workflowEditor.leftClickButtonDescription")}
                </div>
            </Grid>
        </Page>
    }
}

const ProviderWrapper = props => {
    const updateNodeInternals = useUpdateNodeInternals()

    return <FlowClass {...props} updateNodeInternals={id => updateNodeInternals(id)} />
}

class FlowClass extends Component {
    render() {
        const elements = this.props.elements

        return <ReactFlow
            nodeTypes={{
                start: StartNodeComponent,
                branch: props => <BranchNodeComponent {...props} change={data => {
                    this.props.change(data, () => {
                        this.props.updateNodeInternals(data.id)
                    })
                }} />,
                simple: SimpleNodeComponent,
                phase: props => <PhaseNodeComponent {...props} phases={this.props.phases} />,
                workflow: props => <WorkflowNodeComponent {...props} workflows={this.props.workflows} />,
                end: EndNodeComponent
            }}
            onNodeContextMenu={(event, node) => {
                event.preventDefault()
                event.persist()

                if(node.type === "start") return false

                this.props.contextMenu(event, [
                    {
                        title: i18next.t("page.private.workFlow.workflowEditor.deleteNodeButton"),
                        icon: "times",
                        onClick: () => {
                            this.props.removeElement(node, "node")
                        }
                    }
                ])
            }}
            onEdgeContextMenu={(event, edge) => {
                event.preventDefault()
                event.persist()

                this.props.contextMenu(event, [
                    {
                        title: i18next.t("page.private.workFlow.workflowEditor.disconnect"),
                        icon: "times",
                        onClick: () => {
                            this.props.removeElement(edge, "edge")
                        }
                    }
                ])
            }}
            connectionLineComponent={ConnectionLine}
            snapToGrid={true}
            snapGrid={[16, 16]}
            defaultZoom={1.5}
            defaultPosition={this.props.defaultPosition}
            elements={elements}
            onConnect={edge => {
                if(!elements.find(el => {
                    return el.source === edge.source && el.target === edge.target && el.sourceHandle === edge.sourceHandle && el.targetHandle === edge.targetHandle
                })) this.props.addElement({
                    ...edge,
                    id: (elements.length + 1) + ""
                })
            }}
            onNodeDragStop={(_, node) => {
                this.props.change(node)
            }}
        >
            <Background variant="lines" color="rgba(255, 255, 255, 0.1)" gap={16} />
        </ReactFlow>
    }


}
