export default function() {
    this.setState({ loading: true })

    const passwordResetToken = this.passwordResetToken
    const userId = this.userId

    fetch(scanmetrix.backendURL + "/user/password-reset-verification", {
        method: "POST",
        body: JSON.stringify({
            passwordResetToken,
            userId,
            newPassword: this.state.password,
            newPasswordRepeat: this.state.passwordRepeat
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(result => result.json()).then(result => {
        const status = result.status

        if(status === "ERROR") {
            this.setState({ loading: false, blurMode: "error", error: result.error })
        } else if(status === "VERIFICATION_SUCCESSFUL") {
            this.setState({ loading: false, blurMode: "password-reset-verification", password: null, passwordRepeat: null })
        }
    })
}
