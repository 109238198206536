import React, { Component } from 'react'
import { Modal, Input, Grid, GridElement, Select } from 'scanmetrix-components'
import i18next from "i18next";

export default class extends Component {
    state = { extensionOption: null }

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={extensionOption => this.setState({ extensionOption })}>
            <Modal.Head title={i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.title")} icon="history" />
            <Modal.Body padding={24}>
                {this.state.extensionOption && <Input.MutationProvider value={{
                    name: "updateContractExtensionOption",
                    id: this.state.extensionOption.id,
                    data: this.state.extensionOption,
                    onSave: () => this.props.refresh()
                }}>
                    <Grid gap="16px" columns={[ "1fr", "1fr" ]}>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input disabled={scanmetrix.checkPermission("ContractExtensionOption") < 3} required date field="date" icon="calendar" label={i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.extensionFrom")} adjustWidth />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input disabled={scanmetrix.checkPermission("ContractExtensionOption") < 3} date field="deadline" icon="calendar-exclamation" label={i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.utilizationLatest")} adjustWidth />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input disabled={scanmetrix.checkPermission("ContractExtensionOption") < 3} required int field="intervalAmount" icon="tally" label={i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.durationNumber")} adjustWidth />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Select readOnly={scanmetrix.checkPermission("ContractExtensionOption") < 3} required field="intervalType" icon="calendar-week" label={i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.durationUnit")} adjustWidth items={[
                                { key: "days", title: i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.durationUnitTypes.days") },
                                { key: "weeks", title: i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.durationUnitTypes.weeks") },
                                { key: "months", title: i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.durationUnitTypes.months") },
                                { key: "years", title: i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.durationUnitTypes.years") },
                            ]} />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={3}>
                            <Input disabled={scanmetrix.checkPermission("ContractExtensionOption") < 3} textArea adjustWidth label={i18next.t("page.private.contract.modals.updateContractExtensionOptionModal.notes")} icon="sticky-note" field="notes" />
                        </GridElement>
                    </Grid>
                </Input.MutationProvider>}
            </Modal.Body>
        </Modal>
    }
}
