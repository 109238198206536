import React, { Component } from "react"
import { Modal, Input, GridElement, Grid } from "scanmetrix-components"
import i18next from "i18next"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/theme-xcode"
import "ace-builds/src-noconflict/ext-language_tools"

export default class extends Component {
    state = { id: null, buildingInspectionReportTemplate: null, content: null, loading: false }

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
    }

    onChange() {
        if(this.saveCache) clearTimeout(this.saveCache)

        this.saveCache = setTimeout(() => {
            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($id: ID!, $content: String) {
                        updateBuildingInspectionReportTemplate(id: $id, content: $content)
                    }
                `,
                variables: {
                    id: this.state.id,
                    content: this.state.content
                }
            }).then(data => {
                if(data.data.updateBuildingInspectionReportTemplate) this.props.refresh()
            })

            this.saveCache = null
        }, 300)
    }

    render() {
        return <Modal minWidth="768px" instance={this.props.instance} initialize={buildingInspectionReportTemplate => this.setState({ id: buildingInspectionReportTemplate.id, content: buildingInspectionReportTemplate.content, buildingInspectionReportTemplate, loading: false })}>
            <Modal.Head title={i18next.t("page.private.buildingInspection.reportTemplates.updateBuildingInspectionsReportTemplateModal.title")} icon="file-pdf" />
            <Modal.Body padding={24}>
                <Input.MutationProvider value={{
                    name: i18next.t("page.private.buildingInspection.reportTemplates.updateBuildingInspectionsReportTemplateModal.name"),
                    id: this.state.id,
                    data: this.state.buildingInspectionReportTemplate,
                    onSave: () => this.props.refresh()
                }}>
                    <Grid gap="16px">
                        <GridElement>
                            <Input field="name" required icon="tag" label="Name" adjustWidth />
                        </GridElement>
                        <GridElement>
                            <AceEditor
                                placeholder={i18next.t("page.private.buildingInspection.reportTemplates.updateBuildingInspectionsReportTemplateModal.searchPlaceholder")}
                                mode="html"
                                theme="xcode"
                                value={this.state.content || ""}
                                onChange={content => this.setState({ content }, this.onChange)}
                                name="updateBuildingInspectionReportContent"
                                editorProps={{ $blockScrolling: true }}
                                style={{ width: "100%" }}
                            />
                        </GridElement>
                    </Grid>
                </Input.MutationProvider>
            </Modal.Body>
        </Modal>
    }
}
