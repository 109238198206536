import React, { Component } from "react"
import { Modal, Button, Select } from "scanmetrix-components"
import { v4 as uuid } from "uuid"
import i18next from "i18next";

export default class extends Component {
    state = { workflowId: null, loading: false }

    constructor(props) {
        super(props)
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ workflowId: null, loading: false })

            this.file = null
        }}>
            <Modal.Head title={i18next.t("page.private.workFlow.createWorkflowNodeModal.title")} icon="project-diagram" />
            <Modal.Body adjustHeight="328px" padding={24}>
                <Select
                    label="Workflow"
                    icon="project-diagram"
                    value={this.state.workflowId}
                    required
                    noUnselect
                    adjustWidth
                    onChange={workflowId => this.setState({ workflowId })}
                    items={this.props.workflows.map(workflow => ({ key: workflow.id, title: workflow.name }))}
                />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.workflowId} title={i18next.t("page.private.workFlow.createWorkflowNodeModal.exitButton")} icon="project-diagram" onClick={() => {
                this.props.addNode({
                    id: uuid(),
                    data: { workflowId: this.state.workflowId },
                    type: "workflow",
                    position: { x: 0, y: 0 }
                })

                instance.close()
            }} />} />
        </Modal>
    }
}
