import styled from "styled-components"

export default styled.div`
  width: fit-content;
  margin: 5px 10px 5px 0;
  padding: 5px 10px;
  border-radius: 125px;
  border: 1px solid rgba(0, 0, 0, .4);
  transition: all 250ms ease;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`