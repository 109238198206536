import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import countries from "i18n-iso-countries"
import i18next from "i18next"

countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

export default class extends Component {
    state = { name: null, type: null, addressStreetName: null, addressStreetNumber: null, addressPostalCode: null, addressCity: null, addressCountry: null, addressAdditional: null, loading: false }

    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ name: null, type: null, addressStreetName: null, addressStreetNumber: null, addressPostalCode: null, addressCity: null, addressCountry: null, addressAdditional: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.titleForm")} icon="house-user" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Modal.Divider title={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.generalInformation")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                        <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.name")} adjustWidth />
                        <Select required adjustWidth value={this.state.type} onChange={type => this.setState({ type })} icon="house" label={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.type")} items={[
                            { key: "landlord", title: i18next.t("page.private.facilityManagement.landlord.createCustodianModal.typeTypes.landlord") },
                            { key: "administrative", title: i18next.t("page.private.facilityManagement.landlord.createCustodianModal.typeTypes.administrative") }
                        ]} />
                    </div>
                    <Modal.Divider title={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.address")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "2fr 0.5fr" }}>
                        <Input required value={this.state.addressStreetName} onChange={addressStreetName => this.setState({ addressStreetName })} icon="road" label={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.street")} adjustWidth />
                        <Input required value={this.state.addressStreetNumber} onChange={addressStreetNumber => this.setState({ addressStreetNumber })} icon="bell" label={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.houseNumber")} adjustWidth />
                    </div>
                    <Input value={this.state.addressAdditional} onChange={addressAdditional => this.setState({ addressAdditional })} icon="font" label={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.additionalAddress")} adjustWidth />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 2fr" }}>
                        <Input required value={this.state.addressPostalCode} onChange={addressPostalCode => this.setState({ addressPostalCode })} icon="mailbox" label={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.postalCode")} adjustWidth />
                        <Input required value={this.state.addressCity} onChange={addressCity => this.setState({ addressCity })} icon="city" label={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.city")} adjustWidth />
                    </div>
                    <Select upwards required adjustWidth value={this.state.addressCountry} onChange={addressCountry => this.setState({ addressCountry })} icon="globe-europe" label={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.country")} items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
               </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.type || !this.state.addressStreetName || !this.state.addressStreetNumber || !this.state.addressPostalCode || !this.state.addressCity || !this.state.addressCountry} title={i18next.t("page.private.facilityManagement.landlord.createCustodianModal.exitButton")} icon="house-user" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $type: String!, $addressStreetName: String!, $addressStreetNumber: String!, $addressAdditional: String, $addressPostalCode: String!, $addressCity: String!, $addressCountry: String!) {
                            createCustodian(name: $name, type: $type, addressStreetName: $addressStreetName, addressStreetNumber: $addressStreetNumber, addressAdditional: $addressAdditional, addressPostalCode: $addressPostalCode, addressCity: $addressCity, addressCountry: $addressCountry)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        type: this.state.type,
                        addressStreetName: this.state.addressStreetName,
                        addressStreetNumber: this.state.addressStreetNumber,
                        addressAdditional: this.state.addressAdditional,
                        addressPostalCode: this.state.addressPostalCode,
                        addressCity: this.state.addressCity,
                        addressCountry: this.state.addressCountry
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createCustodian) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
