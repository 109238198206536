import React, { Component } from 'react'
import { Button, Modal, Input, Select, Grid, GridElement } from 'scanmetrix-components'


export default class extends Component {
    state = { loading: false }

    constructor(props) {
        super(props)

        this.state = this.getCleanState()
        this.state.drivers = []

        this.fetch = this.fetch.bind(this)
        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($carId: ID!) {
                    CarDrivers(filter: { carId_eq: $carId }) {
                        nodes {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            `,
            variables: {
                carId: this.props.carId
            }
        }).then(data => {
            this.setState({ drivers: data.data.CarDrivers.nodes })
        })
    }

    getCleanState = () => ({
        loading: false,
        startMileage: null,
        endMileage: null,
        type: null,
        carDriverId: null,
        date: null,
        route: null,
        reason: null
    })

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={() => this.setState(this.getCleanState())}>
            <Modal.Head title="Fahrtenbucheintrag anlegen" icon="book" />
            <Modal.Body padding={24}>
                <Grid columns={[ "1fr", "1fr" ]} gap="16px">
                    <GridElement columnStart={1} columnEnd={2}>
                        <Select required adjustWidth value={this.state.carDriverId} onChange={carDriverId => this.setState({ carDriverId })} label='Fahrer' icon='user' items={this.state.drivers.map(driver => ({
                            key: driver.id,
                            title: `${driver.firstName} ${driver.lastName}`
                        }))} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Select required adjustWidth label='Typ' icon='text' onChange={type => this.setState({ type })} value={this.state.type} items={[
                            { key: "company", title: "Anfahrt Firma" },
                            { key: "private", title: "Heimweg Privatanschrift" },
                            { key: "customer", title: "Anfahrt Kunde" },
                        ]} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input required adjustWidth value={this.state.date} onChange={date => this.setState({ date })} label='Datum' icon='calendar-day' date />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input adjustWidth label='Routenbezeichnung' icon='road' value={this.state.route} onChange={route => this.setState({ route })} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input required adjustWidth label='Kilometerstand (Start)' icon='car' value={this.state.startMileage} onChange={startMileage => this.setState({ startMileage })} int />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input required adjustWidth label='Kilometerstand (Ende)' icon='car-garage' value={this.state.endMileage} onChange={endMileage => this.setState({ endMileage })} int />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={3}>
                        <Input adjustWidth label='Grund / Bemerkung' icon='question-circle' value={this.state.reason} onChange={reason => this.setState({ reason })} />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={!this.state.carDriverId || !this.state.type || !this.state.date || this.state.startMileage === null || this.state.endMileage === null} loading={this.state.loading} thick title="Fahrtenbucheintrag anlegen" icon="book" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($carId: ID!, $carDriverId: ID!, $date: DateTime!, $startMileage: Int!, $endMileage: Int!, $type: String!, $reason: String, $route: String) {
                            createCarLogbook(carId: $carId, carDriverId: $carDriverId, date: $date, startMileage: $startMileage, endMileage: $endMileage, type: $type, reason: $reason, route: $route)
                        }
                    `,
                    variables: {
                        ...this.state,
                        date: moment(this.state.date, "DD.MM.YYYY").toDate(),
                        carId: this.props.carId
                    }
                }).then(result => {
                    if(result.data.createCarLogbook) {
                        instance.close()
                        this.props.refresh()
                    }
                })
            }} />} />
        </Modal>
    }
}
