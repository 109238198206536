import React, { Component } from 'react'
import { Button, Modal, Input, Select } from 'scanmetrix-components'
import styled from "styled-components"

const InputView = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;
  
  .row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
  }
`

export default class extends Component {
    state = {
        seasonType: null,
        type: null,
        location: null,
        size: null,
        amount: null,
        loading: false
    }

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={() => this.setState({ seasonType: null,
            type: null,
            location: null,
            size: null,
            amount: null,
            loading: false })}>
            <Modal.Head title="Reifen anlegen" icon="tire" />
            <Modal.Body padding={24}>
                <InputView>
                    <Select value={this.state.seasonType} onChange={seasonType => this.setState({ seasonType })} required adjustWidth label="Saisontyp" icon="sun-cloud" items={[
                        { key: 'allSeason', title: 'Allwetterreifen' },
                        { key: 'summer', title: 'Sommerreifen' },
                        { key: 'winter', title: 'Winterreifen' }
                    ]} />
                    <Input value={this.state.type} onChange={type => this.setState({ type })} required adjustWidth label="Reifentyp Bezeichnung" icon="exclamation" />
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <Input value={this.state.location} onChange={location => this.setState({ location })} required adjustWidth label="Ablageort (z.B. am Fahrzeug, Ersatzrad, auf Lager)" icon="search" />
                    </div>
                    <Input value={this.state.amount} onChange={amount => this.setState({ amount })} int required adjustWidth label="Anzahl (i.d.R. 4)" icon="list-ol" />
                    <Input value={this.state.size} onChange={size => this.setState({ size })} float adjustWidth label="Größe (in Zoll)" icon="tire" />
                </InputView>
            </Modal.Body>
            <Modal.Footer loading={this.state.loading} buttons={instance => <Button disabled={!this.state.seasonType || !this.state.type || !this.state.location || !this.state.amount} thick title="Reifen anlegen" icon="tire" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($carId: ID!, $seasonType: String!, $type: String!, $location: String!, $size: Float, $amount: Int!) {
                            createCarTire(carId: $carId, seasonType: $seasonType, type: $type, location: $location, size: $size, amount: $amount)
                        }
                    `,
                    variables: {
                        ...this.state,
                        carId: this.props.carId
                    }
                }).then(result => {
                    if(result.data.createCarTire) {
                        instance.close()
                        this.props.refresh()
                    }
                })
            }} />} />
        </Modal>
    }
}