import React, { Component } from "react"

import { Modal, Button, Select, Input, Grid, GridElement } from "scanmetrix-components"

import i18next from "i18next";

export default class extends Component {
    state = { date: null, deadline: null, intervalAmount: null, intervalType: null, notes: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ date: null, deadline: null, intervalAmount: null, intervalType: null, notes: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.contract.modals.createContractExtensionOptionModal.title")} icon="history" />
            <Modal.Body padding={24}>
                <Grid gap="16px" columns={[ "1fr", "1fr" ]}>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input required date value={this.state.date} onChange={date => this.setState({ date })} icon="calendar" label={i18next.t("page.private.contract.modals.createContractExtensionOptionModal.extensionFrom")} adjustWidth />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input date value={this.state.deadline} onChange={deadline => this.setState({ deadline })} icon="calendar-exclamation" label={i18next.t("page.private.contract.modals.createContractExtensionOptionModal.utilizationLatest")} adjustWidth />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input required int value={this.state.intervalAmount} onChange={intervalAmount => this.setState({ intervalAmount })} icon="tally" label={i18next.t("page.private.contract.modals.createContractExtensionOptionModal.durationNumber")} adjustWidth />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Select required value={this.state.intervalType} onChange={intervalType => this.setState({ intervalType })} icon="calendar-week" label={i18next.t("page.private.contract.modals.createContractExtensionOptionModal.durationUnit")} adjustWidth items={[
                            { key: "days", title: i18next.t("page.private.contract.modals.createContractExtensionOptionModal.durationUnitTypes.days") },
                            { key: "weeks", title: i18next.t("page.private.contract.modals.createContractExtensionOptionModal.durationUnitTypes.weeks") },
                            { key: "months", title: i18next.t("page.private.contract.modals.createContractExtensionOptionModal.durationUnitTypes.months") },
                            { key: "years", title: i18next.t("page.private.contract.modals.createContractExtensionOptionModal.durationUnitTypes.years") },
                        ]} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={3}>
                        <Input textArea adjustWidth label={i18next.t("page.private.contract.modals.createContractExtensionOptionModal.notes")} icon="sticky-note" value={this.state.notes} onChange={notes => this.setState({ notes })} />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.contract.modals.createContractExtensionOptionModal.exitButton")} disabled={!this.state.date || !this.state.intervalAmount || !this.state.intervalType} icon="history" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($contractId: ID!, $date: DateTime!, $deadline: DateTime, $notes: String, $intervalAmount: Int!, $intervalType: String!) {
                            createContractExtensionOption(contractId: $contractId, date: $date, deadline: $deadline, notes: $notes, intervalAmount: $intervalAmount, intervalType: $intervalType)
                        }
                    `,
                    variables: {
                        contractId: this.props.contractId,
                        date: moment(this.state.date, "DD.MM.YYYY").toDate(),
                        deadline: this.state.deadline ? moment(this.state.deadline, "DD.MM.YYYY").toDate() : null,
                        notes: this.state.notes,
                        intervalAmount: this.state.intervalAmount,
                        intervalType: this.state.intervalType
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createContractExtensionOption) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
