import React, { Component } from "react"
import { GridElement, Table, Button } from "scanmetrix-components"
import CreateCarLogbookModal from "./modals/CreateCarLogbookModal"
import DeleteCarLogbookModal from "./modals/DeleteCarLogbookModal"
import UpdateCarLogbookModal from "./modals/UpdateCarLogbookModal"


export default class extends Component {
    state = { logbooks: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($carId: ID!) {
                    CarLogbooks(filter: { carId_eq: $carId }) {
                        nodes {
                            id
                            date
                            startMileage
                            endMileage
                            drivenKilometers
                            type
                            reason
                            route
                            carDriver {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
            `,
            variables: {
                carId: this.props.car.id
            }
        }).then(data => {
            this.setState({ logbooks: data.data.CarLogbooks.nodes })
        })
    }

    render() {
        return <>
            <CreateCarLogbookModal instance={ref => this.createCarLogbookModal = ref} carId={this.props.car.id} refresh={() => this.fetch()} />
            <DeleteCarLogbookModal instance={ref => this.deleteCarLogbookModal = ref} refresh={() => this.fetch()} />
            <UpdateCarLogbookModal instance={ref => this.updateCarLogbookModal = ref} carId={this.props.car.id} refresh={() => this.fetch()} />
            <GridElement styled title="Fahrtenbuch" icon="book" rightContent={<Button thick title='Neuen Fahrtenbucheintrag anlegen' icon='book' onClick={() => this.createCarLogbookModal.open()} />}>
                <Table
                    head={[
                        { title: "Fahrer", width: "12.5%", column: "driver" },
                        { title: "Datum", width: "10%", column: "formattedDate" },
                        { title: "Kilom. (Start)", width: "10%", column: "formattedStartMileage" },
                        { title: "Kilom. (Ende)", width: "10%", column: "formattedEndMileage" },
                        { title: "Distanz", width: "10%", column: "formattedDrivenKilometers" },
                        { title: "Typ", width: "12.5%", column: "formattedType" },
                        { title: "Grund", width: "20%", column: "reason" },
                        { title: "Route", width: "15%", column: "route" },
                    ]}
                    contextItems={[
                        { title: "Eintrag löschen", icon: "trash", onClick: item =>  this.deleteCarLogbookModal.open(item.id)}
                    ]}
                    onItemClick={item => this.updateCarLogbookModal.open(item)}
                    items={this.state.logbooks ? this.state.logbooks.map(logbook => ({
                        ...logbook,
                        formattedType: logbook.type === "private" ? "Heimweg Privatanschrift" : (logbook.type === "company" ? "Anfahrt Firma" : "Anfahrt Kunde"),
                        driver: `${logbook.carDriver.firstName} ${logbook.carDriver.lastName}`,
                        formattedDate: moment(logbook.date).format("DD.MM.YYYY"),
                        formattedStartMileage: logbook.startMileage + ' km',
                        formattedEndMileage: logbook.endMileage + ' km',
                        formattedDrivenKilometers: logbook.drivenKilometers + ' km',
                    })) : null}
                />
            </GridElement>
        </>
    }
}
