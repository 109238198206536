import React, { Component } from "react"
import { Page, Grid, GridElement, Input, Button, Breadcrumbs, Table, Modal } from "scanmetrix-components"
import styled from "styled-components"
import { Document, Page as PDFPage, pdfjs } from "react-pdf"
import { MoonLoader as LoadingAnimation } from 'react-spinners'

import { getIntervalType } from "../object/"
import ChecklistForm from "./ChecklistForm"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
import i18next from "i18next";

const FullSizeImage = styled.img`
    max-width: 768px;
    max-height: 768px;
    object-fit: cover;
    display: block;
`

class InspectionModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            {inspection => <>
                <Modal.Head title={i18next.t("page.private.maintenance.viewTest")} icon="clipboard-list-check" />
                <Modal.Body>
                    {inspection && <ChecklistForm data={inspection.data ? JSON.parse(inspection.data) : null} checklist={inspection.inspection.checklist ? (JSON.parse(inspection.inspection.checklist)) : null} />}
                </Modal.Body>
            </>}
        </Modal>
    }
}

class PhotoModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            {context => <>
                <Modal.Head title={i18next.t("page.private.maintenance.viewImage")} icon="images" />
                <Modal.Body>
                    <FullSizeImage src={context} />
                </Modal.Body>
            </>}
        </Modal>
    }
}

const renderIcon = type => {
    let icon = "question"

    if(type === "structural") icon = "construction"
    if(type === "organisational") icon = "project-diagram"
    if(type === "objectional") icon = "folder-tree"

    return icon
}

const getType = type => {
    if(type === "structural") return "Baulich"
    if(type === "organisational") return "Organisatorisch"
    if(type === "objectional") return "Anlagentechnisch"
}

const StyledDocument = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.loading ? "48px 0" : 0};

    .react-pdf__Document {
        cursor: pointer;
        user-select: none;
        user-drag: none;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.75;
        }
    }
`

const Image = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.75;
    }
`

const SingleAmount = styled.div`
    background: ${props => props.light ? "white" : (props.green ? "linear-gradient(45deg,#16a085,#27dbb8)" : (props.red ? "linear-gradient(45deg,#c0392b,#ff6252)" : "linear-gradient(45deg,#7d7d7d,#9e9e9e)"))};
    border-radius: 5px;
    grid-column-start: ${props => props.columnStart};
    grid-column-end: ${props => props.columnEnd};
    padding: 16px;
    box-sizing: border-box;
    color: ${props => props.light ? "#000" : "white"};
    box-shadow: 0 8px 24px -16px rgba(0, 0, 0, 0.4);
    display: ${props => props.flex ? "flex" : "block"};
    align-items: center;
    cursor: ${props => props.active ? "pointer" : "default"};
    transition: opacity 0.3s;
    user-select: none;

    &:hover {
        opacity: ${props => props.active ? 0.75 : 1};
    }

    .value {
        font-size: 2.2em;
        color: ${props => props.light ? "#869ead" : "white"};
        margin-bottom: ${props => props.flex ? 0 : "8px"};
        margin-right: ${props => props.flex ? "16px" : 0};

        b {
            color: ${props => props.light ? "#647A88" : "rgba(255, 255, 255, 0.75)"};
            font-weight: bold;
        }
    }

    .title {
        font-size: 1.3em;
        opacity: ${props => props.light ? 0.75 : 1};

        b {
            font-size: 0.8em;
            font-weight: normal;
            opacity: 0.75;
        }
    }
`

const LoadingContainer = styled.div`
    padding: 64px 0;
`

class Loading extends Component {
    render() {
        return <LoadingContainer>
            <LoadingAnimation sizeUnit="px" size={64} color="#3b97d3" />
        </LoadingContainer>
    }
}

class PDFModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            <Modal.Head title={i18next.t("page.private.maintenance.frameData.maintenanceLogPdf")} icon="file-pdf" />
            <Modal.Body>
                <StyledDocument>
                    <Document options={{ withCredentials: true }} onClick={() => window.open(scanmetrix.backendURL + "/maintenance/pdf/" + this.props.match.params.serviceproviderid + "/" + this.props.match.params.maintenanceid, "_blank")} onLoadSuccess={() => this.setState({ loading: false })} loading={<Loading />} file={scanmetrix.backendURL + "/maintenance/pdf/" + this.props.match.params.serviceproviderid + "/" + this.props.match.params.maintenanceid}>
                        <PDFPage pageNumber={1}></PDFPage>
                    </Document>
                </StyledDocument>
            </Modal.Body>
        </Modal>
    }
}


class FlawsModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            <Modal.Head title={i18next.t("page.private.maintenance.defectsRepairedMaintenance.title")} icon="bolt" />
            <Modal.Body>
                <Table
                    head={[
                        { title: i18next.t("page.private.maintenance.defectsRepairedMaintenance.defects"), width: "17.5%", column: "name" },
                        { title: i18next.t("page.private.maintenance.defectsRepairedMaintenance.description"), width: "25%", column: "description" },
                        { title: i18next.t("page.private.maintenance.defectsRepairedMaintenance.recorded"), width: "10%", column: "createdAt" },
                        { title: i18next.t("page.private.maintenance.defectsRepairedMaintenance.priority"), width: "10%", column: "priority" },
                        { title: i18next.t("page.private.maintenance.defectsRepairedMaintenance.deadline"), width: "10%", column: "deadline" },
                        { title: i18next.t("page.private.maintenance.defectsRepairedMaintenance.type"), width: "17.5%", column: "type" },
                        { title: i18next.t("page.private.maintenance.defectsRepairedMaintenance.condition"), width: "10%", column: "state" }
                    ]}
                    onItemClick={flaw => this.props.history.push(`/flaw/${this.props.match.params.serviceproviderid}/${flaw.id}`)}
                    items={this.props.flaws.map(flaw => ({
                        id: flaw.id,
                        name: flaw.name,
                        description: flaw.description,
                        deadline: flaw.deadline,
                        createdAt: flaw.maintenance.date,
                        priority: <><i className={`fas fa-${flaw.priority === 1 ? "check-square green" : (flaw.priority === 2 ? "exclamation-square orange" : "times-square red")}`} />{flaw.priority === 1 ? i18next.t("page.private.maintenance.low") : (flaw.priority === 2 ? i18next.t("page.private.maintenance.medium") : i18next.t("page.private.maintenance.high"))}</>,
                        state: <><i className={`far fa-${flaw.fixedInMaintenance ? "check-circle green" : "times-circle red"}`} />{flaw.fixedInMaintenance ? i18next.t("page.private.maintenance.fixed") : i18next.t("page.private.maintenance.open")}</>,
                        type: <><i className={`far fa-${renderIcon(flaw.type)}`} />{getType(flaw.type)}</>
                    }))}
                />
            </Modal.Body>
        </Modal>
    }
}

export default class extends Component {
    state = { maintenance: null }

    constructor(props) {
        super(props)

        /*scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    maintenance(id: $id) {
                        id
                        date
                        notes
                        operative
                        user {
                            firstName
                            lastName
                        }
                        images {
                            imageURL
                        }
                        flaws {
                            id
                            name
                            description
                            type
                            priority
                            deadline
                            customerObjectComponent {
                                id
                            }
                            images {
                                id
                                imageURL
                            }
                            maintenance {
                                id
                                date
                            }
                            fixedInMaintenance {
                                id
                            }
                        }
                        fixedFlaws {
                            id
                            name
                            description
                            type
                            priority
                            deadline
                            customerObjectComponent {
                                id
                            }
                            images {
                                id
                                imageURL
                            }
                            maintenance {
                                id
                                date
                            }
                            fixedInMaintenance {
                                id
                            }
                        }
                        inspections {
                            date
                            customerObjectComponent {
                                id
                                component {
                                    name
                                }
                            }
                            inspection {
                                name
                                intervalAmount
                                intervalType
                            }
                        }
                        customerObject {
                            id
                            inheritInterval
                            intervalAmount
                            intervalType
                            smid {
                                id
                            }
                            customerSubsidiaryFacility {
                                id
                                name
                                intervalType
                                intervalAmount
                                customerSubsidiary {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.maintenanceid
            }
        }).then(data => {
            this.setState({ maintenance: data.data.maintenance })
        })*/

        fetch(scanmetrix.backendURL + "/maintenance/" + this.props.match.params.serviceproviderid + "/" + this.props.match.params.maintenanceid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            console.log(data)
            this.setState({ maintenance: data })
        })
    }

    render() {
        if(!this.state.maintenance) return null
        const maintenance = this.state.maintenance

        return <Page {...this.props}>
            <PDFModal {...this.props} maintenance={maintenance} instance={instance => this.pdfModal = instance} />
            <PhotoModal instance={instance => this.photoModal = instance} />
            <InspectionModal instance={instance => this.inspectionModal = instance} />
            <FlawsModal match={this.props.match} history={this.props.history} instance={instance => this.flawsModal = instance} flaws={maintenance.fixedFlaws} />
            <Breadcrumbs values={[
                {
                    icon: "building",
                    title: i18next.t("page.private.maintenance.facilityManagement"),
                    link: "/facilitymanagement"
                },
                {
                    icon: "map-marker",
                    title: `${maintenance.customerObject.customerSubsidiaryFacility.customerSubsidiary.name} (${maintenance.customerObject.customerSubsidiaryFacility.customerSubsidiary.label})`,
                    link: `/subsidiary/${maintenance.customerObject.customerSubsidiaryFacility.customerSubsidiary.id}`
                },
                {
                    icon: "folder-tree",
                    title: maintenance.customerObject.customerSubsidiaryFacility.name,
                    link: `/facility/${this.props.match.params.serviceproviderid}/${maintenance.customerObject.customerSubsidiaryFacility.id}`
                },
                {
                    icon: "qrcode",
                    title: maintenance.customerObject.smid.id,
                    link: `/object/${this.props.match.params.serviceproviderid}/${maintenance.customerObject.id}`
                },
                {
                    icon: "toolbox",
                    title: `${i18next.t("page.private.maintenance.maintenanceFrom")} ${maintenance.date}`
                }
            ]} />
            <Grid gap="32px" columns={[ "1fr", "4fr" ]}>
                <GridElement columnStart={1} columnEnd={2}>
                    <Grid gap="32px" columns={[ "1fr" ]}>
                        <SingleAmount flex green={!!maintenance.operative} red={maintenance.operative === false}>
                            <p className="value"><i className={`far fa-${!!maintenance.operative ? "check" : (maintenance.operative === false ? "times" : "question")}-circle`} /></p>
                            <p className="title"><b>{i18next.t("page.private.maintenance.result")}</b> {!!maintenance.operative ? "Funktionsbereit" : (maintenance.operative === false ? "Außer Betrieb" : "Keine Angabe")}</p>
                        </SingleAmount>
                        <SingleAmount light>
                            <p className="value"><b>{maintenance.flaws.length}</b></p>
                            <p className="title">{i18next.t("page.private.maintenance.defectsDetected")}</p>
                        </SingleAmount>
                        <SingleAmount light active onClick={() => this.flawsModal.open()}>
                            <p className="value"><b>{maintenance.fixedFlaws.length}</b></p>
                            <p className="title">{i18next.t("page.private.maintenance.defectsRepaired")}</p>
                        </SingleAmount>
                        <GridElement styled title={i18next.t("page.private.maintenance.maintenancePicture")} icon="images">
                            <div style={{display: "flex", flexDirection: "column", gap: 16, overflowY: "scroll", maxHeight: 700, padding: 16 }}>
                                {maintenance.images.map((image, index) => {
                                    const url = `${scanmetrix.backendURL}/maintenance-image/${this.props.match.params.serviceproviderid}/${image.id}`

                                    return <Image key={index} src={url} onClick={() => this.photoModal.open(url)}/>
                                })}
                            </div>
                        </GridElement>
                    </Grid>
                </GridElement>
                <GridElement columnStart={2} columnEnd={3}>
                    <Grid gap="32px" columns={["1fr"]}>
                        <GridElement styled title={i18next.t("page.private.maintenance.frameData.title")}
                                     icon="database" rightContent={<Button thick icon="file-pdf"
                                                                           title={i18next.t("page.private.maintenance.frameData.maintenanceLog")}
                                                                           onClick={() => this.pdfModal.open()}/>}>
                            <Grid gap="32px" padding="32px" columns={["1fr", "1fr", "1fr", "1fr" ]}>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input readOnly adjustWidth label={i18next.t("page.private.maintenance.frameData.date")} value={moment(maintenance.date, "DD.MM.YYYY").format("dddd, DD. MMMM YYYY")} icon="hourglass-start" />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input readOnly adjustWidth label={i18next.t("page.private.maintenance.frameData.interval")} value={maintenance.customerObject.inheritInterval ? (maintenance.customerObject.customerSubsidiaryFacility.intervalAmount ? `${maintenance.customerObject.customerSubsidiaryFacility.intervalAmount} ${getIntervalType(maintenance.customerObject.customerSubsidiaryFacility.intervalType)}` : null) : (maintenance.customerObject.intervalAmount ? `${maintenance.customerObject.intervalAmount} ${getIntervalType(maintenance.customerObject.intervalType)}` : null)} icon="history" />
                                </GridElement>
                                <GridElement columnStart={3} columnEnd={4}>
                                    <Input readOnly adjustWidth label={i18next.t("page.private.maintenance.frameData.technician")} value={`${maintenance.user.firstName} ${maintenance.user.lastName}`} icon="user" />
                                </GridElement>
                                <GridElement columnStart={4} columnEnd={5}>
                                    <Input readOnly adjustWidth label={i18next.t("page.private.maintenance.frameData.serviceProvider")} value="scanmetrix.fm GmbH" icon="user-tie" />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={5}>
                                    <Input readOnly adjustWidth label={i18next.t("page.private.maintenance.frameData.notes")} value={maintenance.notes} icon="font" />
                                </GridElement>
                            </Grid>
                        </GridElement>
                        <GridElement styled title={i18next.t("page.private.maintenance.tests.title")} icon="clipboard-list-check">
                            <Table
                                head={[
                                    { title: i18next.t("page.private.maintenance.tests.smid"), width: "20%", column: "smid" },
                                    { title: i18next.t("page.private.maintenance.tests.component"), width: "20%", column: "component" },
                                    { title: i18next.t("page.private.maintenance.tests.test"), width: "50%", column: "inspection" },
                                    { title: i18next.t("page.private.maintenance.tests.date"), width: "10%", column: "date" }
                                ]}
                                onItemClick={inspection => {
                                    this.inspectionModal.open(inspection.inspectionData)
                                }}
                                items={maintenance.inspections.map(inspection => ({
                                    smid: <><i className="far fa-qrcode" />{`${maintenance.customerObject.smid.id}-${inspection.customerObjectComponent.id}`}</>,
                                    inspection: inspection.inspection.name,
                                    component: inspection.customerObjectComponent.component.name,
                                    date: inspection.date,
                                    inspectionData: inspection
                                }))}
                            />
                        </GridElement>
                        <GridElement styled title={i18next.t("page.private.maintenance.defectsMaintenance.title")} icon="bolt">
                            <Table
                                head={[
                                    { title: i18next.t("page.private.maintenance.defectsMaintenance.defects"), width: "17.5%", column: "name" },
                                    { title: i18next.t("page.private.maintenance.defectsMaintenance.description"), width: "25%", column: "description" },
                                    { title: i18next.t("page.private.maintenance.defectsMaintenance.recorded"), width: "10%", column: "createdAt" },
                                    { title: i18next.t("page.private.maintenance.defectsMaintenance.priority"), width: "10%", column: "priority" },
                                    { title: i18next.t("page.private.maintenance.defectsMaintenance.deadline"), width: "10%", column: "deadline" },
                                    { title: i18next.t("page.private.maintenance.defectsMaintenance.type"), width: "17.5%", column: "type" },
                                    { title: i18next.t("page.private.maintenance.defectsMaintenance.condition"), width: "10%", column: "state" }
                                ]}
                                onItemClick={flaw => this.props.history.push(`/flaw/${this.props.match.params.serviceproviderid}/${flaw.id}`)}
                                items={maintenance.flaws.map(flaw => ({
                                    id: flaw.id,
                                    name: flaw.name,
                                    description: flaw.description,
                                    deadline: flaw.deadline,
                                    createdAt: flaw.maintenance.date,
                                    priority: <><i className={`fas fa-${flaw.priority === 1 ? "check-square green" : (flaw.priority === 2 ? "exclamation-square orange" : "times-square red")}`} />{flaw.priority === 1 ? "Niedrig" : (flaw.priority === 2 ? "Mittel" : "Hoch")}</>,
                                    state: <><i className={`far fa-${flaw.fixedInMaintenance ? "check-circle green" : "times-circle red"}`} />{flaw.fixedInMaintenance ? "Behoben" : "Offen"}</>,
                                    type: <><i className={`far fa-${renderIcon(flaw.type)}`} />{getType(flaw.type)}</>
                                }))}
                            />
                        </GridElement>
                    </Grid>
                </GridElement>
            </Grid>
        </Page>
    }
}
