import React, { Component } from "react"
import { Modal, Input } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { id: null, subsidiaryContract: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={subsidiaryContract => this.setState({ id: subsidiaryContract.id, subsidiaryContract, loading: false })}>
            <Modal.Head title={i18next.t("page.private.contract.modals.updateSubsidiaryContractModal.title")} icon="map-marker" />
            <Modal.Body padding={24}>
                <Input.MutationProvider value={{
                    name: "updateSubsidiaryContract",
                    id: this.state.id,
                    data: this.state.subsidiaryContract,
                    onSave: () => this.props.refresh()
                }}>
                    <Input disabled={scanmetrix.checkPermission("SubsidiaryContract") < 3} field="costQuota" icon="percent" label={i18next.t("page.private.contract.modals.updateSubsidiaryContractModal.costQuota")} adjustWidth float format={val => (val !== undefined && val !== null) ? `${val} %` : ""} />
                </Input.MutationProvider>
            </Modal.Body>
        </Modal>
    }
}
