import React, { Component } from "react"
import styled, { keyframes } from "styled-components"
import { NavLink, matchPath, withRouter } from "react-router-dom"
import Avatar from "react-avatar"

import changelog from  "../changelog.json"
import i18next from "i18next"
import { Tooltip as ReactTooltip } from 'react-tooltip'
import semver from "semver"
import Cookies from "js-cookie";
import HashLoader from "react-spinners/HashLoader";

const StyledSmallNavigation = styled.div`
    height: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 0px 4px;
     background: white;
    position: absolute;
    right: 0;
    top: 0;
    width: 64px;
    z-index: 10;
    overflow: hidden;
    transition: all 0.3s;
  
    .grid {
      display: grid;
      height: min-content;
      grid-auto-flow: row;
    }
  
    p {
        opacity: 0;
      transition: all 0.3s;
    }
  
  i {
    width: 64px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  &:hover {
        width: 250px;
        overflow-y: auto;
        right: calc(-250px + 64px);
        
        p {
            opacity: 1;
        }
    }
  
    .link {
           cursor: pointer;
           user-select: none;
        width: 100%;
        min-height: 56px;
        height: 64px;
        max-height: 64px;
        display: flex;
        align-items: center;
      justify-content: flex-start;
        color: rgba(0, 0, 0, 0.5);
        white-space: nowrap;
        font-size: 20px;
        
        &:hover {
            background: rgba(0, 0, 0, 0.05);
          color: #3b97d3;
          
            i {
                color: #3b97d3;
            }
        }
      
        p {
            font-size: 16px;
        }
      
        &.selected {
            background: #3b97d3;
            color: white;
            font-weight: 600;
          
            i {
                color: white;
            }
        }
    }
`

const Navigation = styled.div`
  height: 100vh;
  width: 360px;
  left: ${props => props.showAlerts ? "328px" : 0};
  padding: 0 24px;
  padding-left: ${props => props.showAlerts ? "56px" : "24px"};
  padding-bottom: 50px;
  background: white;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  border-radius: 16px 0 0 16px;
  box-shadow: -16px 0 16px -12px #233444;
  user-select: none;
  align-items: center;
  transition: left 0.5s, padding-left 0.5s;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-y: scroll;
    
  &::-webkit-scrollbar {
    width: 5px;
  }
    
  &::-webkit-scrollbar-track {
    background: transparent;
  }
    
  &::-webkit-scrollbar-thumb {
    border-radius: 125px;
    background: rgba(0, 0, 0, .0625);
  }
    
  .scanmetrix {
    width: 60%;
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
    filter: grayscale(1);
    opacity: 0.25;
    -webkit-user-drag: none;
  }
    
  .logo {
    height: 80px;
    width: auto;
    max-width: 70%;
    object-fit: contain;
    margin: 0 auto 32px;
    flex-shrink: 0;
    -webkit-user-drag: none;
  }
    
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    box-shadow: -16px 0 16px -12px rgba(35, 52, 68, .25);
    padding: 32px 20px !important;
    left: ${props => props.showAlerts ? "calc(25vw - 70px)" : 0};
    
    .logo {
      width: auto;
      height: auto;
      max-height: 80px;
      margin: 0 !important;
      padding-bottom: 32px !important;
    }
  }
    
  @media screen and (min-width: 1280px) and (max-width: 2030px) {
    box-shadow: -16px 0 16px -12px rgba(35, 52, 68, .25);
    padding: 32px 20px !important;
    left: ${props => props.showAlerts ? "calc(20vw - 70px)" : 0};
    
    .logo {
      width: auto;
      height: auto;
      max-height: 80px;
      margin: 0 !important;
      padding-bottom: 32px !important;
    }
  }
    
  @media screen and (max-height: 1280px) {
    padding-top: 40px;
    
    .logo {
        margin: 0 auto 24px;
    }
  }
    
  @media screen and (max-height: 1152px) {
    padding-top: 24px;
    
    .logo {
        margin: 0 auto 12px;
    }
  }

  a {
    text-decoration: none;
  }
`

const StyledNavigationItem = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.05em;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  user-select: none;
  opacity: ${props => props.disabled ? 0.5 : 1};
  text-overflow: ellipsis;
  
  p.title {
      text-overflow: ellipsis;
      overflow: hidden;
  }
  
  @media screen and (max-width: 1600px) {
      a {
          padding-left: 0 !important;
          padding-right: 8px !important;
      }
  }
  
  .notifications {
    background: ${props => props.selected ? "white" : "#3B97D3"};
    border-radius: 24px;
    width: 24px;
    height: 24px;
    font-weight: bold;
    font-size: 0.7em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    color: ${props => props.selected ? "#3B97D3" : "white"};
  }
  
  .beta {
    margin-left: auto;
    color: ${props => props.selected ? "white" : "#3b97d3"};
    border-radius: 8px;
    font-size: 0.6em;
    z-index: 10;
  }

  &:hover {
    a {
      color: black;
    }
  }

  i {
    width: 48px;
    text-align: center;
    flex-shrink: 0;
  }
    
  a {
    display: flex;
    box-sizing: border-box;
    height: 40px;
    align-items: center;
    color: ${props => props.disabled ? "rgba(0, 0, 0, 0.5) !important" : (props.selected ? "white !important" : "rgba(0, 0, 0, 0.5)")};
    text-decoration: none;
    transition: color 0.3s, opacity 0.5s;
    background: ${props => props.selected ? "linear-gradient(30deg,#0f7abf,#58b9f9)" : "transparent"};
    box-shadow: ${props => props.selected ? "0 0 6px 0 #1a699c66" : "none"};
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
  }
  
`

const Blinking = keyframes`
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
`

const Left = styled.div`
  height: 100vh;
  width: 96px;
  flex-shrink: 0;
  background: #20242b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  user-select: none;
  
  .user {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 32px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    
    .avatarContainer {
      cursor: pointer;
      transition: opacity 0.3s;
      
      &:hover {
          opacity: 0.75;
      }
    }
    
    i {
      color: white;
      font-size: 1.4em;
      opacity: 0.75;
      cursor: pointer;
      transition: opacity 0.3s;
      
      &.disabled {
        opacity: 0.25 !important;
        pointer-events: none;
      }
      
      &:hover {
        opacity: 1;
      }
    }
    
    .avatar {
      width: 64px;
      height: 64px;
      border-radius: 100%;
      box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.75);
      -webkit-user-drag: none;
    }
  }
  
  .icon {
    width: 48px;
    height: 48px;
    margin-top: 24px;
    -webkit-user-drag: none;
  }
  
  .version {
    color: white;
    font-size: 12px;
    width: 100%;
    text-align: center;
    opacity: 0.5;
    margin-top: 8px;
  }
    
    .one {
        color: white;
        background: #3b97d3;
        padding: 4px;
        border-radius: 4px;
        justify-self: center;
        font-weight: bold;
        font-size: 12px;
        width: 100%;
        text-align: center;
        margin-top: 8px;
    }

  #SideItem{
    
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        .TipHoverDiv{
            display: block;
        }
    }
  }
`

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(96px + 64px);
  background: #191b20;
  z-index: 10;
  
  .mission {
      img {
          transition: transform 0.5s;
      }
    
      &:hover {
          img {
              transform: rotate(180deg);
          }
      }
  }
 
`

const PresentationMode = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 32px;
  user-select: none;
  display: flex;

  .recording {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      margin-right: 8px;
      background: #3B97D3;
      animation: ${Blinking} 0.4s linear infinite;
      animation-direction: alternate-reverse;
  }

  .text {
      font-size: 1.3em;
  }

  .subtext {
      font-size: 0.8em;
      opacity: 0.75;
      white-space: normal;
      max-width: 240px;
      text-align: center;
      margin-top: 4px;
      display: inline-block; 
  }

  .topText {
      display: inline-flex;
      align-items: center;
  }
`

const FeatureTooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 200px;
  text-align: center;
  line-height: 1.4em;
  font-size: 1.05em;
  user-select: none;
    
  .icon {
    font-size: 3em;
    margin-bottom: 16px;
    color: #2980b9;
  }
    
  button {
    outline: 0;
    border: none;
    height: 32px;
    padding: 0 16px;
    margin-top: 16px;
    color: #20242b;
    background: white;
    border-radius: 32px;
    cursor: pointer;
    transition: opacity 0.3s;
    box-shadow: 0 3px 8px -4px rgba(0, 0, 0, 0.4);
    
    &:hover {
      opacity: 0.75;
    }
    
    i {
      margin-right: 8px;
    }
    
    &.primary {
      background: #3B97D3;
      color: white;
    }
  }
`

class NavigationItem extends Component {
    state = { hovered: false, ref: null }

    componentWillReceiveProps(nextProps) {
        if(nextProps.showAlerts && this.state.hovered) this.setState({ hovered: false })
    }

    render() {
        const LinkElement = this.props.disabled ? (props => <a>{props.children}</a>) : NavLink
        return <>
            {this.state.ref && this.props.disabled && <ToolTip tooltipTimeout={100} group="navigation" parent={this.state.ref} active={this.state.hovered && !this.props.showAlerts} position="right" arrow="center" style={{ arrowStyle: { borderColor: false } }}>
                <FeatureTooltip>
                    <i className={`fad fa-${this.props.conversation ? "comments-alt" : (this.props.dataMissing ? "database" : "sad-tear")} icon`} />
                    {this.props.conversation ? "Das Ticket-System ist noch nicht verfügbar." : (this.props.dataMissing ? "Es fehlen noch Daten, um dieses Modul zu verwenden." : "Diese Funktion ist in Ihren gebuchten Modulen nicht enthalten.")}
                    {!this.props.dataMissing && !this.props.conversation && <button className="primary" onClick={() => window.open("https://scanmetrix.app/cockpit", "_blank")}><i className="far fa-box-open" />Mehr erfahren</button>}
                </FeatureTooltip>
            </ToolTip>}
            <StyledNavigationItem {...this.props} onMouseEnter={() => this.setState({ hovered: true })} onMouseLeave={() => this.setState({ hovered: false })} innerRef={ref => {
                if(!this.state.ref) this.setState({ ref })
            }}>
                <LinkElement to={this.props.link}>
                    <i className={`fa-duotone fa-${this.props.icon}`} style={{ color: this.props.selected ? "white" : "inherit" }} />
                    <p className="title">{this.props.title}</p>
                    {this.props.notifications && <p className="notifications">{this.props.notifications}</p>}
                    {this.props.new && <p className="beta">Neu</p>}
                    {this.props.beta && <p className="beta">Beta</p>}
                    {this.props.demo && <p className="beta">Demo</p>}
                    {(this.props.dataMissing || this.props.conversation) && <p className="beta" style={{ fontSize: "1.2em" }}><i className="far fa-exclamation-circle" /></p>}
                </LinkElement>
            </StyledNavigationItem>
        </>
    }
}

const StyledTipHover = styled.div`
position: absolute;
width: 100%;
  
.TipHoverDiv{  
    display: none;
    padding: 6px 10px;
    background: #20242b;
    left: calc(100% + 7px);
    position: absolute;
    z-index: 1000;
    top: -14px;
  color: white;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  white-space: nowrap;
  border-radius: 3px;
  
  &:before{
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgb(32, 36, 43) transparent transparent;
  }
}
`

class TipHover extends Component {

    render() {
        const { content } = this.props;

        return  <StyledTipHover >
                  <p className="TipHoverDiv">
                      { content }
                  </p>
                </StyledTipHover>
    }
 }



const Alerts = styled.div`
  position: absolute;
  left: 96px;
  width: 328px;
  height: 100%;
  box-sizing: border-box;
  padding: 16px;
  background: #191b20;
  opacity: ${props => props.showAlerts ? 1 : 0};
  transition: opacity 0.5s;
  overflow-y: scroll;
  border-radius: 16px 0 0 16px;
  
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    
    .recording {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      margin-right: 8px;
      background: #3B97D3;
      animation: ${Blinking} 0.4s linear infinite;
      animation-direction: alternate-reverse;
    }

    .demo {
      color: white;
      font-weight: bold;
    }
  }
  
  .alertsContainer {
    transform: ${props => props.showAlerts ? "scale(1)" : "scale(0.75)"};
    transition: transform 0.5s;
    position: relative;
  }

  .topTitle {
      color: white;
      margin-left: 2px;
      font-size: 22px;
      margin-bottom: 16px;
  }
  
  .settings-bar-perspective {
    width: 100%;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 5px;
    display: flex;
    box-sizing: border-box;
    user-select: none;
    border: 2px solid rgba(255, 255, 255, .25);
    cursor: pointer;
    transition: all 250ms ease;
    color: white;
    text-align: left;
    margin-left: 2px;
    position: relative;
    
    &.disabled {
        cursor: not-allowed;
    }
    
    .selected {
        color: white;
        position: absolute;
        right: 3px;
        bottom: 3px;
        height: 24px;
        padding: 0 8px;
        background: #3b97d3;
        z-index: 1;
        border-radius: 3px;
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    
    
    i {
        width: 40px;
        flex-shrink: 0;
        font-size: 20px;
    }
    
    
    .description {
        font-size: 14px;
        margin-top: 8px;
        opacity: 0.75;
        line-height: 20px;
    }
    
    &:hover {
      opacity: 0.75;
      background: rgba(255, 255, 255, .1);
    }
  }
  
  .settings-bar {
    width: 100%;
    height: 50px;
    margin: 8px 0;
    padding: 16px 0;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    user-select: none;
    border: 2px solid rgba(255, 255, 255, .25);
    cursor: pointer;
    transition: all 250ms ease;
    
    &:hover {
      opacity: 0.75;
      background: rgba(255, 255, 255, .1);
    }
    
    i, p {
      color: white;
    }
    
    i {
      margin-left: 8px;
    }
  }
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .showOlder {
    color: white;
    cursor: pointer;
    font-size: 0.9em;
    opacity: 0.5;
    user-select: none;
    transition: opacity 0.3s;
    
    &:hover {
      opacity: 0.75;
    }
    
    i {
      margin-right: 8px;
    }
  }
  
  .date {
    color: white;
    margin-bottom: 16px;
    font-size: 1em;
    user-select: none;
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: calc(25vw - 50px);
    left: 50px;
    padding-right: 24px;
  }
  
  @media screen and (min-width: 1280px) and (max-width: 2030px) {
    width: calc(20vw - 50px);
    left: 50px;
    padding-right: 24px;
  }
`

const Alert = styled.div`
  background: white;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 5px;
  color: #20242b;
  box-sizing: border-box;
  padding: 12px;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.5);
  display: flex;
  user-select: none;
  cursor: pointer;
  opacity: ${props => props.read ? 0.5 : 1};
  font-size: 0.9em;
  transition: opacity 0.3s;
  word-break: break-word;
  overflow: hidden;
  
  &:hover {
    opacity: 0.75;
  }
  
  .left {
    padding-right: 12px;
    display: flex;
    align-items: center;
    
    .icon {
      width: 32px;
      font-size: 32px;
    }
    
    .avatar {
      border-radius: 100%;
    }
  }
  
  .right {
    display: flex;
    flex-direction: column;
    
    .title {
      margin-bottom: 4px;
      font-size: 0.9em;
    }
    
    .description {
      font-size: 0.8em;
      opacity: 0.75;
    }
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .left {
      margin-bottom: 16px;
    }
  }
`

const CloseAlerts = styled.div`
  width: 14px;
  height: 100vh;
  position: absolute;
  left: 8px;
  top: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  opacity: ${props => props.showAlerts ? 0.5 : 0};
  user-select: none;
  transition: opacity 0.5s, visibility 0.5s;
  visibility: ${props => props.showAlerts ? "visible" : "hidden"};
  
  &:hover {
    opacity: 1;
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: 16px;
    left: 2px;
    font-size: 0.85em;
    
    @media screen and (min-width: 1550px) {
      width: 10px;
    }
  }
  
  @media screen and (min-width: 1280px) and (max-width: 2030px) {
    width: 10px;
    left: 4px;
  }
`

const StyledItemGroup = styled.div`
  width: 100%;
  
  > .title {
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    cursor: pointer;
    justify-content: space-between;
    
    i.icon {
        width: 32px;
        color: #3b97d3;
    }
    
    i.chevron {
        justify-self: flex-end;
        transition: all 250ms ease;
    }
  }
    
  .dropdown {
    width: 100%;
    height: ${props => props.open ? ((props.items * 44) + "px") : 0};
    display: flex;
    flex-direction: column;
    transition: height 0.5s;
    overflow: hidden;
    margin: 6px 0;
    padding: 0 6px;
    box-sizing: border-box;
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1600px) {
    font-size: 0.9em;
    
    > .title {
      height: 24px;
      padding: 0;
      
      i.icon {
        width: 25px;
      }
    }
  }
`

class ItemGroup extends Component {
    state = { open: true }

    render() {
        return <StyledItemGroup open={this.state.open} items={this.props.children.filter(c => c !== false).length}>
            <div className="title" onClick={() => this.setState({ open: !this.state.open })}>
                <p>
                    <i className={`fa-duotone fa-${this.props.icon} icon`} />
                    {this.props.title}
                </p>
                <i className="far chevron fa-chevron-down" style={ this.state.open ? {  } : { transform: 'rotate(180deg)' } } />
            </div>
            <div className="dropdown">
                {this.props.children}
            </div>
        </StyledItemGroup>
    }
}

const Notification = withRouter(class extends Component {
    state = { loading: true, user: null, titleData: null, descriptionData: null, read: false }

    constructor(props) {
        super(props)

        const notification = props.data

        this.state.read = !!notification.readAt

        if(notification && notification.data) this.fetch(notification)
    }

    fetch(notification) {
        const type = notification.type

        if(type === "CONVERSATION_CREATED") {
            return scanmetrix.client.query({
                query: scanmetrix.gql`
                    query($id: ID!) {
                        Conversation(id: $id) {
                            id
                            topic
                            content
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                    }
                `,
                variables: {
                    id: notification.data.conversationId
                }
            }).then(result => {
                const data = result.data.Conversation

                this.setState({ loading: false, user: { name: `${data.user.firstName} ${data.user.lastName}`, email: data.user.email }, titleData: { topic: data.topic }, descriptionData: { name: `${data.user.firstName} ${data.user.lastName}`, content: data.content.message } })
            })
        }

        if(type === "CONVERSATION_REPLY_CREATED") {
            return scanmetrix.client.query({
                query: scanmetrix.gql`
                    query($id: ID!) {
                        ConversationReply(id: $id) {
                            id
                            content
                            conversation {
                                id
                                topic
                            }
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                    }
                `,
                variables: {
                    id: notification.data.conversationReplyId
                }
            }).then(result => {
                const data = result.data.ConversationReply

                this.setState({ loading: false, user: { name: `${data.user.firstName} ${data.user.lastName}`, email: data.user.email }, titleData: { topic: data.conversation.topic }, descriptionData: { name: `${data.user.firstName} ${data.user.lastName}`, content: data.content.message } })
            })
        }
    }

    navigate() {
        const notification = this.props.data
        const data = notification.data
        const type = notification.type

        if(type === "CONVERSATION_CREATED") return this.props.history.push("/conversations", { conversationId: data.conversationId })
        if(type === "CONVERSATION_REPLY_CREATED") return this.props.history.push("/conversations", { conversationReplyId: data.conversationReplyId })
    }

    render() {
        const notification = this.props.data

        return <Alert read={this.state.read} onClick={() => {
            this.navigate()

            if(this.state.read) return false

            this.setState({ read: true })

            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($id: ID!) {
                        readUserNotification(id: $id)
                    }
                `,
                variables: {
                    id: notification.id
                }
            })
        }}>
            {!this.state.loading && this.state.user && <>
                <div className="left">
                    <Avatar size="32" name={this.state.user.name} email={scanmetrix.disableGravatar ? null : this.state.user.email} className="avatar" />
                </div>
                <div className="right">
                    <p className="title">{i18next.t("notification." + notification.type + ".title", this.state.titleData)}</p>
                    <p className="description">{i18next.t("notification." + notification.type + ".description", this.state.descriptionData)}</p>
                </div>
            </>}
        </Alert>
    }
})

class AvatarParent extends Component {
    render() {
        return <div className="avatarContainer" onClick={() => {
            this.props.history.push('/profile')
        }}><Avatar size="64" email={scanmetrix.disableGravatar ? null : scanmetrix.session.email} name={`${scanmetrix.session.firstName} ${scanmetrix.session.lastName}`} className="avatar" /></div>
    }
}


export default withRouter(class extends Component {
    state = {
        showAlerts: false,
        showPerspectives: false,
        showLanguages: false,
        sidebarType: null,
        notifications: [],
        loading: true,
        openModal: false,
        topOpen: true,
        bottomOpen: false,
        tickets: 0
    }

    constructor(props) {
        super(props)

        if(scanmetrix.tickets !== undefined) this.state.tickets = scanmetrix.tickets

        this.escFunction = this.escFunction.bind(this)

        this.fetchNotifications = this.fetchNotifications.bind(this)

        this.fetchNotifications()

        try {
            const lastKnownVersion = Cookies.get("lastKnownVersion")

            if(lastKnownVersion) {
                if(semver.gt(changelog[0].version, lastKnownVersion)) {
                    setTimeout(() => props.changelogModal(), 1000)
                }
            } else {
                setTimeout(() => props.changelogModal(), 1000)
            }

            Cookies.set("lastKnownVersion", changelog[0].version, { expires: 365 })
        } catch(e) {}
    }

    fetchNotifications() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                {
                    UserNotifications {
                        nodes {
                            id
                            type
                            data
                            createdAt
                            readAt
                        }
                    }
                }
            `
        }).then(result => {
            const tickets = 0
            //const tickets = result.data.Tickets.nodes.filter(ticket => ticket.status !== "done" && (!ticket.user || (ticket.user && ticket.user.id === scanmetrix.session.id))).length
            this.setState({ tickets, loading: false, notifications: result.data.UserNotifications.nodes.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()) })
            scanmetrix.tickets = tickets
        })
    }

    escFunction(event) {
        if(event.keyCode === 27) {
            if (this.state.openModal === true) {
                this.setState({ openModal: false })
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false)
    }

    render() {
        let activeField = ""

        this.props.routes.forEach(route => matchPath(this.props.location.pathname, route) && (activeField = route.activeField))

        const isSelected = field => {
            if(!activeField) return false
            const splitted = activeField.split(".")

            if ((splitted.length > 0 && splitted[0] === field) || field === activeField) return true
            return false
        }

        const hasModule = module => !!scanmetrix.session.modules.find(item => item.module === module)

        const sidebarOpen = this.state.showAlerts || this.state.showPerspectives || this.state.showLanguages

        const navigationItems = [
            { icon: "tachometer", title: i18next.t("navigation.sideNavigation.organisation.dashboard"), link: "dashboard" },
            { icon: "calendar", title: i18next.t("navigation.sideNavigation.organisation.calendar"), link: "calendar" },
            { icon: "map-marker", title: i18next.t("navigation.sideNavigation.organisation.map"), link: "map" },
            ...(scanmetrix.checkPermission("AgendaItem") > 0 ? [{ icon: "tasks", title: i18next.t("navigation.sideNavigation.tasks.agenda"), link: "agenda" }] : []),
            ...(scanmetrix.checkPermission("Ticket") > 0 ? [{ icon: "comments-alt", title: i18next.t("navigation.sideNavigation.tasks.ticket"), link: "tickets" }] : []),
            ...(scanmetrix.checkPermission("PurchaseOrder") > 0 ? [{ icon: "comment-alt-check", title: i18next.t("navigation.sideNavigation.tasks.purchaseOrder"), link: "purchaseOrders" }] : []),
            ...(scanmetrix.checkPermission("Measure") > 0 ? [{ icon: "network-wired", title: i18next.t("navigation.sideNavigation.tasks.measure"), link: "measures" }] : []),
            ...(scanmetrix.checkPermission("Workflow") > 0 ? [{ icon: "project-diagram", title: i18next.t("navigation.sideNavigation.tasks.workflow"), link: "workflows" }] : []),
            ...(scanmetrix.checkPermission("ServiceProvider") > 0 ? [{ icon: "user-tie", title: i18next.t("navigation.sideNavigation.administration.serviceProviders"), link: "serviceProviders" }] : []),
            { icon: "users", title: i18next.t("navigation.sideNavigation.administration.users"), link: "users" },
            ...(scanmetrix.checkPermission("Contract") > 0 ? [{ icon: "file-contract", title: i18next.t("navigation.sideNavigation.administration.contracts"), link: "contracts" }] : []),
            ...(scanmetrix.checkPermission("File") > 0 ? [{ icon: "folder-tree", title: i18next.t("navigation.sideNavigation.administration.documents"), link: "documents" }] : []),
            ...(scanmetrix.checkPermission("Car") > 0 ? [{ icon: "car", title: i18next.t("navigation.sideNavigation.administration.cars"), link: "cars" }] : []),
            { icon: "building", title: i18next.t("navigation.sideNavigation.liability.branches"), link: "facilityManagement" },
            ...(scanmetrix.checkPermission("TGA") > 0 ? [{ icon: "history", title: i18next.t("navigation.sideNavigation.liability.tgaDueDates"), link: "dueDates" }] : []),
            ...(scanmetrix.checkPermission("TGA") > 0 ? [{ icon: "bolt", title: i18next.t("navigation.sideNavigation.liability.escalation"), link: "escalation" }] : []),
            ...(scanmetrix.checkPermission("BuildingInspection") > 0 ? [{ icon: "person-walking", title: i18next.t("navigation.sideNavigation.liability.inspections"), link: "buildingInspections" }] : []),
            ...(scanmetrix.checkPermission("AccountingInvoice") > 0 ? [{ icon: "coins", title: scanmetrix.one && scanmetrix.one.enabled && scanmetrix.one.type === "CLOSED_BOOK" ? "Ausgangsrechnungen" : i18next.t("navigation.sideNavigation.fiCo.accounting"), link: "accounting" }] : []),
            ...(scanmetrix.checkPermission("Budget") > 0 ? [{ icon: "piggy-bank", title: i18next.t("navigation.sideNavigation.fiCo.budget"), link: "budget" }] : []),
            ...(hasModule("smartBuilding") ? [{ icon: "house-signal", title: i18next.t("navigation.sideNavigation.buildingTechnology.msr"), link: "msr" }] : []),
            ...(hasModule("spaceManagement") ? [{ icon: "chair-office", title: i18next.t("navigation.sideNavigation.buildingTechnology.spacemanagement"), link: "spacemanagement" }] : [])
        ]

        return <NavigationContainer >
            <Left style={{position: "relative"}}>
                <div>
                    <img src="/icon_white.svg" className="icon" />
                    <div className="version">{scanmetrix.version}</div>
                    {scanmetrix.one.enabled && <div id="scanmetrixOne" className="one">One <i style={{ color: "white", marginLeft: 4 }} className="far fa-arrows-rotate" /></div>}
                    {scanmetrix.one.enabled && <ReactTooltip place="right" anchorSelect="#scanmetrixOne" clickable offset={26}>
                        <div>
                            <OnePopup />
                        </div>
                    </ReactTooltip>}
                </div>
                <div className="user">
                    <AvatarParent history={this.props.history} />

                    {scanmetrix.checkPermission("NavigationMission") > 0 &&
                    <div id="SideItem">
                      <i  className="mission"  onClick={() => {
                        window.open("https://mission.scanmetrix.io", "_blank")
                      }}>
                        <img src="/mission.svg" style={{ width: 24, height: 24, background: "white", padding: 6, borderRadius: "100%" }} ></img>
                     </i>
                    <TipHover content={i18next.t("navigation.placeholders.mission")} />
                    </div>
                    }
                    {scanmetrix.one.enabled && <div id="SideItem">
                    <i className="fal fa-arrow-progress" onClick={() => window.open(scanmetrix.one.frontendURL, "_blank")} ></i>
                    <TipHover content="ERP" />
                   </div>}
                   <div id="SideItem">
                    <i className="fal fa-bell-concierge" onClick={() => window.open(scanmetrix.serviceDeskURL, "_blank")} ></i>
                    <TipHover content={i18next.t("navigation.placeholders.serviceDesk")} />
                   </div>
                    <div id="SideItem">
                        <i className="fal fa-megaphone" data-tooltip-id="my-tooltip" onClick={() => this.props.feedbackModal()} > </i>
                        <TipHover content={i18next.t("navigation.placeholders.feedback")} />
                    </div>
                    <div id="SideItem">
                        <i className="fal fa-list" onClick={() => {
                            this.props.changelogModal()
                            /*
                            if(!this.state.showAlerts && !this.state.showPerspectives) {
                           if(this.state.showLanguages) this.setState({ showLanguages: false })
                           else this.setState({ showLanguages: true, sidebarType: "languages" })}*/
                        }}></i>
                        <TipHover content={i18next.t("navigation.placeholders.changelog")} />
                    </div>
                    <div id="SideItem">
                         <i className="fal fa-globe" onClick={() => {
                             this.props.languageModal()
                             /*
                             if(!this.state.showAlerts && !this.state.showPerspectives) {
                            if(this.state.showLanguages) this.setState({ showLanguages: false })
                            else this.setState({ showLanguages: true, sidebarType: "languages" })}*/
                          }}></i>
                         <TipHover content={i18next.t("navigation.placeholders.language")} />
                    </div>
                    {scanmetrix.checkPermission("SwitchPerspective") > 0 &&
                    <div id="SideItem">
                        <i className="fal fa-eye" onClick={() => {
                            /*
                        if(!this.state.showAlerts && !this.state.showLanguages) {
                            if(this.state.showPerspectives) this.setState({ showPerspectives: false })
                            else this.setState({ showPerspectives: true, sidebarType: "perspectives" })
                        }*/
                            this.props.perspectiveModal()
                        }} ></i>
                        <TipHover content={i18next.t("navigation.placeholders.perspective")} />
                    </div>
                    }
                    {scanmetrix.checkPermission("UserNotification") > 0 &&
                    <div id="SideItem">
                    <i className="fal fa-bell" onClick={() => {
                        /*if(!this.state.showPerspectives && !this.state.showLanguages) {
                            if(this.state.showAlerts) this.setState({ showAlerts: false })
                            else this.setState({ showAlerts: true, sidebarType: "alerts", notifications: [], loading: true }, this.fetchNotifications)
                        }*/
                        this.props.history.push('/notification-settings')
                    }} >  </i>
                    <TipHover content={i18next.t("navigation.placeholders.notifications")} />
                    </div>}
                    {scanmetrix.checkPermission("UserIntegration") > 0 &&<div id="SideItem"> <i  className="fal fa-puzzle-piece" onClick={() => this.props.history.push('/integrations')} ></i> <TipHover content={i18next.t("navigation.placeholders.integrations")} /> </div>}
                    {/*scanmetrix.checkPermission("Marketplace") > 0 && <div id="SideItem"> <i  className="fal fa-store-alt" onClick={() => this.props.history.push('/marketplace')} ></i> <TipHover content={i18next.t("navigation.placeholders.marketplace")} /></div>*/}
                    {scanmetrix.session.administrative && <div id="SideItem"> <i  className="fal fa-code" onClick={() => window.open(`${scanmetrix.nestURL}/v2/docs`, "_blank")} ></i><TipHover content={i18next.t("navigation.placeholders.api")} /></div>}
                    {scanmetrix.session.administrative && <div id="SideItem"> <i  className="fal fa-cog" onClick={() => this.props.history.push('/settings')} ></i><TipHover content={i18next.t("navigation.placeholders.settings")} /></div>}
                    <div id="SideItem" >
                    <i className="fal fa-sign-out" style={{color: '#E74C3C', fontWeight: 'bold', width: "36px", height: "36px", borderRadius: "50%", display: "flex",alignItems:"center", justifyContent:"center", background: "white", fontSize: "1em", boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.25)" }} onClick={() => {
                        fetch(scanmetrix.backendURL + "/user/logout", {
                            method: "POST",
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json"
                            }
                        }).then(result => result.json()).then(() => {
                            scanmetrix.session = null
                            this.props.history.push("/login", { state: { logout: true } })
                        })
                    }} > </i>
                    <TipHover content={i18next.t("navigation.placeholders.signOut")} />
                    </div>
                </div>
            </Left>

            {/*<Alerts showAlerts={sidebarOpen}>
                {this.state.sidebarType === "perspectives" && <div className="alertsContainer">
                    <div className="topTitle">{i18next.t("navigation.sideNavigation.perspective.topTitle")}</div>
                    <div className='settings-bar-perspective' onClick={() => {
                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($perspective: String!) {
                                    updatePerspective(perspective: $perspective)
                                }
                            `,
                            variables: {
                                perspective: "facilityManagement"
                            }
                        }).then(result => {
                            if(result.data.updatePerspective) {
                                scanmetrix.session.perspective = "facilityManagement"
                                if(this.props.history.location.pathname === "/dashboard") this.props.history.replace("/reload")
                            } this.setState({ showPerspectives: false })
                        })
                    }}>
                        {scanmetrix.session.perspective === "facilityManagement" && <div className="selected">{i18next.t("navigation.sideNavigation.perspective.choiceButton")}</div>}
                        <i className="far fa-building" />
                        <div className="right">
                            <p>{i18next.t("navigation.sideNavigation.perspective.facilityManagement.title")}</p>
                            <div className="description">{i18next.t("navigation.sideNavigation.perspective.facilityManagement.description")}</div>
                        </div>
                    </div>
                    <div className='settings-bar-perspective' onClick={() => {
                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($perspective: String!) {
                                    updatePerspective(perspective: $perspective)
                                }
                            `,
                            variables: {
                                perspective: "propertyManagement"
                            }
                        }).then(result => {
                            if(result.data.updatePerspective) {
                                scanmetrix.session.perspective = "propertyManagement"
                                if(this.props.history.location.pathname === "/dashboard") this.props.history.replace("/reload")
                            } this.setState({ showPerspectives: false })
                        })
                    }}>
                        {scanmetrix.session.perspective === "propertyManagement" && <div className="selected">{i18next.t("navigation.sideNavigation.perspective.choiceButton")}</div>}
                        <i className="far fa-house-return" />
                        <div className="right">
                            <p>{i18next.t("navigation.sideNavigation.perspective.propertyManagement.title")}</p>
                            <div className="description">{i18next.t("navigation.sideNavigation.perspective.propertyManagement.description")}</div>
                        </div>
                    </div>
                </div>}
                {this.state.sidebarType === "languages" && <div className="alertsContainer">
                    <div className="topTitle">{i18next.t("navigation.sideNavigation.language.topTitle")}</div>
                    <div className='settings-bar-perspective' onClick={() => {
                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($language: String!) {
                                    updateLanguage(language: $language)
                                }
                            `,
                            variables: {
                                language: "de_DE"
                            }
                        }).then(result => {
                            if(result.data.updateLanguage) {
                                scanmetrix.session.language = "de_DE"
                                location.reload()
                            } this.setState({ showLanguages: false })
                        })
                    }}>
                        {scanmetrix.session.language === "de_DE" && <div className="selected">{i18next.t("navigation.sideNavigation.perspective.choiceButton")}</div>}
                        <i className="far fa-globe" />
                        <div className="right">
                            <p>{i18next.t("navigation.sideNavigation.perspective.germanTitle")}</p>
                            <div className="description">{i18next.t("navigation.sideNavigation.perspective.germanDescription")}</div>
                        </div>
                    </div>
                    <div className='settings-bar-perspective' onClick={() => {
                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($language: String!) {
                                    updateLanguage(language: $language)
                                }
                            `,
                            variables: {
                                language: "en_US"
                            }
                        }).then(result => {
                            if(result.data.updateLanguage) {
                                scanmetrix.session.language = "en_US"
                                location.reload()
                            } this.setState({ showLanguages: false })
                        })
                    }}>
                        {scanmetrix.session.language === "en_US" && <div className="selected">{i18next.t("navigation.sideNavigation.perspective.choiceButton")}</div>}
                        <i className="far fa-globe" />
                        <div className="right">
                            <p>{i18next.t("navigation.sideNavigation.perspective.englishTitle")}</p>
                            <div className="description">{i18next.t("navigation.sideNavigation.perspective.englishDescription")}</div>
                        </div>
                    </div>
                </div>}
                {this.state.sidebarType === "alerts" && <div className="alertsContainer">
                    <div className='settings-bar' onClick={() => this.props.history.push('/notification-settings')}>
                        <p>{i18next.t("navigation.sideNavigation.perspective.notificationSettings")}</p>
                        <i className='far fa-cog' />
                    </div>
                    <p className="date">{i18next.t("navigation.sideNavigation.notification.date")}</p>
                    {!this.state.loading && this.state.notifications.map(notification => <Notification data={notification} key={notification.id} />)}
                    <p className="showOlder"><i className="far fa-history" />{i18next.t("navigation.sideNavigation.notification.message")}</p>
                </div>}
            </Alerts>*/}
            <StyledSmallNavigation>
                <div className="grid">
                    {navigationItems.map((item, index) => <div className={`link ${isSelected(item.link) ? "selected" : ""}`} onClick={() => this.props.history.push(`/${item.link}`)} key={index}>
                        <i className={`fa-duotone fa-${item.icon}`} />
                        <p>{item.title}</p>
                    </div>)}
                </div>
            </StyledSmallNavigation>
        </NavigationContainer>
    }
})

const StyledOneTooltip = styled.div`
    padding: 8px;
    
    ul {
        margin-top: 8px;
        
        li {
            list-style-type: none;
            line-height: 24px;
            
            .red {
                color: #e74c3c;
            }
            
            .green {
                color: #1abc9c;
            }
            
            i.fa-times-circle {
                color: #e74c3c;
            }
            
            i.fa-check-circle {
                color: #1abc9c;
            }
        }
    }
    
    h1 {
        font-size: 16px;
        margin-bottom: 4px;
    }
    
    h2 {
        font-size: 14px;
        font-weight: normal;
    }
`

class OnePopup extends Component {
    state = { loading: true, meta: null }

    componentDidMount() {
        this.setState({ loading: true, meta: null })

        fetch(`${scanmetrix.nestURL}/v2/one/meta`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include"
        }).then(result => result.json()).then(meta => {
            if(!meta.statusCode) {
                this.setState({ meta, loading: false })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    render() {
        if(this.state.loading) return <div style={{ padding: 16 }}>
            <HashLoader color="white" />
        </div>

        if(!this.state.meta) return <p>Ein Fehler ist aufgetreten.</p>

        return <StyledOneTooltip>
            <h1>scanmetrix® One</h1>
            <h2><span style={{ opacity: 0.5 }}>Verwendetes Verfahren:</span> {scanmetrix.one.type}</h2>
            <h2><span style={{ opacity: 0.5 }}>Letzter Datenabgleich</span>: {moment(this.state.meta.lastSync).fromNow()}</h2>
            <ul>
                {scanmetrix.one.type === "CLOSED_BOOK" && <li><i className="fa-duotone fa-times-circle" /> <b className="red">Deaktiviert:</b> Selbstständige Buchung von Eingangsbelegen</li>}
                {scanmetrix.one.type === "CLOSED_BOOK" && <li><i className="fa-duotone fa-times-circle" /> <b className="red">Deaktiviert:</b> E-Mail-Postfach für Eingangsbelege</li>}
                {scanmetrix.one.type === "CLOSED_BOOK" && <li><i className="fa-duotone fa-times-circle" /> <b className="red">Deaktiviert:</b> Freigabeanfragen in Maßnahmen</li>}
                <li><i className="fa-duotone fa-check-circle" /> <b className="green">Aktiviert:</b> Link zum ERP in Seitenleiste</li>
                <li><i className="fa-duotone fa-check-circle" /> <b className="green">Aktiviert:</b> Unterscheidung von Maßnahmen in Regelleistung & Sonderleistung</li>
                {scanmetrix.one.type === "CLOSED_BOOK" && <li><i className="fa-duotone fa-check-circle" /> <b className="green">Aktiviert:</b> Automatischer Stammdatenabgleich</li>}
                {scanmetrix.one.type === "CLOSED_BOOK" && <li><i className="fa-duotone fa-check-circle" /> <b className="green">Aktiviert:</b> Maßnahmen lösen in ERP Vorgänge aus</li>}
                {scanmetrix.one.type === "CLOSED_BOOK" && <li><i className="fa-duotone fa-check-circle" /> <b className="green">Aktiviert:</b> Beauftragte Ausgangsangebote geben Bestellungen frei</li>}
                {scanmetrix.one.type === "CLOSED_BOOK" && <li><i className="fa-duotone fa-check-circle" /> <b className="green">Aktiviert:</b> Ausgangsangebotswert als Bestellobligo in Budgetierung</li>}
                {scanmetrix.one.type === "OPEN_BOOK" && <li><i className="fa-duotone fa-check-circle" /> <b className="green">Aktiviert:</b> Eingangsrechnungen lösen Aufschlagsvorgang in ERP aus</li>}
                {scanmetrix.one.type === "OPEN_BOOK" && <li><i className="fa-duotone fa-check-circle" /> <b className="green">Aktiviert:</b> Dienstleistungsaufschlag für Bestellungen</li>}
                <li><i className="fa-duotone fa-check-circle" /> <b className="green">Aktiviert:</b> Automatische Übernahme von Ausgangsrechnungen aus ERP in CAFM</li>
            </ul>
            <a style={{ color: "#3b97d3", marginTop: 8, textDecoration: "none", display: "block" }} target="_blank" href="/One_Guide.pdf"><i className="fa-duotone fa-file-pdf" /> Dokumentation ansehen</a>
        </StyledOneTooltip>
    }
}
