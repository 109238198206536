import React, { Component } from 'react'
import { Modal, Input, Grid, GridElement, Checkbox, Select } from 'scanmetrix-components'
import i18next from "i18next";

export default class extends Component {
    state = { subsidiaries: [], provisionProof: null }

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={provisionProof => {
            this.setState({ provisionProof })

            scanmetrix.client.query({
                query: scanmetrix.gql`
                    query {
                        Subsidiaries {
                            nodes {
                                id
                                name
                                label
                            }
                        }
                    }  
                `
            }).then(data => {
                this.setState({ subsidiaries: data.data.Subsidiaries.nodes })
            })
        }}>
            <Modal.Head title={i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.title")} icon="history" />
            <Modal.Body padding={24}>
                {this.state.provisionProof && <Input.MutationProvider value={{
                    name: "updateContractProvisionProof",
                    id: this.state.provisionProof.id,
                    data: this.state.provisionProof,
                    onSave: () => this.props.refresh()
                }}>
                    <Grid gap="16px" columns={[ "1fr", "1fr" ]}>
                        <GridElement columnStart={1} columnEnd={3}>
                            <Select readOnly={scanmetrix.checkPermission("ContractProvisionProof") < 3} field="subsidiary.id" icon="map-marker" label={i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.branch")} adjustWidth items={this.state.subsidiaries.map(subsidiary => ({ key: subsidiary.id, title: `${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""}` }))} />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={3}>
                            <Input disabled={scanmetrix.checkPermission("ContractProvisionProof") < 3} required date field="date" icon="calendar-day" label={i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.performanceDate")} adjustWidth />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={3}>
                            <Input disabled={scanmetrix.checkPermission("ContractProvisionProof") < 3} required field="name" icon="tag" label={i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.performanceName")} adjustWidth />
                        </GridElement>
                        {this.state.provisionProof.tracing && <GridElement columnStart={1} columnEnd={3}>
                            <Select readOnly={scanmetrix.checkPermission("ContractProvisionProof") < 3} adjustWidth icon="exclamation" label={i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.priority")} field="priority" noUnselect items={[
                                { key: "low", title: i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.priorityTypes.low") },
                                { key: "medium", title: i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.priorityTypes.medium") },
                                { key: "high", title: i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.priorityTypes.high") }
                            ]} />
                        </GridElement>}
                        <GridElement columnStart={1} columnEnd={2}>
                            <Checkbox disabled={scanmetrix.checkPermission("ContractProvisionProof") < 3} label={i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.intervalContinue")} field="continueInterval" />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Checkbox disabled={scanmetrix.checkPermission("ContractProvisionProof") < 3} label={i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.serviceDateInterval")} field="continueFromDate" />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={3}>
                            <Checkbox disabled={scanmetrix.checkPermission("ContractProvisionProof") < 3} label={i18next.t("page.private.contract.modals.updateContractProvisionProoflModal.defectEvent")} field="tracing" />
                        </GridElement>
                    </Grid>
                </Input.MutationProvider>}
            </Modal.Body>
        </Modal>
    }
}
