import React, { Component } from "react"
import { GridElement, Table, Button } from "scanmetrix-components"
import CreateCarFlawModal from "./modals/CreateCarFlawModal"
import DeleteCarFlawModal from "./modals/DeleteCarFlawModal"

import UpdateCarFlawModal from "./modals/UpdateCarFlawModal"

export default class extends Component {
    state = { flaws: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($carId: ID!) {
                    CarFlaws(filter: { carId_eq: $carId }) {
                        nodes {
                            id
                            damagedAt
                            title
                            description
                            location
                            fixed
                            data
                            carDriver {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
            `,
            variables: {
                carId: this.props.car.id
            }
        }).then(data => {
            this.setState({ flaws: data.data.CarFlaws.nodes })
        })
    }

    render() {
        return <>
            <CreateCarFlawModal instance={ref => this.createCarFlawModal = ref} car={this.props.car} refresh={() => this.fetch()} />
            <DeleteCarFlawModal instance={ref => this.deleteCarFlawModal = ref} refresh={() => this.fetch()} />
            <UpdateCarFlawModal instance={ref => this.updateCarFlawModal = ref} car={this.props.car} refresh={() => this.fetch()} />
            <GridElement styled title="Mängel" icon="bolt" rightContent={<Button thick icon="bolt" title="Mangel anlegen" onClick={() => this.createCarFlawModal.open()} />}>
                <Table
                    head={[
                        { title: "Fahrer", width: "15%", column: "formattedDriver" },
                        { title: "Titel", width: "17.5%", column: "title" },
                        { title: "Beschreibung", width: "27.5%", column: "description" },
                        { title: "Fahrzeugposition", width: "20%", column: "location" },
                        { title: "Repariert", width: "10%", column: "formattedFixed" },
                        { title: "Schadensdatum", width: "10%", column: "formattedDamagedAt" }
                    ]}
                    items={this.state.flaws ? this.state.flaws.map(flaw => ({
                        ...flaw,
                        formattedDriver: `${flaw.carDriver.firstName} ${flaw.carDriver.lastName}`,
                        formattedFixed: flaw.fixed ? <><i className="far fa-check-circle" />Ja</> : <><i className="far fa-times-circle" />Nein</>,
                        formattedDamagedAt: flaw.damagedAt ? moment(flaw.damagedAt).format("DD.MM.YYYY") : null
                    })) : null}
                    onItemClick={item => this.updateCarFlawModal.open(item)}
                    contextItems={[
                        { title: "Eintrag löschen", icon: "trash", onClick: item =>  this.deleteCarFlawModal.open(item.id)}
                    ]}
                />
            </GridElement>
        </>
    }
}
