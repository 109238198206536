import React, { Component } from 'react'
import { Button, Modal, Input, Grid, GridElement, Select } from 'scanmetrix-components'


const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = { loading: false }

    getCleanState = () => ({
        loading: false,
        name: null,
        insurer: null,
        type: null,
        insuranceNumber: null,
        deductible: null,
        contractBegin: null,
        contractEnd: null,
        subscriptionIntervalFee: null,
        subscriptionIntervalType: null,
        description: null,
        email: null,
        telephone: null
    })

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={() => this.setState(this.getCleanState())}>
            <Modal.Head title="Versicherung anlegen" icon="car-crash" />
            <Modal.Body padding={24}>
                <Grid columns={[ "1fr", "1fr" ]} gap="16px">
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input required value={this.state.name} onChange={name => this.setState({ name })} adjustWidth label='Name' icon='font' />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input required adjustWidth label='Versicherer' icon='user-tie' value={this.state.insurer} onChange={insurer => this.setState({ insurer })} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input adjustWidth label='Vertragsbeginn' icon='calendar-day' date value={this.state.contractBegin} onChange={contractBegin => this.setState({ contractBegin })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input adjustWidth label='Vertragsende' icon='calendar-day' date value={this.state.contractEnd} onChange={contractEnd => this.setState({ contractEnd })} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Select required adjustWidth label='Typ' icon='cog' value={this.state.type} onChange={type => this.setState({ type })} items={[
                            { key: 'liability', title: 'Haftpflicht' },
                            { key: 'partiallyComprehensive', title: 'Teilkasko' },
                            { key: 'fullyComprehensive', title: 'Vollkasko' },
                        ]} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input adjustWidth label='Beschreibung' icon='sticky-note' value={this.state.description} onChange={description => this.setState({ description })} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input adjustWidth label='Vertragsgebühren' icon='euro-sign' float format={val => val !== null ? formatter.format(val) : val} value={this.state.subscriptionIntervalFee} onChange={subscriptionIntervalFee => this.setState({ subscriptionIntervalFee })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Select adjustWidth label='Zahlungsintervall' icon='hourglass' value={this.state.subscriptionIntervalType} onChange={subscriptionIntervalType => this.setState({ subscriptionIntervalType })} items={[
                            { key: 'one', title: 'Monatlich' },
                            { key: 'three', title: 'Alle 3 Monate' },
                            { key: 'six', title: 'Alle 6 Monate' },
                            { key: 'nine', title: 'Alle 9 Monate' },
                            { key: 'twelve', title: 'Jährlich' },
                        ]} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input adjustWidth label='Versicherungsnummer' icon='barcode' value={this.state.insuranceNumber} onChange={insuranceNumber => this.setState({ insuranceNumber })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input adjustWidth label='Selbstbeteiligung' icon='money-bill-wave' format={val => val !== null ? formatter.format(val) : val} float value={this.state.deductible} onChange={deductible => this.setState({ deductible })} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Input adjustWidth label='E-Mail Adresse' icon='envelope-open' value={this.state.email} onChange={email => this.setState({ email })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input adjustWidth label='Telefonnummer' icon='phone' value={this.state.telephone} onChange={telephone => this.setState({ telephone })} />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={!this.state.type || !this.state.insurer || !this.state.name} loading={this.state.loading} thick title="Versicherung anlegen" icon="car-crash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($carId: ID!, $name: String!, $insurer: String!, $type: String!, $insuranceNumber: String, $deductible: Float, $contractBegin: DateTime, $contractEnd: DateTime, $subscriptionIntervalFee: Float, $subscriptionIntervalType: String, $description: String, $email: String, $telephone: String) {
                            createCarInsurance(carId: $carId, name: $name, insurer: $insurer, type: $type, insuranceNumber: $insuranceNumber, deductible: $deductible, contractBegin: $contractBegin, contractEnd: $contractEnd, subscriptionIntervalFee: $subscriptionIntervalFee, subscriptionIntervalType: $subscriptionIntervalType, description: $description, email: $email, telephone: $telephone)
                        }
                    `,
                    variables: {
                        ...this.state,
                        contractBegin: this.state.contractBegin ? moment(this.state.contractBegin, "DD.MM.YYYY").toDate() : null,
                        contractEnd: this.state.contractEnd ? moment(this.state.contractEnd, "DD.MM.YYYY").toDate() : null,
                        carId: this.props.carId
                    }
                }).then(result => {
                    if(result.data.createCarInsurance) {
                        instance.close()
                        this.props.refresh()
                    }
                })
            }} />} />
        </Modal>
    }
}
