import React, { Component } from 'react'
import styled from 'styled-components'
import { Page, Grid, GridElement, Input, Breadcrumbs, Button } from 'scanmetrix-components'
import { ScaleLoader } from 'react-spinners';

import CreateCarModal from './modals/CreateCarModal'
import CarPreview from "./styled/CarPreview"

const CarpoolBody = styled.div`
  width: 100%;
  
  .button-view {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 32px;
  }
`

const GridBody = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  
  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)); 
  }
  
  @media screen and (max-width: 1280px) {
    zoom: 0.8;
  }
  
  grid-auto-rows: 800px;
  box-sizing: border-box;
`

const CarBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  
  background: white;
  border-radius: 5px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  box-sizing: border-box;
  transition: all 250ms ease;
  
  
  .innerContainer {
    display: grid;
    grid-template-rows: 1.25fr 78px 1fr 50px;
    grid-template-columns: 100%;
    grid-gap: 16px;
    max-width: 100%;
    width: 100%;
  }
  
  &:hover {
    transform: scale(1.025);
  }
  
  .header {
    width: 100%;
    height: 100%;
    background: url('${props => props.image}');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    
    canvas {
        height: 280px !important;
    }
  }
  
  .plate-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
    .plate {
      height: 64px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 3px solid #20242B;
      position: relative;
      user-select: none;

      .country-sticker {
        height: 64px;
        width: 32px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        
        @media screen and (max-width: 1280px) {
          width: 34px;
          height: 68px;
          top: -2px;
          left: -2px;
        }
      }

      b {
        width: 29px;
        font-family: "Euro Plate";
        position: absolute;
        left: 0;
        bottom: 4px;
        text-align: center;
        color: white;
        font-weight: normal;
      }

      p {
        font-family: "Euro Plate";
        font-size: 2.6em;
        text-align: center;
        padding: 0 16px 0 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        word-spacing: 8px;
      }
    }
    
    .sticker {
      width: 64px;
      height: 64px;
      border-radius: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid #20242B;
      box-sizing: border-box;
      position: relative;
      user-select: none;
      
      img {
        position: absolute;
        width: 64px;
        height: 64px;
        top: -3px;
        left: -3px;
        user-select: none;
        user-drag: none;
      }
      
      p {
        font-family: "Euro Plate";
        color: black;
        font-size: 0.7em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  
  .body {
    width: 100%;
    height: 100%;
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

function getRotation(value) {
    if (value === 0) return 'rotate(30deg)'
    else if (value === 1) return 'rotate(60deg)'
    else if (value === 2) return 'rotate(90deg)'
    else if (value === 3) return 'rotate(120deg)'
    else if (value === 4) return 'rotate(150deg)'
    else if (value === 5) return 'rotate(180deg)'
    else if (value === 6) return 'rotate(210deg)'
    else if (value === 7) return 'rotate(240deg)'
    else if (value === 8) return 'rotate(270deg)'
    else if (value === 9) return 'rotate(300deg)'
    else if (value === 10) return 'rotate(330deg)'
    return 'rotate(0)'
}

function getBackgroundSticker(value) {
    const colors =[ '#1BB1F1', '#F4F017', '#D65C4B', '#F79CB8', '#1BAF82', '#E59101' ]
    value = value - 20
    return colors[value%colors.length]
}

class Card extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
      }








    render() {





        return (

             this.props.data.map((item, index) => {
                return <CarBox key={index} image={item.image}>
                    <div className="innerContainer">
                        <div className='header'>
                            {!item.image && <CarPreview enableZoom={false} minDistance={4} maxDistance={4} selectedCar={item.type} />}
                        </div>

                        <div className='plate-body'>
                            {item.tuvYear && item.tuvMonth && <div className='sticker' style={{ backgroundColor: getBackgroundSticker(item.tuvYear) }}>
                                <img src='car_circle.svg' style={{ transform: getRotation(item.tuvMonth) }} />
                                <p>{item.tuvYear.toString().substr(-2)}</p>
                            </div>}

                            <div className='plate'>
                                <img className='country-sticker' src='plate.svg' />
                                <p>{item.plate}</p>
                                <b>{item.carCountry}</b>
                            </div>
                        </div>

                        <div className='body'>
                            <Grid gap="16px" columns={[ "1fr", "1fr" ]}>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Input adjustWidth label="Fahrzeug" icon="car" value={item.name} readOnly />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Input adjustWidth label="Niederlassung" icon="map-marker" value={item.subsidiary} readOnly />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input adjustWidth label="Hersteller" icon="industry" value={item.carManufacturer} readOnly />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input adjustWidth label="Modell" icon="cars" value={item.carModel} readOnly />
                                </GridElement>
                            </Grid>
                        </div>

                        <div className='button'>
                            <Button thick icon="car" title="Zum Fahrzeug" onClick={() => this.props.history.push(`/car/${item.id}`)} />
                        </div>
                    </div>
                </CarBox>
            })

          );
    }
}

const NoCarsPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
    align-items: center;
    justify-content: center;
    padding-top: 48px;
    
  
    h1 {
        font-weight: normal;
        font-size: 1.3em;
    }
  
    img {
        opacity: 0.5;
        width: 400px;
        margin-bottom: 32px;
        -webkit-user-drag: none;
    }
`

export default class extends Component {
    state = {modalOpen: false, subsidiaries: [], cars: [], googleLoading: true}

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetch()

    }


    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Cars {
                        nodes {
                            id
                            name
                            model
                            manufacturer
                            nextInspection
                            thumbnailBase64
                            licensePlate1
                            licensePlate2
                            licensePlate3
                            type
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    inline
                                }
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ cars: data.data.Cars.nodes, googleLoading: false})
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Subsidiaries {
                        nodes {
                            id
                            name
                            legalName
                            address {
                                postalCode
                                city
                                streetName
                                streetNumber
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ subsidiaries: data.data.Subsidiaries.nodes })
        })

    }






    render() {













        return  <Page {...this.props} height="100%" style={{position:"relative"}}>

            <Breadcrumbs values={[
                {
                    icon: "car",
                    title: "Fuhrpark"
                }
            ]} />
            <CreateCarModal refresh={() => this.fetch()} subsidiaries={this.state.subsidiaries} instance={ref => this.createCarModal = ref} />
            <CarpoolBody>

                <div className="button-view">
                    <Button thick icon="car" title="Fahrzeug anlegen" onClick={() => this.createCarModal.open()} />
                </div>
                {this.state.cars.length > 0 && <GridBody>
                    <Card history={this.props.history} data={this.state.cars.map(car => ({
                        id: car.id,
                        carCountry: "D",
                        plate: `${car.licensePlate1} ${car.licensePlate2} ${car.licensePlate3}`,
                        tuvMonth: car.nextInspection ? moment(car.nextInspection).month() : null,
                        tuvYear: car.nextInspection ? moment(car.nextInspection).year() : null,
                        image: car.thumbnailBase64,
                        carModel: car.model,
                        carManufacturer: car.manufacturer,
                        name: car.name,
                        type: car.type,
                        subsidiary: car.subsidiary ? `${car.subsidiary.name} (${car.subsidiary.label || car.subsidiary.id}) ${car.subsidiary.address.inline}` : null
                    }))} />
                </GridBody>}
            </CarpoolBody>
            {this.state.googleLoading && <div style={{display:"flex", justifyContent:"center", alignItems:" center", flexDirection:"column", fontSize:"2rem", lineHeight:"50px", height:"400px"}}>
                        <div >
                            <ScaleLoader color="#3b97d3" height={20} />
                        </div>
                        Lade Fahrzeuge...
                    </div>}

            {this.state.googleLoading === false && this.state.cars.length === 0 && <NoCarsPlaceholder>
                <img src="/illustrations/carpool.svg" />
                <h1>Sie haben noch keine Fahrzeuge in Ihrem Fuhrpark.</h1>
            </NoCarsPlaceholder>}

        </Page>
        }


}
