import React, { Component } from "react"
import { Page, Breadcrumbs, Table, GridElement, Button } from "scanmetrix-components"
import CreateServiceProviderModal from "./modals/CreateServiceProviderModal"
import styled from "styled-components"
import i18next from "i18next"

const Status = styled.div`
  position: absolute;
  left: -16px;
  width: 32px;
  height: 32px;
  background: ${props => props.blue ? "#3b97d3" : "#2c3e50"};
  border-radius: 32px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 16px);  
  font-size: 0.9em;
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);
  
  i {
      margin: 0 !important;
  }
`

export default class extends Component {
    state = { serviceProviders: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    ServiceProviders {
                        nodes {
                            id
                            name
                            responsibility
                            globalId
                            address {
                                streetName
                                streetNumber
                                postalCode
                                city
                            }
                            contactEmail
                            contactTelephone
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ serviceProviders: data.data.ServiceProviders.nodes })
        })
    }

    render() {
        return <Page {...this.props}>
            <CreateServiceProviderModal refresh={() => this.fetch()} instance={ref => this.createServiceProviderModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "user-tie",
                    title: i18next.t("page.private.serviceProviders.breadCrumbsTitle")
                }
            ]} />
            <GridElement styled title={i18next.t("page.private.serviceProviders.gridElementTitle")} icon="user-tie" rightContent={<Button thick icon="user-tie" title={i18next.t("page.private.serviceProviders.button")} disabled={scanmetrix.checkPermission("ServiceProvider") < 2} onClick={() => this.createServiceProviderModal.open()} />}>
                <Table
                    head={[
                        { title: i18next.t("page.private.serviceProviders.table.name"), width: "20%", column: "name" },
                        { title: i18next.t("page.private.serviceProviders.table.responsibility"), width: "27.5%", column: "responsibility" },
                        { title: i18next.t("page.private.serviceProviders.table.city"), width: "15%", column: "city" },
                        { title: i18next.t("page.private.serviceProviders.table.contactEmail"), width: "17.5%", column: "contactEmail" },
                        { title: i18next.t("page.private.serviceProviders.table.contactTelephone"), width: "15%", column: "contactTelephone" }
                    ]}
                    additionalSearchIndexes={["nameRaw"]}
                    onItemClick={serviceProvider => this.props.history.push(`/serviceprovider/${serviceProvider.id}`)}
                    contextItems={[ 
                        { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: serviceProvider => window.open(`/serviceprovider/${serviceProvider.id}`)}
                    ]}
                    items={this.state.serviceProviders ? this.state.serviceProviders.map(serviceProvider => ({
                        ...serviceProvider,
                        nameRaw: serviceProvider.name,
                        name: <div style={{ paddingLeft: "16px" }}><Status blue={!!serviceProvider.globalId}><i className={`far fa-${serviceProvider.globalId ? "sync" : "print"}`} /></Status>{serviceProvider.name}</div>,
                        city: serviceProvider.address.postalCode && serviceProvider.address.city ? `${serviceProvider.address.postalCode} ${serviceProvider.address.city}` : (serviceProvider.address.city || "N/A")
                    })) : null}
                />
            </GridElement>
        </Page>
    }
}
