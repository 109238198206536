import u2fApi from "u2f-api"
import Cookies from "universal-cookie"

export default function() {
    this.setState({ loading: true })

    console.log(scanmetrix.nestURL)

    fetch(scanmetrix.nestURL + "/v2/auth/login", {
        method: "POST",
        body: JSON.stringify({
            email: (this.state.email || "").toLowerCase(),
            password: this.state.password
        }),
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        }
    }).then(async result => {
        if(result.status === 201) {
            new Cookies().set("rememberUsername", this.state.remember ? this.state.email : "", { path: "/", maxAge: 60 * 60 * 24 * 365 })
            this.props.refreshSession(() => this.props.history.push("/dashboard"))

            if(result.challenge) {
                this.setState({ blurMode: "u2f-verification", loading: false })

                u2fApi.sign(result).then(authResponse => {
                    if(this.state.blurMode === "u2f-verification") {
                        fetch(scanmetrix.backendURL + "/user/u2f-authentication-verification", {
                            method: "POST",
                            credentials: "include",
                            body: JSON.stringify({
                                authRequest: result,
                                authResponse
                            }),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        }).then(result => result.json()).then(result => {
                            if(result.success) {
                                this.props.refreshSession(() => this.props.history.push("/dashboard"))
                            } else {
                                this.setState({ blurMode: null })
                            }
                        })
                    }
                }).catch(() => this.setState({ blurMode: null }))
            } else {
                console.log("success:")
                console.log(result)
            }
        } else {
            console.log(result)
            this.setState({ loading: false, blurMode: "error", error: result.status })
        }
    }).catch(error => {
        this.setState({ loading: false, blurMode: "error", error: error })
    })
}
