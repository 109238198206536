import styled from "styled-components"

export default styled.div`
  box-sizing: border-box;
  position: relative;
  
  .attention {
    height: 28px;
    width: 28px;
    left: -50px;
    top: calc(50% - 14px);
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: white;
    border-radius: 100%;
    box-shadow: 0 0 4px 0 #e74c3c;
    color: #e74c3c;
    border: 2px solid #e74c3c;
    position: absolute;
    
    &.orange {
      color: #e67e22;
      box-shadow: 0 0 4px 0 #e67e22;
      border: 2px solid #e67e22;
    }
  }
  
  svg {
    height: 64px;
    width: 64px;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .label {
    width: 30%;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 1em;
    line-height: 1.3em;
  }
  
  .unit {
    width: 20%;
    flex-shrink: 0;
    flex-grow: 0;
    opacity: 0.75; 
  }
`
