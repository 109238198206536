import React, { Component } from "react"
import { Modal, Input, Select, Checkbox } from "scanmetrix-components"
import { CirclePicker } from "react-color"
import styled from "styled-components"
import i18next from "i18next";
import isEmail from "validator/lib/isEmail";

const CircleDesign = styled.div`
    transform: translateX(7px);
`

export default class extends Component {
    state = { id: null, ticketEscalationStep: null, color: null, statuses: [], allTypes: [], types: [], priorities: [], additionalEmails: null, loading: false }

    constructor(props) {
        super(props)

        this.updateLists = this.updateLists.bind(this)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    TicketTypes {
                        nodes {
                            id
                            name
                            color
                            icon
                            active
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ allTypes: data.data.TicketTypes.nodes })
        })
    }

    updateLists() {
        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $types: JSON, $statuses: JSON, $priorities: JSON, $additionalEmails: JSON) {
                    updateTicketEscalationStep(id: $id, types: $types, statuses: $statuses, priorities: $priorities, additionalEmails: $additionalEmails)
                }
            `,
            variables: {
                id: this.state.id,
                types: this.state.types,
                statuses: this.state.statuses,
                priorities: this.state.priorities,
                additionalEmails: this.state.additionalEmails ? this.state.additionalEmails.split(",").filter(email => isEmail(email)) : []
            }
        }).then(data => {
            if(data.data.updateTicketEscalationStep) this.props.refresh()
        })
    }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={ticketEscalationStep => this.setState({ ticketEscalationStep: ticketEscalationStep.raw, ...ticketEscalationStep.raw, additionalEmails: ticketEscalationStep.additionalEmails.join(","), loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.updateTicketEscalationStep.title")} icon="message-exclamation" />
            <Modal.Body padding={24}>
                <Input.MutationProvider value={{
                    name: "updateTicketEscalationStep",
                    id: this.state.id,
                    data: this.state.ticketEscalationStep,
                    onSave: () => this.props.refresh()
                }}>
                    <div style={{ padding: "16px", border: `3px dashed ${this.state.color || "#cccccc"}`, display: "grid", gridGap: "32px", gridTemplateColumns: "1fr", width: "480px" }}>
                        <Input required field="description" icon="tag" label={i18next.t("page.private.settings.modals.createTicketEscalationStep.description")} adjustWidth />
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 16 }}>
                            <Input required int field="durationAmount" icon="tally" label={i18next.t("page.private.settings.modals.createTicketEscalationStep.durationAmount")} adjustWidth />
                            <Select noUnselect required field="durationType" icon="calendar-week" label={i18next.t("page.private.settings.modals.createTicketEscalationStep.durationType")} adjustWidth items={[
                                { key: "minutes", title: "Minuten" },
                                { key: "hours", title: "Stunden" },
                                { key: "days", title: "Tage" },
                                { key: "weeks", title: "Wochen" },
                                { key: "months", title: "Monate" },
                                { key: "years", title: "Jahre" },
                            ]} />
                        </div>
                        <CircleDesign>
                            <CirclePicker onChange={color => {
                                this.setState({ color: color.hex })

                                scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                        mutation($id: ID!, $color: String!) {
                                            updateTicketEscalationStep(id: $id, color: $color)
                                        }
                                    `,
                                    variables: {
                                        id: this.state.id,
                                        color: color.hex
                                    }
                                }).then(data => {
                                    if(data.data.updateTicketEscalationStep) this.props.refresh()
                                })
                            }} width="100%" />
                        </CircleDesign>
                    </div>
                    <div style={{ marginTop: 16 }}>
                        <Input adjustWidth label={i18next.t("page.private.settings.modals.createTicketEscalationStep.additionalEmails")} textArea placeholder="max@mustermann.de,peter@mustermann.de" icon="envelope" value={this.state.additionalEmails} onChange={additionalEmails => this.setState({ additionalEmails: additionalEmails.replace(/ /g, "") }, this.updateLists)} />
                    </div>
                    <Modal.Divider title={i18next.t("page.private.settings.modals.createTicketEscalationStep.priorities")} />
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                        {["low", "medium", "high"].map((priority, index) => <Checkbox key={index} label={i18next.t("page.private.ticket-system.createTicketModal.priorityTypes." + priority)} value={this.state.priorities.includes(priority)} onChange={checked => checked ? this.setState({ priorities: [...this.state.priorities, priority] }, this.updateLists) : this.setState({ priorities: this.state.priorities.filter(p => p !== priority) }, this.updateLists) } />)}
                    </div>
                    <Modal.Divider title={i18next.t("page.private.settings.modals.createTicketEscalationStep.types")} />
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                        {this.state.allTypes.map(type => <Checkbox key={type.id} label={type.name} value={this.state.types.includes(type.id)} onChange={checked => checked ? this.setState({ types: [...this.state.types, type.id] }, this.updateLists) : this.setState({ types: this.state.types.filter(p => p !== type.id) }, this.updateLists) } />)}
                    </div>
                    <Modal.Divider title={i18next.t("page.private.settings.modals.createTicketEscalationStep.statuses")} />
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                        {["unassigned", "assigned", "active", "done"].map((status, index) => <Checkbox key={index} label={i18next.t("page.private.ticket-system.tickets.status." + status)} value={this.state.statuses.includes(status)} onChange={checked => checked ? this.setState({ statuses: [...this.state.statuses, status] }, this.updateLists) : this.setState({ statuses: this.state.statuses.filter(p => p !== status) }, this.updateLists) } />)}
                    </div>
                </Input.MutationProvider>
            </Modal.Body>
        </Modal>
    }
}
