import React, { Component } from "react"
import styled from "styled-components"

const StyledGridElement = styled.div`
    background: ${props => props.styled ? "white" : "none"};
    border-radius: ${props => props.styled ? "5px" : 0};
    box-shadow: ${props => props.styled ? "0 1px 4px rgba(0, 0, 0, 0.15)" : "none"};
    height: ${props => props.height ? props.height : "fit-content"};
    grid-column-start: ${props => props.columnStart};
    grid-column-end: ${props => props.columnEnd};
    grid-row-start: ${props => props.rowStart || "auto"};
    grid-row-end: ${props => props.rowEnd || "auto"};

    .topTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        user-select: none;

        .header {
            display: flex;
            align-items: center;
        }

        i.topIcon {
            margin-right: 8px;
        }
    }

    .innerContent {
        padding: ${props => props.styled ? (props.padding || 0) : 0};
        height: ${props => (props.height && props.styled) ? "calc(100% - 52px)" : (props.height || "unset")};
    }
`

export default class extends Component {
    render() {
        return <StyledGridElement {...this.props}>
            {this.props.title && <div className="topTitle"><div className="header">{this.props.icon && <i className={`${this.props.iconType || "fa-duotone"} topIcon fa-${this.props.icon}`} />}<p>{this.props.title}</p></div>{this.props.rightContent}</div>}
            <div className="innerContent">
                {this.props.children}
            </div>
        </StyledGridElement>
    }
}
