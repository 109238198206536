import React, { Component } from 'react'
import { Select, Button, Input } from 'scanmetrix-components'
import styled from 'styled-components'

const EmptyView = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  
  img {
    width: 100%;
    max-width: 512px;
    user-select: none;
    pointer-events: none;
    user-drag: none;
  }
  
  p {
    margin-bottom: 64px;
    font-size: 1.4em;
    font-weight: 500;
    color: black;
    user-select: none;
    
    b {
      color: #3B97D3;
      font-weight: inherit;
    }
  }
`

const GridView = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  
  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    
    @media screen and (min-width: 1280px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    } 
  }
  grid-auto-rows: 1fr;
  grid-gap: 32px;
`

const WheelCard = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px;
  background: white;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  border-radius: 10px;
  box-sizing: border-box;
  transition: all 250ms ease;
  
  &:hover {
    transform: scale(1.025);
  }
  
  .header {
    width: 100%;
    padding-bottom: 32px;
    margin-bottom: 32px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    overflow: hidden;
    
    .icon-view {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      i {
        margin-bottom: 16px;
        font-size: 1.2em;
        color: rgba(0, 0, 0, .4);
      }
    }
    
    .wheel-view {
      width: 200px;
      height: 200px;

      img {
        width: 200px;
        height: 200px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        user-select: none;
        user-drag: none;
        pointer-events: none;
        z-index: 4;
      }
    }
  }
  
  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

class Wheel extends Component {
    state = {
        id: null,
        seasonType: null,
        type: null,
        location: null,
        amount: null,
        size: null,
        editable: false,
        loading: false
    }

    constructor(props) {
        super(props)

        this.state = { ...props.item, editable: false, loading: false }
    }

    render() {
        const editable = this.state.editable && !this.state.loading

        return <WheelCard>
            <div className='header'>
                <div className='icon-view'>
                    {this.state.seasonType === 'allSeason' ? <><i className='far fa-snowflake'/><i
                        className='far fa-sun'/></> : this.state.seasonType === 'summer' ?
                        <i className='far fa-sun'/> : this.state.seasonType === 'winter' ?
                            <i className='far fa-snowflake'/> : null}
                </div>
                <div className='wheel-view'>
                    <img src='/wheel.png'/>
                </div>
            </div>

            <div className='body'>
                <Select readOnly={!editable} onChange={seasonType => this.setState({ seasonType })} adjustWidth value={this.state.seasonType} margin='0 0 16px 0'
                        label='Saisontyp' icon='sun-cloud' items={[
                    { key: 'allSeason', title: 'Allwetterreifen' },
                    { key: 'summer', title: 'Sommerreifen' },
                    { key: 'winter', title: 'Winterreifen' }
                ]} />
                <Input readOnly={!editable} onChange={type => this.setState({ type })} adjustWidth value={this.state.type} margin='0 0 16px 0' label='Reifentyp Bezeichnung'
                       icon='exclamation'/>
                <Input readOnly={!editable} onChange={location => this.setState({ location })} adjustWidth value={this.state.location} margin='0 0 16px 0' label='Ablageort (z.B. am Fahrzeug)'
                       icon='search'/>
                <Input readOnly={!editable} onChange={amount => this.setState({ amount })} int adjustWidth value={this.state.amount} margin='0 0 16px 0'
                       label='Anzahl (i.d.R. 4)' icon='list-ol'/>
                <Input readOnly={!editable} onChange={size => this.setState({ size })} float adjustWidth value={this.state.size} margin='0 0 32px 0'
                       label='Größe (in Zoll)' icon='tire'/>

                <Button loading={this.state.loading} adjustWidth thick title={this.state.editable ? "Reifen speichern" : "Reifen bearbeiten"} green={this.state.editable} icon={this.state.editable ? "save" : "pencil-alt"} onClick={() => {
                    if(this.state.editable) {
                        this.setState({ loading: true })

                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($id: ID!, $seasonType: String!, $type: String!, $amount: Int!, $size: Float, $location: String!) {
                                    updateCarTire(id: $id, seasonType: $seasonType, type: $type, amount: $amount, size: $size, location: $location)
                                }
                            `,
                            variables: {
                                id: this.state.id,
                                size: this.state.size ? parseFloat(this.state.size, 10) : null,
                                amount: this.state.amount ? parseInt(this.state.amount, 10) : null,
                                type: this.state.type,
                                location: this.state.location,
                                seasonType: this.state.seasonType
                            }
                        }).then(result => {
                            if(result.data.updateCarTire) {
                                this.props.refresh()
                                this.setState({ loading: false, editable: false })
                            }
                        })
                    }
                    else this.setState({ editable: true })
                }} style={{ marginBottom: 16 }} />
                <Button disabled={!this.state.editable || this.state.loading} adjustWidth thick red title='Reifen entfernen' icon='trash' onClick={() => this.props.delete(this.state.id)} />
            </div>
        </WheelCard>
    }
}

export default class extends Component {
    state = {
        editable: false,
        loading: false
    }

    render() {
        return <>
            {this.props.data.length ?
                <GridView>
                    {this.props.data.map((item, index) => {
                        return <Wheel item={item} editable={this.state.editable} key={index} refresh={this.props.refresh} delete={this.props.delete} />
                    })}
                </GridView>
                :
                <EmptyView>
                    <p>Es befinden sich noch keine <b>Reifen</b> in dieser Liste.</p>
                    <img src="/empty.svg" />
                </EmptyView>
            }
        </>
    }
}