import React, { Component } from "react"

import RGL, { WidthProvider } from "react-grid-layout"

import i18next from "i18next"

const GridLayout = WidthProvider(RGL)

import StyledContainer from './styled/Container'
import StyledForm from './styled/Form'
import Pseudo from './styled/Pseudo'
import FormItem from "./styled/FormItem"
import Title from "./styled/Title"
import NoItems from "./styled/NoItems"

import { Section, Input } from "scanmetrix-components"




export default class extends Component {
    state = { manufacturers: [], approvals: [] }

    getManufacturer(id) {
        if(!id) return {}

        const found = this.state.manufacturers.find(manufacturer => String(manufacturer.id) === String(id))

        if(found) {
            if(found.loaded) return found

            return {}
        }

        this.state.manufacturers.push({
            id: id,
            loaded: false
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    manufacturer(id: $id) {
                        id
                        name
                    }
                }
            `,
            variables: {
                id: id
            }
        }).then(data => {
            const manufacturer = data.data.manufacturer
            let manufacturers = this.state.manufacturers

            manufacturers.forEach((target, index) => {
                if(String(target.id) === String(id)) manufacturers[index] = { ...manufacturer, loaded: true }
            })

            this.setState({ manufacturers })
        })

        return {}
    }

    getApproval(id) {
        if(!id) return {}

        const found = this.state.approvals.find(approval => String(approval.id) === String(id))

        if(found) {
            if(found.loaded) return found

            return {}
        }

        this.state.approvals.push({
            id: id,
            loaded: false
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    approval(id: $id) {
                        id
                        number
                    }
                }
            `,
            variables: {
                id: id
            }
        }).then(data => {
            const approval = data.data.approval
            let approvals = this.state.approvals

            approvals.forEach((target, index) => {
                if(String(target.id) === String(id)) approvals[index] = { ...approval, loaded: true }
            })

            this.setState({ approvals })
        })

        return {}
    }

    render() {
        if(!this.props.items) return null
        let data = this.props.data || {}

        return <StyledContainer>
            {this.props.items.length === 0 && <NoItems>
                <div>
                    <h1>{i18next.t("component.dataForm.inputScreen.title")}</h1>
                    <h2>{i18next.t("component.dataForm.inputScreen.description")}</h2>
                </div>
            </NoItems>}
            {this.props.items.length > 0 && <Section bodyPadding="8px 0 8px 5px">
                {this.props.items.map((topItem, index) => <Section.Item icon={topItem.icon} title={topItem.title} key={index}>
                    {topItem.items.length === 0 && <NoItems>
                        <div>
                            <h1>{i18next.t("component.dataForm.section.title")}</h1>
                            <h2>{i18next.t("component.dataForm.section.description")}</h2>
                        </div>
                    </NoItems>}
                    {topItem.items.length > 0 && <StyledForm>
                        <div className="formContainer">
                            <GridLayout isDraggable={false} isResizable={false} margin={[16, 16]} className="layout" cols={6} rowHeight={68} autoSize={true} compactType="vertical" useCSSTransforms={false} measureBeforeMount={true}>
                                {topItem.items.map(item => {
                                    let maxY = 1

                                    topItem.items.forEach(target => {
                                        if(target.y > maxY) maxY = target.y
                                    })

                                    const format = value => {
                                        if(value === null) return null
                                        if(!item.input.format) return value
                                        if(!item.input.format.length) return value

                                        return item.input.format.replace(/{value}/g, value)
                                    }

                                    let value = data[topItem.uuid]
                                    if(value) value = value[item.input.dataKey] || null
                                    else value = null

                                    return <FormItem style={{ zIndex: maxY - item.y }} onContextMenu={e => {
                                        e.preventDefault()
                                        this.inputFieldModal.open({ data: item })
                                    }} key={item.i} data-grid={item}>
                                        <Pseudo>
                                            {item.input.type === "string" && <Input readOnly format={format} adjustWidth label={item.input.label} icon={item.input.icon} value={value} />}
                                            {item.input.type === "date" && <Input readOnly format={format} adjustWidth label={item.input.label} icon={item.input.icon} value={value} />}
                                            {item.input.type === "float" && <Input readOnly format={format} adjustWidth label={item.input.label} icon={item.input.icon} value={value} />}
                                            {item.input.type === "int" && <Input readOnly format={format} adjustWidth label={item.input.label} icon={item.input.icon} value={value} />}
                                            {item.input.type === "manufacturer" && <Input readOnly format={format} adjustWidth label={item.input.label} icon={item.input.icon} value={this.getManufacturer(value).name} />}
                                            {item.input.type === "approval" && <Input readOnly format={format} adjustWidth label={item.input.label} icon={item.input.icon} value={this.getApproval(value).number} />}
                                            {item.input.type === "select" && <Input readOnly adjustWidth label={item.input.label} icon={item.input.icon} value={(item.input.selectItems.find(item => item.uuid === value) || {}).name} />}
                                            {item.input.type === "boolean" && <Input readOnly adjustWidth label={item.input.label} icon={value ? "check" : "times"} value={value ? "Ja" : "Nein"} />}
                                            {item.input.type === "title" && <Title>{item.input.label}</Title>}
                                        </Pseudo>
                                    </FormItem>
                                })}
                            </GridLayout>
                        </div>
                    </StyledForm>}
                </Section.Item>)}
            </Section>}
        </StyledContainer>
    }
}
