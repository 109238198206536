import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import countries from "i18n-iso-countries"
import i18next from "i18next"

countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

export default class extends Component {
    state = { name: null, responsibility: null, contactEmail: null, addressStreetName: null, addressStreetNumber: null, addressPostalCode: null, addressCity: null, addressCountry: null, addressAdditional: null, loading: false }

    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ name: null, responsibility: null, contactEmail: null, loading: false, addressStreetName: null, addressStreetNumber: null, addressPostalCode: null, addressCity: null, addressCountry: null, addressAdditional: null })}>
            <Modal.Head title={i18next.t("page.private.serviceProviders.createserviceProvidersModal.titleForm")} icon="user-tie" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Modal.Divider title={i18next.t("page.private.serviceProviders.createserviceProvidersModal.generalInformation")} />
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.serviceProviders.createserviceProvidersModal.name")} adjustWidth />
                    <Input required value={this.state.responsibility} onChange={responsibility => this.setState({ responsibility })} icon="hard-hat" label={i18next.t("page.private.serviceProviders.createserviceProvidersModal.Responsibility")} adjustWidth />
                    <Input value={this.state.contactEmail} onChange={contactEmail => this.setState({ contactEmail })} icon="at" label={i18next.t("page.private.serviceProviders.createserviceProvidersModal.email")} adjustWidth />
                    <Modal.Divider title={i18next.t("page.private.serviceProviders.createserviceProvidersModal.address")} />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "2fr 0.5fr" }}>
                        <Input value={this.state.addressStreetName} onChange={addressStreetName => this.setState({ addressStreetName })} icon="road" label={i18next.t("page.private.serviceProviders.createserviceProvidersModal.street")} adjustWidth />
                        <Input value={this.state.addressStreetNumber} onChange={addressStreetNumber => this.setState({ addressStreetNumber })} icon="bell" label={i18next.t("page.private.serviceProviders.createserviceProvidersModal.houseNumber")} adjustWidth />
                    </div>
                    <Input value={this.state.addressAdditional} onChange={addressAdditional => this.setState({ addressAdditional })} icon="font" label={i18next.t("page.private.serviceProviders.createserviceProvidersModal.additionalAddress")} adjustWidth />
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 2fr" }}>
                        <Input value={this.state.addressPostalCode} onChange={addressPostalCode => this.setState({ addressPostalCode })} icon="mailbox" label={i18next.t("page.private.serviceProviders.createserviceProvidersModal.postalCode")} adjustWidth />
                        <Input value={this.state.addressCity} onChange={addressCity => this.setState({ addressCity })} icon="city" label={i18next.t("page.private.serviceProviders.createserviceProvidersModal.city")} adjustWidth />
                    </div>
                    <Select upwards adjustWidth value={this.state.addressCountry} onChange={addressCountry => this.setState({ addressCountry })} icon="globe-europe" label={i18next.t("page.private.serviceProviders.createserviceProvidersModal.country")} items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.responsibility} title={i18next.t("page.private.serviceProviders.createserviceProvidersModal.exitButton")} icon="user-tie" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $responsibility: String!, $contactEmail: String, $addressStreetName: String, $addressStreetNumber: String, $addressAdditional: String, $addressPostalCode: String, $addressCity: String, $addressCountry: String) {
                            createServiceProvider(name: $name, responsibility: $responsibility, contactEmail: $contactEmail, addressStreetName: $addressStreetName, addressStreetNumber: $addressStreetNumber, addressAdditional: $addressAdditional, addressPostalCode: $addressPostalCode, addressCity: $addressCity, addressCountry: $addressCountry)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        responsibility: this.state.responsibility,
                        contactEmail: this.state.contactEmail,
                        addressStreetName: this.state.addressStreetName,
                        addressStreetNumber: this.state.addressStreetNumber,
                        addressAdditional: this.state.addressAdditional,
                        addressPostalCode: this.state.addressPostalCode,
                        addressCity: this.state.addressCity,
                        addressCountry: this.state.addressCountry
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createServiceProvider) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
