import styled from "styled-components"

export default styled.div`
  height: 64px;
  width: 64px;
  user-select: none;
    flex-shrink: 0;
    flex-grow: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  color: #3b97d3;
  font-weight: bold;
  background: white;
  padding: 4px;
  border-radius: 100%;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  
  @media screen and (max-width: 1920px) {
    height: 56px;
    width: 56px;
  }
  
  .value {
    position: absolute;
  }
`
