import React, { useState } from 'react';
import Progressbar from "./Progress_bar";
import "./DialogPage.css"

const DialogPage = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [organization, setOrganization] = useState({
    companyName: '',
    legalName: '',
    streetName: '',
    streetNumber: '',
    additionalAddress: '',
    postalCode: '',
    city: '',
    country: ''
  });
  const [logoFile, setLogoFile] = useState(null);
  const [emailDomains, setEmailDomains] = useState([]);
  const [minPasswordSecurity, setMinPasswordSecurity] = useState(20);
  const [minPasswordResetInterval, setMinPasswordResetInterval] = useState(0);
  const [ssoSettings, setSsoSettings] = useState({
    domain: '',
    entityURI: '',
    entryPoint: '',
    certificateFile: null,
    entityReplyURL: '',
  });
  const [smtpSettings, setSmtpSettings] = useState({
    host: '',
    port: '',
    secure: true,
    fromEmail: '',
    username: '',
    password: ''
  });

  
  const handleFileChange = (event) => {
    setLogoFile(event.target.files[0]);
  };

  const handleEmailDomainChange = (event) => {
    const newDomain = event.target.value.trim();
    if (newDomain) {
      setEmailDomains([...emailDomains, newDomain]);
    }
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform input validation here
    if (step === 7) {
      // if (validateInput()) {
      // Call a function to save settings (not shown for brevity)
      // saveSettings(userData, organization, logoFile, emailDomains, minPasswordSecurity, minPasswordResetInterval, ssoSettings, smtpSettings);
      // Call a function to finalize installation (not shown for brevity)
      // finalizeInstallation();
    } else {
      handleNext();
    }
  };

  const [authenticationDuration, setAuthenticationDuration] = useState(48);
  const [defaultLanguage, setDefaultLanguage] = useState('english');
  const [disabledAccountingStates, setDisabledAccountingStates] = useState('waitingForPayment');
  const [standardModules, setStandardModules] = useState('facilityManagement');


  const [viewportConstraintEnabled, setViewportConstraintEnabled] = useState(false);

  const handleViewportConstraintChange = (event) => {
    setViewportConstraintEnabled(event.target.checked);
  };

  return (
    <div className="first-user-install-dialog">
      <span>
         <img src="https://betreiber.scanmetrix.io/logo_text_white.svg/" alt="Scanmetrix Logo" />
       </span> 
      <h2>COCK<span className='pit'>PIT</span> INSTALLATION</h2>

      <Progressbar currentStep={step} />
            
      <form onSubmit={handleSubmit}>
        {step === 1 && (
        <div>
            
            <div className="user-box" style={{ position: 'relative' }}>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={userData.firstName}
            onChange={(event) => setUserData({ ...userData, firstName: event.target.value })}
            required 
          />
          <label htmlFor="firstName" style={{ display: 'flex', alignItems: 'center' }}>
            First Name: 
            <i className="fas fa-user" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
          </label>
        </div>
        
        <div class="user-box">
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={userData.lastName}
          onChange={(event) => setUserData({ ...userData, lastName: event.target.value })}
          required
        />
        <label htmlFor="lastName" style={{ display: 'flex', alignItems: 'center' }}>
          Last Name:
          <i className="fas fa-angle-double-right" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
          </label>
        </div>

        <div class="user-box">
          <input
            type="email"
            id="email"
            name="email"
            value={userData.email}
            onChange={(event) => setUserData({ ...userData, email: event.target.value })}
            required
          />
          <label htmlFor="email" style={{ display: 'flex', alignItems: 'center' }}>
            Email:
          <i className="fas fa-envelope" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
          </label>
        </div>

        <div class="user-box">
          <input
            type="password"
            id="password"
            name="password"
            value={userData.password}
            onChange={(event) => setUserData({ ...userData, password: event.target.value })}
            required
          />
          <label htmlFor="password" style={{ display: 'flex', alignItems: 'center' }}>
            Password:
          <i className="fas fa-lock" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
          </label>
        </div>

        </div>
        )}

        {/* Step 2: Organization Details Section */}
        {step === 2 && (
        <div>

        <div class="user-box">
        <input
            type="text"
            id="companyName"
            name="companyName"
            value={organization.companyName}
            onChange={(event) => setOrganization({ ...userData, companyName: event.target.value })}
            required
          />
         <label htmlFor="companyName" style={{ display: 'flex', alignItems: 'center' }}>
          Company Name:
          <i className="fas fa-building" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
          </label>
        </div>

        <div class="user-box">
        <input
          type="text"
          id="legalName"
          name="legalName"
          value={organization.legalName}
          onChange={(event) => setOrganization({ ...userData, legalName: event.target.value })}
          required
        />
        <label htmlFor="legalName" style={{ display: 'flex', alignItems: 'center' }}>
          Legal Name:
          <i className="fas fa-id-card" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
          </label>
        </div>

        <div class="user-box">
        <input type="file" id="logo" name="logo" accept="image/png" onChange={handleFileChange} />
        <label htmlFor="logo" style={{ display: 'flex', alignItems: 'center' }}>
          Upload Logo (PNG):
          <i className="fas fa-paint-brush" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
          </label>
        </div>

        <div class="user-box">
             <input
               type="email"
               id="emailDomains"
               name="emailDomains"
               placeholder="Enter domain (e.g., @gmx.de)"
               onChange={handleEmailDomainChange}
             />
             <label htmlFor="emailDomains" style={{ display: 'flex', alignItems: 'center' }}>
              Allowed Email Domains:
              <i className="fas fa-list-ul" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
           </div>
           {/* <div class="user-box">
             <input
               type="range"
               id="minPasswordSecurity"
               name="minPasswordSecurity"
               min={20}
               max={100}
               value={minPasswordSecurity}
               onChange={(event) => setMinPasswordSecurity(event.target.value)}
             />
             <label htmlFor="minPasswordSecurity">Minimum Password Security <span>{minPasswordSecurity}%</span>:</label>
           </div> */}

           <div class="user-box">
             <input
               type="number"
               id="minPasswordResetInterval"
               name="minPasswordResetInterval"
               value={minPasswordResetInterval}
               onChange={(event) => setMinPasswordResetInterval(event.target.value)}
             />
             <label htmlFor="minPasswordResetInterval" style={{ display: 'flex', alignItems: 'center' }}>
               Minimum Password Reset Interval (seconds):
              <i className="fas fa-clock" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
             </label>
           </div>

        </div>
        )}

        {/* Step 3: User Management Section */}
        {step === 3 && (
         <div>
           {/* <h3>User Management</h3> */}
           <div class="user-box">
          <input
            type="text"
            id="streetName"
            name="streetName"
            value={organization.streetName}
            onChange={(event) => setOrganization({ ...userData, streetName: event.target.value })}
          required
          />
          <label htmlFor="streetName" style={{ display: 'flex', alignItems: 'center' }}>
            Street Name:
              <i className="fas fa-map-marker" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
        </div>

        <div class="user-box">
          <input
            type="text"
            id="streetNumber"
            name="streetNumber"
            value={organization.streetNumber}
            onChange={(event) => setOrganization({ ...userData, streetNumber: event.target.value })}
            required
          />
          <label htmlFor="streetNumber" style={{ display: 'flex', alignItems: 'center' }}>
            Street Number:
              <i className="fas fa-hashtag" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
        </div>

        <div class="user-box">
          <input
            type="text"
            id="additionalAddress"
            name="additionalAddress"
            value={organization.additionalAddress}
            onChange={(event) => setOrganization({ ...userData, additionalAddress: event.target.value })}
          />
          <label htmlFor="additionalAddress" style={{ display: 'flex', alignItems: 'center' }}>
            Additional Address:
              <i className="fas fa-address-card" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
        </div>

        <div class="user-box">
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={organization.postalCode}
            onChange={(event) => setOrganization({ ...userData, postalCode: event.target.value })}
            required
          />
          <label htmlFor="postalCode" style={{ display: 'flex', alignItems: 'center' }}>
            Postal Code:
              <i className="fas fa-envelope" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
        </div>

        <div class="user-box">
          <input
            type="text"
            id="city"
            name="city"
            value={organization.city}
            onChange={(event) => setOrganization({ ...userData, city: event.target.value })}
            required
          />
          <label htmlFor="city" style={{ display: 'flex', alignItems: 'center' }}>
            City:
              <i className="fas fa-city" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
        </div>

        <div class="user-box">
          <input
            type="text"
            id="country"
            name="country"
            value={organization.country}
            onChange={(event) => setOrganization({ ...userData, country: event.target.value })}
            required
          />
          <label htmlFor="country" style={{ display: 'flex', alignItems: 'center' }}>
            Country:
              <i className="fas fa-globe" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
        </div>

          </div>
       )}

        {/* Step 4: Microsoft Single Sign-On (SSO) */}
        {step === 4 && (
        <div>

        <div>
          {/* <h3>Microsoft Single Sign-On (SSO)</h3> */}
          <div className="sso-settings">
              <div class="user-box">
                <input
                  type="text"
                  id="ssoDomain"
                  name="ssoDomain"
                  value={ssoSettings.domain}
                  onChange={(event) =>
                    setSsoSettings({ ...ssoSettings, domain: event.target.value })
                  }
                />
                <label htmlFor="ssoDomain" style={{ display: 'flex', alignItems: 'center' }}>
                  Domain:
              <i className="fas fa-globe" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                  </label>
              </div>

              <div class="user-box">
                <input
                  type="text"
                  id="entityURI"
                  name="entityURI"
                  value={ssoSettings.entityURI}
                  onChange={(event) =>
                    setSsoSettings({ ...ssoSettings, entityURI: event.target.value })
                  }
                />
                <label htmlFor="entityURI" style={{ display: 'flex', alignItems: 'center' }}>
                  Entity URI:
              <i className="fas fa-link" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                  </label>
              </div>

              <div class="user-box">
                <input
                  type="text"
                  id="entryPoint"
                  name="entryPoint"
                  value={ssoSettings.entryPoint}
                  onChange={(event) =>
                    setSsoSettings({ ...ssoSettings, entryPoint: event.target.value })
                  }
                />
                <label htmlFor="entryPoint" style={{ display: 'flex', alignItems: 'center' }}>
                  Entry Point:
              <i className="fas fa-sign-in-alt" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                  </label>
              </div>

              <div class="user-box">
                <input
                  type="text"
                  id="entityReplyURL"
                  name="entityReplyURL"
                  value={ssoSettings.entityReplyURL}
                  onChange={(event) =>
                    setSsoSettings({ ...ssoSettings, entityReplyURL: event.target.value })
                  }
                />
                <label htmlFor="entityReplyURL" style={{ display: 'flex', alignItems: 'center' }}>
                  Entity Reply URL:
              <i className="fas fa-reply" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                  </label>
              </div>

              <div class="user-box">
                <input
                  type="file"
                  id="certificate"
                  name="certificate"
                  onChange={(event) =>
                    setSsoSettings({ ...ssoSettings, certificateFile: event.target.files[0] })
                  }
                />
                <label htmlFor="certificate" style={{ display: 'flex', alignItems: 'center' }}>
                  Certificate Upload:
              <i className="fas fa-file-upload" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                  </label>
              </div>
            </div>
        </div>

        </div>
        )}


        {/* Step 5 */}
        {step === 5 && (
        <div>

        <div class="user-box">
          <input
            type="number"
            id="authenticationDuration"
            name="authenticationDuration"
            value={authenticationDuration}
            onChange={(event) => setAuthenticationDuration(event.target.value)}
          />
          <label htmlFor="authenticationDuration" style={{ display: 'flex', alignItems: 'center' }}>
            Authentication Duration (hours):
            <i className="fas fa-clock" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
          </label>
        </div>

        <div class="user-box">
          <select
            id="defaultLanguage"
            name="defaultLanguage"
            value={defaultLanguage}
            onChange={(event) => setDefaultLanguage(event.target.value)}
          >
            <option value="german">German</option>
            <option value="english">English</option>
          </select>
          <label htmlFor="defaultLanguage" style={{ display: 'flex', alignItems: 'center' }}>
            Default Language:
              <i className="fas fa-language" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
        </div>

        <div class="user-box">
          <select 
            id="disabledAccountingStates"
            name="disabledAccountingStates"
            value={disabledAccountingStates}
            onChange={(event) => setDisabledAccountingStates(event.target.value)}
          >
            <option value="waitingForPayment">Waiting For Payment</option>
            <option value="fiClearance">FI Clearance</option>
            <option value="factualClearance">Factual Clearance</option>
          </select>
          <label htmlFor="disabledAccountingStates" style={{ display: 'flex', alignItems: 'center' }}>
            Disabled Accounting States:
              <i className="fas fa-ban" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
        </div>

        <div class="user-box">
          <select
            id="standardModules"
            name="standardModules"
            value={standardModules}
            onChange={(event) => setStandardModules(event.target.value)}
          >
            <option value="facilityManagement">Facility Management</option>
            <option value="documents">Documents</option>
          </select>
          <label htmlFor="standardModules" style={{ display: 'flex', alignItems: 'center' }}>
            Standard Modules for New Users:
              <i className="fas fa-puzzle-piece" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
            </label>
        </div>

        </div>
        )}

        {/* Step 6: Email Configuration Section */}
        {step === 6 && (
        <div>

        {/* <h3>Email Configuration</h3> */}
          {/* <div className="smtp-settings"> */}
            <div class="user-box">
              <input
                type="text"
                id="smtpHost"
                name="smtpHost"
                value={smtpSettings.host}
                onChange={(event) =>
                  setSmtpSettings({ ...smtpSettings, host: event.target.value })
                }
                required
              />
              <label htmlFor="smtpHost" style={{ display: 'flex', alignItems: 'center' }}>
                SMTP Host:
              <i className="fas fa-server" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                </label>
            </div>

            <div class="user-box">
              <input
                type="number"
                id="smtpPort"
                name="smtpPort"
                value={smtpSettings.port}
                onChange={(event) =>
                  setSmtpSettings({ ...smtpSettings, port: event.target.value })
                }
                required
              />
              <label htmlFor="smtpPort" style={{ display: 'flex', alignItems: 'center' }}>
                SMTP Port:
              <i className="fas fa-sign-out-alt" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                </label>
            </div>

            <div class="user-box">
              <select
                id="smtpSecure"
                name="smtpSecure"
                value={smtpSettings.secure}
                onChange={(event) =>
                  setSmtpSettings({ ...smtpSettings, secure: event.target.value })
                }
                required
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <label htmlFor="smtpSecure" style={{ display: 'flex', alignItems: 'center' }}>
                SMTP Secure:
              <i className="fas fa-lock" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                </label>
            </div>

            <div class="user-box">
              <input
                type="email"
                id="smtpFromEmail"
                name="smtpFromEmail"
                value={smtpSettings.fromEmail}
                onChange={(event) =>
                  setSmtpSettings({ ...smtpSettings, fromEmail: event.target.value })
                }
                required
              />
              <label htmlFor="smtpFromEmail" style={{ display: 'flex', alignItems: 'center' }}>
                From Email:
              <i className="fas fa-envelope" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                </label>
            </div>

            <div class="user-box">
              <input
                type="text"
                id="smtpUsername"
                name="smtpUsername"
                value={smtpSettings.username}
                onChange={(event) =>
                  setSmtpSettings({ ...smtpSettings, username: event.target.value })
                }
                required
              />
              <label htmlFor="smtpUsername" style={{ display: 'flex', alignItems: 'center' }}>
                SMTP Username:
              <i className="fas fa-user" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                </label>
            </div>

            <div class="user-box">
              <input
                type="password"
                id="smtpPassword"
                name="smtpPassword"
                value={smtpSettings.password}
                onChange={(event) =>
                  setSmtpSettings({ ...smtpSettings, password: event.target.value })
                }
                required
              />
              <label htmlFor="smtpPassword" style={{ display: 'flex', alignItems: 'center' }}>
                SMTP Password:
              <i className="fas fa-key" style={{ color: '#fff', position: 'absolute', left: '280px', top: '50%', transform: 'translateY(-50%)' }} />
                </label>
            </div>
          {/* </div> */}

          </div>
          )}

        {/* Step 7: checkboxes */}
        {step === 7 && (
        <div>

        {/* <h3>User Registration</h3> */}
        <div class="user-box-new">
          <label htmlFor="viewportConstraintEnabled">
            Desktop/mobile viewport constraint:
            <input
              type="checkbox"
              id="viewportConstraintEnabled"
              name="viewportConstraintEnabled"
              checked={viewportConstraintEnabled}
              onChange={handleViewportConstraintChange}
            />
          </label>
        </div>

        <div class="user-box-new">
          <label htmlFor="autoApproveUsers">
          Automatically Approve New Users:
          <input
            type="checkbox"
            id="autoApproveUsers"
            name="autoApproveUsers"
          />
          </label>
        </div>

         <div class="user-box-new">
          <label htmlFor="copyEmailsViaBcc">
            Copy Emails via BCC to the User:
            <input
              type="checkbox"
              id="copyEmailsViaBcc"
              name="copyEmailsViaBcc"
            />
          </label>
        </div>

        <div class="user-box-new">
          <label htmlFor="allowSelfRegistration">
            Allow Self-Registration:
            <input
              type="checkbox"
              id="allowSelfRegistration"
              name="allowSelfRegistration"
            />
          </label>
        </div>

        <div class="user-box-new">
          <label htmlFor="enableGravatar">
            Enable Gravatar:
            <input
              type="checkbox"
              id="enableGravatar"
              name="enableGravatar"
            />
          </label>
        </div>

        </div>
        )}

        <button type="submit" class="user-box">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {step === 7 ? 'Save' : 'Next'}
          </button>
        {step > 1 && (
          <button type="button" onClick={handlePrevious}>
          {/* <span></span>
          <span></span>
          <span></span>
          <span></span> */}
            Back
          </button>
        )}
        {step >= 2 && ( // Conditionally apply CSS for second page
        <style>{`.first-user-install-dialog button { margin-left: 40px; margin-right: 0px }`}</style>
       )}
      </form>
    </div>
  );
};

export default DialogPage;











