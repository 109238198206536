import styled from "styled-components"

export default  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    width: 200px;
    text-align: center;
    line-height: 1.4em;
    font-size: 1.05em;
    user-select: none;
    
    .icon {
        font-size: 3em;
        margin-bottom: 16px;
        color: #2980b9;
    }
    
    button {
      outline: 0;
      border: none;
      height: 32px;
      padding: 0 16px;
      margin-top: 16px;
      color: #20242b;
      background: white;
      border-radius: 32px;
      cursor: pointer;
      transition: opacity 0.3s;
      box-shadow: 0 3px 8px -4px rgba(0, 0, 0, 0.4);
      
      &:hover {
        opacity: 0.75;
      }
      
      i {
        margin-right: 8px;
      }
      
      &.primary {
        background: #3B97D3;
        color: white;
      }
    }
`