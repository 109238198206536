import styled from "styled-components"

export default styled.div`
  width: 100%;
  padding-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
`