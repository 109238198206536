import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"
import {Checkbox} from "../../../../components"
import i18next from "i18next";

export default class extends Component {
    state = { checked: false, error: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ checked: false, error: null, loading: false })
        }}>
            <Modal.Head title={i18next.t("page.private.purchaseOrder.cfdsModal.title")} icon="sync" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16, marginTop: -16 }}>
                    <Modal.Info maxWidth="650px">{i18next.t("page.private.purchaseOrder.cfdsModal.text1")}{/*Der Auftrag wechselt umgehend in den Automatikmodus, was dazu führt, dass Sie den Fortschritt nicht mehr manuell bestimmen können. Erst durch Datenbereitstellung über scanmetrix Mission seitens Ihres Dienstleisters verändert der Auftrag seinen Status.*/}<br /><br /><b>{i18next.t("page.private.purchaseOrder.cfdsModal.text2")}</b>{i18next.t("page.private.purchaseOrder.cfdsModal.text3")}</Modal.Info>
                    {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
                    <Checkbox readOnly={this.state.loading} label={i18next.t("page.private.purchaseOrder.cfdsModal.understoodCheckbox")} value={this.state.checked} onChange={checked => this.setState({ checked })} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.checked} primary thick title={i18next.t("page.private.purchaseOrder.cfdsModal.button")} icon="sync" onClick={() => {
                this.setState({ loading: true, error: null })

                fetch(scanmetrix.backendURL + "/purchaseorder/cfds/" + this.props.purchaseOrderId, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(result => result.json()).then(result => {
                    if(result.status === "SUCCESS") {
                        instance.close()
                        this.props.refresh()
                    } else {
                        this.setState({ loading: false, error: result.error })
                    }
                })
            }} />} />
        </Modal>
    }
}
