import React, { Component } from "react"
import { Modal, Button, Select, Input } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = {
        typeId: null,
        priority: null,
        title: null,
        description: null,
        subsidiaryId: null,
        userId: null,
        smid: null,
        loading: false
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({
                typeId: null,
                priority: null,
                title: null,
                description: null,
                subsidiaryId: null,
                userId: null,
                smid: null,
                loading: false
            })
        }}>
            <Modal.Head title={i18next.t("page.private.ticket-system.createTicketModal.titleForm")} icon="comments-alt" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16, gridTemplateColumns: "1fr 1fr" }}>
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <Select
                            adjustWidth
                            value={this.state.subsidiaryId}
                            onChange={subsidiaryId => this.setState({ subsidiaryId })}
                            icon="map-marker"
                            label={i18next.t("page.private.ticket-system.createTicketModal.branch")}
                            required
                            items={this.props.subsidiaries.map(subsidiary => ({
                                key: subsidiary.id,
                                title: `${subsidiary.name} (${subsidiary.label || subsidiary.id}) / ${subsidiary.address.inline}`
                            }))}
                        />
                    </div>
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 2 }}>
                        <Select
                            adjustWidth
                            value={this.state.userId}
                            onChange={userId => this.setState({ userId })}
                            icon="user"
                            label={i18next.t("page.private.ticket-system.createTicketModal.assignUser")}
                            items={this.props.users.map(user => ({
                                key: user.id,
                                title: `${user.firstName} ${user.lastName} / ${user.email}`
                            }))}
                        />
                    </div>
                    <div style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
                        <Input
                            adjustWidth
                            label={i18next.t("page.private.ticket-system.createTicketModal.smid")}
                            icon="qrcode"
                            value={this.state.smid}
                            onChange={smid => this.setState({ smid })}
                            placeholder={i18next.t("page.private.ticket-system.createTicketModal.smidSearchPlaceholder")}
                        />
                    </div>
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 2 }}>
                        <Select
                            adjustWidth
                            label={i18next.t("page.private.ticket-system.createTicketModal.ticketType")}
                            icon="cog"
                            value={this.state.typeId}
                            required
                            onChange={typeId => this.setState({ typeId })}
                            noUnselect
                            items={this.props.types.filter(type => type.active).map(type => ({
                                key: type.id, title: type.name
                            }))}
                        />
                    </div>
                    <div style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
                        <Select
                            adjustWidth
                            label={i18next.t("page.private.ticket-system.createTicketModal.priority")}
                            icon="exclamation"
                            value={this.state.priority}
                            required
                            onChange={priority => this.setState({ priority })}
                            noUnselect
                            items={[
                                { key: "low", title: i18next.t("page.private.ticket-system.createTicketModal.priorityTypes.low") },
                                { key: "medium", title: i18next.t("page.private.ticket-system.createTicketModal.priorityTypes.medium") },
                                { key: "high", title: i18next.t("page.private.ticket-system.createTicketModal.priorityTypes.high") }
                            ]}
                        />
                    </div>
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <Input
                            adjustWidth
                            label={i18next.t("page.private.ticket-system.createTicketModal.title")}
                            icon="font"
                            value={this.state.title}
                            required
                            onChange={title => this.setState({ title })}
                            placeholder={i18next.t("page.private.ticket-system.createTicketModal.titleSearchPlaceholder")}
                        />
                    </div>
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                        <Input
                            adjustWidth
                            textArea
                            label={i18next.t("page.private.ticket-system.createTicketModal.description")}
                            icon="sticky-note"
                            value={this.state.description}
                            onChange={description => this.setState({ description })}
                            placeholder={i18next.t("page.private.ticket-system.createTicketModal.descriptionSearchPlaceholder")}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.typeId || !this.state.priority || !this.state.title || !this.state.subsidiaryId} thick title={i18next.t("page.private.ticket-system.createTicketModal.exitButton")} icon="comments-alt" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($typeId: ID!, $priority: String!, $title: String!, $description: String, $subsidiaryId: ID!, $userId: ID, $smid: String) {
                            createTicket(typeId: $typeId, priority: $priority, title: $title, description: $description, subsidiaryId: $subsidiaryId, userId: $userId, smid: $smid)
                        }
                    `,
                    variables: {
                        typeId: this.state.typeId,
                        priority: this.state.priority,
                        title: this.state.title,
                        description: this.state.description,
                        subsidiaryId: this.state.subsidiaryId,
                        userId: this.state.userId,
                        smid: this.state.smid
                    }
                }).then(result => {
                    if(result.data.createTicket) {
                        this.props.refresh().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                })}
            } />} />
        </Modal>
    }
}
