import styled from "styled-components"

export default styled.div`
  width: 100%;
  height: 256px;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 256px;
    object-fit: cover;
  }
`