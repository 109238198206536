import styled from "styled-components"

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 48px;
  box-sizing: border-box;
  user-select: none;
  
  h1 {
    color: #3b97d3;
    margin: 32px 0;
    font-size: 1.8em;
  }
  
  @media screen and (max-width: 1920px) {
    padding: 0 32px;
    font-size: 0.9em;
  }
`
