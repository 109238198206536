import React, { Component } from "react"
import { Modal, Button, Checkbox } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { id: null, standardEquipment: true, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={workSection => this.setState({ id: workSection.id, standardEquipment: workSection.standardEquipment, loading: false })}>
            <Modal.Head title={i18next.t("page.private.settings.modals.updateWorkSectionCFDSModal.title")} icon="hard-hat" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16 }}>
                    <Checkbox value={this.state.standardEquipment} onChange={standardEquipment => this.setState({ standardEquipment })} label={i18next.t("page.private.settings.modals.updateWorkSectionCFDSModal.standardBuildingEquipment")} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.settings.modals.updateWorkSectionCFDSModal.updateButton")} icon="hard-hat" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $standardEquipment: Boolean!) {
                            updateWorkSection(id: $id, standardEquipment: $standardEquipment)
                        }
                    `,
                    variables: {
                        id: this.state.id,
                        standardEquipment: this.state.standardEquipment
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.updateWorkSection) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
