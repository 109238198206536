import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { year: null, subsidiaryTenantId: null, type: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ year: null, subsidiaryTenantId: null, type: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.createSubsidiaryTenantUtlitiesStatementModal.title")} icon="home" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Select required value={this.state.subsidiaryTenantId} onChange={subsidiaryTenantId => this.setState({ subsidiaryTenantId })} label={i18next.t("page.private.subsidiary.styled.createSubsidiaryTenantUtlitiesStatementModal.landlord")} icon="home" items={this.props.tenants.map(tenant => ({ key: tenant.id, title: tenant.name }))} adjustWidth />
                    <Input required value={this.state.year} onChange={year => this.setState({ year })} icon="calendar" int label={i18next.t("page.private.subsidiary.styled.createSubsidiaryTenantUtlitiesStatementModal.Jahr")} adjustWidth />
                    <Select required value={this.state.type} onChange={type => this.setState({ type })} label={i18next.t("page.private.subsidiary.styled.createSubsidiaryTenantUtlitiesStatementModal.type")} icon="cog" items={[
                        { key: "effluent", title: i18next.t("page.private.subsidiary.styled.createSubsidiaryTenantUtlitiesStatementModal.typeTypes.effluent") },
                        { key: "overhead", title: i18next.t("page.private.subsidiary.styled.createSubsidiaryTenantUtlitiesStatementModal.typeTypes.overhead") }
                    ]} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.year || !this.state.subsidiaryTenantId || !this.state.type} title={i18next.t("page.private.subsidiary.styled.createSubsidiaryTenantUtlitiesStatementModal.exitButton")} icon="home" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($year: Int!, $subsidiaryTenantId: ID!, $type: String!) {
                            createSubsidiaryTenantUtilitiesStatement(year: $year, subsidiaryTenantId: $subsidiaryTenantId, type: $type)
                        }
                    `,
                    variables: {
                        year: this.state.year,
                        subsidiaryTenantId: this.state.subsidiaryTenantId,
                        type: this.state.type
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createSubsidiaryTenantUtilitiesStatement) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
