import React, { Component } from "react"
import {Modal, Input, Button, Select} from "scanmetrix-components"
import Dropzone from "react-dropzone";
import prettyBytes from "pretty-bytes";
import styled from "styled-components";
import i18next from "i18next"

const UploadBody = styled.div`
    box-shadow: ${props => props.isDragActive ? "inset 0 0 0 4px #3b97d3" : "inset 0 0 0 1px rgba(0, 0, 0, 0.25)"};
    padding: 24px;
    user-select: none;
    
    i {
        margin-right: 8px;
    }
`

const File = styled.div`
    padding: 24px;
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: 16px;
`

export default class extends Component {
    state = { name: null, type: null, subsidiaryId: null, subsidiaries: [], fileSize: null, fileName: null, loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ subsidiaries: data.data.Subsidiaries.nodes })
        })
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ name: null, type: null, subsidiaryId: null, fileName: null, fileSize: null, loading: false })

            this.file = null
        }}>
            <Modal.Head title={i18next.t("page.private.spaceManagement.spaceManagementForm.titleForm")} icon="chair-office" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%", marginBottom: 24 }}>
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.spaceManagement.spaceManagementForm.name")} adjustWidth />
                    <Input required value={this.state.type} onChange={type => this.setState({ type })} icon="cog" label={i18next.t("page.private.spaceManagement.spaceManagementForm.types")} adjustWidth />
                    <Select upwards required adjustWidth value={this.state.subsidiaryId} onChange={subsidiaryId => this.setState({ subsidiaryId })} icon="map-marker" label={i18next.t("page.private.spaceManagement.spaceManagementForm.branch")} items={this.state.subsidiaries.map(subsidiary => ({
                        key: subsidiary.id,
                        title: `${subsidiary.name} (${subsidiary.address.postalCode} ${subsidiary.address.city})`
                    }))} />
                </div>
                <Dropzone accept={[ ".dwg", ".dxf" ]} multiple={false} onDrop={acceptedFiles => {
                    if(acceptedFiles.length && !this.state.loading) {
                        const file = acceptedFiles[0]
                        this.file = file

                        this.setState({ fileName: file.name, fileSize: file.size })
                    }
                }}>
                    {({ getRootProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef" })}>
                        <p className="title"><i className="far fa-upload" /> {i18next.t("page.private.spaceManagement.spaceManagementForm.2dFloorplan")}</p>
                    </UploadBody>}
                </Dropzone>
                {this.state.fileName && <File>
                    <p className="name">{this.state.fileName}</p>
                    <p className="size">{prettyBytes(this.state.fileSize, { locale: "de" })}</p>
                </File>}
                {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
                {this.state.loading && this.state.fileName.toLowerCase().endsWith(".dwg") && <Modal.Info>{i18next.t("page.private.spaceManagement.spaceManagementForm.dwgConversion")}</Modal.Info>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.type || !this.state.subsidiaryId || !this.state.fileName} title={i18next.t("page.private.spaceManagement.spaceManagementForm.exitButton")} icon="chair-office" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $type: String!, $subsidiaryId: ID!, $file: Upload!) {
                            createSpace(name: $name, type: $type, subsidiaryId: $subsidiaryId, file: $file)
                        }
                    `,
                    context: {
                        hasUpload: true
                    },
                    variables: {
                        name: this.state.name,
                        type: this.state.type,
                        subsidiaryId: this.state.subsidiaryId,
                        file: this.file
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createSpace) {
                        this.props.refresh()
                        instance.close()
                    } else {
                        this.setState({ error: "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut." })
                    }
                })}
            } />} />
        </Modal>
    }
}
