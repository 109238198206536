import React, { Component } from "react"
import { Page, Input, Button, Modal, Checkbox, Select } from "scanmetrix-components"
import styled from "styled-components"
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar"
import i18next from "i18next"

//import "moment/locale/de"
import "react-big-calendar/lib/css/react-big-calendar.css"
import 'moment-timezone'
import ScaleLoader from "react-spinners/ScaleLoader"
import color from "color"
import ScrollContainer from "react-indiana-drag-scroll"
import ToolTip from "react-portal-tooltip";
import { withRouter } from "react-router-dom"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
`
const SideBar = styled.div`
  width: 25%;
  max-width: 400px;
  height: 100%;
  padding: 48px 16px 48px 0;
  border-right: 1px solid rgba(0, 0 , 0, .14);
  box-sizing: border-box;
  
  @media screen and (max-width: 1600px) {
    zoom: 0.8;
  }
  
  .figure {
    height: 150px;
    margin-bottom: 48px;
    background-size: contain !important;
    background: url("calendar.svg") no-repeat center;
  }
`

const CalendarBox = styled.div`
  width: 100%;
  max-width: 1400px;
  flex: 1;
  padding: 16px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  box-sizing: border-box;
  
  @media screen and (max-width: 1600px) {
    zoom: 0.8;
  }
  
  .rbc-calendar .rbc-event {
      box-shadow: 0 3px 6px -2px rgb(0 0 0 / 21%);
  }
  
  .rbc-calendar {
    a {
      pointer-events: none;
      user-select: none;
    }
  
    .rbc-toolbar {
      display: flex;
      padding: 8px 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      
      .rbc-toolbar-label {
        font-weight: 600;
        user-select: none;
      }
      
      button {
        cursor: pointer;
        outline: none;
        transition: all 250ms ease;
      }
    }
    
    .rbc-row-segment a {
      color: #20242B;
      text-decoration: none;
      padding-left: 5px;
      padding-top: 10px;
    }
    
    .rbc-row-segment {
      padding: 5px;
    }
    
    .rbc-event {
      background: #3B97D3;
      outline: none;
      transition: all 250ms ease;
      
      &:hover {
        opacity: 0.75;
      }
    }
    
    .rbc-off-range-bg {
      background: rgba(0, 0, 0, .0325);
    }
    
    .rbc-today {
      background: rgba(59, 151, 211, .125);
      border: 1px solid #3B97D3;
      box-sizing: border-box;
    }
    
    .rbc-show-more {
      background: transparent;
    }
  }
`

const LegendView = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  
  .title {
    width: 100%;
    padding: 16px;
    font-weight: 400;
    font-size: 1.1em;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
  }
  
  .table {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    
    .row-view {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      i {
        font-size: 16px;
        margin-right: 10px;
      }
      
      p {
        font-size: 14px;
        color: #20242B;
      }
    }
  }
`

const Content = styled.div`
  width: 75%;
  height: 100%;
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const EventBody = styled.div`
  width: 100%;
  margin-bottom: 25px;
  max-width: 1400px;
  background: white;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  overflow: hidden;
  
  .topTitle {
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    user-select: none;
    
    .header {
      display: flex;
      align-items: center;
      flex-direction: row;
      color: #20242B;
      
      i {
        margin-right: 8px;
      }
    }
  }
  
  .innerContent {
    width: 100%;
    padding: 32px 16px;
    box-sizing: border-box;
  }
`

const DaysRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1px repeat(7, minmax(175px, 1fr)) 1px;
  grid-gap: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  border-left: 1px solid rgba(0, 0, 0, .125);
  border-right: 1px solid rgba(0, 0, 0, .125);
  overflow-x: scroll;
  
  @media screen and (max-width: 1280px) {
    zoom: 0.8;
  }
  
  &::-webkit-scrollbar {
    height: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 125px;
    background: rgba(0, 0, 0, .125);
  }

  .dayItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    
    .dayLabel {
      font-size: 1em;
      font-weight: 500;
    }
    
    .dateLabel {
      font-size: 0.85em;
    }
    
    .agendaBody {
      margin: 16px 0;
      padding: 10px;
      position: relative;
      border-radius: 5px;
      background: linear-gradient(45deg, #f8f8f8, #ffffff);
      border: 1px solid rgba(0, 0, 0, .1);
      width: 100%;
      height: 250px;
      box-sizing: border-box;
      overflow-y: scroll;
      
      @media screen and (max-width: 1600px) {
        height: 125px;
      }
      
      &::-webkit-scrollbar {
        width: 3px;
      }
      
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #F6F6F6;
        border-radius: 5px;
      }
      
      .placeholder {
        width: 100%;
        padding: 5px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        
        i {
          font-size: 2em;
          color: #3B97D3;
          margin-bottom: 16px;
        }
        
        p {
          font-size: 0.9em;
          color: #20242B;
          text-align: center;
          width: 100%;
        }
      }
    }
  }  
`

const Event = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 5px;
  background: white;
  color: #20242B;
  font-size: 0.75em;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  transition: all 200ms ease;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &:hover {
    opacity: 0.75;
  }
  
  .body {
    width: 100%;
    padding: 2.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    
    .title, .time {
      font-weight: 400;
      user-select: none;
      text-align: center;
    }
  
    .spacer {
      width: 75%;
      margin: 5px 0;
      border-bottom: 1px solid rgba(0, 0, 0, .125);
    }
  }
`

const ButtonView = styled.div`
  width: 100%;
  padding: 25px 0;
  box-sizing: border-box;
  
  .button {
    width: 100%;
    margin-bottom: 16px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 8px 24px -16px rgba(0, 0 , 0, .4);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease;
    cursor: pointer;
    
    p {
      user-select: none;
      color: white;
      font-size: 1.0625em;
    }
    
    &:hover {
      opacity: 0.75;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`

function getColor(type) {
    if (type === 'vacation') return '#2ECC71'
    if (type === 'expertInspection') return '#9B59B6'
    if (type === 'maintenance') return '#F39C12'
    if (type === 'duedate') return '#E74C3C'
    if (type === 'own') return '#2C3E50'
    if (type === 'sync') return '#3B97D3'
    if (type === 'google') return '#3B97D3'
    if (type === 'inspection') return '#7F8C8D'
    if (type === 'provisionInterval') return '#f1c40f'

    return '#20242B'
}

class SingleDay extends Component {
    state = {
        openModal: false
    }

    constructor(props) {
        super(props)
        this.escFunction = this.escFunction.bind(this)
    }

    escFunction(event) {
        if(event.keyCode === 27) {
            if (this.state.openModal === true) {
                this.setState({ openModal: false })
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false)
    }

    render() {
        return <div className='dayItem'>
            <h2 className='dayLabel'>{this.props.dayLabel}</h2>
            <ScrollContainer className='agendaBody'>
                {this.props.data.map(( item, index ) => {
                    return <Event key={index}>
                        <div className='body' style={{ borderLeft: '3px solid ' + getColor(item.type) }} onClick={ () => this.setState({ openModal: !this.state.openModal }) }>
                            <h3 className='title'>{item.title}</h3>
                            <div className='spacer' />
                            <p className='time'>{item.time}</p>
                        </div>
                    </Event>
                })}
                {this.props.data.length === 0 && <div className='placeholder'>
                    <i className='fas fa-stars' />
                    <p>{i18next.t("page.private.calendar.upcomingAppointments.noAvailableAppointments")}</p>
                </div> }
            </ScrollContainer>
            <p className='dateLabel'>{this.props.dateLabel}</p>
            <Modal style={ this.state.openModal ? { display: 'block' } : { display: 'none' } }>
                <div className='modal-viewport'>
                    <div className='modal-body'>
                        <div className='modal-inner-title'>
                            <div className='row-view'>
                                <i className='fas fa-circle' style={{ color: '#F39C12' }} />
                                <h1>{i18next.t("page.private.calendar.upcomingAppointments.fireExtinguisherMaintenance")}</h1>
                            </div>
                            <i className='fas fa-times exit-modal' onClick={() => this.setState({ openModal: !this.state.openModal }) } />
                        </div>
                        <div className='modal-inner-text'>
                            <p className='time'>{i18next.t("page.private.calendar.upcomingAppointments.time")}</p>
                            <p className='description'>{i18next.t("page.private.calendar.upcomingAppointments.description")}</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    }
}

class EventView extends Component {
    render() {
        let days = []

        for(let i = 0; i < 7; i++) {
            let date = moment().add(i, "days")
            const dateLabel = date.format("DD.MM.YYYY")

            days.push({
                dayLabel: date.format("dddd"),
                dateLabel,
                data: this.props.appointments.filter(appointment => moment(appointment.date).format("DD.MM.YYYY") === dateLabel).sort((a, b) => (a.allDay ? -1 : (moment(a.date).valueOf())) - (b.allDay ? -1 : (moment(b.date).valueOf()))).map(appointment => ({
                    title: appointment.name,
                    time: appointment.allDay ? "Ganztägig" : `${moment(appointment.date).format("HH:mm")} - ${moment(appointment.date).add(appointment.duration, "minutes").format("HH:mm")}`,
                    type: appointment.type
                }))
            })
        }

        return <EventBody>
            <div className='topTitle'>
                <div className='header'>
                    <i className='far fa-calendar-times' />
                    <p>{i18next.t("page.private.calendar.upcomingAppointments.title")}</p>
                </div>
            </div>
            <div className='innerContent'>
                <DaysRow>
                    <div />
                    {days.map((day, index) => <SingleDay
                        key={index}
                        dayLabel={day.dayLabel}
                        dateLabel={day.dateLabel}
                        data={day.data}
                    />)}
                    <div />
                </DaysRow>
            </div>
        </EventBody>
    }
}

const localizer = momentLocalizer(moment)

class ButtonComponent extends Component {
    render() {
        return <ButtonView>
            { this.props.data.map(( item, index ) => {
                return<div className='button' key={index} style={{ background: item.background }} onClick={item.onClick}>
                    <p><i className={`${item.iconType || "far"} fa-${item.icon}`} /> {item.label}</p>
                </div>
            }) }
        </ButtonView>
    }
}

const InputGrid = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: ${props => props.allDay ? "5fr" : "3fr 2fr"};
`

class CreateAppointmentModal extends Component {
    state = { name: null, subsidiaryId: null, subsidiaries: [], filteredContractIds: [], description: null, startDate: null, startTime: null, endDate: null, endTime: null, allDay: false, contractId: null, contractProvisionIntervalId: null, contracts: [], intervals: [], loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Contracts {
                        nodes {
                            id
                            title
                            number
                            partnerName
                            serviceProvider {
                                id
                                name
                            }
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ contracts: data.data.Contracts.nodes, subsidiaries: data.data.Subsidiaries.nodes })
        })
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={type => this.setState({ type, name: null, subsidiaryId: null, filteredContractIds: [], description: null, startDate: null, startTime: null, endDate: null, endTime: null, allDay: false, contractId: null, contractProvisionIntervalId: null, intervals: [], loading: false })}>
            <Modal.Head title={i18next.t("page.private.calendar.legendForm.title")} icon="calendar-day" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <InputGrid allDay={this.state.allDay}>
                        <Input date value={this.state.startDate} onChange={startDate => {
                            if(this.state.endDate) this.setState({ startDate })
                            else this.setState({ startDate, endDate: startDate })
                        }} required icon="calendar-day" label={i18next.t("page.private.calendar.legendForm.startDate")} adjustWidth />
                        {!this.state.allDay && <Input required time value={this.state.startTime} onChange={startTime => this.setState({ startTime })} icon="clock" label={i18next.t("page.private.calendar.legendForm.startTime")} adjustWidth />}
                    </InputGrid>
                    <InputGrid allDay={this.state.allDay}>
                        <Input date value={this.state.endDate} onChange={endDate => {
                            if(this.state.startDate) this.setState({ endDate })
                            else this.setState({ endDate, startDate: endDate })
                        }} required icon="calendar-day" label={i18next.t("page.private.calendar.legendForm.endDate")} adjustWidth />
                        {!this.state.allDay && <Input required time value={this.state.endTime} onChange={endTime => this.setState({ endTime })} icon="clock" label={i18next.t("page.private.calendar.legendForm.endTime")} adjustWidth />}
                    </InputGrid>
                    <Checkbox label={i18next.t("page.private.calendar.legendForm.allDay")} value={this.state.allDay} onChange={allDay => this.setState({ allDay })} />
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="pencil" label={i18next.t("page.private.calendar.legendForm.name")} adjustWidth />
                    <Input textArea value={this.state.description} onChange={description => this.setState({ description })} icon="font" label={this.state.type === "google" ? i18next.t("page.private.calendar.legendForm.place") : i18next.t("page.private.calendar.legendForm.description")} adjustWidth />
                </div>
                {this.state.type === "maintenance" && <div style={{ marginTop: 16 }}>
                    <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                        <Modal.Divider title={i18next.t("page.private.calendar.legendForm.proofOfPerformance")} />
                        <Select label={i18next.t("page.private.calendar.legendForm.branchFilter")} adjustWidth icon="map-marker" value={this.state.subsidiaryId} onChange={subsidiaryId => {
                            this.setState({ subsidiaryId, filteredContractIds: [] })

                            if(subsidiaryId) {
                                scanmetrix.client.query({
                                    query: scanmetrix.gql`
                                    query($subsidiaryId: ID!) {
                                        SubsidiaryContracts(filter: { subsidiaryId_eq: $subsidiaryId }) {
                                            nodes {
                                                id
                                                contract {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                `,
                                    variables: {
                                        subsidiaryId
                                    }
                                }).then(data => {
                                    this.setState({ filteredContractIds: data.data.SubsidiaryContracts.nodes.map(n => n.contract.id) })
                                })
                            }
                        }} items={this.state.subsidiaries.map(subsidiary => ({
                            key: subsidiary.id,
                            title: `${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""}`
                        }))} />
                        <Select label={i18next.t("page.private.calendar.legendForm.contract")} noUnselect adjustWidth icon="file-signature" value={this.state.contractId} onChange={contractId => {
                            this.setState({ contractId })

                            scanmetrix.client.query({
                                query: scanmetrix.gql`
                                    query($contractId: ID!) {
                                        ContractProvisionIntervals(filter: { contractId_eq: $contractId }) {
                                            nodes {
                                                id
                                                name
                                                intervalAmount
                                                intervalType
                                                startingAt
                                            }
                                        }
                                    }
                                `,
                                variables: {
                                    contractId
                                }
                            }).then(data => {
                                this.setState({ intervals: data.data.ContractProvisionIntervals.nodes })
                            })
                        }} items={this.state.contracts.filter(contract => !this.state.subsidiaryId || (this.state.subsidiaryId && this.state.filteredContractIds.includes(contract.id))).map(contract => ({
                            key: contract.id,
                            title: `${contract.number ? ("(" + contract.number + ") ") : ""}${contract.title} / ${contract.serviceProvider ? contract.serviceProvider.name : contract.partnerName}`
                        }))} />
                        <Select readOnly={!this.state.contractId} label={i18next.t("page.private.calendar.legendForm.performanceCycleContract")} adjustWidth icon="history" value={this.state.contractProvisionIntervalId} onChange={contractProvisionIntervalId => this.setState({ contractProvisionIntervalId })} items={this.state.intervals.map(interval => ({
                            key: interval.id,
                            title: `${interval.name} / ${interval.intervalAmount} ${[{key: "days", title: i18next.t("page.private.calendar.legendForm.intervalTypes.days")},
                                {key: "weeks", title: i18next.t("page.private.calendar.legendForm.intervalTypes.weeks")},
                                {key: "months", title: i18next.t("page.private.calendar.legendForm.intervalTypes.months")},
                                {key: "years", title: i18next.t("page.private.calendar.legendForm.intervalTypes.years")}].find(d => d.key === interval.intervalType).title}${interval.startingAt ? (" / " + moment(interval.startingAt).format("DD.MM.YYYY")) : ""}`
                        }))} />
                    </div>
                </div>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.startDate || !this.state.endDate || (!this.state.allDay && (!this.state.endDate || !this.state.endTime || !this.state.startTime))} title={i18next.t("page.private.calendar.legendForm.exitButton")} icon="calendar-day" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $description: String, $type: String!, $duration: Int!, $date: DateTime!, $allDay: Boolean!, $contractProvisionIntervalId: ID, $subsidiaryId: ID) {
                            createAppointment(name: $name, description: $description, type: $type, date: $date, duration: $duration, allDay: $allDay, contractProvisionIntervalId: $contractProvisionIntervalId, subsidiaryId: $subsidiaryId)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        description: this.state.description,
                        subsidiaryId: this.state.subsidiaryId,
                        type: this.state.type,
                        date: moment(`${this.state.startDate} ${this.state.startTime}`, "DD.MM.YYYY HH:mm").toDate(),
                        duration: this.state.allDay ? (moment.duration(moment(this.state.endDate, "DD.MM.YYYY").diff(moment(this.state.startDate, "DD.MM.YYYY"))).asMinutes()) : (moment.duration(moment(`${this.state.endDate} ${this.state.endTime}`, "DD.MM.YYYY HH:mm").diff(moment(`${this.state.startDate} ${this.state.startTime}`, "DD.MM.YYYY HH:mm"))).asMinutes()),
                        allDay: this.state.allDay,
                        contractProvisionIntervalId: this.state.contractProvisionIntervalId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createAppointment) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}

export { UpdateAppointmentModal }

class UpdateAppointmentModal extends Component {
    state = { id: null, readOnly: false, name: null, description: null, startDate: null, startTime: null, endDate: null, endTime: null, allDay: false, updateLoading: false, deleteLoading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={appointment => this.setState({
            id: appointment.id,
            readOnly: appointment.readOnly,
            name: appointment.title,
            description: appointment.description,
            startDate: moment(appointment.start).format("DD.MM.YYYY"),
            startTime: appointment.allDay ? "00:00" : moment(appointment.start).format("HH:mm"),
            allDay: appointment.allDay,
            location: appointment.location,
            endDate: moment(appointment.end).format("DD.MM.YYYY"),
            endTime: appointment.allDay ? "00:00" : moment(appointment.end).format("HH:mm"),
            type: appointment.type,
            updateLoading: false,
            deleteLoading: false,
            onlyMeta: appointment.onlyMeta || false
        })}>
            <Modal.Head title={this.state.type === "google" ? i18next.t("page.private.calendar.bottomCalendarForm.titleShow") : i18next.t("page.private.calendar.bottomCalendarForm.titleUpdate")} icon="calendar-day" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                {!this.state.onlyMeta && <>

                    <InputGrid allDay={this.state.allDay}>
                        <Input disabled={scanmetrix.checkPermission("Appointment") < 3 || this.state.type === "google"} readOnly={this.state.type === "google" || this.state.type === "provisionInterval"} date value={this.state.startDate} onChange={startDate => {
                            if(this.state.endDate) this.setState({ startDate })
                            else this.setState({ startDate, endDate: startDate })
                        }} icon="calendar-day" label={i18next.t("page.private.calendar.bottomCalendarForm.startDate")} adjustWidth />
                        {!this.state.allDay && <Input disabled={scanmetrix.checkPermission("Appointment") < 3} readOnly={this.state.type === "google" || this.state.type !== "provisionInterval"} time value={this.state.startTime} onChange={startTime => this.setState({ startTime })} icon="clock" label={i18next.t("page.private.calendar.bottomCalendarForm.startTime")} adjustWidth />}
                    </InputGrid>
                    <InputGrid allDay={this.state.allDay}>
                        <Input disabled={scanmetrix.checkPermission("Appointment") < 3 || this.state.type === "google" || this.state.type !== "provisionInterval"} readOnly={this.state.type === "google" || this.state.type === "provisionInterval"} date value={this.state.endDate} onChange={endDate => {
                            if(this.state.startDate) this.setState({ endDate })
                            else this.setState({ endDate, startDate: endDate })
                        }} icon="calendar-day" label={i18next.t("page.private.calendar.bottomCalendarForm.endDate")} adjustWidth />
                        {!this.state.allDay && <Input disabled={scanmetrix.checkPermission("Appointment") < 3} readOnly={this.state.type === "google" || this.state.type !== "provisionInterval"} time value={this.state.endTime} onChange={endTime => this.setState({ endTime })} icon="clock" label={i18next.t("page.private.calendar.bottomCalendarForm.endTime")} adjustWidth />}
                    </InputGrid>
                    <Checkbox disabled={scanmetrix.checkPermission("Appointment") < 3 || this.state.type === "google" || this.state.type === "provisionInterval"} label={i18next.t("page.private.calendar.bottomCalendarForm.allDay")} value={this.state.allDay} onChange={allDay => this.setState({ allDay })} />
                </>}
                    <Input disabled={scanmetrix.checkPermission("Appointment") < 3} readOnly={this.state.type === "google" || this.state.type === "provisionInterval"} value={this.state.name} onChange={name => this.setState({ name })} icon="pencil" label={i18next.t("page.private.calendar.bottomCalendarForm.name")} adjustWidth />
                    {this.state.type === "google" && <Input readOnly value={this.state.location} icon="map-marker" label={i18next.t("page.private.calendar.bottomCalendarForm.place")} adjustWidth />}
                    {this.state.type !== "google" && this.state.type !== "provisionInterval" && <Input disabled={scanmetrix.checkPermission("Appointment") < 3} textArea value={this.state.description} onChange={description => this.setState({ description })} icon="font" label={i18next.t("page.private.calendar.bottomCalendarForm.description")} adjustWidth />}
                </div>
            </Modal.Body>
            {this.state.type !== "google" && this.state.type !== "provisionInterval" && <Modal.Footer buttons={instance => [
                <Button loading={this.state.updateLoading} thick disabled={scanmetrix.checkPermission("Appointment") < 3 || this.state.deleteLoading || !this.state.name || !this.state.startDate || !this.state.endDate || (!this.state.allDay && (!this.state.endDate || !this.state.endTime || !this.state.startTime))} title={i18next.t("page.private.calendar.bottomCalendarForm.updateButton")} icon="calendar-day" onClick={() => {
                    this.setState({ updateLoading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($id: ID!, $name: String!, $description: String, $type: String!, $duration: Int!, $date: DateTime!, $allDay: Boolean!) {
                            updateAppointment(id: $id, name: $name, description: $description, type: $type, date: $date, duration: $duration, allDay: $allDay)
                        }
                    `,
                        variables: {
                            id: this.state.id,
                            name: this.state.name,
                            description: this.state.description,
                            type: this.state.type,
                            date: moment(`${this.state.startDate} ${this.state.startTime}`, "DD.MM.YYYY HH:mm").toDate(),
                            duration: this.state.allDay ? (moment.duration(moment(this.state.endDate, "DD.MM.YYYY").diff(moment(this.state.startDate, "DD.MM.YYYY"))).asMinutes()) : (moment.duration(moment(`${this.state.endDate} ${this.state.endTime}`, "DD.MM.YYYY HH:mm").diff(moment(`${this.state.startDate} ${this.state.startTime}`, "DD.MM.YYYY HH:mm"))).asMinutes()),
                            allDay: this.state.allDay
                        }
                    }).then(result => {
                        this.setState({ updateLoading: false })

                        if(result.data.updateAppointment) {
                            this.props.refresh()
                            instance.close()
                        }
                    })
                }} />,
                <Button disabled={this.state.updateLoading || scanmetrix.checkPermission("Appointment") < 4} loading={this.state.deleteLoading} thick secondary title={i18next.t("page.private.calendar.bottomCalendarForm.deleteButton")} icon="trash" onClick={() => {
                    this.setState({ deleteLoading: true })

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($id: ID!) {
                                deleteAppointment(id: $id)
                            }
                        `,
                        variables: {
                            id: this.state.id
                        }
                    }).then(result => {
                        this.setState({ deleteLoading: false })

                        if(result.data.deleteAppointment) {
                            this.props.refresh()
                            instance.close()
                        }
                    })
                }} />
            ]} />}
        </Modal>
    }
}

const Sync = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.05em;
    font-style: italic;
`

export default class extends Component {
    state = {
        newAgendaModal: false,
        appointments: [],
        googleEvents: [],
        automaticAppointments: [],
        googleLoading: true,
        view: "month"
    }

    constructor(props) {
        super(props)

        this.escFunction = this.escFunction.bind(this)
        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        fetch(scanmetrix.backendURL + "/integration/google_workspace/calendar_events", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(data.status === "SUCCESSFUL") this.setState({ googleEvents: data.events, googleLoading: false })
            else this.setState({ googleLoading: false })
        })

        fetch(scanmetrix.backendURL + "/calendar/automatic_appointments", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(data.status === "SUCCESSFUL") this.setState({ automaticAppointments: data.items })
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Appointments {
                        nodes {
                            id
                            date
                            duration
                            type
                            name
                            description
                            allDay
                        }
                    }
                }
            `
        }).then(result => {
            const appointments = result.data.Appointments.nodes

            this.setState({ appointments })
        })
    }

    escFunction(event) {
        if(event.keyCode === 27) {
            if (this.state.newAgendaModal === true) {
                this.setState({ newAgendaModal: false })
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false)
    }

    render() {
        const appointments = (this.state.appointments || []).concat(this.state.googleEvents).concat(this.state.automaticAppointments)

        return <Page {...this.props} fullHeight maxSize='100%' padding='0 16px !important'>
            <CreateAppointmentModal refresh={() => this.fetch()} instance={ref => this.createAppointmentModal = ref} />
            <UpdateAppointmentModal refresh={() => this.fetch()} instance={ref => this.updateAppointmentModal = ref} />
            <Container>
                <SideBar>
                    <div className='figure' />
                    <LegendView>
                        <h1 className='title'>{i18next.t("page.private.calendar.legend.title")}</h1>
                        <div className='table'>
                            <div className='row-view'>
                                <i className='fas fa-circle' style={{ color: '#2ECC71' }} />
                                <p>{i18next.t("page.private.calendar.legend.vacationDays")}</p>
                            </div>
                            <div className='row-view'>
                                <i className='fas fa-circle' style={{ color: '#8E44AD' }} />
                                <p>{i18next.t("page.private.calendar.legend.expertAppointments")}</p>
                            </div>
                            <div className='row-view'>
                                <i className='fas fa-circle' style={{ color: '#E67E22' }} />
                                <p>{i18next.t("page.private.calendar.legend.maintenance")}</p>
                            </div>
                            <div className='row-view'>
                                <i className='fas fa-circle' style={{ color: '#E74C3C' }} />
                                <p>{i18next.t("page.private.calendar.legend.deadlines")}</p>
                            </div>
                            <div className='row-view'>
                                <i className='fas fa-circle' style={{ color: '#2C3E50' }} />
                                <p>{i18next.t("page.private.calendar.legend.generalAppointments")}</p>
                            </div>
                            <div className='row-view'>
                                <i className='fas fa-circle' style={{ color: '#3B97D3' }} />
                                <p>{i18next.t("page.private.calendar.legend.synchronizedAppointments")}</p>
                            </div>
                            <div className='row-view'>
                                <i className='fas fa-circle' style={{ color: '#7F8C8D' }} />
                                <p>{i18next.t("page.private.calendar.legend.inspectionAppointments")}</p>
                            </div>
                            <div className='row-view'>
                                <i className='fas fa-circle' style={{ color: '#f1c40f' }} />
                                <p>{i18next.t("page.private.calendar.legend.performanceCycles")}</p>
                            </div>
                        </div>
                    </LegendView>
                    {scanmetrix.checkPermission("Appointment") > 1 && <ButtonComponent
                        data={
                            [
                                { onClick: () => this.createAppointmentModal.open("vacation"), icon: "island-tropical", label: i18next.t("page.private.calendar.legendButtons.newvacationDays"), background: 'linear-gradient(45deg, #2ECC71, #32D176' },
                                { onClick: () => this.createAppointmentModal.open("expertInspection"), icon: "user-tie", label: i18next.t("page.private.calendar.legendButtons.newexpertAppointments"), background: 'linear-gradient(45deg, #8E44AD, #9A4EBA' },
                                { onClick: () => this.createAppointmentModal.open("maintenance"), icon: "wrench", label: i18next.t("page.private.calendar.legendButtons.newmaintenance"), background: 'rgb(230, 126, 34)' },
                                { onClick: () => this.createAppointmentModal.open("inspection"), icon: "user-hard-hat", label: i18next.t("page.private.calendar.legendButtons.newInspectionAppointments"), background: 'linear-gradient(45deg, #7F8C8D, #8F9B9C' },
                                { onClick: () => this.createAppointmentModal.open("custom"), icon: "calendar-day", label: i18next.t("page.private.calendar.legendButtons.newgeneralAppointments"), background: 'linear-gradient(45deg, #20242B, #2B3038' },
                                { onClick: () => this.createAppointmentModal.open("google"), iconType: "fab", icon: "google", label: i18next.t("page.private.calendar.legendButtons.newGoogleAppointments"), background: '#3b97d3' }
                            ]
                        }
                    />}
                    {this.state.googleLoading && <Sync>
                        <div style={{ flexShrink: 0, marginRight: 16 }}>
                            <ScaleLoader color="#3b97d3" height={20} />
                        </div>
                        {i18next.t("page.private.calendar.synchronizedAppointmentsText")}
                    </Sync>}
                </SideBar>
                <Content>
                    <EventView appointments={appointments} />
                    <CalendarBox>
                        <BigCalendar
                            localizer={localizer}
                            events={appointments.map(appointment => ({
                                id: appointment.id,
                                duration: appointment.duration,
                                description: appointment.description,
                                title: <p>{appointment.type === "google" && <i className="fab fa-google" />} {appointment.name}{appointment.location && <><br /><i style={{ opacity: 0.85, fontSize: "0.9em" }}>{appointment.location}</i></>}</p>,
                                name: appointment.name,
                                start: moment(appointment.date).toDate(),
                                end: moment(appointment.date).add(appointment.duration, "minutes").toDate(),
                                allDay: appointment.allDay,
                                location: appointment.location,
                                type: appointment.type,
                                readOnly: !!appointment.automatic,
                                appointment
                            }))}
                            startAccessor='start'
                            endAccessor='end'
                            eventPropGetter={event => ({
                                style: this.state.view === "month" ? ({ background: `linear-gradient(20deg, ${color(getColor(event.type)).darken(0.1).hex()}, ${color(getColor(event.type)).lighten(0.2).hex()})` }) : ({})
                            })}
                            components={{
                                eventWrapper: ({ event, children }) => <EventWrapper event={event} childs={children} updateAppointmentModal={() => this.updateAppointmentModal} />
                            }}
                            onView={view => this.setState({ view })}
                            onShowMore={() => this.setState({ view: "agenda" })}
                            culture="de-DE"
                            view={this.state.view}
                            messages={{ next: i18next.t("page.private.calendar.bottomCalendar.further"), previous: i18next.t("page.private.calendar.bottomCalendar.back"), today: i18next.t("page.private.calendar.bottomCalendar.today"), month: i18next.t("page.private.calendar.bottomCalendar.month"), allDay: i18next.t("page.private.calendar.bottomCalendar.allDay"), showMore: total => `+ ${total} weitere`, }}
                            views={['month', 'agenda']}
                        />
                    </CalendarBox>
                </Content>
            </Container>
        </Page>
    }
}

const EventWrapper = withRouter(class extends Component {
    state = { ref: null, hovered: false }

    render() {
        const event = this.props.event
        const appointment = event.appointment
        const interval = appointment.interval

        return <>
            {this.state.ref && event.type === "provisionInterval" && <ToolTip tooltipTimeout={100} group="navigation" parent={this.state.ref} active={this.state.hovered} position="top" arrow="center" style={{ arrowStyle: { borderColor: false } }}>
                <FeatureTooltip>
                    <div className="row name">
                        <p>{i18next.t("page.private.calendar.featureTooltip.performanceCycle")}</p>
                        <p>{interval.name}</p>
                    </div>
                    {interval.subsidiary && <div className="row name">
                        <p>{i18next.t("page.private.calendar.featureTooltip.branch")}</p>
                        <p>{interval.subsidiary.name}{interval.subsidiary.label ? (" (" + interval.subsidiary.label + ")") : ""}</p>
                    </div>}
                    <div className="row interval">
                        <p>{i18next.t("page.private.calendar.featureTooltip.interval")}</p>
                        <p>{`${interval.intervalAmount} ${[{ key: "days", title: i18next.t("page.private.calendar.featureTooltip.intervalTypes.days") },
                            { key: "weeks", title: i18next.t("page.private.calendar.featureTooltip.intervalTypes.weeks") },
                            { key: "months", title: i18next.t("page.private.calendar.featureTooltip.intervalTypes.months") },
                            { key: "years", title: i18next.t("page.private.calendar.featureTooltip.intervalTypes.years") }].find(d => d.key === interval.intervalType).title}`}</p>
                    </div>
                    <div className="row contractTitle">
                        <p>{i18next.t("page.private.calendar.featureTooltip.contractReference")}</p>
                        <p>{interval.contract.title}</p>
                    </div>
                    <div className="row contractNumber">
                        <p>{i18next.t("page.private.calendar.featureTooltip.contractNumber")}</p>
                        <p>{interval.contract.number}</p>
                    </div>
                    <div className="row contractPartner">
                        <p>{i18next.t("page.private.calendar.featureTooltip.contractPartner")}</p>
                        <p>{interval.contract.partner}</p>
                    </div>
                    <div style={{ marginTop: 16, width: "100%", display: "flex", justifyContent: "center" }}>
                        <Button title={i18next.t("page.private.calendar.featureTooltip.exitButton")} thick icon="file-signature" onClick={() => this.props.history.push(`/contract/${interval.contract.id}`)} />
                    </div>
                </FeatureTooltip>
            </ToolTip>}
            <div onMouseEnter={() => this.setState({ hovered: true })} onMouseLeave={() => this.setState({ hovered: false })} onClick={() => this.props.updateAppointmentModal().open({ ...event, title: event.name })} ref={ref => {
                if(!this.state.ref) this.setState({ ref })
            }} onContextMenu={e => {
                e.preventDefault()
            }}>{this.props.childs}</div>
        </>
    }
})

const FeatureTooltip = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 500px;
  overflow-x: auto;
  line-height: 1.4em;
  font-size: 1.05em;
  user-select: none;
  
  .row {
      display: grid;
      grid-template-columns: 130px auto;
      grid-gap: 16px;
      margin-top: 4px;
    
      &:first-child {
          margin-top: 0;
      }
    
      p:first-child {
          width: 150px;
          color: #3b97d3;
          font-weight: 600;
      }
  }
`


export { getColor }
