import React, { Component } from "react"
import { Section, GridElement, Table, Modal, Button, Input, Select, Checkbox } from "scanmetrix-components"

import { withRouter } from "react-router-dom"
import i18next from "i18next";
import RemindServiceProviderModal from "../modals/RemindServiceProviderModal";
import Avatar from "react-avatar";
import styled from "styled-components";

class DeleteContractProvisionIntervalCommentModal extends Component {
    state = { commentId: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={commentId => this.setState({ commentId, loading: false })}>
            <Modal.Head title={i18next.t("page.private.contracts.deleteContractProvisionIntervalCommentModal.title")} icon="trash" />
            <Modal.Body padding={24}>
                {i18next.t("page.private.contracts.deleteContractProvisionIntervalCommentModal.description")}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.contracts.deleteContractProvisionIntervalCommentModal.exitButton")} icon="trash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            deleteContractProvisionIntervalComment(id: $id)
                        }
                    `,
                    variables: {
                        id: this.state.commentId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.deleteContractProvisionIntervalComment) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}


class CommentsModal extends Component {
    state = { subsidiaryId: null, intervalId: null }

    render() {
        return <Modal minWidth="1024px" instance={this.props.instance} initialize={cpi => {
            this.setState({ intervalId: cpi.id, subsidiaryId: cpi.subsidiaryId })
        }}>
            <Modal.Head title={i18next.t("page.private.contracts.showContractProvisionIntervalCommentModal.title")} icon="comments" />
            <Modal.Body>
                {this.state.intervalId && <Comments refresh={this.props.refresh} subsidiaryId={this.state.subsidiaryId} intervalId={this.state.intervalId} />}
            </Modal.Body>
        </Modal>
    }
}

class Comments extends Component {
    state = { comments: [], comment: null, loading: false }

    constructor(props) {
        super(props)

        this.save = this.save.bind(this)
        this.refresh = this.refresh.bind(this)

        this.refresh().then(data => {
            this.setState({
                comments: data.data.ContractProvisionIntervalComments.nodes
            })
        })
    }

    refresh() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!, $subsidiaryId: ID) {
                    ContractProvisionIntervalComments(filter: { contractProvisionIntervalId_eq: $id, subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            date
                            content
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.intervalId,
                subsidiaryId: this.props.subsidiaryId
            }
        })
    }

    save() {
        this.setState({ loading: true })

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($contractProvisionIntervalId: ID!, $content: String!, $subsidiaryId: ID) {
                    createContractProvisionIntervalComment(contractProvisionIntervalId: $contractProvisionIntervalId, subsidiaryId: $subsidiaryId, content: $content)
                }
            `,
            variables: {
                contractProvisionIntervalId: this.props.intervalId,
                subsidiaryId: this.props.subsidiaryId,
                content: this.state.comment
            }
        }).then(data => {
            if(data.data.createContractProvisionIntervalComment) {
                this.props.refresh()

                this.refresh().then(data => {
                    this.setState({ comment: null, comments: data.data.ContractProvisionIntervalComments.nodes, loading: false })
                })
            }
        })
    }

    render() {
        return <>
            <DeleteContractProvisionIntervalCommentModal instance={ref => this.deleteContractProvisionIntervalCommentModal = ref} refresh={() => this.refresh().then(data => {
                this.props.refresh()
                this.setState({
                    comments: data.data.ContractProvisionIntervalComments.nodes
                })
            })} />
            <div style={{ display: "flex", alignItems: "center", padding: "32px 32px 0 32px" }}>
                <Input disabled={this.state.loading} label={i18next.t("page.private.contracts.performanceRecords.prioritySection.comments.searchPlaceholder")} adjustWidth icon="font" value={this.state.comment} onChange={comment => this.setState({ comment })} />
                <div style={{ marginLeft: 16 }}>
                    <Button loading={this.state.loading} icon="comments-alt" title={i18next.t("page.private.contracts.performanceRecords.prioritySection.comments.commentButton")} thick onClick={this.save} disabled={scanmetrix.checkPermission("ContractProvisionIntervalComment") < 2 || !this.state.comment || (this.state.comment && this.state.comment.trim().length === 0)} />
                </div>
            </div>
            <StyledComments>
                {this.state.comments.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map((comment, id) => <div key={id} className="comment">
                    <div className="avatar">
                        <div className="pic">
                            <Avatar size="32" email={scanmetrix.disableGravatar ? null : comment.user.email} name={`${comment.user.firstName} ${comment.user.lastName}`} className="avatar" />
                        </div>
                        <p>{comment.user.firstName} {comment.user.lastName}</p>
                    </div>
                    {scanmetrix.session.id === comment.user.id && <div className="delete">
                        <i className="far fa-trash" onClick={() => this.deleteContractProvisionIntervalCommentModal.open(comment.id)} />
                    </div>}
                    <div className="body">
                        <div className="date">
                            {moment(comment.date).fromNow()}
                        </div>
                        <div className="content">
                            {comment.content}
                        </div>
                    </div>
                </div>)}
            </StyledComments>
        </>
    }
}

const StyledComments = styled.div`
    padding: 32px;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .comment {
        display: grid;
        grid-template-columns: 1fr 32px;
        grid-gap: 0 16px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 16px;
        border-radius: 4px;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.075);
    }

    .avatar {
        display: flex;
        align-items: center;
        grid-column-start: 1;
        grid-column-end: 2;
        height: 32px;

        .pic {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 8px;
        }
    }

    .delete {
        grid-column-start: 2;
        grid-column-end: 3;
        display: flex;
        align-items: center;
        height: 32px;
        justify-content: flex-end;

        i {
            cursor: pointer;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        grid-column-start: 1;
        grid-column-end: 3;

        .date {
            margin-bottom: 16px;
            margin-top: 16px;
            font-size: 0.8em;
            font-weight: 400;
            opacity: 0.75;
        }

        .content {
            font-size: 18px;
            line-height: 26px;
        }
    }
`


export default withRouter(class extends Component {
    state = { contractProvisionIntervals: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        return fetch(scanmetrix.backendURL + "/contract-provision-intervals", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ contractProvisionIntervals: data.contractProvisionIntervals.filter(this.props.filter || (() => true)) })
        })
    }

    render() {
        return <>
            <CommentsModal instance={ref => this.showCommentsModal = ref} refresh={() => this.fetch()} />
            <CreateAppointmentModal instance={ref => this.createAppointmentModal = ref} refresh={() => {
                this.setState({ contractProvisionIntervals: [] })

                return this.fetch()
            }} />
            <RemindServiceProviderModal refresh={() => this.fetch()} instance={ref => this.remindServiceProviderModal = ref} />
            <Section.Item title={i18next.t("page.private.contracts.contractProvisionIntervals.sectionTitle")} icon="hourglass" {...this.props} amount={this.state.contractProvisionIntervals ? this.state.contractProvisionIntervals.length : 0}>
                <GridElement styled title={i18next.t("page.private.contracts.contractProvisionIntervals.gridElementTitle")} icon="hourglass">
                    <Table
                        items={this.state.contractProvisionIntervals ? this.state.contractProvisionIntervals.map(cpi => ({
                            id: cpi.id,
                            due: <p style={moment(cpi.date, "DD.MM.YYYY").isSameOrBefore(moment()) ? ({ fontWeight: "bold", color: "rgb(231, 76, 60)" }) : ({})}>{`${cpi.date} (${moment(cpi.date, "DD.MM.YYYY").fromNow()})`}</p>,
                            name: cpi.name,
                            raw: cpi,
                            interval: `${cpi.intervalAmount} ${[{ key: "days", title: i18next.t("page.private.contracts.contractProvisionIntervals.intervalTypes.days") },
                                { key: "weeks", title: i18next.t("page.private.contracts.contractProvisionIntervals.intervalTypes.weeks") },
                                { key: "months", title: i18next.t("page.private.contracts.contractProvisionIntervals.intervalTypes.months") },
                                { key: "years", title: i18next.t("page.private.contracts.contractProvisionIntervals.intervalTypes.years") }].find(d => d.key === cpi.intervalType).title}`,
                            contract: cpi.contract.title,
                            partner: cpi.contract.partner,
                            lastContractProof: cpi.last,
                            contractRaw: cpi.contract,
                            serviceProvider: cpi.serviceProvider,
                            lastReminder: cpi.lastReminder,
                            comments: <><i style={{ color: cpi.comments > 0 ? "#3b97d3" : "#95a5a6" }} className="fa-duotone fa-comments" /> {cpi.comments}</>,
                            subsidiary: cpi.subsidiary ? `${cpi.subsidiary.name}${cpi.subsidiary.label ? (" (" + cpi.subsidiary.label + ")") : ""}` : null,
                            subsidiaryId: cpi.subsidiary ? cpi.subsidiary.id : null,
                            latestAppointment: cpi.latestAppointment ? <p style={moment(cpi.latestAppointment, "DD.MM.YYYY").isSameOrBefore(moment()) ? ({ fontWeight: "bold", color: "rgb(231, 76, 60)" }) : ({})}>{cpi.latestAppointment}</p> : null
                        })) : null}
                        contextItems={[
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: item => window.open(`/contract/${item.contractRaw.id}`, '_blank')},
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.comments"), icon: "fa-light fa-comments", onClick: item => this.showCommentsModal.open(item)},
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.appointment"), disabled: item => !item.subsidiaryId, icon: "fa-light fa-calendar", onClick: item => this.createAppointmentModal.open(item)},
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.remind"), disabled: item => !item.serviceProvider || !item.subsidiary, icon: "fa-duotone fa-history", onClick: item => this.remindServiceProviderModal.open(item)}
                        ]}
                        onItemClick={item => this.props.history.push(`/contract/${item.contractRaw.id}`)}
                        head={[
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.branch"), width: "12.5%", column: "subsidiary" },
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.due"), width: "12.5%", column: "due" },
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.name"), width: "12.5%", column: "name" },
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.interval"), width: "10%", column: "interval" },
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.contract"), width: "12.5%", column: "contract" },
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.partner"), width: "12.5%", column: "partner" },
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.lastContractProof"), width: "7.5%", column: "lastContractProof" },
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.latestAppointment"), width: "7.5%", column: "latestAppointment" },
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.lastReminder"), width: "7.5%", column: "lastReminder" },
                            { title: i18next.t("page.private.contracts.contractProvisionIntervals.table.comments"), width: "5%", column: "comments" },
                        ]}
                    />
                </GridElement>
            </Section.Item>
        </>
    }
})


class CreateAppointmentModal extends Component {
    state = { name: null, description: null, startDate: null, startTime: null, endDate: null, endTime: null, allDay: false, contractProvisionIntervalId: null, subsidiaryId: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={item => this.setState({ name: null, description: null, startDate: null, startTime: null, endDate: null, endTime: null, allDay: false, contractProvisionIntervalId: item.id, subsidiaryId: item.subsidiaryId, loading: false })}>
            <Modal.Head title={i18next.t("page.private.calendar.legendForm.title")} icon="calendar-day" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <InputGrid allDay={this.state.allDay}>
                        <Input date value={this.state.startDate} onChange={startDate => {
                            if(this.state.endDate) this.setState({ startDate })
                            else this.setState({ startDate, endDate: startDate })
                        }} required icon="calendar-day" label={i18next.t("page.private.calendar.legendForm.startDate")} adjustWidth />
                        {!this.state.allDay && <Input required time value={this.state.startTime} onChange={startTime => this.setState({ startTime })} icon="clock" label={i18next.t("page.private.calendar.legendForm.startTime")} adjustWidth />}
                    </InputGrid>
                    <InputGrid allDay={this.state.allDay}>
                        <Input date value={this.state.endDate} onChange={endDate => {
                            if(this.state.startDate) this.setState({ endDate })
                            else this.setState({ endDate, startDate: endDate })
                        }} required icon="calendar-day" label={i18next.t("page.private.calendar.legendForm.endDate")} adjustWidth />
                        {!this.state.allDay && <Input required time value={this.state.endTime} onChange={endTime => this.setState({ endTime })} icon="clock" label={i18next.t("page.private.calendar.legendForm.endTime")} adjustWidth />}
                    </InputGrid>
                    <Checkbox label={i18next.t("page.private.calendar.legendForm.allDay")} value={this.state.allDay} onChange={allDay => this.setState({ allDay })} />
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="pencil" label={i18next.t("page.private.calendar.legendForm.name")} adjustWidth />
                    <Input textArea value={this.state.description} onChange={description => this.setState({ description })} icon="font" label={this.state.type === "google" ? i18next.t("page.private.calendar.legendForm.place") : i18next.t("page.private.calendar.legendForm.description")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.startDate || !this.state.endDate || (!this.state.allDay && (!this.state.endDate || !this.state.endTime || !this.state.startTime))} title={i18next.t("page.private.calendar.legendForm.exitButton")} icon="calendar-day" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $description: String, $type: String!, $duration: Int!, $date: DateTime!, $allDay: Boolean!, $contractProvisionIntervalId: ID, $subsidiaryId: ID) {
                            createAppointment(name: $name, description: $description, type: $type, date: $date, duration: $duration, allDay: $allDay, contractProvisionIntervalId: $contractProvisionIntervalId, subsidiaryId: $subsidiaryId)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        description: this.state.description,
                        subsidiaryId: this.state.subsidiaryId,
                        type: "maintenance",
                        date: moment(`${this.state.startDate} ${this.state.startTime}`, "DD.MM.YYYY HH:mm").toDate(),
                        duration: this.state.allDay ? (moment.duration(moment(this.state.endDate, "DD.MM.YYYY").diff(moment(this.state.startDate, "DD.MM.YYYY"))).asMinutes()) : (moment.duration(moment(`${this.state.endDate} ${this.state.endTime}`, "DD.MM.YYYY HH:mm").diff(moment(`${this.state.startDate} ${this.state.startTime}`, "DD.MM.YYYY HH:mm"))).asMinutes()),
                        allDay: this.state.allDay,
                        contractProvisionIntervalId: this.state.contractProvisionIntervalId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createAppointment) {
                        this.props.refresh().then(() => instance.close())
                    }
                })}
            } />} />
        </Modal>
    }
}

const InputGrid = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: ${props => props.allDay ? "5fr" : "3fr 2fr"};
`
