import React, { useState, useEffect } from "react";
import { Page, GridElement, Grid, Breadcrumbs } from "scanmetrix-components";
import styled from "styled-components";
import { ApolloClient, InMemoryCache, createHttpLink, gql } from "@apollo/client";
import i18next from "i18next";

const Tour = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s;
  background-color: white;

  &:hover {
    border-color: #3b97d3;
    background: rgb(59 151 211 / 9%);
  }

  .right {
    display: flex;
    flex-direction: column;
    font-size: 18px;

    .title {
      font-weight: bold;
      margin-bottom: 4px;
    }

    .description {
      line-height: 28px;
    }
  }

  i {
    font-size: 40px;
    color: #3b97d3;
    flex-shrink: 0;
    margin-right: 32px;
    width: 48px;
  }
`;

const ToursPage = (props) => {
  const [tourOptions, setTourOptions] = useState([]);

  useEffect(() => {
    const client = new ApolloClient({
      link: createHttpLink({
        uri: "https://api-eu-central-1.graphcms.com/v2/ckc0kun7m08ci01xl4h8500rq/master",
      }),
      cache: new InMemoryCache({ addTypename: false }),
      defaultOptions: {
        watchQuery: {
          errorPolicy: "all",
          fetchPolicy: "network-only",
        },
        query: {
          errorPolicy: "all",
          fetchPolicy: "network-only",
        },
        mutate: {
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        },
      },
    });

    client
      .query({
        query: gql`
          {
            tours {
              id
              title
              description
              icon
              url
              category
            }
          }
        `,
      })
      .then((data) => {
        const tourOptions = data.data.tours;
        setTourOptions(tourOptions);
      });
  }, []);

  // Function to handle click event
  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  // Get unique categories
  const categories = Array.from(new Set(tourOptions.map((tour) => tour.category)));

  return (
    <Page {...props} style={{ boxSizing: "border-box" }}>
      <Breadcrumbs values={[
        {
          icon: "presentation-screen",
          title: i18next.t("page.private.tours.breadCrumbsTitle")
        }
      ]}
       margin="0 32px 32px 32px" />
      <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16 }}>
        {/* Render tour options for each category */}
        {categories.map((category) => (
          <GridElement key={category} styled title={category}>
            <Grid padding="32px">
              <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16 }}>
                {tourOptions
                  .filter((tour) => tour.category === category)
                  .map((tourOption, index) => (
                    <Tour key={index} onClick={() => handleClick(tourOption.url)}>
                      <i className={`fa-duotone fa-${tourOption.icon}`} />
                      <div className="right">
                        <p className="title">{tourOption.title}</p>
                        <p className="description">{tourOption.description}</p>
                      </div>
                    </Tour>
                  ))}
              </div>
            </Grid>
          </GridElement>
        ))}
      </div>
    </Page>
  );
};

export default ToursPage;
