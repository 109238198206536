import React, { Component } from "react"
import { Modal, Input, Button } from "scanmetrix-components"

import { GridElement, Grid } from "../../../../../components"
import i18next from "i18next";

export default class extends Component {
    state = { value: null, date: null, meterId: null, type: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={meter => this.setState({ value: null, date: moment().format("DD.MM.YYYY"), meterId: meter.id, type: meter.type, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.createMeterReadingModal.title")} icon="clock" />
            <Modal.Body padding={24} adjustHeight={470}>
                <Grid gap="24px" columns={[ "1fr" ]}>
                    <GridElement>
                        <Input required maxLength={10} float value={this.state.value} onChange={value => this.setState({ value })} icon="monitor-heart-rate" label={i18next.t("page.private.subsidiary.styled.createMeterReadingModal.meterValue")} adjustWidth />
                    </GridElement>
                    <GridElement>
                        <Input required date value={this.state.date} onChange={date => this.setState({ date })} icon="calendar-day" label={i18next.t("page.private.subsidiary.styled.createMeterReadingModal.readingDate")} adjustWidth />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.value || !this.state.date} title={i18next.t("page.private.subsidiary.styled.createMeterReadingModal.exitButton")} icon="clock" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($value: Float!, $meterId: ID!, $date: DateTime!) {
                            createMeterReading(value: $value, meterId: $meterId, date: $date)
                        }
                    `,
                    variables: {
                        value: this.state.value,
                        date: moment(this.state.date, "DD.MM.YYYY HH:mm:ss ZZ").toDate(),
                        meterId: this.state.meterId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createMeterReading) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
