import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { title: null, type: null, subsidiaryId: null, subsidiaries: [], letterHeads: [], letterHeadId: null, loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    LetterHeads {
                        nodes {
                            id
                            name
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ subsidiaries: data.data.Subsidiaries.nodes, letterHeads: data.data.LetterHeads.nodes })
        })
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({
            title: null,
            type: null,
            subsidiaryId: null,
            letterHeadId: null,
            loading: false
        })}>
            <Modal.Head title={i18next.t("page.private.measures.createMeasureModal.titleForm")} icon="network-wired" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <Select required adjustWidth value={this.state.letterHeadId} onChange={letterHeadId => this.setState({ letterHeadId })} icon="scroll" label={i18next.t("page.private.measures.createMeasureModal.letterHead")} items={this.state.letterHeads.map(letterHead => ({
                        key: letterHead.id,
                        title: letterHead.name
                    }))} />
                    <Select required adjustWidth value={this.state.type} onChange={type => this.setState({ type })} icon="wrench" label={i18next.t("page.private.measures.createMeasureModal.type")} items={[
                        { key: "service", title: i18next.t("page.private.measures.createMeasureModal.typeTypes.service"), icon: "hard-hat" },
                        { key: "construction", title: i18next.t("page.private.measures.createMeasureModal.typeTypes.construction"), icon: "digging" },
                        { key: "goods", title: i18next.t("page.private.measures.createMeasureModal.typeTypes.goods"), icon: "truck-loading" },
                        { key: "other", title: i18next.t("page.private.measures.createMeasureModal.typeTypes.other"), icon: "question" },
                    ]} />
                    <Input required value={this.state.title} onChange={title => this.setState({ title })} icon="font" label={i18next.t("page.private.measures.createMeasureModal.title")} adjustWidth />
                    <Select upwards required adjustWidth value={this.state.subsidiaryId} onChange={subsidiaryId => this.setState({ subsidiaryId })} icon="map-marker" label={i18next.t("page.private.measures.createMeasureModal.branch")} items={this.state.subsidiaries.map(subsidiary => ({
                        key: subsidiary.id,
                        title: `${subsidiary.name} (${subsidiary.address.postalCode} ${subsidiary.address.city})`
                    }))} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.type || !this.state.title || !this.state.letterHeadId || !this.state.subsidiaryId} title={i18next.t("page.private.measures.createMeasureModal.exitButton")} icon="network-wired" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($title: String!, $type: String!, $subsidiaryId: ID!, $letterHeadId: ID!) {
                            createMeasure(title: $title, type: $type, subsidiaryId: $subsidiaryId, letterHeadId: $letterHeadId)
                        }
                    `,
                    variables: {
                        title: this.state.title,
                        type: this.state.type,
                        subsidiaryId: this.state.subsidiaryId,
                        letterHeadId: this.state.letterHeadId
                    }
                }).then(result => {
                    if(result.data.createMeasure) {
                        this.props.refresh().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                })}
            } />} />
        </Modal>
    }
}
