import React, { Component } from 'react'
import { Button, Modal, Input, Grid, GridElement, Select, Checkbox } from 'scanmetrix-components'
import styled from 'styled-components'
import VehicleCar from '../../../styled/VehicleCar'


const InnerGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 32px;
`

const InputView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
  }
`

const CarView = styled.div`
  width: 100%;
  height: 500px;
  background: radial-gradient(at bottom left,rgb(220 220 220) 5%,rgba(235,238,243,1) 75%);
  border-radius: 10px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  .tooltip-label {
    position: absolute;
    bottom: 16px;
    left: 16px;
    user-select: none;
    
    p {
      max-width: 256px;
      font-size: 0.8em;
      opacity: 0.75;
      color: white;
      background: rgba(0, 0, 0, 0.5);
      padding: 8px;
      border-radius: 4px;
    }

    .scale-tooltip {
      padding: 4px;
      background: white;
      border: 1px solid rgba(0, 0, 0, .1);
      border-radius: 10px;
      box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 8px;

      i {
        padding: 8px 16px;
        display: flex;
        border: 1px solid transparent;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 250ms ease;

        &.active {
          border: 1px solid rgba(0, 0, 0, .1)
        }

        &:hover {
          border: 1px solid rgba(0, 0, 0, .1);
        }

        &.delete:hover {
          color: white;
          background: #E74C3C;
        }
      }
    }
  }
  
  .draw-tooltip {
    padding: 4px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    background: white;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 10px;
    box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 8px;
    
    i {
      padding: 8px 16px;
      display: flex;
      border: 1px solid transparent;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 250ms ease;
      
      &.active {
        border: 1px solid rgba(0, 0, 0, .1)
      }
      
      &:hover {
        border: 1px solid rgba(0, 0, 0, .1);
      }
      
      &.delete:hover {
        color: white;
        background: #E74C3C;
      }
    }
  }
  
  .save-button {
    height: 44px;
    padding: 0 16px;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    background: #2ECC71;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 8x 24px -16px rgba(0, 0, 0, .4);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    
    &:hover {
      opacity: 0.75;
    }
    
    i {
      margin-right: 8px;
    }
  }
`

class FlawDrawer extends Component {
    state = { addCircle: false, deleteAll: false, circlePosition: null }

    constructor(props) {
        super(props)

        this.pos = { x: 0, y: 0, z: 0 }
        this.state.circlePosition = props.circlePosition
    }

    render() {
        return <>
            <CarView>
                <VehicleCar onRaycast={(x, y, z) => {
                    this.pos = { x, y, z }
                }} height={500} carPos={[0, 0.5, 0]} circlePosition={this.state.circlePosition} minDistance={2.75} maxDistance={4.5} addCircle={this.state.addCircle} car={this.props.car.type} />

                <div className='tooltip-label'>
                    { this.state.addCircle ?
                        <div className='scale-tooltip'>
                            <i className='far fa-plus' />
                            <i className='far fa-minus' />
                        </div>
                    :
                        <p>Hier können Sie die genaue Position des Schadens eingrenzen.</p>
                    }
                </div>

                <div className='save-button' style={{ display: this.state.addCircle ? 'flex' : 'none' }} onClick={() => {
                    this.setState({ addCircle: false, circlePosition: this.pos })
                    this.props.onPos(this.pos)

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($id: ID!, $data: JSON) {
                                updateCarFlaw(id: $id, data: $data)
                            }  
                        `,
                        variables: {
                            id: this.props.id,
                            data: { position: this.pos }
                        }
                    }).then(() => this.props.refresh())
                }}>
                    <i className='far fa-check' />
                    <p>Platzieren</p>
                </div>

                <div className='draw-tooltip'>
                    <i onClick={() => {
                        this.setState({ addCircle: !this.state.addCircle, circlePosition: null })
                        this.props.onPos(null)
                    }} className={ this.state.addCircle ? 'far fa-layer-plus active' : 'far fa-layer-plus' } />
                </div>
            </CarView>
        </>
    }
}

export default class extends Component {
    state = { drivers: [], flaw: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($carId: ID!) {
                    CarDrivers(filter: { carId_eq: $carId }) {
                        nodes {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            `,
            variables: {
                carId: this.props.car.id
            }
        }).then(data => {
            this.setState({ drivers: data.data.CarDrivers.nodes })
        })
    }

    render() {
        if(this.state.flaw) console.log(this.state.flaw)
        return <Modal minWidth="1024px" instance={this.props.instance} initialize={flaw => this.setState({ flaw })}>
            <Modal.Head title="Mangel aktualisieren" icon="bolt" />
            <Modal.Body padding={24}>
                <InnerGrid>
                    {this.state.flaw && <FlawDrawer refresh={this.props.refresh} id={this.state.flaw.id} circlePosition={this.state.flaw.data.position} car={this.props.car} onPos={position => this.setState(position ? { data: { position } } : null)} />}
                    <InputView>
                        <Grid columns={[ "1fr" ]} gap="16px">
                            {this.state.flaw && <Input.MutationProvider value={{
                                name: "updateCarFlaw",
                                id: this.state.flaw.id,
                                data: this.state.flaw,
                                onSave: () => this.props.refresh()
                            }}>
                                <GridElement>
                                    <Select noUnselect adjustWidth required label='Fahrer' icon='steering-wheel' field="carDriver.id" items={this.state.drivers.map(driver => ({
                                        key: driver.id,
                                        title: `${driver.firstName} ${driver.lastName}`
                                    }))} />
                                </GridElement>
                                <GridElement>
                                    <Input date adjustWidth label='Schadensdatum' icon='calendar' field="damagedAt" />
                                </GridElement>
                                <GridElement>
                                    <Input adjustWidth required label='Titel des Mangels' icon='bolt' field="title" />
                                </GridElement>
                                <GridElement>
                                    <Input adjustWidth label='Mangelbeschreibung' icon='font' field="description" />
                                </GridElement>
                                <GridElement>
                                    <Input adjustWidth label='Position am Fahrzeug' icon='marker' field="location" />
                                </GridElement>
                                <GridElement>
                                    <Checkbox label='Mangel behoben' field="fixed" />
                                </GridElement>
                            </Input.MutationProvider>}
                        </Grid>
                    </InputView>
                </InnerGrid>
            </Modal.Body>
        </Modal>
    }
}
