import React, { Component } from 'react'
import { Button, Modal, Input, Select, Grid, GridElement } from 'scanmetrix-components'


export default class extends Component {
    state = { logbook: null, drivers: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($carId: ID!) {
                    CarDrivers(filter: { carId_eq: $carId }) {
                        nodes {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            `,
            variables: {
                carId: this.props.carId
            }
        }).then(data => {
            this.setState({ drivers: data.data.CarDrivers.nodes })
        })
    }

    render() {
        return <Modal maxWidth="1024px" minWidth="768px" instance={this.props.instance} initialize={logbook => this.setState({ logbook })}>
            <Modal.Head title="Fahrtenbucheintrag aktualisieren" icon="book" />
            <Modal.Body padding={24}>
                {this.state.logbook && <Input.MutationProvider value={{
                    name: "updateCarLogbook",
                    id: this.state.logbook.id,
                    data: this.state.logbook,
                    onSave: () => this.props.refresh()
                }}>
                    <Grid columns={[ "1fr", "1fr" ]} gap="16px">
                        <GridElement columnStart={1} columnEnd={2}>
                            <Select noUnselect required adjustWidth field="carDriver.id" label='Fahrer' icon='user' items={this.state.drivers.map(driver => ({
                                key: driver.id,
                                title: `${driver.firstName} ${driver.lastName}`
                            }))} />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Select noUnselect required adjustWidth label='Typ' icon='text' field="type" items={[
                                { key: "company", title: "Anfahrt Firma" },
                                { key: "private", title: "Heimweg Privatanschrift" },
                                { key: "customer", title: "Anfahrt Kunde" },
                            ]} />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input required adjustWidth field="date" label='Datum' icon='calendar-day' date />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input adjustWidth label='Routenbezeichnung' icon='road' field="route" />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Input required adjustWidth label='Kilometerstand (Start)' icon='car' field="startMileage" int />
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Input required adjustWidth label='Kilometerstand (Ende)' icon='car-garage' field="endMileage" int />
                        </GridElement>
                        <GridElement columnStart={1} columnEnd={3}>
                            <Input adjustWidth label='Grund / Bemerkung' icon='question-circle' field="reason" />
                        </GridElement>
                    </Grid>
                </Input.MutationProvider>}
            </Modal.Body>
        </Modal>
    }
}
