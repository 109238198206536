import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { id: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={id => this.setState({ id, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.deleteMeterHistoryModal.title")} icon="trash" />
            <Modal.Body padding={24}>
                {i18next.t("page.private.subsidiary.styled.deleteMeterHistoryModal.description")}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.subsidiary.styled.deleteMeterHistoryModal.exitButton")} icon="trash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            deleteMeterReading(id: $id)
                        }
                    `,
                    variables: {
                        id: this.state.id
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.deleteMeterReading) {
                        this.props.refresh(this.state.id)
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
