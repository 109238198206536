import styled from "styled-components"

export default styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  border: 3px dashed rgba(0, 0, 0, .25);
  color: rgba(0, 0, 0, .25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 250ms ease;
  position: relative;
  user-select: none;
  outline: none;

  &:hover {
    opacity: 0.75;
  }
  
  input {
    display: none;
  }

  i {
    font-size: 2em;
    margin-bottom: 16px;
  }

  p {
    font-size: 1em;
    text-align: center;
    width: 75%;
  }
`