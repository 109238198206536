import React, { Component } from "react"
import { Page, Grid, GridElement, Input, Breadcrumbs, Modal, Section } from "scanmetrix-components"
import styled from "styled-components"
import i18next from "i18next";

const Image = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`

const renderIcon = type => {
    let icon = "question"

    if(type === "structural") icon = "construction"
    if(type === "organisational") icon = "project-diagram"
    if(type === "objectional") icon = "folder-tree"

    return icon
}

const getType = type => {
    if(type === "structural") return "Baulich"
    if(type === "organisational") return "Organisatorisch"
    if(type === "objectional") return "Anlagentechnisch"
}

const SingleAmount = styled.div`
    background: ${props => props.light ? "white" : (props.green ? "linear-gradient(45deg,#16a085,#27dbb8)" : (props.red ? "linear-gradient(45deg,#c0392b,#ff6252)" : "linear-gradient(45deg,#7d7d7d,#9e9e9e)"))};
    border-radius: 5px;
    grid-column-start: ${props => props.columnStart};
    grid-column-end: ${props => props.columnEnd};
    padding: 16px;
    box-sizing: border-box;
    color: ${props => props.light ? "#000" : "white"};
    box-shadow: 0 8px 24px -16px rgba(0, 0, 0, 0.4);
    display: ${props => props.flex ? "flex" : "block"};
    align-items: center;
    cursor: ${props => props.active ? "pointer" : "default"};
    transition: opacity 0.3s;
    user-select: none;

    &:hover {
        opacity: ${props => props.active ? 0.75 : 1};
    }

    .value {
        font-size: 1.2em;
        color: ${props => props.light ? "#869ead" : "white"};
        margin-bottom: ${props => props.flex ? 0 : "8px"};
        margin-right: ${props => props.flex ? "16px" : 0};

        b {
            color: ${props => props.light ? "#647A88" : "rgba(255, 255, 255, 0.75)"};
            font-weight: bold;
        }

        i {
            font-size: 1.3em;
        }
    }

    .title {
        font-size: 1.3em;
        opacity: ${props => props.light ? 0.75 : 1};

        b {
            font-size: 0.8em;
            font-weight: normal;
            opacity: 0.75;
        }
    }
`

const FullSizeImage = styled.img`
    max-width: 768px;
    max-height: 768px;
    object-fit: cover;
    display: block;
`

class PhotoModal extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            {context => <>
                <Modal.Head title={i18next.t("page.private.flaws.viewImage")} icon="images" />
                <Modal.Body>
                    <FullSizeImage src={context} />
                </Modal.Body>
            </>}
        </Modal>
    }
}

export default class extends Component {
    state = { flaw: null }

    constructor(props) {
        super(props)

        fetch(scanmetrix.backendURL + "/flaw/" + this.props.match.params.serviceproviderid + "/" + this.props.match.params.flawid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ flaw: data })
        })

        /*scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    flaw(id: $id) {
                        id
                        name
                        actionOperator
                        actionServiceProvider
                        description
                        priority
                        deadline
                        type
                        images {
                            type
                            imageURL
                        }
                        fixedInMaintenance {
                            id
                        }
                        maintenance {
                            id
                            date
                            customerObject {
                                id
                                smid {
                                    id
                                }
                                customerSubsidiaryFacility {
                                    id
                                    name
                                    building
                                    customerSubsidiary {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.flawid
            }
        }).then(data => {
            this.setState({ flaw: data.data.flaw })
        })*/
    }

    render() {
        if(!this.state.flaw) return null
        const flaw = this.state.flaw

        let imagesBefore = []

        flaw.images.filter(img => img.type === "before").forEach(image => {
            imagesBefore.push(`${scanmetrix.backendURL}/flaw-image/${this.props.match.params.serviceproviderid}/${image.id}`)
        })

        let imagesAfter = []

        flaw.images.filter(img => img.type === "after").forEach(image => {
            imagesAfter.push(`${scanmetrix.backendURL}/flaw-image/${this.props.match.params.serviceproviderid}/${image.id}`)
        })

        let imageStacksBefore = []

        for(let i = 0; i < imagesBefore.length; i += 5) {
            imageStacksBefore.push(imagesBefore.slice(i, i + 5))
        }

        if(!imageStacksBefore.length) imageStacksBefore = [[]]

        imageStacksBefore.forEach(stack => {
            if(stack.length < 5) {
                let intialStackLength = stack.length
                for(var i = 0; i < (5 - intialStackLength); i++) stack.push(null)
            }
        })

        let imageStacksAfter = []

        for(let i = 0; i < imagesAfter.length; i += 5) {
            imageStacksAfter.push(imagesAfter.slice(i, i + 5))
        }

        if(!imageStacksAfter.length) imageStacksAfter = [[]]

        imageStacksAfter.forEach(stack => {
            if(stack.length < 5) {
                let intialStackLength = stack.length
                for(var i = 0; i < (5 - intialStackLength); i++) stack.push(null)
            }
        })

        return <Page {...this.props}>
            <PhotoModal instance={instance => this.photoModal = instance} />
            <Breadcrumbs values={[
                {
                    icon: "building",
                    title: i18next.t("page.private.flaws.facilityManagement"),
                    link: "/facilitymanagement"
                },
                {
                    icon: "map-marker",
                    title: `${flaw.maintenance.customerObject.customerSubsidiaryFacility.customerSubsidiary.name} (${flaw.maintenance.customerObject.customerSubsidiaryFacility.customerSubsidiary.label || "k. A."})`,
                    link: `/subsidiary/${flaw.maintenance.customerObject.customerSubsidiaryFacility.customerSubsidiary.id}`
                },
                {
                    icon: "folder-tree",
                    title: i18next.t(flaw.maintenance.customerObject.customerSubsidiaryFacility.name),
                    link: `/facility/${this.props.match.params.serviceproviderid}/${flaw.maintenance.customerObject.customerSubsidiaryFacility.id}`
                },
                {
                    icon: "qrcode",
                    title: flaw.maintenance.customerObject.smid.id,
                    link: `/object/${this.props.match.params.serviceproviderid}/${flaw.maintenance.customerObject.id}`
                },
                {
                    icon: "toolbox",
                    title: `${i18next.t("page.private.flaws.maintenance")} ${flaw.maintenance.date}`,
                    link: `/maintenance/${this.props.match.params.serviceproviderid}/${flaw.maintenance.id}`
                },
                {
                    icon: "bolt",
                    title: `${i18next.t("page.private.flaws.defect")} „${flaw.name.trim()}“`
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.flaws.general.title")} icon="database">
                    <Grid gap="32px" columns={[ "1fr", "4fr" ]}>
                        <GridElement columnStart={1} columnEnd={2}>
                            <Grid gap="32px" columns={[ "1fr" ]}>
                                <SingleAmount flex red={!flaw.fixedInMaintenance} green={!!flaw.fixedInMaintenance}>
                                    <p className="value"><i className={`far fa-${flaw.fixedInMaintenance ? "check" : "times"}-circle`} /></p>
                                    <p className="title"><b>{i18next.t("page.private.flaws.general.status")}</b> {flaw.fixedInMaintenance ? i18next.t("page.private.flaws.general.fixed") : i18next.t("page.private.flaws.general.open")}</p>
                                </SingleAmount>
                                <SingleAmount light>
                                    <p className="value"><b>{i18next.t("page.private.flaws.general.measureServiceProvider")}</b></p>
                                    <p className="title">{flaw.actionServiceProvider || "Keine"}</p>
                                </SingleAmount>
                                <SingleAmount light>
                                    <p className="value"><b>{i18next.t("page.private.flaws.general.measureOperator")}</b></p>
                                    <p className="title">{flaw.actionOperator || "Keine"}</p>
                                </SingleAmount>
                            </Grid>
                        </GridElement>
                        <GridElement columnStart={2} columnEnd={3}>
                            <Grid gap="32px" columns={[ "1fr" ]}>
                                <GridElement styled title={i18next.t("page.private.flaws.frameData.title")} icon="database">
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={3}>
                                            <Input readOnly adjustWidth label={i18next.t("page.private.flaws.frameData.name")} value={flaw.name} icon="bolt" />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Input readOnly adjustWidth label={i18next.t("page.private.flaws.frameData.type")} value={getType(flaw.type)} icon={renderIcon(flaw.type)} />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={4}>
                                            <Input readOnly adjustWidth label={i18next.t("page.private.flaws.frameData.description")} value={flaw.description} icon="font" />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={2}>
                                            <Input readOnly adjustWidth label={i18next.t("page.private.flaws.frameData.entryData")} value={moment(flaw.maintenance.date, "DD.MM.YYYY").format("dddd, DD. MMMM YYYY")} icon="hourglass-start" />
                                        </GridElement>
                                        <GridElement columnStart={2} columnEnd={3}>
                                            <Input readOnly adjustWidth label={i18next.t("page.private.flaws.frameData.priority")} value={flaw.priority === 1 ? "Niedrig" : (flaw.priority === 2 ? "Mittel" : "Hoch")} icon="exclamation-circle" />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Input readOnly adjustWidth label={i18next.t("page.private.flaws.frameData.deadline")} value={flaw.deadline ? moment(flaw.deadline).format("DD.MM.YYYY") : null} icon="hourglass-end" />
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                                <GridElement styled title={i18next.t("page.private.flaws.beforeImage")} icon="images">
                                    <Grid gap="16px" padding="16px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                                        {imageStacksBefore.map((imageStack, index) => <React.Fragment key={index}>
                                            {imageStack.map((image, index) => <GridElement key={index} columnStart={index + 1} columnEnd={index + 2}>
                                                <Image onClick={() => this.photoModal.open(image)} none={!image} src={image || "/noImage.jpg"} />
                                            </GridElement>)}
                                        </React.Fragment>)}
                                    </Grid>
                                </GridElement>
                                <GridElement styled title={i18next.t("page.private.flaws.afterImage")} icon="images">
                                    <Grid gap="16px" padding="16px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                                        {imageStacksAfter.map((imageStack, index) => <React.Fragment key={index}>
                                            {imageStack.map((image, index) => <GridElement key={index} columnStart={index + 1} columnEnd={index + 2}>
                                                <Image onClick={() => this.photoModal.open(image)} none={!image} src={image || "/noImage.jpg"} />
                                            </GridElement>)}
                                        </React.Fragment>)}
                                    </Grid>
                                </GridElement>
                            </Grid>
                        </GridElement>
                    </Grid>
                </Section.Item>
            </Section>
        </Page>
    }
}
