import React, { Component } from "react"
import styled from "styled-components";
import i18next from "i18next";

import MomentLocaleUtils from "react-day-picker/moment";
import ReactApexChart from "react-apexcharts";
import { GridElement, Grid, Button } from "scanmetrix-components"
import { LastVisitComponent, LastUserActivities } from "../index"
import {UtilitiesStatement} from "../../subsidiary";
import ElementTitle from "../styled/ElementTitle";
import { withRouter } from "react-router-dom"
import ContractDatesModal from "./ContractDatesModal";
import TitleButton from "../styled/TitleButton";

const UtilitiesStatementsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: min-content min-content;
    grid-gap: 32px;
    grid-row-start: 3;
    grid-row-end: 4;
`


const StyledYearSelect = styled.div`
    width: 100%;
    
    .year {
        box-sizing: border-box;
        padding: 0 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        cursor: pointer;
        transition: opacity 0.3s;
        border-radius: 5px;
        opacity: 0.65;
        user-select: none;
        
        p:first-child {
            font-size: 18px;
        }
        
        &:hover {
            opacity: 1;
        }
        
        &.selected {
            background: linear-gradient(30deg,#0f7abf,#58b9f9);
            box-shadow: 0 0 6px 0 #1a699c66;
            color: white;
            opacity: 1 !important;
        }
    }
`

class PieChart extends Component {
    state = {
        options: {
            chart: {
                width: 600,
                type: 'donut'
            },
            plotOptions: {
                pie: {
                    expandOnClick: false
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: '75%'
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: {
                show: false
            }
        }
    }

    render() {
        const format = val => {
            if(this.props.format) return this.props.format(val)

            return val
        }

        return <ReactApexChart options={{ ...this.state.options, colors: this.props.series.map(serie => serie.color), labels: this.props.series.map(serie => serie.name),
            dataLabels: {
                enabled: true,
                formatter: (_, opts) => {
                    return format(this.props.series[opts.seriesIndex].value)
                }
            },
            tooltip: {
                y: {
                    formatter: format
                }
            }, }} series={this.props.series.map(serie => serie.value)} type='donut' height={250} />
    }
}

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})


const YearSelectContainer = styled.div`
    margin: 16px;
    padding-right: 16px;
    overflow-y: scroll;
    height: 546px;
    
    ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.075);
        border-radius: 4px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
    }
`

const MiniChart = styled.div`
    box-sizing: border-box;
    padding: 16px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 100%;
    user-select: none;
    
    .title {
        font-size: 24px;
        margin-bottom: 4px;
        color: ${props => props.color};
        font-weight: 400;
    }
    
    .subtitle {
       font-size: 14px;
       line-height: 20px;
       opacity: 0.75;
    }
`


class BarChart extends Component {
    state = {
        options: {
            chart: {
                width: "100%",
                type: 'bar'
            },
            dataLabels: {
                enabled: false,
                formatter: formatter.format
            },
            yaxis: {
                labels: { formatter: formatter.format }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: {
                show: false
            }
        }
    }

    render() {
        return <ReactApexChart options={{ ...this.state.options, colors: this.props.series.map(serie => serie.color), xaxis: { categories: this.props.categories }}} series={this.props.series.map(serie => ({ name: serie.title, data: serie.data }))} type='bar' width="100%" height={250} />
    }
}

class YearSelect extends Component {
    render() {
        return <YearSelectContainer>
            <StyledYearSelect>
                {this.props.years.map((year, index) => <div key={year} onClick={() => this.props.onChange(year)} className={`year ${year === this.props.year ? "selected" : ""}`}>
                    <p>{year}</p>
                    {this.props.values && <p>{formatter.format(this.props.values[index])}</p>}
                </div>)}
            </StyledYearSelect>
        </YearSelectContainer>
    }
}

class UtilitiesStatements extends Component {
    state = {
        year: moment().year(),
        categories: [],
        utilitiesStatements: [],
        years: [],
        yearValues: null
    }

    constructor(props) {
        super(props)

        for(let i = 0; i < 25; i++) {
            this.state.years.push(this.state.year - i)
        }

        this.state.categories = MomentLocaleUtils.getMonths("de")

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Users {
                        nodes {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                    UtilitiesStatements {
                        nodes {
                            id
                            user {
                                id
                            }
                            value
                            date
                            state
                            type
                            biller
                            aggregate
                            notes
                            year
                            subsidiary {
                                id
                                label
                                name
                                address {
                                    city
                                    postalCode
                                }
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({
                utilitiesStatements: data.data.UtilitiesStatements.nodes,
                users: data.data.Users.nodes,
                yearValues: this.state.years.map(year => data.data.UtilitiesStatements.nodes.filter(us => us.year === year && us.value > 0).map(us => us.value).reduce((a, b) => a + b, 0))
            })
        })
    }

    render() {
        const utilitiesStatements = this.state.utilitiesStatements.filter(us => us.year === this.state.year)

        return <UtilitiesStatementsContainer>
            <GridElement styled title={i18next.t("page.private.dashboard.propertyManagement.selectYear")} columnStart={1} columnEnd={2} rowStart={1} rowEnd={3} icon="calendar">
            {/* <div dangerouslySetInnerHTML={{ __html: i18next.t("page.private.dashboard.propertyManagement.selectYear") }}></div> */}
                <YearSelect values={this.state.yearValues} years={this.state.years} year={this.state.year} onChange={year => this.setState({ year })} />
            </GridElement>
            <GridElement styled title={i18next.t("page.private.dashboard.propertyManagement.savingShares")} icon="chart-pie">
                <div style={{ height: 226, overflow: "hidden" }}>
                    <PieChart format={val => (Math.round(val * 100) / 100) + " %"} series={[
                        {
                            color: "#2ecc71",
                            name: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.operatingCosts"),
                            value: 100 * (utilitiesStatements.filter(us => us.value > 0 && us.type === "overhead").map(us => us.value).reduce((a, b) => a + b, 0) / (utilitiesStatements.filter(us => us.value > 0).map(us => us.value).reduce((a, b) => a + b, 0) || 1))
                        },
                        {
                            color: "#3498db",
                            name: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.sewageCharges"),
                            value: 100 * (utilitiesStatements.filter(us => us.value > 0 && us.type === "effluent").map(us => us.value).reduce((a, b) => a + b, 0) / (utilitiesStatements.filter(us => us.value > 0).map(us => us.value).reduce((a, b) => a + b, 0) || 1))
                        }
                    ]} />
                </div>
            </GridElement>
            <GridElement styled title={i18next.t("page.private.dashboard.propertyManagement.processingStatuses")} icon="wrench">
                <div style={{ height: 226, overflow: "hidden" }}>
                    <PieChart format={val => val + i18next.t("page.private.dashboard.propertyManagement.pieces")} series={[
                        {
                            color: "#34495e",
                            name: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.notApplicable"),
                            value: utilitiesStatements.filter(us => us.state == "notApplicable").length
                        },
                        {
                            color: "#e67e22",
                            name: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.pending"),
                            value: utilitiesStatements.filter(us => us.state == "pending").length
                        },
                        {
                            color: "#3498db",
                            name: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.inProcess"),
                            value: utilitiesStatements.filter(us => us.state == "afoot").length
                        },
                        {
                            color: "#27ae60",
                            name: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.done"),
                            value: utilitiesStatements.filter(us => us.state == "done").length
                        },
                        {
                            color: "#e74c3c",
                            name: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.rebuffed"),
                            value: utilitiesStatements.filter(us => us.state == "rebuffed").length
                        },
                        {
                            color: "#9b59b6",
                            name: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.requested"),
                            value: utilitiesStatements.filter(us => us.state == "requested").length
                        },
                        {
                            color: "#7f8c8d",
                            name: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.notRequested"),
                            value: utilitiesStatements.filter(us => us.state == "notRequested").length
                        }
                    ]} />
                </div>
            </GridElement>
            <GridElement styled title={i18next.t("page.private.dashboard.propertyManagement.operatingCost.operatingCostTitle")} icon="house-day">
                <MiniChart color="#2ecc71">
                    <div>
                        <p className="title">{formatter.format(utilitiesStatements.filter(us => us.value > 0 && us.type === "overhead").map(us => us.value).reduce((a, b) => a + b, 0))}</p>
                        <p className="subtitle">{i18next.t("page.private.dashboard.propertyManagement.operatingCost.operatingCostSavings")}</p>
                    </div>
                    <div>
                        <p className="title">{formatter.format(utilitiesStatements.filter(us => us.value > 0 && us.type === "overhead").map(us => us.value).reduce((a, b) => a + b, 0) / ([...new Set(utilitiesStatements.filter(us => us.value > 0 && us.type === "overhead").map(us => us.subsidiary.id))].length || 1))}</p>
                        <p className="subtitle">{i18next.t("page.private.dashboard.propertyManagement.operatingCost.branchSavings")}</p>
                    </div>
                </MiniChart>
            </GridElement>
            <GridElement styled title={i18next.t("page.private.dashboard.propertyManagement.wastewater.wastewaterTitle")} icon="tint-slash">
                <MiniChart color="#3498db">
                    <div>
                        <p className="title">{formatter.format(utilitiesStatements.filter(us => us.value > 0 && us.type === "effluent").map(us => us.value).reduce((a, b) => a + b, 0))}</p>
                        <p className="subtitle">{i18next.t("page.private.dashboard.propertyManagement.wastewater.wastewaterSavings")}</p>
                    </div>
                    <div>
                        <p className="title">{formatter.format(utilitiesStatements.filter(us => us.value > 0 && us.type === "effluent").map(us => us.value).reduce((a, b) => a + b, 0) / ([...new Set(utilitiesStatements.filter(us => us.value > 0 && us.type === "effluent").map(us => us.subsidiary.id))].length || 1))}</p>
                        <p className="subtitle">{i18next.t("page.private.dashboard.propertyManagement.wastewater.branchSavings")}</p>
                    </div>
                </MiniChart>
            </GridElement>
            <GridElement columnStart={2} columnEnd={6} rowStart={2} rowEnd={3} styled title={i18next.t("page.private.dashboard.propertyManagement.monthlySavings")} icon="chart-bar">
                <BarChart categories={this.state.categories} title={i18next.t("page.private.dashboard.propertyManagement.totalSavings")} series={[
                    {
                        color: "#2ecc71",
                        title: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.operatingCosts"),
                        data: this.state.categories.map((_, i) => utilitiesStatements.filter(us => us.value > 0 && us.type === "overhead" && us.date && moment(us.date).month() === i).map(us => us.value).reduce((a, b) => a + b, 0))
                    },
                    {
                        color: "#3498db",
                        title: i18next.t("page.private.dashboard.propertyManagement.pieChartValues.sewageCharges"),
                        data: this.state.categories.map((_, i) => utilitiesStatements.filter(us => us.value > 0 && us.type === "effluent" && us.date && moment(us.date).month() === i).map(us => us.value).reduce((a, b) => a + b, 0))
                    }
                ]} />
            </GridElement>
            <GridElement columnStart={1} columnEnd={6}>
                <Grid gap="24px">
                    {[... new Set(utilitiesStatements.map(us => us.subsidiary.id))].map(subsidiaryId => {
                        const subsidiary = utilitiesStatements.find(us => us.subsidiary.id === subsidiaryId).subsidiary

                        return <div key={subsidiaryId}>
                            <h2 className="title" style={{ fontWeight: 300, fontSize: "18px", marginBottom: 12, userSelect: "none" }}>{i18next.t("page.private.dashboard.propertyManagement.branch")} {subsidiary.name + (subsidiary.label ? (" (" + subsidiary.label + ")") : "")}, {subsidiary.address.postalCode} {subsidiary.address.city}</h2>
                            <Grid gap="24px">
                                {utilitiesStatements.filter(us => us.subsidiary.id === subsidiaryId).map(us => <UtilitiesStatement key={us.id} data={us} readOnly users={this.state.users} />)}
                            </Grid>
                        </div>
                    })}
                </Grid>
            </GridElement>
        </UtilitiesStatementsContainer>
    }
}

const ContractDates = styled.div`
    background: white;
    box-sizing: border-box;
    padding: 16px;
    box-shadow: ${props => props.embed ? "none" : "0 8px 24px -16px rgb(0 0 0 / 40%)"};
    border-radius: 5px;
    grid-row-start: 5;
    grid-row-end: 6;
    overflow-y: auto;
    max-height: ${props => props.embed ? "70vh" : "auto"};
    min-height: 500px;
  
    table {
        table-layout: fixed;
        width: 100%;
        border-spacing: 0;
        
        td, th {
          &:first-child {
            width: 17.5%;
          }

          &:nth-child(2) {
            width: 17.5%;
          }

          &:nth-child(3) {
            width: 12.5%;
          }

          &:nth-child(4) {
            width: 15%;
          }

          &:nth-child(5) {
            width: 7.5%;
          }

          &:nth-child(6) {
            width: 25%;
          }

          &:last-child {
            width: 5%;
          }
        }
      
        thead {
            width: 100%;
            
            tr {
                th {
                    text-align: left;
                    font-weight: normal;
                    height: 48px;
                    font-size: 18px;
                    color: #3b97d3;
                  
                    &:first-child {
                        padding-left: 16px;
                    }
                }
            }
        }
        tbody {
            width: 100%;
          
            tr {
                height: 48px;
              
                &:nth-child(even) {
                    background: rgba(0, 0, 0, 0.05);
                }
              
                &:hover {
                  td {
                    background: rgb(59 151 211 / 9%);
                  }
                }
              
                td {
                    height: 48px;
                    padding-right: 16px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  
                    b {
                        color: #e74c3c;
                    }
                    
                    &:first-child {
                        padding-left: 16px;
                        border-radius: 5px 0 0 5px;
                      
                        i {
                            width: 24px;
                            color: #3b97d3;
                        }
                    }
                  
                    &:last-child {
                        text-align: right;
                        color: #3b97d3;
                        border-radius: 0 5px 5px 0;
                    }
                  
                    &:nth-child(5) {
                        i {
                            width: 24px;
                          
                            &.fa-check-circle {
                                color: #1abc9c;
                            }
                          
                            &.fa-times-circle {
                                color: #e74c3c;
                            }
                        }
                    }
                }
            }
        }
    }
`

const Easements = styled.div`
    background: white;
    max-height: 250px;
    width: 100%;
  box-sizing: border-box;
  padding: 16px;
  box-shadow: 0 8px 24px -16px rgb(0 0 0 / 40%);
  border-radius: 5px;
    overflow-y: scroll;
    grid-column-start: ${props => props.columnStart || 1};
    grid-column-end: ${props => props.columnEnd || 2};
  
    .title {
        width: 100%;
        font-size: 18px;
        margin-bottom: 16px;
        color: #3b97d3;
    }
  
    .subsidiaries {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      
        .subsidiary {
            cursor: pointer;
          
            &:hover {
                opacity: 0.5;
            }
          
            .location {
                font-size: 12px;
                margin-top: 4px;
                opacity: 0.75;
            }
        }
    }
`

const getNameForType = type => {
    if(type === "extensionOption") return i18next.t("page.private.dashboard.propertyManagement.deadlineType.extensionOption")
    if(type === "contractEnd") return i18next.t("page.private.dashboard.propertyManagement.deadlineType.contractEnd")
    if(type === "contractStart") return i18next.t("page.private.dashboard.propertyManagement.deadlineType.contractStart")
    if(type === "cancelationDate") return i18next.t("page.private.dashboard.propertyManagement.deadlineType.cancelationDate")
    if(type === "automaticRenewal") return i18next.t("page.private.dashboard.propertyManagement.deadlineType.automaticRenewal")
}

const getIconForType = type => {
    if(type === "extensionOption") return "history"
    if(type === "contractEnd") return "hourglass-end"
    if(type === "contractStart") return "hourglass-start"
    if(type === "cancelationDate") return "ban"
    if(type === "automaticRenewal") return "forward"
}

const contractTypes = [
    { key: "onDemand", title: i18next.t("page.private.dashboard.propertyManagement.contractType.onDemand") },
    { key: "surveyor", title: i18next.t("page.private.dashboard.propertyManagement.contractType.surveyor") },
    { key: "maintenance", title: i18next.t("page.private.dashboard.propertyManagement.contractType.maintenance") },
    { key: "general", title: i18next.t("page.private.dashboard.propertyManagement.contractType.general") },
    { key: "rent", title: i18next.t("page.private.dashboard.propertyManagement.contractType.rent") },
    { key: "rentAd", title: i18next.t("page.private.dashboard.propertyManagement.contractType.rentAd") },
    { key: "rentSub", title: i18next.t("page.private.dashboard.propertyManagement.contractType.rentSub") },
    { key: "commodate", title: i18next.t("page.private.dashboard.propertyManagement.contractType.commodate") },
    { key: "nonCashLending", title: i18next.t("page.private.dashboard.propertyManagement.contractType.nonCashLending") },
    { key: "endowment", title: i18next.t("page.private.dashboard.propertyManagement.contractType.endowment") },
    { key: "barter", title: i18next.t("page.private.dashboard.propertyManagement.contractType.barter") },
    { key: "purchase", title: i18next.t("page.private.dashboard.propertyManagement.contractType.purchase") },
    { key: "loan", title: i18next.t("page.private.dashboard.propertyManagement.contractType.loan") },
    { key: "service", title: i18next.t("page.private.dashboard.propertyManagement.contractType.service") },
    { key: "work", title: i18next.t("page.private.dashboard.propertyManagement.contractType.work") },
    { key: "leasing", title: i18next.t("page.private.dashboard.propertyManagement.contractType.leasing") },
    { key: "lease", title: i18next.t("page.private.dashboard.propertyManagement.contractType.lease") },
    { key: "deployment", title: i18next.t("page.private.dashboard.propertyManagement.contractType.deployment") },
    { key: "guarantee", title: i18next.t("page.private.dashboard.propertyManagement.contractType.guarantee") },
    { key: "saas", title: i18next.t("page.private.dashboard.propertyManagement.contractType.saas") },
    { key: "estateDuties", title: i18next.t("page.private.dashboard.propertyManagement.contractType.estateDuties") },
    { key: "energyPower", title: i18next.t("page.private.dashboard.propertyManagement.contractType.energyPower") },
    { key: "energyWater", title: i18next.t("page.private.dashboard.propertyManagement.contractType.energyWater") },
    { key: "energyGas", title: i18next.t("page.private.dashboard.propertyManagement.contractType.energyGas") },
    { key: "energyHeat", title: i18next.t("page.private.dashboard.propertyManagement.contractType.energyHeat") },
    { key: "insurance", title: i18next.t("page.private.dashboard.propertyManagement.contractType.insurance") },
    { key: "eegReward", title: i18next.t("page.private.dashboard.propertyManagement.contractType.eegReward") },
    { key: "other", title: i18next.t("page.private.dashboard.propertyManagement.contractType.other") }
]

const getNameForContractType = type => {
    const items = contractTypes

    if(!type) return ""

    return items.find(i => i.key === type).title
}

export default withRouter(class extends Component {
    state = { contracts: [], easementSubsidiaries: [], metersLeft: [], metersRight: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetch()
    }

    fetch() {
        fetch(scanmetrix.backendURL + "/pm-dashboard/meters?from=" + moment("15.07." + (moment().year() - 1), "DD.MM.YYYY").format("DD.MM.YYYY") + "&until=" + moment().startOf("year").format("DD.MM.YYYY"), {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => this.setState({ metersLeft: data.subsidiaries }))

        fetch(scanmetrix.backendURL + "/pm-dashboard/meters?from=" + moment("01.01." + moment().year(), "DD.MM.YYYY").format("DD.MM.YYYY") + "&until=" + moment("15.07." + moment().year(), "DD.MM.YYYY").format("DD.MM.YYYY"), {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => this.setState({ metersRight: data.subsidiaries }))

        fetch(scanmetrix.backendURL + "/pm-dashboard/easements", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => this.setState({ easementSubsidiaries: data.subsidiaries }))

        fetch(scanmetrix.backendURL + "/pm-dashboard/contracts", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => this.setState({ contracts: data.contracts }))
    }

    render() {
        return <>
            <ContractDatesModal instance={ref => this.contractDatesModal = ref} data={this.state.contracts} />
            <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 48, width: "100%", gridColumnStart: 1, gridColumnEnd: 7 }}>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 48 }}>
                    <LastVisitComponent statistics={this.props.statistics} />
                    <Easements columnStart={2} columnEnd={3}>
                        <div className="title">{i18next.t("page.private.dashboard.propertyManagement.content")}</div>
                        <div className="subsidiaries">
                            {this.state.easementSubsidiaries.map((subsidiary, index) => <div onClick={() => this.props.history.push(`/subsidiary/${subsidiary.id}`)} className="subsidiary" key={index}>
                                <div className="label">{subsidiary.label || subsidiary.name}</div>
                                <div className="location">{subsidiary.postalCode} {subsidiary.city}</div>
                            </div>)}
                        </div>
                    </Easements>
                </div>
                <ElementTitle rowStart={2} rowEnd={3} columnEnd={2}>{i18next.t("page.private.dashboard.propertyManagement.serviceCharges")}</ElementTitle>
                <UtilitiesStatements />
                <ElementTitle rowStart={4} rowEnd={5} columnEnd={2}>{i18next.t("page.private.dashboard.propertyManagement.contractManagement")} <TitleButton onClick={() => this.contractDatesModal.open()}>{i18next.t("page.private.dashboard.propertyManagement.viewAll")}</TitleButton></ElementTitle>
                    <ContractDatesComponent data={[...this.state.contracts].slice(0, 10)} />
                <ElementTitle rowStart={6} rowEnd={7} columnEnd={2}>{i18next.t("page.private.dashboard.propertyManagement.meterReadings")}</ElementTitle>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 48, gridRowStart: 7, gridRowEnd: 8 }}>
                    <Easements columnStart={1} columnEnd={2}>
                        <div className="title">{i18next.t("page.private.dashboard.propertyManagement.missingMeter0101")}{moment().year()}</div>
                        <div className="subsidiaries">
                            {this.state.metersLeft.map((subsidiary, index) => <div onClick={() => this.props.history.push(`/subsidiary/${subsidiary.id}`)} className="subsidiary" key={index}>
                                <div className="label">{subsidiary.label || subsidiary.name}</div>
                                <div className="location">{subsidiary.postalCode} {subsidiary.city}</div>
                            </div>)}
                        </div>
                    </Easements>
                    <Easements columnStart={2} columnEnd={3}>
                        <div className="title">{i18next.t("page.private.dashboard.propertyManagement.missingMeter1507")}{moment().year()}</div>
                        <div className="subsidiaries">
                            {this.state.metersRight.map((subsidiary, index) => <div onClick={() => this.props.history.push(`/subsidiary/${subsidiary.id}`)} className="subsidiary" key={index}>
                                <div className="label">{subsidiary.label || subsidiary.name}</div>
                                <div className="location">{subsidiary.postalCode} {subsidiary.city}</div>
                            </div>)}
                        </div>
                    </Easements>
                </div>
                <ElementTitle rowStart={8} rowEnd={9} columnEnd={2}>{i18next.t("page.private.dashboard.propertyManagement.recentActiveUsers")}</ElementTitle>
                <LastUserActivities users={this.props.users} columnEnd={2} rowStart={9} rowEnd={10} />
            </div>
        </>
    }
})

const ContractDatesComponent = withRouter(class extends Component {
    render() {
        return <ContractDates embed={this.props.embed}>
            <table>
                <thead>
                <tr>
                    <th>{i18next.t("page.private.dashboard.propertyManagement.contractManagementTable.deadlineType")}</th>
                    <th>{i18next.t("page.private.dashboard.propertyManagement.contractManagementTable.deadlineDate")}</th>
                    <th>{i18next.t("page.private.dashboard.propertyManagement.contractManagementTable.contractNumber")}</th>
                    <th>{i18next.t("page.private.dashboard.propertyManagement.contractManagementTable.contractPartner")}</th>
                    <th>{i18next.t("page.private.dashboard.propertyManagement.contractManagementTable.automaticRenewal")}</th>
                    <th>{i18next.t("page.private.dashboard.propertyManagement.contractManagementTable.contractType")}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {this.props.data.map((contract, index) => <tr key={index}>
                    <td><i className={`fa-duotone fa-${getIconForType(contract.type)}`} /> {getNameForType(contract.type)}</td>
                    <td>{moment(contract.date).format("DD.MM.YYYY")} {moment(contract.date).isSameOrBefore(moment().add(3, "months")) ? <b>({moment(contract.date).fromNow()})</b> : <>({moment(contract.date).fromNow()})</>}</td>
                    <td>{contract.number}</td>
                    <td>{contract.partner}</td>
                    <td><i className={`fas fa-${contract.automaticRenewal ? "check-circle" : "times-circle"}`} />{contract.automaticRenewal ? i18next.t("page.private.dashboard.propertyManagement.yes") : i18next.t("page.private.dashboard.propertyManagement.no")}</td>
                    <td>{getNameForContractType(contract.contractType)}</td>
                    <td style={{ cursor: "pointer" }} onClick={() => this.props.history.push(`/contract/${contract.id}`)}><i className="far fa-arrow-right" /></td>
                </tr>)}
                </tbody>
            </table>
        </ContractDates>
    }
})

export { contractTypes, ContractDatesComponent }
