import React, {Component} from "react";
import DOMPurify from "dompurify";
import styled from "styled-components";

const StyledPost = styled.div`
  width: 100%;
  padding: 0 0 0 32px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 64px;
  box-sizing: border-box;
  
  .topAvatar {
    width: 64px;
    height: 64px;
    border-radius: 64px;
    position: absolute;
    left: 0;
    top: 60px;
    transform: translateY(-50%);
    object-fit: cover;
    user-select: none;
    user-drag: none;
    box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .post {
    padding: 48px;
    position: relative;
    box-shadow: 0 8px 32px -8px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 8px 8px 0 16px;
    background: white;
    
    .topic {
      margin-bottom: 16px;
      font-weight: normal;
    }
    
    .topLabel {
      width: 100%;
      display: flex;
      justify-content: space-between;
      user-select: none;
      margin-bottom: 24px;
      
      .right {
        display: flex;
        align-items: center;
        
        .context {
          margin-left: 16px;
            
          i {
            font-size: 1.4em;
            cursor: pointer;
            opacity: 0.5;
            transition: opacity 0.3s;
            
            &:hover {
              opacity: 1;
            }
          }
        }
        
        .date {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    
    .content {
      line-height: 1.7em;
      color: rgba(0, 0, 0, 0.75);
      text-align: justify;
      
      a {
        color: #3B97D3;
        text-decoration: none;
        font-weight: bold;
        
        &:active {
          opacity: 0.5;
        }
      }
    }
    
    .author {
      font-size: 1.1em;
      color: rgba(0, 0, 0, 0.5);
      
      b {
        color: #3B97D3;
      }
    }
  }
  
  .commentsOuter {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    margin-left: 64px;
    border-radius: 0 0 8px 16px;
  }
  
  .comments {
    border-radius: 0 0 8px 16px;
    background: white;
    box-shadow: inset 0 32px 48px -48px rgba(0, 0, 0, 0.1);
    
    .comment {
      display: flex;
      justify-content: space-between;
      padding: 24px 0;
      padding-right: 48px;
      
      &:first-child {
        padding-top: 48px;
      }
      
      .content {
        padding-left: 24px;
        box-sizing: border-box;
        width: 100%;
        
        .topLabel {
          display: flex;
          justify-content: space-between;
          width: 100%;
          user-select: none;
          
          .right {
            display: flex;
                      
            .context {
              margin-left: 16px;
                        
              i {
                cursor: pointer;
                opacity: 0.5;
                transition: opacity 0.3s;
                
                &:hover {
                  opacity: 1;
                }
              }
            }
                      
            .date {
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
        
        .name {
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 8px;
          font-size: 1em;
          
          b {
            color: #3B97D3;
          }
        }
        
        .text {
          line-height: 1.6em;
          text-align: justify;
          color: rgba(0, 0, 0, 0.75);
          
          a {
            color: #3B97D3;
            text-decoration: none;
            font-weight: bold;
            
            &:active {
              opacity: 0.5;
            }
          }
        }
      }
      
      .date {
        font-size: 0.9em;
        white-space: nowrap;
      }
      
      .avatar {
        width: 48px;
        height: 48px;
        margin-left: -28px;
        padding: 4px;
        background: white;
        border-radius: 48px;
        object-fit: cover;
        user-select: none;
        user-drag: none;
        box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.75);
      }
    }
  }
`

const Reply = styled.div`
  position: absolute;
  right: 20px;
  bottom: -20px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  border-radius: 40px;
  background: white;
  user-select: none;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.3);
  color: #20242b;
  transform: translateY(0);
  transition: transform 0.3s, color 0.3s, box-shadow 0.3s;
  
  &:hover {
    color: #3B97D3;
    transform: translateY(-4px);
    box-shadow: 0 3px 12px -2px rgba(0, 0, 0, 0.2);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  i {
    margin-left: 8px;
  }
`

export default class extends Component {
    render() {
        const author = this.props.author

        return <StyledPost>
            <Reply>
                <p className="title">Antworten</p>
                <i className="far fa-reply" />
            </Reply>
            <img className="topAvatar" src={author.avatarURL} />
            <div className="post">
                <div className="topLabel">
                    <p className="author"><b>{author.name}</b> - {author.title}</p>
                    <div className="right">
                        <p className="date">{this.props.date}</p>
                        <p className="context"><i className="far fa-ellipsis-h" /></p>
                    </div>
                </div>
                <h2 className="topic">{this.props.topic}</h2>
                <p className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.content).replace(/@([a-z\d_-]+)/ig, '<a href="#/$1">@$1</a>')}}></p>
            </div>
            {this.props.comments && <div className="commentsOuter"><div className="comments">
                {this.props.comments.map((comment, index) => <div className="comment" key={index}>
                    <img className="avatar" src={comment.author.avatarURL} />
                    <div className="content">
                        <div className="topLabel">
                            <p className="name"><b>{comment.author.name}</b> - {comment.author.title}</p>
                            <div className="right">
                                <p className="date">{comment.date}</p>
                                <p className="context"><i className="far fa-ellipsis-h" /></p>
                            </div>
                        </div>
                        <p className="text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(comment.content).replace(/@([a-z\d_-]+)/ig, '<a href="#/$1">@$1</a>')}}></p>
                    </div>
                </div>)}
            </div></div>}
        </StyledPost>
    }
}
