import React, { Component } from "react"
import { Table, GridElement, Section } from "scanmetrix-components"
import { withRouter } from "react-router-dom"
import i18next from "i18next"

export default withRouter(class extends Component {
    state = { workSections: [] }

    constructor(props) {
        super(props)

        fetch(scanmetrix.backendURL + "/subsidiary-missing-work-sections", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ workSections: data.workSections })
        })
    }

    render() {
        return <Section.Item title={i18next.t("page.private.facilityManagement.undeclaredTradeAssignments.title")} icon="exclamation-triangle" {...this.props} amountColor={this.state.workSections.map(d => d.subsidiaries.length).reduce((a, b) => a + b, 0) > 0 ? "#e67e22" : "#16a085"} amount={this.state.workSections.map(d => d.subsidiaries.length).reduce((a, b) => a + b, 0)}>
            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 32 }}>
                {this.state.workSections.map(workSection => <GridElement styled title={workSection.name} icon={workSection.icon} key={workSection.id}>
                    <Table
                        noControls
                        head={[
                            { title: i18next.t("page.private.facilityManagement.undeclaredTradeAssignments.table.name"), width: "25%", column: "name" },
                            { title: i18next.t("page.private.facilityManagement.undeclaredTradeAssignments.table.label"), width: "15%", column: "label" },
                            { title: i18next.t("page.private.facilityManagement.undeclaredTradeAssignments.table.legalName"), width: "20%", column: "legalName" },
                            { title: i18next.t("page.private.facilityManagement.undeclaredTradeAssignments.table.street"), width: "20%", column: "street" },
                            { title: i18next.t("page.private.facilityManagement.undeclaredTradeAssignments.table.postalCode"), width: "7.5%", column: "postalCode" },
                            { title: i18next.t("page.private.facilityManagement.undeclaredTradeAssignments.table.city"), width: "12.5%", column: "city" }
                        ]}
                        onItemClick={subsidiary => this.props.history.push(`/subsidiary/${subsidiary.id}`)}
                        contextItems={[ 
                            { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: subsidiary => window.open(`/subsidiary/${subsidiary.id}`, '_blank')}
                        ]}
                        items={workSection.subsidiaries.map(subsidiary => {
                            return ({
                                id: subsidiary.id,
                                label: subsidiary.label,
                                name: subsidiary.name,
                                legalName: subsidiary.legalName,
                                street: `${subsidiary.address.streetName} ${subsidiary.address.streetNumber}`,
                                postalCode: subsidiary.address.postalCode,
                                city: subsidiary.address.city
                            })
                        })}
                    />
                </GridElement>)}
            </div>
        </Section.Item>
    }
})
