import qs from "qs"

export default function() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    if(params["email-verification"] && params.id) {
        this.props.history.push("/login")

        const emailVerificationToken = params["email-verification"]
        const userId = params.id

        fetch(scanmetrix.backendURL + "/user/email-verification", {
            method: "POST",
            body: JSON.stringify({
                emailVerificationToken,
                userId
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(result => {
            const status = result.status

            if(status === "ERROR") {
                this.setState({ blurMode: "error", error: result.error })
            } else if(status === "VERIFICATION_SUCCESSFUL") {
                this.setState({ blurMode: "email-verification" })
            }
        })
    }
}
