import React, { Component } from 'react'
import { Button, Modal } from 'scanmetrix-components'
import styled from "styled-components";
import i18next from "i18next";

const FullSizeImage = styled.img`
  max-width: 768px;
  max-height: 768px;
  object-fit: cover;
  display: block;
`

export default class extends Component {
    render() {
        return <Modal instance={this.props.instance}>
            <Modal.Head title="Verlinkung angeben" icon="link" />
            <Modal.Body>
                <FullSizeImage src={this.props.src} />
            </Modal.Body>
        </Modal>
    }
}