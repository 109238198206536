import React, { Component } from "react"
import { Page, Breadcrumbs, Section } from "scanmetrix-components"

import Statistics from "./tabs/Statistics"
import Unbooked from "./tabs/Unbooked"
import FactualClearance from "./tabs/FactualClearance"
import FIClearance from "./tabs/FIClearance"
import Archive from "./tabs/Archive"
import WaitingForPayment from "./tabs/WaitingForPayment";
import i18next from "i18next"

export default class extends Component {
    state = { unbookedAmount: 0, factualClearanceAmount: 0, fiClearanceAmount: 0, archiveAmount: 0, waitingForPaymentAmount: 0 }

    render() {
        let disabledStatuses = scanmetrix.disableAccountingStatus

        if(!disabledStatuses) disabledStatuses = []

        console.log()

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "coins",
                    title: i18next.t("page.private.accounting.breadCrumbsTitle")
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Unbooked
                    amount={this.state.unbookedAmount}
                    onAmount={unbookedAmount => this.setState({ unbookedAmount })}
                    addFactualClearance={() => this.setState({ factualClearanceAmount: this.state.factualClearanceAmount + 1 })}
                />
                <FactualClearance
                    amount={this.state.factualClearanceAmount}
                    onAmount={factualClearanceAmount => this.setState({ factualClearanceAmount })}
                    addFIClearance={() => this.setState({ fiClearanceAmount: this.state.fiClearanceAmount + 1 })}
                    addArchive={() => this.setState({ archiveAmount: this.state.archiveAmount + 1 })}
                />
                {disabledStatuses.includes("fiClearance") ? <div></div> : <FIClearance
                    amount={this.state.fiClearanceAmount}
                    onAmount={fiClearanceAmount => this.setState({ fiClearanceAmount })}
                    addWaitingForPayment={() => this.setState({ waitingForPaymentAmount: this.state.waitingForPaymentAmount + 1 })}
                    addFactualClearance={() => this.setState({ factualClearanceAmount: this.state.factualClearanceAmount + 1 })}
                />}
                {disabledStatuses.includes("waitingForPayment") ? <div></div> : <WaitingForPayment
                    amount={this.state.waitingForPaymentAmount}
                    onAmount={waitingForPaymentAmount => this.setState({ waitingForPaymentAmount })}
                    addArchive={() => this.setState({ archiveAmount: this.state.archiveAmount + 1 })}
                />}
                <Archive
                    amount={this.state.archiveAmount}
                    onAmount={archiveAmount => this.setState({ archiveAmount })}
                />
                <Section.Item title={i18next.t("page.private.accounting.statistics.sectionTitle")} icon="chart-column">
                    <Statistics />
                </Section.Item>
            </Section>
        </Page>
    }
}
