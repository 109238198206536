import React, { Component } from "react"
import { Modal, Input, Button, Select, Grid, GridElement } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { id: null, name: null, loading: false, type: null, reportTemplateId: null, reportTemplates: [] }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    BuildingInspectionReportTemplates {
                        nodes {
                            id
                            name
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ reportTemplates: data.data.BuildingInspectionReportTemplates.nodes })
        })
    }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={data => this.setState({ id: data.id, name: data.name, reportTemplateId: data.reportTemplateId, type: data.type, loading: false })}>
            <Modal.Head title={i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.title")} icon="copy" />
            <Modal.Body adjustHeight="488px" padding={24}>
                <Grid gap="16px">
                    <GridElement>
                        <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.name")} adjustWidth />
                    </GridElement>
                    <GridElement>
                        <Select noUnselect required value={this.state.type} onChange={type => this.setState({ type })} icon="cog" label={i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.name")} adjustWidth items={[
                            { key: "safetyInspection", title: i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.typeTable.safetyInspection"), icon: "hard-hat" },
                            { key: "defectView", title: i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.typeTable.defectView"), icon: "bolt" },
                            { key: "test", title: i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.typeTable.test"), icon: "message-smile" },
                            { key: "visualControl", title: i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.typeTable.visualControl"), icon: "eye" },
                            { key: "maintenance", title: i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.typeTable.maintenance"), icon: "clipboard-list" },
                            { key: "riskAssessment", title: i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.typeTable.riskAssessment"), icon: "brake-warning" },
                            { key: "asa", title: i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.typeTable.asa"), icon: "user-doctor" },
                            { key: "sibe", title: i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.typeTable.sibe"), icon: "shield-check" }
                        ]} />
                    </GridElement>
                    <GridElement>
                        <Select value={this.state.reportTemplateId} onChange={reportTemplateId => this.setState({ reportTemplateId })} icon="tag" label={i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.reportTemplate")} adjustWidth items={this.state.reportTemplates.map(reportTemplate => ({
                            key: reportTemplate.id,
                            title: reportTemplate.name
                        }))} />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.type} title={i18next.t("page.private.buildingInspection.inspectionTemplates.duplicateBuildingInspectionTemplateModal.exitButton")} icon="copy" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $name: String!, $type: String!, $reportTemplateId: ID) {
                            duplicateBuildingInspectionTemplate(id: $id, name: $name, type: $type, reportTemplateId: $reportTemplateId)
                        }
                    `,
                    variables: {
                        id: this.state.id,
                        name: this.state.name,
                        type: this.state.type,
                        reportTemplateId: this.state.reportTemplateId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.duplicateBuildingInspectionTemplate) {
                        this.props.refresh().then(() => instance.close())
                    }
                })}
            } />} />
        </Modal>
    }
}
