import React, { Component } from "react"
import { Page, GridElement, Table, Breadcrumbs } from "scanmetrix-components"
import styled from "styled-components"

import { withRouter } from "react-router-dom"
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import ApexChart from 'react-apexcharts'

import i18next from "i18next"


const Flaws = styled.div`
  
  .dot {
    height: 100%;
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    
    &.red:after {
      background: #c0392b;
    }
    
    &.yellow:after {
      background: #e67e22;
    }
    
    &.green:after {
      background: #27ae60;
    }
    
    &:after {
      content: " ";
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }
  }
`

const EscalationStats = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;
  
  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    zoom: 0.75;
  }
  
  .box {
    padding: 32px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  }
`

const ProgressBody = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  
  .CircularProgressbar-path {
    stroke: white !important;
  }
  
  .CircularProgressbar-trail {
    stroke: rgba(255, 255, 255, 0.2) !important;
  }
  
  .CircularProgressbar-text {
    fill: white !important;
    font-weight: bold;
    font-size: 24px;
  }
  
  svg {
    width: 96px;
    height: 96px;
  }
  
  &:last-child {
    margin-right: 0;
  }
  
  .text-view {
    margin-top: 32px;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content:  flex-start;
    align-items: center; 
    
    .title {
      text-align: center;
      font-size: 1.5em;
      font-family: inherit;
      margin-bottom: 8px;
    }
    
    .description {
      font-size: 1.1em;
      opacity: 0.75;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 1600px) {
    .text-view {
      
      .title {
        font-size: 1.25em;
      }
      
      .description {
        font-size: 1em;
        word-break: break-word;
      }
    }
  }
`

class Statistics extends Component {
    state = {
        radialSeries: [this.props.value],
        radialOptions: {
            chart: {
                height: 120,
                type: 'radialBar'
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '70%'
                    },
                    dataLabels: {
                        show: false
                    }
                }
            }
        },
        pieSeries: this.props.priorityValues,
        pieOptions: {
            chart: {
                type: 'donut'
            },
            legend: {
                show: false
            },
            colors: [
                '#E74C3C', '#E67E22', '#2ECC71'
            ],
            labels: [
                  i18next.t("page.private.escalation.highPriority"), i18next.t("page.private.escalation.mediumPriority"), i18next.t("page.private.escalation.lowPriority")
            ],
            dataLabels: {
                enabled: false
            }
        }
    }

    render() {
        return<ProgressBody className='box' style={{ background: this.props.background }}>
            {!this.props.priority && <CircularProgressbar value={this.props.value} style={{ width: 96, height: 96 }} text={`${this.props.value}%`} />}
            {this.props.priority && <ApexChart options={this.state.pieOptions} series={this.state.pieSeries} type='donut' height={120} />}
            <div className='text-view'>
                <p style={{ color: this.props.color }} className='title'>{this.props.title}</p>
                <p style={{ color: this.props.color }} className='description'>{this.props.description}</p>
            </div>
        </ProgressBody>
    }
}

export default withRouter(class extends Component {
    state = { escalation: null }

    constructor(props) {
        super(props)

        fetch(scanmetrix.backendURL + "/escalation", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => this.setState({ escalation: data.flaws }))
    }

    render() {
        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "bolt",
                    title: i18next.t("page.private.escalation.breadCrumbsTitle")
                }
            ]} />

            {/*<EscalationStats>
                <Statistics value={32} color='white' background='linear-gradient(45deg, #4C5E7D, #8EA5D1)' title='Behobene Mängel (Ich)' description='Mängel in meiner Verantwortung' />
                <Statistics value={50} color='white' background='linear-gradient(45deg, #4C5E7D, #8EA5D1)' title='Behobene Mängel (Alle)' description='Kunden- und Verantwortungsübergreifend' />
                <Statistics value={75} color='white' background='linear-gradient(45deg, #4C5E7D, #8EA5D1)' title='Behobene Mängel (Ich)' description='Offene Mängel mit zugeteilter Zuständigkeit' />
                <Statistics priority priorityValues={[ 10, 25, 65 ]} color='#20242B' background='white' title='Prioritäten' description='Gegenüberstellung der offenen Prioritäten' />
            </EscalationStats>*/}

            <GridElement padding="0" styled title={i18next.t("page.private.escalation.gridElementTitle")} icon="bolt" columnStart={2} columnEnd={3}>
                <Flaws>
                    <Table
                        head={[
                            { title: i18next.t("page.private.escalation.table.branch"), width: "7.5%", column: "branch" },
                            { title: i18next.t("page.private.escalation.table.code"), width: "10%", column: "label" },
                            { title: i18next.t("page.private.escalation.table.location"), width: "12.5%", column: "location" },
                            { title: i18next.t("page.private.escalation.table.building"), width: "12.5%", column: "building" },
                            { title: i18next.t("page.private.escalation.table.name"), width: "22.5%", column: "name" },
                            { title: i18next.t("page.private.escalation.table.smid"), width: "12.5%", column: "smid" },
                            { title: i18next.t("page.private.escalation.table.priority"), width: "7.5%", column: "priority" },
                            { title: i18next.t("page.private.escalation.table.due"), width: "15%", column: "deadline" }
                        ]}
                        onItemClick={flaw => this.props.history.push(`/flaw/${flaw.serviceProvider.id}/${flaw.id}`)}
                        contextItems={[ 
                          { title: i18next.t("page.private.escalation.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: flaw => window.open(`/flaw/${flaw.serviceProvider.id}/${flaw.id}`, '_blank')}
                      ]}
                        items={this.state.escalation ? this.state.escalation.map(flaw => {
                            const deadline = flaw.deadline ? moment(flaw.deadline, "DD.MM.YYYY") : null

                            return ({
                                id: flaw.id,
                                label: flaw.label,
                                branch: <img style={{ height: 48, width: "100%", objectFit: "contain" }} src={scanmetrix.backendURL + "/logo/" + flaw.subsidiary.branch.id} />,
                                location: `${flaw.postalCode} ${flaw.city}`,
                                building: flaw.building,
                                name: flaw.name,
                                subsidiaryId: flaw.subsidiary.id,
                                smid: <><i className="far fa-qrcode" />{flaw.smid}</>,
                                priority: <><div className={`dot ${flaw.priority === 1 ? "green" : (flaw.priority === 2 ? "yellow" : (flaw.priority === 3 ? "red" : "gray"))}`} />{flaw.priority === 1 ? i18next.t("page.private.escalation.low") : (flaw.priority === 2 ? i18next.t("page.private.escalation.medium") : (flaw.priority === 3 ? i18next.t("page.private.escalation.high") : i18next.t("page.private.escalation.unknown")))}</>,
                                deadline: deadline ? <><div className={`dot ${deadline.isSameOrBefore(moment()) ? "red" : "green"}`} />{deadline.fromNow()}</> : null,
                                serviceProvider: flaw.serviceProvider
                            })
                        }) : null}
                    />
                </Flaws>
            </GridElement>
        </Page>
    }
})
