import React, { Component } from "react"
import { Page, Breadcrumbs, Section, GridElement, Button } from "scanmetrix-components"
import ReactFlow, { Handle } from "react-flow-renderer"
import ConnectionLine from "../workflow/ConnectionLine"
import styled from "styled-components"
import { Responsive } from 'react-grid-layout'
import "react-resizable/css/styles.css"
import "react-grid-layout/css/styles.css"
import i18next from "i18next";


import WidthProvider from "./WidthProvider"

const ResponsiveGridLayout = WidthProvider(Responsive);

export default class extends Component {
    state = { workflowExecution: null, finishLoading: false }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    WorkflowExecution(id: $id) {
                        id
                        finishedAt
                        steps {
                            id
                            stepId
                            data
                            updatedAt
                            user {
                                id
                            }
                        }
                        workflow {
                            id
                            name
                            elements
                        }
                    }
                    Workflows {
                        nodes {
                            id
                            name
                        }
                    }
                    Phases {
                        nodes {
                            id
                            name
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.workflowexecutionid
            }
        }).then(data => {
            this.setState({ workflowExecution: data.data.WorkflowExecution, phases: data.data.Phases.nodes, workflows: data.data.Workflows.nodes })
        })
    }

    render() {
        const workflowExecution = this.state.workflowExecution

        if(!workflowExecution) return null

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "project-diagram",
                    title: i18next.t("page.private.workFlowExecution.breadCrumbsWorkflows"),
                    link: "/workflows"
                },
                {
                    title: i18next.t("page.private.workFlowExecution.breadCrumbsExecutions"),
                    icon: "play"
                },
                {
                    title: workflowExecution.workflow.name
                }
            ]} />
            <Section bodyPadding="16px 0 0 0">
                <Section.Item title={i18next.t("page.private.workFlowExecution.WorkflowView.sectionElementTitle")} icon="project-diagram">
                    <GridElement styled title={i18next.t("page.private.workFlowExecution.WorkflowView.gridElementTitle")} icon="project-diagram">
                        <div ref={element => {
                            if(element && !this.state.defaultPosition) this.setState({ defaultPosition: [ element.clientWidth / 2 - 75, element.clientHeight / 2 - 25 ] })
                        }} style={{ height: "768px", overflow: "hidden", width: "100%", backgroundColor: "#20242b", borderRadius: "0 0 5px 5px", position: "relative" }}>
                            <div style={{ position: "absolute", userSelect: "none", userDrag: "none", width: "80%", left: "10%", top: "50%", transform: "translateY(-50%)", filter: "grayscale(1)", opacity: 0.025 }}><img style={{ height: "100%", width: "100%" }} src={`${scanmetrix.backendURL}/logo`} /></div>
                            {this.state.defaultPosition && <FlowClass steps={workflowExecution.steps} defaultPosition={this.state.defaultPosition} elements={workflowExecution.workflow.elements} phases={this.state.phases} workflows={this.state.workflows} />}
                        </div>
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.workFlowExecution.taskBoard.sectionElementTitle")} icon="tasks" amount={workflowExecution.steps.filter(step => !step.data.done && !workflowExecution.finishedAt).length}>
                    <NoteGrid finished={!!workflowExecution.finishedAt} steps={workflowExecution.steps} elements={workflowExecution.workflow.elements} refresh={() => {
                        return this.fetch()
                    }} />
                    {workflowExecution.steps.filter(step => !step.data.done && !workflowExecution.finishedAt).length === 0 && !workflowExecution.finishedAt && <NoNotes>
                        <img src="/notes.svg" />
                        <h2>{i18next.t("page.private.workFlowExecution.taskBoard.note1")}</h2>
                        <h3>{i18next.t("page.private.workFlowExecution.taskBoard.note2")}</h3>
                        <div style={{ display: "inline-grid", gridTemplateColumns: "min-content min-content", gridGap: "16px" }}>
                            <Button loading={this.state.finishLoading} green thick title={i18next.t("page.private.workFlowExecution.taskBoard.workflowCloseButton")} icon="check-double" onClick={() => {
                                this.setState({ finishLoading: true })

                                scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                        mutation($id: ID!) {
                                            finishWorkflowExecution(id: $id)
                                        }   
                                    `,
                                    variables: {
                                        id: workflowExecution.id
                                    }
                                }).then(data => {
                                    if(data.data.finishWorkflowExecution) this.fetch()
                                })
                            }} />
                            <Button disabled secondary thick title={i18next.t("page.private.workFlowExecution.taskBoard.taskCreationButton")} icon="tasks" />
                        </div>
                    </NoNotes>}
                    {workflowExecution.finishedAt && <NoNotes>
                        <img src="/done.svg" />
                        <h2>{i18next.t("page.private.workFlowExecution.taskBoard.note3")}</h2>
                        <h3>{i18next.t("page.private.workFlowExecution.taskBoard.note4")}</h3>
                    </NoNotes>}
                </Section.Item>
                <Section.Item title={i18next.t("page.private.workFlowExecution.chronology.sectionElementTitle")} icon="clock" amount={workflowExecution.steps.filter(step => {
                    const element = workflowExecution.workflow.elements.find(el => el.id === step.stepId)

                    return (!!step.data.done || !!workflowExecution.finishedAt) && element && (element.type === "simple" || element.type === "branch")
                }).length}>
                    {[...new Set(workflowExecution.steps.filter(step => {
                        const element = workflowExecution.workflow.elements.find(el => el.id === step.stepId)

                        return (!!step.data.done || !!workflowExecution.finishedAt) && element && (element.type === "simple" || element.type === "branch")
                    }).sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()).map(step => moment(step.updatedAt).format("DD.MM.YYYY")))].map(date => {
                        return <div style={{ marginBottom: 4 }}>
                            <h3 style={{ fontWeight: 300, marginBottom: 12, userSelect: "none" }}>{moment().format("DD.MM.YYYY") === date ? "Heute" : moment(date, "DD.MM.YYYY").fromNow()} ({date})</h3>
                            <ChronologyNoteGrid finished={!!workflowExecution.finishedAt} steps={workflowExecution.steps.filter(step => moment(step.updatedAt).format("DD.MM.YYYY") === date)} elements={workflowExecution.workflow.elements} />
                        </div>
                    })}
                </Section.Item>
            </Section>
        </Page>
    }
}

const NoNotes = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  
    h2, h3 {
        font-weight: normal;
    }
  
    h2 {
        margin-top: 32px;
        margin-bottom: 8px;
    }
  
    h3 {
        margin-bottom: 16px;
        font-size: 14px;
    }
  
    img {
        max-width: 400px;
        max-height: 300px;
    }
`

const Outcomes = styled.div`
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
`

const Outcome = styled.div`
    background: #9b59b6;
    color: white;
    margin: 0 4px;
    padding: 6px;
    position: relative;
    border-radius: 4px;
  
    &:first-child {
        margin-left: 0;
    }
  
    &:last-child {
        margin-right: 0;
    }
  
    p.title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 0.7em;
    }
`

const BranchNodeComponent = props => {
    const data = props.data

    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            border: "2px solid #9b59b6",
            fontSize: "12px",
            borderRadius: 4,
            position: "relative",
            opacity: props.active ? 1 : 0.35
        }}>
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ fontWeight: "bold", fontSize: "10px" }}>{i18next.t("page.private.workFlowExecution.chronology.nodes.branching")}</div>
            <div><i className="far fa-project-diagram" /> {data.label}</div>
            <Outcomes>
                {data.outcomes.map((outcome, index) => <Outcome key={index}>
                    <p className="title">{outcome.title}</p>
                    <Handle
                        type="source"
                        position="bottom"
                        id={outcome.id}
                        style={{ left: "50%", backgroundColor: "#9b59b6" }}
                    />
                </Outcome>)}
            </Outcomes>
        </div>
    )
}

const StartNodeComponent = props => {
    const data = props.data

    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            borderRadius: 12,
            opacity: props.active ? 1 : 0.35
        }}>
            <Handle type="source" position="bottom" style={{ backgroundColor: "#9b59b6" }} />
            <div style={{ fontWeight: "bold" }}>{data.label}</div>
        </div>
    )
}

const SimpleNodeComponent = props => {
    const data = props.data

    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            border: "2px solid #3b97d3",
            borderRadius: 4,
            opacity: props.active ? 1 : 0.35
        }}>
            <Handle type="source" position="bottom" style={{ backgroundColor: "#9b59b6" }} />
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ fontWeight: "bold", fontSize: "10px" }}>{i18next.t("page.private.workFlowExecution.chronology.nodes.workingSteps")}</div>
            <div>{data.label}</div>
        </div>
    )
}

const PhaseNodeComponent = props => {
    const data = props.data
    const phases = props.phases
    const phase = phases.find(ph => ph.id === data.phaseId)

    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            border: "2px solid #1abc9c",
            borderRadius: 4,
            opacity: props.active ? 1 : 0.35
        }}>
            <Handle type="source" position="bottom" style={{ backgroundColor: "#9b59b6" }} />
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ fontWeight: "bold", fontSize: "10px" }}>{i18next.t("page.private.workFlowExecution.chronology.nodes.phase")}</div>
            <div>{phase ? phase.name : "Unbekannte Phase"}</div>
        </div>
    )
}

const WorkflowNodeComponent = props => {
    const data = props.data
    const workflows = props.workflows
    const workflow = workflows.find(wf => wf.id === data.workflowId)

    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            border: "2px solid #1abc9c",
            borderRadius: 4,
            opacity: props.active ? 1 : 0.35
        }}>
            <Handle type="source" position="bottom" style={{ backgroundColor: "#9b59b6" }} />
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ fontWeight: "bold", fontSize: "10px" }}>{i18next.t("page.private.workFlowExecution.chronology.nodes.workflowTrigger")}</div>
            <div>{workflow ? workflow.name : "Unbekannter Workflow"}</div>
        </div>
    )
}

const EndNodeComponent = props => {
    return (
        <div style={{
            background: '#ffffff',
            color: '#20242b',
            padding: 10,
            fontSize: "12px",
            border: "2px solid #e74c3c",
            borderRadius: 12,
            opacity: props.active ? 1 : 0.35
        }}>
            <Handle type="target" position="top" style={{ backgroundColor: "#3b97d3" }} />
            <div style={{ color: "#e74c3c" }}>{i18next.t("page.private.workFlowExecution.chronology.nodes.workflowEnd")}</div>
        </div>
    )
}

class FlowClass extends Component {
    render() {
        const elements = this.props.elements

        const allX = elements.filter(el => !!el.position).map(pos => pos.position.x).reduce((a, b) => a + b, 0)
        const allY = elements.filter(el => !!el.position).map(pos => pos.position.y).reduce((a, b) => a + b, 0)
        const centerX = allX / elements.filter(el => !!el.position).length
        const centerY = allY / elements.filter(el => !!el.position).length

        const defaultPosition = this.props.defaultPosition

        const steps = this.props.steps

        return <ReactFlow
            nodeTypes={{
                start: props => <StartNodeComponent {...props}  active={!!steps.find(step => step.stepId === props.id && step.data && !step.data.done)} />,
                branch: props => <BranchNodeComponent {...props} active={!!steps.find(step => step.stepId === props.id && step.data && !step.data.done)} />,
                simple: props => <SimpleNodeComponent {...props} active={!!steps.find(step => step.stepId === props.id && step.data && !step.data.done)} />,
                phase: props => <PhaseNodeComponent {...props} phases={this.props.phases} active={!!steps.find(step => step.stepId === props.id && step.data && !step.data.done)} />,
                workflow: props => <WorkflowNodeComponent {...props} workflows={this.props.workflows} active={!!steps.find(step => step.stepId === props.id && step.data && !step.data.done)} />,
                end: props => <EndNodeComponent {...props} active={!!steps.find(step => step.stepId === props.id && step.data && !step.data.done)} />
            }}
            snapToGrid={true}
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={false}
            snapGrid={[16, 16]}
            defaultZoom={1.2}
            defaultPosition={[ defaultPosition[0] - centerX, defaultPosition[1] - centerY ]}
            elements={elements.map(el => ({ ...el, animated: true, draggable: false, connectable: false, selectable: false }))}
            edgeTypes={{ f: ConnectionLine }}
        >
        </ReactFlow>
    }
}

const StyledNote = styled.div`
    background: white;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    position: relative;
  
    .status {
        position: absolute;
        left: 0;
        bottom: 0;
        background: #3b97d3;
        color: white;
        display: flex;
        align-items: center;
        padding: 4px 8px;
        border-radius: 0 4px 0 0 ;
        font-size: 12px;
      
        &.simple {
          background: #3b97d3;
        }

        &.branch {
          background: #9b59b6;
        }

        i {
            margin-right: 8px;
        }
    }
  
    .contentContainer {
        width: 100%;
        height: 100%;
        padding-right: 16px;
        padding-bottom: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
      
        .contentTitle {
            font-size: 18px;
            margin-bottom: 16px;
            user-select: none;
            cursor: grab;
        }
      
        .finish {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            height: 100%;
          
            .outcomes {
                display: flex;
                flex-wrap: wrap;
              
                .outcome {
                    background: linear-gradient(30deg,#0f7abf,#58b9f9);
                    color: white;
                    border-radius: 4px;
                    padding: 6px 12px;
                    margin: 4px;
                    user-select: none;
                    cursor: pointer;
                    transition: opacity 0.3s;
                  
                    &:hover {
                        opacity: 0.75;
                    }
                }
            }
          
            p.descriptionFinish {
                font-size: 0.9em;
                line-height: 1.4em;
                margin-bottom: 16px;
            }
            
            .finishButton {
                user-select: none;
                background: linear-gradient(30deg,#16a085,#1abc9c);
                white-space: nowrap;
                color: white;
                padding: 8px 16px;
                cursor: pointer;
                border-radius: 4px;
                margin-top: 16px;
                transition: opacity 0.3s;
              
                &:hover {
                    opacity: 0.75;
                }
            }
        }
      
        textarea {
            outline: 0;
            width: 100%;
            font-family: inherit;
            font-size: inherit;
            flex: 1;
            border: none;
            box-sizing: border-box;
            resize: none;
            line-height: 24px;
          
            &::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0.1);
                width: 3px;
                border-radius: 3px;
            }
          
            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.2);
                width: 3px;
                border-radius: 3px;
            }
        }
    }
`

const Sidebar = styled.div`
    width: 48px;
    flex-shrink: 0;
    flex-grow: 0;
    height: 100%;
    border-left: 1px dashed rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    
    .buttonCont {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        transition: background 0.3s;
        cursor: pointer;
        margin-top: 8px;
      
        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
      
        &:first-child {
            margin-top: 0;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }
      
        &.selected {
            background: linear-gradient(30deg,#0f7abf,#58b9f9);
            box-shadow: 0 0 6px 0 rgb(26 105 156 / 40%);
            color: white;
          
            &.finish {
                background: linear-gradient(30deg,#16a085,#1abc9c);
                box-shadow: 0 0 6px 0 rgb(26 156 84 / 40%);
            }
        }
    }
`

const StyledTasks = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 1px;
    padding-right: 8px;
    padding-bottom: 8px;
    user-select: none;
    
    .create {
        display: flex;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 16px;
        flex-wrap: wrap;
        overflow: visible;
      
        input {
            width: 100%;
            height: 30px;
            font-family: inherit;
            outline: 0;
            border: 1px solid rgba(0, 0, 0, 0.25);
            padding-left: 8px;
            border-radius: 3px;
            flex: 1;
            min-width: 150px;
            box-sizing: border-box;
            margin-bottom: 8px;
        }
      
        .button {
            vertical-align: top;
            box-sizing: border-box;
            height: 30px;
            flex-shrink: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            background: linear-gradient(30deg,#0f7abf,#58b9f9);
            box-shadow: 0 0 6px 0 #1a699c66;
            margin-left: 8px;
            border-radius: 3px;
            white-space: nowrap;
            padding: 6px 12px;
            transition: opacity 0.3s;
            color: white;
            font-size: 0.9em;
          
            &:hover {
                opacity: 0.75;
            }
        }
    }
  
    .task {
        display: flex;
        cursor: pointer;
        align-items: center;
        margin-bottom: 8px;
        transition: opacity 0.3s;
        justify-content: space-between;
      
        &:last-child {
            margin-bottom: 0;
        }
      
        &:hover {
            opacity: 0.5;
        }
      
        .checkbox {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            border: 1px solid black;
            
            i {
                visibility: hidden;
                transition: opacity 0.3s, visibility 0.3s;
                opacity: 0;
            }
        }
      
        .left {
            display: flex;
            align-items: center;
        }
      
        .trash {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s, background 0.3s, color 0.3s;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            font-size: 0.9em;
          
            &:hover {
                background: #20242b;
                color: white;
            }
        }
      
        &:hover {
            .trash {
                opacity: 1;
                visibility: visible;
            }
        }
      
        &.done {
            opacity: 0.75;
          
            .title {
                text-decoration: line-through;
            }
          
            .checkbox {
                i {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
`

class Tasks extends Component {
    state = { tasks: [], title: null }

    constructor(props) {
        super(props)

        this.state.tasks = props.tasks
    }

    render() {
        return <StyledTasks>
            {!this.props.chronology && <div className="create">
                <input placeholder={i18next.t("page.private.workFlowExecution.chronology.subtask")} value={this.state.title || ""} onChange={e => this.setState({ title: e.target.value })} />
                <div className="button" onClick={() => {
                    if(this.state.title && this.state.title.trim()) {
                        let tasks = this.state.tasks
                        tasks.push({ title: this.state.title, done: false })
                        this.setState({ tasks, title: null }, () => this.props.onChange(tasks))
                    }
                }}><i className="tasks" /> {i18next.t("page.private.workFlowExecution.chronology.subtaskCreation")}</div>
            </div>}
            {this.state.tasks.map((task, index) => <div className={`task${task.done ? " done" : ""}`} key={index} onClick={() => {
                if(this.props.chronology) return false

                let tasks = this.state.tasks
                task.done = !task.done
                tasks[index] = task
                this.setState({ tasks }, () => this.props.onChange(tasks))
            }}>
                <div className="left">
                    <div className="checkbox"><i className="far fa-check" /></div>
                    <div className="title">{task.title}</div>
                </div>
                {!this.props.chronology && <div className="trash" onClick={e => {
                    e.stopPropagation()
                    let tasks = this.state.tasks.filter((_, ind) => index !== ind)

                    this.setState({ tasks }, () => this.props.onChange(tasks))
                }}><i className="far fa-trash" /></div>}
            </div>)}
        </StyledTasks>
    }
}

class Note extends Component {
    state = { perspective: "notes", saving: false, data: null }

    constructor(props) {
        super(props)

        this.state.data = this.props.data || null

        this.save = this.save.bind(this)
    }

    save() {
        if(this.saveTimer) clearTimeout(this.saveTimer)

        this.saveTimer = setTimeout(() => {
            this.props.save(this.state.data)
            this.saveTimer = null
        }, 400)
    }

    render() {
        //const data = this.props.data
        const type = this.props.type

        return <StyledNote>
            <div className="contentContainer">
                <p className="contentTitle dragHandle">{this.props.title}</p>
                {this.state.perspective === "notes" && <textarea readOnly={!!this.props.chronology} onChange={e => {
                    this.setState({ data: { ...this.state.data, notes: e.target.value } }, this.save)
                }} spellCheck="false" autoCorrect="false" placeholder={i18next.t("page.private.workFlowExecution.chronology.searchPlaceholderNotes")} value={this.state.data.notes || ""} />}
                {this.state.perspective === "tasks" && <Tasks tasks={this.state.data.tasks || []} onChange={tasks => this.setState({ data: { ...this.state.data, tasks } }, this.save)} chronology={this.props.chronology} />}
                {this.state.perspective === "finish" && type === "simple" && <div className="finish">
                    <p className="descriptionFinish">{i18next.t("page.private.workFlowExecution.chronology.text1")}</p>
                    <Button loading={this.state.saving} onClick={() => {
                        this.setState({ saving: true })
                        this.props.save({ done: true }).then(() => this.setState({ saving: false }))
                    }} icon="check-double" title={i18next.t("page.private.workFlowExecution.chronology.taskCloseButton")} green thick />
                </div>}
                {this.state.perspective === "finish" && type === "branch" && <div className="finish">
                    <p className="descriptionFinish">{i18next.t("page.private.workFlowExecution.chronology.text2")}</p>
                    <div className="outcomes">
                        {(this.props.stepData.data.outcomes || []).map((outcome, index) => <div className="outcome" key={index} onClick={() => {
                            this.setState({ saving: true })
                            this.props.save({ done: true, outcome: outcome.id }).then(() => this.setState({ saving: false }))
                        }}>
                            {outcome.title}
                        </div>)}
                    </div>
                </div>}
            </div>
            <Sidebar>
                <div className={`buttonCont ${this.state.perspective === "notes" ? "selected" : ""}`} onClick={() => this.setState({ perspective: "notes" })}><i className="far fa-sticky-note" /></div>
                <div className={`buttonCont ${this.state.perspective === "tasks" ? "selected" : ""}`} onClick={() => this.setState({ perspective: "tasks" })}><i className="far fa-tasks" /></div>
                <div className={`buttonCont disabled ${this.state.perspective === "files" ? "selected" : ""}`} onClick={() => {
                    //this.setState({ perspective: "files" })
                }}><i className="far fa-folder-tree" /></div>
                <div className={`buttonCont disabled ${this.state.perspective === "user" ? "selected" : ""}`} onClick={() => {
                    //this.setState({ perspective: "user" })
                }}><i className="far fa-user" /></div>
                {!this.props.chronology && <div className={`buttonCont finish ${this.state.perspective === "finish" ? "selected" : ""}`} onClick={() => this.setState({ perspective: "finish" })}><i className="far fa-check" /></div>}
            </Sidebar>
            {type === "simple" && <div className="status simple">
                <i className="far fa-check" />
                {i18next.t("page.private.workFlowExecution.chronology.workingSteps")}
            </div>}
            {type === "branch" && <div className="status branch">
                <i className="far fa-code-branch" />
                {i18next.t("page.private.workFlowExecution.chronology.branching")}
            </div>}
        </StyledNote>
    }
}

const NoteGridContainer = styled.div`
    transform: translate(-16px, -16px);

    .react-grid-item.react-grid-placeholder {
        background: black !important;
        opacity: 0.1 !important;
        border-radius: 5px !important;
    }
`

class NoteGrid extends Component {
    render() {
        return <NoteGridContainer>
            <ResponsiveGridLayout onLayoutChange={data => {
                let changed = []

                data.forEach(note => {
                    let step = this.props.steps.filter(step => !step.data.done).find(step => String(step.id) === String(note.i))

                    if(step) {
                        if(step.data.x !== note.x || step.data.y !== note.y || step.data.w !== note.w || step.data.h !== note.h) {
                            step.data.x = note.x
                            step.data.y = note.y
                            step.data.w = note.w
                            step.data.h = note.h

                            changed.push(step)
                        }
                    }
                })

                if(changed.length) {
                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($steps: [JSON]!) {
                            updateWorkflowExecutionSteps(steps: $steps)
                        }   
                    `,
                        variables: {
                            steps: changed.map(change => ({ id: change.id, data: change.data }))
                        }
                    })
                }
            }} measureBeforeMount={true} draggableHandle=".dragHandle" margin={[16, 16]} className="layout" layout={[]} cols={{ lg: 4, md: 4, sm: 2, xs: 1, xxs: 1 }} rowHeight={300}>
                {this.props.steps.filter(step => !step.data.done && !this.props.finished).map(step => {
                    const stepData = this.props.elements.find(el => el.id === step.stepId)

                    return <div key={step.id} data-grid={{ x: step.data.x, y: step.data.y, w: step.data.w, h: step.data.h}}>
                        <Note title={stepData.data.label} data={step.data} type={stepData.type} stepData={stepData} save={data => {
                            let currentData = step.data
                            let newData = Object.assign(currentData, data)

                            return new Promise(resolve => {
                                return scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                        mutation($steps: [JSON]!) {
                                            updateWorkflowExecutionSteps(steps: $steps)
                                        }   
                                    `,
                                    variables: {
                                        steps: [
                                            { id: step.id, data: newData }
                                        ]
                                    }
                                }).then(data => {
                                    if(data.data.updateWorkflowExecutionSteps) this.props.refresh().then(resolve)
                                })
                            })
                        }} />
                    </div>
                })}
            </ResponsiveGridLayout>
        </NoteGridContainer>
    }
}


class ChronologyNoteGrid extends Component {
    render() {
        return <NoteGridContainer>
            <ResponsiveGridLayout isDraggable={false} isResizable={false} measureBeforeMount={true} draggableHandle=".dragHandle" margin={[16, 16]} className="layout" layout={[]} cols={{ lg: 4, md: 4, sm: 2, xs: 1, xxs: 1 }} rowHeight={300}>
                {this.props.steps.filter(step => {
                    const element = this.props.elements.find(el => el.id === step.stepId)

                    return (!!step.data.done || this.props.finished) && element && (element.type === "simple" || element.type === "branch")
                }).map((step, index) => {
                    const stepData = this.props.elements.find(el => el.id === step.stepId)

                    return <div key={step.id} data-grid={{ x: index % 4, y: Math.floor(index / 4), w: 1, h: 1}}>
                        <Note title={stepData.data.label} data={step.data} type={stepData.type} stepData={stepData} save={() => {}} chronology />
                    </div>
                })}
            </ResponsiveGridLayout>
        </NoteGridContainer>
    }
}
