import React, { Component } from "react"
import { Modal, TextEditor } from "scanmetrix-components"
import moment from "moment"
import i18next from "i18next";

export default class extends Component {
    state = { text: null }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={measureApproval => this.setState({ ...measureApproval })}>
            <Modal.Head title={i18next.t("page.private.measure.showMeasureApprovalModal.title")} icon="check-to-slot" />
            <Modal.Body padding={24}>
                <div style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
                    <TextEditor
                        readOnly
                        label={i18next.t("page.private.measure.showMeasureApprovalModal.Receiver")}
                        icon="font"
                        placeholder={i18next.t("page.private.measure.showMeasureApprovalModal.searchPlaceholderGreetings")}
                        value={this.state.text}
                        height={400}
                    />
                </div>
            </Modal.Body>
        </Modal>
    }
}
