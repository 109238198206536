import React, { Component } from "react"
import { Page, Section, Breadcrumbs, Table, GridElement, Button, Grid } from "scanmetrix-components"
import styled from "styled-components"
import i18next from "i18next"

import CreateSubsidiaryModal from "./modals/CreateSubsidiaryModal"
import CreateCustodianModal from "./modals/CreateCustodianModal"
import ReactApexChart from "react-apexcharts"
import subsidiary, { UtilitiesStatement } from "../subsidiary"
import MomentLocaleUtils from "react-day-picker/moment"
import SubsidiaryWorkSections from "./tabs/SubsidiaryWorkSections";

const Status = styled.div`
  position: absolute;
  left: -16px;
  width: 32px;
  height: 32px;
  background: ${props => props.yellow ? "#e67e22" : (!!props.status ? "#27ae60" : (props.status === false ? "#c0392b" : "#2c3e50"))};
  border-radius: 32px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 16px);
  font-size: 0.9em;
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);
  
  i {
      margin: 0 !important;
  }
`

export default class extends Component {
    state = { statuses: [], phases: [] }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()

        this.evaluate = this.evaluate.bind(this)
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Phases {
                        nodes {
                            id
                            name
                        }
                    }
                    Custodians {
                        nodes {
                            id
                            name
                            type
                            address {
                                inline
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({
                custodians: data.data.Custodians.nodes,
                phases: data.data.Phases.nodes
            })
        })

        fetch(scanmetrix.backendURL + "/subsidiary-statuses", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ statuses: data.statuses })
        })
    }

    evaluate = subsidiary => {
        if(this.state.statuses) {
            const status = this.state.statuses.find(status => String(status.subsidiaryId) === String(subsidiary.id))

            if(status) {
                return status.status
            }
        }

        return "gray"
    }

    render() {
        return <Page {...this.props}>
            <CreateSubsidiaryModal refresh={() => this.fetchSubsidiaries()} instance={ref => this.createSubsidiaryModal = ref} />
            <CreateCustodianModal refresh={() => this.fetch()} instance={ref => this.createCustodianModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "building",
                    title: i18next.t("page.private.facilityManagement.title")
                }
            ]} />
            <Section bodyPadding="32px 0 0 0" initialSelected={this.props.location.state ? (this.props.location.state.tab || 0) : 0}>
                <Section.Item title={i18next.t("page.private.facilityManagement.branches.breadCrumbsTitle")} icon="map-marker" amount={this.state.subsidiaryAmount || 0}>
                    <GridElement styled title={i18next.t("page.private.facilityManagement.branches.gridElementTitle")} icon="map-marker" rightContent={<Button thick title={i18next.t("page.private.facilityManagement.branches.button")} disabled={scanmetrix.checkPermission("Subsidiary") < 2} icon="map-marker" onClick={() => this.createSubsidiaryModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.facilityManagement.branches.table.name"), width: "15%", column: "name", orderField: "name" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.label"), width: "10%", column: "label", orderField: "label" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.legalName"), width: "17.5%", column: "legalName", orderField: "legal_name" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.street"), width: "20%", column: "street", orderField: "address_street_name" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.postalCode"), width: "7.5%", column: "postalCode", orderField: "address_postal_code" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.city"), width: "10%", column: "city", orderField: "address_city" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.type"), width: "10%", column: "type", orderField: "subsidiaryType.name" },
                                { title: i18next.t("page.private.facilityManagement.branches.table.phase"), width: "10%", column: "phase", orderField: "phase.name" }
                            ]}
                            refresh={refresh => this.fetchSubsidiaries = refresh}
                            query={`${scanmetrix.nestURL}/v2/subsidiary`}
                            defaultSort="created_at_DESC"
                            onItemClick={subsidiary => this.props.history.push(`/subsidiary/${subsidiary.id}`)}
                            metaCallback={meta => this.setState({ subsidiaryAmount: meta.total })}
                            map={subsidiaries => subsidiaries.map(subsidiary => {
                                const evaluation = this.evaluate(subsidiary)

                                return ({
                                    id: subsidiary.id,
                                    label: subsidiary.label,
                                    rawName: subsidiary.name,
                                    name: <div style={{ paddingLeft: "16px" }}><Status yellow={evaluation === "orange"} status={evaluation === "gray" ? null : (evaluation === "red" ? false : true)}><i className={`far fa-${evaluation === "gray" ? "clock" : (evaluation === "green" ? "check-double" : "bolt")}`} /></Status>{subsidiary.name}</div>,
                                    legalName: subsidiary.legal_name,
                                    street: `${subsidiary.address_street_name} ${subsidiary.address_street_number}`,
                                    postalCode: subsidiary.address_postal_code,
                                    city: subsidiary.address_city,
                                    type: subsidiary.subsidiaryType ? <b style={{ color: subsidiary.subsidiaryType.color }}>{subsidiary.subsidiaryType.name}</b> : null,
                                    phase: subsidiary.phase ? <b style={{ color: subsidiary.phase.color }}>{subsidiary.phase.name}</b> : null,
                                    phaseId: subsidiary.phase ? subsidiary.phase.id : null
                                })
                            })}
                            contextItems={[ 
                                { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: subsidiary => window.open(`/subsidiary/${subsidiary.id}`, '_blank')}
                            ]}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.facilityManagement.landlord.breadCrumbsTitle")} icon="house-user" amount={this.state.custodians ? this.state.custodians.length: 0}>
                    <GridElement styled title={i18next.t("page.private.facilityManagement.landlord.gridElementTitle")} icon="house-user" rightContent={<Button thick title={i18next.t("page.private.facilityManagement.landlord.button")} icon="house-user" onClick={() => this.createCustodianModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.facilityManagement.landlord.table.type"), width: "12.5%", column: "type" },
                                { title: i18next.t("page.private.facilityManagement.landlord.table.name"), width: "17.5%", column: "name" },
                                { title: i18next.t("page.private.facilityManagement.landlord.table.address"), width: "70%", column: "address" }
                            ]}
                            onItemClick={custodian => this.props.history.push(`/custodian/${custodian.id}`)}
                            items={this.state.custodians ? this.state.custodians.map(custodian => {
                                return ({
                                    id: custodian.id,
                                    name: custodian.name,
                                    type: custodian.type === "landlord" ? <div style={{ display: "flex", alignItems: "center" }}><i className="far fa-user-tie" />{i18next.t("page.private.facilityManagement.landlordBottom")}</div> : <div style={{ display: "flex", alignItems: "center" }}><i className="far fa-cog" />{i18next.t("page.private.facilityManagement.administrativeBottom")}</div>,
                                    address: custodian.address.inline
                                })
                            }) : null}
                            contextItems={[ 
                                { title: "Link in neuem Tab öffnen", icon: "fa-light fa-share-from-square", onClick: custodian => window.open(`/custodian/${custodian.id}`, '_blank')}
                            ]}
                        />
                    </GridElement>
                </Section.Item>
                <SubsidiaryWorkSections />
                {/*<Section.Item title="NK-Ersparnis Auswertung" icon="piggy-bank">

                </Section.Item>*/}
            </Section>
        </Page>
    }
}
