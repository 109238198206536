import styled from "styled-components";

export default styled.div`
  width: 100%;
  height: calc(100% - 64px);
  margin-top: 32px;
  background: #11141a;
  background-size: cover;
  box-sizing: border-box;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border-radius: 10px 0 0 10px;
  
  .footer .version {
      width: 100%;
      text-align: center;
      color: white;
      margin-top: 8px;
      opacity: 0.75;
      user-select: none;
      font-size: 0.7em;
  }
  
  img {
    height: 40px;
    margin-bottom: 16px;
    user-select: none;
    -webkit-user-drag: none;
  }
  
  h1 {
    color: white;
    opacity: 0.75;
    font-weight: 300;
    font-size: 1.3em;
    user-select: none;
  }
  
  ul {
      display: flex;
      user-select: none;
      margin-top: 16px;
      justify-content: center;
      
      li {
          list-style-type: none;
          color: white;
          margin-right: 16px;
          display: flex;
          font-size: 0.75em;
          
          &::after {
            content: "|";
            display: block;
            margin-left: 16px;
            opacity: 0.5;
          }
          
          &:last-child {
            margin-right: 0;
            
            &::after {
              display: none;
            }
          }
          
          a {
              cursor: pointer;
              transition: opacity 0.3s;
              opacity: 0.5;
              color: white;
              text-decoration: none;

              &:hover {
                opacity: 1;
              }
          }
      }
  }
`
