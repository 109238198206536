import React, {Component} from "react"
import { GridElement, Table, Button } from "scanmetrix-components"
import i18next from "i18next"
import CreateTicketTypeModal from "./modals/CreateTicketTypeModal";
import UpdateTicketTypeModal from "./modals/UpdateTicketTypeModal";
import DeleteTicketTypeModal from "./modals/DeleteTicketTypeModal";

export default class extends Component {
    render() {
        return <>
            <CreateTicketTypeModal instance={ref => this.createTicketTypeModal = ref} refresh={() => this.props.fetch()} />
            <UpdateTicketTypeModal instance={ref => this.updateTicketTypeModal = ref} refresh={() => this.props.fetch()} />
            <DeleteTicketTypeModal instance={ref => this.deleteTicketTypeModal = ref} refresh={() => this.props.fetch()} />
            <GridElement styled title={i18next.t("page.private.settings.TicketTypes.gridElementTitle")} icon="ticket" rightContent={<Button thick title={i18next.t("page.private.settings.TicketTypes.button")} icon="ticket" onClick={() => this.createTicketTypeModal.open()} />}>
                <Table
                    head={[
                        { title: i18next.t("page.private.settings.TicketTypes.table.icon"), width: "15%", column: "icon" },
                        { title: i18next.t("page.private.settings.TicketTypes.table.name"), width: "65%", column: "name" },
                        { title: i18next.t("page.private.settings.TicketTypes.table.color"), width: "10%", column: "color" },
                        { title: i18next.t("page.private.settings.TicketTypes.table.active"), width: "10%", column: "active" }
                    ]}
                    onItemClick={ticketType => this.updateTicketTypeModal.open(ticketType)}
                    contextItems={[
                        {
                            icon: "trash",
                            title: i18next.t("page.private.settings.TicketTypes.deleteButton"),
                            onClick: data => {
                                this.deleteTicketTypeModal.open(data.id)
                            }
                        }
                    ]}
                    items={this.props.data ? this.props.data.map(ticketType => {
                        return ({
                            raw: ticketType,
                            id: ticketType.id,
                            name: ticketType.name,
                            icon: <div><i style={{ marginRight: 8 }} className={`fa-duotone fa-${ticketType.icon}`} /> {ticketType.icon}</div>,
                            active: ticketType.active ? <><i className="fas fa-check-circle green" /> Ja</> : <><i className="fas fa-times-circle red" /> Nein</>,
                            color: <div style={{ float: "left", width: "fit-content", backgroundColor: ticketType.color, color: "white", padding: 6, borderRadius: 3, boxShadow: `0 3px 4px -3px ${ticketType.color}` }}>{ticketType.color}</div>
                        })
                    }) : null}
                />
            </GridElement>
        </>
    }
}
