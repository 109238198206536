import React, { Component } from "react"
import { ReactSpreadsheetImport } from "react-spreadsheet-import"
import { Button, Modal } from "scanmetrix-components"

export default class extends Component {
    constructor(props) {
        super(props)

        this.open = this.open.bind(this)
    }

    state = {
        open: false
    }

    open() {
        this.setState({ open: true })
    }

    componentDidMount() {
        if(this.props.onMount) this.props.onMount(this)
    }

    render() {
        return <>
            <ImportModal onSubmit={this.props.onSubmit || (async () => {})} modal={ref => this.importModal = ref} />
            {this.state.open && <ReactSpreadsheetImport
                fields={this.props.fields || []}
                isOpen
                onClose={() => this.setState({ open: false })}
                allowInvalidSubmit={true}
                maxRecords={2048}
                isNavigationEnabled={true}
                customTheme={{
                    colors: {
                        background: 'white',
                        rsi: {
                            50: "#e6f2f9",
                            100: "#c1e0f1",
                            200: "#3b97d3",
                            300: "#3b97d3",
                            400: "#3b97d3",
                            500: "#3b97d3",
                            600: "#20242b",
                            700: "#3b97d3",
                            800: "#3b97d3",
                            900: "#3b97d3",
                        }
                    }
                }}
                translations={{
                    uploadStep: {
                        title: "Datei hochladen",
                        manifestTitle: "Daten, die wir erwarten:",
                        manifestDescription: "(Du kannst Spalten im nächsten Schritt umbenennen oder entfernen)",
                        maxRecordsExceeded: (maxRecords) => `Zu viele Zeilen. Es können maximal ${maxRecords} Zeilen importiert werden.`,
                        dropzone: {
                            title: "Datei im Format .xlsx, .xls oder .csv hochladen",
                            errorToastDescription: "Hochladen fehlgeschlagen!",
                            activeDropzoneTitle: "Datei hier ablegen...",
                            buttonTitle: "Datei auswählen",
                            loadingTitle: "Verarbeiten..."
                        },
                        selectSheet: {
                            title: "Arbeitsblatt für den Import auswählen",
                            nextButtonTitle: "Weiter",
                            backButtonTitle: "Zurück"
                        }
                    },
                    selectHeaderStep: {
                        title: "Kopfzeile auswählen",
                        nextButtonTitle: "Weiter",
                        backButtonTitle: "Zurück"
                    },
                    matchColumnsStep: {
                        title: "Spalten zuordnen",
                        nextButtonTitle: "Weiter",
                        backButtonTitle: "Zurück",
                        userTableTitle: "Deine Tabellespalte...",
                        templateTitle: "...wird zu...",
                        selectPlaceholder: "Spalte auswählen...",
                        ignoredColumnText: "Spalte ignoriert",
                        subSelectPlaceholder: "Auswählen...",
                        matchDropdownTitle: "Zuordnen",
                        unmatched: "Unzugeordnet",
                        duplicateColumnWarningTitle: "Eine andere Spalte wurde unzugeordnet",
                        duplicateColumnWarningDescription: "Spalten können nicht doppelt sein"
                    },
                    validationStep: {
                        title: "Daten prüfen",
                        nextButtonTitle: "Bestätigen",
                        backButtonTitle: "Zurück",
                        noRowsMessage: "Keine Daten gefunden",
                        noRowsMessageWhenFiltered: "Keine fehlerhaften Daten gefunden",
                        discardButtonTitle: "Fehlerhafte Daten entfernen",
                        filterSwitchTitle: "Nur Zeilen mit fehlerhaften Daten anzeigen"
                    },
                    alerts: {
                        confirmClose: {
                            headerTitle: "Import beenden",
                            bodyText: "Bist du sicher? Deine aktuelle Auswahl wird nicht gespeichert.",
                            cancelButtonTitle: "Abbrechen",
                            exitButtonTitle: "Import beenden"
                        },
                        submitIncomplete: {
                            headerTitle: "Fehler erkannt!",
                            bodyText: "Es gibt noch Zeilen mit fehlerhaften Daten. Fehlerhafte Zeilen werden beim Import nicht berücksichtigt.",
                            bodyTextSubmitForbidden: "Es gibt noch fehlerhafte Zeilen.",
                            cancelButtonTitle: "Abbrechen",
                            finishButtonTitle: "Abschließen"
                        },
                        submitError: {
                            title: "Fehler",
                            defaultMessage: "Beim Abschließen der Daten ist ein Fehler aufgetreten."
                        },
                        unmatchedRequiredFields: {
                            headerTitle: "Nicht alle Spalten wurden zugeordnet",
                            bodyText: "Es gibt Spalten, die nicht zugeordnet oder ignoriert wurden. Trotzdem fortfahren?",
                            listTitle: "Unzugeordnete Spalten:",
                            cancelButtonTitle: "Abbrechen",
                            continueButtonTitle: "Fortfahren"
                        },
                        toast: {
                            error: "Fehler"
                        }
                    }
                }}
                onSubmit={data => {
                    this.importModal.open(data.validData)
                }}
            />}
        </>
    }
}

class ImportModal extends Component {
    state = { loading: false, data: [] }

    render = () => <Modal
        initialize={data => {
            this.setState({
                data,
                loading: false
            })
        }}
        instance={modal => this.props.modal(modal)}>
        <Modal.Head title="Datenimport abschließen" icon="file-import" />
        <Modal.Body padding={24}>
            Es werden insgesamt <b style={{ color: "#3b97d3" }}>{this.state.data.length} Datensätze</b> importiert. Möchtest du fortfahren?
            <br /><br />(Wichtig: Bitte schließe während des Imports diese Seite nicht!)
        </Modal.Body>
        <Modal.Footer buttons={instance => [<Button thick loading={this.state.loading} onClick={() => {
            this.setState({ loading: true })

            this.props.onSubmit(this.state.data).then(() => {
                instance.close()
            })
        }} icon="file-import" title="Importieren" primary />,
            <Button thick secondary clickEvent={instance.close} title="Abbrechen" />]} />
    </Modal>
}
