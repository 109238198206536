import React, { Component } from "react"

import { getIntervalType } from "../../object"
import { Modal, Button, Select, Input, Grid, GridElement, Checkbox } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { subsidiaryId: null, subsidiaries: [], name: null, contractProvisionIntervalId: null, date: null, priority: null, continueInterval: true, continueFromDate: true , tracing: false, intervals: [], loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ name: null, subsidiaries: [], subsidiaryId: null, contractProvisionIntervalId: null, date: moment().format("DD.MM.YYYY"), priority: "low", continueInterval: true, continueFromDate: true, tracing: false, intervals: [], loading: false })

            scanmetrix.client.query({
                query: scanmetrix.gql`
                    query($contractId: ID!) {
                        ContractProvisionIntervals(filter: { contractId_eq: $contractId }) {
                            nodes {
                                id
                                name
                                intervalAmount
                                intervalType
                                contract {
                                    id
                                    title
                                }
                            }
                        }
                        SubsidiaryContracts(filter: { contractId_eq: $contractId }) {
                            nodes {
                                id
                                subsidiary {
                                    id
                                    name
                                    label
                                }
                            }
                        }
                    }  
                `,
                variables: {
                    contractId: this.props.contractId
                }
            }).then(data => {
                this.setState({ intervals: data.data.ContractProvisionIntervals.nodes, subsidiaries: data.data.SubsidiaryContracts.nodes.map(s => s.subsidiary) })
            })
        }}>
            <Modal.Head title={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.title")} icon="file-certificate" />
            <Modal.Body padding={24}>
                <Grid gap="16px" columns={[ "1fr", "1fr" ]}>
                    <GridElement columnStart={1} columnEnd={3}>
                        <Select value={this.state.subsidiaryId} onChange={subsidiaryId => this.setState({ subsidiaryId })} icon="map-marker" label={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.branch")} adjustWidth items={this.state.subsidiaries.map(subsidiary => ({ key: subsidiary.id, title: `${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""}` }))} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={2}>
                        <Select required value={this.state.contractProvisionIntervalId} onChange={(contractProvisionIntervalId, raw) => {
                            if(this.state.name || !raw) {
                                this.setState({ contractProvisionIntervalId })
                            } else {
                                this.setState({ contractProvisionIntervalId, name: `${raw.raw.name} / ${raw.raw.contract.title}` })
                            }
                        }} icon="hourglass" label={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.performanceCycle")} adjustWidth items={this.state.intervals.map(interval => ({
                            key: interval.id,
                            title: `${interval.name} / ${interval.intervalAmount} ${getIntervalType(interval.intervalType)}`,
                            raw: interval
                        }))} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Input required date value={this.state.date} onChange={date => this.setState({ date })} icon="calendar-day" label={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.performanceDate")} adjustWidth />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={3}>
                        <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="tag" label={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.performanceName")} adjustWidth />
                    </GridElement>
                    {this.state.tracing && <GridElement columnStart={1} columnEnd={3}>
                        <Select adjustWidth icon="exclamation" label={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.priority")} value={this.state.priority} noUnselect onChange={priority => this.setState({ priority })} items={[
                            { key: "low", title: i18next.t("page.private.contract.modals.createContractProvisionProoflModal.priorityTypes.low") },
                            { key: "medium", title: i18next.t("page.private.contract.modals.createContractProvisionProoflModal.priorityTypes.medium") },
                            { key: "high", title: i18next.t("page.private.contract.modals.createContractProvisionProoflModal.priorityTypes.high") }
                        ]} />
                    </GridElement>}
                    <GridElement columnStart={1} columnEnd={2}>
                        <Checkbox label={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.intervalContinue")} value={this.state.continueInterval} onChange={continueInterval => this.setState({ continueInterval })} />
                    </GridElement>
                    <GridElement columnStart={2} columnEnd={3}>
                        <Checkbox label={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.serviceDateInterval")} value={this.state.continueFromDate} onChange={continueFromDate => this.setState({ continueFromDate })} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={3}>
                        <Checkbox label={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.defectEvent")} value={this.state.tracing} onChange={tracing => this.setState({ tracing })} />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => [<Button loading={this.state.loading} thick title={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.exitButton")} disabled={!this.state.name || !this.state.contractProvisionIntervalId || !this.state.date} icon="file-certificate" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($subsidiaryId: ID, $contractProvisionIntervalId: ID!, $priority: String!, $name: String!, $date: DateTime!, $continueInterval: Boolean!, $continueFromDate: Boolean!, $tracing: Boolean!) {
                            createContractProvisionProof(subsidiaryId: $subsidiaryId, priority: $priority, contractProvisionIntervalId: $contractProvisionIntervalId, name: $name, date: $date, continueInterval: $continueInterval, continueFromDate: $continueFromDate, tracing: $tracing)
                        }
                    `,
                    variables: {
                        contractProvisionIntervalId: this.state.contractProvisionIntervalId,
                        name: this.state.name,
                        date: moment(this.state.date, "DD.MM.YYYY").toDate(),
                        continueInterval: this.state.continueInterval,
                        continueFromDate: this.state.continueFromDate,
                        tracing: this.state.tracing,
                        priority: this.state.priority,
                        subsidiaryId: this.state.subsidiaryId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createContractProvisionProof) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />, <Button loading={this.state.loading} thick title={i18next.t("page.private.contract.modals.createContractProvisionProoflModal.exitButton1")} disabled={!this.state.name || !this.state.contractProvisionIntervalId || !this.state.date} icon="file-certificate" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                        mutation($subsidiaryId: ID, $contractProvisionIntervalId: ID!, $priority: String!, $name: String!, $date: DateTime!, $continueInterval: Boolean!, $continueFromDate: Boolean!, $tracing: Boolean!) {
                            createContractProvisionProof(subsidiaryId: $subsidiaryId, priority: $priority, contractProvisionIntervalId: $contractProvisionIntervalId, name: $name, date: $date, continueInterval: $continueInterval, continueFromDate: $continueFromDate, tracing: $tracing)
                        }
                    `,
                variables: {
                contractProvisionIntervalId: this.state.contractProvisionIntervalId,
                name: this.state.name,
                date: moment(this.state.date, "DD.MM.YYYY").toDate(),
                continueInterval: this.state.continueInterval,
                continueFromDate: this.state.continueFromDate,
                tracing: this.state.tracing,
                priority: this.state.priority,
                subsidiaryId: this.state.subsidiaryId
            }
            }).then(result => {
                this.setState({ loading: false })

                if(result.data.createContractProvisionProof) {
                    this.props.refresh()
                    this.props.openFiles(result.data.createContractProvisionProof)
                    instance.close()
                }
            })}
            } />]} />
        </Modal>
    }
}
