import React, { Component } from "react"
import { Modal, Input, Button, Select } from "scanmetrix-components"
import { Checkbox } from "../../../../components";
import i18next from "i18next";

const modules = [ "facilityManagement", "documents", "spaceManagement", "accounting", "budget", "carpool" ]

export default class extends Component {
    state = { loading: false, search: null, selected: [], selectedModules: [], roles: [], featureBlacklist: [] }

    constructor(props) {
        super(props)

        this.updateSubsidiaries = this.updateSubsidiaries.bind(this)
        this.updateModules = this.updateModules.bind(this)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Roles {
                        nodes {
                            id
                            name
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ roles: data.data.Roles.nodes })
        })
    }

    updateSubsidiaries() {
        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $subsidiaries: [String!]) {
                    updateUserSubsidiaries(id: $id, subsidiaries: $subsidiaries)
                }
            `,
            variables: {
                id: this.state.id,
                subsidiaries: this.state.selected
            }
        }).then(() => this.props.refresh())
    }

    updateModules() {
        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $modules: [String!]) {
                    updateUserModules(id: $id, modules: $modules)
                }
            `,
            variables: {
                id: this.state.id,
                modules: this.state.selectedModules
            }
        }).then(() => this.props.refresh())
    }

    render() {
        const searchFilter = subsidiary => {
            if(!this.state.search) return true

            const search = this.state.search.trim().toLowerCase()
            if(search.length === 0) return true

            const searchString = `${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""} / ${subsidiary.address.inline}`.toLowerCase().trim()

            return searchString.includes(search)
        }

        return <Modal minWidth="650px" instance={this.props.instance} initialize={user => this.setState({ ...user, search: null, selectedModules: user.modules.map(m => m.module), selected: user.subsidiaries.map(s => String(s.subsidiary.id)), loading: false })}>
            <Modal.Head title="Benutzer anpassen" icon="user" />
            <Modal.Body padding={24}>
                <Input.MutationProvider value={{
                    name: "updateUser",
                    id: this.state.id,
                    data: this.state,
                    onSave: () => this.props.refresh()
                }}>
                    <div style={{ display: "grid", gridGap: 16, gridAutoFlow: "row" }}>
                        <Modal.Divider title={i18next.t("page.private.users.sectionItem.updateUserModal.profileData")} />
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: 16 }}>
                            <Select adjustWidth required field="salutation" icon="venus-mars" label={i18next.t("defaults.input.salutation")} items={[
                                { key: "male", title: i18next.t("defaults.salutation.male"), icon: "mars" },
                                { key: "female", title: i18next.t("defaults.salutation.female"), icon: "venus" },
                                { key: "maleDr", title: i18next.t("defaults.salutation.maleDr"), icon: "mars" },
                                { key: "femaleDr", title: i18next.t("defaults.salutation.femaleDr"), icon: "venus" },
                                { key: "maleProfDr", title: i18next.t("defaults.salutation.maleProfDr"), icon: "mars" },
                                { key: "femaleProfDr", title: i18next.t("defaults.salutation.femaleProfDr"), icon: "venus" },
                                { key: "maleProfDrMed", title: i18next.t("defaults.salutation.maleProfDrMed"), icon: "mars" },
                                { key: "femaleProfDrMed", title: i18next.t("defaults.salutation.femaleProfDrMed"), icon: "venus" },
                                { key: "maleDrMed", title: i18next.t("defaults.salutation.maleDrMed"), icon: "mars" },
                                { key: "femaleDrMed", title: i18next.t("defaults.salutation.femaleDrMed"), icon: "venus" },
                                { key: "maleDiplIng", title: i18next.t("defaults.salutation.maleDiplIng"), icon: "mars" },
                                { key: "femaleDiplIng", title: i18next.t("defaults.salutation.femaleDiplIng"), icon: "venus" },
                                { key: "maleDiplKfm", title: i18next.t("defaults.salutation.maleDiplKfm"), icon: "mars" },
                                { key: "femaleDiplKfr", title: i18next.t("defaults.salutation.femaleDiplKfr"), icon: "venus" }
                            ]} />
                            <Input required field="firstName" icon="user" label={i18next.t("page.private.users.sectionItem.updateUserModal.firstName")} adjustWidth />
                            <Input required field="lastName" icon="user" label={i18next.t("page.private.users.sectionItem.updateUserModal.lastName")} adjustWidth />
                        </div>
                        <div style={{ display: "grid", gridGap: 16, gridTemplateColumns: "1fr 1fr" }}>
                            <Input disabled required field="email" icon="envelope-open" label={i18next.t("page.private.users.sectionItem.updateUserModal.email")} adjustWidth />
                            <Select adjustWidth required field="role.id" icon="shield" label={i18next.t("page.private.users.sectionItem.updateUserModal.role")} items={this.state.roles.map(role => ({
                                key: role.id,
                                title: role.name
                            }))} />
                        </div>
                        <Modal.Divider title={i18next.t("page.private.users.sectionItem.updateUserModal.accessRestrictions")} />
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 16 }}>
                            <Checkbox label={i18next.t("page.private.users.sectionItem.updateUserModal.active")} field="active" />
                            <Checkbox disabled={this.state.approved} label={i18next.t("page.private.users.sectionItem.updateUserModal.approved")} field="approved" />
                            <Checkbox disabled label={i18next.t("page.private.users.sectionItem.updateUserModal.emailVerified")} field="emailVerified" />
                            <Checkbox label={i18next.t("page.private.users.sectionItem.updateUserModal.subsidiaryWildcard")} field="subsidiaryWildcard" />
                        </div>
                        <Modal.Divider title={i18next.t("page.private.users.sectionItem.updateUserModal.modulesTitle")} />
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 16 }}>
                            {modules.map(module => <Checkbox onChange={value => {
                                let mods = this.state.selectedModules

                                if(value) {
                                    mods = [...new Set([ module, ...mods ])]
                                } else {
                                    mods = mods.filter(m => m !== module)
                                }

                                this.setState({ selectedModules: mods }, this.updateModules)
                            }} value={!!this.state.selectedModules.find(m => m === module)} label={i18next.t("page.private.users.sectionItem.updateUserModal.modules." + module)} key={module} />)}
                        </div>
                        {!this.state.subsidiaryWildcard && <Modal.Divider title={i18next.t("page.private.users.sectionItem.updateUserModal.subsidiaries")} />}
                        {!this.state.subsidiaryWildcard && <div>
                            <div style={{ display: "grid", gridTemplateColumns: "min-content min-content", gridGap: 16, marginTop: 16, marginBottom: 16 }}>
                                <Button thick disabled={this.props.subsidiaries.filter(searchFilter).length === 0 || this.state.loading} title={i18next.t("page.private.users.sectionItem.updateUserModal.selectAllVisible") + this.props.subsidiaries.filter(searchFilter).length + ")"} icon="check-double" onClick={() => {
                                    let subs = this.props.subsidiaries.filter(searchFilter)

                                    let selected = this.state.selected
                                    selected.push(...subs.map(s => s.id))

                                    selected = [...new Set(selected)]

                                    this.setState({ selected }, this.updateSubsidiaries)
                                }} />
                                <Button thick disabled={this.props.subsidiaries.filter(searchFilter).length === 0 || this.state.loading} title={i18next.t("page.private.users.sectionItem.updateUserModal.deselectAllVisible") + this.props.subsidiaries.filter(searchFilter).length + ")"} secondary icon="times" onClick={() => {
                                    let subIds = this.props.subsidiaries.filter(searchFilter).map(s => s.id)

                                    let selected = this.state.selected.filter(id => !subIds.includes(id))

                                    this.setState({ selected }, this.updateSubsidiaries)
                                }} />
                            </div>
                            <div style={{ marginBottom: 16, opacity: 0.75 }}>
                                {this.state.selected.length} / {this.props.subsidiaries.length} {i18next.t("page.private.users.sectionItem.updateUserModal.selected")}
                            </div>
                            <Input icon="search" label={i18next.t("page.private.users.sectionItem.updateUserModal.branchLabel")} adjustWidth value={this.state.search} onChange={search => this.setState({ search })} />
                            <div style={{ height: 400, overflowY: "scroll", display: "flex", flexDirection: "column", marginTop: 16 }}>
                                {this.props.subsidiaries.filter(searchFilter).map(subsidiary => <div key={subsidiary.id} style={{ marginTop: 16 }}>
                                    <Checkbox label={`${subsidiary.name}${subsidiary.label ? (" (" + subsidiary.label + ")") : ""}`} value={this.state.selected.includes(subsidiary.id)} onChange={value => {
                                        if(value) {
                                            let selected = this.state.selected
                                            selected.push(subsidiary.id)
                                            selected = [...new Set(selected)]
                                            this.setState({ selected }, this.updateSubsidiaries)
                                        } else {
                                            let selected = this.state.selected.filter(id => id !== subsidiary.id)
                                            this.setState({ selected }, this.updateSubsidiaries)
                                        }
                                    }} />
                                </div>)}
                            </div>
                        </div>}
                    </div>
                </Input.MutationProvider>

            </Modal.Body>
        </Modal>
    }
}
