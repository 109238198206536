import React, { Component } from "react"
import { Page, Button, Modal, Input, Select, Section } from "scanmetrix-components"
import styled from "styled-components"
import OutsideClickHandler from "react-outside-click-handler";
import Avatar from "react-avatar"

import * as JsSearch from 'js-search'

import i18next from "i18next"

const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 1280px) {
    zoom: 0.8;
  }
`

const Header = styled.div`
  width: 75%;
  margin: 0 auto;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  
  .filter-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  
    .filter-view {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      user-select: none;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
        
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
          
        i {
          font-size: 1em;
        }
          
        p {
          font-size: 1em;
          padding: 0 10px;
        }
          
        .select-box {
          width: 175px;
          padding: 10px 15px;
          background: white;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: none;
          font-size: 1em;
          outline: none;
          box-shadow: 0 0 5px rgba(0, 0, 0, .0625);
          cursor: pointer;
          user-select: none;
          
          .placeholder {
            display: flex;
            flex-direction: row;
            align-items: center;
            
            .placeholder-label {
              padding: 0;
              font-size: 1em;
            }
            
            i {
              margin-left: auto;
              color: #3B97D3;
            }
          }
          
          .list {
            list-style: none;
            position: absolute;
            top: 100%;
            left: 0;
            border-radius: 0 0 5px 5px;
            background: white;
            box-shadow: 0 7.5px 7.5px rgba(0, 0, 0, .125);
            overflow: hidden;
            z-index: 15;
            
            li {
              width: 175px;
              transition: all 100ms ease;
              padding: 5px 15px;
              
              &:hover {
                background: #F1F1F1;
              }
            }
          }
        }
      }
    }
    
    .tab-view {
      height: 40px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      box-sizing: border-box;
      user-select: none;
      
      .label {
        padding: 0 15px;
        height: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 250ms ease;
        
        &:hover {
          opacity: .75;
        }
        
        p {
          color: gray;
          display: flex;
          align-items: center;
          transition: all 250ms ease;
        }
        
        i {
          color: gray;
          transition: all 250ms ease;
        }
      }
      
      .active {
        border-bottom: 3px solid #3B97D3;
        
        p, i {
          color: black;
        }
      }
    }
  }
`

const SearchBar = styled.input`
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
  padding: 0 32px;
  font-size: 1.1em;
  border-radius: 125px;
  box-sizing: border-box;
  outline: none;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0 , 0, .15);

  &::placeholder {
    color: rgba(0, 0 , 0 , .5);
  }
`

const Body = styled.div `
  width: 75%;
  margin: 0 auto 48px auto;
  flex: 1;
  
  .list-body {
    width: 100%;
  }
`

const AgendaContainer = styled.div`
  padding-bottom: 25px;
  box-sizing: border-box;
`

const AgendaBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  background: white;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 8px;
`

const AgendaPoint = styled.div`
  width: 100%;
  padding: 25px 0 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, .0625);
  box-shadow: 0 2.5px 15px rgba(0, 0 , 0, .0625);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .checker {
    font-size: 2rem;
    padding: 0 25px;
    transition: all 250ms ease;
    cursor: pointer;
    
    &:hover {
      opacity: 0.5;
    }
  }
  
  .text-column {
    margin-bottom: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    user-select: none;
    
    h1 {
      font-size: 1.25em;
      font-weight: 600;
      color: #20242B;
      margin-bottom: 10px;
    }
    
    .description {
      line-height: 1.8em;
      font-size: 1em;
      color: #20242B;
    }
    
    .subsidiary {
        margin-top: 8px;
        font-size: 0.8em;
    }
    
    .user {
        display: flex;
        align-items: center;
        margin-top: 8px;
        
        .avatar {
            border-radius: 24px;
            margin-right: 8px;
        }
    }
    
    .date-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;
    
      .date, .time {
        font-size: 0.85em;
        color: #20242B;
      }
      
      .break {
        font-size: 0.85em;
        color: #20242B;
        margin: 0 5px;
      }
    }
  }
  
  .doneBy {
      display: flex;
      align-items: center;
      user-select: none;
      
      .avatar {
          width: 32px;
          height: 32px;
          border-radius: 32px;
          margin-right: 8px;
      }
  }
  
  .priority-box {
    margin: 0 25px;
    padding: 5px;
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .0625);
    box-shadow: 0 0 5px rgba(0, 0, 0, .0625);
    
    .priority-marker {
      font-size: 2rem;
      padding: 0;
      position: absolute;
      top: 0;
    }
  }
`

const CommentBody = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  
  .profile-picture {
    user-select: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    overflow: hidden;
    margin: 5px 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .25);
    cursor: pointer;
    transition: all 250ms ease;
    
    &:hover {
      opacity: 0.75;
    }
  }
  
  .comment-column {
    display: block;
    max-width: 70%;
    
    .author-field {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 5px;
    
      h2 {
        font-size: 0.95em;
        font-weight: 600;
        color: #3B97D3;
        cursor: pointer;
        transition: all 250ms ease;
      
        &:hover {
          opacity: 0.5;
        }
      }
      
      .date-item {
        font-size: 0.75em;
        margin-left: 5px;
      }
      
      .date-item-first {
        margin-left: 10px;
      }
    }
    
    .comment-text {
      font-size: 0.85em;
      color: #20242B;
      cursor: default;
      padding-right: 15px;
      box-sizing: border-box;
      line-height: 1.7em;
    }
  }
  
  .delete-btn {
    width: 20px; 
    height: 20px;
    margin: 0 22.5px 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(30deg, #0F7ABF, #58B9F9);
    border-radius: 5px;
    padding: 12px;
    box-sizing: border-box;
    transition: all 250ms ease;
    cursor: pointer;
    
    &:hover {
      opacity: 0.5;
    }
    
    i {
      color: white;
      font-size: 0.85em;
    }
  }
`

const AddComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  padding: 10px 15px;
  border-top: 1px solid rgba(0, 0, 0, .0625);
  box-sizing: border-box;
  position: relative;
  margin-top: -1px;
  
  .textarea {
    width: 100%;
    max-height: 150px;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    outline: none;
    box-sizing: border-box;
    font-family: inherit;
    resize: none;
    overflow-y: scroll;
    transition: border-color 0.3s;
    
    &:focus {
        border-color: #20242b;
    }
    
    &::-webkit-scrollbar {
      width: 5px;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .125);
      border-radius: 125px;
    }
  }
  
  .textarea[placeholder]:empty:before {
    content: attr(placeholder);
    color: rgba(0, 0, 0, .5);
  }
  
  i {
    cursor: pointer;
    color: white;
    margin-left: 15px;
    padding: 10px;
    border-radius: 5px;
    background: linear-gradient(30deg,#0f7abf,#58b9f9);
    transition: all 150ms ease;
    box-shadow: 0 2px 8px -3px #3B97D3;
    user-select: none;
    
    &:hover {
      opacity: 0.75;
    }
  }
`

function getFlagColor(value) {
    if (value === 2) return '#C0392B'
    if (value === 1) return '#F39C12'
    return '#2ECC71'
}

class FilterSelect extends Component {
    state = {
        selectOpened: false,
        value: null
    }

    constructor(props) {
        super(props)

        this.state.value = props.value || props.data[0].key
    }

    render() {
        return<OutsideClickHandler onOutsideClick={() => this.setState({ selectOpened: false })}>
            <div className='select-box' style={ this.state.selectOpened ? { borderTopLeftRadius: 5, borderTopRightRadius: 5 } : { borderRadius: 5 } } onClick={ () => this.setState({ selectOpened: !this.state.selectOpened }) }>
                <div className='placeholder'>
                    <p className='placeholder-label'>{this.props.data.find(dataSet => dataSet.key === this.state.value).method}</p>
                    <i className={ this.state.selectOpened ? 'fas fa-chevron-up' : 'fas fa-chevron-down' } />
                </div>
                <ul className='list' style={ this.state.selectOpened ? { display: 'block' } : { display: 'none' } }>
                    {this.props.data.map(( item, index ) => {
                        return <li key={index} onClick={() => {
                            this.setState({ value: item.key })
                            this.props.onChange(item.key)
                        }}>
                            {item.method}
                        </li>
                    })}
                </ul>
            </div>
        </OutsideClickHandler>
    }
}

class AgendaItem extends Component {
    state = { checked: false, locked: false, comment: null }

    constructor(props) {
        super(props)

        this.state.checked = !!props.item.doneAt
    }

    render() {
        const item = this.props.item

        return <AgendaContainer>
        <AgendaBody>
            <AgendaPoint>
                <i
                    className={ this.state.checked ? 'far fa-check-square checker' : 'fal fa-square checker' }
                    onClick={ () => {
                        if(!this.state.locked) {
                            if(this.props.scope === "google") {
                                fetch(scanmetrix.backendURL + "/integration/google_workspace/finish_task", {
                                    method: "POST",
                                    credentials: "include",
                                    body: JSON.stringify({ taskId: item.id, tasklistId: item.tasklist.id }),
                                    headers: {
                                        "Content-Type": "application/json"
                                    }
                                }).then(result => result.json()).then(result => {
                                    if(result.status === "SUCCESSFUL") setTimeout(this.props.refresh, 500)
                                })
                            } else {
                                scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                    mutation($id: ID!, $done: Boolean!) {
                                        updateAgendaItem(id: $id, done: $done)
                                    }
                                `,
                                    variables: {
                                        id: item.id,
                                        done: !this.state.checked
                                    }
                                }).then(data => {
                                    if(data.data.updateAgendaItem) {
                                        setTimeout(this.props.refresh, 500)
                                    }
                                })
                            }

                            this.setState({ checked: !this.state.checked, locked: true })
                        }
                    } }
                    style={ this.state.checked ? { color: '#3B97D3' } : { color: '#20242B' } }
                />
                <div className='text-column'>
                    <h1 className='title' style={ this.state.checked ? { textDecoration: 'line-through', opacity: 0.75 } : {  } }>{item.title}</h1>
                    <p className='description' style={ this.state.checked ? { textDecoration: 'line-through', opacity: 0.75 } : {  } }>{item.description}</p>
                    <div className='date-row'>
                        <p className='date' style={ this.state.checked ? { textDecoration: 'line-through', opacity: 0.75 } : {  } }>{moment(item.createdAt).format("DD.MM.YYYY")}</p>
                        <p className='break'>-</p>
                        <p className='time' style={ this.state.checked ? { textDecoration: 'line-through', opacity: 0.75 } : {  } }>{moment(item.createdAt).format("HH:mm")}</p>
                    </div>
                    {item.subsidiary && <div className="subsidiary">{i18next.t("page.private.agenda.concernsBranchOffice")} <b>{item.subsidiary.name} ({item.subsidiary.label || item.subsidiary.id})</b> in <b>{item.subsidiary.address.postalCode} {item.subsidiary.address.city}</b></div>}
                    {item.user && <div className="user">
                        <Avatar size="24" email={scanmetrix.disableGravatar ? null : item.user.email} name={`${item.user.firstName} ${item.user.lastName}`} className="avatar" />
                        <p className="text">{item.user.firstName} {item.user.lastName}</p>
                    </div>}
                </div>
                {item.doneByUser && <div className="doneBy">
                    <Avatar size="32" email={scanmetrix.disableGravatar ? null : item.doneByUser.email} name={`${item.doneByUser.firstName} ${item.doneByUser.lastName}`} className="avatar" />
                    <p className="text">{i18next.t("page.private.agenda.completedOn")} {moment(item.doneAt).format("DD.MM.YYYY")} {i18next.t("page.private.agenda.from")} {`${item.doneByUser.firstName} ${item.doneByUser.lastName}`}</p>
                </div>}
                {this.props.scope !== "google" && <div className='priority-box'>
                    <i className='fad fa-bookmark priority-marker' style={{ color: getFlagColor(item.priority) }} />
                </div>}
            </AgendaPoint>
            {this.props.actions.map((comment, index) => <CommentBody key={index}>
                <div className="profile-picture">
                    <Avatar size="45" email={scanmetrix.disableGravatar ? null : comment.user.email} name={`${comment.user.firstName} ${comment.user.lastName}`} />
                </div>
                <div className='comment-column' style={this.state.checked ? { textDecoration: 'line-through', opacity: 0.5 } : {  }}>
                    <div className='author-field'>
                        <h2>{comment.user.firstName} {comment.user.lastName}</h2>
                        <p className='date-item date-item-first'>{moment(comment.createdAt).format("DD.MM.YYYY")}</p>
                        <p className='date-item'>-</p>
                        <p className='date-item'>{moment(comment.createdAt).format("HH:mm")}</p>
                    </div>
                    <p className='comment-text'>{comment.content}</p>
                </div>
                {comment.user.id === scanmetrix.session.id && !item.doneAt && <div className='delete-btn' onClick={() => this.props.deleteAgendaItemActionModal(comment) }>
                    <i className='fal fa-trash' />
                </div>}
            </CommentBody>)}
            {!item.doneAt && this.props.scope !== "google" && <AddComment>
                <input spellCheck={false} autoCorrect={false} autoCapitalize={false} value={this.state.comment || ""} onChange={e => this.setState({ comment: e.target.value })} className='textarea' placeholder='Kommentar hinzufügen...' />
                <i className='fas fa-paper-plane' onClick={() => {
                    const content = this.state.comment

                    if(!content) return false
                    if(content.trim().length === 0) return false

                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($content: String!, $agendaItemId: ID!) {
                                createAgendaItemAction(content: $content, agendaItemId: $agendaItemId)
                            }
                        `,
                        variables: {
                            content,
                            agendaItemId: item.id
                        }
                    }).then(data => {
                        if(data.data.createAgendaItemAction) {
                            this.setState({ comment: null })
                            this.props.refresh()
                        }
                    })
                }} />
            </AddComment>}
        </AgendaBody>
        </AgendaContainer>
    }
}

const NoItems = styled.div`
  width: 90%;
  max-width: 512px;
  margin: 0 auto;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-weight: normal;
    user-select: none;
  }

  img {
    width: 50%;
    margin-top: 40px;
    user-select: none;
    filter: grayscale(100%);
    opacity: 0.4;
    -webkit-user-drag: none;
  }
`

class Agenda extends Component {
    render() {
        return <>
            {this.props.data.map(item => <AgendaItem scope={this.props.scope} deleteAgendaItemActionModal={this.props.deleteAgendaItemActionModal} key={item.id} item={item} actions={item.actions || []} refresh={() => this.props.refresh()} />)}
            {this.props.data.length === 0 && <NoItems>
                <h2>{i18next.t("page.private.agenda.noEntries")}</h2>
                <img src="/illustrations/agenda_empty.svg" />
            </NoItems>}
        </>
    }
}

class AgendaItems extends Component {
    state = { agendaItems: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()

        props.fetch(this.fetch)
    }

    fetch() {
        if(this.props.scope === "google") {
            fetch(scanmetrix.backendURL + "/integration/google_workspace/tasks", {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(result => result.json()).then(data => {
                if(data.status === "SUCCESSFUL") {
                    const agendaItems = data.tasks.map(task => ({
                        id: task.id,
                        title: task.title,
                        description: task.notes,
                        createdAt: moment(task.updated).toDate(),
                        doneAt: null,
                        priority: 1,
                        deadline: null,
                        user: scanmetrix.session,
                        doneByUser: null,
                        subsidiary: null,
                        tasklist: task.tasklist,
                        actions: []
                    }))

                    this.setState({ agendaItems })
                    this.props.amount(agendaItems.length)
                }
            })

            return true
        }

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($scope: String!) {
                    AgendaItems(scope: $scope) {
                        nodes {
                            id
                            title
                            description
                            createdAt
                            doneAt
                            priority
                            deadline
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            doneByUser {
                                id
                                firstName
                                lastName
                                email
                            }
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    postalCode
                                    city
                                }
                            }
                            actions {
                                id
                                user {
                                    id
                                    email
                                    firstName
                                    lastName
                                }
                                createdAt
                                content
                            }
                        }
                    }
                }
            `,
            variables: {
                scope: this.props.scope
            }
        }).then(data => {
            const agendaItems = data.data.AgendaItems.nodes

            this.props.amount(agendaItems.length)

            this.setState({ agendaItems })
        })
    }

    render() {
        if(!this.state.agendaItems || this.props.hidden) return null

        const search = new JsSearch.Search("id")
        search.addIndex("title")
        search.addIndex("description")
        search.addIndex("comments")

        search.addDocuments(this.state.agendaItems.map(agendaItem => ({ ...agendaItem, comments: agendaItem.actions.map(action => `${action.user.firstName} ${action.user.lastName}: ${action.content}`) })))

        let result = search.search(this.props.search)
        const sortMethod = this.props.sortMethod
        let formatted = this.props.search ? result : this.state.agendaItems

        if(sortMethod === "newest") formatted = formatted.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())
        if(sortMethod === "oldest") formatted = formatted.sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())
        if(sortMethod === "high") formatted = formatted.sort((a, b) => b.priority - a.priority)
        if(sortMethod === "low") formatted = formatted.sort((a, b) => a.priority - b.priority)

        return <Agenda scope={this.props.scope} deleteAgendaItemActionModal={this.props.deleteAgendaItemActionModal} data={formatted} refresh={() => this.props.refresh()} />
    }
}


class CreateAgendaItemModal extends Component {
    state = { type: null, title: null, description: null, deadline: null, priority: 0, subsidiaryId: null, loading: false, subsidiaries: [] }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                inline
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ subsidiaries: data.data.Subsidiaries.nodes })
        })
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={type => this.setState({ type, title: null, description: null, deadline: null, priority: 0, subsidiaryId: null, loading: false })}>
            <Modal.Head title={i18next.t("page.private.agenda.agendaForm.title")} icon="tasks" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    {this.state.type !== "google" && <>
                        <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "1fr 1fr" }}>
                            <Select adjustWidth value={this.state.priority} onChange={priority => this.setState({ priority })} icon="exclamation" label={i18next.t("page.private.agenda.agendaForm.priority.priorityTitle")} items={[
                                { key: 0, title: i18next.t("page.private.agenda.agendaForm.priority.priorityTypes.low"), icon: "tachometer-slowest" },
                                { key: 1, title: i18next.t("page.private.agenda.agendaForm.priority.priorityTypes.medium"), icon: "tachometer-average" },
                                { key: 2, title: i18next.t("page.private.agenda.agendaForm.priority.priorityTypes.high"), icon: "tachometer-fastest" },
                            ]} />
                            <Input value={this.state.deadline} onChange={deadline => this.setState({ deadline })} date icon="calendar-day" label={i18next.t("page.private.agenda.agendaForm.priority.deadline")} adjustWidth />
                        </div>
                        <Select adjustWidth value={this.state.subsidiaryId} onChange={subsidiaryId => this.setState({ subsidiaryId })} icon="map-marker" label={i18next.t("page.private.agenda.agendaForm.priority.settlement")} items={this.state.subsidiaries.map(subsidiary => ({
                            key: subsidiary.id,
                            title: `${subsidiary.name} (${subsidiary.label || subsidiary.id}) / ${subsidiary.address.inline}`
                        }))} />
                    </>}
                    <Input required value={this.state.title} onChange={title => this.setState({ title })} icon="pencil" label={i18next.t("page.private.agenda.agendaForm.priority.title")} adjustWidth />
                    <Input textArea value={this.state.description} onChange={description => this.setState({ description })} icon="font" label={i18next.t("page.private.agenda.agendaForm.priority.description")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.title} title={i18next.t("page.private.agenda.agendaForm.priority.button")} icon="tasks" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($title: String!, $description: String, $priority: Int!, $deadline: DateTime, $subsidiaryId: ID, $type: String) {
                            createAgendaItem(title: $title, description: $description, priority: $priority, deadline: $deadline, subsidiaryId: $subsidiaryId, type: $type)
                        }
                    `,
                    variables: {
                        title: this.state.title,
                        description: this.state.description,
                        priority: this.state.priority,
                        subsidiaryId: this.state.subsidiaryId,
                        type: this.state.type,
                        deadline: this.state.deadline ? moment(this.state.deadline, "DD.MM.YYYY").toDate() : null
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createAgendaItem) {
                        instance.close()
                        this.props.refresh()
                    }
                })}
            } />} />
        </Modal>
    }
}


class DeleteAgendaItemActionModal extends Component {
    state = { id: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={agendaItemAction => this.setState({ id: agendaItemAction.id, loading: false })}>
            <Modal.Head title={i18next.t("page.private.agenda.comment.title")} icon="trash" />
            <Modal.Body padding={24}>
                {i18next.t("page.private.agenda.agendaForm.comment.description")}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.agenda.comment.button")} icon="trash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            deleteAgendaItemAction(id: $id)
                        }
                    `,
                    variables: {
                        id: this.state.id
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.deleteAgendaItemAction) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}

const Amount = styled.b`
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    background: white;
    margin-left: 8px;
    color: #20242B;
    padding: 0 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
`

export default class extends Component {
    state = { tab: "all", amountAll: 0, amountServiceProvider: 0, amountUser: 0, amountArchive: 0, amountGoogle: 0, search: "", sortMethod: "newest" }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
    }

    fetch() {
        this.fetchAll()
        this.fetchUser()
        this.fetchServiceProvider()
        this.fetchArchive()
        this.fetchGoogle()
    }

    render() {
        return <Page {...this.props} fullHeight maxSize='100%'>
            <CreateAgendaItemModal refresh={() => this.fetch()} instance={ref => this.createAgendaItemModal = ref} />
            <DeleteAgendaItemActionModal refresh={() => this.fetch()} instance={ref => this.deleteAgendaItemActionModal = ref} />
            <Container>
                <Content>
                    <Header>
                        <div className='filter-row'>
                            <div className='filter-view'>
                                <div className='row'>
                                    <i className='fas fa-filter' />
                                    <p className='label'>{i18next.t("page.private.agenda.sorting.sortingTitle")} </p>
                                    <FilterSelect
                                        value={this.state.sortMethod}
                                        onChange={key => this.setState({ sortMethod: key })}
                                        data={
                                            [
                                                { method: i18next.t("page.private.agenda.sorting.sortingTypes.newestFirst"), key: "newest" },
                                                { method: i18next.t("page.private.agenda.sorting.sortingTypes.oldestFirst"), key: "oldest" },
                                                { method: i18next.t("page.private.agenda.sorting.sortingTypes.priorityHigh"), key: "high" },
                                                { method: i18next.t("page.private.agenda.sorting.sortingTypes.priorityLow"), key: "low" },
                                            ]
                                        }
                                    />
                                    <div style={{ marginLeft: 24 }}>
                                        <Button disabled={scanmetrix.checkPermission("AgendaItem") < 2 || (this.state.tab !== "all" && this.state.tab !== "user" && this.state.tab !== "google")} thick title= {i18next.t("page.private.agenda.newAgendaEntryButton")} icon="plus" onClick={() => this.createAgendaItemModal.open(this.state.tab)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Header>

                    <Body>
                        <div style={{ width: "100%", display: "flex", alignItems: "left", marginTop: 16 }}>
                            <Section className="list-body" bodyPadding="24px 0 0 0">
                                <Section.Item title={<>{i18next.t("page.private.agenda.sectionItem.all")}<Amount>{this.state.amountAll}</Amount></>} icon="list-ol">
                                    <SearchBar spellCheck={false} autoCapitalize={false} autoComplete={false} autoCorrect={false} value={this.state.search || ""} onChange={e => this.setState({ search: e.target.value })} placeholder={i18next.t("page.private.agenda.searchPlaceholder")} />
                                    <AgendaItems sortMethod={this.state.sortMethod} search={this.state.search} deleteAgendaItemActionModal={agendaItemAction => this.deleteAgendaItemActionModal.open(agendaItemAction)} scope="all" fetch={fetch => this.fetchAll = fetch} refresh={() => this.fetch()} amount={amountAll => this.setState({ amountAll })} />
                                </Section.Item>
                                <Section.Item title={<>{i18next.t("page.private.agenda.sectionItem.serviceProvider")}<Amount>{this.state.amountServiceProvider}</Amount></>} icon="user-tie">
                                    <SearchBar spellCheck={false} autoCapitalize={false} autoComplete={false} autoCorrect={false} value={this.state.search || ""} onChange={e => this.setState({ search: e.target.value })} placeholder={i18next.t("page.private.agenda.searchPlaceholder")} />
                                    <AgendaItems sortMethod={this.state.sortMethod} search={this.state.search} deleteAgendaItemActionModal={agendaItemAction => this.deleteAgendaItemActionModal.open(agendaItemAction)} scope="serviceProvider" fetch={fetch => this.fetchServiceProvider = fetch} refresh={() => this.fetch()} amount={amountServiceProvider => this.setState({ amountServiceProvider })} />
                                </Section.Item>
                                <Section.Item title={<>{i18next.t("page.private.agenda.sectionItem.own")}<Amount>{this.state.amountUser}</Amount></>} icon="user">
                                    <SearchBar spellCheck={false} autoCapitalize={false} autoComplete={false} autoCorrect={false} value={this.state.search || ""} onChange={e => this.setState({ search: e.target.value })} placeholder={i18next.t("page.private.agenda.searchPlaceholder")} />
                                    <AgendaItems sortMethod={this.state.sortMethod} search={this.state.search} deleteAgendaItemActionModal={agendaItemAction => this.deleteAgendaItemActionModal.open(agendaItemAction)} scope="user" fetch={fetch => this.fetchUser = fetch} refresh={() => this.fetch()} amount={amountUser => this.setState({ amountUser })} />
                                </Section.Item>
                                <Section.Item title={<>{i18next.t("page.private.agenda.sectionItem.googleTasks")}<Amount>{this.state.amountGoogle}</Amount></>} icon="google" iconType="fab">
                                    <SearchBar spellCheck={false} autoCapitalize={false} autoComplete={false} autoCorrect={false} value={this.state.search || ""} onChange={e => this.setState({ search: e.target.value })} placeholder={i18next.t("page.private.agenda.searchPlaceholder")} />
                                    <AgendaItems sortMethod={this.state.sortMethod} search={this.state.search} deleteAgendaItemActionModal={agendaItemAction => this.deleteAgendaItemActionModal.open(agendaItemAction)} scope="google" fetch={fetch => this.fetchGoogle = fetch} refresh={() => this.fetch()} amount={amountGoogle => this.setState({ amountGoogle })} />
                                </Section.Item>
                                <Section.Item title={<>{i18next.t("page.private.agenda.sectionItem.archive")}<Amount>{this.state.amountArchive}</Amount></>} icon="inbox">
                                    <SearchBar spellCheck={false} autoCapitalize={false} autoComplete={false} autoCorrect={false} value={this.state.search || ""} onChange={e => this.setState({ search: e.target.value })} placeholder={i18next.t("page.private.agenda.searchPlaceholder")} />
                                    <AgendaItems sortMethod={this.state.sortMethod} search={this.state.search} deleteAgendaItemActionModal={agendaItemAction => this.deleteAgendaItemActionModal.open(agendaItemAction)} scope="archive" fetch={fetch => this.fetchArchive = fetch} refresh={() => this.fetch()} amount={amountArchive => this.setState({ amountArchive })} />
                                </Section.Item>
                            </Section>
                        </div>
                    </Body>
                </Content>
            </Container>
        </Page>
    }
}
