import React, {forwardRef, Component} from 'react';
import classNames from 'classnames';
import styled from "styled-components"
import {Action, Handle, Remove} from '../index';
import { v4 as uuid } from "uuid"
;

const Input = styled.input`
    outline: 0;
    height: 40px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: 0 10px;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 3px;
    font-size: 16px;
    transition: border-color 0.3s;
  
    &:focus {
        border-color: rgba(0, 0, 0, 0.25);
    }
`

const StyledDataInput = styled.input`
  outline: 0;
  height: 34px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 3px;
  font-size: 14px;
  transition: border-color 0.3s;
::placeholder {
    user-select: none;
}

  &:focus {
    border-color: rgba(0, 0, 0, 0.25)
  }
`

const StyledDataInputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
  
    .fa-trash {
        position: absolute;
        z-index: 2;
        right: 3px;
        top: 3px;
        margin-right: 0;
        border-radius: 100%;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;
        font-size: 14px;
      
        &:hover {
            color: #e74c3c;
            background: rgba(0, 0, 0, 0.05);
        }
    }
`

class DataInput extends Component {
    render() {
        return <StyledDataInputContainer>
            <StyledDataInput {...this.props} />
            <i className="fa-duotone fa-trash" onClick={() => this.props.delete()} />
        </StyledDataInputContainer>
    }
}


const Number = styled.div`
    margin-left: 10px;
    font-weight: bold;
    user-select: none;
`

export const TreeItem = forwardRef(
    (
        {
            childCount,
            clone,
            depth,
            disableSelection,
            disableInteraction,
            ghost,
            handleProps,
            indentationWidth,
            indicator,
            collapsed,
            onCollapse,
            onRemove,
            style,
            value,
            text,
            inputs,
            onInputsChange,
            onChange,
            number,
            wrapperRef,
            ...props
        },
        ref
    ) => {
        return (
            <li
                className={classNames(
                    "Wrapper",
                    clone && "clone",
                    ghost && "ghost",
                    indicator && "indicator",
                    disableSelection && "disableSelection",
                    disableInteraction && "disableInteraction"
                )}
                ref={wrapperRef}
                style={
                    {
                        '--spacing': `${indentationWidth * depth}px`,
                    }
                }
                {...props}
            >
                <div className={"TreeItem"} ref={ref} style={style}>
                    <div className="top">
                        <Handle {...handleProps} />
                        {onCollapse && (
                            <Action
                                onClick={onCollapse}
                                className={classNames(
                                    "Collapse",
                                    collapsed && "collapsed"
                                )}
                            >
                                {collapseIcon}
                            </Action>
                        )}
                        {number && <Number>{number}</Number>}
                        <Input style={{ marginLeft: number ? 10 : 0 }} value={text} onChange={e => onChange(e.target.value)} />
                        {!clone && onRemove && <Remove onClick={onRemove} />}
                        {clone && childCount && childCount > 1 ? (
                            <span className={"Count"}>{childCount}</span>
                        ) : null}
                    </div>
                    {!clone && <div className="bottom">
                        <AddDataInput onClick={() => {
                            inputs[uuid()] = { title: "", createdAt: moment().valueOf() }
                            onInputsChange(inputs)
                        }} />
                        {Object.keys(inputs).sort((a, b) => inputs[b].createdAt - inputs[a].createdAt).map(key => <DataInput delete={() => {
                            delete inputs[key]
                            onInputsChange(inputs)
                        }} onChange={e => {
                            inputs[key].title = e.target.value
                            onInputsChange(inputs)
                        }} key={key} value={inputs[key].title} placeholder="Titel des Eingabefelds" />)}
                    </div>}
                </div>
            </li>
        );
    }
);

const StyledAdd = styled.div`
    color: #3b97d3;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    transition: opacity 0.3s;
    user-select: none;
    font-size: 14px;
  
    &:hover {
        opacity: 0.75;
    }
  
    i {
        margin-right: 8px;
    }
`

class AddDataInput extends Component {
    render() {
        return <StyledAdd onClick={this.props.onClick}>
            <i className="fas fa-plus" />
            Eingabefeld hinzufügen
        </StyledAdd>
    }
}

const collapseIcon = (
    <svg width="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 41">
        <path d="M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z" />
    </svg>
);
