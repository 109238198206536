import React, { useRef, Suspense } from 'react'
import styled from 'styled-components'
import * as THREE from "three"
import { Canvas, useFrame, useThree } from 'react-three-fiber'
import { useGLTF } from '@react-three/drei/useGLTF'
import { OrbitControls } from '@react-three/drei'

const mouse = new THREE.Vector3()

function onMouseMove(event) {
    event.preventDefault()
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1
    mouse.y = ( event.clientY / window.innerWidth ) * 2 + 1
}

window.addEventListener('mousemove', onMouseMove)

const Crosshair = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
    
  &::after {
    content: '\f055';
    display: block;
    font-family: "Font Awesome 5 Pro Light";
    font-size: 2em;
    color: #2ECC71;
    text-shadow: 0 0 5px rgba(46, 204, 113, .4);
  }
`

function SmallCar(props) {
    const { nodes, materials } = useGLTF('/smallCar.glb')
    const scale = 0.6

    return (
        <group ref={props.iref} scale={[scale, scale, scale]}>
            <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
            <mesh material={materials.Glass} geometry={nodes.Cube_2.geometry} />
            <mesh material={materials.Metallic} geometry={nodes.Cube_3.geometry} />
            <mesh material={materials.Wheels} geometry={nodes.Cube_4.geometry} />
            <mesh material={materials['Felgen Hinter']} geometry={nodes.Cube_5.geometry} />
            <mesh material={materials['Felgen Vorne']} geometry={nodes.Cube_6.geometry} />
            <mesh material={materials['Felge Platte']} geometry={nodes.Cube_7.geometry} />
            <mesh material={materials.Light} geometry={nodes.Cube_8.geometry} />
            <mesh material={materials.Redlight} geometry={nodes.Cube_9.geometry} />
            <mesh material={materials.Backlight} geometry={nodes.Cube_10.geometry} />
        </group>
    )
}

function Limousine(props) {
    const { nodes, materials } = useGLTF('/limousine.glb')
    const scale = 0.4

    return (
        <group ref={props.iref} scale={[scale, scale, scale]}>
            <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
            <mesh material={materials.Window} geometry={nodes.Cube_2.geometry} />
            <mesh material={materials.Handle} geometry={nodes.Cube_3.geometry} />
            <mesh material={materials.Wheels} geometry={nodes.Cube_4.geometry} />
            <mesh material={materials.Metallic} geometry={nodes.Cube_5.geometry} />
            <mesh material={materials.Felge} geometry={nodes.Cube_6.geometry} />
            <mesh material={materials.PlasticDark} geometry={nodes.Cube_7.geometry} />
            <mesh material={materials.Backlight} geometry={nodes.Cube_8.geometry} />
            <mesh material={materials.BacklightWhite} geometry={nodes.Cube_9.geometry} />
            <mesh material={materials.Light} geometry={nodes.Cube_10.geometry} />
        </group>
    )
}

function SUV(props) {
    const { nodes, materials } = useGLTF('/suv.glb')
    const scale = 0.35

    return (
        <group ref={props.iref} scale={[scale, scale, scale]}>
            <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
            <mesh material={materials.PlasticDark} geometry={nodes.Cube_2.geometry} />
            <mesh material={materials.Windows} geometry={nodes.Cube_3.geometry} />
            <mesh material={materials.Backlight} geometry={nodes.Cube_4.geometry} />
            <mesh material={materials.Light} geometry={nodes.Cube_5.geometry} />
            <mesh material={materials.Wheels} geometry={nodes.Cube_6.geometry} />
            <mesh material={materials.Metallic} geometry={nodes.Cube_7.geometry} />
            <mesh material={materials.Felge} geometry={nodes.Cube_8.geometry} />
        </group>
    )
}

function SmallTransporter(props) {
    const { nodes, materials } = useGLTF('/smallTransporter.glb')
    const scale = 0.5

    return (
        <group ref={props.iref} scale={[scale, scale, scale]}>
            <mesh material={materials.Material} geometry={nodes.Cube_1.geometry} />
            <mesh material={materials.Window} geometry={nodes.Cube_2.geometry} />
            <mesh material={materials.FrontLight} geometry={nodes.Cube_3.geometry} />
            <mesh material={materials.Backlight} geometry={nodes.Cube_4.geometry} />
            <mesh material={materials['Backwards Light']} geometry={nodes.Cube_5.geometry} />
            <mesh material={materials.Metallic} geometry={nodes.Cube_6.geometry} />
            <mesh material={materials.Wheel} geometry={nodes.Cube_7.geometry} />
            <mesh material={materials.Felgen} geometry={nodes.Cube_8.geometry} />
        </group>
    )
}

function BigTransporter(props) {
    const { nodes, materials } = useGLTF('/bigTransporter.glb')
    const scale = 0.25

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh material={materials.PlasticSubColor} geometry={nodes.Cube_2.geometry} />
        <mesh material={materials.Windows} geometry={nodes.Cube_3.geometry} />
        <mesh material={materials.Backlight} geometry={nodes.Cube_4.geometry} />
        <mesh material={materials.Frontlight} geometry={nodes.Cube_5.geometry} />
        <mesh material={materials.Wheels} geometry={nodes.Cube_6.geometry} />
        <mesh material={materials.Metallic} geometry={nodes.Cube_7.geometry} />
        <mesh material={materials.Felge} geometry={nodes.Cube_8.geometry} />
    </group>
}

function BoxTransporter(props) {
    const { nodes, materials } = useGLTF('/boxTransporter.glb')
    const scale = 0.3

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh material={materials.Glass} geometry={nodes.Cube_2.geometry} />
        <mesh material={materials.Metallic} geometry={nodes.Cube_3.geometry} />
        <mesh material={materials.Light} geometry={nodes.Cube_4.geometry} />
        <mesh material={materials.Wheel} geometry={nodes.Cube_5.geometry} />
        <mesh material={materials.Felge} geometry={nodes.Cube_6.geometry} />
        <mesh material={materials.Container} geometry={nodes.Cube_7.geometry} />
    </group>
}

function Truck(props) {
    const { nodes, materials } = useGLTF('/Truck.glb')
    const scale = 0.25

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh material={materials.Glass} geometry={nodes.Cube_2.geometry} />
        <mesh material={materials.Metallic} geometry={nodes.Cube_3.geometry} />
        <mesh material={materials.BackLight} geometry={nodes.Cube_4.geometry} />
        <mesh material={materials.BackRed} geometry={nodes.Cube_5.geometry} />
        <mesh material={materials.Light} geometry={nodes.Cube_6.geometry} />
        <mesh material={materials.Wheel} geometry={nodes.Cube_7.geometry} />
        <mesh material={materials.Felge} geometry={nodes.Cube_8.geometry} />
        <mesh material={materials.Container} geometry={nodes.Cube_9.geometry} />
    </group>
}

function Trailer(props) {
    const { nodes, materials } = useGLTF('/anhaenger.glb')
    const scale = 0.275

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyMetallic} geometry={nodes.Cube_1.geometry} />
        <mesh material={materials.SubMetallic} geometry={nodes.Cube_2.geometry} />
        <mesh material={materials.Wheels} geometry={nodes.Cube_3.geometry} />
        <mesh material={materials.Felge} geometry={nodes.Cube_4.geometry} />
        <mesh material={materials.PlasticDark} geometry={nodes.Cube_5.geometry} />
        <mesh material={materials.Backlight} geometry={nodes.Cube_6.geometry} />
    </group>
}

function Bagger(props) {
    const { nodes, materials } = useGLTF('/bagger.glb')
    const scale = 0.225

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh material={materials.Window} geometry={nodes.Cube_2.geometry} />
        <mesh material={materials.SubColor} geometry={nodes.Cube_3.geometry} />
        <mesh material={materials.Metallic} geometry={nodes.Cube_4.geometry} />
        <mesh material={materials.Wheels} geometry={nodes.Cube_5.geometry} />
        <mesh material={materials.Redlight} geometry={nodes.Cube_6.geometry} />
    </group>
}

function ForkLift(props) {
    const { nodes, materials } = useGLTF('/forkLift.glb')
    const scale = 0.45

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh material={materials.SubColor} geometry={nodes.Cube_2.geometry} />
        <mesh material={materials.Backlight} geometry={nodes.Cube_3.geometry} />
        <mesh material={materials.ThirdBodyColor} geometry={nodes.Cube_4.geometry} />
        <mesh material={materials.Wheels} geometry={nodes.Cube_5.geometry} />
        <mesh material={materials.Felgen} geometry={nodes.Cube_6.geometry} />
        <mesh material={materials.Metallic} geometry={nodes.Cube_7.geometry} />
    </group>
}

function Tractor(props) {
    const { nodes, materials } = useGLTF('/tractor.glb')
    const scale = 0.45

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyColor} geometry={nodes.Cube001.geometry} />
        <mesh material={materials.Windows} geometry={nodes.Cube001_1.geometry} />
        <mesh material={materials['Darker Material']} geometry={nodes.Cube001_2.geometry} />
        <mesh material={materials.Metal} geometry={nodes.Cube001_3.geometry} />
        <mesh material={materials.Wheels} geometry={nodes.Cube001_4.geometry} />
        <mesh material={materials.Felge} geometry={nodes.Cube001_5.geometry} />
        <mesh material={materials.Redlight} geometry={nodes.Cube001_6.geometry} />
        <mesh material={materials.Light} geometry={nodes.Cube001_7.geometry} />
    </group>
}

function TransportLift(props) {
    const { nodes, materials } = useGLTF('/transportLift.glb')
    const scale = 0.25

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh material={materials.PlasticDark} geometry={nodes.Cube_2.geometry} />
        <mesh material={materials.Windows} geometry={nodes.Cube_3.geometry} />
        <mesh material={materials.Wheels} geometry={nodes.Cube_4.geometry} />
        <mesh material={materials.Metallic} geometry={nodes.Cube_5.geometry} />
        <mesh material={materials.Felge} geometry={nodes.Cube_6.geometry} />
        <mesh material={materials.Light} geometry={nodes.Cube_7.geometry} />
    </group>
}

function TransportTruck(props) {
    const { nodes, materials } = useGLTF('/transportTruck.glb')
    const scale = 0.325

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyColor} geometry={nodes.Cube_1.geometry} />
        <mesh material={materials.SubColor} geometry={nodes.Cube_2.geometry} />
        <mesh material={materials.PlasticDark} geometry={nodes.Cube_3.geometry} />
        <mesh material={materials.Windows} geometry={nodes.Cube_4.geometry} />
        <mesh material={materials.Wheels} geometry={nodes.Cube_5.geometry} />
        <mesh material={materials.Metallic} geometry={nodes.Cube_6.geometry} />
        <mesh material={materials.Felge} geometry={nodes.Cube_7.geometry} />
        <mesh material={materials.Light} geometry={nodes.Cube_8.geometry} />
        <mesh material={materials.Backlight} geometry={nodes.Cube_9.geometry} />
    </group>
}

function FlatbedTruck(props) {
    const { nodes, materials } = useGLTF('/flatbedTruck.glb')
    const scale = .4

    return <group ref={props.iref} scale={[scale, scale, scale]}>
        <mesh material={materials.BodyColor} geometry={nodes.Cube001.geometry} />
        <mesh material={materials.SecondColor} geometry={nodes.Cube001_1.geometry} />
        <mesh material={materials.Metallic} geometry={nodes.Cube001_2.geometry} />
        <mesh material={materials.Plating} geometry={nodes.Cube001_3.geometry} />
        <mesh material={materials.Windows} geometry={nodes.Cube001_4.geometry} />
        <mesh material={materials.Wheels} geometry={nodes.Cube001_5.geometry} />
        <mesh material={materials.Felgen} geometry={nodes.Cube001_6.geometry} />
        <mesh material={materials.Frontlight} geometry={nodes.Cube001_7.geometry} />
        <mesh material={materials.Backlight} geometry={nodes.Cube001_8.geometry} />
        <mesh material={materials.BackwardsLight} geometry={nodes.Cube001_9.geometry} />
    </group>
}

const MouseHandler = props => {
    const ref = useRef()
    const { viewport } = useThree()

    const raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(mouse, props.camera)

    useFrame(({ mouse }) => {
        const bounds = viewport()

        const x = (((mouse.x + 1) / 2) * bounds.width * bounds.factor)
        const y = (((mouse.y + 1) / 2) * bounds.height * bounds.factor)

        /*const mouseDirection = new THREE.Vector3(x, y, 0)
            .unproject(props.camera)
            .sub(props.camera.position)
            .normalize()*/

        //raycaster.set(props.camera.position, mouseDirection)

        //console.log(props.camera)

        raycaster.setFromCamera(new THREE.Vector2(x, y), props.camera)

        if(!props.truck.current) return false

        const intersects = raycaster.intersectObject(props.truck.current, true)

        if(intersects.length) ref.current.position.set(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z)

        //ref.current.position.set(x, y, 0)
    })
    const sphereMaterial = new THREE.MeshLambertMaterial({
        color: 0xE74C3C,
        transparent: true,
        opacity: 0.85
    })
    const sphereGeometry = new THREE.SphereGeometry(0.25, 32, 32)



    return <group ref={ref} {...props} position={new THREE.Vector3(0, 0, 0)} dispose={null}>
        <mesh material={sphereMaterial} geometry={sphereGeometry} position={props.position} />
    </group>
}

const CameraControls = props => {
    const {
        camera,
        viewport,
        gl: { domElement }
    } = useThree()

    const sphereRef = useRef()

    const raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(mouse, camera)

    //camera.position.set(3, 1, 0)

    const controls = useRef()

    const sphereMaterial = new THREE.MeshLambertMaterial({
        color: 0xE74C3C,
        transparent: true,
        opacity: props.addCircle ? 0.8 : 0
    })

    const sphereGeometry = new THREE.SphereGeometry(0.25, 32, 32)



    useFrame(() => {
        controls.current.update()

        const bounds = viewport()

        //const x = (((mouse.x + 1) / 2) * bounds.width * bounds.factor)
        //const y = (((mouse.y + 1) / 2) * bounds.height * bounds.factor)

        //console.log(x, y)

        //console.log((mouse.x - 1) * 2)

        raycaster.setFromCamera(new THREE.Vector2(0, 0), camera)

        if(!props.truck.current) return false

        if(props.circlePosition) sphereRef.current.position.set(props.circlePosition.x, props.circlePosition.y, props.circlePosition.z)

        const intersects = raycaster.intersectObject(props.truck.current, true)


        if(intersects.length) {
            const intersect = intersects[0].point

            if(sphereRef) {
                if(!props.circlePosition) sphereRef.current.position.set(intersect.x, intersect.y, intersect.z)
                if(props.onRaycast) props.onRaycast(intersect.x, intersect.y, intersect.z)
            }

            //console.log(intersect)
        }
    })


    return (
        <>
            <mesh ref={sphereRef} material={sphereMaterial} geometry={sphereGeometry} />
            <OrbitControls
                ref={controls}
                args={[ camera, domElement ]}
                autoRotate={false}
                enableZoom={props.enableZoom}
                minDistance={props.minDistance}
                maxDistance={props.maxDistance}
            />
        </>
    )
}

export default (props) => {
    const carRef = React.createRef()

    return (
        <div style={{ width: '100%', height: props.height + 'px' || '100%', position: 'relative' }}>
            <Canvas>
                <CameraControls circlePosition={props.circlePosition} onRaycast={props.onRaycast} addCircle={props.addCircle || props.circlePosition} truck={carRef} minDistance={ props.minDistance || 3 } maxDistance={ props.maxDistance || 6 } enableZoom={props.enableZoom !== false} />
                <ambientLight intensity={0.25} />
                <directionalLight intensity={0.5} position={[ 3, 1, 0 ]} />
                <directionalLight intensity={0.5} position={[ -3, 1, 0 ]} />

                <Suspense fallback={null}>
                    { props.car === "smallCar" && <SmallCar iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "limousine" && <Limousine iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "suv" && <SUV iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "smallTransporter" && <SmallTransporter iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "bigTransporter" && <BigTransporter iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "boxTransporter" && <BoxTransporter iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "truck" && <Truck iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "forklift" && <ForkLift iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "excavator" && <Bagger iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "tractor" && <Tractor iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "trailer" && <Trailer iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "transportTruck" && <TransportTruck iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "transportLift" && <TransportLift iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                    { props.car === "flatbedTruck" && <FlatbedTruck iref={carRef} carPos={props.carPos || [0, 0, 0]} /> }
                </Suspense>
            </Canvas>

            <Crosshair style={{ display: props.addCircle ? 'flex' : 'none' }} />
        </div>
    )
}

/*useGLTF.preload('smallCar.glb')
useGLTF.preload('limousine.glb')
useGLTF.preload('suv.glb')
useGLTF.preload('smallTransporter.glb')
useGLTF.preload('bigTransporter.glb')
useGLTF.preload('boxTransporter.glb')
useGLTF.preload('Truck.glb')
useGLTF.preload('forkLift.glb')
useGLTF.preload('bagger.glb')
useGLTF.preload('tractor.glb')
useGLTF.preload('anhaenger.glb')
useGLTF.preload('transportTruck.glb')
useGLTF.preload('transportLift.glb')
useGLTF.preload('flatbedTruck.glb')*/
