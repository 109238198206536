import styled from "styled-components"

export default styled.h2`
  font-weight: normal;
  grid-column-start: ${props => props.columnStart || 1};
  grid-column-end: ${props => props.columnEnd || 6};
  grid-row-start: ${props => props.rowStart || 1};
  grid-row-end: ${props => props.rowEnd || 1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  
  &:first-child {
    margin-top: 0;
  }
  
  @media screen and (max-width: 1919px) {
    &.overview {
      grid-row-start: 2;
      grid-row-end: 3;
    }
    
    &.calendar {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 5;
      grid-row-end: 6;
    }
    
    &.duedates {
      grid-row-start: 7;
      grid-row-end: 8;
      grid-column-start: 1;
      grid-column-end: 6;
    }
    
    &.news {
        grid-column-start: 1;
        grid-column-end: 6;
        grid-row-start: 11;
        grid-row-end: 12;
    }
    
    &.users {
      grid-row-start: 9;
      grid-row-end: 10;
      grid-column-start: 1;
      grid-column-end: 6;
    }
  }
`
