import React, { Component } from 'react'
import { Button, Modal, Input } from 'scanmetrix-components'
import i18next from "i18next";

export default class extends Component {
    render() {
        return <Modal maxWidth="768px" instance={this.props.instance}>
            <Modal.Head title={i18next.t("page.private.profile.socialMediaModal.title")} icon="link" />
            <Modal.Body padding={24}>
                <Input label={i18next.t("page.private.profile.socialMediaModal.url")} icon='link' adjustWidth='100%' />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button thick title={i18next.t("page.private.profile.socialMediaModal.exitButton")} icon="link" onClick={() => {
                instance.close()
            }} />} />
        </Modal>
    }
}