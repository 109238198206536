import React, { Component } from "react"
import Checklist from "./styled/Checklist"
import NoChecklistTitle from "./styled/NoChecklistTitle"
import ChecklistItem from "./styled/ChecklistItem"
import { Input, Switch } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { data: {} }

    constructor(props) {
        super(props)

        if(props.data) this.state.data = props.data

        this.save = this.save.bind(this)
    }

    save() {
        const saveAll = () => {
            this.props.save(this.state.data)
        }

        if(this.saveTimeout) clearTimeout(this.saveTimeout)

        this.saveTimeout = setTimeout(() => {
            saveAll()
            this.saveTimeout = null
        }, 300)
    }

    render() {
        const data = this.state.data

        if(!this.props.checklist) return <React.Fragment>
            <NoChecklistTitle><i className="far fa-exclamation-circle" />{i18next.t("page.private.maintenance.checkListForm.noCheckpoints")}</NoChecklistTitle>
        </React.Fragment>

        return <Checklist>
            {this.props.checklist.map((checklistItem, index) => {
                const dataSet = data[checklistItem.uuid] || {}

                if(dataSet.value === null) return null

                return <ChecklistItem key={index}>
                    <div className="header">
                        <div className="left">
                            <div className="info">
                                <p className={checklistItem.title ? "name title" : "name"}>{checklistItem.name}</p>
                                {checklistItem.description && <p className="description">{checklistItem.description}</p>}
                            </div>
                        </div>
                        {!checklistItem.title && <div className="right">
                            {checklistItem.required && <i className="far fa-exclamation-circle required" style={{ marginRight: 16 }} />}
                            <Switch value={dataSet.value} disabled />
                        </div>}
                    </div>
                    {checklistItem.inputs && !!checklistItem.inputs.length && <div className="inputs">
                        <div style={{ display: "grid", gap: 8, gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                            {checklistItem.inputs.map((input, index) => {
                                const dataSetInputs = dataSet.inputs
                                const inputValue = dataSetInputs ? (dataSetInputs[input.uuid] || null) : null

                                return <div key={index}>
                                    <Input readOnly value={inputValue} labelIcon={input.required ? "exclamation-circle" : null} width="100%" label={input.name} icon={input.icon} format={value => {
                                        if(input.format && input.format.length && (value || value === 0)) {
                                            return input.format.replace(/{value}/g, value)
                                        }

                                        return value
                                    }} />
                                </div>
                            })}
                        </div>
                    </div>}
                </ChecklistItem>
            })}
        </Checklist>
    }
}
