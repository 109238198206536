import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { id: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={id => {
            this.setState({ id, loading: false })
        }}>
            <Modal.Head title={i18next.t("page.private.workFlows.work-Flows.comment.title")} icon="trash" />
            <Modal.Body padding={24}>
                {i18next.t("page.private.workFlows.work-Flows.comment.description")}
                {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.workFlows.work-Flows.comment.button")} icon="trash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            deleteWorkflow(id: $id)
                        }
                    `,
                    variables: {
                        id: this.state.id
                    }
                }).then(result => {
                    if(result.data.deleteWorkflow) {
                        this.props.refresh()
                        instance.close()
                    } else {
                        this.setState({ loading: false })
                        this.setState({ error: "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut." })
                    }
                })}
            } />} />
        </Modal>
    }
}
