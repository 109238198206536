import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"

export default class extends Component {
    state = { loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={carInsuranceId => this.setState({ carInsuranceId, loading: false })}>
            <Modal.Head title="Versicherung löschen" icon="trash" />
            <Modal.Body padding={24}>
                Sind Sie sicher, dass Sie diese Versicherung löschen möchten? Diese Aktion ist nicht widerrufbar.
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title="Versicherung löschen" icon="trash" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            deleteCarInsurance(id: $id)
                        }
                    `,
                    variables: {
                        id: this.state.carInsuranceId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.deleteCarInsurance) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
