import React, { Component } from "react"
import { Modal } from "scanmetrix-components"
import { FileManager } from "../../../documents"
import i18next from "i18next";

export default class extends Component {
    state = { tenantUtilitiesStatementId: null, contractPath: null }

    render() {
        return <Modal minWidth="1024px" instance={this.props.instance} initialize={tenantUtilitiesStatementId => this.setState({ tenantUtilitiesStatementId, contractPath: null })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.styled.tenantUtilitiesStatementDocumentsModal.title")} icon="folder-tree" />
            <Modal.Body>
                <FileManager tenantUtilitiesStatementId={this.state.tenantUtilitiesStatementId} title={i18next.t("page.private.subsidiary.styled.tenantUtilitiesStatementDocumentsModal.documents")} icon="file-certificate" scope="tenantUtilitiesStatement" onPathChange={contractPath => this.setState({ contractPath })} />
            </Modal.Body>
        </Modal>
    }
}
