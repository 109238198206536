import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { subsidiaryFactSheetId: null, loading: false }

    render() {
        return <Modal instance={this.props.instance} initialize={subsidiaryFactSheetId => this.setState({ subsidiaryFactSheetId, loading: false })}>
            <Modal.Head title={i18next.t("page.private.subsidiary.applyWorkSectionsModal.title")} icon="list-check" />
            <Modal.Body padding={24}>
                <Modal.Info maxWidth="500px">
                    {i18next.t("page.private.subsidiary.applyWorkSectionsModal.description")}
                </Modal.Info>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title={i18next.t("page.private.subsidiary.applyWorkSectionsModal.button")} icon="list-check" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($subsidiaryId: ID!) {
                            applyWorkSections(subsidiaryId: $subsidiaryId)
                        }
                    `,
                    variables: {
                        subsidiaryId: this.props.subsidiaryId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.applyWorkSections) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}
