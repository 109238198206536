import styled from 'styled-components'

export default styled.div`
  width: 100%;
  margin-bottom: 32px;
  padding: 32px;
  background: white;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
  box-sizing: border-box;
  user-select: none;

  .header-left {
    width: 50%;
    display: flex;
    padding-right: 32px;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;

    .avatar {
      border-radius: 100%;
    }

    .text-view {
      width: 350px;
      margin-left: 32px;
      position: relative;

      h1 {
        font-size: 1.6em;
        margin-bottom: 8px;
      }

      .position {
        margin-bottom: 16px;
      }
    }
  }

  .header-right {
    width: 50%;
    height: 100%;
    padding-left: 32px;
    border-left: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info-view {
      width: 100%;
      display: flex;
      flex-direction: column;

      .row {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: flex-start;

        .label {
          margin-bottom: 5px;
          
          p {
            font-weight: bold;
            margin-right: 8px;
          }
        }

        .input {
          width: 100%;
        }
      }
    }

    .social-media {
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        margin-right: 32px;
        color: #3B97D3;
        font-size: 1.6em;
        cursor: pointer;
        transition: all 250ms ease;
        pointer-events: auto;

        &.disabled {
          filter: grayscale(100%);
          opacity: 0.75;
          cursor: default;
          pointer-events: none;
        }

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
`
