import React, { Component } from "react"
import { Document, Page as PDFPage } from "react-pdf"
import { MoonLoader as LoadingAnimation } from "react-spinners"
import { Modal } from "scanmetrix-components"
import styled from "styled-components"

const StyledDocument = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.loading ? "48px 0" : 0};

    .react-pdf__Document {
        cursor: pointer;
        user-select: none;
        user-drag: none;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.75;
        }
    }
`

const LoadingContainer = styled.div`
    padding: 64px 0;
`

class Loading extends Component {
    render() {
        return <LoadingContainer>
            <LoadingAnimation sizeUnit="px" size={64} color="#3b97d3" />
        </LoadingContainer>
    }
}

export default class extends Component {
    render() {
        const url = scanmetrix.backendURL + "/buildinginspection-template/pdf-preview/" + this.props.buildingInspectionTemplateId

        return <Modal instance={this.props.instance}>
            <Modal.Head title="PDF-Ansicht" icon="file-pdf" />
            <Modal.Body>
                <StyledDocument>
                    <Document options={{ withCredentials: true }} onClick={() => window.open(url, "_blank")} onLoadSuccess={() => this.setState({ loading: false })} loading={<Loading />} file={url}>
                        <PDFPage pageNumber={1}></PDFPage>
                    </Document>
                </StyledDocument>
            </Modal.Body>
        </Modal>
    }
}
