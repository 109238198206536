import React, { Component } from "react"
import { Modal, Button, Input, TextEditor, Checkbox } from "scanmetrix-components"
import countries from "i18n-iso-countries";
import i18next from "i18next";
import isEmail from "validator/lib/isEmail";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = { selectedStandardFiles: [], selectedFiles: [], files: [], standardFiles: [], selectedOffers: [], fee: 0, email: null, title: null, text: null, loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    StandardFiles {
                        nodes {
                            id
                            name
                        }
                    }
                    Files(filter: { measureId_eq: $id }) {
                        nodes {
                            id
                            name
                            path
                        }
                    }
                }
            `,
            variables: {
                id: this.props.measure.id
            }
        }).then(data => {
            this.setState({ files: data.data.Files.nodes, standardFiles: data.data.StandardFiles.nodes })
        })
    }

    render() {
        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ selectedOffers: [], fee: 0, email: null, loading: false, title: `${i18next.t("page.private.measure.createMeasureApprovalModal.text1")} ${this.props.measure.number}`, text: `<p>${i18next.t("page.private.measure.createMeasureApprovalModal.text2")}<br /><br />${i18next.t("page.private.measure.createMeasureApprovalModal.text3")}<br /><br />${i18next.t("page.private.measure.createMeasureApprovalModal.text4")}<br /><br /><u>${i18next.t("page.private.measure.createMeasureApprovalModal.text5")}</u><br />${this.props.measure.subsidiary.legalName}<br />${this.props.measure.subsidiary.label ? (this.props.measure.subsidiary.label + "<br />") : ""}${this.props.measure.subsidiary.address.additional ? (this.props.measure.subsidiary.address.additional + "<br />") : ""}${this.props.measure.subsidiary.address.streetName} ${this.props.measure.subsidiary.address.streetNumber}<br />${this.props.measure.subsidiary.address.postalCode} ${this.props.measure.subsidiary.address.city}<br />${formattedCountries[this.props.measure.subsidiary.address.country] || this.props.measure.subsidiary.address.country}<br /><br /><br />${i18next.t("page.private.measure.createMeasureApprovalModal.text6")}<br /><br />${scanmetrix.session.firstName} ${scanmetrix.session.lastName}<br />${scanmetrix.session.email}<br />${this.props.measure.subsidiary.legalName}</p>` })
        }}>
            <Modal.Head title={i18next.t("page.private.measure.createMeasureApprovalModal.title")} icon="envelope" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 24 }}>
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 24 }}>
                        <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.createMeasureApprovalModal.email")} icon="envelope" placeholder={i18next.t("page.private.measure.createMeasureApprovalModal.searchPlaceholderMustermann")} value={this.state.email} onChange={email => this.setState({ email })} required />
                        <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.createMeasureApprovalModal.subject")} icon="heading" placeholder={i18next.t("page.private.measure.createMeasureApprovalModal.searchPlaceholderPo2023")} value={this.state.title} onChange={title => this.setState({ title })} required />
                        <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.createMeasureApprovalModal.serviceSurcharge")} icon="percent" placeholder="0 %" float format={value => `${value} %`} value={this.state.fee} onChange={fee => this.setState({ fee: fee === null ? 0 : fee })} required />
                        <div style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
                            <TextEditor
                                readOnly={this.state.loading}
                                label={i18next.t("page.private.measure.createMeasureApprovalModal.Receiver")}
                                icon="font"
                                placeholder={i18next.t("page.private.measure.createMeasureApprovalModal.searchPlaceholderGreetings")}
                                value={this.state.text}
                                height={500}
                                onChange={text => this.setState({ text })}
                            />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16, height: "100%" }}>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAwaitingRelease")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "unedited").map((offer, index) => <Checkbox key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAwaitingApproval")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "approval").map((offer, index) => <Checkbox key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureOfferRequestModal.standardDocumentAttachment")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.state.standardFiles.map((standardFile, index) => <Checkbox key={index} label={standardFile.name} value={this.state.selectedStandardFiles.includes(standardFile.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedStandardFiles
                                        selected.push(standardFile.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedStandardFiles: selected })
                                    } else {
                                        let selected = this.state.selectedStandardFiles.filter(id => id !== standardFile.id)
                                        this.setState({ selectedStandardFiles: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureOfferRequestModal.standardAttachment")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.state.files.map((file, index) => <Checkbox key={index} label={file.path + file.name} value={this.state.selectedFiles.includes(file.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedFiles
                                        selected.push(file.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedFiles: selected })
                                    } else {
                                        let selected = this.state.selectedStandardFiles.filter(id => id !== file.id)
                                        this.setState({ selectedFiles: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAlreadyApproved")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "accepted").map((offer, index) => <Checkbox key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAlreadyRejected")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "declined").map((offer, index) => <Checkbox key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAlreadyCommissioned")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "purchased").map((offer, index) => <Checkbox key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={!this.state.email || this.state.selectedOffers.length === 0 || !this.state.text || !this.state.title || !isEmail(this.state.email)} loading={this.state.loading} primary thick title={i18next.t("page.private.measure.createMeasureApprovalModal.exitButton")} icon="envelope" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($measureId: ID!, $email: String!, $title: String!, $text: String!, $fee: Float!, $offers: [ID!], $files: [ID!], $standardFiles: [ID!]) {
                            createMeasureApproval(measureId: $measureId, email: $email, title: $title, text: $text, fee: $fee, offers: $offers, files: $files, standardFiles: $standardFiles)
                        }
                    `,
                    variables: {
                        measureId: this.props.measure.id,
                        email: this.state.email,
                        title: this.state.title,
                        text: this.state.text,
                        fee: this.state.fee,
                        offers: this.state.selectedOffers,
                        files: this.state.selectedFiles,
                        standardFiles: this.state.selectedStandardFiles
                    }
                }).then(result => {
                    if(result.data.createMeasureApproval) {
                        this.props.refresh().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                })}
            } />} />
        </Modal>
    }
}
