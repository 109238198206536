import React, { Component } from "react"
import { Page, Section, Breadcrumbs, Input, Grid, GridElement, Select, Button, Table } from "scanmetrix-components"
import countries from "i18n-iso-countries"
import DeleteCustodianModal from "./modals/DeleteCustodianModal"
import i18next from "i18next";
import CreateContactModal from "./modals/CreateContactModal"
import UpdateContactModal from "./modals/UpdateContactModal"

countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

export default class extends Component {
    state = {
        custodian: null,
        subsidiaries: null,
        contacts: null
    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetchContacts = this.fetchContacts.bind(this)

        this.fetch()
        this.fetchContacts()
    }

    fetchContacts() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    CustodianContacts(filter: { custodianId_eq: $id }) {
                        nodes {
                            id
                            email
                            telephone
                            responsibility
                            salutation
                            firstName
                            lastName
                            type
                            mobileTelephone
                            telefax
                            company
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.custodianid
            }
        }).then(data => {
            this.setState({ contacts: data.data.CustodianContacts.nodes })
        })
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    Custodian(id: $id) {
                        id
                        type
                        name
                        notes
                        address {
                            streetName
                            streetNumber
                            additional
                            postalCode
                            city
                            country
                        }
                    }
                    landlord: Subsidiaries(filter: { landlordCustodianId_eq: $id }) {
                        nodes {
                            id
                            name
                            label
                            legalName
                            subsidiaryType {
                                color
                                name
                            }
                            address {
                                streetName
                                streetNumber
                                city
                                postalCode
                            }
                        }
                    }
                    administrative: Subsidiaries(filter: { administrativeCustodianId_eq: $id }) {
                        nodes {
                            id
                            name
                            label
                            legalName
                            subsidiaryType {
                                color
                                name
                            }
                            address {
                                streetName
                                streetNumber
                                city
                                postalCode
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.custodianid
            }
        }).then(data => {
            let subsidiaries = data.data.landlord.nodes.map(s => ({ ...s, function: "landlord" }))

            data.data.administrative.nodes.forEach(a => {
                if(!subsidiaries.find(s => s.id === a.id)) subsidiaries.push({ ...a, function: "administrative" })
                else a.function = "both"
            })

            this.setState({ custodian: data.data.Custodian, subsidiaries })
        })
    }

    render() {
        if(!this.state.custodian) return null
        const custodian = this.state.custodian

        const formattedCountries = countries.getNames("de", { select: "official" })

        return <Page {...this.props}>
            <DeleteCustodianModal id={custodian.id} history={this.props.history} instance={ref => this.deleteCustodianModal = ref} />
            <CreateContactModal custodian={custodian} instance={ref => this.createContactModal = ref} refresh={() => this.fetchContacts()} />
            <UpdateContactModal instance={ref => this.updateContactModal = ref} refresh={() => this.fetchContacts()} />
            <Breadcrumbs values={[
                {
                    icon: "building",
                    title: i18next.t("page.private.custodian.breadCrumbsFacilityManagement"),
                    link: "/facilitymanagement"
                },
                {
                    icon: "house-user",
                    title: i18next.t("page.private.custodian.breadCrumbsLandlord")
                },
                {
                    title: custodian.name
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.custodian.landlord.sectionTitle")} icon="house-user">
                    <Input.MutationProvider value={{
                        name: "updateCustodian",
                        id: custodian.id,
                        data: custodian
                    }}>
                        <Grid columns={[ "1fr", "1fr" ]} gap="32px">
                            <GridElement styled title={i18next.t("page.private.custodian.contactPerson.sectionTitle")} icon="cog" columnStart={1} columnEnd={2} rightContent={<Button secondary title={i18next.t("page.private.custodian.landlord.generalInformation.deleteButton")} thick icon="trash" onClick={() => this.deleteCustodianModal.open()} />}>
                                <Grid gap="32px" padding="24px" columns={[ "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input field="name" adjustWidth label={i18next.t("page.private.custodian.landlord.generalInformation.name")} icon="tag" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Select noUnselect field="type" adjustWidth label={i18next.t("page.private.custodian.landlord.generalInformation.type")} icon="house" items={[
                                            { key: "landlord", title: i18next.t("page.private.custodian.landlord.generalInformation.typeTypes.landlord") },
                                            { key: "administrative", title: i18next.t("page.private.custodian.landlord.generalInformation.typeTypes.propertyManager") }
                                        ]} />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input field="notes" adjustWidth label={i18next.t("page.private.custodian.landlord.generalInformation.notes")} icon="sticky-note" />
                                    </GridElement>
                                </Grid>
                            </GridElement>
                            <GridElement styled title={i18next.t("page.private.custodian.landlord.address.gridElementTitle")} icon="map-marker" columnStart={2} columnEnd={3}>
                                <Grid gap="32px" padding="24px" columns={[ "1fr", "1fr", 
                                "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input field="address.streetName" adjustWidth label={i18next.t("page.private.custodian.landlord.address.street")} icon="road" />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <Input field="address.streetNumber" adjustWidth label={i18next.t("page.private.custodian.landlord.address.houseNumber")} icon="bell" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={4}>
                                        <Input field="address.additional" adjustWidth label={i18next.t("page.private.custodian.landlord.address.additionalAddress")} icon="font" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input field="address.postalCode" adjustWidth label={i18next.t("page.private.custodian.landlord.address.postalCode")} icon="mailbox" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={4}>
                                        <Input field="address.city" adjustWidth label={i18next.t("page.private.custodian.landlord.address.city")} icon="city" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={4}>
                                        <Select noUnselect field="address.country" upwards adjustWidth icon="globe-europe" label={i18next.t("page.private.custodian.landlord.address.country")} items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
                                    </GridElement>
                                </Grid>
                            </GridElement>
                        </Grid>
                    </Input.MutationProvider>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.custodian.contactPerson.sectionTitle")} icon="address-book" amount={this.state.contacts ? this.state.contacts.length : 0}>
                    <GridElement styled title={i18next.t("page.private.custodian.contactPerson.contactPersonArea.gridElementTitle")} icon="address-book" rightContent={<Button thick title={i18next.t("page.private.custodian.contactPerson.contactPersonArea.deleteButton")} icon="address-book" onClick={() => this.createContactModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.custodian.contactPerson.contactPersonArea.table.salutation"), width: "10%", column: "salutationFormatted" },
                                { title: i18next.t("page.private.custodian.contactPerson.contactPersonArea.table.name"), width: "15%", column: "name" },
                                { title: i18next.t("page.private.custodian.contactPerson.contactPersonArea.table.email"), width: "15%", column: "email" },
                                { title: i18next.t("page.private.custodian.contactPerson.contactPersonArea.table.telephone"), width: "15%", column: "telephone" },
                                { title: i18next.t("page.private.custodian.contactPerson.contactPersonArea.table.type"), width: "15%", column: "type" },
                                { title: i18next.t("page.private.custodian.contactPerson.contactPersonArea.table.company"), width: "15%", column: "company" },
                                { title: i18next.t("page.private.custodian.contactPerson.contactPersonArea.table.responsibility"), width: "15%", column: "responsibility" }
                            ]}
                            onItemClick={contact => this.updateContactModal.open(contact)}
                            items={this.state.contacts ? this.state.contacts.map(contact => ({
                                id: contact.id,
                                firstName: contact.firstName,
                                lastName: contact.lastName,
                                name: `${contact.firstName ? (contact.firstName + " ") : ""}${contact.lastName}`,
                                salutation: contact.salutation,
                                salutationFormatted: i18next.t(`defaults.salutation.${contact.salutation}`),
                                email: contact.email,
                                telephone: contact.telephone,
                                mobileTelephone: contact.mobileTelephone,
                                type: contact.type,
                                company: contact.company,
                                telefax: contact.telefax,
                                responsibility: contact.responsibility
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.custodian.branches.sectionTitle")} icon="map-marker" amount={this.state.subsidiaries ? this.state.subsidiaries.length : 0}>
                    <GridElement styled title={i18next.t("page.private.custodian.branches.branchesArea.gridElementTitle")} icon="map-marker">
                        <Table
                            head={[
                                { title: i18next.t("page.private.custodian.branches.branchesArea.table.name"), width: "15%", column: "name" },
                                { title: i18next.t("page.private.custodian.branches.branchesArea.table.label"), width: "10%", column: "label" },
                                { title: i18next.t("page.private.custodian.branches.branchesArea.table.legalName"), width: "22.5%", column: "legalName" },
                                { title: i18next.t("page.private.custodian.branches.branchesArea.table.street"), width: "20%", column: "street" },
                                { title: i18next.t("page.private.custodian.branches.branchesArea.table.postalCode"), width: "7.5%", column: "postalCode" },
                                { title: i18next.t("page.private.custodian.branches.branchesArea.table.city"), width: "15%", column: "city" },
                                { title: i18next.t("page.private.custodian.branches.branchesArea.table.responsibility"), width: "10%", column: "function" }
                            ]}
                            onItemClick={subsidiary => this.props.history.push(`/subsidiary/${subsidiary.id}`)}
                            items={this.state.subsidiaries ? this.state.subsidiaries.map(subsidiary => {
                                return ({
                                    id: subsidiary.id,
                                    label: subsidiary.label,
                                    name: subsidiary.name,
                                    function: subsidiary.function === "landlord" ? i18next.t("page.private.custodian.branches.contactPersonArea.Landlord") : (subsidiary.function === "administrative" ? i18next.t("page.private.custodian.branches.contactPersonArea.Manager") : i18next.t("page.private.custodian.branches.contactPersonArea.Landlord/Manager")),
                                    legalName: subsidiary.legalName,
                                    street: `${subsidiary.address.streetName} ${subsidiary.address.streetNumber}`,
                                    postalCode: subsidiary.address.postalCode,
                                    city: subsidiary.address.city,
                                    type: subsidiary.subsidiaryType ? <b style={{ color: subsidiary.subsidiaryType.color }}>{subsidiary.subsidiaryType.name}</b> : null
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>{/* TODO
                <Section.Item title="Niederlassungen" icon="map-marker">

                </Section.Item>
                <Section.Item title="Verträge" icon="file-contract">

                </Section.Item>*/}
            </Section>
        </Page>
    }
}
