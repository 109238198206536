import React, { Component } from "react"
import { Page, Section, Breadcrumbs, Table, GridElement, Grid, Input, Modal, Button, Checkbox, Select } from "scanmetrix-components"
import { getIntervalType } from "../object/"
import styled from "styled-components"
import * as THREE from "three"
import { Canvas } from "react-three-fiber"
import { OrbitControls, Box, Text } from "@react-three/drei"

import Cases from './styled/Cases'
import Counters from './styled/Counters'
import RatingView from './styled/RatingView'
import UpdateSubsidiaryModal from "./styled/modals/UpdateSubsidiaryModal"
import CreateContactModal from "./styled/modals/CreateContactModal"
import CreateTenantModal from "./styled/modals/CreateTenantModal"
import i18next from "i18next"
import UpdateContactModal from "./styled/modals/UpdateContactModal"
import DeleteSubsidiaryModal from "./styled/modals/DeleteSubsidiaryModal"
import DeleteTenantModal from "./styled/modals/DeleteTenantModal"
import NewFacilityModal from "./styled/modals/NewFacilityModal"
import ApplyWorkSectionsModal from "./modals/ApplyWorkSectionsModal"
import {FileManager} from "../documents"

import {Responsive, WidthProvider} from "react-grid-layout";
const ResponsiveGridLayout = WidthProvider(Responsive)

import countries from "i18n-iso-countries"

import Avatar from "react-avatar";
import CreateWorkflowExecutionModal from "./modals/CreateWorkflowExecutionModal";
import DeleteWorkflowExecutionModal from "../workflows/modals/DeleteWorkflowExecutionModal";
import CreateUtilitiesStatementOverheadModal from "./styled/modals/CreateUtilitiesStatementOverheadModal";
import CreateUtilitiesStatementEffluentModal from "./styled/modals/CreateUtilitiesStatementEffluentModal";
import DeleteUtilitiesStatement from "./styled/modals/DeleteUtilitiesStatement";
import CreateSubsidiaryTenantUtilitiesStatementModal from "./styled/modals/CreateSubsidiaryTenantUtilitiesStatementModal";
import DeleteSubsidiaryTenantUtilitiesStatement from "./styled/modals/DeleteSubsidiaryTenantUtilitiesStatement";
import CreateSubsidiaryFactSheetModal from "./modals/CreateSubsidiaryFactSheetModal";
import DeleteSubsidiaryFactSheetModal from "./modals/DeleteSubsidiaryFactSheetModal";
//import { OrderGrid } from "../workOrders";
import ContractProvisionIntervals from "../contracts/tabs/ContractProvisionIntervals";
import { contractTypes } from "../dashboard/propertyManagement"
import UtilitiesStatementDocumentsModal from "./styled/modals/UtilitiesStatementDocumentsModal";
import TenantUtilitiesStatementDocumentsModal from "./styled/modals/TenantUtilitiesStatementDocumentsModal";
import {getStatus, getType} from "../workOrders";
import {getChannelAsTag, getPriorityAsTag, getTypeAsTag, getStatusAsTag} from "../tickets";
import moment from "moment";
import UpdateTicketModal from "../tickets/modals/UpdateTicketModal";
import CreateRoomModal from "./modals/CreateRoomModal";
import UpdateRoomModal from "./modals/UpdateRoomModal";
import DeleteRoomModal from "./modals/DeleteRoomModal";
countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

const Status = styled.div`
  position: absolute;
  left: -16px;
  width: 32px;
  height: 32px;
  background: ${props => props.yellow ? "#e67e22" : (!!props.status ? "#27ae60" : (props.status === false ? "#c0392b" : "#2c3e50"))};
  border-radius: 32px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 16px);
  font-size: 0.9em;
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);
  
  i {
      margin: 0 !important;
  }
`

const MaxWidthView = styled.div`
  width: 100%;
  
  @media screen and (max-width: 1280px) {
    display: none;
  }
`

const ResponsiveView = styled.div`
  width: 100%;
  
  @media screen and (min-width: 1281px) {
    display: none;
  }
`

const ObjectMarker = props => {
    const marker = props.marker
    const factor = props.factor

    let plane = null

    if(marker.pictogram) {
        let texture = new THREE.TextureLoader().load(marker.pictogram)
        texture.wrapS = THREE.RepeatWrapping
        texture.wrapT = THREE.RepeatWrapping

        let material = new THREE.MeshLambertMaterial({ map: texture })

        plane = new THREE.Mesh(new THREE.PlaneGeometry(3, 3), material)
        plane.material.side = THREE.DoubleSide
        plane.frustumCulled = false
        plane.receiveShadow = true

        plane.rotation.x = -Math.PI / 2
    }

    return <>
        <Box onClick={() => {
            props.history.push("/object/" + props.customerSubsidiaryId + "/" + marker.customerObject.id)
        }} receiveShadow castShadow position={[-props.dimensions.width * factor * 0.5 + (props.dimensions.width * factor * marker.planX), -44, -props.dimensions.height * factor * 0.5 + 15 + (props.dimensions.height * factor * marker.planY)]} args={[4, 2, 4]}>
            <meshStandardMaterial attach="material" color={marker.customerObject.discarded ? "gray" : (marker.customerObject.operative ? (marker.customerObject.hasFlaw ? "orange" : "green") : "red")} />
        </Box>
        <Text anchorX="center" fontSize={2} color="black" position={[-props.dimensions.width * factor * 0.5 + (props.dimensions.width * factor * marker.planX), -39, -props.dimensions.height * factor * 0.5 + 10 + (props.dimensions.height * factor * marker.planY)]}>
            {marker.customerObject.smid.id}
        </Text>
        {plane && <primitive object={plane} position={[-props.dimensions.width * factor * 0.5 + (props.dimensions.width * factor * marker.planX), -42.99, -props.dimensions.height * factor * 0.5 + 15 + (props.dimensions.height * factor * marker.planY)]} />}
    </>
}

class PlanModal extends Component {
    state = { dimensions: null }

    render() {
        return <Modal instance={this.props.instance}>
            {context => {
                if(!context.imageData) return null
                if(!this.loading) {
                    const img = new Image()
                    img.src = context.imageData

                    img.onload = () => {
                         this.setState({ dimensions: { width: img.width, height: img.height } })
                    }

                    this.loading = true
                }


                if(!this.state.dimensions) return null

                //let texture = THREE.ImageUtils.loadTexture(context.imageData)
                let texture = new THREE.TextureLoader().load(context.imageData)
                texture.wrapS = THREE.RepeatWrapping
                texture.wrapT = THREE.RepeatWrapping

                let material = new THREE.MeshLambertMaterial({ map: texture })

                const factor = 0.1

                let plane = new THREE.Mesh(new THREE.PlaneGeometry(this.state.dimensions.width * factor, this.state.dimensions.height * factor), material)
                plane.material.side = THREE.DoubleSide
                plane.frustumCulled = false
                plane.receiveShadow = true

                plane.rotation.x = -Math.PI / 2

                var spotLight = new THREE.SpotLight( 0xffffff, 1.5 );
                spotLight.position.set( 0, 150, 0 );

                spotLight.castShadow = true;

                spotLight.shadow.mapSize.width = 1024;
                spotLight.shadow.mapSize.height = 1024;

                spotLight.shadow.mapSize.width = 5120 // default
                spotLight.shadow.mapSize.height = 5120 // default
                spotLight.shadow.camera.near = 0.1 // default
                spotLight.shadow.camera.far = 500 // default
                spotLight.shadow.camera.top = -100 // default
                spotLight.shadow.camera.right = 100 // default
                spotLight.shadow.camera.left = -100 // default
                spotLight.shadow.camera.bottom = 100 // default

                return <>
                    <Modal.Head title={i18next.t("page.private.subsidiary.plan")} icon="layer-group" />
                    <Modal.Body>
                        <Canvas style={{ width: 1024, height: 768, outline: 0, background: "radial-gradient(#ffffff, #d9f2ff, #5fa7f6)" }} onCreated={({ gl, scene }) => {
                            gl.shadowMap.enabled = true
                            gl.shadowMap.type = THREE.PCFSoftShadowMap
                        }}>
                            <ambientLight intensity={0.2} />
                            <primitive object={spotLight} />
                            {context.markers.map((marker, index) => <ObjectMarker customerSubsidiaryId={this.props.customerSubsidiary.id} history={this.props.history} factor={factor} dimensions={this.state.dimensions} key={index} marker={marker} />)}
                            <primitive object={plane} position={[0, -45, 0]} />
                            <OrbitControls target={new THREE.Vector3(-this.state.dimensions.width * factor * 0.5 + (this.state.dimensions.width * factor * 0.5), -45,  -this.state.dimensions.height * factor * 0.5 + -40 + (this.state.dimensions.height * factor * 0.5))} />
                        </Canvas>
                    </Modal.Body>
                </>
            }}
        </Modal>
    }
}

class SubsidiaryWorkSection extends Component {
    state = { facilitiesOpen: false, exempt: false }

    constructor(props) {
        super(props)

        this.state.exempt = props.exempts.length > 0
    }

    render() {
        const workSection = this.props.workSection
        const contracts = this.props.contracts
        const exempts = this.props.exempts

        return <GridElement styled title={workSection.name} icon={workSection.icon} rightContent={<div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
            {/*<Select adjustWidth label="Dienstleister" icon="user-tie" readOnly items={[
                { key: "unknown", title: "Unbekannt" },
                { key: "notYetGiven", title: "Noch nicht vorhanden" },
                { key: "notRequired", title: "Nicht notwendig" },
                { key: "given", title: "Vorhanden" }
            ]} />
            <Select adjustWidth label="(Instandhaltungs-)Vertrag" icon="file-contract" items={[
                { key: "unknown", title: "Unbekannt" },
                { key: "notYetGiven", title: "Noch nicht vorhanden" },
                { key: "notRequired", title: "Nicht notwendig" },
                { key: "given", title: "Vorhanden" }
            ]} />
            <Select noUnselect adjustWidth label="Gegebenheit" icon="building" items={[
                { key: "unknown", title: "Unbekannt", icon: "question" },
                { key: "notYetGiven", title: "Noch nicht vorhanden", icon: "hourglass" },
                { key: "notRequired", title: "Nicht notwendig", icon: "times" },
                { key: "given", title: "Vorhanden", icon: "check" }
            ]} />
            <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                <Button thick title="Anlagen anzeigen" icon="folder-tree" />
            </div>*/}
            {!this.state.exempt && contracts.length === 0 && <div style={{ color: "#e74c3c", fontWeight: "bold", display: "flex", alignItems: "center" }}><i className="far fa-exclamation-triangle" style={{ marginRight: 8 }} /> {i18next.t("page.private.subsidiary.noServiceContract")}</div>}
            <div>
                <Checkbox disabled={!!contracts.length || scanmetrix.checkPermission("Subsidiary") < 3} label={i18next.t("page.private.subsidiary.tradeAssignments.workSectionNotAvailable")} onChange={exempt => {
                    this.setState({ exempt })
                    scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                            mutation($subsidiaryId: ID!, $workSectionId: ID!, $exempt: Boolean!) {
                                setSubsidiaryWorkSectionExempt(subsidiaryId: $subsidiaryId, workSectionId: $workSectionId, exempt: $exempt)
                            }
                        `,
                        variables: {
                            subsidiaryId: this.props.subsidiaryId,
                            workSectionId: workSection.id,
                            exempt
                        }
                    }).then(() => this.props.refresh())
                }} value={this.state.exempt} />
            </div>
        </div>}>
        <div style={{ boxSizing: "border-box", width: "100%", padding: 16 }}>
            <Input.MutationProvider value={{
                data: { notes: this.props.note },
                customFunction: value => scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                            mutation($subsidiaryId: ID!, $workSectionId: ID!, $notes: String) {
                                setSubsidiaryWorkSectionNote(subsidiaryId: $subsidiaryId, workSectionId: $workSectionId, notes: $notes)
                            }
                        `,
                    variables: {
                        subsidiaryId: this.props.subsidiaryId,
                        workSectionId: workSection.id,
                        notes: value
                    }
                }).then(() => this.props.refreshNotes())
            }}>
                <Input field="notes" label={i18next.t("page.private.subsidiary.notes.sectionElementTitle")} icon="font" adjustWidth value={this.props.note} />
            </Input.MutationProvider>
        </div>
            {!!contracts.length && <Table
                noControls
                head={[
                    { title: i18next.t("page.private.subsidiary.notes.table.title"), width: "35%", column: "title" },
                    { title: i18next.t("page.private.subsidiary.notes.table.type"), width: "15%", column: "type" },
                    { title: i18next.t("page.private.subsidiary.notes.table.number"), width: "15%", column: "number" },
                    { title: i18next.t("page.private.subsidiary.notes.table.partnerName"), width: "25%", column: "partnerName" },
                    { title: i18next.t("page.private.subsidiary.notes.table.duration"), width: "10%", column: "duration" }
                ]}
                onItemClick={contract => this.props.history.push(`/contract/${contract.id}`)}
                items={contracts ? contracts.map(contract => ({
                    ...contract,
                    partnerName: contract.serviceProvider ? contract.serviceProvider.name : contract.partnerName,
                    type: contract.type ? (contractTypes.find(type => type.key === contract.type) ? contractTypes.find(type => type.key === contract.type).title : "Unbekannt") : "",
                    duration: contract.perpetual ? i18next.t("page.private.subsidiary.notes.unlimitedDuration") : `${contract.contractDurationAmount} ${i18next.t("defaults.durationType." + contract.contractDurationType)}`,
                })) : null}
            />}
        </GridElement>
    }
}

class SubsidiaryPage extends Component {/////// STATE HERE
    state = {
        customerSubsidiary: null,
        facilities: [],
        inspections: null,
        contacts: null,
        plans: null,
        hideDoneTickets: true,
        hideArchivedTickets: true,
        cases: [],
        contracts: [],
        types: [],
        statistics: null,
        utilitiesStatements: [],
        subsidiaryTenantUtilitiesStatements: [],
        tenants: null,
        spaces: [],
        tickets: [],
        factSheets: [],
        subsidiaryFactSheets: [],
        purchaseOrders: [],
        custodians: [],
        workflows: [],
        workSections: [],
        workflowExecutions: [],
        workSectionExempts: [],
        workSectionNotes: [],

    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetchContacts = this.fetchContacts.bind(this)
        this.fetchTickets = this.fetchTickets.bind(this)
        this.fetchTenants = this.fetchTenants.bind(this)
        this.fetchContracts = this.fetchContracts.bind(this)
        this.fetchWorkflowExecutions = this.fetchWorkflowExecutions.bind(this)
        this.fetchUtilitiesStatements = this.fetchUtilitiesStatements.bind(this)
        this.fetchFactSheets = this.fetchFactSheets.bind(this)
        this.fetchInspections = this.fetchInspections.bind(this)
        this.saveSubsidiaryFactSheet = this.saveSubsidiaryFactSheet.bind(this)
        this.fetchWorkSectionExempts = this.fetchWorkSectionExempts.bind(this)
        this.fetchWorkSectionNotes = this.fetchWorkSectionNotes.bind(this)
        this.fetchPurchaseOrders = this.fetchPurchaseOrders.bind(this)

        this.fetch()
        this.fetchTickets()
        this.fetchContacts()
        this.fetchTenants()
        this.fetchContracts()
        this.fetchWorkflowExecutions()
        this.fetchUtilitiesStatements()
        //this.fetchInspections()
        this.fetchFactSheets()
        this.fetchWorkSectionExempts()
        this.fetchWorkSectionNotes()
        this.fetchPurchaseOrders()

        fetch(scanmetrix.backendURL + "/facilities/" + this.props.match.params.subsidiaryid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(!data.error) this.setState({ facilities: data.facilities })
        })

        fetch(scanmetrix.backendURL + "/plans/" + this.props.match.params.subsidiaryid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(!data.error) this.setState({ plans: data.plans })
        })

        fetch(scanmetrix.backendURL + "/statistics/" + this.props.match.params.subsidiaryid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(!data.error) this.setState({ statistics: data })
        })
    }

    fetchTickets() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    TicketTypes {
                        nodes {
                            id
                            name
                            color
                            icon
                            active
                        }
                    }
                    Tickets(filter: { subsidiaryId_eq: $id }) {
                        nodes {
                            id
                            number
                            type {
                                id
                            }
                            status
                            title
                            priority
                            smid
                            index
                            archived
                            comments {
                                id
                            }
                            user {
                                id
                                email
                                firstName
                                lastName
                            }
                            subsidiary {
                                id
                                name
                                legalName
                                label
                                address {
                                    streetName
                                    streetNumber
                                    country
                                    additional
                                    postalCode
                                    city
                                }
                            }
                            channel
                            description
                            updatedAt
                            createdAt
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ tickets: data.data.Tickets.nodes, types: data.data.TicketTypes.nodes })
        })
    }

    fetchInspections() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    BuildingInspections(filter: { subsidiaryId_eq: $id }) {
                        nodes {
                            id
                            createdAt
                            status
                            tickets {
                                id
                            }
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    postalCode
                                    city
                                }
                            }
                            serviceDeskUser {
                                id
                                firstName
                                lastName
                                email
                            }
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            inspectionTemplate {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ inspections: data.data.BuildingInspections.nodes })
        })
    }


    fetchWorkSectionExempts() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    SubsidiaryWorkSectionExempts(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            workSection {
                                id
                            }
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ workSectionExempts: data.data.SubsidiaryWorkSectionExempts.nodes })
        })
    }

    fetchWorkSectionNotes() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    SubsidiaryWorkSectionNotes(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            notes
                            workSection {
                                id
                            }
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ workSectionNotes: data.data.SubsidiaryWorkSectionNotes.nodes })
        })
    }

    fetchUtilitiesStatements() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    UtilitiesStatements(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            user {
                                id
                            }
                            state
                            notes
                            year
                            value
                            date
                            type
                            aggregate
                            biller
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ utilitiesStatements: data.data.UtilitiesStatements.nodes })
        })
    }

    fetchContacts() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    SubsidiaryContacts(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            email
                            telephone
                            responsibility
                            salutation
                            firstName
                            lastName
                            type
                            mobileTelephone
                            telefax
                            company
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ contacts: data.data.SubsidiaryContacts.nodes })
        })
    }

    fetchTenants() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    SubsidiaryTenants(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            name
                            telephone
                            telefax
                            email
                            utilitiesStatements {
                                id
                                year
                                type
                                notes
                                date
                                state
                                value
                                subsidiaryTenant {
                                    id
                                }
                                user {
                                    id
                                }
                            }
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ tenants: data.data.SubsidiaryTenants.nodes, subsidiaryTenantUtilitiesStatements: data.data.SubsidiaryTenants.nodes.map(tenant => tenant.utilitiesStatements).flat() })
        })
    }

    fetchWorkflowExecutions() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    Workflows {
                        nodes {
                            id
                            name
                        }
                    }
                    WorkflowExecutions(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            contextType
                            createdAt
                            finishedAt
                            steps {
                                id
                                data
                            }
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            team {
                                id
                                name
                            }
                            subsidiary {
                                id
                                name
                                label
                                address {
                                    postalCode
                                    city
                                }
                            }
                            workflow {
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ workflows: data.data.Workflows.nodes, workflowExecutions: data.data.WorkflowExecutions.nodes })
        })
    }

    fetchPurchaseOrders() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    PurchaseOrders(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            title
                            number
                            status
                            date
                            type
                            positions
                            letterHead {
                                id
                                name
                            }
                            subsidiary {
                                id
                                name
                                label
                                locationLat
                                locationLng
                                address {
                                    postalCode
                                    city
                                }
                            }
                            serviceProvider {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ purchaseOrders: data.data.PurchaseOrders.nodes })
        })
    }

    fetchFactSheets() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    FactSheets {
                        nodes {
                            id
                            name
                            icon
                            elements
                        }
                    }
                    SubsidiaryFactSheets(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            data
                            factSheet {
                                id
                                name
                                icon
                                elements
                            }
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ factSheets: data.data.FactSheets.nodes, subsidiaryFactSheets: data.data.SubsidiaryFactSheets.nodes })
        })
    }

    fetchContracts() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    SubsidiaryContracts(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            contract {
                                id
                                beginsAt
                                endsAt
                                title
                                type
                                number
                                perpetual
                                automaticRenewal
                                partnerName
                                contractDurationAmount
                                contractDurationType
                                serviceProvider {
                                    id
                                    name
                                }
                                workSection {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                subsidiaryId: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ contracts: data.data.SubsidiaryContracts.nodes.map(item => item.contract) })
        })
    }

    fetch() {
        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    WorkSections(filter: { standardEquipment_eq: true }) {
                        nodes {
                            id
                            name
                            icon
                        }
                    }
                    Custodians {
                        nodes {
                            id
                            name
                            type
                            address {
                                inline
                            }
                        }
                    }
                    SubsidiaryTypes {
                        nodes {
                            id
                            name
                            color
                        }
                    }
                    Phases {
                        nodes {
                            id
                            name
                            color
                        }
                    }
                    Users {
                        nodes {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                    Subsidiary(id: $id) {
                        id
                        name
                        legalName
                        label
                        notes
                        contactTelephone
                        contactTelefax
                        contactEmail
                        responsibilityMaintenance
                        subsidiaryTransferable
                        specialty
                        securityDeposit
                        easementRentalContract
                        easementRegistered
                        handoverDate
                        openingDate
                        plotArea
                        mainTenantArea
                        adjacentArea
                        general
                        features
                        rentStartDate
                        rentPaymentStartDate
                        minimumRent
                        salesRent
                        rentNotes
                        rentIncrease
                        parkingSpaceRental
                        advertisingSpaceRental
                        operatingDevicesRental
                        advancePaymentServiceCharges
                        ancillaryCostsFlatRate
                        advancePaymentServiceChargesAll
                        ancillaryCostsFlatRateAll
                        utilitiesStatementNotes
                        utilitiesStatementDeadlineMonths
                        utilitiesStatementAllegation
                        utilitiesStatementBackPayBarred
                        districtManagerUser {
                            id
                        }
                        subsidiaryType {
                            id
                        }
                        phase {
                            id
                        }
                        landlordCustodian {
                            id
                        }
                        administrativeCustodian {
                            id
                        }
                        address {
                            streetName
                            streetNumber
                            additional
                            postalCode
                            city
                            country
                            inline
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.subsidiaryid
            }
        }).then(data => {
            this.setState({ workSections: data.data.WorkSections.nodes, users: data.data.Users.nodes, customerSubsidiary: data.data.Subsidiary, custodians: data.data.Custodians.nodes, subsidiaryTypes: data.data.SubsidiaryTypes.nodes, phases: data.data.Phases.nodes })
        })
    }

    saveSubsidiaryFactSheet(id) {
        if(!this.sfsTimeouts) this.sfsTimeouts = {}

        if(this.sfsTimeouts[id]) {
            clearTimeout(this.sfsTimeouts[id])
        }

        this.sfsTimeouts[id] = setTimeout(() => {
            this.sfsTimeouts[id] = null

            scanmetrix.client.mutate({
                mutation: scanmetrix.gql`
                    mutation($id: ID!, $data: JSON!) {
                        updateSubsidiaryFactSheet(id: $id, data: $data)
                    }
                `,
                variables: {
                    id,
                    data: this.state.subsidiaryFactSheets.find(sfs => sfs.id === id).data
                }
            })
        }, 300)
    }

    render() {
        if(!this.state.customerSubsidiary) return null
        const customerSubsidiary = this.state.customerSubsidiary

        const formattedCountries = countries.getNames("de", { select: "official" })

        console.log(this.state.tickets)

        return <>
            <PlanModal customerSubsidiary={customerSubsidiary} history={this.props.history} instance={planModal => this.planModal = planModal} />
            <UpdateTicketModal refresh={() => this.fetch()} instance={ref => this.updateTicketModal = ref} />
            <UpdateSubsidiaryModal instance={ref => this.updateSubsidiaryModal = ref} refresh={() => this.fetch()} />
            <CreateRoomModal subsidiaryId={customerSubsidiary.id} instance={ref => this.createRoomModal = ref} refresh={() => this.refreshRooms()} />
            <UpdateRoomModal instance={ref => this.updateRoomModal = ref} refresh={() => this.refreshRooms()} />
            <DeleteRoomModal instance={ref => this.deleteRoomModal = ref} refresh={() => this.refreshRooms()} />
            <CreateTenantModal subsidiary={customerSubsidiary} instance={ref => this.createTenantModal = ref} refresh={() => this.fetchTenants()} />
            <CreateUtilitiesStatementOverheadModal subsidiary={customerSubsidiary} instance={ref => this.createUtilitiesStatementOverheadModal = ref} refresh={() => this.fetchUtilitiesStatements()} />
            <CreateUtilitiesStatementEffluentModal subsidiary={customerSubsidiary} instance={ref => this.createUtilitiesStatementEffluentModal = ref} refresh={() => this.fetchUtilitiesStatements()} />
            <CreateSubsidiaryTenantUtilitiesStatementModal tenants={this.state.tenants || []} instance={ref => this.createSubsidiaryTenantUtilitiesStatementModal = ref} refresh={() => this.fetchTenants()} />
            <DeleteUtilitiesStatement instance={ref => this.deleteUtilitiesStatementModal = ref} refresh={() => this.fetchUtilitiesStatements()} />
            <DeleteSubsidiaryTenantUtilitiesStatement instance={ref => this.deleteSubsidiaryTenantUtilitiesStatementModal = ref} refresh={() => this.fetchTenants()} />
            <CreateContactModal subsidiary={customerSubsidiary} instance={ref => this.createContactModal = ref} refresh={() => this.fetchContacts()} />
            <UpdateContactModal instance={ref => this.updateContactModal = ref} refresh={() => this.fetchContacts()} />
            <DeleteSubsidiaryModal instance={ref => this.deleteSubsidiaryModal = ref} history={this.props.history} />
            <DeleteTenantModal refresh={() => this.fetchTenants()} instance={ref => this.deleteTenantModal = ref} history={this.props.history} />
            <CreateWorkflowExecutionModal history={this.props.history} workflows={this.state.workflows} instance={ref => this.createWorkflowExecutionModal = ref} subsidiaryId={customerSubsidiary.id} />
            <CreateSubsidiaryFactSheetModal instance={ref => this.createSubsidiaryFactSheetModal = ref} subsidiaryId={customerSubsidiary.id} refresh={() => this.fetchFactSheets()} factSheets={this.state.factSheets} />
            <NewFacilityModal instance={ref => this.newFacilityModal = ref} />
            <ApplyWorkSectionsModal subsidiaryId={customerSubsidiary.id} refresh={() => {
                this.fetch()
                this.fetchWorkSectionExempts()
            }} instance={ref => this.applyWorkSectionsModal = ref} />
            <DeleteSubsidiaryFactSheetModal instance={ref => this.deleteSubsidiaryFactSheetModal = ref} refresh={() => this.fetchFactSheets()} />
            <DeleteWorkflowExecutionModal instance={ref => this.deleteWorkflowExecutionModal = ref} refresh={() => this.fetchWorkflowExecutions()} />
            <UtilitiesStatementDocumentsModal instance={ref => this.utilitiesStatementDocumentsModal = ref} />
            <TenantUtilitiesStatementDocumentsModal instance={ref => this.tenantUtilitiesStatementDocumentsModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "building",
                    title: i18next.t("page.private.subsidiary.breadCrumbsTitle"),
                    link: "/facilitymanagement"
                },
                {
                    icon: "map-marker",
                    title: `${customerSubsidiary.name}${customerSubsidiary.label ? (" (" + customerSubsidiary.label + ")") : ""}`
                }
            ]}
                margin="0 32px 32px 32px"
            />
            <Section bodyPadding="32px 0 0 0" bodyMargin="0 32px">
                <Section.Item title={i18next.t("page.private.subsidiary.branch.sectionElementTitle")} icon="map-marker">
                    {this.state.statistics && <RatingView operative={this.state.statistics.operative} fixedFlaws={this.state.statistics.fixedFlaws} validIntervals={this.state.statistics.validIntervals} />}
                    <Grid columns={[ "3fr", "1fr" ]} gap="24px">
                        <GridElement columnStart={1} columnEnd={2}>
                            <Grid gap="24px">
                                <GridElement styled title={i18next.t("page.private.subsidiary.branch.branchForm.gridElementTitle")} icon="map-marker" rightContent={<div style={{ display: "grid", gridTemplateColumns: "max-content max-content", gridGap: "16px" }}>
                                    <Button disabled={scanmetrix.checkPermission("Subsidiary") < 3} thick icon="pencil" title={i18next.t("page.private.subsidiary.branch.branchForm.updateButton")} onClick={() => this.updateSubsidiaryModal.open(customerSubsidiary)} />
                                    <Button disabled={scanmetrix.checkPermission("Subsidiary") < 4} thick secondary icon="trash" title={i18next.t("page.private.subsidiary.branch.branchForm.deleteButton")} onClick={() => this.deleteSubsidiaryModal.open(customerSubsidiary)} />
                                </div>}>
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={3}>
                                            <Input adjustWidth label={i18next.t("page.private.subsidiary.branch.branchForm.name")} icon="font" value={customerSubsidiary.name} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Input adjustWidth label={i18next.t("page.private.subsidiary.branch.branchForm.abbreviation")} icon="store" value={customerSubsidiary.label} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={4} columnEnd={7}>
                                            <Input adjustWidth label={i18next.t("page.private.subsidiary.branch.branchForm.legalName")} icon="gavel" value={customerSubsidiary.legalName} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={7}>
                                            <Input adjustWidth label={i18next.t("page.private.subsidiary.branch.branchForm.address")} icon="map-marked" value={customerSubsidiary.address.inline} readOnly />
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                                <Input.MutationProvider value={{
                                    name: "updateSubsidiaryInfos",
                                    id: customerSubsidiary.id,
                                    data: customerSubsidiary
                                }}>
                                    <GridElement styled title={i18next.t("page.private.subsidiary.branch.general.gridElementTitle")} icon="cog">
                                        <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr" ]}>
                                            <GridElement columnStart={1} columnEnd={2}>
                                                <Select readOnly={scanmetrix.checkPermission("Subsidiary") < 3} field="subsidiaryType.id" label={i18next.t("page.private.subsidiary.branch.general.branchType")} icon="map-marker-edit" adjustWidth items={this.state.subsidiaryTypes.map(subsidiaryType => ({ key: subsidiaryType.id, title: subsidiaryType.name }))} />
                                            </GridElement>
                                            <GridElement columnStart={2} columnEnd={3}>
                                                <Select readOnly={scanmetrix.checkPermission("Subsidiary") < 3} field="phase.id" label={i18next.t("page.private.subsidiary.branch.general.status")} icon="comment-alt-edit" adjustWidth items={this.state.phases.map(phase => ({ key: phase.id, title: phase.name }))} />
                                            </GridElement>
                                            <GridElement columnStart={3} columnEnd={4}>
                                                <Select readOnly={scanmetrix.checkPermission("Subsidiary") < 3} field="districtManagerUser.id" label={i18next.t("page.private.subsidiary.branch.general.districtManager")} icon="user-tie" adjustWidth items={this.state.users.map(user => ({ key: user.id, title: `${user.firstName} ${user.lastName} / ${user.email}` }))} />
                                            </GridElement>
                                            <GridElement columnStart={1} columnEnd={2}>
                                                <Input disabled={scanmetrix.checkPermission("Subsidiary") < 3} adjustWidth label={i18next.t("page.private.subsidiary.branch.general.generalInformation")} icon="font" field="general" textArea />
                                            </GridElement>
                                            <GridElement columnStart={2} columnEnd={3}>
                                                <Input disabled={scanmetrix.checkPermission("Subsidiary") < 3} adjustWidth label={i18next.t("page.private.subsidiary.branch.general.specialfeatures")} icon="font" field="specialty" textArea />
                                            </GridElement>
                                            <GridElement columnStart={3} columnEnd={4}>
                                                <Input disabled={scanmetrix.checkPermission("Subsidiary") < 3} adjustWidth label={i18next.t("page.private.subsidiary.branch.general.notes")} icon="font" field="notes" textArea />
                                            </GridElement>
                                        </Grid>
                                    </GridElement>
                                </Input.MutationProvider>
                            </Grid>
                        </GridElement>
                        <Input.MutationProvider value={{
                            name: "updateSubsidiaryInfos",
                            id: customerSubsidiary.id,
                            data: customerSubsidiary
                        }}>
                            <GridElement columnStart={2} columnEnd={3} title={i18next.t("page.private.subsidiary.branch.contactData.gridElementTitle")} icon="address-book" styled>
                                <Grid gap="32px" padding="32px" columns={[ "1fr" ]}>
                                    <GridElement>
                                        <Input disabled={scanmetrix.checkPermission("Subsidiary") < 3} adjustWidth label={i18next.t("page.private.subsidiary.branch.contactData.email")} icon="at" field="contactEmail" />
                                    </GridElement>
                                    <GridElement>
                                        <Input disabled={scanmetrix.checkPermission("Subsidiary") < 3} adjustWidth label={i18next.t("page.private.subsidiary.branch.contactData.phoneNumber")} icon="phone" field="contactTelephone" />
                                    </GridElement>
                                    <GridElement>
                                        <Input disabled={scanmetrix.checkPermission("Subsidiary") < 3} adjustWidth label={i18next.t("page.private.subsidiary.branch.contactData.fax")} icon="fax" field="contactTelefax" />
                                    </GridElement>
                                </Grid>
                            </GridElement>
                        </Input.MutationProvider>
                    </Grid>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.subsidiary.tradeAssignments.sectionElementTitle")} icon="shapes" amountColor={this.state.workSections.length > 0 ? (this.state.workSections.filter(ws => this.state.workSectionExempts.filter(exempt => exempt.workSection.id === ws.id).length > 0 || this.state.contracts.filter(contract => contract.workSection && contract.workSection.id === ws.id).length > 0).length < this.state.workSections.length ? "#e67e22" : "#16a085") : null} amount={`${this.state.workSections.filter(ws => this.state.workSectionExempts.filter(exempt => exempt.workSection.id === ws.id).length > 0 || this.state.contracts.filter(contract => contract.workSection && contract.workSection.id === ws.id).length > 0).length} / ${this.state.workSections.length}`}>
                    <div style={{display:"flex",background:"white",height:"48px",lineHeight:"48px", fontSize:"16px", border:"1px solid  #EBEEF3", outline: "none", marginBottom:"24px",marginTop:"-24px",  borderRadius:"5px 5px 5px 5px", boxShadow:" rgba(0, 0, 0, 0.4) 0px 8px 24px -16px "}}><input onChange={(e) => this.setState({ filterText: e.target.value })} value={this.state.filterText} style={{ height: "100%" ,width:"90%",marginLeft:"12px", border:"none", outline:"none",fontSize:"16px", paddingLeft:"5px"}} placeholder={i18next.t("page.private.subsidiary.tradeAssignments.workSectionSearch")}></input></div>
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 24 }}>
                    {this.state.workSections
                    .filter(workSection => !this.state.filterText || workSection.name.toLowerCase().includes(this.state.filterText.toLowerCase())).map(workSection => <SubsidiaryWorkSection
                        note={this.state.workSectionNotes.find(wsn => wsn.workSection.id === workSection.id)?.notes || null}
                        subsidiaryId={customerSubsidiary.id}
                        history={this.props.history}
                        key={workSection.id}
                        refreshNotes={() => this.fetchWorkSectionNotes()}
                        refresh={() => this.fetchWorkSectionExempts()}
                        workSection={workSection}
                        exempts={this.state.workSectionExempts.filter(exempt => exempt.workSection.id === workSection.id)} contracts={this.state.contracts.filter(contract => contract.workSection && contract.workSection.id === workSection.id)} />)}
                    </div>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.subsidiary.TGASystems.sectionElementTitle")} icon="folder-tree" amount={this.state.facilities.length}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.TGASystems.gridElementTitle")} icon="folder-tree" /*rightContent={<div style={{ display: 'grid', gridTemplateColumns: 'max-content' }}>
                        <Button thick icon="folder-tree" title="Anlage anlegen" onClick={() => this.newFacilityModal.open()} />
                    </div>}*/ rightContent={<div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginRight: 16 }}><i className="far fa-sync" style={{ color: "#3b97d3" }} /> {i18next.t("page.private.subsidiary.TGASystems.cfdsText")}</p>
                        <Button onClick={() => this.applyWorkSectionsModal.open()} thick icon="list-check" title={i18next.t("page.private.subsidiary.TGASystems.applyWorkSections")} />
                    </div>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.TGASystems.table.name"), width: "15%", column: "name" },
                                { title: i18next.t("page.private.subsidiary.TGASystems.table.building"), width: "10%", column: "building" },
                                { title: i18next.t("page.private.subsidiary.TGASystems.table.component"), width: "10%", column: "component" },
                                { title: i18next.t("page.private.subsidiary.TGASystems.table.workSection"), width: "15%", column: "workSection" },
                                { title: i18next.t("page.private.subsidiary.TGASystems.table.targetWorkSection"), width: "12.5%", column: "targetWorkSection" },
                                { title: i18next.t("page.private.subsidiary.TGASystems.table.interval"), width: "10%", column: "interval" },
                                { title: i18next.t("page.private.subsidiary.TGASystems.table.nextInspectionDate"), width: "10%", column: "nextInspectionDate" },
                                { title: i18next.t("page.private.subsidiary.TGASystems.table.serviceProvider"), width: "10%", column: "serviceProvider" },
                                { title: i18next.t("page.private.subsidiary.TGASystems.table.inventory"), width: "7.5%", column: "inventory" }
                            ]}
                            onItemClick={facility => this.props.history.push(`/facility/${facility.serviceProviderRaw.id}/${facility.id}`)}
                            items={this.state.facilities.map(facility => {
                                return ({
                                    id: facility.id,
                                    targetWorkSection: this.state.workSections.find(w => w.id === facility.targetWorkSectionId) ? <><i className={`fa-duotone fa-${this.state.workSections.find(w => w.id === facility.targetWorkSectionId).icon}`} />{this.state.workSections.find(w => w.id === facility.targetWorkSectionId).id}: {this.state.workSections.find(w => w.id === facility.targetWorkSectionId).name}</> : null,
                                    name: <div style={{ paddingLeft: "16px" }}><Status yellow={facility.status === "orange"} status={facility.status === "gray" ? null : (facility.status !== "red")}><i className={`far fa-${facility.status === "gray" ? "clock" : (facility.status === "green" ? "check-double" : "bolt")}`} /></Status>{facility.name}</div>,
                                    building: facility.building,
                                    interval: facility.intervalAmount ? `${facility.intervalAmount} ${getIntervalType(facility.intervalType)}` : null,
                                    nextInspectionDate: facility.nextInspectionDate,
                                    component: facility.mainComponent ? facility.mainComponent.name : null,
                                    workSection: facility.workSection ? <><i className={`fa-duotone fa-${facility.workSection.icon}`} />{facility.workSection.name}</> : null,
                                    serviceProvider: facility.serviceProvider.name,
                                    inventory: `${facility.referenceInventoryAmount || 0} ${i18next.t("page.private.subsidiary.TGASystems.pieces")}${facility.isReferenceAmount ? i18next.t("page.private.subsidiary.TGASystems.target") : ""}`,
                                    serviceProviderRaw: facility.serviceProvider
                                })
                            })}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.subsidiary.orders.sectionElementTitle")} icon="comment-alt-check" amount={this.state.purchaseOrders.length}>
                    {/*<div style={{ position: "relative" }}>
                        {/*<Sort>
                            Sortieren nach <b>Alle anzeigen</b><i className="far fa-arrow-down" />
                        </Sort>*/}
                        {/*<OrderGrid
                            history={this.props.history}
                            data={this.state.purchaseOrders}
                        />*/}
                    {/*</div>*/}
                    <GridElement styled title={i18next.t("page.private.subsidiary.orders.gridElementTitle")} icon="comment-alt-check">
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.orders.table.date"), width: "7.5%", column: "date" },
                                { title: i18next.t("page.private.subsidiary.orders.table.status"), width: "15%", column: "status" },
                                { title: i18next.t("page.private.subsidiary.orders.table.number"), width: "7.5%", column: "number" },
                                { title: i18next.t("page.private.subsidiary.orders.table.letterHead"), width: "7.5%", column: "letterHead" },
                                { title: i18next.t("page.private.subsidiary.orders.table.type"), width: "12.5%", column: "type" },
                                { title: i18next.t("page.private.subsidiary.orders.table.serviceProvider"), width: "20%", column: "serviceProvider" },
                                { title: i18next.t("page.private.subsidiary.orders.table.title"), width: "22.5%", column: "title" },
                                { title: i18next.t("page.private.subsidiary.orders.table.sum"), width: "7.5%", column: "sum" },
                            ]}
                            onItemClick={purchaseOrder => this.props.history.push(`/purchaseorder/${purchaseOrder.id}`)}
                            items={this.state.purchaseOrders ? this.state.purchaseOrders.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(purchaseOrder => {
                                return ({
                                    ...purchaseOrder,
                                    status: <><i className={`fa-duotone fa-${getStatus(purchaseOrder.status).icon}`} style={{ color: getStatus(purchaseOrder.status).color, marginRight: 8 }} /> {getStatus(purchaseOrder.status).title}</>,
                                    type: <><i className={`fa-duotone fa-${getType(purchaseOrder.type).icon}`} style={{ color: getType(purchaseOrder.type).color, marginRight: 8 }} /> {getType(purchaseOrder.type).title}</>,
                                    letterHead: purchaseOrder.letterHead ? purchaseOrder.letterHead.name : null,
                                    serviceProvider: purchaseOrder.serviceProvider.name,
                                    date: moment(purchaseOrder.date).format("DD.MM.YYYY"),
                                    subsidiary: purchaseOrder.subsidiary.name + (purchaseOrder.subsidiary.label ? (" (" + purchaseOrder.subsidiary.label + ")") : ""),
                                    sum: formatter.format(((typeof purchaseOrder.positions === "string" ? JSON.parse(purchaseOrder.positions) : purchaseOrder.positions) || []).map(item => item.amount * item.price).reduce((a, b) => a + b, 0))
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.subsidiary.factSheets.sectionElementTitle")} icon="file-spreadsheet" amount={this.state.subsidiaryFactSheets.length}>
                    <Button disabled={scanmetrix.checkPermission("SubsidiaryFactSheet") < 2} title={i18next.t("page.private.subsidiary.factSheets.factSheetsButton")} icon="file-spreadsheet" thick onClick={() => {
                        this.createSubsidiaryFactSheetModal.open()
                    }} />
                    <div style={{ marginTop: 32, display: "grid", gridAutoFlow: "row", gridGap: 24 }}>
                        {this.state.subsidiaryFactSheets.length === 0 && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <h2 style={{ textAlign: "center", fontWeight: 300, marginTop: 48, width: 400, userSelect: "none" }}>{i18next.t("page.private.subsidiary.factSheets.factSheetsText")}</h2>
                            <img style={{ filter: "grayscale(100%)", opacity: 0.5, height: 250, marginTop: 32 }} src="/fact_sheets.svg" />
                        </div>}
                        {this.state.subsidiaryFactSheets.sort((a, b) => b.id - a.id).map(subsidiaryFactSheet => <GridElement key={subsidiaryFactSheet.id} styled title={subsidiaryFactSheet.factSheet.name} icon={subsidiaryFactSheet.factSheet.icon} rightContent={<Button disabled={scanmetrix.checkPermission("SubsidiaryFactSheet") < 4} thick red title={i18next.t("page.private.subsidiary.factSheets.deleteButton")} icon="trash" onClick={() => this.deleteSubsidiaryFactSheetModal.open(subsidiaryFactSheet.id)} />}>
                            <ResponsiveGridLayout isDraggable={false} isResizable={false} margin={[32, 32]} containerPadding={[32, 32]} measureBeforeMount className="layout" breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}} cols={{lg: 6, md: 6, sm: 6, xs: 6, xxs: 6}} rowHeight={64}>
                                {subsidiaryFactSheet.factSheet.elements.map(element => <div key={element.id} data-grid={element}>
                                    <div>



                                    <Input
                                    style={{display: "flex", background: "red"}}
                                    disabled={scanmetrix.checkPermission("SubsidiaryFactSheet") < 3} height="100%" textArea label={element.label || "Undefiniertes Freitextfeld"} adjustWidth value={subsidiaryFactSheet.data[element.id] || null} onChange={value => {
                                        const subsidiaryFactSheets = this.state.subsidiaryFactSheets.map(sfs => {
                                            if(sfs.id !== subsidiaryFactSheet.id) return sfs

                                            return ({
                                                ...subsidiaryFactSheet,
                                                data: {
                                                    ...subsidiaryFactSheet.data,
                                                    [element.id]: value
                                                }
                                            })
                                        })

                                        this.setState({ subsidiaryFactSheets }, () => this.saveSubsidiaryFactSheet(subsidiaryFactSheet.id))
                                    }} />
                                    </div>
                                </div>)}
                            </ResponsiveGridLayout>
                        </GridElement>)}
                    </div>
                </Section.Item>
                <Section.Item disabled={scanmetrix.checkPermission("SubsidiaryTenancySettings") === 0} title={i18next.t("page.private.subsidiary.rent.sectionElementTitle")} icon="house">
                    <Input.MutationProvider value={{
                        name: "updateSubsidiaryInfos",
                        id: customerSubsidiary.id,
                        data: customerSubsidiary
                    }}>
                        <Grid gap="24px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                            {scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiary.rent.general.gridElementTitle")} icon="info" columnStart={1} columnEnd={5}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} field="responsibilityMaintenance" adjustWidth label={i18next.t("page.private.subsidiary.rent.general.responsibilityMaintenance")} icon="user-tie" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} field="subsidiaryTransferable" adjustWidth label={i18next.t("page.private.subsidiary.rent.general.subsidiaryTransferable")} icon="question" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Grid gap="32px" columns={[ "1fr", "1fr", "1fr" ]}>
                                            <GridElement columnStart={1} columnEnd={2}>
                                                <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} field="securityDeposit" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.general.securityDeposit")} icon="university" />
                                            </GridElement>
                                            <GridElement columnStart={2} columnEnd={3}>
                                                <Checkbox disabled={scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} label={i18next.t("page.private.subsidiary.rent.general.easementRentalContract")} field="easementRentalContract" />
                                            </GridElement>
                                            <GridElement columnStart={3} columnEnd={4}>
                                                <Checkbox label={i18next.t("page.private.subsidiary.rent.general.easementRegistered")} contextualDisabled={data => !data.easementRentalContract || scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} field="easementRegistered" />
                                            </GridElement>
                                        </Grid>
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} textArea field="features" adjustWidth label={i18next.t("page.private.subsidiary.rent.general.features")} icon="exclamation" />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                            <GridElement styled title={i18next.t("page.private.subsidiary.rent.responsibilities.gridElementTitle")} icon="user-tie" columnStart={5} columnEnd={7}>
                                <Grid gap="32px" padding="32px">
                                    <GridElement>
                                        <Select readOnly={scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} link={val => `/custodian/${val}`} field="landlordCustodian.id" label={i18next.t("page.private.subsidiary.rent.responsibilities.landlord")} icon="house-user" adjustWidth items={this.state.custodians.map(custodian => ({ key: custodian.id, title: custodian.name }))} />
                                    </GridElement>
                                    <GridElement>
                                        <Select readOnly={scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} link={val => `/custodian/${val}`} field="administrativeCustodian.id" label={i18next.t("page.private.subsidiary.rent.responsibilities.propertyManager")} icon="house-damage" adjustWidth items={this.state.custodians.map(custodian => ({ key: custodian.id, title: custodian.name }))} />
                                    </GridElement>
                                </Grid>
                            </GridElement>
                            {/*<GridElement styled title="Objektvermieter" icon="house-user" columnStart={1} columnEnd={4}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input field="address." icon="user-tie" label="Vermietername" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input icon="road" label="Straße" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input icon="bell" label="Hausnr." adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input icon="font" label="Adresszusatz" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input icon="mailbox" label="Postleitzahl" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input icon="city" label="Stadt" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Select adjustWidth icon="globe-europe" label="Land" items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input icon="note" label="Notizen" adjustWidth />
                                    </GridElement>
                                </Grid>
                            </GridElement>
                            <GridElement styled title="Objektverwalter" icon="house-damage" columnStart={4} columnEnd={7}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input icon="user-tie" label="Verwaltername" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input icon="road" label="Straße" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input icon="bell" label="Hausnr." adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input icon="font" label="Adresszusatz" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input icon="mailbox" label="Postleitzahl" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input icon="city" label="Stadt" adjustWidth />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Select adjustWidth icon="globe-europe" label="Land" items={Object.keys(formattedCountries).map(country => ({ key: country, title: formattedCountries[country] }))} />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={3}>
                                        <Input icon="note" label="Notizen" adjustWidth />
                                    </GridElement>
                                </Grid>
                            </GridElement>*/}
                            <GridElement styled title={i18next.t("page.private.subsidiary.rent.plot.gridElementTitle")} icon="house" columnStart={1} columnEnd={5} rowStart={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") === 0 ? 1 : 2} rowEnd={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") === 0 ? 2 : 3}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="plotArea" float format={val => val !== null ? `${val} m²` : null} adjustWidth label={i18next.t("page.private.subsidiary.rent.plot.plotArea")} icon="ruler-combined" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="mainTenantArea" float format={val => val !== null ? `${val} m²` : null} adjustWidth label={i18next.t("page.private.subsidiary.rent.plot.mainRentalArea")} icon="ruler-combined" />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="adjacentArea" float format={val => val !== null ? `${val} m²` : null} adjustWidth label={i18next.t("page.private.subsidiary.rent.plot.ancillaryArea")} icon="ruler-combined" />
                                    </GridElement>
                                    <GridElement columnStart={4} columnEnd={5}>
                                        <Input float format={val => val !== null ? `${val} m²` : null} adjustWidth label={i18next.t("page.private.subsidiary.rent.plot.totalArea")} icon="ruler-combined" contextualValue={data => ((data.mainTenantArea || 0) + (data.adjacentArea || 0) + " m²")} />
                                    </GridElement>
                                </Grid>
                            </GridElement>
                            <GridElement styled title={i18next.t("page.private.subsidiary.rent.basicData.gridElementTitle")} icon="cog" columnStart={5} columnEnd={7}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} field="handoverDate" date adjustWidth label={i18next.t("page.private.subsidiary.rent.basicData.handoverDate")} icon="key" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancySettings") < 3} field="openingDate" date adjustWidth label={i18next.t("page.private.subsidiary.rent.basicData.openingDate")} icon="flag-checkered" />
                                    </GridElement>
                                </Grid>
                            </GridElement>
                            {scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiary.rent.rents.gridElementTitle")} icon="house-return" columnStart={1} columnEnd={7}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="rentStartDate" adjustWidth date label={i18next.t("page.private.subsidiary.rent.rents.rentStartDate")} icon="hourglass-start" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="rentPaymentStartDate" adjustWidth date label={i18next.t("page.private.subsidiary.rent.rents.rentPaymentStartDate")} icon="hourglass-start" />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="minimumRent" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.rents.minimumRent")} icon="piggy-bank" />
                                    </GridElement>
                                    <GridElement columnStart={4} columnEnd={5}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="salesRent" adjustWidth float format={val => val !== null ? `${val} %` : val} label={i18next.t("page.private.subsidiary.rent.rents.salesRent")} icon="empty-set" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="rentIncrease" adjustWidth label={i18next.t("page.private.subsidiary.rent.rents.rentIncrease")} icon="chart-line" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="parkingSpaceRental" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.rents.parkingSpaceRental")} icon="parking" />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="advertisingSpaceRental" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.rents.advertisingSpaceRental")} icon="ad" />
                                    </GridElement>
                                    <GridElement columnStart={4} columnEnd={5}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="operatingDevicesRental" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.rents.operatingDevicesRental")} icon="light-switch-on" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={5}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} textArea field="rentNotes" adjustWidth label={i18next.t("page.private.subsidiary.rent.rents.rentNotes")} icon="sticky-note" />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                            {scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiary.rent.serviceCharges.gridElementTitle")} icon="faucet-drip" columnStart={1} columnEnd={4}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="advancePaymentServiceCharges" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.serviceCharges.advancePaymentServiceCharges")} icon="faucet-drip" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="ancillaryCostsFlatRate" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.serviceCharges.ancillaryCostsFlatRate")} icon="faucet-drip" />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="advancePaymentServiceChargesAll" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.serviceCharges.advancePaymentServiceChargesAll")} icon="hand-holding-usd" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input disabled={scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") < 3} field="ancillaryCostsFlatRateAll" adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.serviceCharges.ancillaryCostsFlatRateAll")} icon="hand-holding-usd" />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                            {scanmetrix.checkPermission("SubsidiaryTenancyAccountingSettings") > 0 && <GridElement styled title={i18next.t("page.private.subsidiary.rent.costOverview.gridElementTitle")} icon="coins" columnStart={4} columnEnd={7}>
                                <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input readOnly adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.costOverview.totalAncillaryCosts")} icon="faucet-drip" contextualValue={data => formatter.format((data.advancePaymentServiceCharges || 0) + (data.ancillaryCostsFlatRate || 0))} />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.costOverview.rentAndAncillaryCosts")} icon="equals" contextualValue={data => formatter.format((data.advancePaymentServiceCharges || 0) + (data.minimumRent || 0) + (data.ancillaryCostsFlatRate || 0))} />
                                    </GridElement>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.costOverview.ParkingAdvertisingOperatingCosts")} icon="hand-holding-usd" contextualValue={data => formatter.format((data.parkingSpaceRental || 0) + (data.advertisingSpaceRental || 0) + (data.operatingDevicesRental || 0) + (data.ancillaryCostsFlatRateAll || 0) + (data.advancePaymentServiceChargesAll || 0))} />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input adjustWidth float format={val => val !== null ? formatter.format(val) : val} label={i18next.t("page.private.subsidiary.rent.costOverview.totalPropertyCosts")} icon="equals" contextualValue={data => formatter.format((data.advancePaymentServiceCharges || 0) + (data.minimumRent || 0) + (data.ancillaryCostsFlatRate || 0) + (data.parkingSpaceRental || 0) + (data.advertisingSpaceRental || 0) + (data.operatingDevicesRental || 0) + (data.ancillaryCostsFlatRateAll || 0) + (data.advancePaymentServiceChargesAll || 0))} />
                                    </GridElement>
                                </Grid>
                            </GridElement>}
                        </Grid>
                    </Input.MutationProvider>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.subsidiary.subtenant.sectionElementTitle")} icon="home" amount={this.state.tenants ? this.state.tenants.length : 0}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.subtenant.gridElementTitle")} icon="home" rightContent={<Button disabled={scanmetrix.checkPermission("SubsidiaryTenant") < 2} thick title={i18next.t("page.private.subsidiary.subtenant.button")} icon="home" onClick={() => this.createTenantModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.subtenant.table.name"), width: "30%", column: "name" },
                                { title: i18next.t("page.private.subsidiary.subtenant.table.place"), width: "20%", column: "location" },
                                { title: i18next.t("page.private.subsidiary.subtenant.table.email"), width: "20%", column: "email" },
                                { title: i18next.t("page.private.subsidiary.subtenant.table.Telefon"), width: "15%", column: "telephone" },
                                { title: i18next.t("page.private.subsidiary.subtenant.table.fax"), width: "15%", column: "telefax" }
                            ]}
                            contextItems={scanmetrix.checkPermission("SubsidiaryTenant") < 4 ? null : [
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.subsidiary.subtenant.deleteButton"),
                                    onClick: data => {
                                        this.deleteTenantModal.open(data)
                                    }
                                }
                            ]}
                            onItemClick={tenant => this.props.history.push(`/subsidiary-tenant/${tenant.id}`)}
                            items={this.state.tenants ? this.state.tenants.map(tenant => ({
                                ...tenant,
                                location: (tenant.address.postalCode && tenant.address.city) ? `${tenant.address.postalCode} ${tenant.address.city}` : <p><i className="far fa-exclamation-circle red" />{i18next.t("page.private.subsidiary.subtenant.incompleteAddress")}</p>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item disabled={scanmetrix.checkPermission("WorkflowExecution") < 1} title={i18next.t("page.private.subsidiary.workflowExecutions.sectionTitle")} icon="play" amount={this.state.workflowExecutions.length}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.workflowExecutions.gridElementTitle")} icon="play" rightContent={<Button thick icon="play" title={i18next.t("page.private.subsidiary.workflowExecutions.button")} green onClick={() => this.createWorkflowExecutionModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.workflowExecutions.table.workflow"), width: "17.5%", column: "workflow" },
                                { title: i18next.t("page.private.subsidiary.workflowExecutions.table.context"), width: "12.5%", column: "context" },
                                { title: i18next.t("page.private.subsidiary.workflowExecutions.table.branch"), width: "20%", column: "subsidiary" },
                                { title: i18next.t("page.private.subsidiary.workflowExecutions.table.team"), width: "15%", column: "team" },
                                { title: i18next.t("page.private.subsidiary.workflowExecutions.table.user"), width: "15%", column: "user" },
                                { title: i18next.t("page.private.subsidiary.workflowExecutions.table.createdAt"), width: "10%", column: "createdAt" },
                                { title: i18next.t("page.private.subsidiary.workflowExecutions.table.openSteps"), width: "10%", column: "openSteps" }
                            ]}
                            onItemClick={workflowExecution => this.props.history.push(`/workflow-execution/${workflowExecution.id}`)}
                            items={this.state.workflowExecutions ? this.state.workflowExecutions.sort((a, b) => (a.finishedAt ? moment(a.finishedAt).valueOf() : 0) - (b.finishedAt ? moment(b.finishedAt).valueOf() : 0)).map(execution => ({
                                id: execution.id,
                                workflow: <div style={{ paddingLeft: "16px" }}><Status yellow={!execution.finishedAt} status={true}><i className={`far fa-${execution.finishedAt ? "check" : "play"}`} /></Status>{execution.workflow.name}</div>,
                                team: execution.team ? execution.team.name : null,
                                user: execution.user ? <div style={{ display: "flex", alignItems: "center" }}><div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}><Avatar size="32" email={scanmetrix.disableGravatar ? null : execution.user.email} name={`${execution.user.firstName} ${execution.user.lastName}`} className="avatarInner" /></div>{execution.user.firstName} {execution.user.lastName}</div> : null,
                                subsidiary: execution.subsidiary ? `${execution.subsidiary.name} (${execution.subsidiary.label}) / ${execution.subsidiary.address.postalCode} ${execution.subsidiary.address.city}` : null,
                                context: execution.contextType === "user" ? <><i className="far fa-user" />{i18next.t("page.private.subsidiary.workflowExecutions.content.user")}</> : (execution.contextType === "subsidiary" ? <><i className="far fa-map-marker" />{i18next.t("page.private.subsidiary.workflowExecutions.content.branch")}</> : (execution.contextType === "team" ? <><i className="far fa-users-crown" />{i18next.t("page.private.subsidiary.workflowExecutions.content.team")}</> : (execution.contextType === "flaw" ? <><i className="far fa-bolt" />{i18next.t("page.private.subsidiary.workflowExecutions.content.defect")}</> : <><i className="far fa-question" />{i18next.t("page.private.subsidiary.workflowExecutions.content.other")}</>))),
                                createdAt: moment(execution.createdAt).format("DD.MM.YYYY"),
                                openSteps: <div style={{ color: "white", background: execution.steps.filter(step => step.data && !step.data.done).length > 0 ? "#3b97d3" : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{execution.steps.filter(step => step.data && !step.data.done).length} {i18next.t("page.private.subsidiary.workflowExecutions.content.tasks")}</div>
                            })) : null}
                            contextItems={[
                                { title: i18next.t("page.private.subsidiary.workflowExecutions.deleteButton"), icon: "trash", onClick: item => this.deleteWorkflowExecutionModal.open(item.id)}
                            ]}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.subsidiary.documents.sectionTitle")} icon="folder-tree">
                    <FileManager subsidiaryId={customerSubsidiary.id} title={i18next.t("page.private.subsidiary.documents.title")} icon="folder-tree" scope="subsidiary" />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.subsidiary.plans.sectionElementTitle")} icon="layer-group" amount={this.state.plans?.length || 0}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.plans.gridElementTitle")} icon="layer-group" rightContent={<p><i className="far fa-sync" style={{ color: "#3b97d3" }} /> {i18next.t("page.private.subsidiary.plans.cfdsText")}</p>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.plans.table.name"), width: "45%", column: "name" },
                                { title: i18next.t("page.private.subsidiary.plans.table.type"), width: "40%", column: "type" },
                                { title: i18next.t("page.private.subsidiary.plans.table.markings"), width: "15%", column: "markerLength" }
                            ]}
                            onItemClick={plan => this.planModal.open(plan)}
                            items={this.state.plans ? this.state.plans.map(plan => {
                                return ({
                                    id: plan.id,
                                    name: plan.name,
                                    type: plan.type,
                                    markers: plan.markers,
                                    markerLength: plan.markers.length,
                                    imageData: plan.imageData,
                                    serviceProvider: plan.serviceProvider
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.subsidiary.contracts.sectionTitle")} icon="file-contract" amount={this.state.contracts.length}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.contracts.gridElementTitle")} icon="file-contract">
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.contracts.table.title"), width: "20%", column: "title" },
                                { title: i18next.t("page.private.subsidiary.contracts.table.type"), width: "15%", column: "type" },
                                { title: i18next.t("page.private.subsidiary.contracts.table.number"), width: "10%", column: "number" },
                                { title: i18next.t("page.private.subsidiary.contracts.table.partnerName"), width: "15%", column: "partnerName" },
                                { title: i18next.t("page.private.subsidiary.contracts.table.duration"), width: "10%", column: "duration" },
                                { title: i18next.t("page.private.subsidiary.contracts.table.beginsAt"), width: "10%", column: "beginsAt" },
                                { title: i18next.t("page.private.subsidiary.contracts.table.endsAt"), width: "10%", column: "endsAt" },
                                { title: i18next.t("page.private.subsidiary.contracts.table.automaticRenewal"), width: "10%", column: "automaticRenewal" }
                            ]}
                            onItemClick={contract => this.props.history.push(`/contract/${contract.id}`)}
                            items={this.state.contracts.map(contract => {
                                return ({
                                    ...contract,
                                    type: contract.type ? (contractTypes.find(type => type.key === contract.type) ? contractTypes.find(type => type.key === contract.type).title : "Unbekannt") : "",
                                    duration: contract.perpetual ? i18next.t("page.private.subsidiary.contracts.unlimitedDuration") : `${contract.contractDurationAmount} ${i18next.t("defaults.durationType." + contract.contractDurationType)}`,
                                    beginsAt: contract.beginsAt ? moment(contract.beginsAt).format("DD.MM.YYYY") : "k. A.",
                                    endsAt: contract.endsAt ? moment(contract.endsAt).format("DD.MM.YYYY") : ((contract.contractDurationAmount && contract.contractDurationType && !contract.perpetual) ? (contract.beginsAt ? moment(contract.beginsAt).add(contract.contractDurationAmount, contract.contractDurationType).format("DD.MM.YYYY") : "k. A.") : (contract.perpetual ? i18next.t("page.private.subsidiary.contracts.unlimitedEndsAt") : null)),
                                    automaticRenewal: contract.automaticRenewal ? <><i className="far fa-check-circle" />{i18next.t("page.private.subsidiary.contracts.yes")}</> : <><i className="far fa-times-circle" />{i18next.t("page.private.subsidiary.contracts.no")}</>
                                })
                            })}
                        />
                    </GridElement>
                </Section.Item>
                <ContractProvisionIntervals title={i18next.t("page.private.subsidiary.performanceCycle.title")} filter={data => data.subsidiary && String(data.subsidiary.id) === String(customerSubsidiary.id)} />
                <Section.Item title={i18next.t("page.private.subsidiary.contactPerson.sectionElementTitle")} icon="address-book" amount={(this.state.contacts || []).length}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.contactPerson.gridElementTitle")} icon="address-book" rightContent={<Button disabled={scanmetrix.checkPermission("SubsidiaryContact") < 2} thick title={i18next.t("page.private.subsidiary.contactPerson.button")} icon="address-book" onClick={() => this.createContactModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.salutationFormatted"), width: "10%", column: "salutationFormatted" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.name"), width: "15%", column: "name" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.email"), width: "15%", column: "email" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.telephone"), width: "15%", column: "telephone" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.type"), width: "15%", column: "type" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.company"), width: "15%", column: "company" },
                                { title: i18next.t("page.private.subsidiary.contactPerson.table.responsibility"), width: "15%", column: "responsibility" }
                            ]}
                            onItemClick={contact => this.updateContactModal.open(contact)}
                            items={this.state.contacts ? this.state.contacts.map(contact => ({
                                id: contact.id,
                                firstName: contact.firstName,
                                lastName: contact.lastName,
                                name: `${contact.firstName ? (contact.firstName + " ") : ""}${contact.lastName}`,
                                salutation: contact.salutation,
                                salutationFormatted: i18next.t(`defaults.salutation.${contact.salutation}`),
                                email: contact.email,
                                telephone: contact.telephone,
                                mobileTelephone: contact.mobileTelephone,
                                type: contact.type,
                                company: contact.company,
                                telefax: contact.telefax,
                                responsibility: contact.responsibility
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                {/*<Section.Item title="Dienstleister" icon="user-tie" amount={5}>
                    <GridElement styled title="Dienstleister" icon="user-tie">
                        <Table
                            head={[
                                { title: "Name", width: "20%", column: "name" },
                                { title: "Gewerk", width: "20%", column: "workSection" },
                                { title: "Zuständigkeit", width: "20%", column: "responsible" },
                                { title: "Telefonnr.", width: "15%", column: "telephone" },
                                { title: "Postleitzahl", width: "5%", column: "postalCode" },
                                { title: "Stadt", width: "20%", column: "city" }
                            ]}
                            items={[
                                { name: "scanmetrix.fm GmbH", workSection: "Brandschutztechnik", responsible: "Feuerlöscher, RWA-Anlagen, Hydranten, Türen", telephone: "+49 208 780 124 30", postalCode: "45478", city: "Mülheim an der Ruhr" },
                                { name: "Musterfirma Sanitär GmbH", workSection: "Sanitärwartung", responsible: "Toiletten, Waschbecken, Kessel, Durchlauferhitzer", telephone: "+49 206 48418237", postalCode: "45478", city: "Mülheim an der Ruhr" },
                                { name: "Musterfirma Arbeitssicherheit GmbH", workSection: "Arbeitsschutz", responsible: "Sicherheitsbegehungen und allgemeine ASR", telephone: "+49 209 9471623", postalCode: "40583", city: "Düsseldorf" },
                                { name: "Musterfirma Kälte-/Klima GmbH", workSection: "Kälte-/Klimatechnik", responsible: "Split-Klimaanlagen, Lüftungssysteme, Brandschutzklappen", telephone: "+49 5144 3291342", postalCode: "03471", city: "Berlin" },
                                { name: "Schlosser Müller", workSection: "Zargen- und Schlüsselarbeiten", responsible: "Eingangs- und Durchgangstüren", telephone: "+49 406 87184817", postalCode: "81947", city: "Hamburg" },
                            ]}
                        />
                    </GridElement>
                </Section.Item>*/}
                {/*<Section.Item title="Verträge" icon="file-contract" amount={(this.state.contracts || []).length}>
                    <GridElement styled title="Verträge" icon="file-contract">
                        <Table
                            head={[
                                { title: "Vertragsbez.", width: "20%", column: "title" },
                                { title: "Vertragsnr.", width: "10%", column: "number" },
                                { title: "Vertragspartner", width: "20%", column: "partnerName" },
                                { title: "Laufzeit", width: "10%", column: "duration" },
                                { title: "Vertragsbeginn", width: "12.5%", column: "beginsAt" },
                                { title: "Vertragsende", width: "12.5%", column: "endsAt" },
                                { title: "Autom. Erneuerung", width: "15%", column: "automaticRenewal" }
                            ]}
                            onItemClick={contract => this.props.history.push(`/contract/${contract.id}`)}
                            items={this.state.contracts ? this.state.contracts.map(contract => ({
                                ...contract,
                                duration: contract.perpetual ? "Unbefristet" : `${contract.contractDurationAmount} ${i18next.t("defaults.durationType." + contract.contractDurationType)}`,
                                beginsAt: moment(contract.beginsAt).format("DD.MM.YYYY"),
                                endsAt: contract.endsAt ? moment(contract.endsAt).format("DD.MM.YYYY") : null,
                                automaticRenewal: contract.automaticRenewal ? <><i className="far fa-check-circle" />Ja</> : <><i className="far fa-times-circle" />Nein</>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>*/}
                <Counters subsidiary={this.state.customerSubsidiary} />
                <Section.Item disabled={scanmetrix.checkPermission("UtilitiesStatement") === 0} title={i18next.t("page.private.subsidiary.serviceCharges.sectionTitle")} icon="piggy-bank" amount={this.state.utilitiesStatements.length}>
                    <Grid gap="32px">
                        <Input.MutationProvider value={{
                            name: "updateSubsidiaryInfos",
                            id: customerSubsidiary.id,
                            data: customerSubsidiary
                        }}>
                            <GridElement styled title={i18next.t("page.private.subsidiary.serviceCharges.gridElementTitle")} icon="piggy-bank">
                                <Grid gap="32px" padding="32px" columns={[ "3fr", "1fr", "1fr" ]}>
                                    <GridElement columnStart={1} columnEnd={2}>
                                        <Input field="utilitiesStatementNotes" adjustWidth label={i18next.t("page.private.subsidiary.serviceCharges.utilitiesStatementNotes")} icon="sticky-note" />
                                    </GridElement>
                                    <GridElement columnStart={2} columnEnd={3}>
                                        <Input field="utilitiesStatementDeadlineMonths" int format={val => val ? val + i18next.t("page.private.subsidiary.serviceCharges.months") : val} adjustWidth disabledContextualValue={data => data.utilitiesStatementAllegation ? 36 : data.utilitiesStatementDeadlineMonths} label={i18next.t("page.private.subsidiary.serviceCharges.utilitiesStatementDeadlineMonths")} icon="calendar" contextualDisabled={data => !!data.utilitiesStatementAllegation} />
                                    </GridElement>
                                    <GridElement columnStart={3} columnEnd={4}>
                                        <div style={{ display: "flex", flexDirection: "column", height: 64, justifyContent: "space-between" }}>
                                            <Checkbox field="utilitiesStatementAllegation" adjustWidth label={i18next.t("page.private.subsidiary.serviceCharges.utilitiesStatementAllegation")} />
                                            <Checkbox field="utilitiesStatementBackPayBarred" adjustWidth label={i18next.t("page.private.subsidiary.serviceCharges.utilitiesStatementBackPayBarred")} />
                                        </div>
                                    </GridElement>
                                </Grid>
                            </GridElement>
                        </Input.MutationProvider>
                        <GridElement>
                            <Section bodyPadding="16px 0 0 0">
                                <Section.Item title={i18next.t("page.private.subsidiary.serviceCharges.operatingCostSavings.sectionTitle")} icon="house-day" amount={this.state.utilitiesStatements.filter(us => us.type === "overhead").length}>
                                    <div style={{ position: "relative" }}>
                                        <div style={{ position: "absolute", right: 0, top: -56 }}>
                                            <Button disabled={scanmetrix.checkPermission("UtilitiesStatement") < 2} title={i18next.t("page.private.subsidiary.serviceCharges.operatingCostSavings.button")} confirm icon="house-day" thick onClick={() => this.createUtilitiesStatementOverheadModal.open()} />
                                        </div>
                                        {!this.state.utilitiesStatements.filter(us => us.type === "overhead").length && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <h2 style={{ textAlign: "center", fontWeight: 300, marginTop: 48, width: 400, userSelect: "none" }}>{i18next.t("page.private.subsidiary.serviceCharges.operatingCostSavings.paragraph")}</h2>
                                            <img style={{ filter: "grayscale(100%)", opacity: 0.5, height: 250 }} src="/utilitiesstatement.svg" />
                                        </div>}
                                        {!!this.state.utilitiesStatements.filter(us => us.type === "overhead").length && <Grid gap="24px">
                                            {this.state.utilitiesStatements.filter(us => us.type === "overhead").sort((a, b) => b.year - a.year).map(us => <UtilitiesStatement documentsModal={() => this.utilitiesStatementDocumentsModal.open(us.id)} readOnly={scanmetrix.checkPermission("UtilitiesStatement") < 3} delete={() => this.deleteUtilitiesStatementModal.open(us.id)} users={this.state.users} key={us.id} data={us} />)}
                                        </Grid>}
                                    </div>
                                </Section.Item>
                                <Section.Item title={i18next.t("page.private.subsidiary.serviceCharges.wastewaterChargesSavings.sectionTitle")} icon="tint-slash" amount={this.state.utilitiesStatements.filter(us => us.type === "effluent").length}>
                                    <div style={{ position: "relative" }}>
                                        <div style={{ position: "absolute", right: 0, top: -56 }}>
                                            <Button disabled={scanmetrix.checkPermission("UtilitiesStatement") < 2} title={i18next.t("page.private.subsidiary.serviceCharges.wastewaterChargesSavings.button")} confirm icon="tint-slash" thick onClick={() => this.createUtilitiesStatementEffluentModal.open()} />
                                        </div>
                                        {!this.state.utilitiesStatements.filter(us => us.type === "effluent").length && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <h2 style={{ textAlign: "center", fontWeight: 300, marginTop: 48, width: 400, userSelect: "none" }}>{i18next.t("page.private.subsidiary.serviceCharges.wastewaterChargesSavings.paragraph")}</h2>
                                            <img style={{ filter: "grayscale(100%)", opacity: 0.5, height: 250 }} src="/utilitiesstatement.svg" />
                                        </div>}
                                        {!!this.state.utilitiesStatements.filter(us => us.type === "effluent").length && <Grid gap="24px">
                                            {this.state.utilitiesStatements.filter(us => us.type === "effluent").sort((a, b) => b.year - a.year).map(us => <UtilitiesStatement documentsModal={() => this.utilitiesStatementDocumentsModal.open(us.id)} readOnly={scanmetrix.checkPermission("UtilitiesStatement") < 3} delete={() => this.deleteUtilitiesStatementModal.open(us.id)} users={this.state.users} key={us.id} data={us} />)}
                                        </Grid>}
                                    </div>
                                </Section.Item>
                                <Section.Item title={i18next.t("page.private.subsidiary.serviceCharges.subtenantSettlements.sectionTitle")} icon="home" amount={this.state.subsidiaryTenantUtilitiesStatements.length}>
                                    <div style={{ position: "relative" }}>
                                        <div style={{ position: "absolute", right: 0, top: -56 }}>
                                            <Button disabled={scanmetrix.checkPermission("SubsidiaryTenantUtilitiesStatement") < 2} title={i18next.t("page.private.subsidiary.serviceCharges.subtenantSettlements.button")} confirm icon="home" thick onClick={() => this.createSubsidiaryTenantUtilitiesStatementModal.open()} />
                                        </div>
                                        {!this.state.subsidiaryTenantUtilitiesStatements.length && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <h2 style={{ textAlign: "center", fontWeight: 300, marginTop: 48, width: 400, userSelect: "none" }}>{i18next.t("page.private.subsidiary.serviceCharges.subtenantSettlements.paragraph")}</h2>
                                            <img style={{ filter: "grayscale(100%)", opacity: 0.5, height: 250 }} src="/utilitiesstatement.svg" />
                                        </div>}
                                        {!!this.state.subsidiaryTenantUtilitiesStatements.length && <Grid gap="24px">
                                            {this.state.subsidiaryTenantUtilitiesStatements.sort((a, b) => b.year - a.year).map(us => <SubsidiaryTenantUtilitiesStatement documentsModal={() => this.tenantUtilitiesStatementDocumentsModal.open(us.id)} readOnly={scanmetrix.checkPermission("SubsidiaryTenantUtilitiesStatement") < 3} delete={() => this.deleteSubsidiaryTenantUtilitiesStatementModal.open(us.id)} users={this.state.users} tenants={this.state.tenants} key={us.id} data={us} />)}
                                        </Grid>}
                                    </div>
                                </Section.Item>
                            </Section>
                        </GridElement>
                    </Grid>
                </Section.Item>
                <Cases subsidiary={this.state.customerSubsidiary} />
                <Section.Item title={i18next.t("page.private.subsidiary.spacemanagement.sectionElementTitle")} icon="chair-office" amount={this.state.spaces ? this.state.spaces.length : 0}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.spacemanagement.gridElementTitle")} icon="chair-office">
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.spacemanagement.table.name"), width: "50%", column: "name" },
                                { title: i18next.t("page.private.subsidiary.spacemanagement.table.type"), width: "50%", column: "type" }
                            ]}
                            onItemClick={space => this.props.history.push(`/space/${space.id}`)}
                            items={this.state.spaces ? this.state.spaces.map(space => ({
                                ...space,
                                subsidiary: `${space.subsidiary.name} (${space.subsidiary.address.postalCode} ${space.subsidiary.address.city}, ${space.subsidiary.label || space.subsidiary.id})`
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item disabled={scanmetrix.checkPermission("Room") === 0} title={i18next.t("page.private.subsidiary.rooms.sectionElementTitle")} icon="table-layout" amount={this.state.roomAmount || 0}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.rooms.gridElementTitle")} icon="table-layout" rightContent={<Button icon="table-layout" title="Raum anlegen" thick primary onClick={() => this.createRoomModal.open()} />}>
                        <Table
                            refresh={refresh => this.refreshRooms = refresh}
                            head={[
                                { title: "Name", width: "55%", column: "name", orderField: "name" },
                                { title: "Fläche in m²", width: "10%", column: "areaFormatted", orderField: "area" },
                                { title: "Flächenplan", width: "15%", column: "space", orderField: "space.name" },
                                { title: "Bearbeitet", width: "10%", column: "updated_at", orderField: "updated_at" },
                                { title: "Erstellt", width: "10%", column: "created_at", orderField: "created_at" },
                            ]}
                            contextItems={[
                                { title: "Raum löschen", icon: "trash", onClick: item => this.deleteRoomModal.open(item.id)}
                            ]}
                            metaCallback={meta => this.setState({ roomAmount: meta.total })}
                            query={`${scanmetrix.nestURL}/v2/room/${customerSubsidiary.id}`}
                            defaultSort="created_at_DESC"
                            onItemClick={room => this.updateRoomModal.open(room)}
                            map={rooms => rooms.map(room => ({
                                ...room,
                                space: room.space?.name,
                                areaFormatted: `${room.area} m²`,
                                updated_at: moment(room.updated_at).fromNow(),
                                created_at: moment(room.created_at).fromNow(),
                            }))}
                        />
                    </GridElement>
                </Section.Item>
                {/* Durchgeführte Begehungen */}
                {/*<Section.Item title={i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.sectionTitle")} icon="person-walking" amount={this.state.inspections ? this.state.inspections.length : 0}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.gridElementTitle")} icon="person-walking" rightContent={<p>{i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.text-1")} <b style={{ color: "#3b97d3" }}><i style={{ marginRight: 0 }} className="far fa-person-walking" /> {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.text-2")}</b> {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.text-3")}</p>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.date"), width: "10%", column: "date" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.status"), width: "10%", column: "status" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.buildingInspectionTemplate"), width: "20%", column: "inspectionTemplate" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.branch"), width: "30%", column: "subsidiary" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.user"), width: "15%", column: "user" },
                                { title: i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.table.result"), width: "15%", column: "result" }
                            ]}
                            contextItems={[
                                { title: i18next.t("page.private.subsidiary.inspections.entryDelete"), icon: "trash", onClick: item => this.deleteinspectionsModal.open(item.id)}
                            ]}
                            onItemClick={inspections => this.pdfModal.open(inspections.id)}
                            items={this.state.inspections ? this.state.inspections.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(inspections => ({
                                ...inspections,
                                user: inspections.user ? <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : inspections.user.email} name={`${inspections.user.firstName} ${inspections.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {inspections.user.firstName} {inspections.user.lastName}
                                </div> : (inspections.serviceDeskUser ? <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : inspections.serviceDeskUser.email} name={`${inspections.serviceDeskUser.firstName} ${inspections.serviceDeskUser.lastName}`} className="avatarInner" />
                                    </div>
                                    {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.sd")} {inspections.serviceDeskUser.firstName} {inspections.serviceDeskUser.lastName}
                                </div> : <><i className="fa-duotone fa-bell-concierge" /> {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.guestUser")}</>),
                                date: moment(inspections.createdAt).format("DD.MM.YYYY"),
                                subsidiary: `${inspections.subsidiary.name} ${inspections.subsidiary.label ? ("(" + inspections.subsidiary.label + ") ") : ""}/ ${inspections.subsidiary.address.postalCode} ${inspections.subsidiary.address.city}`,
                                inspectionTemplate: inspections.inspectionTemplate.name,
                                result: <div style={{ color: "white", background: inspections.tickets.length > 0 ? "#3b97d3" : "rgba(0, 0, 0, 0.25)", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{inspections.tickets.length} {i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.pieces")}</div>,
                                status: <div style={{ color: "white", background: inspections.status === "done" ? "#16a085" : "#f39c12", padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{inspections.status === "done" ? i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.done") : i18next.t("page.private.subsidiary.inspections.inspectionsCarriedOut.active")}</div>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>*/}
                {/* extra */}
                <Section.Item title={i18next.t("page.private.subsidiary.tickets.sectionTitle")} icon="comments-alt" amount={this.state.tickets ? this.state.tickets.length : 0}>
                    <GridElement styled title={i18next.t("page.private.subsidiary.tickets.gridElementTitle")} icon="comments-alt" rightContent={<div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16 }}>
                        <Checkbox value={this.state.hideDoneTickets} onChange={hideDoneTickets => this.setState({ hideDoneTickets })} label="Erledigte ausblenden" />
                        <Checkbox value={this.state.hideArchivedTickets} onChange={hideArchivedTickets => this.setState({ hideArchivedTickets })} label="Archivierte ausblenden" />
                    </div>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.subsidiary.tickets.table.status"), width: "7.5%", column: "status" },
                                { title: i18next.t("page.private.subsidiary.tickets.table.number"), width: "7.5%", column: "number" },
                                { title: i18next.t("page.private.subsidiary.tickets.table.title"), width: "20%", column: "title" },
                                { title: i18next.t("page.private.subsidiary.tickets.table.branch"), width: "20%", column: "subsidiary" },
                                { title: i18next.t("page.private.subsidiary.tickets.table.source"), width: "12.5%", column: "channel" },
                                { title: i18next.t("page.private.subsidiary.tickets.table.type"), width: "15%", column: "type" },
                                { title: i18next.t("page.private.subsidiary.tickets.table.priority"), width: "12.5%", column: "priority" },
                                { title: i18next.t("page.private.subsidiary.tickets.table.updatedAt"), width: "12.5%", column: "updatedAt" }
                            ]}
                            onItemClick={ticket => this.updateTicketModal.open(ticket.id)}
                            items={this.state.tickets ? this.state.tickets.filter(ticket => {
                                if(this.state.hideDoneTickets) {
                                    if(this.state.hideArchivedTickets) return ticket.status !== "done" && !ticket.archived

                                    return ticket.status !== "done"
                                }

                                if(this.state.hideArchivedTickets) return !ticket.archived

                                return true
                            }).sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()).map(ticket => ({
                                id: ticket.id,
                                status: <div style={{ color: "white", background: getStatusAsTag(ticket.status).bgcolor , padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getStatusAsTag(ticket.status).title}</div>,
                                number: ticket.number,
                                title: ticket.title,
                                subsidiary: ticket.subsidiary ? `${ticket.subsidiary.name} (${ticket.subsidiary.label}) / ${ticket.subsidiary.address.postalCode} ${ticket.subsidiary.address.city}` : null,
                                updatedAt: moment(ticket.updatedAt).fromNow(),
                                priority: <div style={{ color: "white", background: getPriorityAsTag(ticket.priority).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getPriorityAsTag(ticket.priority).title}</div>,
                                type: <div style={{ color: "white", background: getTypeAsTag(ticket.type.id, this.state.types).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getTypeAsTag(ticket.type.id, this.state.types).title}</div>,
                                channel: <div style={{ color: "white", background: getChannelAsTag(ticket.channel).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getChannelAsTag(ticket.channel).title}</div>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
            </Section>
        </>
    }
}

const StyledUtilitiesStatement = styled.div`
    background: white;
    box-shadow: 0 8px 24px -16px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 20px 0;
    box-sizing: border-box;
    position: relative;
    display: flex;
    padding-left: 64px;
    padding-right: 24px;

    .delete {
        position: absolute;
        left: 16px;
        top: 54px;
        transition: opacity 0.3s, visibility 0.3s;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
    }


    .year {
        position: absolute;
        left: -8px;
        top: 40px;
        background: #3b97d3;
        height: 24px;
        font-size: 0.8em;
        padding: 0 8px;
        padding-left: 16px;
        border-radius: 0 4px 4px 4px;
        color: white;
        user-select: none;
        align-items: center;
        display: flex;
        transition: top 0.3s;

        &::before {
            display: block;
            content: " ";
            position: absolute;
            top: -6px;
            left: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #16669a;
            transform: rotate(-225deg);
            z-index: -1;
        }
    }

    &:hover {
        .year {
            top: ${props => props.readOnly ? "40px" : "12px"};
        }
        .delete {
            opacity: 1;
            visibility: visible;
        }
    }

    > .content {
        display: grid;
        grid-template-columns: ${props => props.type === "overhead" ? "0.5fr 1.25fr 0.75fr 0.5fr 64px" : (props.type === "subsidiaryTenant" ? "0.5fr 0.75fr 1.25fr 0.75fr 0.75fr 0.75fr 64px" : "0.5fr 1.25fr 0.75fr 0.5fr 1fr 0.75fr 64px")};
        width: 100%;
        grid-gap: 24px;
    }

`

class SubsidiaryTenantUtilitiesStatement extends Component {
    render() {
        const data = this.props.data
        const readOnly = this.props.readOnly

        return <Input.MutationProvider value={{
            name: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.name"),
            id: data.id,
            data
        }}>
            <StyledUtilitiesStatement type="subsidiaryTenant" readOnly={readOnly}>
                <div className="year">{data.year}</div>
                {!readOnly && <div className="delete" onClick={() => this.props.delete()}><i className="far fa-trash" /></div>}
                <div className="content">
                    <Input readOnly={readOnly} field="date" label={i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.date")} icon="calendar" date adjustWidth />
                    <Select readOnly={readOnly} field="user.id" label={i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.clerk")} icon="user" adjustWidth items={this.props.users.map(user => ({ key: user.id, title: `${user.firstName} ${user.lastName} / ${user.email}` }))} />
                    <Select readOnly={readOnly} field="state" label={i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.state")} icon="wrench" items={[
                        { key: "notApplicable", title: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.stateTypes.notApplicable") },
                        { key: "pending", title: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.stateTypes.pending") },
                        { key: "afoot", title: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.stateTypes.afoot") },
                        { key: "done", title: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.stateTypes.done") },
                        { key: "rebuffed", title: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.stateTypes.rebuffed") },
                        { key: "requested", title: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.stateTypes.requested") },
                        { key: "notRequested", title: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.stateTypes.notRequested") },
                    ]} adjustWidth />
                    <Input readOnly={readOnly} allowNegative field="value" label={i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.refund")} icon="piggy-bank" adjustWidth float format={val => val !== null ? formatter.format(val) : val} />
                    <Select readOnly={readOnly} field="subsidiaryTenant.id" label={i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.subtenant")} icon="home" items={this.props.tenants.map(tenant => ({ key: tenant.id, title: tenant.name }))} adjustWidth />
                    <Select readOnly={readOnly} field="type" label={i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.type")} icon="cog" items={[
                        { key: "effluent", title: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.typeTypes.effluent") },
                        { key: "overhead", title: i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.typeTypes.overhead") }
                    ]} adjustWidth />
                    <Button noIconMargin icon="folder-tree" thick stopPropagation onClick={() => this.props.documentsModal()} />
                    <div style={{ gridColumnStart: 1, gridColumnEnd: 8 }}><Input readOnly={readOnly} field="notes" label={i18next.t("page.private.subsidiary.updateSubsidiaryTenantUtilitiesStatement.button")} icon="sticky-note" adjustWidth /></div>
                </div>
            </StyledUtilitiesStatement>
        </Input.MutationProvider>
    }
}

class UtilitiesStatement extends Component {
    render() {
        const data = this.props.data
        const readOnly = this.props.readOnly

        return <Input.MutationProvider value={{
            name: i18next.t("page.private.subsidiary.updateUtilitiesStatement.name"),
            id: data.id,
            data
        }}>
            <StyledUtilitiesStatement type={data.type} readOnly={readOnly}>
                <div className="year">{data.year}</div>
                {!readOnly && <div className="delete" onClick={() => this.props.delete()}><i className="far fa-trash" /></div>}
                <div className="content">
                    <Input readOnly={readOnly} field="date" label={i18next.t("page.private.subsidiary.updateUtilitiesStatement.date")} icon="calendar" date adjustWidth />
                    <Select readOnly={readOnly} field="user.id" label={i18next.t("page.private.subsidiary.updateUtilitiesStatement.clerk")} icon="user" adjustWidth items={this.props.users.map(user => ({ key: user.id, title: `${user.firstName} ${user.lastName} / ${user.email}` }))} />
                    <Select readOnly={readOnly} field="state" label={i18next.t("page.private.subsidiary.updateUtilitiesStatement.state")} icon="wrench" items={[
                        { key: "notApplicable", title: i18next.t("page.private.subsidiary.updateUtilitiesStatement.stateTypes.notApplicable") },
                        { key: "pending", title: i18next.t("page.private.subsidiary.updateUtilitiesStatement.stateTypes.pending") },
                        { key: "afoot", title: i18next.t("page.private.subsidiary.updateUtilitiesStatement.stateTypes.afoot") },
                        { key: "done", title: i18next.t("page.private.subsidiary.updateUtilitiesStatement.stateTypes.done") },
                        { key: "rebuffed", title: i18next.t("page.private.subsidiary.updateUtilitiesStatement.stateTypes.rebuffed") },
                        { key: "requested", title: i18next.t("page.private.subsidiary.updateUtilitiesStatement.stateTypes.requested") },
                        { key: "notRequested", title: i18next.t("page.private.subsidiary.updateUtilitiesStatement.stateTypes.notRequested") },
                    ]} adjustWidth />
                    <Input readOnly={readOnly} allowNegative field="value" label={i18next.t("page.private.subsidiary.updateUtilitiesStatement.refund")} icon="piggy-bank" adjustWidth float format={val => val !== null ? formatter.format(val) : val} />
                    {data.type === "effluent" && <Input readOnly={readOnly} field="biller" label={i18next.t("page.private.subsidiary.updateUtilitiesStatement.invoice")} icon="user-tie" adjustWidth />}
                    {data.type === "effluent" && <Input readOnly={readOnly} field="aggregate" label={i18next.t("page.private.subsidiary.updateUtilitiesStatement.freeWasteWater")} icon="tint" adjustWidth float format={val => val !== null ? `${val} m³` : val} />}
                    <Button noIconMargin icon="folder-tree" thick stopPropagation onClick={() => this.props.documentsModal()} />
                    <div style={{ gridColumnStart: 1, gridColumnEnd: data.type === "effluent" ? 8 : 6 }}><Input readOnly={readOnly} field="notes" label={i18next.t("page.private.subsidiary.updateUtilitiesStatement.button")} icon="sticky-note" adjustWidth /></div>
                </div>
            </StyledUtilitiesStatement>
        </Input.MutationProvider>
    }
}

export { UtilitiesStatement }

export default class extends Component {
    render() {
        return <Page {...this.props} responsiveMaxSize="100%" padding="0 32px 0 32px" style={{ boxSizing: 'border-box' }}>
            <SubsidiaryPage {...this.props} />
        </Page>
    }
}
