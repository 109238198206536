import React, { Component } from "react"
import { Modal, Button, Select } from "scanmetrix-components"
import i18next from "i18next"

export default class extends Component {
    state = { workflowId: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ workflowId: null, loading: false })
        }}>
            <Modal.Head title={i18next.t("page.private.subsidiary.createWorkflowExecutionModal.title")} icon="play" />
            <Modal.Body padding={24} adjustHeight="328px">
                <Select
                    adjustWidth
                    label={i18next.t("page.private.subsidiary.createWorkflowExecutionModal.factSheetLabel")}
                    icon="project-diagram"
                    value={this.state.workflowId}
                    required
                    onChange={workflowId => this.setState({ workflowId })}
                    noUnselect
                    items={this.props.workflows.map(workflow => ({ key: workflow.id, title: workflow.name }))}
                />
            </Modal.Body>
            <Modal.Footer buttons={() => <Button loading={this.state.loading} thick green disabled={!this.state.workflowId} title={i18next.t("page.private.subsidiary.createWorkflowExecutionModal.exitButton")} icon="play" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($workflowId: ID!, $contextType: String!, $subsidiaryId: ID) {
                            createWorkflowExecution(workflowId: $workflowId, contextType: $contextType, subsidiaryId: $subsidiaryId)
                        }
                    `,
                    variables: {
                        workflowId: this.state.workflowId,
                        contextType: "subsidiary",
                        subsidiaryId: this.props.subsidiaryId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createWorkflowExecution) {
                        this.props.history.push("/workflow-execution/" + result.data.createWorkflowExecution)
                    }
                })}
            } />} />
        </Modal>
    }
}
