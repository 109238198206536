import styled from "styled-components";

export default styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 48px;
  flex: 1;
  position: relative;
  user-select: none;
  -webkit-user-drag: none;
  box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.25);
  background: white;
  z-index: 5;
  border-radius: 10px;
  
  h1, h2 {
    font-weight: 400;
  }

  h1 {
    font-size: 1.4em;
    margin-bottom: 8px;
    
    b {
      font-weight: 600;
      color: #3B97D3;
    }
  }
  
  h2 {
    margin-bottom: 32px;
    font-size: 1.1em;
    opacity: 0.75;
  }
`
