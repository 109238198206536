import React, { Component } from "react"
import styled from "styled-components"

const MultilangModal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
  
  .modal-body {
    width: 500px;
    border-radius: 5px;
    background: white;
    border: 1px solid rgba(0, 0, 0, .0625);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    .modal-title {
      width: 100%;
      padding: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, .0625);
      
      h1 {
        font-size: 1.15em;
        font-weight: 600;
        color: #20242B;
      }
      
      .close-btn {
        width: 25px;
        height: 25px;
        position: absolute;
        right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 250ms ease;
        
        &:hover {
          opacity: 0.75;
        }
        
        i {
          color: #20242B;
        }
      }
    }
    
    .modal-content {
      width: 100%;
      height: 100%;
      padding: 35px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      user-select: none;
      
      p {
        color: #20242B;
      }
      
      .radio-column {
        margin-top: 10px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        
        .row-view {
          width: 100%;
          margin: 5px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          
          input {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            cursor: pointer;
          }
          
          label {
            cursor: pointer;
          }
        }
      }
    }
    
    .modal-footer {
      width: 100%;
      padding: 15px;
      border-top: 1px solid rgba(0, 0 , 0, .0625);
      box-sizing: border-box;
      margin-top: auto;
      user-select: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      
      .modal-footer-close-btn {
        display: block;
        padding: 10px 20px;
        transition: all 250ms ease;
        cursor: pointer;
        box-sizing: border-box;
        outline: none;
        border-radius: 5px;
        border: 1px solid rgba(88, 190, 255, .75);
        background: #3B97D3;
        
        &:hover {
          opacity: 0.75;
        }
        
        p {
          color: white;
        }
      }
    }
  }
`

export default class extends Component {
    render() {
        return<MultilangModal style={this.props.style}>
            <div className='modal-body'>
                <div className='modal-title'>
                    <h1>Anzeigesprache auswählen</h1>
                    <div className='close-btn' onClick={this.props.closeBtn}>
                        <i className='fas fa-times'/>
                    </div>
                </div>
                <div className='modal-content'>
                    <p>Wählen Sie die gewünschte Anzeigesprache aus:</p>
                    <div className='radio-column'>
                        <div className='row-view'>
                            <input type='radio' id='de' name='Sprache' value='Deutsch'/>
                            <label htmlFor='de'>Deutsch</label>
                        </div>
                        <div className='row-view'>
                            <input type='radio' id='en' name='Sprache' value='English'/>
                            <label htmlFor='en'>English</label>
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='modal-footer-close-btn' onClick={this.props.submitBtn}>
                        <p>Bestätigen</p>
                    </div>
                </div>
            </div>
        </MultilangModal>
    }
}
